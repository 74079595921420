import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';

import {
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  Tab,
  Tabs,
  Navigation
} from '../inbox/inbox-styled.jsx';
import { Outlet } from 'react-router';
import { LinkItem } from '../../../dashboard/pages/inbox/inbox-styled';

const breadcrumbs = [
  { link: '#', name: 'Messages' },
  { link: '#', name: 'Inbox' }
];

export const InboxProfile = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const hideSidebar = () => {
    setIsSidebarVisible(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  const showSidebar = () => {
    setIsSidebarVisible(true);
    document.querySelector('body').style.overflow = 'hidden';
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Tabs>
        <Navigation>
          <NavLink to={'messages'}>
            {({ isActive }) => (
              <LinkItem isActive={isActive}>
                Inbox <span>35</span>
              </LinkItem>
            )}
          </NavLink>
          <NavLink to={'notifications'}>
            {({ isActive }) => (
              <LinkItem isActive={isActive}>
                Notifications<span>35</span>
              </LinkItem>
            )}
          </NavLink>
          <NavLink to={'offers'}>
            {({ isActive }) => (
              <LinkItem isActive={isActive}>
                Offers<span>35</span>
              </LinkItem>
            )}
          </NavLink>
        </Navigation>
        <Tab>
          <Outlet />
        </Tab>
      </Tabs>
    </>
  );
};
