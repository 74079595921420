import { SectionHeaderKnowledge } from 'components/section-header-nowledge/section-header-knowledge';
import { KnowledgeBaseInfo } from '../../../../components/knowledge-base-info/knowledge-base-info';
import { Container } from './knowledge-base-section-styled';

export const KnowledgeBaseSection = () => {
  return (
    <Container>
      <SectionHeaderKnowledge title={'Knowledge Base'} link={'#'} />
      <KnowledgeBaseInfo />
    </Container>
  );
};
