import { useState } from 'react';
import mapMock from './map-mock.jpg';
import { Container, MapWrapper, TabBtn, TabsButtons, TabsContent } from './tabs-with-map-styled';

const MapEnum = {
  map: 'map',
  schools: 'schools',
  restaurants: 'restaurants',
  hospitals: 'hospitals'
};

export const TabsWithMap = () => {
  const [currentTab, setCurrentTab] = useState(MapEnum.map);
  return (
    <Container>
      <TabsButtons>
        <TabBtn
          isActive={currentTab === MapEnum.map}
          onClick={() => {
            setCurrentTab(MapEnum.map);
          }}
        >
          Map
        </TabBtn>
        <TabBtn
          isActive={currentTab === MapEnum.schools}
          onClick={() => {
            setCurrentTab(MapEnum.schools);
          }}
        >
          Schools
        </TabBtn>
        <TabBtn
          isActive={currentTab === MapEnum.restaurants}
          onClick={() => {
            setCurrentTab(MapEnum.restaurants);
          }}
        >
          Restaurants
        </TabBtn>
        <TabBtn
          isActive={currentTab === MapEnum.hospitals}
          onClick={() => {
            setCurrentTab(MapEnum.hospitals);
          }}
        >
          Hospitals
        </TabBtn>
      </TabsButtons>
      <TabsContent>
        {currentTab === MapEnum.map && (
          <MapWrapper>
            <img src={mapMock} alt='' />
          </MapWrapper>
        )}
        {currentTab === MapEnum.schools && (
          <MapWrapper>
            <img src={mapMock} alt='' />
          </MapWrapper>
        )}{' '}
        {currentTab === MapEnum.restaurants && (
          <MapWrapper>
            <img src={mapMock} alt='' />
          </MapWrapper>
        )}{' '}
        {currentTab === MapEnum.hospitals && (
          <MapWrapper>
            <img src={mapMock} alt='' />
          </MapWrapper>
        )}
      </TabsContent>
    </Container>
  );
};
