import {
  ImgPlaceholder,
  ImgUploadBtnWrapper,
  ImgUploadGallery,
  ImgUploadGalleryWrapper,
  ImgUploadMain,
  ImgUploadTitle,
  ImgUploadWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

export const ProductImage = ({ title = 'Product Image' }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <ImgUploadWrapper>
        <ImgUploadMain>
          <ImgUploadTitle>main product image</ImgUploadTitle>
          <ImgPlaceholder />
          <ImgUploadBtnWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </ImgUploadBtnWrapper>
        </ImgUploadMain>
        <ImgUploadGallery>
          <ImgUploadTitle>product image Gallery</ImgUploadTitle>
          <ImgUploadGalleryWrapper>
            <ImgPlaceholder />
            <ImgPlaceholder />
            <ImgPlaceholder />
            <ImgPlaceholder />
          </ImgUploadGalleryWrapper>
          <ImgUploadBtnWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </ImgUploadBtnWrapper>
        </ImgUploadGallery>
      </ImgUploadWrapper>
    </Section>
  );
};
