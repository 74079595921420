import { ReactComponent as Eye } from '../../../../assets/eye-table-icon.svg';
import thumbnail from '../../../../assets/all-order-sidebar-table-icon.png';
import {
  TagsWrapper,
  Tag,
  StyledId,
  CheckboxWrapper,
  CheckboxWrapperHeader,
  DropdownWrapper,
  Thumbnail,
  SidebarInputWrapper
} from './all-order-styled';
import Checkbox from '../../../../components/checkbox/checkbox';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { Status } from '../../../../components/status/status';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Total',
    accessor: 'total'
  },
  {
    Header: 'Products',
    accessor: 'products'
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod'
  },
  {
    Header: 'Delivery Method',
    accessor: 'deliveryMethod'
  },
  {
    Header: 'Actions',
    accessor: 'eye'
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    customer: 'Langton Shoriwa',
    date: '07 Jul 2022',
    status: <Status label={'paid'} isChecked={true} />,
    total: '$300 000 000',
    products: (
      <TagsWrapper>
        {Tags.map((data, index) => {
          return <Tag key={index}>{data}</Tag>;
        })}
      </TagsWrapper>
    ),
    paymentMethod: 'Cash On Delivery',
    deliveryMethod: 'Everything Zimbabwean',
    eye: <Eye />
  },
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    customer: 'Langton Shoriwa',
    date: '07 Jul 2022',
    status: <Status label={'paid'} isChecked={false} />,
    total: '$300 000 000',
    products: (
      <TagsWrapper>
        {Tags.map((data, index) => {
          return <Tag key={index}>{data}</Tag>;
        })}
      </TagsWrapper>
    ),
    paymentMethod: 'Cash On Delivery',
    deliveryMethod: 'Everything Zimbabwean',
    eye: <Eye />
  }
];

export const SidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product Name',
    accessor: 'productName'
  },
  {
    Header: 'Colour',
    accessor: 'colour'
  },
  {
    Header: 'Capacity',
    accessor: 'capacity'
  },
  {
    Header: 'Model',
    accessor: 'model'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const SidebarColumnsRows = [
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    colour: (
      <DropdownWrapper>
        <Dropdown name={'Maroon'} withBorders />
      </DropdownWrapper>
    ),
    capacity: (
      <DropdownWrapper>
        <Dropdown name={'3kg'} withBorders />
      </DropdownWrapper>
    ),
    model: (
      <DropdownWrapper>
        <Dropdown name={'KM100'} withBorders />
      </DropdownWrapper>
    ),
    qty: (
      <SidebarInputWrapper>
        <GlobalInput value={'1'} width={'40px'} />
      </SidebarInputWrapper>
    ),
    price: (
      <SidebarInputWrapper>
        <GlobalInput value={'$2000'} width={'58px'} />
      </SidebarInputWrapper>
    ),
    total: '$2000'
  }
];

export const SecondSidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product Name',
    accessor: 'productName'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const SecondSidebarColumnsRows = [
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  }
];
