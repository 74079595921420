import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { FormTracking } from './components/form-tracking/form-tracking';
import { Container, Description, Title, TrackingContainer, Wrapper } from './order-tracking-styled';
import { OrderTrackingStatus } from './status/status';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Order Tracking' }
];

const description =
  'To track your order please enter your Order ID in the box below and press the «Track» button. This was given to you on your receipt and in the confirmation email you should have received.';

export const OrderTracking = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Wrapper>
          <TrackingContainer>
            <Title>Order Tracking</Title>
            <Description>{description}</Description>
            <FormTracking />
          </TrackingContainer>
          <TrackingContainer>
            <OrderTrackingStatus />
          </TrackingContainer>
        </Wrapper>
      </Container>
    </>
  );
};
