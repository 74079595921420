import { BreadcrumbsLink, Container } from './breadcrumbs-styled';

export const Breadcrumbs = ({ links }) => {
  return (
    <Container>
      {links ? (
        links.map((link, i) => {
          return (
            <BreadcrumbsLink to={link.link} key={i} isActive={i === links.length - 1}>
              {link.name}
            </BreadcrumbsLink>
          );
        })
      ) : (
        <>
          <BreadcrumbsLink to='#'>Electronic Goods</BreadcrumbsLink>
          <BreadcrumbsLink isActive to='#'>
            Mobile & Smart Phones
          </BreadcrumbsLink>
        </>
      )}
    </Container>
  );
};
