export const YoutubeIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.9054 4.815C16.8163 4.45906 16.6349 4.13293 16.3794 3.86955C16.1239 3.60618 15.8035 3.41489 15.4504 3.315C14.1604 3 9.00043 3 9.00043 3C9.00043 3 3.84043 3 2.55043 3.345C2.19736 3.44489 1.87691 3.63618 1.62144 3.89955C1.36597 4.16293 1.18452 4.48906 1.09543 4.845C0.85934 6.15417 0.743856 7.48223 0.750429 8.8125C0.742013 10.1528 0.857505 11.491 1.09543 12.81C1.19365 13.1549 1.37916 13.4686 1.63404 13.7209C1.88891 13.9731 2.20454 14.1554 2.55043 14.25C3.84043 14.595 9.00043 14.595 9.00043 14.595C9.00043 14.595 14.1604 14.595 15.4504 14.25C15.8035 14.1501 16.1239 13.9588 16.3794 13.6954C16.6349 13.4321 16.8163 13.1059 16.9054 12.75C17.1397 11.4507 17.2552 10.1328 17.2504 8.8125C17.2588 7.47222 17.1434 6.13402 16.9054 4.815V4.815Z'
        stroke='#388638'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.3125 11.2644L11.625 8.81188L7.3125 6.35938V11.2644Z'
        stroke='#388638'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
