import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--ez-grey-bg-devider, #dcdcdc);
  background: #fff;
  overflow: hidden;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ImgWrapper = styled.div`
  flex-basis: 230px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Content = styled.div`
  padding: 16px;
  flex-basis: calc(100% - 230px);
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const TopItem = styled.div``;

export const Title = styled.div`
  color: var(--dark, #29263a);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 141.176% */
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Location = styled.div`
  color: var(--txt-secondary, #acadb9);

  /* subtext */
  ${LatoFont};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 200% */
`;

export const Price = styled.div`
  color: var(--dark, #29263a);
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  @media ${device.mobile} {
    text-align: left;
  }
`;

export const PriceZWL = styled.div`
  color: #acadb9;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  @media ${device.mobile} {
    text-align: left;
  }
`;

export const Description = styled.div`
  margin: 5px 0 14px;
  overflow: hidden;
  color: #676a75;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${LatoFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 8px;
`;

export const Tag = styled.div`
  border-radius: 20px;
  border: 1px solid #bdd7bd;
  color: var(--dark, #29263a);
  ${LatoFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  display: flex;
  align-items: center;
  padding: 8px 13px 8px 18px;
  button {
    ${buttonReset};
    display: flex;
    align-items: center;
  }
`;

export const Btns = styled.div`
  display: flex;
  gap: 8px;
  @media ${device.mobile} {
    width: 100%;
    button {
      width: 50%;
    }
  }
`;
