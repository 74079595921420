import styled from 'styled-components';
import { buttonReset, mainContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${device.tablet} {
    display: none;
  }
`;

export const SliderBtn = styled.button`
  ${buttonReset};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const PrevSlideBtn = styled(SliderBtn)`
  border: 1px solid #c6d2e9;
  color: #29263a;
`;

export const NextSlideBtn = styled(SliderBtn)`
  color: #ffffff;
  margin-left: 16px;
  background: var(--main-green);
  svg {
    transform: rotate(180deg);
  }
`;

export const Container = styled.div`
  ${mainContainer};
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const SliderWrapper = styled.div`
  width: calc(100% + 350px);
`;

export const FadeOutContainer = styled.div`
  position: relative;
  &::after {
    position: absolute;
    height: 100%;
    width: 90px;
    background: linear-gradient(271.2deg, #ffffff 0.91%, rgba(255, 255, 255, 0) 98.87%);
    opacity: 0.6;
    top: 0;
    right: 0;
    content: '';
    z-index: 5;
    pointer-events: none;
  }
`;
