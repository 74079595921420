import styled, { css } from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

import pin from './img/marker-pin.svg';
import { device } from '../../assets/style-helpers/breakpoints';

export const Content = styled.div`
  padding: 12px 14px 20px;
`;

export const ImgWrapper = styled.div`
  width: 100%;
  height: 124px;
  display: flex;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Logo = styled.div`
  position: absolute;
  left: 14px;
  bottom: 12px;
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContactsBtns = styled.div`
  display: none;
  align-items: center;
  margin-right: 16px;

  a {
    display: flex;
    color: var(--main-green);
  }

  a + a {
    margin-left: 10px;
  }
`;

export const Title = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 22px;
  height: 44px;
  font-weight: 600;
`;

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid var(--main-gray-2);
  overflow: hidden;

  ${(props) => {
    return props.isListView
      ? css`
          display: flex;
          flex-direction: row;
          ${ImgWrapper} {
            width: auto;
            flex-basis: 160px;
            height: 100%;
            flex-shrink: 0;
          }
          ${Title} {
            -webkit-line-clamp: 1;
            height: 22px;
          }
          ${Content} {
            flex-grow: 1;
          }
          ${ContactsBtns} {
            display: flex;
          }
        `
      : css`
          display: flex;
          flex-direction: column;
        `;
  }}

  ${(props) =>
    props.isMobileListDefault &&
    css`
      @media ${device.tablet} {
        display: flex;
        flex-direction: row;
        ${ImgWrapper} {
          width: auto;
          flex-basis: 160px;
          height: 100%;
          flex-shrink: 0;
        }
        ${Title} {
          -webkit-line-clamp: 2;
          height: 22px;
        }
        ${Content} {
          flex-grow: 1;
        }
        ${ContactsBtns} {
          display: flex;
        }
      }
      @media ${device.mobile} {
        ${ContactsBtns} {
          display: none;
        }
      }
    `}
`;

export const Description = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: var(--main-subtext);
  margin-bottom: 8px;
  font-weight: 700;
`;

export const BtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 16px;
`;

export const Text = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: #676a75;
  margin-bottom: 8px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const Divider = styled.div`
  padding-bottom: 64px;
`;

export const Address = styled.div`
  margin-bottom: 8px;
  padding-left: 22px;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 48px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background: url(${pin}) no-repeat center;
    background-size: 16px 16px;
  }
`;

export const BottomMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewBtn = styled.button`
  ${buttonReset};
  border-radius: 50%;
  background: var(--main-green);
  color: var(--main-white);
  transition: all 0.3s;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--main-green);
  }
`;

export const RateStarsDesktop = styled.div`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    display: none;
  }
  a {
    color: var(--main-green);
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a + a {
    margin-left: 8px;
  }
`;
