import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const MainStyled = styled.section`
  width: 100%;
  min-width: 0;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;
