import React from 'react';

import { ReactComponent as RadioIcon } from './img/radio.svg';
import { Description, Icon, Input, Label, RadioContainer, Title } from './radio-styled';

export function Radio({
  id,
  name,
  value,
  title,
  desc,
  isChecked = false,
  cb,
  disabled,
  isActiveRadio = true
}) {
  return (
    <RadioContainer>
      <Input
        id={id}
        type='radio'
        name={name}
        value={value}
        checked={!!isChecked}
        onChange={(e) => cb && cb(e)}
        disabled={disabled}
      />

      <Label htmlFor={id} isActiveRadio={isActiveRadio}>
        <Icon>{isActiveRadio && <RadioIcon />}</Icon>

        <Title isDisabled={disabled}>{title}</Title>
        {desc && <Description isDisabled={disabled}>{desc}</Description>}
      </Label>
    </RadioContainer>
  );
}
