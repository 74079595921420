import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { Link } from 'react-router-dom';
import { linkReset } from '../../assets/style-helpers/style-helpers';

export const MobileBottomMenuStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--light-bg);
  padding: 8px 0 5px;
  z-index: 100;
  display: none;
  z-index: 999;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const MobileFooterItem = styled(Link)`
  ${linkReset};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--main-text);
  font-family: 'Poppins', sans-serif;
  line-height: 15px;
  letter-spacing: -0.24px;

  span {
    font-size: 10px;
    display: block;
    margin-top: 4px;
  }
`;
