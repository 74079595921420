import { GlobalInput } from '../../../../../../components/global-input/global-input';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import {
  CheckboxWrapper,
  GridInputWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-form-auction-styled.jsx';

export const ProductPricing = () => {
  return (
    <Section>
      <SectionTitle>Pricing</SectionTitle>
      <CheckboxWrapper>
        <GridInputWrapper>
          <InputGroup label={'Start bidding price'}>
            <GlobalInput placeholder={'$'} />
          </InputGroup>
          <InputGroup label={'Reserve price'}>
            <GlobalInput placeholder={'$'} />
          </InputGroup>{' '}
          <InputGroup label={'buy it now price'}>
            <GlobalInput placeholder={'$'} />
          </InputGroup>
        </GridInputWrapper>
      </CheckboxWrapper>
    </Section>
  );
};
