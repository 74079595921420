import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { ReactComponent as DownloadIcon } from '../../components/icons/download.svg';
import { ReactComponent as WordpressIcon } from '../../components/icons/wordpress.svg';
import { ItemCard } from '../../components/item-card/item-card';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  CardsWrapper,
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  Subtitle,
  Title,
  UploadBtnWrapper
} from './product-bulk-upload-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Bulk Upload' }
];

const uploadCards = [
  {
    title: 'Import From File',
    subtitle: 'Use a CVS or XML File',
    icon: <DownloadIcon />
  },
  {
    title: 'Import From A WordPress Website',
    subtitle: 'WooCommerce API',
    icon: <WordpressIcon />
  }
];

export const ProductBulkUploadProfile = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Product Bulk Upload</Title>
      <Subtitle>Please select the method you would like to use in uploading your products</Subtitle>
      <CardsWrapper>
        {uploadCards.map((item, i) => {
          return (
            <ItemCard title={item.title} icon={item.icon} subtitle={item.subtitle} key={i}>
              <UploadBtnWrapper>
                <GlobalButton type='secondary' fullWidth>
                  <SvgIcon name='download' />
                  Upload
                </GlobalButton>
              </UploadBtnWrapper>
            </ItemCard>
          );
        })}
      </CardsWrapper>
    </>
  );
};
