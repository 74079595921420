import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  ${mainContainer};
  display: flex;
  min-height: 100vh;

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
  @media ${device.mobile} {
  }
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;

    @media ${device.laptop} {
      overflow-y: scroll;
      height: auto;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 3px;
        background: rgb(243 243 243);
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(56, 134, 56, 0.1);
      }
    }

    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
    padding-top: 5px;
  }
  @media ${device.mobile} {
    top: 135px;
    padding-top: 12.5px;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 14px;
  }
  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 10px;
  }
`;

export const Title = styled.div`
  ${title30};
  margin-top: 5px;
  margin-bottom: 25px;

  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobile} {
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${device.tablet} {
    margin-bottom: 20px;
    > section {
      width: 100%;
      margin-right: 33px;
      input {
        width: 100% !important;
      }
    }
  }
  @media ${device.mobile} {
  }
  > section {
    width: 560px;
    @media ${device.tablet} {
      width: 90%;
    }
  }
`;

export const FilterButton = styled.button`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: #3664f6;
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;
  @media ${device.tablet} {
    display: flex;
    width: unset;
    font-size: 14px;
    padding: 8px 28px;
    margin-bottom: unset;
    margin-right: 22px;

    img {
      margin-right: 11px;
    }
  }
  @media ${device.mobile} {
    width: unset;
    font-size: 14px;
    padding: 11px 26px;
    margin-bottom: unset;

    img {
      margin-right: 11px;
    }
  }
`;

export const ControlsSecond = styled.div`
  display: flex;
  align-items: center;
  right: 3px;
  top: 3px;
  border: 1px solid #e2e5eb;
  border-radius: 6px;
  padding: 2px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const SortBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  background: #f5f7fb;
  border-radius: 6px;
  margin-right: 19px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #acadb9;
`;

export const ButtonMobile = styled.div`
  display: none;
  align-items: center;
  svg {
    margin-right: 11px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
  }
  @media ${device.tablet} {
    display: flex;
  }
`;

export const Categories = styled.div`
  margin-top: 34px;
  margin-bottom: 34px;
  @media ${device.tablet} {
    width: auto;
    overflow-y: scroll;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eeeff5;
    }
  }
  @media ${device.mobile} {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const Category = styled.div`
  background: #ffffff;
  border: 1px solid rgba(56, 134, 56, 0.3);
  border-radius: 20px;
  padding: 7px 18px 6px;
  font-family: 'Poppins';
  margin-right: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
  color: #29263a;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      background: #f2f7f2;
      border: 1px solid #388638;
    `};

  &:last-child {
    margin-right: 0px;
  }
  @media ${device.mobile} {
    margin-right: 8px;
  }
`;

export const ProductsBox = styled.div`
  margin-bottom: 90px;
`;

export const Products = styled.div`
  margin-bottom: 40px;
`;

export const Product = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const ProductPhoto = styled.div`
  width: 252px;
  height: 243px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  @media ${device.tablet} {
    height: 253px;
  }
  @media ${device.mobile} {
    width: 142.48px;
    height: 142px;
  }
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const ProductBox = styled.div`
  margin-left: 24px;
  width: 78%;
  @media ${device.mobile} {
    margin-left: 12px;
  }
`;

export const ProductTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 5px;
  padding-top: 10px;
  @media ${device.tablet} {
    padding-top: 10px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ProductInfo = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #acadb9;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const ProductRating = styled.div`
  margin-left: 8px;
  border-left: 1px solid #dcdcdc;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const ProductRatingTitle = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #29263a;
  margin-left: 8px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const RatingCount = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #acadb9;
  margin-left: 4px;
`;

export const ProductDescription = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5a5670;
  @media ${device.mobile} {
    display: none;
  }
`;

export const ProductPrice = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #29263a;
  margin-top: 14px;
  margin-bottom: 14px;
  @media ${device.mobile} {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: auto;
  }
`;

export const Box = styled.div`
  @media ${device.mobile} {
    display: flex;
    align-items: center;
  }
`;

export const ProductButtons = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  @media ${device.tablet} {
    padding-bottom: 15px;
  }
  @media ${device.mobile} {
    justify-content: flex-end;
    margin-right: 8px;
    display: inline-flex;
    padding-bottom: 0px;
  }
  > button {
    &:nth-child(1) {
      @media ${device.mobile} {
        position: relative;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        svg {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
        }
      }
    }
    &:nth-child(2) {
      margin-left: 8px;
      @media ${device.mobile} {
        display: none;
      }
    }
  }
`;

export const Span = styled.span`
  @media ${device.mobile} {
    display: none;
  }
`;

export const DeleteButton = styled.button`
  border: 1px solid #dcdcdc;
  background: transparent;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  transition: 0.6s;
  cursor: pointer;
  transition: all 0.6s;
  margin-left: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    display: flex !important;
    margin-left: 8px !important;
  }
  &:hover {
    border: 1px solid #f9955f;
    transition: all 0.6s;
    svg {
      path {
        stroke: #f9955f;
        transition: all 0.6s;
      }
    }
  }
`;

export const ViewButton = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const ProductStatus = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #acadb9;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
  padding: 8px 16px;
  display: inline-block;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  ${(props) =>
    props.isActive &&
    css`
      background: #388638;
    `}

  @media ${device.mobile} {
    right: auto;
    left: 0;
    bottom: auto;
    top: 0;
  }
`;

export const Img = styled.img`
  @media ${device.mobile} {
    display: none;
  }
`;

export const ImgMobile = styled.img`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

export const ButtonAllShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
