import img1 from '../assets/temp-img/temp4.png';
import img2 from '../assets/temp-img/temp5.png';
import img3 from '../assets/temp-img/temp6.png';

export const generalProductsMock = [
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.8,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img2,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.8,
    reviews: 28,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.1,
    reviews: 30,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.8,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img2,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.8,
    reviews: 28,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.1,
    reviews: 30,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  }
];
