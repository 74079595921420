import {
  Buttons,
  Container,
  Description,
  MainButtons,
  MataInfoItem,
  MeniItem,
  MetaInfo,
  MetaInfoText,
  MetaInfoTitle,
  MobBtn,
  Price,
  RatingWrapper,
  Reviews,
  ReviewsOrders,
  ShareBtn,
  ShopName,
  Tag,
  TagsTitle,
  TagsWrapper,
  Title
} from './product-info-styled';
import { RateStars } from '../rate-stars/rate-stars';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { TabsWithMap } from '../tabs-with-map/tabs-with-map';
import { useState } from 'react';
import { MakeOfferPopup } from '../make-offer-popup/make-offer-popup';

export const ProductInfoRealEstate = ({ setIsSidebarHidden }) => {
  const [isMakeOfferVisible, setMakeOfferVisible] = useState(false);

  return (
    <Container>
      <ShopName>Properties & Associates</ShopName>
      <Title>12 Roomed Home</Title>
      <RatingWrapper>
        <RateStars rate={3.5} height={17} width={17} isRateCounter={false} />
        <ReviewsOrders>
          <Reviews>
            <a href='components/product-info/product-info-real-estate#'>10 Reviews</a>
          </Reviews>
        </ReviewsOrders>
      </RatingWrapper>
      <MetaInfo>
        <MataInfoItem>
          <MetaInfoTitle>Item Published On: </MetaInfoTitle>
          <MetaInfoText>10/03/2022</MetaInfoText>
        </MataInfoItem>
        <MataInfoItem>
          <MetaInfoTitle>ID: </MetaInfoTitle>
          <MetaInfoText>EZ124-585-22155-44</MetaInfoText>
        </MataInfoItem>
      </MetaInfo>
      <Price>$1 200 000</Price>
      <Description>
        Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
        morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare
        viverra leo ornare viverra.
      </Description>
      <MeniItem>
        <TagsTitle>Property Highlights:</TagsTitle>
        <TagsWrapper>
          <Tag>For Sale</Tag>
          <Tag>For Rental</Tag>
        </TagsWrapper>
      </MeniItem>
      <MeniItem>
        <TagsTitle>Property Highlights:</TagsTitle>
        <TagsWrapper>
          <Tag>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.33301 14.3332V8.99984C1.33301 8.64621 1.47348 8.30708 1.72353 8.05703C1.97358 7.80698 2.31272 7.6665 2.66634 7.6665H13.333C13.6866 7.6665 14.0258 7.80698 14.2758 8.05703C14.5259 8.30708 14.6663 8.64621 14.6663 8.99984V14.3332M2.66634 7.6665V4.99984C2.66634 4.64622 2.80682 4.30708 3.05687 4.05703C3.30691 3.80698 3.64605 3.6665 3.99967 3.6665H11.9997C12.3533 3.6665 12.6924 3.80698 12.9425 4.05703C13.1925 4.30708 13.333 4.64622 13.333 4.99984V7.6665M7.99967 3.6665V7.6665M1.33301 12.9998H14.6663'
                stroke='#29263A'
                strokeWidth='1.6'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            4 Beds
          </Tag>
          <Tag>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.25 8H2M14 8C14.1989 8 14.3897 7.92098 14.5303 7.78033C14.671 7.63968 14.75 7.44891 14.75 7.25V4.25C14.75 3.5 14.3 2 12.5 2C10.7 2 10.25 3.5 10.25 4.25M14 8V9.5C14 10.3535 13.5627 11.936 11.9412 12.3807M14 8H2M2 8V9.5C2 10.3535 2.43725 11.936 4.05875 12.3807M3.5 14L4.05875 12.3807M4.05875 12.3807C4.33625 12.4572 4.649 12.5 5 12.5H11C11.3177 12.5028 11.6343 12.4626 11.9412 12.3807M12.5 14L11.9412 12.3807M10.25 4.25H8.75M10.25 4.25H11.75'
                stroke='#29263A'
                strokeWidth='1.6'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            2 Baths
          </Tag>
          <Tag>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_1714_151576)'>
                <path
                  d='M9.66697 3.66667L10.667 4.66667M7.66697 5.66667L8.66697 6.66667M5.66697 7.66667L6.66697 8.66667M3.66697 9.66667L4.66697 10.6667M1.71073 11.7105L4.28982 14.2896C4.42182 14.4216 4.48783 14.4876 4.56394 14.5123C4.63088 14.5341 4.703 14.5341 4.76995 14.5123C4.84606 14.4876 4.91206 14.4216 5.04406 14.2896L14.2898 5.04382C14.4218 4.91182 14.4878 4.84581 14.5126 4.7697C14.5343 4.70276 14.5343 4.63064 14.5126 4.56369C14.4878 4.48758 14.4218 4.42158 14.2898 4.28957L11.7107 1.71049C11.5787 1.57848 11.5127 1.51248 11.4366 1.48775C11.3697 1.466 11.2976 1.466 11.2306 1.48775C11.1545 1.51248 11.0885 1.57848 10.9565 1.71049L1.71073 10.9562C1.57873 11.0882 1.51272 11.1542 1.48799 11.2304C1.46624 11.2973 1.46624 11.3694 1.48799 11.4364C1.51272 11.5125 1.57873 11.5785 1.71073 11.7105Z'
                  stroke='#29263A'
                  strokeWidth='1.6'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_1714_151576'>
                  <rect width='16' height='16' fill='white' />
                </clipPath>
              </defs>
            </svg>
            5000m2
          </Tag>
        </TagsWrapper>
      </MeniItem>
      <Buttons>
        <MainButtons>
          <GlobalButton height={40} type='primary'>
            <SvgIcon name={'message-check-square'} />
            Book a Tour
          </GlobalButton>
          <GlobalButton
            height={40}
            type={'orange'}
            onClick={() => {
              setMakeOfferVisible(true);
            }}
          >
            <SvgIcon name={'message-text-square'} />
            Make an Offer
          </GlobalButton>
          <MobBtn>
            <GlobalButton
              height={40}
              type='primary'
              onClick={() => {
                setIsSidebarHidden(false);
              }}
            >
              Contact Seller
            </GlobalButton>
          </MobBtn>
        </MainButtons>
        <ShareBtn>
          <SvgIcon name={'share'} />
          Share
        </ShareBtn>
      </Buttons>
      <TabsWithMap />
      <MakeOfferPopup
        isVisible={isMakeOfferVisible}
        onClose={() => {
          setMakeOfferVisible(false);
        }}
        onMakeOfferBtnClick={() => {
          setMakeOfferVisible(false);
        }}
      />
    </Container>
  );
};
