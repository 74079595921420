import styled from 'styled-components';
import { ulReset } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--main-gray-2);
  margin-bottom: 16px;
`;

export const List = styled.ul`
  ${ulReset};
  height: 210px;
  overflow: auto;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 15px;
  }
`;

export const ItemTitleWrapper = styled.div``;

export const ItemTitle = styled.div`
  font-weight: 600;
`;

export const ItemSubtitle = styled.div`
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  line-height: 17px;
  color: var(--main-subtext);
`;

export const ItemValue = styled.div`
  font-size: 17px;
  line-height: 26px;
`;
