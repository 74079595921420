import arrow from '../../assets/icons/arrow-bottom-phone.svg';
import { BoxInput, CodeInput, Img, StyledInput } from './global-input-phone-styled';

export const GlobalInputPhone = ({ value, onChange, placeholder, name }) => {
  return (
    <BoxInput>
      <CodeInput>
        +263 <Img src={arrow} alt='arrow' />
      </CodeInput>
      <StyledInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        type='number'
        name={name}
      />
    </BoxInput>
  );
};
