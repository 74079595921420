import styled from 'styled-components';
import { GlobalButton } from 'components/global-button/global-button';

export const RegisterForm = styled.form`
  width: 100%;
  max-width: 480px;
`;

export const RegisterButton = styled(GlobalButton)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const WhatsupNumberLabelStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const WhatsupNumberLabelStyledText = styled.div`
  max-width: 155px;
`;

export const WhatsupNumberLabelStyledTitle = styled.div`
  margin-right: 8px;
`;

export const AccountType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

export const RadiosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  > div + div {
    margin-left: 64px;
  }
`;

export const CompareLink = styled.div`
  a {
    color: var(--main-green);
    &:hover {
      color: var(--main-green);
    }
  }
`;

export const AcceptTerms = styled.div`
  margin-top: 16px;
  font-family: 'Lato', sans-serif;
  color: var(--main-subtext);
  a {
    color: var(--main-green);
    &:hover {
      color: var(--main-green);
    }
  }
`;
