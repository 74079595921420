import ico from 'assets/icons/ico.svg';
import ico2 from 'assets/icons/ico2.svg';
import {
  MyNotifications,
  NotificationBlock,
  NotificationBox,
  NotificationData,
  NotificationName,
  NotificationPhoto,
  NotificationPoint,
  NotificationTitle,
  Photo,
  Sort,
  SortActive,
  Sorts
} from '../../../../../profile/pages/notifications/notifications-styled';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as CheckInboxAll } from 'assets/icons/check-inbox.svg';

const notification = [
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 Nike Air Jordan With Cool Lanb',
    data: '2 hours ago',
    ico: ico,
    statusNew: true
  },
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 — Web Design Consultation Online',
    data: '2 hours ago',
    ico: ico2,
    statusNew: true
  },
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 Nike Air Jordan With Cool Lanb',
    data: '2 hours ago',
    ico: ico,
    statusNew: true
  },
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 — Web Design Consultation Online',
    data: '2 hours ago',
    ico: ico2,
    status: 'event',
    statusNew: false
  },
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 Nike Air Jordan With Cool Lanb',
    data: '2 hours ago',
    ico: ico,
    status: 'product',
    statusNew: false
  },
  {
    name: 'John Doe',
    text: 'has made a price inquiry on your product:',
    product: '#12325 — Web Design Consultation Online',
    data: '2 hours ago',
    ico: ico2,
    status: 'event',
    statusNew: false
  }
];

export const NotificationsDashboard = () => {
  return (
    <>
      <Sorts>
        <SortActive>
          <CheckInboxAll />
          All
        </SortActive>
        <Sort>Unread</Sort>
      </Sorts>
      <MyNotifications>
        {notification.map((info, i) => (
          <NotificationBlock key={i}>
            <NotificationPhoto>
              <Photo src={info.ico} alt='ico' />
              {info.statusNew && <NotificationPoint></NotificationPoint>}
            </NotificationPhoto>
            <NotificationBox>
              <NotificationTitle>
                <NotificationName>{info.name}</NotificationName>
                {info.text}
                <NotificationName>{info.product}</NotificationName>
              </NotificationTitle>
              <NotificationData>
                <Clock /> {info.data}
              </NotificationData>
            </NotificationBox>
          </NotificationBlock>
        ))}
      </MyNotifications>
    </>
  );
};
