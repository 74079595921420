import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import {
  Card,
  CardTitle,
  CheckoutStyled,
  Col,
  Container,
  MobileBtns,
  MobileTitle,
  Step,
  StepOneBtn,
  Steps,
  StepTwoBtn,
  Title,
  Wrapper
} from './checkout-styled';
import { BillingDetails } from './components/billing-details/billing-details';
import { Delivery } from './components/delivery/delivery';
import { Payment } from './components/payment/payment';
import { useState } from 'react';
import { GlobalButton } from '../../components/global-button/global-button';

import { ReactComponent as ChevronLeft } from './components/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from './components/icons/chevron-right.svg';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Cart' },
  { link: '#', name: 'Checkout' }
];

export const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <CheckoutStyled>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title className={'mobile-hidden'}>Checkout</Title>
        <MobileTitle>
          <Title>Checkout</Title>
          <Steps>
            <Step isActive={currentStep === 1}>
              <span>1</span>
              <div>Billing Details</div>
            </Step>
            <Step isActive={currentStep === 2}>
              <span>2</span>
              <div>Delivery</div>
            </Step>
            <Step isActive={currentStep === 3}>
              <span>3</span>
              <div>Make Payment</div>
            </Step>
          </Steps>
        </MobileTitle>
        <Wrapper>
          <Col isVisible={currentStep === 1}>
            <Card>
              <CardTitle>
                <span>1</span>
                Billing Details
              </CardTitle>
              <BillingDetails />
            </Card>
          </Col>
          <Col isVisible={currentStep === 2}>
            <Card>
              <CardTitle>
                <span>2</span>
                Delivery
              </CardTitle>
              <Delivery />
            </Card>
          </Col>
          <Col isVisible={currentStep === 3}>
            <Card>
              <CardTitle>
                <span>3</span>
                Make Payment
              </CardTitle>
              <Payment />
            </Card>
          </Col>
        </Wrapper>
        <MobileBtns>
          <StepOneBtn isVisible={currentStep === 1}>
            <GlobalButton
              type='primary'
              onClick={() => {
                setCurrentStep(2);
              }}
            >
              Next: Billing Details <ChevronRight />
            </GlobalButton>
          </StepOneBtn>
          <StepTwoBtn isVisible={currentStep === 2}>
            <GlobalButton
              type='orange-secondary'
              onClick={() => {
                setCurrentStep(1);
              }}
            >
              <ChevronLeft />
              Back: Billing Details
            </GlobalButton>
            <GlobalButton
              type='primary'
              onClick={() => {
                setCurrentStep(3);
              }}
            >
              Next: Make Payment <ChevronRight />
            </GlobalButton>
          </StepTwoBtn>
        </MobileBtns>
      </Container>
    </CheckoutStyled>
  );
};
