import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const MainStyled = styled.section`
  max-width: 774px;
  width: 774px;

  @media ${device.laptop} {
    max-width: 935px;
    width: 935px;
  }
  @media ${device.tablet} {
  }
  @media ${device.mobile} {
    max-width: 100%;
    max-width: 100%;
  }
`;

export const Box = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 25px;
  margin-bottom: 30px;
  @media ${device.mobile} {
    box-shadow: 0px 0px 0px rgb(62 73 59 / 6%);
    border-radius: 0;
    padding: 0;
    margin-bottom: 30px;
  }
`;

export const Information = styled.div``;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
`;

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border: 1px solid #e2e5eb;
  border-radius: 50%;
  object-fit: cover;
`;

export const Block = styled.div`
  margin-left: 10px;
`;

export const Title = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
`;

export const Data = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #acadb9;
  margin-top: 2px;
`;

export const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  margin-top: 14px;
`;

export const Items = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const Item = styled.div`
  background: #f9fcf9;
  border: 1px solid #bdd7bd;
  border-radius: 20px;
  padding: 3px 15px;
  display: inline-flex;
  color: #29263a;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const Ico = styled.img`
  margin-right: 5px;
`;

export const GalleryBox = styled.div`
  @media ${device.laptop} {
    width: 710px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  > button {
    margin-right: 12px;
  }
`;
