import styled, { css } from 'styled-components';
import { title30 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  margin-bottom: 80px;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid var(--main-gray-2);
  margin-bottom: 30px;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  ${title30};
`;

export const Main = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Products = styled.div`
  flex-basis: 640px;
  padding-right: 32px;
  max-height: 915px;
  overflow-y: auto;
  > button {
    margin: 32px auto 0 auto;
    display: flex;
  }
  @media ${device.tablet} {
    flex-basis: 315px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
    padding-right: 0;
  }
`;

export const Map = styled.div`
  flex-basis: calc(100% - 640px);
  @media ${device.tablet} {
    flex-basis: calc(100% - 315px);
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const ProductsTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const SelectWithLabelWrapper = styled.div`
  flex-basis: 240px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  ${(props) =>
    props.isListView &&
    css`
      grid-template-columns: 1fr;
    `}
`;
