import ClothingApparel from './hats/hats';
import { MainStyled } from './main-styled';

const Main = ({ onFilterBtnClick }) => {
  return (
    <MainStyled>
      <ClothingApparel onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;
