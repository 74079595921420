import React from 'react';

import { MainStyled } from './main-styled';

import Background from './components/background/background';
import Tags from './components/categories-tag-menu/categories-tag-menu';
import TradesAndServices from '../trades-and-services/trades-and-services';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { Container } from '../../../categories-motor-vehicles/components/main/main-styled';

export const slides = [
  {
    title: 'Red Carpet Looks',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 50% off'
  },
  {
    title: 'Red Carpet Looks',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 50% off'
  },
  {
    title: 'Red Carpet Looks',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 50% off'
  }
];

const dropdownName = 'Services';
const tags = ['Cleaning', 'Plumbing', 'Electrical', 'Carpentry', 'Electrical'];
const dropdownList = [
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  }
];

const Main = ({ onFilterBtnClick }) => {
  return (
    <MainStyled>
      <Container>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 2500 }}
          pagination={{ clickable: true }}
        >
          {slides.map((item) => {
            return (
              <SwiperSlide key={item.title}>
                <Background
                  title={item.title}
                  subtitle={item.subtitle}
                  buttonText={item.buttonText}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <Tags dropdownName={dropdownName} dropdownList={dropdownList} tagsList={tags} />
      <TradesAndServices onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;
