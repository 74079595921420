import {
  CloseIcon,
  CloseMobile,
  Container,
  Heading,
  SidebarWrapper,
  Title
} from './sidebar-styled';
import closeIcon from '../../assets/close-icon-sidebar.svg';

const Sidebar = ({ showSidebar, title, onClose, onCloseText, children, width, withoutHeading }) => {
  return (
    <>
      {showSidebar ? (
        <SidebarWrapper>
          <Container width={width} withoutHeading={withoutHeading}>
            {!withoutHeading ? (
              <Heading>
                <CloseMobile onClick={onClose}>
                  <svg
                    width='8'
                    height='12'
                    viewBox='0 0 8 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.5 11L1.5 6L6.5 1'
                      stroke='#388638'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <p>{onCloseText}</p>
                </CloseMobile>
                <Title>{title}</Title>
                <CloseIcon src={closeIcon} onClick={onClose} />
              </Heading>
            ) : (
              ''
            )}
            {children}
          </Container>
        </SidebarWrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default Sidebar;
