import React from 'react';
// import { Outlet } from 'react-router-dom'
import { Outlet } from 'react-router';

import { Footer } from '../footer/footer';
import { GlobalStyle } from '../global-style/global-style';

function App() {
  return (
    <>
      <GlobalStyle />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
