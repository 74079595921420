import {
  Buttons,
  CheckboxList,
  Container,
  FilterSection,
  FilterSectionTitle,
  FilterStyled,
  FilterTop,
  IconWrapper,
  Menu,
  MobileCloseBtn,
  ResetBtn,
  HideResultBtn,
  Title,
  CategoriesWrapper,
  Label,
  BreadcrumbsWrapper,
  TopBtnWrapper,
  LinksList
} from './filter-styled';
import { useState } from 'react';
import Checkbox from 'components/checkbox/checkbox';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { GlobalButton } from 'components/global-button/global-button';
import { CategoriesItem, Selector } from '../../../categories/components/filters/filters-styled';
import arrowUp from '../../../../assets/arrow_up.svg';
import { SELECTORS } from '../../../categories/components/filters/filters';
import PriceSelector from '../../../../components/price-selector/price-selector';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { FilterSectionC } from '../filter-section/filter-section';
import { Link } from 'react-router-dom';

export const CloseBtn = ({ onClick }) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M21 7L7 21M7 7L21 21'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const LINKS = [
  {
    name: 'Show all',
    qty: 1246
  },
  {
    name: 'Erotic Clothing',
    qty: 98
  },
  {
    name: 'Others Erotic Clothing',
    qty: 146
  },
  {
    name: 'Handbags & Accessories',
    qty: 321
  },
  {
    name: 'Women’s Handbags',
    qty: 235
  },
  {
    name: 'Handbag  Accessories',
    qty: 11
  },
  {
    name: 'Men’s Fashion',
    qty: 96
  },
  {
    name: 'Others Men’s Clothing',
    qty: 127
  },
  {
    name: 'Shoes & Boots',
    qty: 203
  }
];

export const Filter = ({ isMobileFilterVisible, setIsMobileFilterVisible }) => {
  const [isShopChecked, setIsShopChecked] = useState(false);
  const [isProductChecked, setIsProductChecked] = useState(false);
  const [isAllTypesChecked, setIsAllTypesChecked] = useState(false);
  const [isSingleProductsChecked, setIsSingleProductsChecked] = useState(false);
  const [isActionsChecked, setIsActionsChecked] = useState(false);
  const [searchRadius, setSearchRadius] = useState({ min: 0, max: 1000 });
  const [minimumPrice, setMinimumPrice] = useState({ min: 0, max: 1000 });

  return (
    <Container isMobileFilterVisible={isMobileFilterVisible}>
      <FilterStyled isMobileFilterVisible={isMobileFilterVisible}>
        <FilterTop>
          <Title>Filters</Title>
          <IconWrapper>
            <SvgIcon name={'filter'} />
          </IconWrapper>
          <MobileCloseBtn>
            <CloseBtn
              onClick={() => {
                setIsMobileFilterVisible(false);
              }}
            />
          </MobileCloseBtn>
        </FilterTop>
        <Menu>
          <FilterSectionC name={'Categories'}>
            <LinksList>
              {LINKS.map((link, key) => {
                return (
                  <Link key={key} to={'#'}>
                    {link.name} <span>{link.qty}</span>
                  </Link>
                );
              })}
            </LinksList>
          </FilterSectionC>
          <FilterSectionC name={'Product Type:'}>
            <CheckboxList>
              <Checkbox
                id={'all_types'}
                onChange={() => {
                  setIsAllTypesChecked(!isAllTypesChecked);
                }}
                label={'All Types'}
                checked={isAllTypesChecked}
              />
              <Checkbox
                id={'single_product'}
                onChange={() => {
                  setIsSingleProductsChecked(!isSingleProductsChecked);
                }}
                label={'Single Products'}
                checked={isSingleProductsChecked}
              />
              <Checkbox
                id={'actions'}
                onChange={() => {
                  setIsActionsChecked(!isActionsChecked);
                }}
                label={'Actions'}
                checked={isActionsChecked}
              />
            </CheckboxList>
          </FilterSectionC>

          <FilterSection>
            <Label>Search Radius</Label>
            <PriceSelector
              km
              defaultMin={0}
              defaultMax={1000}
              min={searchRadius.min}
              max={searchRadius.max}
              onChange={({ min, max }) => setSearchRadius({ min: min, max: max })}
              values={searchRadius}
              inputsTop={false}
            />
          </FilterSection>
          <FilterSection>
            <Label>Minimum Price</Label>
            <PriceSelector
              defaultMin={0}
              defaultMax={1000}
              min={minimumPrice.min}
              max={minimumPrice.max}
              onChange={({ min, max }) => setMinimumPrice({ min: min, max: max })}
              values={minimumPrice}
              inputsTop={false}
            />
          </FilterSection>
        </Menu>
      </FilterStyled>
    </Container>
  );
};
