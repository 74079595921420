import styled, { css } from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';
import { paragraph30 } from '../../../../../assets/style-helpers/typography';

export const MotorVehiclesStyled = styled.section`
  display: block;

  @media ${device.tablet} {
    margin-top: 28px;
  }

  @media ${device.mobile} {
    margin-top: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 25px;
  @media ${device.tablet} {
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
  }
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const View = styled.div`
  display: flex;
  align-items: center;
`;

export const GridButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 8px;
  background-color: var(--main-green);
  margin-right: 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
`;

export const GridButton = styled.button`
  ${GridButtonStyle};
  @media ${device.tablet} {
    display: none;
  }
`;

export const GridButtonTablet = styled.button`
  display: none;
  @media ${device.tablet} {
    ${GridButtonStyle};
    background-color: unset;
    margin-right: 20px;
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const ListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: transparent;
  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const TradesNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${device.tablet} {
    margin-bottom: 20px;
    > section {
      width: 100%;
      margin-right: 33px;
      input {
        width: 100% !important;
      }
    }
  }
  @media ${device.mobile} {
    display: none;
  }
  > section {
    @media ${device.laptop} {
      width: 560px;
    }
    @media ${device.tablet} {
      width: 486px;
    }
  }
`;

export const TradesNavMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
`;

export const ButtonMobile = styled.div`
  display: none;
  align-items: center;
  svg {
    margin-right: 11px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
  }
  @media ${device.tablet} {
    display: flex;
  }
`;

export const FilterButton = styled.button`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;
  @media ${device.tablet} {
    display: flex;
    width: unset;
    font-size: 14px;
    padding: 8px 28px;
    margin-bottom: unset;
    margin-right: 22px;

    img {
      margin-right: 11px;
    }
  }
  @media ${device.mobile} {
    width: unset;
    font-size: 14px;
    padding: 11px 26px;
    margin-bottom: unset;

    img {
      margin-right: 11px;
    }
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 30px 0;
  @media ${device.tablet} {
    margin: 17px 0 17px 0;
  }
`;

export const TypesSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const TagsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ClearAll = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #676a75;
  margin-left: 14px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
`;

export const Tag = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 4px 16px 4px 14px;
  border: ${(props) => (props.index === 0 ? '1px solid #388638' : '1px solid #dcdcdc')};
  background: ${(props) => (props.index === 0 ? '#ECF6EC' : '')};
  border-radius: 20px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
`;

export const CardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 31px;
  margin-top: 32px;
  @media ${device.mobile} {
    margin-top: 15px;
  }
  > div {
    min-width: 295px;
    max-width: 295px;
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    place-items: center;
    margin-top: 15px;
    > div {
      min-width: 345px;
      max-width: 345px;
      height: 100%;
    }
  }
  @media ${device.mobile} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    > div {
      min-width: 345px;
      max-width: 345px;
      margin-bottom: 16px;
    }
  }
`;

export const LandScapeCardSection = styled.div`
  display: block;
  margin-top: 30px;
  > div {
    margin-bottom: 30px;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const ControlsMobile = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const TopSectionMobile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  > button {
    margin-bottom: unset;
  }
`;

export const ControlsSecond = styled.div`
  display: flex;
  align-items: center;
  right: 3px;
  top: 3px;
  border: 1px solid #e2e5eb;
  border-radius: 6px;
  padding: 2px;
  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const SortBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  background: #f5f7fb;
  border-radius: 6px;
  margin-right: 19px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #acadb9;
`;

export const ResultsPerPage = styled.div`
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    > p {
      color: var(--main-subtext);
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }

    > span {
      display: flex;
      align-items: center;
      color: var(--main-text);
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      cursor: pointer;

      > svg {
        margin-left: 6px;
      }
    }
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
