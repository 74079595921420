import React from 'react';

import Background from './background/background';
import FoodAndGrocery from './food-and-grocery/food-and-grocery';
import {
  Container,
  MainStyled
} from '../../../categories-motor-vehicles/components/main/main-styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import Tags from '../../../categories/components/main/components/categories-tag-menu/categories-tag-menu';

export const slides = [
  {
    title: 'Healthy Vegetables',
    subtitle: 'Limited Time Offer',
    buttonText: '30 % Save'
  },
  {
    title: 'Healthy Vegetables',
    subtitle: 'Limited Time Offer',
    buttonText: '50 % Save'
  },
  {
    title: 'Healthy Vegetables',
    subtitle: 'Offer',
    buttonText: '30 % Save'
  }
];

const dropdownName = 'Groceries';
const tags = ['Cleaning', 'Plumbing', 'Electrical', 'Carpentry', 'Electrical'];
const dropdownList = [
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  },
  {
    title: 'Breakfast',
    list: ['Breakfast', 'Breakfast', 'Breakfast', 'Breakfast']
  }
];

const Main = ({ onFilterBtnClick }) => {
  return (
    <MainStyled>
      <Container>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 2500 }}
          pagination={{ clickable: true }}
        >
          {slides.map((item) => {
            return (
              <SwiperSlide key={item.title}>
                <Background
                  title={item.title}
                  subtitle={item.subtitle}
                  buttonText={item.buttonText}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <Tags dropdownName={dropdownName} dropdownList={dropdownList} tagsList={tags} />

      <FoodAndGrocery onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;
