import {
  CheckboxWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';

export const ProductInventory = () => {
  return (
    <Section>
      <SectionTitle>Inventory</SectionTitle>
      <InputGroup label={'stock quantity'}>
        <GlobalInput placeholder={'Write the stock quantity'} />
      </InputGroup>
      <InputGroup>
        <CheckboxWrapper>
          <GlobalCheckbox>Allow back-orders when stock is depleted</GlobalCheckbox>
        </CheckboxWrapper>
      </InputGroup>
    </Section>
  );
};
