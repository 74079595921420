import styled from 'styled-components';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  padding-bottom: 80px;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const Title = styled.div`
  ${title30};
`;
