import { useState } from 'react';
import Select from 'react-select';

import { Radio } from 'components/radio/radio';

import { Container, FilterItem, RadiosWrapper } from './filter-styled';
import {
  customDropdownIndicator,
  selectCustomStyle
} from 'assets/style-helpers/react-select-styles';

const typeOptions = [
  { value: 'Option 1', label: 'Option 1' },
  { value: 'Option 2', label: 'Option 2' },
  { value: 'Option 3', label: 'Option 3' }
];

export const Filter = () => {
  const [filterFormData, setFilterFormData] = useState({
    type: '',
    numberOfBedrooms: '',
    city: '',
    type2: 'to-let'
  });
  return (
    <Container>
      <FilterItem>
        <Select
          placeholder={'Type'}
          styles={selectCustomStyle}
          options={typeOptions}
          components={{ DropdownIndicator: customDropdownIndicator }}
          onChange={(e) => {
            console.log(e);
          }}
        />
      </FilterItem>
      <FilterItem>
        <Select
          placeholder={'Type'}
          styles={selectCustomStyle}
          options={typeOptions}
          components={{ DropdownIndicator: customDropdownIndicator }}
        />
      </FilterItem>
      <FilterItem>
        <Select
          placeholder={'Type'}
          styles={selectCustomStyle}
          options={typeOptions}
          components={{ DropdownIndicator: customDropdownIndicator }}
        />
      </FilterItem>
      <FilterItem>
        <RadiosWrapper>
          <Radio
            id={'to-let'}
            name={'type2'}
            value={'to-let'}
            title={'To Let'}
            cb={(e) => {
              setFilterFormData({ ...filterFormData, type2: e.target.value });
            }}
            isChecked={filterFormData.type2 === 'to-let'}
          />
          <Radio
            id={'for-sale'}
            name={'type2'}
            value={'for-sale'}
            title={'For Sale'}
            cb={(e) => {
              setFilterFormData({ ...filterFormData, type2: e.target.value });
            }}
            isChecked={filterFormData.type2 === 'for-sale'}
          />
        </RadiosWrapper>
      </FilterItem>
    </Container>
  );
};
