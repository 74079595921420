import styled from 'styled-components';
import { LatoFont, scrollbarStyles } from 'assets/style-helpers/style-helpers';
import { Link } from 'react-router-dom';
import { device } from 'assets/style-helpers/breakpoints';

export const Container = styled.div`
  min-width: 0;
  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  padding: 22px 8px 22px 22px;
  @media ${device.laptop} {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    width: 290px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const UserInfo = styled.div`
  max-width: 100%;
  margin-bottom: 24px;

  button {
    font-size: 12px;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
`;

export const Avatar = styled.div`
  margin-right: 12px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const UserMain = styled.div`
  min-width: 0;
`;

export const BusinessName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;

  text-overflow: ellipsis;
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  ${LatoFont};
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
  img {
    margin-right: 6px;
  }
`;

export const MenuWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
  ${scrollbarStyles};
  padding-right: 8px;
`;

export const MenuItem = styled.div`
  padding-top: 16px;
  border-top: 1px solid var(--divider);
  margin-top: 16px;
`;

export const MenuTitle = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const Submenu = styled.div`
  padding-left: 12px;
  ${LatoFont};
`;

export const SubmenuLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  & + & {
    margin-top: 8px;
  }
`;

export const CloseDashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 24px;
  }
`;

export const Logout = styled.div`
  color: #acadb9;
  font-size: 14px;
  font-style: normal;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
`;
