import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { useNavigate } from 'react-router';
import { Container, Top, Title, NotificationsCounter, Main } from './dashboard-widget-styled';

export const DashboardWidget = ({ title, notifications, link, children }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Top>
        <Title>
          {title}
          <NotificationsCounter>{notifications}</NotificationsCounter>
        </Title>
        <GlobalButton
          type={'orange-secondary'}
          onClick={() => {
            navigate(link);
          }}
        >
          <SvgIcon name={'eye'} />
          See all
        </GlobalButton>
      </Top>
      <Main>{children}</Main>
    </Container>
  );
};
