import styled from 'styled-components';
import { LatoFont, PoppinsFont } from 'assets/style-helpers/style-helpers';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  background: #f4f4f4;
  border-radius: 12px;
  padding: 12px;
  display: flex;
`;

export const ImgWrapper = styled.div`
  width: 65px;
  height: 65px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 16px;
`;

export const Title = styled.div`
  ${PoppinsFont};
  font-weight: 600;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--main-subtext);
`;
