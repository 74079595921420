import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './delivery-partners.scss';
import 'swiper/css';

import {
  DeliveryPartnersStyled,
  Title,
  HorizontalLine,
  Images,
  ImagesMobile,
  ImageMobile,
  FadeOutContainer
} from './delivery-partners-styled';

import zimbabwean from '../../../../assets/zimbabwean.png';
import fedex from '../../../../assets/fedex.png';
import dhl from '../../../../assets/dhl.png';
import zimpost from '../../../../assets/zimpost.png';

const PARTNERS = [
  {
    img: zimbabwean
  },
  {
    img: fedex
  },
  {
    img: dhl
  },
  {
    img: zimpost
  }
];

const DeliveryPartners = () => {
  return (
    <DeliveryPartnersStyled>
      <Title>Our Delivery Partners</Title>
      <HorizontalLine />
      <Images className='images'>
        {PARTNERS.map((item, index) => {
          return <img src={item.img} key={index} alt='' />;
        })}
      </Images>
      <ImagesMobile>
        <FadeOutContainer>
          <Swiper loop={true} slidesPerView={2.3} spaceBetween={10}>
            {PARTNERS.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <ImageMobile src={item.img} alt='' />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </FadeOutContainer>
      </ImagesMobile>
    </DeliveryPartnersStyled>
  );
};

export default DeliveryPartners;
