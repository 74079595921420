import { useState } from 'react';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import Lists from '../all-appointments/components/lists/lists';
import { Container, Main, Title, ViewService } from './all-appointments-styled.jsx';
import Sidebar from '../../../../components/sidebar/sidebar';
import {
  ButtonsWrapper,
  ContactBox,
  ContactName,
  ContactText,
  ContactTitle,
  Content,
  DataText,
  DataTitle,
  Information,
  InformationContact,
  InformationData,
  InputGroupWrapper,
  Message,
  MessageContent,
  MessageTitle,
  Section,
  SectionBox,
  Textarea
} from '../../../profile/components/sidebars/sidebar-respond/respond-styled';
import { ReactComponent as ApplyChanges } from '../../../../assets/apply-changes.svg';
import { ReactComponent as Calendar } from '../../../../assets/calendar-respond.svg';
import { ReactComponent as Clock } from '../../../../assets/clock-respond.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { InputGroup } from '../../../../components/input-group/input-group';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Appointments' },
  { link: '#', name: 'All Appointments' }
];

export const AllAppointments = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Container>
        <Main>
          <Breadcrumbs links={breadcrumbs} />
          <Title>All Appointments</Title>
          <Lists onRespondClick={() => setShowSidebar(true)} />
          <Sidebar
            showSidebar={showSidebar}
            onClose={() => setShowSidebar(false)}
            width={'710px'}
            withoutHeading
          >
            <Main>
              <Information>
                <InformationData>
                  <DataTitle>Tuesday</DataTitle>
                  <DataText>
                    <Calendar /> 07 Jul 2022
                  </DataText>
                  <DataText>
                    <Clock /> 09:00
                  </DataText>
                </InformationData>
                <InformationContact>
                  <ContactTitle>Langton Shoriwa</ContactTitle>
                  <ContactBox>
                    <ContactName>Phone:</ContactName>
                    <ContactText>+263 71 77 562 256</ContactText>
                  </ContactBox>
                  <ContactBox>
                    <ContactName>Email:</ContactName>
                    <ContactText>myemail@gmail.com</ContactText>
                  </ContactBox>
                </InformationContact>
              </Information>
              <Title>
                Company Registration
                <ViewService>View Service</ViewService>
              </Title>
              <Message>
                <MessageTitle>Message</MessageTitle>
                <MessageContent>
                  <Content>Hi!</Content>
                  <Content>
                    Lorem ipsum dolor sit amet consectetur. Elementum eu tortor nulla enim et turpis
                    purus. Cursus lorem vestibulum diam nulla ut donec. Enim vitae neque
                    pellentesque sit nibh ut egestas. Viverra tellus tellus congue scelerisque
                    fermentum ipsum commodo.
                  </Content>
                  <Content>
                    Viverra tellus tellus congue scelerisque fermentum ipsum commodo.
                  </Content>
                </MessageContent>
              </Message>
              <Section>
                <InputGroupWrapper>
                  <InputGroup label={'Response message'}>
                    <Textarea placeholder={'Write your response here'} />
                  </InputGroup>
                </InputGroupWrapper>
              </Section>
              <Section>
                <InputGroupWrapper>
                  <InputGroup label={'Appointment status'}>
                    <GlobalSelect options={selectOptions} />
                  </InputGroup>
                </InputGroupWrapper>
              </Section>
              <Section>
                <SectionBox>
                  <InputGroup label={'Date'}>
                    <GlobalInput placeholder={'Date'} type='date' />
                  </InputGroup>
                  <InputGroup label={'Time'}>
                    <GlobalInput placeholder={'Time'} type='time' />
                  </InputGroup>
                </SectionBox>
              </Section>
              <ButtonsWrapper>
                <GlobalButton type='primary'>
                  <ApplyChanges />
                  Apply Changes
                </GlobalButton>
                <GlobalButton type='gray' onClick={() => setShowSidebar(false)}>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.5 7.5L12.5 12.5M12.5 7.5L7.5 12.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  Cancel
                </GlobalButton>
              </ButtonsWrapper>
            </Main>
          </Sidebar>
        </Main>
      </Container>
    </>
  );
};
