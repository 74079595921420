import { Link } from 'react-router-dom';
import { SvgIcon } from '../svg-icon/svg-icon';

import ecocash from './img/ecocash.svg';
import mastercard from './img/mastercard.svg';
import money from './img/money.svg';
import sw from './img/switch.svg';
import telecash from './img/telecash.svg';
import visa from './img/visa.svg';

import {
  Category,
  Container,
  Copyright,
  FooterTop,
  FooterWrapper,
  InputWrapper,
  List,
  ListTitle,
  ListWrapper,
  Menu,
  PayNowImgs,
  ScrollBtn,
  SubscribeSection,
  Subtitle,
  Title
} from './footer-styled';

const paynowImgList = [visa, mastercard, sw, ecocash, money, telecash];

const menuList = [
  {
    title: 'Books & Stationary',
    link: '#'
  },
  {
    title: 'Clothing & Apparel',
    link: '#'
  },
  {
    title: 'Construction Hardware Supplies',
    link: '#'
  },
  {
    title: 'Electronic Good',
    link: '#'
  },
  {
    title: 'Farming Supplies',
    link: '#'
  },
  {
    title: 'Food & Groceries',
    link: '#'
  },
  {
    title: 'Health & Beauty',
    link: '#'
  },
  {
    title: 'CompanyPage & Garden',
    link: '#'
  },
  {
    title: 'Industrial Supplies',
    link: '#'
  },
  {
    title: 'Motor Spare Accessories',
    link: '#'
  },
  {
    title: 'Motor Vehicles',
    link: '#'
  },
  {
    title: 'Real Estate',
    link: '#'
  },
  {
    title: 'Sport Hobbies & Leisure',
    link: '#'
  },
  {
    title: 'Trades & Services',
    link: '#'
  }
];

const infoList = [
  {
    title: 'About Us',
    link: '#'
  },
  {
    title: 'Help & Support',
    link: '#'
  },
  {
    title: 'Contact Us',
    link: '#'
  },
  {
    title: 'Terms & Conditions',
    link: '#'
  }
];

export const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterTop>
          <ScrollBtn
            onClick={() => {
              scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }}
          >
            <SvgIcon name={'arrow-up'} />
          </ScrollBtn>
        </FooterTop>
        <SubscribeSection>
          <div>
            <Title>Get the latest, news and updates!</Title>
            <Subtitle>We’ll never share your email address with a third-party</Subtitle>
          </div>
          <InputWrapper>
            <input type='text' placeholder={'Enter your email...'} />
            <button>
              <SvgIcon name={'mail'} />
              <span>Subscribe</span>
            </button>
          </InputWrapper>
        </SubscribeSection>
        <Menu>
          <Category>
            <ListTitle>Category</ListTitle>
            <List>
              {menuList.map((item) => {
                return (
                  <Link to={item.link} key={item.title}>
                    {item.title}
                  </Link>
                );
              })}
            </List>
          </Category>
          <ListWrapper>
            <div>
              <ListTitle>Information</ListTitle>
              <List>
                {infoList.map((item) => {
                  return (
                    <Link to={item.link} key={item.title}>
                      {item.title}
                    </Link>
                  );
                })}
              </List>
            </div>
            <div>
              <ListTitle>Paynow</ListTitle>
              <PayNowImgs>
                {paynowImgList.map((img, index) => {
                  return <img src={img} alt={'paynow'} key={index} />;
                })}
              </PayNowImgs>
            </div>
          </ListWrapper>
        </Menu>
        <Copyright>© 2023 - Everything Zimbabwean</Copyright>
      </Container>
    </FooterWrapper>
  );
};
