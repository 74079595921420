import { BannersWrapper, Container, Divider, Main } from './side-banner-styled';
import { SponsoredBanners } from '../sponsored-banners/sponsored-banners';
import { MoreProductsBanners } from '../more-products-banners/more-products-banners';
import { AdSpace } from '../ad-space/ad-space';

export const SideBanner = () => {
  return (
    <Container>
      <Main>
        <MoreProductsBanners />
        <Divider />
        <BannersWrapper>
          <SponsoredBanners />
          <AdSpace />
        </BannersWrapper>
      </Main>
    </Container>
  );
};
