import { useState } from 'react';
import {
  HorizontalLine,
  IconWrapper,
  NotificationSection,
  NotificationsWrapper,
  Tag,
  Tags,
  Title,
  Wrapper,
  Text,
  HoursAgo,
  Container,
  SidebarButton
} from './notifications-sidebar-styled';
import selectedCheckmark from './selected-checkmark.svg';
import iconOne from './notification-icon-1.svg';
import iconTwo from './notification-icon-2.svg';
import clock from './clock.svg';
import notificationSidebar from './notification-sidebar-icon.svg';

export const TAGS = ['All', 'Unread'];
export const NotificationsSidebar = [
  {
    icon: iconOne,
    customerName: 'John Doe',
    made: 'made a price inquiry on your product:',
    product: '#12325 Nike Air Jordan With Cool Lanb',
    hoursAgo: 2
  },
  {
    icon: iconTwo,
    customerName: 'John Doe',
    made: 'has made an appointment request for:',
    product: '#12325 — Web Design Consultation With P',
    hoursAgo: 2
  },
  {
    icon: iconTwo,
    customerName: 'John Doe',
    made: 'has made an appointment request for:',
    product: '#12325 — Web Design Consultation With P',
    hoursAgo: 2
  }
];

export const NOTIFICATIONS_UNREAD = [
  {
    icon: iconTwo,
    customerName: 'John Doe',
    made: 'has made an appointment request for:',
    product: '#12325 — Web Design Consultation With P',
    hoursAgo: 2
  }
];

const NotificationSidebar = ({ showSidebar, setShowSidebar }) => {
  const [tagIndex, setTagIndex] = useState(0);
  return (
    showSidebar && (
      <NotificationsWrapper>
        <Container>
          <SidebarButton src={notificationSidebar} onClick={() => setShowSidebar(false)} alt='' />
          <Title>Notifications</Title>
          <HorizontalLine />
          <Tags>
            {TAGS.map((data, index) => {
              return (
                <Tag selected={index === tagIndex} key={index} onClick={() => setTagIndex(index)}>
                  {index === tagIndex ? <img src={selectedCheckmark} alt='' /> : ''}
                  {data}
                </Tag>
              );
            })}
          </Tags>
          <NotificationSection>
            {(tagIndex === 0 ? NotificationsSidebar : NOTIFICATIONS_UNREAD).map((data, index) => {
              return (
                <Wrapper key={index}>
                  <IconWrapper>
                    <img src={data.icon} alt='' />
                  </IconWrapper>
                  <Text>
                    <strong>{data.customerName}</strong> {data.made}{' '}
                    <strong
                      style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                      {data.product}
                    </strong>
                    <HoursAgo>
                      <img src={clock} alt='' />
                      {data.hoursAgo} hours ago
                    </HoursAgo>
                  </Text>
                </Wrapper>
              );
            })}
          </NotificationSection>
        </Container>
      </NotificationsWrapper>
    )
  );
};

export default NotificationSidebar;
