import {
  CardSection,
  CardSectionText,
  CardSectionTitle,
  CheckboxWrapper,
  InputGroupWrapper,
  InputGroupWrapperTwoRows,
  RadioWrapper
} from '../../checkout-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalInputSelect } from '../../../../components/global-input-select/global-input-select';
import { Radio } from '../../../../components/radio/radio';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import Checkbox from '../../../../components/checkbox/checkbox';
import { GlobalTextarea } from '../../../../components/global-textarea/global-textarea';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const Delivery = () => {
  return (
    <>
      <CardSection>
        <CardSectionText>
          Do you want your order doorstep or you would like to collect it from pick up point?
        </CardSectionText>
        <RadioWrapper>
          <Radio title={'Yes, please have them delivered to me!'} isChecked={true} />
          <Radio title={'I will come and collect from a pick up point'} isChecked={false} />
        </RadioWrapper>
        <InputGroupWrapper>
          <InputGroup label={'pick up location'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'delivery service'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
      <CardSection>
        <CardSectionTitle>Delivery Address:</CardSectionTitle>
        <CheckboxWrapper>
          <Checkbox label={'Use same the as address as my billing address'} checked />
        </CheckboxWrapper>
        <InputGroupWrapper>
          <InputGroup label={'street address'}>
            <GlobalInput placeholder={'Your street address'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'City'}>
            <GlobalInput placeholder={'Your city'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'Country'}>
            <GlobalInput placeholder={'Your country'} />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
      <CardSection>
        <CardSectionTitle>Contact Person:</CardSectionTitle>
        <InputGroupWrapper>
          <InputGroup label={'Full name'}>
            <GlobalInput placeholder={'Full name of the contact person'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'email'}>
            <GlobalInput placeholder={'Email of the contact person'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'Phone Number'}>
            <GlobalInputSelect placeholder={'Your phone number'} />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
      <CardSection>
        <CardSectionTitle>Preferred Delivery Day & Time:</CardSectionTitle>
        <InputGroupWrapperTwoRows>
          <InputGroup label={'delivery Day'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
          <InputGroup label={'delivery time'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
        </InputGroupWrapperTwoRows>
      </CardSection>
      <CardSection>
        <CardSectionTitle>Delivery Notes:</CardSectionTitle>
        <InputGroup label={'Notes'}>
          <GlobalTextarea placeholder={'Write delivery notes here'} />
        </InputGroup>
      </CardSection>
    </>
  );
};
