import styled from 'styled-components';

export const Container = styled.div`
  background: #136400;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Text = styled.div``;
