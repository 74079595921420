import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { linkWithUnderline } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

const card = css`
  background: var(--light-bg);
  border-radius: 12px;
`;

export const Container = styled.div`
  display: flex;
  margin-bottom: 106px;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const ProductsBanner = styled.div`
  ${card};
  padding: 24px 16px 30px;
  flex-basis: 608px;
  margin-right: 32px;
  @media ${device.tablet} {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

export const ProductsBannerTop = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductsBannerTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewShopLink = styled(Link)`
  ${linkWithUnderline};
  font-family: 'Lato', sans-serif;
`;

export const ProductsBannerTitleBadge = styled.div`
  background: var(--light-bg);
  border: 1px solid var(--main-green);
  border-radius: 4px;
  font-size: 11px;
  line-height: 24px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-green);
  margin-right: 12px;
`;

export const ProductsBannerCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  @media ${device.mobile} {
    grid-template-columns: 1fr 1fr;

    > div:last-child {
      display: none;
    }
  }
`;

export const SponsoredBanner = styled.div`
  flex-basis: calc(100% - 640px);
  ${card};
  padding: 24px 32px;
`;

export const SponsoredBannerTitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const SponsoredBannerContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const SponsoredBannersCards = styled.div``;

export const SponsoredBannerAdSpace = styled.div`
  background: #136400;
  border-radius: 12px;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.2);

  justify-content: center;
  margin-left: 40px;

  @media ${device.mobile} {
    height: 140px;
    margin-left: 0;
    margin-top: 32px;
  }
`;
