import { SectionHeader } from 'components/section-header/section-header';
import { ProductsSlider } from 'components/products-slider/products-slider';
import { Container, Wrapper } from './section-with-slider-styled';
import { PCard, ProductCardTypes } from '../../../../components/p-card/p-card';

const products = [
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '30K'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '30K'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '20K',
    badge: 'Sponsored'
  },
  {
    title: 'Master Duplex in Mutare City Centre',
    description: 'Samar Haute Plug | Norton',
    img: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ],
    rate: 3.7,
    reviews: 15,
    price: '30K'
  }
];

export const SectionWithSlider = ({ sectionTitle, link = '#' }) => {
  return (
    <Wrapper>
      <Container>
        <SectionHeader link={link} title={sectionTitle} seeAllMobile />
      </Container>
      <ProductsSlider>
        {products.map((product, index) => {
          return <PCard product={product} type={ProductCardTypes.ONLY_VIEW} key={index} />;
        })}
      </ProductsSlider>
    </Wrapper>
  );
};
