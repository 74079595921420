import React from 'react';

import {
  Dropdown,
  DropdownWrapper,
  List,
  Tag,
  TagsContainer,
  TagsStyled,
  TagsWrapper
} from './tags-styled';

import arrowUp from '../../../../../../assets/arrow_up-second.svg';
import Tippy from '@tippyjs/react/headless';
import { DropdownCategory } from './dropdown-category';

const CategoriesTagMenu = ({ dropdownName, dropdownList, tagsList }) => {
  return (
    <TagsStyled>
      <DropdownWrapper>
        <Tippy
          trigger={'click'}
          placement={'bottom-start'}
          hideOnClick={true}
          interactive={true}
          appendTo={'parent'}
          render={() => {
            return (
              <List>
                {dropdownList.map((item, i) => {
                  return <DropdownCategory title={item.title} subcategory={item.list} key={i} />;
                })}
              </List>
            );
          }}
        >
          <Dropdown>
            {dropdownName}
            <img src={arrowUp} alt='' />
          </Dropdown>
        </Tippy>
      </DropdownWrapper>
      <TagsContainer>
        <TagsWrapper>
          {tagsList.map((item, i) => (
            <Tag key={i}>{item}</Tag>
          ))}
        </TagsWrapper>
      </TagsContainer>
    </TagsStyled>
  );
};

export default CategoriesTagMenu;
