import { SponsoredBanners } from '../../single-product-general/components/sponsored-banners/sponsored-banners';
import { Container } from './auction-side-banner-styled';
import { AuctionWidget } from '../../../components/auction-widget/auction-widget';
import { BidHistoryWidget } from '../../../components/bid-history-widget/bid-history-widget';
import { AdSpace } from '../../single-product-general/components/ad-space/ad-space';
import { MoreProductsBanners } from '../../single-product-general/components/more-products-banners/more-products-banners';
import { Divider } from '../../single-product-general/components/side-banner/side-banner-styled';

export const AuctionSideBanner = () => {
  return (
    <Container>
      <AuctionWidget />
      <BidHistoryWidget />
      {/* <MoreProductsBanners />*/}
      <Divider />
      <SponsoredBanners />
      {/* <AdSpace />*/}
    </Container>
  );
};
