import { Container, ImgWrapper, Price, Title, Content } from './services-card-styled';

export const ServicesCard = ({ service }) => {
  return (
    <Container>
      <ImgWrapper>
        <img src={service.img} alt={service.title} />
      </ImgWrapper>
      <Content>
        <Title>{service.title}</Title>
        <Price>${service.price}</Price>
      </Content>
    </Container>
  );
};
