import React from 'react';
import { RateStars } from '../rate-stars/rate-stars';
import './p-card-slick.scss';

import {
  Badge,
  Buttons,
  BuyBtn,
  Content,
  Main,
  MainPrice,
  OldPrice,
  OnlyViewCardStyled,
  OnlyViewWrapper,
  Price,
  ContactsBox,
  SubTitle,
  MainBox,
  RateStarsDesktop,
  Title,
  ZWLPrice,
  Container,
  ContentTradesView
} from './p-card-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { ProductCardSlider } from '../product-card-slider/product-card-slider';

export const PCardOnlyViewView = ({ product, handleFavBtnClick, isFav, currentViewType }) => {
  return (
    <Container currentViewType={currentViewType}>
      <ProductCardSlider
        img={product.img}
        title={product.title}
        isFav={isFav}
        handleFavBtnClick={handleFavBtnClick}
        currentViewType={currentViewType}
      />
      <Main currentViewType={currentViewType}>
        <MainBox>
          <RateStarsDesktop>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Title currentViewType={currentViewType}>{product.title}</Title>
        {/* {product.description && <SubTitle>{product.description}</SubTitle>}*/}
        {product.location && (
          <SubTitle currentViewType={currentViewType}>{product.location}</SubTitle>
        )}

        <ContentTradesView currentViewType={currentViewType}>
          <Price currentViewType={currentViewType}>
            <OldPrice currentViewType={currentViewType}>{product.old_price}</OldPrice>
            <MainPrice currentViewType={currentViewType}>{product.price}</MainPrice>
            <ZWLPrice currentViewType={currentViewType}>ZWL 200</ZWLPrice>
          </Price>
          <Buttons>
            <OnlyViewWrapper>
              <BuyBtn>
                <SvgIcon name={'eye'} />
              </BuyBtn>
            </OnlyViewWrapper>
          </Buttons>
        </ContentTradesView>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </Container>
  );
};
