import styled from 'styled-components';
import { inputReset } from '../../assets/style-helpers/style-helpers';

export const BoxInput = styled.div`
  position: relative;
  height: 52px;
  border: 1px solid var(--main-gray-2);
  border-radius: 6px;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 14px 24px;
  transition: all 0.3s;
  color: var(--main-text);
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  &::placeholder {
    color: var(--main-subtext);
  }
  &:hover {
    border: 1px solid var(--main-gray);
  }
`;

export const CodeInput = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  border-right: 1px solid #e8e8e8;
  padding-right: 12.9px;

  > img {
    margin-left: 12px;
  }
`;

export const StyledInput = styled.input`
  ${inputReset};
  position: absolute;
  top: 2px;
  height: 48px;
  border-radius: 6px;
  width: 74%;
  padding-right: 10px;
  margin-left: 76px;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s;
  color: var(--main-text);
  font-family: 'Lato', sans-serif;
`;

export const Img = styled.img``;
