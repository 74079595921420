import styled from 'styled-components';
import { title30 } from '../../assets/style-helpers/typography';
import { buttonReset, LatoFont } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const ShopName = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-subtext);
  margin-bottom: 4px;
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 4px;
`;

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const ReviewsOrders = styled.div`
  display: flex;
  align-items: center;
`;

export const Reviews = styled.div`
  a {
    color: var(--main-green);
    font-size: 12px;
    line-height: 16px;
    margin-right: 6px;
    margin-left: 6px;

    transition: all 0.3s;
    &:hover {
      color: var(--main-green);
    }
  }
`;

export const Orders = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding-left: 6px;
  border-left: 1px solid var(--main-gray-2);
`;

export const MetaInfo = styled.div`
  margin-bottom: 12px;
`;

export const MataInfoItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const MataInfoItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin: 12px 0;
`;

export const GreenText = styled.div`
  color: var(--main-green);
  ${LatoFont};
`;

export const MetaInfoTitle = styled.div`
  color: var(--main-subtext);
  margin-right: 4px;
`;

export const MetaInfoText = styled.div`
  color: #5a5670;
  font-weight: 400;
`;

export const MetaInfoTextBig = styled.div`
  font-size: 20px;
  color: var(--main-text);
  line-height: 30px;
`;

export const Price = styled.div`
  ${title30};
  flex-shrink: 0;
`;

export const Stock = styled.div`
  color: var(--main-subtext);
  font-size: 12px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  font-family: 'Lato', sans-serif;
  margin-bottom: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  @media ${device.mobile} {
    > div {
      margin-top: 0;
    }
  }
`;

export const MainButtons = styled.div`
  display: flex;
  //justify-content: space-between;
  width: 100%;
  gap: 16px;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
  button {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media ${device.tablet} {
    display: flex;
    //button + button {
    //  margin-left: 8px;
    //}
  }
`;

export const MeniItem = styled.div`
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & + & {
    margin-top: 20px;
  }
`;

export const ShareBtn = styled.button`
  ${buttonReset};
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--main-green);
  transition: all 0.3s;
  &:hover {
    color: var(--main-green);
  }
  svg {
    margin-right: 8px;
  }
`;

export const TagsTitle = styled.div`
  font-weight: 600;
`;

export const TagsWrapper = styled.div`
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  background: #f9fcf9;
  border: 1px solid #bdd7bd;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  margin: 6px 4px 0;
  svg {
    margin-right: 6px;
  }
`;

export const MobileSection = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

export const Timer = styled.div`
  background: var(--main-orange);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: var(--main-white);
  margin-bottom: 8px;
  svg {
    margin-right: 12px;
  }
`;

export const TopTag = styled.div`
  background: var(--main-green);
  border-radius: 8px;
  padding: 15px 16px;
  color: var(--main-white);
  margin-bottom: 12px;
`;

export const TopTagShopName = styled.div`
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 4px;
`;

export const TopTagItemName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const PriceWithButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  gap: 16px;
  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
    ${Price} {
      margin-bottom: 16px;
    }
  }
`;

export const MobBtn = styled.div`
  display: none;
  margin-left: 16px;
  @media ${device.mobile} {
    display: block;
  }
`;

export const Status = styled.div`
  margin: 24px 0;
  ${LatoFont};
`;

export const OfferSent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const OfferTimer = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: var(--main-orange);
`;

export const OfferApproved = styled.div`
  color: var(--main-green);
  display: flex;
  gap: 8px;
  align-items: center;
  button {
    background: none;
    border: none;
    outline: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    ${LatoFont};
    cursor: pointer;
    line-height: 18px; /* 150% */
    text-decoration-line: underline;
  }
`;

export const OfferReject = styled.div`
  color: #cb3b2f;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const MobileContactSellerBtn = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    margin-top: 20px;
  }
`;
