import { useState } from 'react';
import { AddNewProductLayout } from '../../components/add-new-product-layout/add-new-product-layout';
import { ProductCategories } from '../../components/add-product-form-components/product-categories';
import { ProductImage } from '../../components/add-product-form-components/product-image';
import { ProductSubmitBtns } from '../../components/add-product-form-components/product-submit-btns';
import { ServiceDetails } from '../../components/add-product-form-components/service-details';
import { ProductPricing } from '../../components/add-product-form-components/product-pricing';
import { ServiceAttributes } from '../../components/add-product-form-components/service-attributes';
import { AdditionalServices } from '../../components/add-product-form-components/additional-services';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductSingleService = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <AddNewProductLayout
      isSidebarHidden={isSidebarHidden}
      setIsSidebarHidden={setIsSidebarHidden}
      pageTitle={'Add Service'}
    >
      <ServiceDetails />
      <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
      <ProductImage title={'Service Image'} />
      <ProductPricing selectOptions={selectOptions} />
      <ServiceAttributes selectOptions={selectOptions} />
      <AdditionalServices selectOptions={selectOptions} />
      <ProductSubmitBtns
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      />
    </AddNewProductLayout>
  );
};
