import {
  AtributesStyled,
  AttributesItem,
  AttributesItemText,
  AttributesItemTitle
} from './attributes-styled';

export const Attributes = () => {
  return (
    <AtributesStyled>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Brand: </AttributesItemTitle>
          Huawei
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Body:</AttributesItemTitle>
          153x67x8.3mm, 161g; Glass front (Gorilla Glass Victus), plastic back, plastic frame;
          IP65/IP68 dust/water resistant (up to 1.5m for 30 mins)
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Colour: </AttributesItemTitle>
          Red, Black, Gold, Matte Black
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Rear camera: </AttributesItemTitle>
          Wide (main): 12 MP, f/1.8, 27mm, 1/2.8", OIS, PDAF; Ultra wide angle: 8 MP, f/2.2, 120˚,
          16mm, 1/4.0"; Telephoto: 8 MP, f/2.2, 54mm, 1/4.4", PDAF, 2x optical zoom
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Front camera: </AttributesItemTitle>8 MP, f/2.0, 27mm (wide), 1/4.0"
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Battery: </AttributesItemTitle>
          5000mAh; Fast charging, Quick Charge, USB Power Delivery
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Display:</AttributesItemTitle>
          6.0" OLED, HDR, 1080x2520px resolution, 21:9 aspect ratio, 457ppi; Triluminos display
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>Memory: </AttributesItemTitle>
          128GB 6GB RAM; UFS; microSDXC (uses shared SIM slot)
        </AttributesItemText>
      </AttributesItem>
      <AttributesItem>
        <AttributesItemText>
          <AttributesItemTitle>OS/Software: </AttributesItemTitle>
          Android 12
        </AttributesItemText>
      </AttributesItem>
    </AtributesStyled>
  );
};
