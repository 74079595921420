import filter from '../../../../../../assets/filter.svg';
import Table from '../../../../../../components/table/table';
import { Container, FilterButton, LeftSide, MobileSearch, Navigation } from './lists-styled';
import { columnsData, columnsRows } from './mock';

const CustomerList = ({ onRespondClick }) => {
  return (
    <Container>
      <Navigation>
        <LeftSide></LeftSide>
      </Navigation>
      <Table
        columnsData={columnsData}
        columnsRows={columnsRows}
        hasCheckbox
        onClick={onRespondClick}
      />
    </Container>
  );
};

export default CustomerList;
