import styled from 'styled-components';

export const DropdownStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin-right: 2px;
  cursor: pointer;
  width: 91px;
  border: ${(props) => (props.withBorders ? '1px solid #E2E5EB' : '')};
  padding: ${(props) => (props.withBorders ? '4px 8px;' : '')};

  img {
    transform: rotate(180deg);
    position: absolute;
    right: 8px;
  }
`;

export const Name = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  margin-right: 60px;
`;
