import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import Orders from '../../components/orders/orders';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import { Order } from '../../components/sidebars/sidebar-order/order';
import {
  Background,
  BoxSiteBar,
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  SiteBar,
  Title
} from './order-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Orders' },
  { link: '#', name: 'My Orders' }
];

export const NewOrderProfile = () => {
  const [isSitebarOrder, setSitebarOrder] = useState(false);

  const newOrder = (status) => {
    setSitebarOrder(status);
    document.querySelector('body').style.overflow = 'hidden';
    scrollTo(0, 0);
    console.log(status);
  };

  const closeOrderForm = (status) => {
    setSitebarOrder(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>New Orders</Title>
      <Orders newOrder={newOrder} />
      {isSitebarOrder && (
        <>
          <BoxSiteBar>
            <SiteBar>
              <Order closeOrderForm={closeOrderForm} />
            </SiteBar>
          </BoxSiteBar>
          <Background />
        </>
      )}
    </>
  );
};
