import styled from 'styled-components';
import { inputReset, LatoFont } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  background: var(--light-bg);
  padding: 24px 20px 32px;
  border-radius: 12px;
  margin-bottom: 32px;
`;

export const LatestBid = styled.div`
  background: var(--main-orange);
  border-radius: 11px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
`;

export const LatestBidTitle = styled.div``;

export const LatestBidValue = styled.div``;

export const Timer = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const TimerTitle = styled.div`
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

export const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  position: relative;
  margin-bottom: 16px;
`;

export const TimerValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid var(--main-gray-2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

export const TimerName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1;
`;

export const TimerDivider = styled.div`
  margin: 0 8px;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-subtext);
  transform: translateY(-16px);
`;

export const TimerUntil = styled.div`
  text-align: center;
  color: var(--main-green);
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
`;

export const Form = styled.div`
  padding-top: 12px;
`;

export const Input = styled.input`
  ${inputReset};
  background: #ffffff;
  /* devider */

  border: 1px solid #e2e5eb;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: 'Lato', sans-serif;
  height: 48px;
  padding: 0 8px;
  text-align: center;
  width: 100%;
  font-weight: 400;
  &::placeholder {
    color: #acadb9;
  }
`;

export const InfoText = styled.div`
  margin-top: 12px;
  text-align: center;
  ${LatoFont};
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  .green {
    color: var(--main-green);
  }

  .orange {
    color: var(--main-orange);
  }
`;
