import Tippy from '@tippyjs/react/headless';
import { ArrowStyled, TippyBox, TippyBtn } from './tooltip-styled';
const Arrow = () => {
  return (
    <ArrowStyled data-popper-arrow=''>
      <svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.25259 0.335586C7.65038 -0.111928 8.34962 -0.111928 8.74741 0.335586L16 8.49475H0L7.25259 0.335586Z'
          fill='white'
        />
      </svg>
    </ArrowStyled>
  );
};

export const Tooltip = ({ children, placement }) => {
  return (
    <Tippy
      placement={placement}
      render={(attrs) => (
        <TippyBox tabIndex='-1' {...attrs}>
          {children}
          <Arrow data-popper-arrow='' id={'arrow'} className={'test'} />
        </TippyBox>
      )}
    >
      <TippyBtn type={'button'}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='8' cy='8' r='7.5' stroke='#DCDCDC' />
          <path
            d='M8.0132 5.33522C7.78586 5.33522 7.5952 5.25822 7.4412 5.10422C7.2872 4.95022 7.2102 4.75955 7.2102 4.53222C7.2102 4.30488 7.2872 4.11422 7.4412 3.96022C7.5952 3.80622 7.78586 3.72922 8.0132 3.72922C8.2332 3.72922 8.4202 3.80622 8.5742 3.96022C8.7282 4.11422 8.8052 4.30488 8.8052 4.53222C8.8052 4.75955 8.7282 4.95022 8.5742 5.10422C8.4202 5.25822 8.2332 5.33522 8.0132 5.33522ZM8.6292 6.13822V12.1992H7.3752V6.13822H8.6292Z'
            fill='#DCDCDC'
          />
        </svg>
      </TippyBtn>
    </Tippy>
  );
};
