import styled from 'styled-components';
import { GlobalButton } from '../../components/global-button/global-button';
import { LatoFont } from '../../assets/style-helpers/style-helpers';

export const ResetPasswordForm = styled.form`
  width: 100%;
  max-width: 480px;
`;

export const SendBtn = styled(GlobalButton)`
  margin-top: 24px;
`;

export const Description = styled.div`
  color: #b6b7c1;
  ${LatoFont};
  max-width: 380px;
  text-align: center;
  margin: 0 auto 24px;
`;
