import { GlobalButton } from '../../../../components/global-button/global-button';
import { PromocodeInputStyled } from './promocode-input-styled';

export const PromocodeInput = () => {
  return (
    <PromocodeInputStyled>
      <input type={'text'} placeholder={'Promocode'} />
      <GlobalButton type='orange' height={34}>
        Apply
      </GlobalButton>
    </PromocodeInputStyled>
  );
};
