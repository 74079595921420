import { Breadcrumbs } from '../../../../../../components/breadcrumbs/breadcrumbs';
import { GlobalSelect } from '../../../../../../components/global-select/global-select';
import {
  CategoriesBreadcrumbsWrapper,
  CategoriesSectionSelectsWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-form-styled.jsx';

export const ProductCategories = ({ selectOptions, breadcrumbs }) => {
  return (
    <Section>
      <SectionTitle>Categories</SectionTitle>
      <CategoriesSectionSelectsWrapper>
        <GlobalSelect options={selectOptions} />
        <GlobalSelect options={selectOptions} />
        <GlobalSelect options={selectOptions} />
      </CategoriesSectionSelectsWrapper>
      <CategoriesBreadcrumbsWrapper>
        <span>E.g.</span>
        <Breadcrumbs links={breadcrumbs} />
      </CategoriesBreadcrumbsWrapper>
    </Section>
  );
};
