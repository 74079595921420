export const WhatsupIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 21L4.27613 16.3822C3.49202 15.0275 3.08025 13.4911 3.08226 11.9277C3.07318 7.00094 7.09228 3 12.0318 3C14.4291 3 16.6801 3.92526 18.375 5.61306C19.2105 6.44039 19.8726 7.42454 20.3232 8.50848C20.7738 9.59243 21.0038 10.7546 21 11.9277C21 16.8448 16.9809 20.8458 12.0408 20.8458C10.5371 20.8458 9.06978 20.4736 7.75732 19.7573L3 21ZM7.96725 18.1514L8.24075 18.3146C9.3887 18.9908 10.6979 19.348 12.0318 19.3489C16.1331 19.3489 19.4781 16.0196 19.4781 11.9362C19.4781 9.95858 18.7036 8.08998 17.2998 6.69306C16.6094 6.00198 15.7886 5.45373 14.8844 5.07991C13.9803 4.70609 13.0108 4.51409 12.0318 4.51498C7.92131 4.51551 4.57741 7.84484 4.57741 11.9282C4.57741 13.3251 4.96949 14.6955 5.71679 15.8749L5.88986 16.1562L5.13348 18.8958L7.96779 18.1519L7.96725 18.1514Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.79892 8.18996C9.63493 7.81773 9.45278 7.80869 9.29787 7.80869C9.16113 7.79965 9.01476 7.79964 8.85985 7.79964C8.71456 7.79964 8.46831 7.85388 8.25891 8.08095C8.04898 8.30747 7.47528 8.84295 7.47528 9.9405C7.47528 11.0386 8.27707 12.1 8.38658 12.2451C8.49555 12.3903 9.93567 14.7125 12.2144 15.6112C14.1097 16.3551 14.4927 16.2099 14.9029 16.1642C15.3126 16.119 16.2245 15.6292 16.4157 15.1028C16.5979 14.5859 16.5979 14.1323 16.5434 14.0414C16.4889 13.951 16.3334 13.8962 16.115 13.7782C15.8869 13.6697 14.7934 13.1252 14.584 13.0523C14.3741 12.98 14.2283 12.9439 14.0824 13.1614C13.9366 13.3884 13.5082 13.8872 13.372 14.0324C13.2438 14.1775 13.1076 14.1956 12.8886 14.0866C12.661 13.9781 11.941 13.742 11.0841 12.98C10.4186 12.3898 9.972 11.6554 9.83525 11.4379C9.70812 11.2109 9.81709 11.0928 9.93567 10.9838C10.0361 10.8844 10.1638 10.7211 10.2733 10.594C10.3822 10.4664 10.4186 10.367 10.5008 10.2218C10.5735 10.0766 10.5371 9.9405 10.4827 9.83202C10.4276 9.73205 9.99977 8.63397 9.79892 8.18942V8.18996Z'
        fill='currentColor'
      />
    </svg>
  );
};
