import { useRef, useState, useEffect } from 'react';
import { CategoriesWrapper, CategoryLink } from './categories-styled';

export const LINKS = [
  {
    title: 'Books & Stationary',
    link: '#'
  },
  {
    title: 'Clothing & Apparel',
    link: '#'
  },
  {
    title: 'Construction & Hardware Supplies',
    link: '#'
  },
  {
    title: 'Electronic Goods',
    link: '#'
  },
  {
    title: 'Food & Groceries',
    link: '#'
  },
  {
    title: 'Health & Beauty',
    link: '#'
  },
  {
    title: 'Home & Garden',
    link: '#'
  },
  {
    title: 'Motor Spare & Accessories',
    link: '#'
  },
  {
    title: 'Motor Vehicles',
    link: '#'
  },
  {
    title: 'Real Estate',
    link: '#'
  },
  {
    title: 'Sports Hobbies & Leisure',
    link: '#'
  },
  {
    title: 'Trades & Services',
    link: '#'
  }
];

const Categories = ({ isOpen }) => {
  const ref = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  useEffect(() => {
    if (ref && ref.current) {
      setWrapperHeight((ref.current.clientHeight + 14.5) * LINKS.length + 40);
    }
  }, [ref]);
  return (
    <CategoriesWrapper height={wrapperHeight} isOpen={isOpen}>
      {LINKS.map((data, index) => {
        return (
          <CategoryLink ref={ref} href={data.link} key={index}>
            {data.title}
          </CategoryLink>
        );
      })}
    </CategoriesWrapper>
  );
};

export default Categories;
