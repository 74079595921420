import { SectionHeader } from 'components/section-header/section-header';
import { Container, Wrapper } from './food-and-groceries-styled';
import { PCard, ProductCardTypes } from 'components/p-card/p-card';
import React from 'react';
import { ProductsSlider } from 'components/products-slider/products-slider';
import { foodProductList } from '../../../../dummy-data/foodProductList';

export const FoodAndGroceries = () => {
  return (
    <Wrapper>
      <Container>
        <SectionHeader link='#' title={'Food and Groceries'} seeAllMobile />
      </Container>
      <ProductsSlider>
        {foodProductList.map((product, index) => {
          return <PCard product={product} type={ProductCardTypes.GROCERIES} key={index} />;
        })}
      </ProductsSlider>
    </Wrapper>
  );
};
