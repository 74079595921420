import { device } from 'assets/style-helpers/breakpoints';
import { buttonReset } from 'assets/style-helpers/style-helpers';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: rgba(31, 49, 36, 0.4);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  @media ${device.mobile} {
    padding: 0 16px;
    ${(props) =>
      props.alignUnset
        ? css`
            align-items: unset;
          `
        : css`
            align-items: center;
          `}
  }

  ${(props) =>
    props.isVisible
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

export const Wrapper = styled.div`
  margin: 32px 0;
  @media ${device.mobile} {
    margin: 0;
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  ${buttonReset};
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const Inner = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  ${(props) =>
    props.withoutPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: 32px;
        `}
  position: relative;
  @media ${device.mobile} {
    ${(props) =>
      props.withoutPadding
        ? css`
            padding: 0;
          `
        : css`
            padding: 20px;
          `}
  }
`;
