import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.section`
  padding-bottom: 110px;

  @media ${device.tablet} {
    padding-bottom: 60px;
  }

  @media ${device.mobile} {
    padding-bottom: 48px;
  }
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 17px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 341px;
  width: 100%;

  @media ${device.tablet} {
    max-width: unset;
    margin-right: 16px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileSearch = styled.div`
  display: none;

  @media ${device.mobile} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 11px;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: var(--main-text);
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid var(--divider);
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  padding: 6px 0 6px 18px;
  outline: none;
`;

export const SearchButton = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-green);
  max-height: 32px;
  border-radius: 6px;
  padding: 9px;
  cursor: pointer;
`;

export const Table = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--divider);
  border-radius: 12px;
  padding: 19px 22px;
`;

export const Row = styled.div`
  width: 100%;
  padding: 25px 0;
  border-bottom: 1px solid var(--divider);
`;

export const ButtonWrapper = styled.div`
  min-width: 200px;
`;

export const TableNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
  @media ${device.tablet} {
    margin-top: 28px;
  }
  @media ${device.mobile} {
    max-width: unset;
    margin-right: 68px;
  }
`;

export const ShowAll = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-green);
  text-decoration: underline;
  cursor: pointer;
`;

export const Description = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--main-subtext);
  margin: -8px 0 18px 0;
`;

export const Content = styled.div`
  > div {
    margin-bottom: 18px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  @media ${device.mobile} {
    display: block;
    text-align: center;
    > button {
      width: 100%;
      margin-top: 20px;
    }
  }
  > p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #949ab6;
    margin: 0 20px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    display: block;
    > button {
      width: 100%;
    }
  }
  > p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #949ab6;
    margin: 0 20px;
    @media ${device.mobile} {
      margin: 6px 0;
    }
  }
`;

export const TextInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  h5 {
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-text);
  }
  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: var(--main-text);
  }
`;

export const SecondTextInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
  h5 {
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-text);
  }
`;

export const BottomSection = styled.div`
  border: 1px solid var(--divider);
  border-radius: 12px;
  padding: 20px 24px 24px 24px;
  background-color: var(--main-white);
  margin-bottom: 88px !important;
  @media ${device.mobile} {
    margin-bottom: 30px !important;
    padding: 16px;
  }
  h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: var(--main-text);
    margin: 0 0 12px 0;
  }
  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--secondary-text);
    margin-bottom: 14px;
  }
  img {
    width: 100%;
  }
`;
