import { Container, Label } from './input-group-styled';

export const InputGroup = ({ children, label, htmlFor }) => {
  return (
    <Container>
      <Label htmlFor={htmlFor}>{label}</Label>
      {children}
    </Container>
  );
};
