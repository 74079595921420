import styled, { css } from 'styled-components';
import { buttonReset, inputReset, LatoFont } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const HeadCell = styled.div`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    border-left: 1px solid var(--divider);
  }

  @media ${device.tablet} {
    padding: 20px 24px;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
`;

export const DotsBtn = styled.div`
  height: 24px;
  width: 24px;
`;

export const RemoveBtn = styled.div`
  background: var(--main-white);
  border-radius: 8px;
  padding: 8px 12px;
  color: #e54d45;
  display: flex;
  align-items: center;
  gap: 6px;
  ${LatoFont};
`;

export const MainCell = styled.div`
  overflow-y: hidden;
  position: relative;
  & + & {
    border-left: 1px solid var(--divider);
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 85px 600px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--divider);
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-top: 20px;

    grid-template-columns: 1fr;
    .chat {
      ${(props) =>
        props.isMessegesOpen
          ? css`
              display: block;
            `
          : css`
              display: none;
            `}
    }

    .control {
      ${(props) =>
        props.isMessegesOpen
          ? css`
              display: none;
            `
          : css`
              display: block;
            `}
    }
  }
`;

export const Search = styled.div`
  position: relative;
  width: 100%;
  max-width: 340px;
  input {
    height: 40px;
  }

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const SearchBtn = styled.button`
  ${buttonReset};
  border-radius: 4px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-green);
  color: var(--main-white);
  position: absolute;
  right: 3px;
  top: 3px;
`;

export const OrderData = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const OrderDataItem = styled.div`
  display: flex;
  align-items: center;
  span {
    &:last-child {
      ${LatoFont};
      margin-left: 4px;
    }
  }
  & + & {
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid var(--divider);
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  width: 100%;
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    padding: 0;
  }
`;

export const Avatar = styled.div`
  margin-right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
`;

export const UserName = styled.div`
  font-size: 17px;
  line-height: 26px;
`;

export const ChatItem = styled.div`
  display: flex;
  padding: 24px 24px 20px;
  border-bottom: 1px solid var(--divider);
  cursor: pointer;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ChatAvatar = styled.div`
  margin-right: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ChatContent = styled.div`
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ChatContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChatUserName = styled.div`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ChatDate = styled.div`
  font-size: 12px;
  color: var(--main-subtext);
  ${LatoFont};
`;

export const ChatContentText = styled.div`
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${LatoFont};
  color: var(--secondary-text);
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const MainChatDate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${LatoFont};
  margin-bottom: 16px;
  span {
    margin: 0 20px;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    background: var(--divider);
    flex-grow: 1;
    height: 1px;
  }
`;

const Message = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
  display: flex;
  margin-bottom: 12px;
  span {
    padding: 16px 18px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 570px;
    margin-bottom: 6px;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 8px;
  }
`;

export const IncomeMessage = styled(Message)`
  span {
    background: #f7f7f7;
  }
`;

export const OutcomeMessage = styled(Message)`
  justify-content: flex-end;
  position: relative;
  span {
    border: 1px solid #eaf0f4;
  }
`;

export const MsgSetings = styled.div`
  position: absolute;
  top: 30px;
  right: -32px;
`;

export const ChatInner = styled.div`
  padding: 20px 28px 94px;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  padding: 14px 24px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  display: flex;
  height: 52px;
  align-items: center;

  input {
    ${inputReset};
    ${LatoFont};
    flex-grow: 1;
    margin: 0 24px;
  }

  button {
    ${buttonReset};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button + button {
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid var(--divider);
  }
`;

export const MessegesWrapper = styled.div``;

export const ChatListWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Control = styled.div`
  border-top: 1px solid var(--divider);
  padding: 20px 24px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--main-white);
`;

export const MobileBackBtn = styled.button`
  ${buttonReset};
  display: none;
  @media ${device.tablet} {
    display: ${(props) => (props.isMessegesOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    color: var(--main-green);
    svg {
      margin-right: 6px;
    }
  }
`;
