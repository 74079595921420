import { SliderWrapper } from './mobile-product-swiper-styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PCard, ProductCardTypes } from 'components/p-card/p-card';
import 'swiper/css';

export const MobileProductSwiper = ({ products }) => {
  return (
    <SliderWrapper>
      <Swiper slidesPerView={2.3} spaceBetween={10}>
        {products.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <PCard product={product} type={ProductCardTypes.BASIC} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SliderWrapper>
  );
};
