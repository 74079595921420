import styled from 'styled-components';
import { device } from 'assets/style-helpers/breakpoints';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const CartBlockStyled = styled.div`
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  padding: 28px 32px;
  @media ${device.tablet} {
    box-shadow: none;
    padding: 0;
  }

  & + & {
    margin-top: 32px;
  }
`;

export const ProductsList = styled.div`
  margin: 20px 0 28px;
`;

export const Total = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      font-size: 16px;
      span {
        &:first-child {
          font-size: 14px;
        }
      }
    }
    &:last-child {
      font-size: 20px;
      span {
        &:first-child {
          font-size: 17px;
        }
      }
    }
  }

  div + div {
    margin-top: 12px;
  }
`;

export const Bottom = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  ${LatoFont};
  a {
    color: var(--main-green);
    margin-right: 4px;
  }
`;
