import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import List from '../../components/lists/lists';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import { Respond } from '../../components/sidebars/sidebar-respond/respond';
import {
  Background,
  BoxSiteBar,
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  SiteBar,
  Title
} from './appointments-respond-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Orders' },
  { link: '#', name: 'My Orders' }
];

export const AppointmentsRespond = () => {
  const [isSitebarOrder, setSitebarOrder] = useState(false);

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>All Appointments</Title>
      <List />
      {isSitebarOrder && (
        <>
          <BoxSiteBar>
            <SiteBar>
              <Respond />
            </SiteBar>
          </BoxSiteBar>
          <Background />
        </>
      )}
    </>
  );
};
