export const MotorIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.4739 15.007C17.9332 16.8145 14.6005 17.0153 11.8893 15.6M3.526 8.99301C6.07296 7.1811 9.4156 6.98374 12.1303 8.41035M15.0363 13.9119C17.6344 12.2745 19.1387 9.27672 18.8409 6.161M8.92366 10.1133C6.35009 11.7562 4.86263 14.7392 5.1589 17.839M15.1742 10.3233C15.0545 7.25567 13.2105 4.45516 10.3639 3.155M8.82806 13.7331C8.96801 16.7786 10.8069 19.5528 13.636 20.845M18.364 5.63604C21.8787 9.15077 21.8787 14.8493 18.364 18.364C14.8492 21.8787 9.15075 21.8787 5.63604 18.364C2.12132 14.8492 2.12132 9.15075 5.63604 5.63604C9.15077 2.12132 14.8493 2.12132 18.364 5.63604ZM14.5456 9.45442C15.9515 10.8603 15.9515 13.1397 14.5456 14.5456C13.1397 15.9515 10.8603 15.9515 9.45442 14.5456C8.04853 13.1397 8.04853 10.8603 9.45442 9.45442C10.8603 8.04853 13.1397 8.04853 14.5456 9.45442Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
