import { useState } from 'react';
import {
  Inner,
  MapWrapper,
  OpenSidebarBtn,
  SidebarWrapper,
  SidebarBackground
} from './sidebar-styled';
import {
  AddressTitleWrapper,
  Avatar,
  Info,
  InfoWrapper,
  Main,
  Properties,
  RateInfo,
  Reviews,
  Subtitle,
  Title
} from '../../../store-front-trades-services/components/other-stores-sidebar/components/other-store-card/other-store-card-styled';
import { RateStars } from 'components/rate-stars/rate-stars';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  AddressText,
  AddressTitle,
  OpenHoursStatus,
  SocialLinks
} from '../../../../components/store-front-top/store-front-top-styled';
import mapIcon from '../../../../components/store-front-top/img/location.svg';
import avatar from 'assets/temp-img/single-shopping-center-avatar.png';
import { BannerImg } from '../../../dashboard/pages/business-information/components/business-settings-sidebar-styled';
import banner from '../../../dashboard/pages/business-information/img/sidebar-banner.jpg';
import map from 'assets/temp-img/map.png';
import { MobileCloseBtn } from '../../../store-finder/components/filter/filter-styled';
import { CloseBtn } from '../../../store-finder/components/filter/filter';

export const Sidebar = ({ isSidebarHidden, setIsSidebarHidden }) => {
  return (
    <>
      <SidebarBackground setIsSidebarHidden={isSidebarHidden} />

      <SidebarWrapper isVisible={isSidebarHidden}>
        <MobileCloseBtn>
          <CloseBtn
            onClick={() => {
              setIsSidebarHidden(true);
            }}
          />
        </MobileCloseBtn>
        <Inner>
          <BannerImg>
            <img src={banner} alt='' />
          </BannerImg>
          <InfoWrapper>
            <Avatar>
              <img src={avatar} alt='' />
            </Avatar>
            <Info>
              <Title>Properties</Title>
              <Subtitle>Real Estate</Subtitle>
              <RateStars rate={3} height={14} width={14} isRateCounter={false} />
              <RateInfo>
                <Reviews>10 Reviews</Reviews>
                <Properties>25 Properties Sold</Properties>
              </RateInfo>
            </Info>
          </InfoWrapper>
          <GlobalButton fullWidth>
            <SvgIcon name={'eye'} />
            View Storefront
          </GlobalButton>
          <Main>
            <AddressTitleWrapper>
              <AddressTitle>
                <img src={mapIcon} alt='icon' />
                Address
              </AddressTitle>
              <OpenHoursStatus>Open now</OpenHoursStatus>
            </AddressTitleWrapper>
            <AddressText>
              33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD
            </AddressText>

            <SocialLinks>
              <a href='#'>
                <SvgIcon name={'whatsup'} />
              </a>
              <a href='#'>
                <SvgIcon name={'phone'} />
              </a>
              <a href='#'>
                <SvgIcon name={'email'} />
              </a>
            </SocialLinks>
          </Main>
          <MapWrapper>
            <img src={map} alt='' />
          </MapWrapper>
        </Inner>
      </SidebarWrapper>
      <OpenSidebarBtn
        isSidebarHidden={isSidebarHidden}
        onClick={() => {
          setIsSidebarHidden(!isSidebarHidden);
        }}
      >
        Contact Seller
      </OpenSidebarBtn>
    </>
  );
};
