export const AccountIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 15C2.94649 12.9598 5.58918 11.7059 8.5 11.7059C11.4108 11.7059 14.0535 12.9598 16 15M12.25 4.70588C12.25 6.75258 10.5711 8.41176 8.5 8.41176C6.42893 8.41176 4.75 6.75258 4.75 4.70588C4.75 2.65918 6.42893 1 8.5 1C10.5711 1 12.25 2.65918 12.25 4.70588Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
