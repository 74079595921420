import {
  SponsoredBannerCardStyled,
  SponsoredBannerCardContent,
  SponsoredBannerCardLogo,
  SponsoredBannerCardSubtitle,
  SponsoredBannerCardText,
  SponsoredBannerCardTitle
} from './sponsored-card-styled';
import cardLogo from '../../pages/stores-you-love/components/adv-section/img/sponsored-banner-logo.svg';

export const SponsoredCard = () => {
  return (
    <SponsoredBannerCardStyled>
      <SponsoredBannerCardLogo>
        <img src={cardLogo} />
      </SponsoredBannerCardLogo>
      <SponsoredBannerCardContent>
        <SponsoredBannerCardTitle>Properties</SponsoredBannerCardTitle>
        <SponsoredBannerCardSubtitle>Real Estate</SponsoredBannerCardSubtitle>
        <SponsoredBannerCardText>
          Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
          morbi leo ornare viverra.
        </SponsoredBannerCardText>
      </SponsoredBannerCardContent>
    </SponsoredBannerCardStyled>
  );
};
