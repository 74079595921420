import { InputGroup } from '../../../../../../components/input-group/input-group';
import {
  DateInput,
  GridInputWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-form-auction-styled.jsx';

export const ProductLiveAuctionDay = () => {
  return (
    <Section>
      <SectionTitle>Live Auctions Day</SectionTitle>
      <GridInputWrapper>
        <InputGroup label={'Auction start date'}>
          <DateInput type={'date'} />
        </InputGroup>
        <InputGroup label={'Auction end date'}>
          <DateInput type={'date'} />
        </InputGroup>
      </GridInputWrapper>
    </Section>
  );
};
