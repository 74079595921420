import styled from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  background: var(--main-white);
  padding: 32px;
  position: relative;
  /* new */

  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 16px;
  margin-bottom: 32px;
  @media ${device.mobile} {
    padding: 28px 20px;
  }
`;

export const CloseBtn = styled.button`
  ${buttonReset};
  position: absolute;
  top: 12px;
  right: 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: 600;

  svg {
    color: var(--main-green);
    margin-right: 12px;
  }
`;

export const Description = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-subtext);
  margin-bottom: 20px;

  span {
    font-weight: 700;
    color: var(--main-green);
  }
`;

export const CoponInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media ${device.mobile} {
    justify-content: space-between;
  }
`;

export const CouponNumber = styled.div`
  padding: 10px 18px;
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  margin-right: 16px;
  color: var(--main-green);
`;

export const CopyBtn = styled.button`
  ${buttonReset};
  display: flex;
  align-items: center;
  color: var(--main-green);
  transition: all 0.3s;
  &:hover {
    color: var(--main-green);
  }

  svg {
    margin-right: 6px;
  }
`;
