import styled, { css } from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../../../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 106px;
`;

export const Main = styled.div`
  flex-basis: 925px;
  margin-right: 32px;
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
  @media ${device.mobile} {
    margin-right: 0px;
    padding: 0px 15px;
  }
`;

export const Title = styled.div`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #29263a;
  margin-bottom: 15px;

  @media ${device.mobile} {
    font-size: 18px;
    padding-left: 0px;
  }
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const InformationData = styled.div`
  position: relative;
  min-width: 134px;
  background: #e9f5e9;
  padding: 16px;
  padding_bottom: 22px;
  border-radius: 0px 0px 6px 6px;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    background: #388638;
    width: 100%;
    height: 6px;
    border-radius: 0px 0px 6px 6px;
  }
`;

export const DataTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #388638;
  margin-bottom: 10px;
`;

export const DataText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #388638;
  margin-bottom: 5px;
  display: flex;
  align-items: center; 

  svg{
    margin-right 7px;
  }
`;

export const InformationContact = styled.div`
  margin-left: 25px;
`;

export const ContactTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #29263a;
  margin-bottom: 6px;
`;

export const ContactBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const ContactName = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  margin-right 7px; 
`;

export const ContactText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5a5670;
`;

export const ViewService = styled.div`
  position: absolute;
  right: 5px;
  top: 0px;
  border-bottom: 1px solid #388638;
  font-family: 'Lato';
  color: #388638;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const BoxClose = styled.div`
  position: absolute;
  right: 108px;
  top: -4px;
  cursor: pointer;
  @media ${device.mobile} {
    display: none;
  }
  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const Message = styled.div`
  margin-top: 28px;
  margin-bottom: 18px;
`;

export const MessageTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29263a;
  margin-bottom: 10px;
`;

export const MessageContent = styled.div`
  border: 1px solid #e2e5eb;
  border-radius: 6px;
  padding: 24px;
`;

export const Content = styled.p`
  margin-bottom: 15px;
  display: block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const Textarea = styled.textarea`
  display: block;
  resize: none;
  width: 100%;
  padding: 24px;
  height: 150px;
  margin: 10px 0;
  margin-bottom: 0px;
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  font-family: 'Lato', sans-serif;

  &::placeholder {
    color: var(--main-subtext);
  }
`;

export const SectionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    width: 98%;
  }

  input {
    width: 98%;
  }
`;

export const SectionSubtitle = styled.div`
  margin-bottom: 15px;
`;

export const SectionTitle = styled.div`
  margin-bottom: 15px;
`;

export const SecondTitle = styled.div`
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const InputGroupWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Orders = styled.div`
  table {
    width: 100%;
    min-width: 100%;

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const Side = styled.div`
  flex-basis: calc(100% - 866px - 32px);
  @media ${device.laptop} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
    overflow-y: auto;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            opacity: 0;
            pointer-events: none;
            transition-delay: 0.3s;
          `
        : css`
            opacity: 1;
            pointer-events: all;
          `};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  button + button {
    margin-left: 16px;

    @media ${device.mobile} {
      margin-top: 0px;
    }
  }
  @media ${device.mobile} {
    margin-bottom: 15px;
  }
`;

export const CreateNewOderSidebarStyled = styled.div`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 40px;
  @media ${device.laptop} {
    position: fixed;
    right: 0;
    top: 0;
    width: 670px;
    padding: 40px 103px 40px 32px;
    z-index: 1001;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            transform: translateX(100%);
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media ${device.tablet} {
    padding: 20px 12px;
    width: 100%;
    border-radius: 0;
  }
`;

export const HelpCardStyled = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  height: 190px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PLayBtn = styled.button`
  ${buttonReset};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  div:first-child {
    max-width: 320px;
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 24px;
`;

export const Section = styled.div`
  background: #ffffff;
  padding: 5px;
  @media ${device.tablet} {
    padding: 0px;
  }
`;

export const Img = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
`;

export const SpanBox = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 12px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  text-align: center;
  color: #29263a;
`;

export const SelectBox = styled.div``;
