import { ProductsYouMauLikeItem } from 'pages/cart/components/products-you-may-like/components/products-you-mau-like-item';
import img1 from 'pages/cart/components/products-you-may-like/img/img.png';
import {
  Container,
  SliderControl,
  SliderNextBtn,
  SliderPrevBtn,
  SliderWrapper,
  Title,
  TitleWrapper
} from './products-you-may-like-styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as ChevronRight } from '../../img/chevron-right.svg';
import { useState } from 'react';

const items = [
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' },
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' },
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' }
];

export const ProductsYouMayLike = () => {
  const [mainSwiper, setMainSwiper] = useState(null);

  return (
    <Container>
      <SliderWrapper>
        <TitleWrapper>
          <Title>Products you may like</Title>
          <SliderControl>
            <SliderPrevBtn
              onClick={() => {
                mainSwiper.slidePrev();
              }}
            >
              <ChevronRight />
            </SliderPrevBtn>
            <SliderNextBtn
              onClick={() => {
                mainSwiper.slideNext();
              }}
            >
              <ChevronRight />
            </SliderNextBtn>
          </SliderControl>
        </TitleWrapper>
        <Swiper spaceBetween={16} onSwiper={setMainSwiper} loop={true}>
          {items.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <ProductsYouMauLikeItem product={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderWrapper>
    </Container>
  );
};
