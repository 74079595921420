export const foodProductList = [
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    rate: 3.7,
    reviews: 15,
    description: 'Samar Haute Plug | Norton',
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: false,
    rate: 3.7,
    reviews: 15,
    description: 'Samar Haute Plug | Norton',
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    rate: 3.7,
    reviews: 15,
    description: 'Samar Haute Plug | Norton',
    img: 'https://picsum.photos/200/300',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    rate: 3.7,
    reviews: 15,
    description: 'Samar Haute Plug | Norton',
    img: 'https://picsum.photos/200/300',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    description: 'Samar Haute Plug | Norton',
    rate: 3.7,
    reviews: 15,
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    description: 'Samar Haute Plug | Norton',
    rate: 3.7,
    reviews: 15,
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    description: 'Samar Haute Plug | Norton',
    rate: 3.7,
    reviews: 15,
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  },
  {
    title: 'Rafters rafters rafters rafters rafters',
    price: '10',
    old_price: '15',
    delivery: true,
    description: 'Samar Haute Plug | Norton',
    rate: 3.7,
    reviews: 15,
    img: 'https://picsum.photos/200/300',
    badge: 'Sponsored',
    stock: true
  }
];
