import {
  AttributesInputGroupRow,
  CheckboxWrapper,
  InputGroupWrapper,
  Section,
  SectionTitle,
  TagsSection
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalSelect } from 'components/global-select/global-select';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { Tag } from '../../pages/add-new-product-single/components/tag/tag';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

export const ServiceAttributes = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Service Attributes</SectionTitle>

      <InputGroupWrapper>
        <InputGroup label={'Registration Type'}>
          <AttributesInputGroupRow>
            <GlobalSelect options={selectOptions} />
            <CheckboxWrapper>
              <GlobalCheckbox>Has Variations</GlobalCheckbox>
            </CheckboxWrapper>
          </AttributesInputGroupRow>
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <TagsSection>
          <Tag>Privat Limited Company</Tag>
          <Tag>Privat Business Information</Tag>
        </TagsSection>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'plus'} />
          Add New Attributes
        </GlobalButton>
      </InputGroupWrapper>
    </Section>
  );
};
