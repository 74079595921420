import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const GallerySection = styled.div`
  flex-basis: 500px;
  min-width: 0;
  @media ${device.laptop} {
    flex-basis: 396px;
  }
  @media ${device.tablet} {
    flex-basis: 346px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const Gallery = styled.div``;

export const Sponsored = styled.div`
  margin-top: 48px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const Main = styled.div`
  flex-basis: 680px;
  padding: 0 40px;
  @media ${device.laptop} {
    flex-basis: 500px;
    padding: 0 24px;
  }
  @media ${device.tablet} {
    padding: 0;
    padding-left: 16px;
    flex-basis: calc(100% - 346px);
  }
  @media ${device.mobile} {
    padding-left: 0;
    flex-basis: 100%;
    margin-top: 24px;
  }
`;

export const MainWrapper = styled.div`
  margin-bottom: 40px;
`;

export const Attributes = styled.div``;

export const BannerWrapper = styled.div`
  flex-basis: 330px;
  margin-left: auto;

  @media ${device.tablet} {
    margin-left: 0;
    flex-basis: 346px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;
