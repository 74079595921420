import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterItem = styled.div`
  flex-basis: 200px;
  & + & {
    margin-left: 20px;
  }
`;

export const RadiosWrapper = styled.div`
  display: flex;
  align-items: center;
  > div + div {
    margin-left: 16px;
  }
`;
