export const Point = () => {
  return (
    <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M7.20313 14.8569C8.70313 12.114 13.2031 10.3437 13.2031 6.62829C13.2031 3.59862 10.5168 1.14258 7.20313 1.14258C3.88942 1.14258 1.20312 3.59862 1.20312 6.62829C1.20312 10.3437 5.70313 12.114 7.20313 14.8569Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.20313 7.54342C7.75541 7.54342 8.20312 7.13408 8.20312 6.62913C8.20312 6.12418 7.75541 5.71484 7.20313 5.71484C6.65084 5.71484 6.20312 6.12418 6.20312 6.62913C6.20312 7.13408 6.65084 7.54342 7.20313 7.54342Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_15_100835'>
          <rect width='15' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
