export const PercentIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.6 6.6H6.608M11.4 11.4H11.408M12.2 5.8L5.8 12.2M13.7209 3.39881C13.8857 3.79723 14.2019 4.11392 14.6 4.27926L15.9962 4.85758C16.3946 5.02263 16.7112 5.3392 16.8762 5.73765C17.0413 6.1361 17.0413 6.58379 16.8762 6.98225L16.2983 8.37744C16.1332 8.77607 16.133 9.22422 16.2989 9.62266L16.8757 11.0174C16.9576 11.2148 16.9997 11.4263 16.9997 11.64C16.9998 11.8536 16.9577 12.0651 16.8759 12.2625C16.7942 12.4599 16.6743 12.6392 16.5233 12.7903C16.3722 12.9413 16.1928 13.0611 15.9954 13.1428L14.6003 13.7207C14.2019 13.8854 13.8852 14.2016 13.7199 14.5998L13.1416 15.996C12.9765 16.3945 12.66 16.711 12.2615 16.8761C11.8631 17.0411 11.4154 17.0411 11.017 16.8761L9.62183 16.2982C9.22338 16.1335 8.77586 16.1339 8.37766 16.2991L6.98151 16.8766C6.5833 17.0413 6.13601 17.0411 5.73789 16.8762C5.33978 16.7113 5.0234 16.3951 4.85825 15.9971L4.27978 14.6005C4.11504 14.2021 3.79884 13.8854 3.40068 13.72L2.00453 13.1417C1.60627 12.9767 1.2898 12.6604 1.12471 12.2621C0.959607 11.8639 0.959383 11.4164 1.12408 11.018L1.70197 9.62284C1.8666 9.22438 1.86627 8.77684 1.70104 8.37863L1.12398 6.98143C1.04216 6.78408 1.00003 6.57254 1 6.35891C0.999966 6.14527 1.04203 5.93372 1.12378 5.73635C1.20553 5.53897 1.32537 5.35964 1.47645 5.2086C1.62754 5.05756 1.8069 4.93777 2.00429 4.85608L3.39944 4.27817C3.7975 4.11358 4.11399 3.79776 4.27944 3.40005L4.85775 2.00385C5.02278 1.6054 5.33934 1.28883 5.73778 1.12378C6.13622 0.958739 6.5839 0.958739 6.98233 1.12378L8.37748 1.70169C8.77592 1.86633 9.22344 1.86599 9.62164 1.70076L11.0184 1.12468C11.4168 0.959728 11.8643 0.959762 12.2627 1.12477C12.6611 1.28978 12.9776 1.60626 13.1426 2.00461L13.7211 3.40122L13.7209 3.39881ZM7 6.6C7 6.82091 6.82091 7 6.6 7C6.37909 7 6.2 6.82091 6.2 6.6C6.2 6.37909 6.37909 6.2 6.6 6.2C6.82091 6.2 7 6.37909 7 6.6ZM11.8 11.4C11.8 11.6209 11.6209 11.8 11.4 11.8C11.1791 11.8 11 11.6209 11 11.4C11 11.1791 11.1791 11 11.4 11C11.6209 11 11.8 11.1791 11.8 11.4Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
