import img from './img/img.png';
import { Container, ImgWrapper, Main, Title, Subtitle } from './business-link-card-styled';
import { RateStars } from '../../../../components/rate-stars/rate-stars';
import { ReactComponent as ChevronRight } from './img/chevron-right.svg';

export const BusinessLinkCard = () => {
  return (
    <Container to={'#'}>
      <ImgWrapper>
        <img src={img} alt='' />
      </ImgWrapper>
      <Main>
        <Title>Business Name</Title>
        <Subtitle>Business Category</Subtitle>
        <RateStars rate={4.1} reviews={36} color={'#388638'} width={14} height={14} />
      </Main>
      <ChevronRight />
    </Container>
  );
};
