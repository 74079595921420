export const LabelIcon = () => {
  return (
    <svg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 9.92345L11.4059 2.39393C10.887 1.87949 10.6276 1.62227 10.3249 1.43832C10.0564 1.27523 9.7638 1.15505 9.45769 1.08219C9.11243 1 8.74554 1 8.01177 1L4 1M1 7.64301L1 9.60073C1 10.0858 1 10.3283 1.05526 10.5565C1.10425 10.7588 1.18506 10.9522 1.29472 11.1297C1.4184 11.3298 1.59136 11.5013 1.93726 11.8442L9.73726 19.5779C10.5293 20.3632 10.9253 20.7558 11.382 20.9029C11.7837 21.0324 12.2163 21.0324 12.618 20.9029C13.0747 20.7558 13.4707 20.3632 14.2627 19.5779L16.7373 17.1244C17.5293 16.3391 17.9253 15.9465 18.0737 15.4937C18.2042 15.0954 18.2042 14.6664 18.0737 14.2681C17.9253 13.8154 17.5293 13.4227 16.7373 12.6374L9.43726 5.39952C9.09136 5.05656 8.9184 4.88507 8.71657 4.76244C8.53763 4.65372 8.34254 4.5736 8.13846 4.52502C7.90829 4.47023 7.6637 4.47023 7.17452 4.47023H4.2C3.0799 4.47023 2.51984 4.47023 2.09202 4.68636C1.7157 4.87648 1.40973 5.17984 1.21799 5.55296C1 5.97715 1 6.53243 1 7.64301Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
