import styled from 'styled-components';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const BlogVideosStyled = styled.div``;

export const Container = styled.div`
  ${mainContainer};
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px 25px;
  margin-bottom: 80px;
  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px 12px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 20px;
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px 20px;
  }
`;
