import closeIco from '../../../../assets/icons/close.svg';
import arrow from '../../../../assets/icons/green-arrow.svg';
import PhotoGallery from '../../../../assets/icons/phone.png';
import defaultPhotoGallery from '../../../../assets/icons/photo-default-for-gallery.png';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalTextarea } from '../../../../components/global-textarea/global-textarea';
import { InputGroup } from '../../../../components/input-group/input-group';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import {
  ArrowBack,
  Block,
  Box,
  BoxPage,
  Buttons,
  Close,
  CloseBox,
  Gallery,
  GalleryBlock,
  GalleryPhoto,
  GlobalButtonBox,
  Img,
  Tag,
  Tags,
  Title
} from './make-request-styled';

const categoryArr = [
  { label: 'Select the category', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const locationArr = [
  { label: 'Select your location', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const tagsArr = [
  { label: 'Select the tags for search', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const tagsArr2 = [{ value: 'Phones' }, { value: '1m' }];

const MakeRequest = ({ show, showBlock }) => {
  const hideBlock = () => {
    showBlock(false);
    document.querySelector('html').style.overflow = 'auto';
  };

  return (
    <>
      <BoxPage>
        <Title>Make Request</Title>
        <Block>
          <InputGroup label={'Request description'}>
            <GlobalTextarea placeholder={'Describe your request here'} />
          </InputGroup>
        </Block>
        <Block>
          <InputGroup label={'Category'}>
            <GlobalSelect options={categoryArr} />
          </InputGroup>
        </Block>
        <Block>
          <InputGroup label={'Location'}>
            <GlobalSelect options={locationArr} />
          </InputGroup>
        </Block>
        <Block>
          <InputGroup label={'Location'}>
            <GlobalSelect options={locationArr} />
          </InputGroup>
        </Block>
        <Block>
          <InputGroup label={'budget'}>
            <GlobalInput placeholder={'Write the budget here'} />
          </InputGroup>
        </Block>
        <Block>
          <InputGroup label={'search tags'}>
            <GlobalSelect options={tagsArr} />
          </InputGroup>
          <Tags>
            {tagsArr2.map((tag, i) => {
              return (
                <Tag key={i}>
                  {tag.value} <Img src={closeIco} alt={tag.value} />
                </Tag>
              );
            })}
          </Tags>
        </Block>
        <Block>
          <InputGroup label={'Image gallery'}>
            <Gallery>
              <GalleryBlock>
                <GalleryPhoto src={PhotoGallery} alt='Photo' />
                <Close>
                  <Img src={closeIco} alt='close' />
                </Close>
              </GalleryBlock>
              <GalleryBlock>
                <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
              </GalleryBlock>
              <GalleryBlock>
                <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
              </GalleryBlock>
              <GalleryBlock>
                <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
              </GalleryBlock>
            </Gallery>
            <GlobalButton height={40} type={'orange-secondary'}>
              <SvgIcon name={'download'} />
              Upload Images
            </GlobalButton>
            <Buttons>
              <GlobalButton height={40} type={'green'} fullWidth>
                <SvgIcon name={'request'} />
                Post Request
              </GlobalButton>
            </Buttons>
          </InputGroup>
        </Block>
      </BoxPage>
      {show && (
        <Box>
          <ArrowBack onClick={hideBlock}>
            <Img src={arrow} alt='Back' />
            Back to Buyer Requests
          </ArrowBack>
          <Title>
            Make Request
            <CloseBox onClick={hideBlock}>
              <Img src={closeIco} alt='close' />
            </CloseBox>
          </Title>
          <Block>
            <InputGroup label={'Request description'}>
              <GlobalTextarea placeholder={'Describe your request here'} />
            </InputGroup>
          </Block>
          <Block>
            <InputGroup label={'Category'}>
              <GlobalSelect options={categoryArr} />
            </InputGroup>
          </Block>
          <Block>
            <InputGroup label={'Location'}>
              <GlobalSelect options={locationArr} />
            </InputGroup>
          </Block>
          <Block>
            <InputGroup label={'Location'}>
              <GlobalSelect options={locationArr} />
            </InputGroup>
          </Block>
          <Block>
            <InputGroup label={'budget'}>
              <GlobalInput placeholder={'Write the budget here'} />
            </InputGroup>
          </Block>
          <Block>
            <InputGroup label={'search tags'}>
              <GlobalSelect options={tagsArr} />
            </InputGroup>
            <Tags>
              {tagsArr2.map((tag, i) => {
                return (
                  <Tag key={i}>
                    {tag.value} <Img src={closeIco} alt={tag.value} />
                  </Tag>
                );
              })}
            </Tags>
          </Block>
          <Block>
            <InputGroup label={'Image gallery'}>
              <Gallery>
                <GalleryBlock>
                  <GalleryPhoto src={PhotoGallery} alt='Photo' />
                  <Close>
                    <Img src={closeIco} alt='close' />
                  </Close>
                </GalleryBlock>
                <GalleryBlock>
                  <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
                </GalleryBlock>
                <GalleryBlock>
                  <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
                </GalleryBlock>
                <GalleryBlock>
                  <GalleryPhoto src={defaultPhotoGallery} alt='Photo' />
                </GalleryBlock>
              </Gallery>
              <GlobalButtonBox>
                <GlobalButton height={40} type={'orange-secondary'}>
                  <SvgIcon name={'download'} />
                  Upload Images
                </GlobalButton>
              </GlobalButtonBox>
              <Buttons>
                <GlobalButton height={40} type={'green'} fullWidth>
                  <SvgIcon name={'request'} />
                  Post Request
                </GlobalButton>
                <GlobalButton height={40} type={'orange-secondary'} onClick={hideBlock} fullWidth>
                  <SvgIcon name={'alert'} />
                  Cancel
                </GlobalButton>
              </Buttons>
            </InputGroup>
          </Block>
        </Box>
      )}
    </>
  );
};

export default MakeRequest;
