import styled from 'styled-components';
import { GlobalButton } from '../../components/global-button/global-button';
import { device } from '../../assets/style-helpers/breakpoints';

export const LoginForm = styled.form`
  width: 100%;
  max-width: 480px;
`;

export const InputGroupWrapper = styled.div`
  width: 100%;
  & + & {
    margin-top: 16px;
  }
`;

export const LoginBtn = styled(GlobalButton)`
  margin-top: 16px;
`;

export const FormBotton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 28px;
`;

export const StyledLink = styled.div`
  a {
    color: var(--main-green);
    font-weight: 600;
    text-decoration: underline;
    transition: all 0.3s;
    &:hover {
      color: var(--main-green);
    }
  }
`;

export const ChecbkoxLabel = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-subtext);
  font-weight: 400;
`;

export const GrayText = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-bottom: 6px;
  color: var(--main-subtext);
  @media ${device.mobile} {
    margin-bottom: 12px;
  }
`;
