import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { title30 } from '../../assets/style-helpers/typography';

export const Container = styled.div`
  ${mainContainer};
`;

export const Products = styled.div`
  margin-bottom: 80px;
  margin-top: 80px;
`;

export const Title = styled.div`
  ${title30};
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--main-gray-2);
`;
