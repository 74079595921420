import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  border: 1px solid var(--main-gray-2);
  border-radius: 6px;
`;

export const Label = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  font-size: 14px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  background: #f5f7fb;
  color: #acadb9;
  border-radius: 6px;
  ${(props) =>
    props.isVar2
      ? css`
          width: 135px;
        `
      : css`
          width: 90px;
        `}
`;
