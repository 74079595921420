import { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  ButtonWrapper,
  MobileSearch,
  Description,
  Content,
  ButtonsWrapper,
  LeftSide,
  TextInfo,
  BottomSection,
  SecondTextInfo
} from './newsletter-styled';
import Sidebar from '../../../../components/sidebar/sidebar';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalTextarea } from '../../../../components/global-textarea/global-textarea';
import { columnsData, columnsRows } from './mock';
import sidebarImage from '../../../../assets/newsletter-image.png';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Customers' },
  { link: '#', name: 'Newsletter' }
];

const customerList = [
  { label: 'User1', value: 'User1' },
  { label: 'User2', value: 'User2' },
  { label: 'User3', value: 'User3' },
  { label: 'User4', value: 'User4' }
];

const Newsletter = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSidebarCompleted, setShowSidebarCompleted] = useState(false);
  const [newsLetterName, setNewsLetterName] = useState('');
  const [subject, setSubject] = useState('');
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Newsletter</Title>
      <Navigation>
        <MobileSearch>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 15L12.2779 12.2778M14.2222 7.61111C14.2222 11.2623 11.2623 14.2222 7.61111 14.2222C3.95989 14.2222 1 11.2623 1 7.61111C1 3.95989 3.95989 1 7.61111 1C11.2623 1 14.2222 3.95989 14.2222 7.61111Z'
              stroke='#29263a'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p>Search</p>
        </MobileSearch>
        <InputWrapper>
          <SearchInput
            placeholder={'Quick Search...'}
            value={quickSearch}
            onChange={(e) => setQuickSearch(e.target.value)}
          />
          <SearchButton>
            <img src={search} alt='' />
          </SearchButton>
        </InputWrapper>
        <ButtonWrapper onClick={() => setShowSidebar(true)}>
          <GlobalButton fullWidth height={40} type={'secondary'}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                stroke='#388638'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Create Newsletter
          </GlobalButton>
        </ButtonWrapper>
      </Navigation>
      <Table columnsData={columnsData} columnsRows={columnsRows} usePagination hasMenu />
      <Sidebar
        showSidebar={showSidebar || showSidebarCompleted}
        onClose={() => {
          setShowSidebar(false);
          setShowSidebarCompleted(false);
        }}
        onCloseText={'Back to Newsletter'}
        title={showSidebar ? 'Create Newsletter' : 'Newsletter Rembinga Dzangu'}
      >
        {showSidebar ? (
          <Content>
            <InputGroup label={'NEWSLETTER NAME'}>
              <GlobalInput
                placeholder={'Enter the customer list name'}
                value={newsLetterName}
                onChange={(e) => setNewsLetterName(e.target.value)}
              />
            </InputGroup>
            <Description>The Newsletter Name will only be visible to you</Description>
            <InputGroup label={'SUBJECT'}>
              <GlobalInput
                placeholder={'Enter the subject'}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </InputGroup>
            <InputGroup label={'CUSTOMER LIST'}>
              <GlobalSelect options={customerList} />
            </InputGroup>
            <InputGroup label={'NEWSLETTER BODY'}>
              <GlobalTextarea placeholder={'Write the newsletter text here'} />
            </InputGroup>
            <ButtonsWrapper>
              <LeftSide>
                <GlobalButton
                  type={'primary'}
                  onClick={() => {
                    setShowSidebar(false);
                    setShowSidebarCompleted(true);
                  }}
                >
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z'
                      stroke='white'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  Send Newsletter
                </GlobalButton>
                <p>Or</p>
                <GlobalButton
                  type={'orange'}
                  onClick={() => {
                    setShowSidebar(false);
                    setShowSidebarCompleted(true);
                  }}
                >
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 5.66667L9 1.5M9 1.5L4.83333 5.66667M9 1.5V11.5'
                      stroke='white'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  Upload Newsletter
                </GlobalButton>
              </LeftSide>
              <GlobalButton type={'gray'} onClick={() => setShowSidebar(false)}>
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.5 6.5L11.5 11.5M11.5 6.5L6.5 11.5M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                    stroke='#ACADB9'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                Cancel
              </GlobalButton>
            </ButtonsWrapper>
          </Content>
        ) : (
          ''
        )}
        {showSidebarCompleted ? (
          <Content>
            <TextInfo>
              <h5>Sent On:&nbsp;</h5>
              <p>01 Oct 2022</p>
            </TextInfo>
            <TextInfo>
              <h5>Sent To:&nbsp;</h5>
              <p>Mbinga Dzangu</p>
            </TextInfo>
            <TextInfo>
              <h5>Subject:&nbsp;</h5>
              <p>New products in Stock. 20% Off to First 10 Buyers.</p>
            </TextInfo>
            <SecondTextInfo>
              <h5>Newsletter Body:</h5>
            </SecondTextInfo>
            <BottomSection>
              <h5>Good Day,</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Magna amet neque in neque metus. Consequat
                ut odio aliquam accumsan. Amet pellentesque proin nec enim diam turpis facilisi.
                Cursus aenean massa sed ultrices magna. Quis morbi massa amet eu fames.
                <br />
                <br />
                Amet proin augue pulvinar eleifend scelerisque consequat enim semper. Posuere id
                vitae diam ultrices ut nibh sed.{' '}
              </p>
              <img src={sidebarImage} alt='' />
            </BottomSection>
          </Content>
        ) : (
          ''
        )}
      </Sidebar>
    </Container>
  );
};

export default Newsletter;
