import { useState } from 'react';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { AddNewProductLayout } from './components/add-new-product-layout/add-new-product-layout';
import { ProductAttributes } from './components/product-attributes/product-attributes';
import { ProductCategories } from './components/product-categories/product-categories';
import { ProductDetails } from './components/product-details/product-details';
import { ProductImage } from './components/product-image/product-image';
import { ProductPricing } from './components/product-pricing/product-pricing';

import { Title } from './add-new-product-form-hire-item-styled.jsx';
import { ProductSubmitBtns } from '../../../dashboard/components/add-product-form-components/product-submit-btns';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductFormHireItemProfile = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Add New Product</Title>
      <AddNewProductLayout
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      >
        <ProductDetails selectOptions={selectOptions} />
        <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
        <ProductImage />
        <ProductPricing selectOptions={selectOptions} />
        <ProductAttributes selectOptions={selectOptions} />
        <ProductSubmitBtns
          isSidebarHidden={isSidebarHidden}
          setIsSidebarHidden={setIsSidebarHidden}
        />
      </AddNewProductLayout>
    </>
  );
};
