import {
  Bottom,
  CardsWrapper,
  CheckboxLabelStyled,
  PaymentItem,
  PaymentItemName,
  PaymentItemTitle,
  PaymentItemTotal,
  PaymentList,
  PaymentStyled,
  PaymentTypeTitle
} from './payment-styled';

import img1 from './img/1.png';
import img2 from './img/2.png';
import img3 from './img/3.png';
import img4 from './img/4.png';
import img5 from './img/5.png';
import img6 from './img/6.png';

import { ReactComponent as Banknot } from './img/bank-note.svg';
import { ReactComponent as Question } from './img/annotation-question.svg';
import { ReactComponent as CheckCircleBroken } from './img/check-circle-broken.svg';

import { PaymentCard } from './payment-card/payment-card';
import { useState } from 'react';
import Checkbox from '../../../../components/checkbox/checkbox';
import { Link } from 'react-router-dom';
import { GlobalButton } from '../../../../components/global-button/global-button';

const CheckboxLable = () => {
  return (
    <CheckboxLabelStyled>
      I have read and agree to the website <Link to={'#'}>Terms and Conditions</Link>
    </CheckboxLabelStyled>
  );
};

export const Payment = () => {
  const [currentPaymentCard, setCurrentPaymentCard] = useState(1);
  return (
    <PaymentStyled>
      <PaymentList>
        <PaymentItem>
          <PaymentItemName>Order Total</PaymentItemName>
          <PaymentItemTitle>$1130</PaymentItemTitle>
        </PaymentItem>
        <PaymentItem>
          <PaymentItemName>Delivery (Everything Zimbabwean)</PaymentItemName>
          <PaymentItemTitle>$20</PaymentItemTitle>
        </PaymentItem>
      </PaymentList>
      <PaymentList>
        <PaymentItem>
          <PaymentItemName>Sub Total</PaymentItemName>
          <PaymentItemTitle>$1150</PaymentItemTitle>
        </PaymentItem>
        <PaymentItemTotal>
          <PaymentItemName>Total</PaymentItemName>
          <PaymentItemTitle>$1150</PaymentItemTitle>
        </PaymentItemTotal>
      </PaymentList>
      <PaymentList>
        <PaymentTypeTitle>Money transfer services</PaymentTypeTitle>
        <CardsWrapper>
          <PaymentCard
            isActive={currentPaymentCard === 1}
            onClick={() => {
              setCurrentPaymentCard(1);
            }}
          >
            <img src={img1} alt='' />
          </PaymentCard>
          <PaymentCard
            isActive={currentPaymentCard === 2}
            onClick={() => {
              setCurrentPaymentCard(2);
            }}
          >
            <img src={img2} alt='' />
          </PaymentCard>
          <PaymentCard
            isActive={currentPaymentCard === 3}
            onClick={() => {
              setCurrentPaymentCard(3);
            }}
          >
            <img src={img3} alt='' />
          </PaymentCard>
        </CardsWrapper>
        <PaymentTypeTitle>Online payments</PaymentTypeTitle>
        <CardsWrapper>
          <PaymentCard
            isActive={currentPaymentCard === 4}
            onClick={() => {
              setCurrentPaymentCard(4);
            }}
          >
            <img src={img4} alt='' />
          </PaymentCard>
          <PaymentCard
            isActive={currentPaymentCard === 5}
            onClick={() => {
              setCurrentPaymentCard(5);
            }}
          >
            <img src={img5} alt='' />
          </PaymentCard>
          <PaymentCard
            isActive={currentPaymentCard === 6}
            onClick={() => {
              setCurrentPaymentCard(6);
            }}
          >
            <img src={img6} alt='' />
          </PaymentCard>
        </CardsWrapper>
        <PaymentTypeTitle>other payment methods</PaymentTypeTitle>
        <CardsWrapper>
          <PaymentCard
            isActive={currentPaymentCard === 7}
            onClick={() => {
              setCurrentPaymentCard(7);
            }}
          >
            <Banknot />
          </PaymentCard>
          <PaymentCard
            isActive={currentPaymentCard === 8}
            onClick={() => {
              setCurrentPaymentCard(8);
            }}
          >
            <Question />
          </PaymentCard>
        </CardsWrapper>
      </PaymentList>
      <Bottom>
        <Checkbox label={<CheckboxLable />} checked />
        <GlobalButton type={'primary'} fullWidth height={40}>
          <CheckCircleBroken />
          Place Order
        </GlobalButton>
      </Bottom>
    </PaymentStyled>
  );
};
