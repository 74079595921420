import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../../components/global-input/global-input';

export const SocialMediaSection = () => {
  return (
    <Section>
      <SectionTitle>Social Media Accounts</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'Facebook'}>
          <GlobalInput placeholder={'Link here'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Instagram'}>
          <GlobalInput placeholder={'Link here'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'twitter'}>
          <GlobalInput placeholder={'Link here'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'LinkedIn'}>
          <GlobalInput placeholder={'Link here'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'tiktok'}>
          <GlobalInput placeholder={'Link here'} />
        </InputGroup>
      </InputGroupWrapper>
    </Section>
  );
};
