import styled, { css } from 'styled-components';
import { GlobalButton } from 'components/global-button/global-button';
import { device } from 'assets/style-helpers/breakpoints';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  position: relative;
  z-index: 2;
  padding: 48px 42px 80px 103px;
  background: var(--main-white);

  @media ${device.tablet} {
    padding-left: 15px;
  }
  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 0;
    background: rgba(41, 38, 58, 0.4);
    transition: all .3s;

    ${(props) => {
      return props.isMobileFilterVisible
        ? css`
            opacity: 1;
            pointer-events: all;
          `
        : css`
            opacity: 0;
            pointer-events: none;
          `;
    }}
`;

export const FilterStyled = styled.div`
  width: 226px;
  pointer-events: all;
  padding-bottom: 40px;

  @media ${device.mobile} {
    background: var(--main-white);
    padding: 15px;
    width: 100%;
    max-width: 295px;
    height: 100%;
    transition: all .3s;
    ${(props) => {
      return props.isMobileFilterVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `;
    }}
`;

export const TopBtnWrapper = styled.div`
  margin-bottom: 16px;
  button {
    width: 100%;
  }
`;

export const FilterTop = styled.div`
  padding: 14px 20px;
  background: var(--main-green);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    background: transparent;
    justify-content: flex-end;
    flex-direction: row-reverse;
    padding: 24px 0;
    position: relative;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  color: var(--main-white);

  @media ${device.mobile} {
    color: var(--main-text);
    margin-right: 10px;
  }
`;

export const Title = styled.div`
  color: var(--main-white);
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  @media ${device.mobile} {
    color: var(--main-text);
  }
`;

export const FilterSection = styled.div`
  & + & {
    margin-top: 26px;
    padding-top: 26px;
    border-top: 1px solid #dcdcdc;
  }
`;

export const FilterSectionTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  svg {
    transition: all 0.3s;

    ${(props) =>
      props.isVisible
        ? css`
            transform: rotate(0);
          `
        : css`
            transform: rotate(180deg);
          `}
  }
`;

export const CheckboxList = styled.div`
  > div + div {
    margin-top: 10px;
  }
`;

export const Menu = styled.div`
  position: relative;
  padding: 20px 0;
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const Buttons = styled.div``;

export const ResetBtn = styled(GlobalButton)`
  border: 1px solid var(--main-green);
  color: var(--main-green);
  background: var(--light-bg);
  margin-top: 10px;
  &:hover {
    color: var(--main-white);
  }
`;

export const MobileCloseBtn = styled.button`
  ${buttonReset};
  position: absolute;
  right: 0;
  top: 24px;
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

export const HideResultBtn = styled.button`
  ${buttonReset};
  color: var(--main-green);
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 103px;
  top: 0px;
  z-index: 10;
  &:hover {
    color: var(--main-green);
  }
  svg {
    margin-right: 6px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const CategoriesWrapper = styled.div`
  border-top: 1px solid var(--divider);
  margin-top: 27px;
  padding-top: 27px;
  margin-bottom: 27px;
`;

export const PriceSelection = styled.div`
  margin-bottom: 19px;
`;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;

export const BreadcrumbsWrapper = styled.div`
  //position: absolute;
  //top: 0;
`;

export const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  a {
    span {
      color: var(--main-subtext);
    }
    &:hover {
      color: var(--main-orange);
    }
  }
`;
