import {
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';

import {
  Item,
  ItemsList,
  ItemText,
  LabelGreen,
  Price,
  RedLabel,
  GrayLabel,
  OrangeLabel,
  MobileText
} from './product-promotion-styled';
import { Link } from 'react-router-dom';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import {
  customDropdownIndicatorSmall,
  GlobalSelect,
  selectCustomStyleSmall
} from '../../../../components/global-select/global-select';

const options = [
  { label: '7 days', value: '7' },
  { label: '2 days', value: '7' },
  { label: '5 days', value: '7' }
];

export const ProductPromotion = () => {
  return (
    <Section>
      <SectionTitle>Product Promotion</SectionTitle>
      <ItemsList>
        <Item>
          <div>
            <GlobalCheckbox />
            <LabelGreen>FEATURED</LabelGreen>
            <ItemText>
              {' '}
              Have you Ad appear at the top of category listings for 3, 4 or 7 days.{' '}
              <Link to={'#'}>View Example</Link>
            </ItemText>
          </div>
          <div>
            <GlobalSelect
              styles={selectCustomStyleSmall}
              options={options}
              dropdownIndicator={customDropdownIndicatorSmall}
            />
            <Price>$2,99</Price>
          </div>
          <MobileText>
            {' '}
            Have you Ad appear at the top of category listings for 3, 4 or 7 days.{' '}
            <Link to={'#'}>View Example</Link>
          </MobileText>
        </Item>
        <Item>
          <div>
            <GlobalCheckbox />
            <RedLabel>URGENT</RedLabel>
            <ItemText>
              {' '}
              Let people know you want to sell, rent or hire quickly.{' '}
              <Link to={'#'}>View Example</Link>
            </ItemText>
          </div>
          <div>
            <GlobalSelect
              styles={selectCustomStyleSmall}
              options={options}
              dropdownIndicator={customDropdownIndicatorSmall}
            />
            <Price>$2,99</Price>
          </div>
          <MobileText>
            {' '}
            Let people know you want to sell, rent or hire quickly.{' '}
            <Link to={'#'}>View Example</Link>
          </MobileText>
        </Item>
        <Item>
          <div>
            <GlobalCheckbox />
            <OrangeLabel>SPOTLIGHT</OrangeLabel>
            <ItemText>
              {' '}
              Have your Ad seen on the Everything Zimbabwean homepage.{' '}
              <Link to={'#'}>View Example</Link>
            </ItemText>
          </div>
          <div>
            <GlobalSelect
              styles={selectCustomStyleSmall}
              options={options}
              dropdownIndicator={customDropdownIndicatorSmall}
            />
            <Price>$2,99</Price>
          </div>
          <MobileText>
            {' '}
            Have your Ad seen on the Everything Zimbabwean homepage.{' '}
            <Link to={'#'}>View Example</Link>
          </MobileText>
        </Item>
        <Item>
          <div>
            <GlobalCheckbox />
            <GrayLabel>BUMP UP</GrayLabel>
            <ItemText>
              {' '}
              Moves your Ad to the top of listing. <Link to={'#'}>View Example</Link>
            </ItemText>
          </div>
          <div>
            <GlobalSelect
              styles={selectCustomStyleSmall}
              options={options}
              dropdownIndicator={customDropdownIndicatorSmall}
            />
            <Price>$2,99</Price>
          </div>
          <MobileText>
            {' '}
            Moves your Ad to the top of listing. <Link to={'#'}>View Example</Link>
          </MobileText>
        </Item>
      </ItemsList>
    </Section>
  );
};
