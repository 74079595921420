import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const SidebarWrapper = styled.div`
  position: fixed;
  top: 133.98px;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(31, 49, 36, 0.4);
  z-index: 10000;
`;

export const Container = styled.div`
  position: absolute;
  padding: ${(props) => (props.withoutHeading ? '0 160px 0 32px' : '40px 160px 0 32px')};
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9999;
  width: ${(props) => (props.width ? props.width : '830px')};
  height: calc(100% - 133.98px);
  overflow-y: auto;
  right: 0;

  @media ${device.tablet} {
    width: 100%;
    padding: 22px 20px 38px 22px;
  }
  @media ${device.mobile} {
    width: 100%;
    padding: 22px 15px 30px 15px;
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const CloseMobile = styled.div`
  display: none;
  width: max-content;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-green);
  cursor: pointer;

  > svg {
    margin-right: 13.5px;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
  }
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${device.tablet} {
    display: block;
    margin-bottom: 19px;
  }
  @media ${device.mobile} {
    display: block;
    margin-bottom: 19px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: var(--main-text);

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 29px;
  }
  @media ${device.mobile} {
    font-size: 24px;
    line-height: 29px;
  }
`;
