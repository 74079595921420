import {
  Container,
  Title,
  CloseBtn,
  Inner,
  TitleWrapper,
  CardsWrapper,
  MobileBackBtnText,
  CloseBtnIconWrapper
} from './other-stores-sidebar-styled';
import avatar from 'assets/temp-img/single-shopping-center-avatar.png';
import { OtherStoreCard } from './components/other-store-card/other-store-card';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

const stores = [
  {
    title: 'Properties',
    subtitle: 'Real Estate',
    avatar: avatar
  },
  {
    title: 'Properties',
    subtitle: 'Real Estate',
    avatar: avatar
  },
  {
    title: 'Properties',
    subtitle: 'Real Estate',
    avatar: avatar
  }
];

export const OtherStoresSidebar = ({
  isVisible,
  onCloseBtnClick,
  title = 'Other Stores Of This Vendor'
}) => {
  return (
    <Container isVisible={isVisible}>
      <Inner>
        <TitleWrapper>
          <Title>{title}</Title>
          <CloseBtn onClick={onCloseBtnClick}>
            <MobileBackBtnText>
              <SvgIcon name={'chevron-left'} />
              Back to So & So Plumbing
            </MobileBackBtnText>
            <CloseBtnIconWrapper>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 6L6 18M6 6L18 18'
                  stroke='#ACADB9'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </CloseBtnIconWrapper>
          </CloseBtn>
        </TitleWrapper>
        <CardsWrapper>
          {stores.map((store, i) => {
            return <OtherStoreCard store={store} key={i} />;
          })}
        </CardsWrapper>
      </Inner>
    </Container>
  );
};
