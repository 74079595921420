import { DateSelect } from '../date-select/date-select';
import { CalendarWrapper, Container, Title, TitleWrapper } from './box-styled';

import tempCalendar from './img/temp-calendar.svg';

export const Box = () => {
  return (
    <Container>
      <TitleWrapper>
        <Title>My Calendar</Title>
        <DateSelect />
      </TitleWrapper>
      <CalendarWrapper>
        <img src={tempCalendar} alt='' />
      </CalendarWrapper>
    </Container>
  );
};
