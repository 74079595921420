import { Icon, PaymentCardStyled } from './payment-card-styled';
import { ReactComponent as Check } from './check.svg';

export const PaymentCard = ({ isActive, children, onClick }) => {
  return (
    <PaymentCardStyled isActive={isActive} onClick={onClick}>
      <Icon isActive={isActive}>
        <Check />
      </Icon>
      {children}
    </PaymentCardStyled>
  );
};
