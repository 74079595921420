import Tippy from '@tippyjs/react/headless';
import { ApproveSellerWrapper, TippyIcon } from './approve-seller-styled';

export const ApproveSeller = () => {
  return (
    <Tippy
      placement={'top-start'}
      render={(attrs) => (
        <ApproveSellerWrapper tabIndex='-1' {...attrs}>
          Approve seller
        </ApproveSellerWrapper>
      )}
    >
      <TippyIcon>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 0L15.1965 2.16227L19.0534 2.2918L20.3685 5.91995L23.4127 8.2918L22.344 12L23.4127 15.7082L20.3685 18.0801L19.0534 21.7082L15.1965 21.8377L12 24L8.80353 21.8377L4.94658 21.7082L3.63153 18.0801L0.587322 15.7082L1.656 12L0.587322 8.2918L3.63153 5.91995L4.94658 2.2918L8.80353 2.16227L12 0Z'
            fill='#45BCB5'
          />
          <path
            d='M7 12L11 16L18 9'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </TippyIcon>
    </Tippy>
  );
};
