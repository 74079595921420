export const ClothingIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.67228 8.0895L4.03578 5.68425C3.92415 5.35401 4.08131 4.99378 4.40145 4.84613L8.83065 3C9.10075 4.51624 10.4358 5.62393 11.9994 5.62912C13.5631 5.62393 14.8981 4.51624 15.1682 3L19.5985 4.84613C19.9187 4.99378 20.0759 5.35401 19.9642 5.68425L19.3277 8.09063C19.2632 8.29201 19.1059 8.45155 18.9033 8.52091C18.7007 8.59027 18.4768 8.56129 18.2993 8.44275L16.7337 7.88025V12.9686L19.1335 17.6171C19.4156 18.1405 19.2408 18.7895 18.7324 19.1066C16.6928 20.3018 14.3725 20.9543 11.9994 21C9.6276 20.954 7.30863 20.302 5.26993 19.1077C4.76084 18.7906 4.586 18.1408 4.86883 17.6171L7.26855 12.9686V7.88138L5.70302 8.44388C5.52509 8.5633 5.30016 8.59252 5.09686 8.52262C4.89356 8.45273 4.73601 8.29201 4.67228 8.0895Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.28455 8.08262C6.39571 8.62361 6.92438 8.97205 7.46536 8.86089C8.00634 8.74973 8.35478 8.22106 8.24362 7.68008L6.28455 8.08262ZM7.86973 6.51623C7.70853 5.98799 7.14963 5.69045 6.6214 5.85164C6.09316 6.01284 5.79562 6.57174 5.95681 7.09998L7.86973 6.51623ZM7.76115 12.1019C7.28203 11.8272 6.67094 11.9929 6.39623 12.472C6.12153 12.9511 6.28724 13.5622 6.76636 13.8369L7.76115 12.1019ZM11.9992 14.3464L11.9529 15.3453C11.9838 15.3467 12.0148 15.3467 12.0457 15.3453L11.9992 14.3464ZM17.2323 13.8357C17.7113 13.5609 17.8769 12.9497 17.6021 12.4707C17.3272 11.9916 16.7161 11.826 16.2371 12.1009L17.2323 13.8357ZM15.7553 7.67861C15.6441 8.2196 15.9925 8.74826 16.5335 8.85942C17.0745 8.97058 17.6032 8.62214 17.7143 8.08116L15.7553 7.67861ZM18.0421 7.09851C18.2033 6.57027 17.9057 6.01138 17.3775 5.85018C16.8493 5.68898 16.2904 5.98653 16.1292 6.51476L18.0421 7.09851ZM8.24362 7.68008C8.19743 7.45526 8.10601 7.18964 8.03895 6.99965C7.9576 6.76917 7.90136 6.61988 7.86973 6.51623L5.95681 7.09998C6.00517 7.25844 6.09406 7.49841 6.15298 7.66533C6.22619 7.87275 6.27018 8.01269 6.28455 8.08262L8.24362 7.68008ZM6.76636 13.8369C8.34771 14.7436 10.1269 15.2607 11.9529 15.3453L12.0455 13.3475C10.5355 13.2775 9.06579 12.8499 7.76115 12.1019L6.76636 13.8369ZM12.0457 15.3453C13.8718 15.2603 15.651 14.7428 17.2323 13.8357L16.2371 12.1009C14.9325 12.8492 13.4628 13.2772 11.9527 13.3475L12.0457 15.3453ZM17.7143 8.08116C17.7287 8.01123 17.7727 7.87128 17.8459 7.66387C17.9048 7.49694 17.9937 7.25698 18.0421 7.09851L16.1292 6.51476C16.0975 6.61842 16.0413 6.7677 15.9599 6.99819C15.8929 7.18818 15.8015 7.4538 15.7553 7.67861L17.7143 8.08116Z'
        fill='currentColor'
      />
    </svg>
  );
};
