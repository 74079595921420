export const TradesIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.2 12C19.2 15.9765 15.9765 19.2 12 19.2M19.2 12C19.2 8.02355 15.9765 4.8 12 4.8M19.2 12H4.8M12 19.2C8.02355 19.2 4.8 15.9765 4.8 12M12 19.2C13.8009 17.2284 14.8249 14.6697 14.8805 12C14.8249 9.33026 13.8009 6.77161 12 4.8M12 19.2C10.1991 17.2284 9.17611 14.6697 9.1205 12C9.17611 9.33026 10.1991 6.77161 12 4.8M4.8 12C4.8 8.02355 8.02355 4.8 12 4.8M6.6 19.2C6.6 20.1941 5.79411 21 4.8 21C3.80589 21 3 20.1941 3 19.2C3 18.2059 3.80589 17.4 4.8 17.4C5.79411 17.4 6.6 18.2059 6.6 19.2ZM21 19.2C21 20.1941 20.1941 21 19.2 21C18.2059 21 17.4 20.1941 17.4 19.2C17.4 18.2059 18.2059 17.4 19.2 17.4C20.1941 17.4 21 18.2059 21 19.2ZM6.6 4.8C6.6 5.79411 5.79411 6.6 4.8 6.6C3.80589 6.6 3 5.79411 3 4.8C3 3.80589 3.80589 3 4.8 3C5.79411 3 6.6 3.80589 6.6 4.8ZM21 4.8C21 5.79411 20.1941 6.6 19.2 6.6C18.2059 6.6 17.4 5.79411 17.4 4.8C17.4 3.80589 18.2059 3 19.2 3C20.1941 3 21 3.80589 21 4.8Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
