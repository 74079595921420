import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { CalendarWrapper, Container, Title, TitleWrapper } from './calendar-styled';
import { DateSelect } from './components/date-select/date-select';

import tempCalendar from './img/temp-calendar.svg';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Appointments' },
  { link: '#', name: 'My Calendar' }
];

export const Calendar = () => {
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <TitleWrapper>
        <Title>My Calendar</Title>
        <DateSelect />
      </TitleWrapper>
      <CalendarWrapper>
        <img src={tempCalendar} alt='' />
      </CalendarWrapper>
    </Container>
  );
};
