import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { Container, Title } from '../add-new-product/add-new-product-styled';
import {
  CalendarBtn,
  StatCardStyled,
  StatsWrapper,
  TitleWrapper,
  StatCardTop,
  StatCardTitle,
  Value,
  StatCardIconWrapper,
  Percent,
  Side,
  Wrapper,
  ChartWrapper,
  Chart,
  ChartLegend,
  LegendItemGreen,
  LegendItemRed,
  LegendItemOrange,
  Main,
  StyledTable,
  MessageStyled,
  MessageAvatar,
  MessageMain,
  MessageTop,
  MessageAuthor,
  MessageDate,
  MessagetText,
  MessageList
} from './dashboard-main-styled';

import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as DollarIcon } from './icons/currency-dollar.svg';
import { ReactComponent as PackageIcon } from './icons/package.svg';
import { ReactComponent as StorefrontIcon } from './icons/storefront.svg';
import chartImg from './temp-img/dashboard.png';
import avatarImg from './temp-img/avatar.png';
import { DashboardWidget } from '../../components/dashboard-widget/dashboard-widget';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Dashboard' }
];

const StatCard = ({ icon, title, percent, value }) => (
  <StatCardStyled>
    <StatCardTop>
      <StatCardIconWrapper>{icon}</StatCardIconWrapper>
      <StatCardTitle>{title}</StatCardTitle>
    </StatCardTop>
    <Value>{value}</Value>
    <Percent>
      {percent > 0 ? (
        <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M3.44859 0.4685C3.73049 0.11076 4.2695 0.110761 4.55141 0.468501L7.84597 4.64934C8.2124 5.11435 7.88379 5.8002 7.29456 5.8002H0.705444C0.116207 5.8002 -0.212401 5.11435 0.154035 4.64934L3.44859 0.4685Z'
            fill='#388638'
          />
        </svg>
      ) : (
        <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M3.44859 5.5315C3.73049 5.88924 4.2695 5.88924 4.55141 5.5315L7.84597 1.35066C8.2124 0.885649 7.88379 0.199805 7.29456 0.199805H0.705444C0.116207 0.199805 -0.212401 0.88565 0.154035 1.35066L3.44859 5.5315Z'
            fill='#E54D45'
          />
        </svg>
      )}
      {percent > 0 && '+'} {percent}% than last year
    </Percent>
  </StatCardStyled>
);

const Table = () => {
  return (
    <StyledTable>
      <table>
        <tr>
          <th>ID</th>
          <th>Customer</th>
          <th>Date</th>
          <th>Status</th>
          <th>Total</th>
        </tr>
        <tr>
          <td>12520</td>
          <td>Langton Shoriwa</td>
          <td>07 Jul 2022</td>
          <td>Paid</td>
          <td>$300 000 000</td>
        </tr>
        <tr>
          <td>12520</td>
          <td>Langton Shoriwa</td>
          <td>07 Jul 2022</td>
          <td>Paid</td>
          <td>$300 000 000</td>
        </tr>
        <tr>
          <td>12520</td>
          <td>Langton Shoriwa</td>
          <td>07 Jul 2022</td>
          <td>Paid</td>
          <td>$300 000 000</td>
        </tr>
      </table>
    </StyledTable>
  );
};

const Message = () => {
  return (
    <MessageStyled>
      <MessageAvatar>
        <img src={avatarImg} alt='avatar' />
      </MessageAvatar>
      <MessageMain>
        <MessageTop>
          <MessageAuthor>Langton Shoriwa</MessageAuthor>
          <MessageDate>1 day ago</MessageDate>
        </MessageTop>
        <MessagetText>
          Lorem ipsum dolor sit amet consectetur Sagittissti ipsum dolor sit amet
        </MessagetText>
      </MessageMain>
    </MessageStyled>
  );
};

const stats = [
  { title: 'Product Views', icon: <PackageIcon />, value: '500K', percent: '3' },
  { title: 'Storefront Views', icon: <StorefrontIcon />, value: '30K', percent: '-23' },
  { title: 'Total Orders', icon: <CartIcon />, value: '20 000', percent: '3' },
  { title: 'Total Revenue', icon: <DollarIcon />, value: '$45K', percent: '23' }
];

export const DashboardMain = () => {
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Wrapper>
        <Main>
          <TitleWrapper>
            <Title>Dashboard</Title>
            <CalendarBtn>
              1 - 31 September 2022{' '}
              <svg
                width='8'
                height='6'
                viewBox='0 0 8 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 1.5L4 4.5L7 1.5'
                  stroke='#29263A'
                  strokeWidth='1.7'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </CalendarBtn>
          </TitleWrapper>
          <StatsWrapper>
            {stats.map((item, i) => (
              <StatCard
                key={i}
                icon={item.icon}
                title={item.title}
                value={item.value}
                percent={item.percent}
              />
            ))}
          </StatsWrapper>
          <ChartWrapper>
            <Chart>
              <img src={chartImg} alt='' />
            </Chart>
            <ChartLegend>
              <LegendItemGreen>Orders</LegendItemGreen>
              <LegendItemRed>Views</LegendItemRed>
              <LegendItemOrange>Enquiries</LegendItemOrange>
            </ChartLegend>
          </ChartWrapper>
        </Main>
        <Side>
          <DashboardWidget title={'New Orders'} notifications={25} link={'#'}>
            <Table />
          </DashboardWidget>
          <DashboardWidget title={'New Messages'} notifications={35} link={'#'}>
            <MessageList>
              <Message />
              <Message />
              <Message />
            </MessageList>
          </DashboardWidget>
        </Side>
      </Wrapper>
    </Container>
  );
};
