import { Container, Icon, Text } from './status-styled';

export const Status = ({ label, isChecked }) => {
  return (
    <Container>
      <Icon isChecked={isChecked} />
      <Text>{label}</Text>
    </Container>
  );
};
