export const CartIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.70907 11.8H15.5704C16.485 11.8 16.9422 11.8 17.3063 11.6301C17.627 11.4803 17.8964 11.24 18.0811 10.9388C18.2908 10.597 18.3413 10.144 18.4423 9.23804L18.9705 4.49951C19.0014 4.22279 19.0168 4.08444 18.9722 3.97734C18.9329 3.88329 18.8629 3.80527 18.7734 3.75605C18.6716 3.7 18.5319 3.7 18.2526 3.7H3.25758M1 1H2.12732C2.36627 1 2.48574 1 2.57922 1.04529C2.6615 1.08517 2.7297 1.14902 2.77476 1.22839C2.82594 1.31854 2.83339 1.43739 2.8483 1.67509L3.66661 14.7249C3.68152 14.9626 3.68897 15.0815 3.74016 15.1716C3.78522 15.251 3.85341 15.3148 3.9357 15.3547C4.02917 15.4 4.14864 15.4 4.38759 15.4H16.3507M5.9664 18.55H5.97543M14.0932 18.55H14.1023M6.4179 18.55C6.4179 18.7985 6.21576 19 5.9664 19C5.71705 19 5.51491 18.7985 5.51491 18.55C5.51491 18.3015 5.71705 18.1 5.9664 18.1C6.21576 18.1 6.4179 18.3015 6.4179 18.55ZM14.5447 18.55C14.5447 18.7985 14.3426 19 14.0932 19C13.8439 19 13.6418 18.7985 13.6418 18.55C13.6418 18.3015 13.8439 18.1 14.0932 18.1C14.3426 18.1 14.5447 18.3015 14.5447 18.55Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
