import React from 'react';

import Header from '../../components/header/header';
import Banner from './components/banner/banner';
import AccountInfo from './components/account-info/account-info';
import Reviews from './components/reviews/reviews';
import Products from './components/products/products';
import StoreLocations from './components/store-locations/store-locations';
import DeliveryPartners from './components/delivery-partners/delivery-partners';

const CompanyPage = () => {
  return (
    <>
      <Header />
      <Banner />
      <AccountInfo />
      <Reviews title={'What Buyers are Saying'} />
      <Products />
      <StoreLocations />
      <DeliveryPartners />
    </>
  );
};

export default CompanyPage;
