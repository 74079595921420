import {
  ContentSection,
  FinderWidgetWrapper,
  MobileHideResultsBtn,
  MobileRangeSlider,
  MobileRangeSliderTitle,
  ProductsSection,
  ProductsWrapper,
  ShowMapBtn,
  StoresSection,
  StoresWrapper,
  TopFilterSection,
  TypeFilter,
  TypeFilterItem,
  ViewWrapper,
  Wrapper
} from './search-results-styled';

import { SectionHeader } from 'components/section-header/section-header';
import { PCardSmall } from 'components/p-card-small/p-card-small';
import { StoreCard } from '../../../../components/store-card/store-card';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { RangeSlider } from '../../../../components/range-slider/range-slider';
import { ViewSelection, viewType } from '../../../../components/view-selection/view-selection';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { FinderWidget } from '../finder-widget/finder-widget';
import { MobileFilterBtn } from '../../store-finder-styled';

export const searchType = {
  all: 'all',
  stores: 'stores',
  products: 'products'
};

export const SearchResults = ({
  currentType,
  onResultTypeChange,
  currentViewType,
  setCurrentViewType,
  products,
  stores,
  onHideResultBtnClick,
  isMapVisible,
  onShowMapBtnClick,
  setIsMobileFilterVisible
}) => {
  return (
    <Wrapper isMapVisible={isMapVisible}>
      <MobileFilterBtn
        onClick={() => {
          setIsMobileFilterVisible(true);
        }}
      >
        <SvgIcon name={'filter'} />
        <span>Filter</span>
      </MobileFilterBtn>
      <FinderWidgetWrapper>
        <FinderWidget />
      </FinderWidgetWrapper>
      <TopFilterSection>
        <MobileRangeSlider>
          <MobileRangeSliderTitle>
            <span>50+ results in location</span>
            <ShowMapBtn onClick={onHideResultBtnClick}>Show map</ShowMapBtn>
          </MobileRangeSliderTitle>
          <RangeSlider min={0} max={100} minVal={0} maxVal={100} onChange={() => {}}>
            <span>0.5Km</span>
            <span>100Km</span>
          </RangeSlider>
        </MobileRangeSlider>
        <TypeFilter>
          <TypeFilterItem
            checked={currentType === searchType.all}
            onClick={() => {
              onResultTypeChange(searchType.all);
            }}
          >
            All results
          </TypeFilterItem>
          <TypeFilterItem
            checked={currentType === searchType.stores}
            onClick={() => {
              onResultTypeChange(searchType.stores);
            }}
          >
            Stores
          </TypeFilterItem>
          <TypeFilterItem
            checked={currentType === searchType.products}
            onClick={() => {
              onResultTypeChange(searchType.products);
            }}
          >
            Products
          </TypeFilterItem>
        </TypeFilter>
        <ViewWrapper>
          {!isMapVisible && (
            <GlobalButton type={'secondary'} onClick={onShowMapBtnClick}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
                  stroke='#388638'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z'
                  stroke='#388638'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
              Show on the map
            </GlobalButton>
          )}
          <ViewSelection
            currentViewType={currentViewType}
            setCurrentViewType={setCurrentViewType}
          />
        </ViewWrapper>
      </TopFilterSection>
      {currentType === searchType.all && (
        <ContentSection>
          <StoresSection>
            <SectionHeader link={'#'} title={'Stores'} fontSize={'md'}></SectionHeader>
            <StoresWrapper
              isListView={currentViewType === viewType.list}
              isMapVisible={isMapVisible}
            >
              {stores.map((store, index) => {
                return (
                  <StoreCard
                    store={store}
                    key={index}
                    isListView={currentViewType === viewType.list}
                  />
                );
              })}
            </StoresWrapper>
          </StoresSection>
          <ProductsSection>
            <SectionHeader link={'#'} title={'Products'} fontSize={'md'}></SectionHeader>
            <ProductsWrapper isMapVisible={isMapVisible}>
              {products.map((product, index) => {
                return <PCardSmall product={product} key={index} />;
              })}
            </ProductsWrapper>
          </ProductsSection>
        </ContentSection>
      )}
      {currentType === searchType.products && (
        <ContentSection>
          <ProductsWrapper isMapVisible={isMapVisible}>
            {products.map((product, index) => {
              return <PCardSmall product={product} key={index} />;
            })}
          </ProductsWrapper>
        </ContentSection>
      )}
      {currentType === searchType.stores && (
        <ContentSection>
          <StoresWrapper isListView={currentViewType === viewType.list} isMapVisible={isMapVisible}>
            {stores.map((store, index) => {
              return (
                <StoreCard
                  store={store}
                  key={index}
                  isListView={currentViewType === viewType.list}
                />
              );
            })}
          </StoresWrapper>
        </ContentSection>
      )}
    </Wrapper>
  );
};
