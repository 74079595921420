import {
  Container,
  Title,
  TitleWrapper
} from '../../pages/store-front-trades-services/components/our-services/our-services-styled';
import { ViewSelection, viewType } from '../view-selection/view-selection';
import { Section } from '../../pages/store-front-trades-services/components/section/section';

export const StoreFrontProductSection = ({
  searchBarLabel,
  additionalBtn,
  currentViewType,
  setCurrentViewType,
  children
}) => {
  return (
    <Container>
      <TitleWrapper>
        <Title>Our Services</Title>
        {currentViewType && (
          <ViewSelection
            setCurrentViewType={setCurrentViewType}
            currentViewType={currentViewType}
            isMobileHidden={false}
          />
        )}
      </TitleWrapper>
      <Section
        button={additionalBtn}
        isListView={currentViewType === viewType.list}
        searchBarLabel={searchBarLabel}
      >
        {children}
      </Section>
    </Container>
  );
};
