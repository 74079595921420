import React, { useState } from 'react';

import Header from '../../components/header/header';
import { Container } from './categories-styled';
import Main from './components/main/main';
import { GlobalFilter } from '../../components/global-filter/global-filter';
import { BreadcrumbsContainer } from '../categories-real-estate/categories-real-estate-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';

const breadcrumbs = [
  {
    link: '#',
    name: 'Home'
  },
  {
    link: '#',
    name: 'Trades and services'
  }
];

const Categories = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main onFilterBtnClick={() => setMobileFilterVisible(!isMobileFilterVisible)} />
      </Container>
    </>
  );
};

export default Categories;
