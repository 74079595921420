import { ReactComponent as ThreeDots } from '../../../../assets/three-dots.svg';

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Username',
    accessor: 'username'
  },
  {
    Header: 'Full Name',
    accessor: 'fullName'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: 'Added On',
    accessor: 'addedOn'
  },
  {
    Header: 'Actions',
    accessor: 'dots'
  }
];

export const columnsRows = [
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    username: 'iamlangtoshoriwa123',
    fullName: 'Langton Shoriwa',
    email: 'langtonshoriwa112565@gmail.com',
    contact: '+263 718 418 254',
    role: 'Product Manager',
    addedOn: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  }
];
