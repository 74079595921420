import React from 'react';
import './map.scss';

import map from '../../../../assets/map_small.png';
import location from '../../../../assets/store_locator.svg';
import whatsapp from '../../../../assets/whatsapp.svg';
import phone from '../../../../assets/phone_white.svg';
import message from '../../../../assets/message.svg';
import { AccountAddress, Info } from './map-styled';

const Map = () => {
  return (
    <AccountAddress>
      <img src={map} alt='' />
      <Info>
        <div className='title'>
          <img src={location} alt='' />
          <p>Address</p>
        </div>
        <p className='address'>
          33 Robson Manyika/Orr Street <br />
          (Next To Central Vehicle Registration), Harare CBD
        </p>
        <div className='social'>
          <div className='whatsapp'>
            <img src={whatsapp} alt='' />
          </div>
          <div className='phone'>
            <img src={phone} alt='' />
          </div>
          <div className='message'>
            <img src={message} alt='' />
          </div>
        </div>
      </Info>
    </AccountAddress>
  );
};

export default Map;
