import { ReactComponent as Check } from './check.svg';
import { Container, Content, Title, Text, Icon, Btn } from './approve-vendor-banner-styled';

export const ApproveVendorBanner = () => {
  return (
    <Container>
      <Content>
        <Title>Apply to become AN APPROVED VENDOR</Title>
        <Text>Send reqest and our team will contact with you soon</Text>
        <Btn>Send request</Btn>
      </Content>
      <Icon>
        <Check />
      </Icon>
    </Container>
  );
};
