import { Link } from 'react-router-dom';

import Header from 'components/header/header';
import { AuthPagesContainer } from 'components/auth-pages-container/auth-pages-container';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import { GlobalInput } from 'components/global-input/global-input';
import { GoogleFacebookAuth } from 'components/google-facebook-auth/google-facebook-auth';
import { GrayText, InputGroupWrapper, StyledLink } from '../login/login-styled';
import {
  AcceptTerms,
  AccountType,
  CompareLink,
  RadiosWrapper,
  RegisterButton,
  RegisterForm,
  WhatsupNumberLabelStyled,
  WhatsupNumberLabelStyledText,
  WhatsupNumberLabelStyledTitle
} from './register-styled';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { GlobalInputSelect } from '../../components/global-input-select/global-input-select';
import { Tooltip } from '../../components/tooltip/tooltip';
import { Radio } from '../../components/radio/radio';
import { useState } from 'react';
import { GlobalCheckbox } from '../../components/global-checkbox/global-checkbox';

const WhatsupNumberLabel = () => {
  return (
    <WhatsupNumberLabelStyled>
      <WhatsupNumberLabelStyledTitle>WhatsApp Number</WhatsupNumberLabelStyledTitle>
      <Tooltip placement='bottom-start'>
        <WhatsupNumberLabelStyledText>
          Enter your WhatsApp number here so we can contact you
        </WhatsupNumberLabelStyledText>
      </Tooltip>
    </WhatsupNumberLabelStyled>
  );
};

export const Register = () => {
  const [checkedRadio, setCheckedRadio] = useState('test1');
  const handleChange = (e) => {
    setCheckedRadio(e.target.value);
  };
  return (
    <>
      <Header />
      <AuthPagesContainer title={'Sign Up'}>
        <RegisterForm>
          <AccountType>
            <RadiosWrapper>
              <Radio
                id={'test1'}
                name={'test'}
                value={'test1'}
                title={'Personal Account'}
                cb={(e) => handleChange(e)}
                isChecked={checkedRadio === 'test1'}
              />
              <Radio
                id={'test2'}
                name={'test'}
                value={'test2'}
                title={'Business Account'}
                isChecked={checkedRadio === 'test2'}
                cb={(e) => handleChange(e)}
              />
            </RadiosWrapper>
            <CompareLink>
              <Link to={'#'}>Compare accounts</Link>
            </CompareLink>
          </AccountType>
          <InputGroupWrapper>
            <InputGroup label={<WhatsupNumberLabel />}>
              <GlobalInputSelect placeholder={'Your WhatsApp number'} />
            </InputGroup>
          </InputGroupWrapper>
          <InputGroupWrapper>
            <InputGroup label={'First name'}>
              <GlobalInput placeholder={'Your first name'} />
            </InputGroup>
          </InputGroupWrapper>
          <InputGroupWrapper>
            <InputGroup label={'last name'}>
              <GlobalInput placeholder={'Your last name'} />
            </InputGroup>
          </InputGroupWrapper>
          <InputGroupWrapper>
            <InputGroup label={'Password'}>
              <GlobalInputPassword />
            </InputGroup>
          </InputGroupWrapper>
          <InputGroupWrapper>
            <InputGroup label={'confirm password'}>
              <GlobalInputPassword />
            </InputGroup>
          </InputGroupWrapper>
          <AcceptTerms>
            <GlobalCheckbox isChecked={true}>
              I accept the <Link to={'#'}>Terms and Conditions</Link>
            </GlobalCheckbox>
          </AcceptTerms>

          <RegisterButton height={52} fullWidth>
            <SvgIcon name={'account'} />
            Sign Up
          </RegisterButton>

          <GoogleFacebookAuth description={'Or continue with'}>
            <GrayText>Already have an account?</GrayText>
            <StyledLink>
              <Link to={'/login'}>Login</Link>
            </StyledLink>
          </GoogleFacebookAuth>
        </RegisterForm>
      </AuthPagesContainer>
    </>
  );
};
