import GoogleMapReact from 'google-map-react';
import {
  Container,
  Content,
  StoresSectionStyled,
  Map,
  Main,
  FilterWrapper,
  CardsSection,
  SearchWrapper,
  TopFilterSection,
  SelectWrapper,
  ControlWrapper,
  CardsWrapper,
  MapBtn,
  TopFilterSectionWrapper,
  MobileFilterBtn,
  MobileSort,
  MobileSearch,
  MapTop
} from './stores-section-styled';
import InputWithLabel from 'components/input-with-label/input-with-label';
import { SelectWithLabel } from 'components/select-with-label/select-with-label';
import { Filter } from './components/filter/filter';
import { ViewSelection, viewType } from '../../../../components/view-selection/view-selection';
import { useState } from 'react';
import { StoreCard } from '../../../../components/store-card/store-card';
import { storeFinderStoresMock } from '../../../store-finder/mock/mock';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { useNavigate } from 'react-router';
import { SortWithIcon } from '../../../../components/sort-with-icon/sort-with-icon';
import { SearchOnButtonClick } from '../../../../components/SearchOnButtonClick/SearchOnButtonClick';
import { ReactComponent as Pin } from './pin.svg';
import { LoadMore } from '../../../../components/load-more/load-more';
import { MobSort } from '../../../../components/mob-sort/mob-sort';
import { MobSearch } from '../../../../components/mob-search/mob-search';

export const StoresSection = () => {
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  const navigate = useNavigate();
  return (
    <StoresSectionStyled>
      <Content>
        <Container>
          <Main>
            <FilterWrapper>
              <Filter />
            </FilterWrapper>
            <CardsSection>
              <TopFilterSectionWrapper>
                <TopFilterSection>
                  <MobileFilterBtn>
                    <GlobalButton height={'40'} type={'primary'}>
                      <SvgIcon name={'filter'} />
                      Filters
                    </GlobalButton>
                  </MobileFilterBtn>
                  <SearchWrapper>
                    <InputWithLabel height={38} name={'Search'} />
                  </SearchWrapper>

                  <MobileSort>
                    <MobSearch />
                    <MobSort />
                    <GlobalButton
                      height={'40'}
                      type='secondary'
                      onClick={() => {
                        navigate('/store-finder');
                      }}
                    >
                      <Pin />
                      <span>Store Locator</span>
                    </GlobalButton>
                  </MobileSort>
                  <SelectWrapper>
                    <SelectWithLabel label={'Sort by:'} />
                  </SelectWrapper>
                  <ControlWrapper>
                    <ViewSelection
                      isMobileHidden={false}
                      currentViewType={currentViewType}
                      setCurrentViewType={setCurrentViewType}
                    />
                    <MapBtn>
                      <SvgIcon name='marker-pin' />
                    </MapBtn>
                  </ControlWrapper>
                </TopFilterSection>
              </TopFilterSectionWrapper>

              <CardsWrapper isListView={currentViewType === viewType.list}>
                {storeFinderStoresMock.map((store, index) => (
                  <StoreCard
                    isMobileListDefault={false}
                    key={index}
                    store={store}
                    isListView={currentViewType === viewType.list}
                  />
                ))}
              </CardsWrapper>
              <LoadMore />
            </CardsSection>
          </Main>
        </Container>
      </Content>
      <Map>
        <MapTop>
          <GlobalButton
            height={'40'}
            type='secondary'
            onClick={() => {
              navigate('/store-finder');
            }}
          >
            <Pin />
            Store Locator
          </GlobalButton>
        </MapTop>
        <GoogleMapReact
          bootstrapURLKeys={{ key: '' }}
          defaultZoom={9}
          defaultCenter={{
            lat: -19.23878101020672,
            lng: 29.339569563132443
          }}
        />
      </Map>
    </StoresSectionStyled>
  );
};
