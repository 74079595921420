import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--divider);
  font-weight: 600;
`;
