import {
  ButtonMobile,
  ClearTags,
  Close,
  ControlsSecond,
  FilterButton,
  FilterTags,
  Header,
  HorizontalLine,
  RightSection,
  ShowMore,
  SortBy,
  Tag,
  Title,
  TradesNav,
  TradesNavMobile
} from '../categories-general/general-styled';
import { Container, Inner, MapWrapper, Main, CardsSection, RangeWrapper } from './near-you-styled';

import filter from '../../../../../assets/filter.svg';
import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import { ReactComponent as CloseIco } from '../../../../../assets/close.svg';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import miles from '../../../../../assets/miles.svg';
import year from '../../../../../assets/year.svg';
import product from '../../../../../assets/product2.png';
import map from './map.jpeg';
import PriceSelector from '../../../../../components/price-selector/price-selector';
import { CARS } from '../../../../../dummy-data/cars';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

const FOOD_PRODUCTS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: false,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  }
];

const ActiveTags = ['$0 - $1mln', 'Used', 'Original', 'Harare Province', 'Harare'];

export const NearYou = () => {
  return (
    <Container>
      <Header>
        <Title>Clothing & Apparel</Title>
      </Header>
      <HorizontalLine />
      <Inner>
        <Main>
          <TradesNav>
            <FilterButton>
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <ControlsSecond>
              <SortBy>Sort by:</SortBy>
              <Dropdown name={'Default'} />
            </ControlsSecond>
            <ButtonMobile>
              <SvgIcon name='sort' />
              <p>Sort</p>
            </ButtonMobile>
          </TradesNav>
          <TradesNavMobile>
            <FilterButton>
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <RightSection>
              <ButtonMobile>
                <SvgIcon name='search' />
                <p>Search</p>
              </ButtonMobile>
              <ButtonMobile>
                <SvgIcon name='sort' />
                <p>Sort</p>
              </ButtonMobile>
            </RightSection>
          </TradesNavMobile>
          <FilterTags>
            {ActiveTags.map((tag, index) => (
              <Tag key={index}>
                <Close>
                  <CloseIco />
                </Close>
                {tag}
              </Tag>
            ))}
            <ClearTags>
              <Close>
                <CloseIco />
              </Close>
              Сlear all
            </ClearTags>
          </FilterTags>
          <CardsSection>
            {FOOD_PRODUCTS_LIST.map((data, index) => (
              <PCard product={data} withLabel={true} type={ProductCardTypes.CLOTHING} key={index} />
            ))}
          </CardsSection>
          <ShowMore>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'eye'} />
              See more products
            </GlobalButton>
          </ShowMore>
        </Main>
        <MapWrapper>
          <RangeWrapper>
            <PriceSelector min={0} max={100} km={true} inputsTop={false} />
          </RangeWrapper>
          <img src={map} alt='' />
        </MapWrapper>
      </Inner>
    </Container>
  );
};
