import {
  CategorySelection,
  DropdownItem,
  FilterButton,
  FiltersStyled,
  Label,
  PriceSelection,
  Selector
} from './filters-styled';

import PriceSelector from '../../../../components/price-selector/price-selector';

import arrowUp from '../../../../assets/arrow_up.svg';
import filter from '../../../../assets/filter.svg';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';

export const SELECTORS = [
  {
    name: 'Building & Construction',
    amount: 98
  },
  {
    name: 'Education',
    amount: 1
  },
  {
    name: 'Electricity, Solar & Energy Supplies',
    amount: 10
  },
  {
    name: 'Engineering',
    amount: 20
  },
  {
    name: 'Health, Fitness & Beauty',
    amount: 20
  },
  {
    name: 'Home Repairs & Plumbing',
    amount: 70
  },
  {
    name: 'Legal Services',
    amount: 4
  },
  {
    name: 'Employment & Human Resources',
    amount: 120
  },
  {
    name: 'Marketing & Advertising',
    amount: 2
  }
];

export const TYPES = [
  {
    label: 'Category',
    name: 'Electronic Goods'
  }
];

const Filters = () => {
  return (
    <FiltersStyled>
      <FilterButton>
        Filters
        <img src={filter} alt='' />
      </FilterButton>
      <PriceSelection>
        <Label>Price Range</Label>
        <PriceSelector
          min={0}
          max={1000000}
          onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
          inputsTop={false}
        />
      </PriceSelection>
      {TYPES.map((data, index) => {
        return (
          <DropdownItem key={index}>
            <Label>{data.label}</Label>
            <Dropdown name={data.name} withBorders />
          </DropdownItem>
        );
      })}
      <CategorySelection>
        {SELECTORS.map((data, index) => {
          return (
            <Selector key={index}>
              <p className='name'>
                {data.name}
                <span>{data.amount}</span>
              </p>
              <img src={arrowUp} alt='' />
            </Selector>
          );
        })}
      </CategorySelection>
    </FiltersStyled>
  );
};

export default Filters;
