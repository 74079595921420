import styled from 'styled-components';
import { LatoFont } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Content = styled.div`
  width: 796px;
  max-width: 100%;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Notice = styled.div`
  border-radius: 12px;
  background: var(--gray-bg);
  padding: 14px 20px;
  margin-bottom: 24px;
`;

export const NoticeTitle = styled.div`
  margin-bottom: 2px;
`;

export const NoticeText = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const BookingForm = styled.form``;

export const BookingFormTitle = styled.div`
  font-size: 17px;
  margin-bottom: 18px;
`;

export const BookingFormLeft = styled.div`
  flex-basis: 420px;
  @media ${device.tablet} {
    flex-basis: 100%;
  }
`;

export const InputGroupWrapper = styled.div`
  & + & {
    margin-top: 18px;
  }
`;

export const BookingFormContent = styled.div`
  display: flex;
  margin-bottom: 28px;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const BookingFormRight = styled.div`
  flex-basis: calc(100% - 420px - 24px);
  margin-left: 24px;
  border-radius: 12px;
  background: var(--gray-bg);
  padding: 20px 24px;
  @media ${device.tablet} {
    flex-basis: 100%;
    margin-left: 0;
    margin-top: 22px;
    background: none;
    padding: 0;
  }
`;

export const SlotsTitle = styled.div`
  margin-bottom: 10px;
`;

export const SlotSection = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const SlotDate = styled.div`
  ${LatoFont};
  font-size: 12px;
  line-height: 2;
  margin-bottom: 3px;
  font-weight: 700;
`;

export const SlotsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
`;

export const Slot = styled.div`
  border: 1px solid #bdd7bd;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${LatoFont};
  font-weight: 700;
  line-height: 2;
  font-size: 12px;
`;
