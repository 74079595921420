import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { Container, Union } from './categories-hats-styled';
import Main from './components/main/main';
import React, { useState } from 'react';
import { GlobalFilter } from '../../components/global-filter/global-filter';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Clothing & Apparel' },
  { link: '#', name: 'Men’s Hats' }
];

const CategoriesHats = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Union>
          <GlobalFilter
            isMobileFilterVisible={isMobileFilterVisible}
            onCloseFilterBtnClick={() => {
              setMobileFilterVisible(false);
            }}
          />
          <Main
            onFilterBtnClick={() => {
              setMobileFilterVisible(!isMobileFilterVisible);
            }}
          />
        </Union>
      </Container>
    </>
  );
};

export default CategoriesHats;
