import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--divider);
  @media ${device.tablet} {
    padding-left: 30px;
  }
  & + & {
    margin-top: 12px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ImgWrapper = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 6px;
  align-self: center;
  @media ${device.tablet} {
    width: 60px;
    height: 60px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 12px;
`;

export const Title = styled.div``;

export const Subtitle = styled.div`
  margin-bottom: 10px;
  color: var(--main-subtext);
  ${LatoFont};
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const Description = styled.div`
  display: flex;
  ${LatoFont};
  color: var(--secondary-text);
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const DescriptionItem = styled.div`
  & + & {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid var(--divider);
  }
`;

export const Counter = styled.div`
  margin: 0 12px;
  flex-basis: 120px;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: auto;
  }

  div {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 6px;
    display: inline-flex;
    align-items: center;
    @media ${device.tablet} {
      border: none;
    }
  }
  button {
    ${buttonReset};
    display: flex;
  }
  input {
    ${LatoFont};
    font-size: 12px;
    line-height: 1;
    width: 27px;
    margin: 0 5px;
    text-align: center;
    border: none;
  }
`;

export const DeleteBtn = styled.button`
  ${buttonReset};
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--main-subtext);
  font-size: 12px;
  right: 0;
  top: 0;
  @media ${device.tablet} {
    right: auto;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid var(--divider);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: none;
    }
  }
`;

export const Price = styled.div`
  font-size: 16px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const PriceMobile = styled.span`
  display: none;
  @media ${device.tablet} {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    text-align: right;
  }
`;
