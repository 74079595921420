import { InputGroup } from 'components/input-group/input-group';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';

import { CardSection, CardSectionTitle, InputGroupWrapper } from '../../checkout-styled';

export const BillingDetails = () => {
  return (
    <>
      <CardSection>
        <CardSectionTitle>Personal Details:</CardSectionTitle>
        <InputGroupWrapper>
          <InputGroup label={'last name'}>
            <GlobalInput placeholder={'Your last name'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'last name'}>
            <GlobalInput placeholder={'Your last name'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'Phone Number'}>
            <GlobalInputSelect placeholder={'Your phone number'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'e-mail'}>
            <GlobalInput placeholder={'Your email address'} />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
      <CardSection>
        <CardSectionTitle>Billing Address:</CardSectionTitle>
        <InputGroupWrapper>
          <InputGroup label={'Street address'}>
            <GlobalInput placeholder={'Your street address'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'City'}>
            <GlobalInput placeholder={'Your city'} />
          </InputGroup>
        </InputGroupWrapper>
        <InputGroupWrapper>
          <InputGroup label={'Country'}>
            <GlobalInput placeholder={'Your country'} />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
    </>
  );
};
