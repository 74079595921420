import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  padding: 16px 103px 124px 103px;
  // @media ${device.laptop} {
  //   padding: 16px 16px 124px;
  // }
  @media ${device.tablet} {
    padding: 0 15px 56px 15px;
  }
`;

export const Union = styled.div`
  display: flex;
  align-items: flex-start;
`;
