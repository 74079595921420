export const MailIcon = () => {
  return (
    <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 3.4375L7.53194 8.0813C8.06087 8.45734 8.32534 8.64536 8.61301 8.71819C8.86712 8.78252 9.13288 8.78252 9.38699 8.71819C9.67466 8.64536 9.93913 8.45734 10.4681 8.0813L17 3.4375M4.84 14H13.16C14.5041 14 15.1762 14 15.6896 13.7343C16.1412 13.5006 16.5083 13.1277 16.7384 12.6691C17 12.1477 17 11.4651 17 10.1V4.9C17 3.53487 17 2.85231 16.7384 2.3309C16.5083 1.87225 16.1412 1.49936 15.6896 1.26567C15.1762 1 14.5041 1 13.16 1H4.84C3.49587 1 2.82381 1 2.31042 1.26567C1.85883 1.49936 1.49168 1.87225 1.26158 2.3309C1 2.85231 1 3.53487 1 4.9V10.1C1 11.4651 1 12.1477 1.26158 12.6691C1.49168 13.1277 1.85883 13.5006 2.31042 13.7343C2.82381 14 3.49587 14 4.84 14Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
