import { useEffect, useState } from 'react';
import { size } from '../assets/style-helpers/breakpoints';

export const useWindow = () => {
  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   if (window && window.innerWidth) {
  //     setIsMobile(window.innerWidth <= Number(size.mobile.replace('px', '')));
  //   }
  // }, [window]);
  // return isMobile;
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return width <= Number(size.mobile.replace('px', ''));
};
