import styled, { css } from 'styled-components';
import { LatoFont, PoppinsFont } from '../../../../assets/style-helpers/style-helpers';

export const Main = styled.div``;

export const Navigation = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--divider);
  a + a {
    margin-left: 32px;
  }
`;

export const LinkItem = styled.div`
  color: var(--main-subtext);
  ${PoppinsFont};
  position: relative;
  padding-bottom: 16px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  font-weight: 600;
  &:hover {
    color: var(--main-text);
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px 4px 0px 0px;
    background: var(--main-green);
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
    transform: scaleX(0);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--divider);
    border-radius: 50%;
    width: 24px;
    margin-left: 6px;
    height: 24px;
    ${LatoFont};
    font-size: 10px;
    line-height: 1;
    font-weight: 700;
    color: var(--main-text);
    transition: all 0.3s;
  }
  ${(props) =>
    props.isActive &&
    css`
      color: var(--main-text);
      span {
        background: #e7f0e7;
        border-color: #e7f0e7;
        color: var(--main-green);
      }
      &::after {
        transform: scaleX(1);
      }
    `}
`;
