import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from './shopping-centres-styled';
import Header from '../../components/header/header';
import { ShoppingCenterSection } from '../home/components/shopping-centers-section/shopping-center-section';
import { PopularShoppingCentres } from './components/popular-shopping-centres/popular-shopping-centres';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Shopping Centres' }
];

export const ShoppingCentres = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
      </Container>
      <ShoppingCenterSection />
      <PopularShoppingCentres />
    </>
  );
};
