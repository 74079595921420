import styled, { css } from 'styled-components';

export const Icon = styled.div`
  opacity: 0;
  transition: all 0.3s;
  position: absolute;
  top: 3px;
  right: 3px;
  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}
`;

export const PaymentCardStyled = styled.div`
  border: 1px solid var(--divider);
  border-radius: 6px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  ${(props) =>
    props.isActive &&
    css`
      background: rgba(247, 109, 33, 0.07);
      border-color: var(--main-orange);
    `}
`;
