import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  ${mainContainer};
  display: flex;
  min-height: 100vh;

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
  @media ${device.mobile} {
  }
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;

    @media ${device.laptop} {
      overflow-y: scroll;
      height: auto;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 3px;
        background: rgb(243 243 243);
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(56, 134, 56, 0.1);
      }
    }

    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
    padding-top: 5px;
  }
  @media ${device.mobile} {
    top: 135px;
    padding-top: 12.5px;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 14px;
  }
  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 10px;
  }
`;

export const Tabs = styled.div`
  margin-top: 4px;
`;

export const TabsHeader = styled.div`
  border-bottom: 1px solid #e2e5eb;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const TabsHeaderButton = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-right: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TabsHeaderButtonDefault = styled.div`
  color: #acadb9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s;

  &:hover {
    color: #29263a;
    transition: all 0.6s;
    cursor: pointer;
  }

  > span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    width: 24px;
    height: 24px;
    line-height: 20px;
    color: #29263a;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }
`;

export const Notification = styled.span``;

export const TabsHeaderButtonActive = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #29263a;

  > span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    width: 24px;
    height: 24px;
    line-height: 20px;
    background: #e7f0e7;
    color: #388638;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: -11px;
    content: '';
    width: 100%;
    height: 4px;
    background: #388638;
    border-radius: 4px 4px 0px 0px;
  }
`;

export const Tab = styled.div`
  margin-top: 25px;
`;

export const Sorts = styled.div`
  display: flex;
  align-items: center;
`;

export const SortActive = styled.div`
  background: #ecf6ec;
  border: 1px solid #388638;
  border-radius: 20px;
  padding: 6px 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  margin-right: 12px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
`;

export const Sort = styled.div`
  background: #ffffff;
  border: 1px solid #c3dbc3;
  border-radius: 20px;
  padding: 6px 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  margin-right: 12px;
`;

export const MyNotifications = styled.div`
  margin-top: 30px;
  margin-bottom: 100px;
  @media ${device.mobile} {
    margin-top: 20px;
    margin-bottom: 70px;
  }
`;

export const NotificationBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const NotificationPhoto = styled.div`
  position: relative;
  background: linear-gradient(
      166.43deg,
      rgba(255, 255, 255, 0.1) 9.72%,
      rgba(255, 255, 255, 0) 78.4%
    ),
    #e7f0e7;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
`;

export const NotificationPoint = styled.span`
  position: absolute;
  background: #e54d45;
  right: 3px;
  top: 9px;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
`;

export const NotificationBox = styled.div`
  margin-left: 14px;
  @media ${device.mobile} {
    width: 80%;
  }
`;

export const NotificationTitle = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
  width: 314px;
  @media ${device.mobile} {
    width: auto;
  }
`;

export const NotificationData = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #acadb9;
  display: flex;
  align-items: center;
  margin-top: 5px;

  > svg {
    margin-right: 5px;
  }
`;

export const NotificationName = styled.div`
  display: inline-block;
  margin-right: 3px;
  font-weight: 700;
`;

export const Photo = styled.img``;
