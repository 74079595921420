import styled from 'styled-components';
import { mainContainer } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Wrapper = styled.div`
  margin-bottom: 80px;
  @media ${device.tablet} {
    margin-bottom: 40px;
  }
`;

export const Container = styled.div`
  ${mainContainer};
`;
