import styled, { css } from 'styled-components';
import { LatoFont } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  width: 580px;
  z-index: 100000;
  @media ${device.mobile} {
    flex-wrap: wrap;
    width: 375px;
  }
`;

export const Side = styled.div`
  background: #f9f9f9;
  padding: 16px 0;
  @media ${device.mobile} {
    width: 50%;
  }
`;

export const Main = styled.div`
  width: 100%;
  max-width: 380px;
  @media ${device.mobile} {
    width: 50%;
  }
`;

export const TabTitle = styled.div`
  padding: 6px 16px 6px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 204px;
  cursor: pointer;
  @media ${device.mobile} {
    width: 100%;
  }
  ${(props) =>
    props.isActive
      ? css`
          background: white;
          color: var(--main-green);
        `
      : css`
          background: #f9f9f9;
        `}
`;

export const FiltersWrapper = styled.div`
  padding: 24px 32px;
  ${LatoFont};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const FilterWidgetBottomDesktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const FilterWidgetBottomMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;
