import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import Background from './background/background';
import RealEstate from './real-estate/real-estate';
import {
  MainStyled,
  Container
} from '../../../categories-motor-vehicles/components/main/main-styled';

export const slides = [
  {
    title: 'Real Estate',
    subtitle: 'House for sale',
    buttonText: '000-120-456-789'
  },
  {
    title: 'Real Estate',
    subtitle: 'House for sale',
    buttonText: '000-120-456-000'
  },
  {
    title: 'Real Estate',
    subtitle: 'House for sale',
    buttonText: '000-120-000-000'
  }
];

const Main = ({ onFilterBtnClick }) => {
  return (
    <MainStyled>
      <Container>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 2500 }}
          pagination={{ clickable: true }}
        >
          {slides.map((item) => {
            return (
              <SwiperSlide key={item.title}>
                <Background
                  title={item.title}
                  subtitle={item.subtitle}
                  buttonText={item.buttonText}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <RealEstate onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;
