import { useState } from 'react';
import { AddNewProductLayout } from '../../../dashboard/components/add-new-product-layout/add-new-product-layout';
import { ProductDetails } from '../add-new-product-form/components/product-details/product-details';
import { ProductCategories } from '../add-new-product-form/components/product-categories/product-categories';
import { ProductImage } from '../add-new-product-form/components/product-image/product-image';
import { ProductPricingHireItem } from '../../../dashboard/components/add-product-form-components/product-pricing-hire-item';
import { ProductAttributes } from '../add-new-product-form/components/product-attributes/product-attributes';
import { ProductSubmitBtns } from '../../../dashboard/components/add-product-form-components/product-submit-btns';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Service' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductSingleForHireItemProfile = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <AddNewProductLayout
      isSidebarHidden={isSidebarHidden}
      setIsSidebarHidden={setIsSidebarHidden}
      pageTitle={'Add Product For Hire'}
      isProductPromotion={true}
    >
      <ProductDetails selectOptions={selectOptions} />
      <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
      <ProductImage />
      <ProductPricingHireItem selectOptions={selectOptions} />
      <ProductAttributes selectOptions={selectOptions} />
      <ProductSubmitBtns
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      />
    </AddNewProductLayout>
  );
};
