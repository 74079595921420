import { SectionHeader } from 'components/section-header/section-header';
import { ShoppingCentersInfo } from '../../../../components/shopping-centres-info/shopping-centers-info';
import { Container } from './shopping-center-section-styled';

export const ShoppingCenterSection = ({ title = 'Shopping Centres' }) => {
  return (
    <Container>
      <SectionHeader title={title} link={'#'} />
      <ShoppingCentersInfo />
    </Container>
  );
};
