import { SectionHeader } from '../../../../components/section-header/section-header';
import {
  CardWrapper,
  InputWrapper,
  RecentUpdatesStyled,
  SortWrapper,
  Top
} from './recent-updates-styled';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { SelectWithLabel } from '../../../../components/select-with-label/select-with-label';
import blogImg from '../../../../assets/temp-img/blog-img.png';
import { BlogCard } from '../blog-card/blog-card';

const blogs = [
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  }
];

export const RecentUpdates = () => {
  return (
    <RecentUpdatesStyled>
      <SectionHeader link={'updates'} title={'Recent BlogUpdates'} />
      <Top>
        <InputWrapper>
          <InputWithLabel
            height={40}
            placeholder={'Enter what you are looking for...'}
            isLabel={false}
            inputClassName={'no-padding'}
          />
        </InputWrapper>
        <SortWrapper>
          <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
        </SortWrapper>
      </Top>
      <CardWrapper>
        {blogs.map((blog, i) => {
          return <BlogCard blog={blog} key={i} />;
        })}
      </CardWrapper>
    </RecentUpdatesStyled>
  );
};
