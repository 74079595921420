import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import Items from '../../components/items/items';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  Title
} from './all-sell-items-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'All Sell Items' }
];

export const AllSellItems = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>All Sell Items</Title>
      <Items />
    </>
  );
};
