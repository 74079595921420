import styled from 'styled-components';

export const Container = styled.div``;

export const FilterTitle = styled.div`
  padding: 14px 20px;
  color: var(--main-white);
  background: var(--main-green);
  border-radius: 6px;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterMain = styled.div`
  padding: 32px 0;
`;

export const Categories = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const CategoriesTitle = styled.div`
  margin-bottom: 16px;
`;

export const CategoriesList = styled.div``;

export const CategoriesItem = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--main-green);
  }

  & + & {
    margin-top: 12px;
  }
`;
