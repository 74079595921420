import { SectionHeader } from 'components/section-header/section-header';
import blogImg from 'assets/temp-img/blog-img.png';
import { CardsWrapper } from './featured-styled';
import { BlogCard } from '../blog-card/blog-card';

const blogs = [
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  }
];

export const Featured = () => {
  return (
    <>
      <SectionHeader title='Featured BlogUpdates' />
      <CardsWrapper>
        {blogs.map((blog, i) => (
          <BlogCard blog={blog} key={i} />
        ))}
      </CardsWrapper>
    </>
  );
};
