import {
  Section,
  SectionText,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import styled from 'styled-components';

import imgPlaceholder from 'assets/icons/img-placeholder.svg';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { device } from 'assets/style-helpers/breakpoints';

const UploadImgSection = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const ImgWrapper = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: center;
  .img-placeholder {
    width: 45px;
    height: 45px;
  }
`;

const BusinessLogoWrapper = styled.div`
  flex-basis: 150px;
  margin-right: 24px;
  @media ${device.mobile} {
    max-width: 100%;
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 16px;
    ${ImgWrapper} {
      max-width: 150px;
    }
  }
`;

const CoverWrapper = styled.div`
  flex-basis: calc(100% - 150px - 24px);
  @media ${device.laptop} {
    max-width: 460px;
  }
  @media ${device.mobile} {
    max-width: 100%;
    flex-basis: auto;
  }
`;

const UploadImgSectionTitle = styled.div`
  color: var(--main-gray-3);
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const UploadBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const StoreFrontSettingsSection = () => {
  return (
    <Section>
      <SectionTitle>Store Front Settings</SectionTitle>
      <SectionText>Manage how buyers will see your storefront</SectionText>
      <UploadImgSection>
        <BusinessLogoWrapper>
          <UploadImgSectionTitle>business logo</UploadImgSectionTitle>
          <ImgWrapper>
            <img src={imgPlaceholder} alt='' className='img-placeholder' />
          </ImgWrapper>
          <UploadBannerWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </UploadBannerWrapper>
        </BusinessLogoWrapper>
        <CoverWrapper>
          <UploadImgSectionTitle>Store front cover image</UploadImgSectionTitle>
          <ImgWrapper>
            <img src={imgPlaceholder} alt='' className='img-placeholder' />
          </ImgWrapper>
          <UploadBannerWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </UploadBannerWrapper>
        </CoverWrapper>
      </UploadImgSection>
    </Section>
  );
};
