export const MsgIcon = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.44444 6.95893H11M5.44444 10.751H14.3333M8.42639 17.2516H15.6667C17.5335 17.2516 18.4669 17.2516 19.18 16.8974C19.8072 16.5857 20.3171 16.0885 20.6367 15.4769C21 14.7816 21 13.8715 21 12.0511V6.20052C21 4.38017 21 3.46999 20.6367 2.77471C20.3171 2.16312 19.8072 1.66588 19.18 1.35426C18.4669 1 17.5335 1 15.6667 1H6.33333C4.46649 1 3.53307 1 2.82003 1.35426C2.19282 1.66588 1.68289 2.16312 1.36331 2.77471C1 3.46999 1 4.38017 1 6.20052V19.782C1 20.3593 1 20.648 1.12137 20.7962C1.22691 20.9252 1.38697 21.0002 1.55616 21C1.7507 20.9998 1.98186 20.8195 2.44417 20.4588L5.09468 18.3912C5.63613 17.9689 5.90685 17.7577 6.20832 17.6075C6.47578 17.4743 6.76047 17.3769 7.05468 17.318C7.3863 17.2516 7.733 17.2516 8.42639 17.2516Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
