import React from 'react';
import './banner.scss';

import {
  BannerSection,
  Logo,
  Text,
  LineOne,
  LineTwo,
  LineThree,
  Button,
  Emoji,
  Mark,
  Fashion,
  MarkMobile
} from './banner-styled';

import background from '../../../../assets/home_background.png';
import cart from '../../../../assets/cart.svg';
import oval from '../../../../assets/red-oval.svg';
import ovalMobile from '../../../../assets/red-oval-mobile.svg';

const Banner = () => {
  return (
    <BannerSection>
      <Logo src={background} alt='' />
      <Text>
        <LineOne>Men & Women</LineOne>
        <LineTwo>
          <Emoji>&#128293;</Emoji>
          <Mark src={oval} alt='' />
          <MarkMobile src={ovalMobile} alt='' />
          Sale &nbsp;
          <Fashion>Fashion</Fashion>
        </LineTwo>
        <LineThree>Baseball Cap</LineThree>
      </Text>
      <Button>
        <img src={cart} alt='' />
        <p>Shop Now</p>
      </Button>
    </BannerSection>
    // <section className='banner'>
    //     <img className='logo' src={background} alt='' />
    //     <div className='text'>
    //         <p className='line_one'>Men & Women</p>
    //         <p className='line_two'>
    //             <span className='emoji'>&#128293; </span>
    //             <img className='mark' src={oval} alt='' />
    //             Sale{' '}
    //             <span style={{ position: 'relative', marginLeft: '25px', zIndex: '1000' }}>Fashion</span>
    //         </p>
    //         <p className='line_three'>Baseball Cap</p>
    //     </div>
    //     <button>
    //         <img src={cart} alt='' />
    //         <p>Shop Now</p>
    //     </button>
    // </section>
  );
};

export default Banner;
