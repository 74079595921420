import {
  Title,
  Container,
  SidebarButton,
  HelpAndTutorialsWrapper,
  VideosSection,
  Video,
  PlayButton,
  VideoWrapper,
  Name,
  Text,
  Duration,
  VideoImage
} from './help-and-tutorials-sidebar-styled';
import videoImage from './help-video-image.png';
import playButton from './play-button-icon.svg';

export const HELP_AND_TUTORIALS_MOCK = [
  {
    video: videoImage,
    name: 'How To Upload A Product',
    duration: '1:30'
  },
  {
    video: videoImage,
    name: 'How To Upload A Product',
    duration: '1:30'
  },
  {
    video: videoImage,
    name: 'How To Upload A Product',
    duration: '1:30'
  },
  {
    video: videoImage,
    name: 'How To Upload A Product',
    duration: '1:30'
  }
];

const HelpAndTutorialsSidebar = ({ showSidebar, setShowSidebar }) => {
  return (
    showSidebar && (
      <HelpAndTutorialsWrapper>
        <Container>
          <SidebarButton onClick={() => setShowSidebar(false)} alt=''>
            Help & Tutorials
          </SidebarButton>
          <Title>Notifications</Title>
          <VideosSection>
            {HELP_AND_TUTORIALS_MOCK.map((data, index) => {
              return (
                <VideoWrapper key={index}>
                  <Video key={index}>
                    <VideoImage src={data.video} />
                    <PlayButton src={playButton} />
                  </Video>
                  <Text>
                    <Name>{data.name}</Name>
                    <Duration>{data.duration}</Duration>
                  </Text>
                </VideoWrapper>
              );
            })}
          </VideosSection>
        </Container>
      </HelpAndTutorialsWrapper>
    )
  );
};

export default HelpAndTutorialsSidebar;
