import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Thumbs } from 'swiper';
import {
  Container,
  MainSlider,
  MainSliderSlide,
  NextButton,
  PrevButton,
  ThumbsContainer,
  ThumbSlierSlide,
  FavBtn
} from './product-gallery-styled';
import { SvgIcon } from '../svg-icon/svg-icon';

const mockImagesArray = [
  'https://picsum.photos/500/500',
  'https://picsum.photos/800/500',
  'https://picsum.photos/600/600',
  'https://picsum.photos/400/400',
  'https://picsum.photos/600/400'
];

export const ProductGallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mainSwiper, setMainSwiper] = useState(null);
  const [isFav, setIsFav] = useState(false);

  return (
    <Container>
      <MainSlider>
        <FavBtn isFav={isFav} onClick={() => setIsFav(!isFav)}>
          <SvgIcon name={'heart-2'} />
        </FavBtn>
        <PrevButton
          onClick={() => {
            mainSwiper.slidePrev();
          }}
        >
          <SvgIcon name={'slider-arrow'} />
        </PrevButton>
        <NextButton
          onClick={() => {
            mainSwiper.slideNext();
          }}
        >
          <SvgIcon name={'slider-arrow'} />
        </NextButton>
        <Swiper modules={[Thumbs]} thumbs={{ swiper: thumbsSwiper }} onSwiper={setMainSwiper}>
          {mockImagesArray.map((img, key) => (
            <SwiperSlide key={key}>
              <MainSliderSlide>
                <img src={img} alt='' />
              </MainSliderSlide>
            </SwiperSlide>
          ))}
        </Swiper>
      </MainSlider>
      <ThumbsContainer>
        <Swiper
          modules={[Thumbs]}
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          slidesPerView={4}
          spaceBetween={8}
        >
          {mockImagesArray.map((img, key) => (
            <SwiperSlide key={key}>
              <ThumbSlierSlide>
                <img src={img} alt='' />
              </ThumbSlierSlide>
            </SwiperSlide>
          ))}
        </Swiper>
      </ThumbsContainer>
    </Container>
  );
};
