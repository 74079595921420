import { SidebarStyled, SidebarContent, SidebarTop } from './sidebar-styled';

export const Sidebar = ({ children }) => {
  const [header, content] = children;
  return (
    <SidebarStyled>
      <SidebarTop>{header}</SidebarTop>
      <SidebarContent>{content}</SidebarContent>
    </SidebarStyled>
  );
};
