import {
  StyledButton,
  StyledGrayButton,
  StyledGreenButton,
  StyledOrangeButton,
  StyledOrangeSecondaryButton,
  StyledPrimaryRoundButton,
  StyledSecondaryButton,
  TransparentButton
} from './global-button-styled';

export const GlobalButton = ({
  height,
  onClick,
  disabled,
  children,
  fullWidth = false,
  className,
  type = 'primary',
  checked = false,
  t = 'button'
}) => {
  switch (type) {
    case 'primary':
      return (
        <StyledButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </StyledButton>
      );
    case 'secondary':
      return (
        <StyledSecondaryButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </StyledSecondaryButton>
      );
    case 'primary-round':
      return (
        <StyledPrimaryRoundButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </StyledPrimaryRoundButton>
      );
    case 'green':
      return (
        <StyledGreenButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          checked={checked}
          type={t}
        >
          {children}
        </StyledGreenButton>
      );
    case 'orange':
      return (
        <StyledOrangeButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </StyledOrangeButton>
      );
    case 'orange-secondary':
      return (
        <StyledOrangeSecondaryButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          checked={checked}
          type={t}
        >
          {children}
        </StyledOrangeSecondaryButton>
      );
    case 'gray':
      return (
        <StyledGrayButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </StyledGrayButton>
      );
    case 'transparent':
      return (
        <TransparentButton
          onClick={onClick}
          disabled={disabled}
          height={height}
          fullWidth={fullWidth}
          className={className}
          type={t}
        >
          {children}
        </TransparentButton>
      );
  }
};
