import { Block, Container, Item, ItemName, ItemValue, List, Title } from './order-info-styled';

export const OrderInfo = () => {
  return (
    <Container>
      <Block>
        <Title>Order Information</Title>
        <List>
          <Item>
            <ItemName>Order Number</ItemName>
            <ItemValue>#121232</ItemValue>
          </Item>
          <Item>
            <ItemName>Date</ItemName>
            <ItemValue>31/12/2022</ItemValue>
          </Item>
          <Item>
            <ItemName>Amount</ItemName>
            <ItemValue>$1250</ItemValue>
          </Item>
          <Item>
            <ItemName>Payment Method</ItemName>
            <ItemValue>Mukuru</ItemValue>
          </Item>
          <Item>
            <ItemName>Payment Ref</ItemName>
            <ItemValue>117829</ItemValue>
          </Item>
          <Item>
            <ItemName>Oreder Tracking Code</ItemName>
            <ItemValue>EW2324IUK</ItemValue>
          </Item>
        </List>
      </Block>
      <Block>
        <Title>Billing Information</Title>
        <List>
          <Item>
            <ItemName>Name</ItemName>
            <ItemValue>Langton Shoriwa</ItemValue>
          </Item>
          <Item>
            <ItemName>Date</ItemName>
            <ItemValue>langtonshoriwa@gmail.com</ItemValue>
          </Item>
          <Item>
            <ItemName>Phone Number</ItemName>
            <ItemValue>+380 66 12 23 123</ItemValue>
          </Item>
        </List>
      </Block>
      <Block>
        <Title>Delivery Information</Title>
        <List>
          <Item>
            <ItemName>Contact Person</ItemName>
            <ItemValue>Langton Shoriwa</ItemValue>
          </Item>
          <Item>
            <ItemName>Date</ItemName>
            <ItemValue>langtonshoriwa@gmail.com</ItemValue>
          </Item>
          <Item>
            <ItemName>Phone Number</ItemName>
            <ItemValue>+380 66 12 23 123</ItemValue>
          </Item>
          <Item>
            <ItemName>Delivery Address</ItemName>
            <ItemValue>6674 21 Street, Glen View 8, Harare Zimbabwe</ItemValue>
          </Item>
          <Item>
            <ItemName>Preffered Day & Time</ItemName>
            <ItemValue>Tuesday, 15:00</ItemValue>
          </Item>
        </List>
      </Block>
    </Container>
  );
};
