import { GlobalCheckbox } from '../../../../../../components/global-checkbox/global-checkbox';
import { GlobalSelect } from '../../../../../../components/global-select/global-select';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import {
  CheckboxWrapper,
  SelectWrapper,
  TopSection,
  TopSectionInputGroupInner
} from '../../add-new-product-form-hire-item-styled.jsx';

export const ProductOffer = ({ selectOptions }) => {
  return (
    <TopSection>
      <InputGroup label='product offer'>
        <TopSectionInputGroupInner>
          <SelectWrapper>
            <GlobalSelect options={selectOptions} />
          </SelectWrapper>
          <CheckboxWrapper>
            <GlobalCheckbox isChecked={true}> Deals Of The Day</GlobalCheckbox>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <GlobalCheckbox> Featured Product</GlobalCheckbox>
          </CheckboxWrapper>
        </TopSectionInputGroupInner>
      </InputGroup>
    </TopSection>
  );
};
