import styled, { css } from 'styled-components';

import imgPlaceholder from './img-placeholder.svg';
import { buttonReset, LatoFont } from 'assets/style-helpers/style-helpers';
import { title30 } from 'assets/style-helpers/typography';
import { device } from 'assets/style-helpers/breakpoints';

export const SidePreviewStyled = styled.div`
  padding: 28px;
  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 16px;
  @media ${device.laptop} {
    width: 530px;
    position: absolute;
    right: 0;
    top: 0;
    background: var(--main-white);
    z-index: 100;
    padding: 42px 103px 42px 32px;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            transform: translateX(100%);
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const MobileBackBtn = styled.button`
  ${buttonReset};
  display: none;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    color: var(--main-green);
    margin-bottom: 20px;
    flex-basis: 100%;
  }
`;

export const MobileTitle = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    font-size: 24px;
    margin-bottom: 24px;
    flex-basis: 100%;
  }
`;

export const PreviewBtn = styled.div`
  position: absolute;
  left: -108px;
  top: 600px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: #ebf3eb;
  border-radius: 6px 6px 0px 0px;
  color: var(--main-green);
  ${(props) =>
    !props.isSidebarHidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  svg {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const PreviewBtnSidebar = styled.div`
  position: absolute;
  left: -126px;
  top: 340px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: #ebf3eb;
  border-radius: 6px 6px 0 0;
  color: var(--main-green);
  ${(props) =>
    props.isSidebarHidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  svg {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const GalleryWrapper = styled.div`
  @media ${device.tablet} {
    flex-basis: 50%;
    padding-right: 16px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
    padding-right: 0;
  }
`;

export const Main = styled.div`
  @media ${device.tablet} {
    flex-basis: 50%;
  }

  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const Category = styled.div`
  ${LatoFont};
  margin-bottom: 4px;
  line-height: 24px;
  color: var(--main-gray-3);
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 12px;
`;

export const Price = styled.div`
  ${title30};
`;

export const Subtitle = styled.div`
  font-size: 12px;
  color: var(--main-gray-3);
  line-height: 1;
  margin-bottom: 16px;
  ${LatoFont};
`;

export const Description = styled.div`
  ${LatoFont};
  margin-bottom: 20px;
`;

export const GalleryImages = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ImgPlaceholder = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  height: 93px;
  background: url(${imgPlaceholder}) no-repeat center center, #f7f7f7;
  background-size: 40px 40px;
`;

export const ImgPlaceholderBig = styled(ImgPlaceholder)`
  height: 420px;
  background-size: 72px 72px;
  @media ${device.mobile} {
    height: 360px;
  }
`;

export const DescriptionItem = styled.div`
  ${LatoFont};

  & + & {
    margin-top: 20px;
  }
`;

export const DescriptionTitle = styled.div`
  margin-bottom: 6px;
`;

export const DescriptionList = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionListItem = styled.div`
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid var(--divider);

  & + & {
    margin-left: 8px;
  }
`;

export const DescriptionColor = styled.div`
  width: 28px;
  height: 28px;
  border: 1px solid var(--divider);
  border-radius: 6px;

  & + & {
    margin-left: 8px;
  }
`;

export const Attributes = styled.div`
  margin-top: 34px;
`;

export const AttributesTitle = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--divider);
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 4px 4px 0px 0px;
    height: 4px;
    width: 130px;
    background: var(--main-green);
  }
`;

export const AttributesList = styled.div``;

export const AttributesListItem = styled.div`
  ${LatoFont};
  span {
    font-weight: 700;
  }
  & + & {
    margin-top: 8px;
  }
`;
