import styled from 'styled-components';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { paragraph30 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: start;

  .slick-slider {
    display: flex;
    align-items: center;
    padding: 0 100px;
  }
`;

export const Filters = styled.section`
  min-width: 238px;
  margin-right: 62px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;

  @media ${device.tablet} {
    width: 130px;
    font-size: 14px;
    padding: 11px 26px;
    margin-bottom: unset;

    img {
      margin-right: 11px;
    }
  }

  @media ${device.mobile} {
    width: 130px;
    font-size: 14px;
    padding: 8px 26px;
    margin-bottom: unset;

    img {
      margin-right: 11px;
    }
  }
`;

export const CategorySelection = styled.div`
  .checkbox {
    margin-bottom: 10px;
  }
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;

  .arrow-down {
    transform: rotate(180deg);
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #29263a;
  }

  img {
    cursor: pointer;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 30px 0;
`;

export const PriceSelection = styled.div`
  margin-bottom: 45px;
`;

export const Colors = styled.div`
  margin-bottom: 35px;
`;

export const FilterByButton = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const OurProducts = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const View = styled.div`
  display: flex;
  align-items: center;
`;

export const GridButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 8px;
  background-color: var(--main-green);
  margin-right: 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
`;

export const ListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: transparent;
`;

export const ProductsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const ProductsNavMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
`;

export const ButtonMobile = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

  svg {
    margin-right: 11px;
  }

  p {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ControlsSecond = styled.div`
  display: flex;
  align-items: center;
  right: 3px;
  top: 3px;
  border: 1px solid #e2e5eb;
  border-radius: 6px;
  padding: 2px;
`;

export const SortBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  background: #f5f7fb;
  border-radius: 6px;
  margin-right: 19px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #acadb9;
`;

export const ProductCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    max-width: unset;
    margin-bottom: 30px;
    @media ${device.mobile} {
      margin-bottom: 15px;
      width: 48%;
    }
  }
`;

export const SeeMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
