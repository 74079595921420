import React from 'react';

import phone from 'assets/phone.svg';
import chat from 'assets/chat.svg';
import arrow_up from 'assets/arrow_up.svg';
import { ButtonsDivider, ButtonsWrapper, TopSection } from './header-top-section-styled';
import { Container, Inner } from '../../header-styled';
import { StoreFinderBtn } from '../store-finder-btn/store-finder-btn';
import { GlobalButton } from '../../../global-button/global-button';

const HeaderTopSection = () => {
  return (
    <TopSection>
      <Container>
        <Inner>
          <ButtonsWrapper>
            <StoreFinderBtn />
            <ButtonsDivider />
            <GlobalButton height={28} type={'secondary'}>
              <svg
                width='14'
                height='14'
                viewBox='0 0 14 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.40619 4.33333C9.40619 5.04058 9.13128 5.71885 8.64194 6.21895C8.1526 6.71905 7.48891 7 6.79688 7C6.10484 7 5.44115 6.71905 4.95181 6.21895C4.46247 5.71885 4.18756 5.04058 4.18756 4.33333M1.33899 3.93426L0.882362 9.53425C0.784267 10.7373 0.735219 11.3388 0.9342 11.8028C1.10902 12.2105 1.41193 12.5469 1.79495 12.7588C2.23089 13 2.8215 13 4.00272 13H9.59103C10.7723 13 11.3629 13 11.7988 12.7588C12.1818 12.5469 12.4847 12.2105 12.6596 11.8028C12.8585 11.3388 12.8095 10.7373 12.7114 9.53425L12.2548 3.93426C12.1704 2.89917 12.1282 2.38162 11.9039 1.9899C11.7064 1.64496 11.4123 1.3684 11.0595 1.1959C10.6589 1 10.1507 1 9.1344 1L4.45935 1C3.44301 1 2.93484 1 2.53425 1.1959C2.18149 1.3684 1.88739 1.64496 1.68988 1.9899C1.46559 2.38162 1.42339 2.89917 1.33899 3.93426Z'
                  stroke='#388638'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Shopping Centres
            </GlobalButton>
          </ButtonsWrapper>
          <div className='contact-info'>
            <a href='tel:+263 712 162 805' className='phone'>
              <img src={phone} alt='' />
              <p>+263 712 162 805</p>
            </a>
            <div className='line' />
            <div className='chat'>
              <img className='chat-icon' src={chat} alt='' />
              <p>Help</p>
              <img className='arrow-icon' src={arrow_up} alt='' />
            </div>
            <button className='sell-items-btn'>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_16_113717)'>
                  <path
                    d='M5.33594 5.33203H5.34261M1.33594 3.46536L1.33594 6.44837C1.33594 6.7745 1.33594 6.93756 1.37278 7.09101C1.40544 7.22705 1.45931 7.35711 1.53242 7.47641C1.61487 7.61097 1.73017 7.72627 1.96078 7.95687L7.0732 13.0693C7.86523 13.8613 8.26125 14.2573 8.7179 14.4057C9.11959 14.5362 9.55228 14.5362 9.95397 14.4057C10.4106 14.2573 10.8066 13.8613 11.5987 13.0693L13.0732 11.5948C13.8652 10.8027 14.2612 10.4067 14.4096 9.95006C14.5401 9.54838 14.5401 9.11568 14.4096 8.714C14.2612 8.25734 13.8652 7.86132 13.0732 7.06929L7.96077 1.95687C7.73017 1.72627 7.61487 1.61097 7.48032 1.52851C7.36102 1.45541 7.23096 1.40153 7.09491 1.36887C6.94146 1.33203 6.7784 1.33203 6.45228 1.33203L3.46927 1.33203C2.72253 1.33203 2.34917 1.33203 2.06395 1.47736C1.81307 1.60519 1.60909 1.80916 1.48126 2.06004C1.33594 2.34526 1.33594 2.71863 1.33594 3.46536ZM5.66927 5.33203C5.66927 5.51613 5.52003 5.66536 5.33594 5.66536C5.15184 5.66536 5.00261 5.51613 5.00261 5.33203C5.00261 5.14794 5.15184 4.9987 5.33594 4.9987C5.52003 4.9987 5.66927 5.14794 5.66927 5.33203Z'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_16_113717'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>

              <span>Sell Items</span>
            </button>
          </div>
        </Inner>
      </Container>
    </TopSection>
  );
};

export default HeaderTopSection;
