import styled from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const ChassisNumberSearchStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 274px;
  height: 376px;
  max-height: 376px;
  background: var(--light-bg);
  border-radius: 12px;
  padding: 32px 38px 32px 24px;
  margin-right: 20px;
  @media ${device.tablet} {
    max-height: 345px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-height: 100%;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
  }
`;

export const TopPart = styled.div`
  display: block;
`;

export const BottomPart = styled.div`
  display: block;
`;

export const Title = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: var(--main-text);
  margin-bottom: 12px;
`;

export const Subtitle = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #676a75;
  margin-bottom: 20px;
`;

export const Link = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-green);
  text-decoration: underline;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 28px;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid var(--divider);
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  padding: 6px 0 6px 18px;
  outline: none;
`;
