import styled from 'styled-components';
import { LatoFont } from 'assets/style-helpers/style-helpers';

export const Content = styled.div`
  max-width: 100%;
  width: 520px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const AvatarWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoMain = styled.div``;

export const InfoTitle = styled.div`
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 24px;
`;

export const InfoCategory = styled.div`
  margin-bottom: 12px;
  ${LatoFont};
  color: var(--main-subtext);
`;

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Reviews = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

const ReviewItem = styled.div`
  ${LatoFont};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  & + & {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid var(--divider);
  }
`;

export const GreenReviewsItem = styled(ReviewItem)`
  color: var(--main-green);
`;

export const BlackReviewsItem = styled(ReviewItem)``;

export const Title = styled.div`
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 14px;
`;

export const TextareaWrapper = styled.div`
  margin: 14px 0 16px;
  display: flex;
`;
