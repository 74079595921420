import { ReactComponent as CloseIcon } from './close-icon.svg';
import { Container, Label, CloseBtn } from './tag-styled';

export const Tag = ({ label, onCloseBtnClick, onClick = () => {}, isButton }) => {
  return (
    <Container onClick={onClick} isButton={isButton}>
      <CloseBtn onClick={onCloseBtnClick}>
        <CloseIcon />
      </CloseBtn>
      <Label>{label}</Label>
    </Container>
  );
};
