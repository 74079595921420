import styled, { css } from 'styled-components';
import { LatoFont } from '../../../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  cursor: pointer;
  ${LatoFont};
  & + & {
    margin-top: 8px;
  }
`;

export const Main = styled.div`
  border-radius: 6px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  svg {
    transform: rotate(-90deg);
    transition: all 0.3s;
  }
  ${(props) =>
    props.isSubOpened &&
    css`
      color: var(--main-green);
      background: #e9f1ea;
      svg {
        transform: rotate(-180deg);
      }
    `}
`;

export const Sub = styled.div`
  margin-top: 8px;
  padding-left: 20px;
`;

export const Subcategory = styled.div`
  padding: 0 10px;
  & + & {
    margin-top: 8px;
  }
  span {
    margin-left: 6px;
    color: var(--main-subtext);
  }
`;
