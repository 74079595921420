import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  Container,
  ImgWrapper,
  Main,
  Title,
  Price,
  Btn
} from './products-you-may-like-item-styled';

export const ProductsYouMauLikeItem = ({ product }) => {
  return (
    <Container>
      <ImgWrapper>
        <img src={product.img} alt={product.title} />
      </ImgWrapper>
      <Main>
        <Title>{product.title}</Title>
        <Price>{product.price}</Price>
      </Main>
      <Btn>
        <SvgIcon name={'cart'} />
      </Btn>
    </Container>
  );
};
