import { Action, Canceled, DeleteBox, Done, Pending } from './lists-styled';
import { CheckboxWrapperHeader, StyledId } from '../../../all-customers/all-customers-styled';
import Checkbox from '../../../../../../components/checkbox/checkbox';
import { CheckboxWrapper } from '../../../all-orders/all-order-styled';
import { GlobalButton } from '../../../../../../components/global-button/global-button';

import { Delete } from '../../../../../../components/svg-icon/icons/delete';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Client Name',
    accessor: 'clientname'
  },
  {
    Header: 'Service Required',
    accessor: 'servicerequired'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Date & Time',
    accessor: 'datetime'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Pending>Pending</Pending>,
    actions: (
      <Action>
        <GlobalButton type={'primary'}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.25 9L7.75 11.5L12.75 6.5M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Respond
        </GlobalButton>
        <DeleteBox>
          <Delete />
        </DeleteBox>
      </Action>
    )
  },
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Done>Done</Done>,
    actions: ''
  },
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Canceled>Canceled</Canceled>,
    actions: ''
  }
];
