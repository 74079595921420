import { useEffect, useState } from 'react';
import { Box, Container, Input, MinusBtn, PlusBtn, Title, Wrapper } from './counter-styled';

export const Counter = ({ title, currentValue, setValue }) => {
  const [value, setCurrentValue] = useState(currentValue);

  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <Container>
      <Title>{title}</Title>
      <Box>
        <Wrapper>
          <MinusBtn
            onClick={() => {
              setCurrentValue(+value - 1);
            }}
          >
            <svg
              width='14'
              height='2'
              viewBox='0 0 14 2'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.75 1H12.25'
                stroke='currentColor'
                strokeWidth='1.8'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </MinusBtn>
          <Input
            value={currentValue}
            onChange={(e) => {
              const newValue = e.target.value;
              const reg = /^\d+\.?\d*$/;
              if (reg.test(newValue) || newValue === '') {
                setCurrentValue(e.target.value);
              }
            }}
          />
          <PlusBtn
            onClick={() => {
              setCurrentValue(+value + 1);
            }}
          >
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 1.75V12.25M1.75 7H12.25'
                stroke='currentColor'
                strokeWidth='1.8'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </PlusBtn>
        </Wrapper>
      </Box>
    </Container>
  );
};
