export const AnnotationCheck = () => {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.875 9.625L9.625 11.375L13.5625 7.4375M8.6625 16.8L9.94 18.5033C10.13 18.7566 10.225 18.8833 10.3414 18.9286C10.4434 18.9682 10.5566 18.9682 10.6586 18.9286C10.775 18.8833 10.87 18.7566 11.06 18.5033L12.3375 16.8C12.594 16.458 12.7223 16.287 12.8787 16.1564C13.0873 15.9824 13.3335 15.8592 13.5979 15.7968C13.7962 15.75 14.01 15.75 14.4375 15.75C15.6606 15.75 16.2721 15.75 16.7545 15.5502C17.3977 15.2838 17.9088 14.7727 18.1752 14.1295C18.375 13.6471 18.375 13.0356 18.375 11.8125V6.825C18.375 5.35486 18.375 4.61979 18.0889 4.05827C17.8372 3.56435 17.4356 3.16278 16.9417 2.91111C16.3802 2.625 15.6451 2.625 14.175 2.625H6.825C5.35486 2.625 4.61979 2.625 4.05827 2.91111C3.56435 3.16278 3.16278 3.56435 2.91111 4.05827C2.625 4.61979 2.625 5.35486 2.625 6.825V11.8125C2.625 13.0356 2.625 13.6471 2.82482 14.1295C3.09124 14.7727 3.60226 15.2838 4.24546 15.5502C4.72786 15.75 5.3394 15.75 6.5625 15.75C6.99001 15.75 7.20376 15.75 7.40207 15.7968C7.66648 15.8592 7.91273 15.9824 8.12131 16.1564C8.27774 16.287 8.406 16.458 8.6625 16.8Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
