import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const PaginationWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  :last-child {
    > p {
      margin-right: unset !important;
    }
  }

  @media ${device.mobile} {
    padding: 0;
    margin: 0;
  }
`;

export const Number = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 32px;
  padding: 6px 10px;
  margin-right: 9px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  color: #c9cad3;
  ${(props) =>
    props.currentPage &&
    css`
      color: #0c283c;
    `}
  cursor: pointer;
`;

export const ArrowLeft = styled.div`
  margin-right: 12px;
  cursor: pointer;
  background: #f6f9fe;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.firstPage &&
    css`
      opacity: 50;
      pointer-events: none;
    `}
`;

export const ArrowRight = styled.div`
  margin-left: 12px;
  cursor: pointer;
  background: #f4f4f4;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.lastPage &&
    css`
      opacity: 50;
      pointer-events: none;
    `}
`;
