import {
  CardsWrapper,
  Container,
  Main,
  Map,
  Products,
  ProductsTop,
  Title,
  TitleWrapper
} from './properties-styled';
import { Filter } from './components/filter/filter';
import GoogleMapReact from 'google-map-react';
import { GoogleMapsMarker } from '../../../store-finder/store-finder';
import { ViewSelection, viewType } from 'components/view-selection/view-selection';
import { useState } from 'react';
import { estateProductsMock } from '../../mock/real-estate-store-mock';
import { RealEstateCard } from './components/real-estate-card/real-estate-card';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { useWindow } from '../../../../hooks/useWindow';

export const Properties = () => {
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  const isMobile = useWindow();
  return (
    <Container>
      <TitleWrapper>
        <Title>Our Properties</Title>
        {isMobile ? (
          <ViewSelection
            currentViewType={currentViewType}
            setCurrentViewType={setCurrentViewType}
            isMobileHidden={false}
          />
        ) : (
          ''
        )}
      </TitleWrapper>
      <Filter />
      <Main>
        <Products>
          <ProductsTop>
            <ViewSelection
              currentViewType={currentViewType}
              setCurrentViewType={setCurrentViewType}
            />
          </ProductsTop>
          <CardsWrapper isListView={currentViewType === viewType.list}>
            {estateProductsMock.map((product, index) => {
              return (
                <RealEstateCard
                  product={product}
                  view={currentViewType}
                  key={index}
                  isListView={currentViewType === viewType.list}
                />
              );
            })}
          </CardsWrapper>
          <GlobalButton type={'orange-secondary'}>
            <SvgIcon name={'eye'} />
            See more
          </GlobalButton>
        </Products>
        <Map>
          <GoogleMapReact
            bootstrapURLKeys={{ key: '' }}
            defaultZoom={9}
            defaultCenter={{
              lat: -19.23878101020672,
              lng: 29.339569563132443
            }}
          >
            <GoogleMapsMarker lat={-19.23878101020672} lng={29.339569563132443} showBallon={true} />
          </GoogleMapReact>
        </Map>
      </Main>
    </Container>
  );
};
