import {
  ColorBox,
  ColorsWrapper,
  Container,
  Title,
  TitleValue,
  TitleWrapper
} from './colors-styled';

export const Colors = () => {
  return (
    <Container>
      <TitleWrapper>
        <Title>Color:</Title>
        <TitleValue>White</TitleValue>
      </TitleWrapper>
      <ColorsWrapper>
        <ColorBox color={'#ffffff'} isActive />
        <ColorBox color={'#7CCC67'} />
        <ColorBox color={'#767B99'} />
        <ColorBox color={'#29263A'} />
      </ColorsWrapper>
    </Container>
  );
};
