import { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  ButtonWrapper,
  MobileSearch,
  Content,
  ButtonsWrapper,
  LeftSide,
  SidebarSecondTitle
} from './customer-list-styled';
import Sidebar from '../../../../components/sidebar/sidebar';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalTextarea } from '../../../../components/global-textarea/global-textarea';
import { columnsData, columnsRows, SidebarColumnsData, SidebarColumnsRows } from './mock';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Customers' },
  { link: '#', name: 'Customer List' }
];

const customerList = [
  { label: 'User1', value: 'User1' },
  { label: 'User2', value: 'User2' },
  { label: 'User3', value: 'User3' },
  { label: 'User4', value: 'User4' }
];

const CustomerList = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [customerListName, setCustomerListName] = useState('');
  const [listDescription, setListDescription] = useState('');
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Customer List</Title>
      <Navigation>
        <MobileSearch>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 15L12.2779 12.2778M14.2222 7.61111C14.2222 11.2623 11.2623 14.2222 7.61111 14.2222C3.95989 14.2222 1 11.2623 1 7.61111C1 3.95989 3.95989 1 7.61111 1C11.2623 1 14.2222 3.95989 14.2222 7.61111Z'
              stroke='#29263a'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p>Search</p>
        </MobileSearch>
        <InputWrapper>
          <SearchInput
            placeholder={'Quick Search...'}
            value={quickSearch}
            onChange={(e) => setQuickSearch(e.target.value)}
          />
          <SearchButton>
            <img src={search} alt='' />
          </SearchButton>
        </InputWrapper>
        <ButtonWrapper onClick={() => setShowSidebar(true)}>
          <GlobalButton fullWidth height={40} type={'secondary'}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                stroke='#388638'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Create New List
          </GlobalButton>
        </ButtonWrapper>
      </Navigation>
      <Table columnsData={columnsData} columnsRows={columnsRows} usePagination hasMenu />
      <Sidebar
        title={'Create New Customer List'}
        showSidebar={showSidebar}
        onClose={() => setShowSidebar(false)}
        onCloseText={'Back to Newsletter'}
      >
        <Content>
          <InputGroup label={'customer list name'}>
            <GlobalInput
              placeholder={'Enter the customer list name'}
              value={customerListName}
              onChange={(e) => setCustomerListName(e.target.value)}
            />
          </InputGroup>
          <InputGroup label={'list description'}>
            <GlobalTextarea
              value={listDescription}
              onChange={(e) => setListDescription(e.target.value)}
              placeholder={'Describe this customer list'}
            />
          </InputGroup>
          <SidebarSecondTitle>Add Customers To List</SidebarSecondTitle>
          <InputGroup label={'Search Customer'}>
            <GlobalSelect options={customerList} />
          </InputGroup>
          <Table
            columnsData={SidebarColumnsData}
            columnsRows={SidebarColumnsRows}
            minWidth={'443px'}
            hasMarginRight={false}
          />
          <ButtonsWrapper>
            <LeftSide>
              <GlobalButton type={'primary'}>
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                Create List
              </GlobalButton>
            </LeftSide>
            <GlobalButton type={'gray'}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.5 6.5L11.5 11.5M11.5 6.5L6.5 11.5M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                  stroke='#ACADB9'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Cancel List
            </GlobalButton>
          </ButtonsWrapper>
        </Content>
      </Sidebar>
    </Container>
  );
};

export default CustomerList;
