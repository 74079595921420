import { Link } from 'react-router-dom';
import { GlobalButton } from '../../../../../global-button/global-button';
import { GlobalCheckbox } from '../../../../../global-checkbox/global-checkbox';
import { SvgIcon } from '../../../../../svg-icon/svg-icon';
import {
  ChecbkoxText,
  CheckboxWrapper,
  Container,
  Description,
  Textarea,
  Title
} from './reports-styled';

export const Reports = () => {
  return (
    <Container>
      <Title>Report Product</Title>
      <Description>
        Please give a reason for reporting <b>Huawei Y7</b> from{' '}
        <Link to={'#'}>Eltrons Electronics</Link>{' '}
      </Description>
      <CheckboxWrapper>
        <GlobalCheckbox>
          <ChecbkoxText>It violates the term of use</ChecbkoxText>
        </GlobalCheckbox>
        <GlobalCheckbox>
          <ChecbkoxText>It contains harmful or offensive content</ChecbkoxText>
        </GlobalCheckbox>
        <GlobalCheckbox>
          <ChecbkoxText>It has not been fulfilles according to the site`s policy</ChecbkoxText>
        </GlobalCheckbox>
        <GlobalCheckbox>
          <ChecbkoxText>Other</ChecbkoxText>
        </GlobalCheckbox>
      </CheckboxWrapper>
      <Textarea placeholder={'Write your review here'} />
      <GlobalButton height={40} type={'primary'}>
        <SvgIcon name={'alert'} />
        Report Product
      </GlobalButton>
    </Container>
  );
};
