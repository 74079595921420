import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const KnowledgeBox = styled.div`
  width: 510px;
  border-radius: 12px;
  border: 1px solid var(--ez-grey-bg-devider, #dcdcdc);
  background: #fff;
  @media ${device.laptop} {
    width: 400px;
  }
  @media ${device.tablet} {
    width: 230px;
  }
  @media ${device.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }
`;
export const Photo = styled.img`
  width: 100%;
  height: 222px;
  border-radius: 12px 12px 0px 0px;
  @media ${device.tablet} {
    height: 150px;
  }
`;
export const Block = styled.div`
  padding: 20px;
  @media ${device.tablet} {
    padding-top: 10px;
  }
`;

export const Title = styled.div`
  color: var(--dark, #29263a);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;
export const Description = styled.div`
  color: var(--1, #5a5670);
  /* body */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 3px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const ButtonBlock = styled.div`
  margin-top: 10px;
`;
