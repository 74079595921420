import {
  ButtonMobile,
  CardsSection,
  ClearTags,
  Close,
  ControlsSecond,
  FilterButton,
  FilterTags,
  FindBox,
  FoodAndGroceryStyled,
  Header,
  HorizontalLine,
  RightSection,
  ShowMore,
  SortBy,
  Tag,
  Title,
  TradesNav,
  TradesNavMobile
} from './general-styled';

import { GlobalButton } from '../../../../../components/global-button/global-button';

import { ReactComponent as CloseIco } from '../../../../../assets/close.svg';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';

import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import filter from '../../../../../assets/filter.svg';
import miles from '../../../../../assets/miles.svg';
import product from '../../../../../assets/product2.png';
import year from '../../../../../assets/year.svg';
import { useNavigate } from 'react-router';
import { CARS } from '../../../../../dummy-data/cars';
import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import React, { useState } from 'react';
import { viewType } from '../../../../../components/view-selection/view-selection';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

const FOOD_PRODUCTS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: false,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  }
];

const ActiveTags = ['$0 - $1mln', 'Used', 'Original', 'Harare Province', 'Harare'];

const General = ({ onFilterBtnClick }) => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState(viewType.grid);
  const [search, setSearch] = React.useState('');
  console.log(currentView);

  return (
    <FoodAndGroceryStyled>
      <CategoriesTopFilter
        title={'General categories'}
        searchTitle={'Search in general'}
        onFilterBtnClick={onFilterBtnClick}
        currentViewType={currentView}
        setCurrentViewType={setCurrentView}
        isMobileViewHidden={false}
        setSearch={setSearch}
        search={search}
        searchPlaceholder={'Search in general products'}
      />
      <CardsSection currentView={currentView}>
        {FOOD_PRODUCTS_LIST.map((data, index) => (
          <PCard
            product={data}
            withLabel={true}
            type={ProductCardTypes.CLOTHING}
            key={index}
            currentViewType={currentView}
          />
        ))}
      </CardsSection>
      <ShowMore>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'eye'} />
          See more products
        </GlobalButton>
      </ShowMore>
    </FoodAndGroceryStyled>
  );
};

export default General;
