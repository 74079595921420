import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { Tooltip } from 'components/tooltip/tooltip';
import { GlobalButton } from 'components/global-button/global-button';
import { ReactComponent as CircleCheck } from './check-circle.svg';
import {
  ButtonsWrapper,
  CheckboxContent,
  Content,
  Price,
  PromoteItem,
  SelectWrapper,
  Subtitle,
  Title,
  TotalWrapper
} from './promote-popup-styled';
import {
  customDropdownIndicatorSmall,
  GlobalSelect,
  selectCustomStyleSmall
} from '../../../../components/global-select/global-select';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const PromotePopup = ({ onClose, isVisible, onPayBtnClick }) => {
  return (
    <ModalWrapper onClose={onClose} isVisible={isVisible} alignCenter>
      <Content>
        <Title>Promote Your Product</Title>
        <Subtitle>Advertise your product and get more orders</Subtitle>
        <PromoteItem>
          <div>
            <GlobalCheckbox>
              <CheckboxContent>
                <span> Deals Of The Day</span>
                <Tooltip>Some test</Tooltip>
              </CheckboxContent>
            </GlobalCheckbox>
          </div>
          <div>
            <SelectWrapper>
              <GlobalSelect
                options={selectOptions}
                styles={selectCustomStyleSmall}
                dropdownIndicator={customDropdownIndicatorSmall}
              />
            </SelectWrapper>
            <Price>$2,99</Price>
          </div>
        </PromoteItem>
        <PromoteItem>
          <div>
            <GlobalCheckbox>
              <CheckboxContent>
                <span> Featured Product</span>
                <Tooltip>Some test</Tooltip>
              </CheckboxContent>
            </GlobalCheckbox>
          </div>
          <div>
            <SelectWrapper>
              <GlobalSelect
                options={selectOptions}
                styles={selectCustomStyleSmall}
                dropdownIndicator={customDropdownIndicatorSmall}
              />
            </SelectWrapper>
            <Price>$6,99</Price>
          </div>
        </PromoteItem>
        <TotalWrapper>
          <span>Total Amount</span>
          <span>$2,99</span>
        </TotalWrapper>
        <ButtonsWrapper>
          <GlobalButton onClick={onPayBtnClick}>
            <CircleCheck />
            Pay
          </GlobalButton>
          <GlobalButton type={'secondary'}>Publish without advertising</GlobalButton>
        </ButtonsWrapper>
      </Content>
    </ModalWrapper>
  );
};
