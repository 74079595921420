import { Container, Rate, Reviews, StarsWrapper, StarsWrapperMobile } from './rate-stars-styled';

const starArray = [1, 2, 3, 4, 5];

const starIcon = (color, width, height, isFilled, key) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 11'
      fill={isFilled ? color : 'none'}
      xmlns='http://www.w3.org/2000/svg'
      key={key}
    >
      <path
        d='M5.11129 3.6768L6 0.94164L6.88871 3.6768C7.03597 4.13002 7.45832 4.43688 7.93487 4.43688H10.8108L8.48412 6.1273C8.09858 6.40741 7.93726 6.90392 8.08452 7.35714L8.97323 10.0923L6.64656 8.40188C6.26103 8.12177 5.73897 8.12177 5.35344 8.40188L3.02677 10.0923L3.91548 7.35714C4.06274 6.90391 3.90141 6.40741 3.51588 6.1273L1.18921 4.43688H4.06513C4.54168 4.43688 4.96403 4.13002 5.11129 3.6768ZM2.77778 10.2732C2.77781 10.2732 2.77784 10.2732 2.77786 10.2731L2.77778 10.2732Z'
        stroke={color}
        strokeWidth='1.2'
      />
    </svg>
  );
};

export const RateStars = ({
  rate,
  width = 14,
  height = 14,
  color = '#388638',
  reviews,
  isRateCounter = true,
  isMobileMinified = true
}) => {
  return (
    <Container>
      <StarsWrapper isMobileMinified={isMobileMinified}>
        {starArray.map((star, index) =>
          starIcon(color, width, height, star <= Math.floor(rate), index)
        )}
      </StarsWrapper>
      {isMobileMinified && (
        <StarsWrapperMobile>{starIcon(color, width, height, true)}</StarsWrapperMobile>
      )}
      {isRateCounter && <Rate>{rate}</Rate>}
      {reviews && <Reviews>({reviews})</Reviews>}
    </Container>
  );
};
