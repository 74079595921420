import { Container, Main, Sub, Subcategory } from './category-styled';
import { useState } from 'react';

export const Category = ({ title, qty, subcategory }) => {
  const [isSubOpened, setIsSubOpened] = useState(false);
  return (
    <Container>
      <Main
        onClick={() => {
          setIsSubOpened(!isSubOpened);
        }}
      >
        {title}
        <span>{qty}</span>
      </Main>
      {isSubOpened && (
        <Sub>
          {subcategory.map((item) => (
            <Subcategory key={item.title}>
              {item.title}
              <span>{item.qty}</span>
            </Subcategory>
          ))}
        </Sub>
      )}
    </Container>
  );
};
