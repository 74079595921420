import { useState } from 'react';

import { ProductDetails } from '../../components/add-product-form-components/product-details';
import { ProductCategories } from '../../components/add-product-form-components/product-categories';
import { ProductImage } from '../../components/add-product-form-components/product-image';
import { ProductPricing } from '../../components/add-product-form-components/product-pricing';
import { ProductInventory } from '../../components/add-product-form-components/product-inventory';
import { ProductAttributes } from '../../components/add-product-form-components/product-attributes';
import { ProductSubmitBtns } from '../../components/add-product-form-components/product-submit-btns';
import { AddNewProductLayout } from '../../components/add-new-product-layout/add-new-product-layout';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductSingle = ({ isProductPromotion = true }) => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <AddNewProductLayout
      isSidebarHidden={isSidebarHidden}
      setIsSidebarHidden={setIsSidebarHidden}
      pageTitle={'Add Product'}
      isProductPromotion={isProductPromotion}
    >
      <ProductDetails selectOptions={selectOptions} />
      <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
      <ProductImage />
      <ProductPricing selectOptions={selectOptions} />
      <ProductInventory />
      <ProductAttributes selectOptions={selectOptions} />
      <ProductSubmitBtns
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      />
    </AddNewProductLayout>
  );
};
