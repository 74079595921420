import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const Box = styled.div`
  height: 175px;
  background: #111b45;
  @media ${device.tablet} {
    &:last-child {
      display: none;
    }
  }
`;

export const Main = styled.div`
  padding: 0 0 40px;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Divider = styled.div`
  margin-bottom: 40px;
`;

export const BannersWrapper = styled.div`
  // @media ${device.tablet} {
  //   display: none;
  // }
`;
