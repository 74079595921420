import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const ReviewCardStyled = styled.section`
  padding: 14px 20px 16px 20px;
  background-color: #f5f7fb;
  border-radius: 6px;
  margin-right: 20px;

  @media ${device.mobile} {
    max-width: 258px;
  }

  .card {
    .text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #29263a;
      margin-bottom: 32px;

      span {
        color: var(--main-green);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media ${device.mobile} {
        justify-content: flex-start;
      }

      .user {
        display: flex;
        align-items: center;

        .account-icon {
          margin-right: 12px;
        }

        .name {
          @media ${device.mobile} {
            display: none;
          }
          display: block;
          margin-right: 21px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 24px;
          color: #29263a;
        }
      }

      .stars-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media ${device.mobile} {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }

        .name {
          display: none;
          margin-right: 21px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 24px;
          color: #29263a;

          @media ${device.mobile} {
            display: block;
          }
        }

        .stars {
          margin-right: 6px;
        }
      }
    }
  }
`;
