import Header from '../../components/header/header';
import {
  Container,
  Products,
  Title
} from '../single-product-general/single-product-general-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { ProductInfoLayout } from '../../components/product-info-layout/product-info-layout';
import { ProductGallery } from '../../components/product-gallery/product-gallery';
import { SponsoredBanners } from '../single-product-general/components/sponsored-banners/sponsored-banners';
import { ProductInfoRealEstate } from '../../components/product-info/product-info-real-estate';
import { ProductDetails } from '../../components/product-info/components/product-details/product-details';
import { SideBanner } from '../single-product-general/components/side-banner/side-banner';
import { ProductsSlider } from '../../components/products-slider/products-slider';

import image1 from '../../assets/categories_card_image_1.png';
import image2 from '../../assets/categories_card_image_2.png';
import image3 from '../../assets/categories_card_image_3.png';
import CategoriesCard from '../../components/categories-card/categories-card';
import React, { useState } from 'react';
import { Sidebar } from '../single-product-general/components/sidebar/sidebar';

const CARDS = [
  {
    img: [image1, image2, image3, image2],
    title: 'Master Duplex in Mutare City Centre',
    location: 'Harare SBD',
    price: '200 000',
    bids: '3 Bids',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    price: '1 200',
    bids: 'Per month',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  }
];

export const SingleProductRealEstate = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs />
        <ProductInfoLayout>
          <ProductGallery />
          <SponsoredBanners />
          <ProductInfoRealEstate setIsSidebarHidden={setIsSidebarHidden} />
          <ProductDetails />
          <SideBanner />
        </ProductInfoLayout>
      </Container>
      <Products>
        <Container>
          <Title>Related Properties</Title>
        </Container>
        <ProductsSlider>
          {CARDS.map((product, index) => {
            return <CategoriesCard key={index} product={product} />;
          })}
        </ProductsSlider>
      </Products>
      <Sidebar isSidebarHidden={isSidebarHidden} setIsSidebarHidden={setIsSidebarHidden} />
    </>
  );
};
