import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { Item, List } from './side-menu-styled';

const categoriesList = [
  {
    name: 'Books & Stationary',
    icon: 'books',
    link: '#'
  },
  {
    name: 'Clothing & Apparel',
    icon: 'clothing',
    link: '#'
  },
  {
    name: 'Construction & Hardware Supplies',
    icon: 'construction',
    link: '#'
  },
  {
    name: 'Electronic Goods',
    icon: 'electronic',
    link: '#'
  },
  {
    name: 'Farming Supplies',
    icon: 'farming',
    link: '#'
  },
  {
    name: 'Food & Groceries',
    icon: 'food',
    link: '#'
  },
  {
    name: 'Health & Beauty',
    icon: 'health',
    link: '#'
  },
  {
    name: 'Home & Garden',
    icon: 'home',
    link: '#'
  },
  {
    name: 'Industrial Supplies',
    icon: 'industrial',
    link: '#'
  },
  {
    name: 'Motor Spare & Accessories',
    icon: 'motor',
    link: '#'
  },
  {
    name: 'Motor Vehicles',
    icon: 'vehicles',
    link: '#'
  },
  {
    name: 'Real Estate',
    icon: 'estate',
    link: '#'
  },
  {
    name: 'Sports Hobbies & Leisure',
    icon: 'sport',
    link: '#'
  },
  {
    name: 'Trades & Services',
    icon: 'trades',
    link: '#'
  }
];

export const SideMenu = () => {
  return (
    <List>
      {categoriesList.map((item) => {
        return (
          <Item to={item.link} key={item.name}>
            <SvgIcon name={item.icon} />
            <span>{item.name}</span>
          </Item>
        );
      })}
    </List>
  );
};
