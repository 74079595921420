import { OtherStoresSidebar } from '../store-front-trades-services/components/other-stores-sidebar/other-stores-sidebar';
import {
  ButtonsTop,
  ButtonWrapper,
  Container
} from '../store-front-trades-services/store-front-trades-services-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { StoreFrontTop } from '../../components/store-front-top/store-front-top';
import { TradesServicesStoreMock } from '../store-front-trades-services/mock/trades-services-mock';
import Reviews from '../company-page/components/reviews/reviews';

import React, { useState } from 'react';
import { ApointmentBookingPopup } from '../store-front-trades-services/components/apointment-booking-popup/apointment-booking-popup';
import { GlobalButton } from '../../components/global-button/global-button';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { StoreFrontProductSection } from '../../components/store-front-product-section/store-front-product-section';

import { generalProductsMock } from '../../dummy-data/generalProductsMock';
import { PCard, ProductCardTypes } from '../../components/p-card/p-card';
import { foodProductList } from '../../dummy-data/foodProductList';
import { LoadMore } from '../../components/load-more/load-more';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Trades & Services' },
  { link: '#', name: 'So & So Plumbing' }
];

const StorefrontGeneralButtons = ({ onOtherStoreBtnClick }) => {
  const [isApointmentBookingVibisle, setIsApointmentBookingVibisle] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  return (
    <ButtonWrapper>
      <ApointmentBookingPopup
        isApointmentBookingVibisle={isApointmentBookingVibisle}
        onClose={() => {
          setIsApointmentBookingVibisle(false);
        }}
      />
      <ButtonsTop>
        <GlobalButton
          onClick={() => {
            setIsApointmentBookingVibisle(true);
          }}
        >
          <SvgIcon name={'annotation-check'} />
          Book An Appointment
        </GlobalButton>
        <GlobalButton
          checked={isFollowed}
          type={'orange-secondary'}
          onClick={() => {
            setIsFollowed(!isFollowed);
          }}
        >
          <SvgIcon name={'bell'} />
          {isFollowed ? 'Followed' : 'Follow'}
        </GlobalButton>
      </ButtonsTop>
      <GlobalButton type={'orange'} fullWidth onClick={onOtherStoreBtnClick}>
        <SvgIcon name={'building'} />
        Our other stores
      </GlobalButton>
    </ButtonWrapper>
  );
};

export const StoreFrontFood = () => {
  const [isOtherStoresVisible, setIsOtherStoresVisible] = useState(false);

  return (
    <>
      <OtherStoresSidebar
        isVisible={isOtherStoresVisible}
        onCloseBtnClick={() => {
          setIsOtherStoresVisible(false);
        }}
        title={'Other Stores Of This Vendor'}
      />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <StoreFrontTop
          storeInfo={TradesServicesStoreMock}
          customButtonSection={() => (
            <StorefrontGeneralButtons
              onOtherStoreBtnClick={() => {
                setIsOtherStoresVisible(true);
              }}
            />
          )}
        />
        <Reviews slidesToShow={4} title={'What Our Clients Are Saying'} />
        <StoreFrontProductSection
          searchBarLabel={'Search in food and groceries'}
          additionalBtn={<LoadMore />}
        >
          {foodProductList.map((product, index) => {
            return <PCard product={product} type={ProductCardTypes.GROCERIES} key={index} />;
          })}
        </StoreFrontProductSection>
      </Container>
    </>
  );
};
