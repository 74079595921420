import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { title30 } from '../../assets/style-helpers/typography';
import btnBg from './img/scroll-btn-decor.svg';
import { device } from '../../assets/style-helpers/breakpoints';

export const FooterWrapper = styled.footer`
  background: var(--main-dark-green);
  color: var(--main-white);
  position: relative;
  z-index: 3;
`;

export const Container = styled.div`
  ${mainContainer};
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ScrollBtn = styled.button`
  transform: translateY(-25px);
  width: 120px;
  height: 52px;
  cursor: pointer;
  background: url(${btnBg}) no-repeat top center;
  background-size: 120px 26px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobile} {
    display: none;
  }
`;

export const SubscribeSection = styled.div`
  padding: 42px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  flex-basis: 480px;
  position: relative;
  @media ${device.tablet} {
    flex-basis: 345px;
  }
  @media ${device.mobile} {
    flex-basis: auto;
    width: 100%;
    margin-top: 24px;
  }
  input {
    height: 46px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--main-light-green);
    background: transparent;
    color: var(--main-white);
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    padding: 0 20px;
    &::placeholder {
      color: var(--main-gray);
    }
  }

  button {
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-green);
    color: var(--main-white);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 20px;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    span {
      margin-left: 12px;
    }
  }
`;

export const Menu = styled.div`
  padding: 42px 0;
  border-bottom: 1px solid var(--main-light-green);
  border-top: 1px solid var(--main-light-green);
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 220px;
  align-content: space-between;
  margin-bottom: -16px;
  @media ${device.mobile} {
    max-height: 270px;
  }

  a {
    max-width: 124px;
    margin-bottom: 16px;
    color: var(--main-gray);
    text-decoration: none;
    &:hover {
      color: var(--main-white);
    }

    @media ${device.tablet} {
      margin-bottom: 8px;
    }
  }
`;

export const ListTitle = styled.div`
  margin-bottom: 24px;
  font-size: 17px;
  font-weight: 600;
  line-height: 141%;
  @media ${device.tablet} {
    margin-bottom: 8px;
  }
  @media ${device.mobile} {
    margin-bottom: 14px;
  }
`;

export const ListWrapper = styled.div`
  flex-basis: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-basis: 240px;
  }

  @media ${device.mobile} {
    flex-direction: row;
    flex-basis: auto;
    > div:first-child {
      flex-basis: 240px;
    }
  }
  ${List} {
    max-height: 80px;
  }
`;

export const Copyright = styled.div`
  padding: 24px 0;
  color: var(--main-gray);
  text-align: center;
  font-family: 'Lato', sans-serif;
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 4px;
  }
`;

export const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-gray);
`;

export const PayNowImgs = styled.div`
  display: flex;
  align-items: center;
  img + img {
    margin-left: 5px;
    @media ${device.tablet} {
      margin-left: 0;
    }
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
`;

export const Category = styled.div`
  flex-basis: 720px;
  @media ${device.tablet} {
    flex-basis: 400px;
  }
  @media ${device.mobile} {
    flex-basis: auto;
    margin-bottom: 32px;
  }
`;
