import { RateStars } from 'components/rate-stars/rate-stars';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  Container,
  Info,
  InfoWrapper,
  Title,
  Subtitle,
  Avatar,
  RateInfo,
  Reviews,
  Properties,
  Main,
  AddressTitleWrapper
} from './other-store-card-styled';
import {
  AddressText,
  AddressTitle,
  OpenHoursStatus,
  SocialLinks
} from 'components/store-front-top/store-front-top-styled';
import mapIcon from 'components/store-front-top/img/location.svg';

export const OtherStoreCard = ({ store }) => {
  return (
    <Container>
      <InfoWrapper>
        <Avatar>
          <img src={store.avatar} alt='' />
        </Avatar>
        <Info>
          <Title>{store.title}</Title>
          <Subtitle>{store.subtitle}</Subtitle>
          <RateStars rate={3} height={14} width={14} isRateCounter={false} />
          <RateInfo>
            <Reviews>10 Reviews</Reviews>
            <Properties>25 Properties Sold</Properties>
          </RateInfo>
        </Info>
      </InfoWrapper>
      <GlobalButton type={'orange'} fullWidth>
        <SvgIcon name={'eye'} />
        View Storefront
      </GlobalButton>
      <Main>
        <AddressTitleWrapper>
          <AddressTitle>
            <img src={mapIcon} alt='icon' />
            Address
          </AddressTitle>
          <OpenHoursStatus>Open now</OpenHoursStatus>
        </AddressTitleWrapper>
        <AddressText>
          33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD
        </AddressText>

        <SocialLinks>
          <a href='#'>
            <SvgIcon name={'whatsup'} />
          </a>
          <a href='#'>
            <SvgIcon name={'phone'} />
          </a>
          <a href='#'>
            <SvgIcon name={'email'} />
          </a>
        </SocialLinks>
      </Main>
    </Container>
  );
};
