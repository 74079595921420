import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { Outlet } from 'react-router';
import { LinkItem, Main, Navigation } from './inbox-styled';
import { NavLink } from 'react-router-dom';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Messages' },
  { link: '#', name: 'Inbox' }
];

export const Inbox = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Main>
        <Navigation>
          <NavLink to={'messages'}>
            {({ isActive }) => (
              <LinkItem isActive={isActive}>
                Inbox <span>35</span>
              </LinkItem>
            )}
          </NavLink>
          <NavLink to={'notifications'}>
            {({ isActive }) => (
              <LinkItem isActive={isActive}>
                Notifications<span>35</span>
              </LinkItem>
            )}
          </NavLink>
        </Navigation>
        <Outlet />
      </Main>
    </>
  );
};
