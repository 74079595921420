export const TwitterIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.25 2.24973C16.5318 2.75634 15.7366 3.14381 14.895 3.39723C14.4433 2.87786 13.843 2.50975 13.1753 2.34267C12.5076 2.1756 11.8046 2.21763 11.1616 2.46307C10.5185 2.70851 9.96633 3.14553 9.57974 3.71502C9.19314 4.2845 8.99077 4.95898 9 5.64723V6.39723C7.68198 6.43141 6.37596 6.13909 5.19826 5.54631C4.02056 4.95354 3.00774 4.07871 2.25 2.99973C2.25 2.99973 -0.75 9.74973 6 12.7497C4.4554 13.7982 2.61537 14.3239 0.75 14.2497C7.5 17.9997 15.75 14.2497 15.75 5.62473C15.7493 5.41582 15.7292 5.20743 15.69 5.00223C16.4555 4.24735 16.9956 3.29426 17.25 2.24973Z'
        stroke='#388638'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
