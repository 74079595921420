import styled from 'styled-components';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  padding: 16px 32px;
`;

export const Filters = styled.div`
  margin-bottom: 32px;
`;

export const FiltersTitle = styled.div`
  margin-bottom: 12px;
  color: var(--main-green);
  ${LatoFont};
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background: #f4f4f4;
  margin-right: 8px;
`;

export const FiltersWrapepr = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
`;

export const FilterBtn = styled.div`
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  ${LatoFont};
  padding: 4px 16px;
  font-size: 12px;
  line-height: 2;
  font-weight: 700;
  font-size: 12px;
  svg {
    margin-right: 4px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  button {
    width: calc(50% - 8px);
  }
`;
