import React, { useState, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import {
  Styles,
  TableNavigation,
  ShowAll,
  TableWrapper,
  Menu,
  View,
  Remove,
  Td,
  Arrows,
  HeadDiv
} from './table-styled';
import Pagination from '../pagination/pagination';
import arrow from '../../assets/table-arrow-icon.svg';

function TableRows({
  columns,
  data,
  hasMarginRight,
  hasMenu,
  hasCheckbox,
  onClick,
  onRemoveClick,
  menuChildren
}) {
  const [showMenu, setShowMenu] = useState(true);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(null);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 9);

  return (
    <TableWrapper
      hasMarginRight={hasMarginRight}
      hasMenu={hasMenu}
      hasCheckbox={hasCheckbox}
      menuChildren={menuChildren}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                  <HeadDiv>
                    {column.render('Header')}
                    {column.canSort && (
                      <Arrows>
                        <svg
                          className={column.isSorted && !column.isSortedDesc && 'active'}
                          width='8'
                          height='7'
                          viewBox='0 0 8 7'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7 4L4 1L1 4'
                            stroke='#ACADB9'
                            stroke-width='1.7'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                        <svg
                          className={column.isSorted && column.isSortedDesc && 'active'}
                          width='8'
                          height='8'
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 3.5L4 6.5L7 3.5'
                            stroke='#ACADB9'
                            stroke-width='1.7'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      </Arrows>
                    )}
                  </HeadDiv>
                  {/* Add a sort direction indicator */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index, array) => {
                  return (
                    <>
                      <Td
                        {...cell.getCellProps()}
                        isActive={index + 1 === array.length && selectedMenuIndex === i && showMenu}
                        key={index}
                        onClick={
                          index + 1 === array.length && hasMenu
                            ? () => {
                                setShowMenu(!showMenu);
                                setSelectedMenuIndex(i);
                              }
                            : onClick
                        }
                      >
                        {cell.render('Cell')}
                      </Td>
                      {index + 1 === array.length && selectedMenuIndex === i && showMenu ? (
                        <>
                          {!menuChildren ? (
                            <Menu>
                              <View>
                                <svg
                                  width='16'
                                  height='12'
                                  viewBox='0 0 16 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M1.61342 6.47513C1.52262 6.33137 1.47723 6.25949 1.45182 6.14862C1.43273 6.06534 1.43273 5.93401 1.45182 5.85073C1.47723 5.73986 1.52262 5.66798 1.61341 5.52422C2.36369 4.33624 4.59693 1.33301 8.00027 1.33301C11.4036 1.33301 13.6369 4.33623 14.3871 5.52422C14.4779 5.66798 14.5233 5.73986 14.5487 5.85073C14.5678 5.93401 14.5678 6.06534 14.5487 6.14862C14.5233 6.25949 14.4779 6.33137 14.3871 6.47513C13.6369 7.66311 11.4036 10.6663 8.00027 10.6663C4.59693 10.6663 2.36369 7.66311 1.61342 6.47513Z'
                                    stroke='#29263A'
                                    strokeWidth='1.6'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M8.00027 7.99967C9.10484 7.99967 10.0003 7.10424 10.0003 5.99967C10.0003 4.89511 9.10484 3.99967 8.00027 3.99967C6.8957 3.99967 6.00027 4.89511 6.00027 5.99967C6.00027 7.10424 6.8957 7.99967 8.00027 7.99967Z'
                                    stroke='#29263A'
                                    strokeWidth='1.6'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                                <p>View</p>
                              </View>
                              <Remove onClick={onRemoveClick}>
                                <svg
                                  width='14'
                                  height='16'
                                  viewBox='0 0 14 16'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M9.66667 3.99967V3.46634C9.66667 2.7196 9.66667 2.34624 9.52134 2.06102C9.39351 1.81014 9.18954 1.60616 8.93865 1.47833C8.65344 1.33301 8.28007 1.33301 7.53333 1.33301H6.46667C5.71993 1.33301 5.34656 1.33301 5.06135 1.47833C4.81046 1.60616 4.60649 1.81014 4.47866 2.06102C4.33333 2.34624 4.33333 2.7196 4.33333 3.46634V3.99967M1 3.99967H13M11.6667 3.99967V11.4663C11.6667 12.5864 11.6667 13.1465 11.4487 13.5743C11.2569 13.9506 10.951 14.2566 10.5746 14.4484C10.1468 14.6663 9.58677 14.6663 8.46667 14.6663H5.53333C4.41323 14.6663 3.85318 14.6663 3.42535 14.4484C3.04903 14.2566 2.74307 13.9506 2.55132 13.5743C2.33333 13.1465 2.33333 12.5864 2.33333 11.4663V3.99967'
                                    stroke='#E54D45'
                                    strokeWidth='1.6'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                                <p>Remove</p>
                              </Remove>
                            </Menu>
                          ) : (
                            <>{menuChildren}</>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
}

function Table({
  columnsData,
  columnsRows,
  usePagination = true,
  minWidth,
  hasMenu,
  hasCheckbox,
  hasMarginRight = true,
  onClick,
  onRemoveClick,
  menuChildren
}) {
  const columns = React.useMemo(() => columnsData, []);

  const data = React.useMemo(() => columnsRows, []);

  let pageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <Styles minWidth={minWidth}>
      <TableRows
        columns={columns}
        data={usePagination ? paginatedData : data}
        hasMarginRight={hasMarginRight}
        hasMenu={hasMenu}
        hasCheckbox={hasCheckbox}
        onClick={onClick}
        onRemoveClick={onRemoveClick}
        menuChildren={menuChildren}
      />
      <TableNavigation usePagination={usePagination}>
        <ShowAll>Show All</ShowAll>
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </TableNavigation>
    </Styles>
  );
}

export default Table;
