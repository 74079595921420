import { FilterByBuyType } from '../../../../components/filter-by-buy-type/filter-by-buy-type';
import {
  NearMeBtnWrapper,
  NearMeDesktopText,
  NearMeMobileText
} from '../../../../components/categories-top-filter/categories-top-filter-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { ReactComponent as Pin } from '../../../categories-real-estate/components/main/real-estate/pin.svg';
import { SelectWithLabel } from '../../../../components/select-with-label/select-with-label';
import React from 'react';
import { Container, Mob } from './search-top-filter-styled';
import { ResultsPerPage } from '../../../../components/results-per-page/results-per-page';

export const SearchTopFilter = () => {
  return (
    <>
      <Container>
        <FilterByBuyType />
        <NearMeBtnWrapper>
          <GlobalButton type={'orange-secondary'} onClick={() => {}}>
            <Pin />
            <NearMeDesktopText>Find products near you</NearMeDesktopText>
            <NearMeMobileText>Near you</NearMeMobileText>
          </GlobalButton>
        </NearMeBtnWrapper>
        <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
      </Container>
      <Mob />
    </>
  );
};
