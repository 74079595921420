import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import Orders from '../../components/orders/orders';
import { CreateNewOrder } from '../../components/sidebars/sidebar-create-new-order/create-new-order';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  Background,
  BoxSiteBar,
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  SiteBar,
  Title
} from './new-orders-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Orders' },
  { link: '#', name: 'My Orders' }
];

export const NewOrdersProfile = () => {
  const [isSitebarOrder, setSitebarOrder] = useState(false);

  const newOrder = (status) => {
    setSitebarOrder(status);
    document.querySelector('body').style.overflow = 'hidden';
    scrollTo(0, 0);
  };

  const closeOrderForm = (status) => {
    setSitebarOrder(status);
    document.querySelector('body').style.overflow = 'auto';
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>New Orders</Title>
      <Orders newOrder={newOrder} />
      {isSitebarOrder && (
        <BoxSiteBar>
          <SiteBar>
            <CreateNewOrder closeOrderForm={closeOrderForm} />
          </SiteBar>
        </BoxSiteBar>
      )}
      {isSitebarOrder && <Background />}
    </>
  );
};
