import styled, { css } from 'styled-components';
import pin from './img/pin.svg';
import { buttonReset, RoundBtn } from '../../../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../../../assets/style-helpers/breakpoints';
import { viewType } from '../../../../../../components/view-selection/view-selection';

export const Container = styled.div`
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  overflow: hidden;

  ${(props) =>
    props.isListView &&
    css`
      display: flex;
    `}
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            > div {
              &:first-child {
                height: unset;
                max-width: 112px;
                max-height: 136px;
              }
            }
          `
        : css``}
  }
`;

export const Main = styled.div`
  padding: 16px 16px 20px;
  padding-top: 12px;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            position: relative;
            padding: 12px 12px 12px 10px;
          `
        : css``}
`;

export const Title = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 6px;
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 7px;
  font-weight: 600;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
                      color: #29263A;
                      font-family: Poppins, sans-serif;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 19px;
                      margin: 5px 0;
                    }
          `
        : css``}
`;

export const Location = styled.div`
  padding-left: 22px;
  position: relative;
  line-height: 16px;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 12px;
  &::before {
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(${pin}) no-repeat center;
    background-size: 100% 100%;
    content: '';
    left: 0;
    top: 0;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            display: none;
          `
        : css`
            display: block;
          `}
`;

export const LocationLand = styled.div`
  display: none;
  padding-left: 22px;
  position: relative;
  line-height: 16px;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 12px;
  &::before {
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(${pin}) no-repeat center;
    background-size: 100% 100%;
    content: '';
    left: 0;
    top: 0;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            display: block;
            margin-bottom: 0;
          `
        : css`
            display: none;
          `}
`;

export const Price = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            color: #29263a;
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16.294px; /* 116.383% */
          `
        : css``}
`;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            margin-bottom: 7px;
          `
        : css``}
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--main-green);
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a + a {
    margin-left: 8px;
  }
`;

export const RateStarsDesktop = styled.div`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;

export const RateStarsMobile = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            display: none;
          `
        : css`
            display: flex;
          `}
`;

export const Logo = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
  img {
    width: 100%;
  }
`;

export const Description = styled.div`
  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    min-width: 0;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #676a75;
    font-size: 12px;
    line-height: 17px;
    font-family: 'Lato', sans-serif;
  }
`;

export const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            position: absolute;
            right: 12px;
            bottom: 12px;
          `
        : css``}
`;

export const Contacts = styled.div`
  display: flex;
  a {
    color: var(--main-green);
    transition: all 0.3s;
    display: flex;
    &:hover {
      color: var(--main-green);
    }
  }

  a + a {
    margin-left: 12px;
  }
`;

export const LinkButton = styled.button`
  ${buttonReset};
  width: 44px;
  height: 44px;
  background: var(--main-green);
  transition: all 0.3s;
  color: var(--main-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--main-green);
  }
`;

export const FavBtn = styled(RoundBtn)`
  ${buttonReset};
  display: none;
  width: 44px;
  height: 44px;
  margin-right: 8px;
  border: 1px solid var(--main-gray);
  background: var(--main-white);
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
`;

export const ListViewAgent = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    ${(props) =>
      props.view === viewType.list
        ? css`
            display: none;
          `
        : css`
            display: flex;
          `}
`;

export const Agent = styled.div`
  display: flex;
  align-items: center;
`;

export const AgentInfo = styled.div``;

export const AgentName = styled.div`
  max-width: 62px;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const AgentRole = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: var(--main-green);
`;
