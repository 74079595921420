import React from 'react';

import {
  Badge,
  Buttons,
  BuyBtn,
  Container,
  Content,
  FavBtn4,
  ImgWrapper,
  SubTitle,
  Main,
  MainPrice,
  RateStarsDesktop,
  OldPrice,
  MainBox,
  Price,
  LabelNotAvailable,
  ContactsBox,
  Title,
  Label,
  ZWLPrice,
  LabelInStock,
  LabelOutStock
} from './p-card-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { RateStars } from '../rate-stars/rate-stars';
import { ApproveSeller } from '../approve-seller/approve-seller';

export const PCardBasicView = ({ product, handleFavBtnClick, isFav }) => {
  return (
    <Container>
      <ImgWrapper>
        <ApproveSeller />
        <img src={product.img} alt={product.title} />
        {product.stock ? (
          <LabelInStock>Stock</LabelInStock>
        ) : (
          <LabelOutStock>Out stock</LabelOutStock>
        )}
        {product.delivery ? (
          <Label>Delivery Available</Label>
        ) : (
          <LabelNotAvailable>Delivery not available</LabelNotAvailable>
        )}
        <FavBtn4 onClick={handleFavBtnClick} isFav={isFav}>
          <SvgIcon name='heart-2' />
        </FavBtn4>
      </ImgWrapper>
      <Main>
        <MainBox>
          <RateStarsDesktop>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Title>{product.title}</Title>
        {product.description && <SubTitle>{product.description}</SubTitle>}
        <Content>
          <Price>
            <OldPrice>{product.old_price}</OldPrice>
            <MainPrice>{product.price}</MainPrice>
            <ZWLPrice>ZWL 200</ZWLPrice>
          </Price>
          <Buttons>
            <BuyBtn>
              <SvgIcon name='cart' />
            </BuyBtn>
          </Buttons>
        </Content>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </Container>
  );
};
