import styled from 'styled-components';
import { LatoFont } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const CompareTable = styled.table`
  width: 100%;
  max-width: 665px;
  border-collapse: collapse;
  @media ${device.mobile} {
    display: none;
  }
  td:not(:first-child) {
    text-align: center;
    ${LatoFont};
  }

  td {
    padding: 12px 20px;
  }

  tbody tr:nth-child(odd) {
    background: #f8f8f8;
  }
  tbody tr:last-child {
    background: none;
    border-top: 1px solid #ededed;
    td {
      padding-top: 32px;
    }
  }

  thead {
    tr {
      border-bottom: 1px solid #ededed;
    }
    th {
      padding-bottom: 32px;
    }
  }
`;

export const SubTitle = styled.div`
  font-size: 10px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #707070;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 1.5;
  color: var(--main-green);
  margin-bottom: 4px;
`;

export const Description = styled.div`
  ${LatoFont};
  color: #676a75;

  font-size: 12px;
  line-height: 1.5;
  max-width: 200px;
  margin: 0 auto 16px;
  font-weight: 400;
  @media ${device.mobile} {
    margin: 0 0 16px;
  }
`;

export const MobileTable = styled.div`
  display: none;
  width: 100%;
  @media ${device.mobile} {
    display: block;
  }
`;

export const Section = styled.div`
  width: 100%;

  & + & {
    margin-top: 40px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    td:not(:first-child) {
      text-align: right;
      ${LatoFont};
    }

    td {
      padding: 12px 20px;
    }

    tr:nth-child(odd) {
      background: #f8f8f8;
    }
    tr:last-child {
      background: none;
      border-bottom: 1px solid #ededed;
    }
  }
  button {
    width: 100%;
  }
`;
