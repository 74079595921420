export const FoodIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.00695 9.76364C3.07039 9.46507 3.28323 9.22155 3.5685 9.12116C3.85376 9.02078 4.17015 9.07805 4.40325 9.27227L6.13126 10.4422C6.35637 10.5795 6.62924 10.6115 6.87946 10.53C7.12968 10.4485 7.33275 10.2614 7.43627 10.0171C10.6557 3.47717 18.6851 5.43227 20.9531 12.2101C21.0156 12.3982 21.0156 12.6018 20.9531 12.7899C18.6851 19.5677 10.6557 21.5228 7.43627 14.9829C7.33229 14.7386 7.12878 14.5519 6.87828 14.4709C6.62778 14.3899 6.35486 14.4225 6.12998 14.5604L4.40196 15.7303C4.16852 15.9239 3.85215 15.9805 3.5672 15.8796C3.28224 15.7787 3.06991 15.5349 3.00695 15.2364C2.99116 15.1265 3.00447 15.0143 3.04552 14.9114L3.77067 12.8263C3.84318 12.6158 3.84318 12.3868 3.77067 12.1763L3.0468 10.0964C3.00393 9.9912 2.99016 9.87617 3.00695 9.76364Z'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.676 15.0468C13.2876 15.4395 13.2911 16.0727 13.6838 16.461C14.0764 16.8494 14.7096 16.8459 15.0979 16.4533L13.676 15.0468ZM17.8533 13.6675C18.2416 13.2748 18.2381 12.6417 17.8455 12.2533C17.4528 11.8649 16.8197 11.8684 16.4313 12.2611L17.8533 13.6675ZM10.4296 14.615C10.0413 15.0077 10.0447 15.6408 10.4374 16.0292C10.8301 16.4176 11.4632 16.4141 11.8516 16.0214L10.4296 14.615ZM17.3609 10.4512C17.7493 10.0585 17.7458 9.42538 17.3532 9.03701C16.9605 8.64864 16.3274 8.65212 15.939 9.04478L17.3609 10.4512ZM10.0026 11.333C9.61427 11.7257 9.61775 12.3589 10.0104 12.7472C10.4031 13.1356 11.0362 13.1321 11.4246 12.7395L10.0026 11.333ZM14.1799 9.9537C14.5683 9.56104 14.5648 8.92788 14.1721 8.53951C13.7795 8.15113 13.1463 8.15461 12.758 8.54728L14.1799 9.9537ZM15.0979 16.4533L17.8533 13.6675L16.4313 12.2611L13.676 15.0468L15.0979 16.4533ZM11.8516 16.0214L17.3609 10.4512L15.939 9.04478L10.4296 14.615L11.8516 16.0214ZM11.4246 12.7395L14.1799 9.9537L12.758 8.54728L10.0026 11.333L11.4246 12.7395Z'
        fill='black'
      />
    </svg>
  );
};
