import styled from 'styled-components';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { title20 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Content = styled.div`
  width: 580px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Title = styled.div`
  ${title20};
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
  margin-bottom: 24px;
`;

export const PromoteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  @media ${device.mobile} {
    flex-wrap: wrap;
    padding: 16px 20px;
    > div {
      flex-basis: 100%;
      justify-content: space-between;
      &:last-child {
        margin-top: 12px;
      }
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  & + & {
    margin-top: 16px;
  }
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
  ${LatoFont};

  span {
    margin-right: 8px;
  }
`;

export const Price = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

export const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${title20};
  margin-top: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  button {
    width: calc(50% - 8px);
    @media ${device.mobile} {
      width: 100%;
    }
  }

  button + button {
    @media ${device.mobile} {
      margin-top: 16px;
    }
  }
`;

export const SelectWrapper = styled.div`
  max-width: 102px;
  margin-right: 16px;
`;
