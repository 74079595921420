import React from 'react';
import './account-info.scss';

import userLogo from '../../../../assets/user_logo.svg';
import stars from '../../../../assets/stars.svg';
import notification from '../../../../assets/notification.svg';

import Map from '../map/map';

import {
  AboutUs,
  Account,
  AccountInfoMap,
  Button,
  HorizontalLine,
  HorizontalLineMobile,
  Info,
  Logo,
  Rating,
  Status,
  Text,
  Time,
  TimeSecond,
  TimeSection,
  User,
  UserInfo
} from './account-info-styled';

const AccountInfo = () => {
  return (
    <AccountInfoMap>
      <Account>
        <User>
          <UserInfo>
            <Logo>
              <img src={userLogo} alt='' />
            </Logo>
            <Text>
              <p className='name'>SamaR Haute Plug</p>
              <p className='role'>General merchandise</p>
              <Rating>
                <img className='stars' src={stars} alt='' />
                <p className='reviews'>
                  10 Reviews <span>- 25 Items Sold</span>
                </p>
              </Rating>
            </Text>
          </UserInfo>
          <Button>
            <img src={notification} alt='' />
            <p>Follow</p>
          </Button>
        </User>
        <HorizontalLine />
        <Info>
          <AboutUs>
            <p className='title'>About Us</p>
            <p className='description'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type.
            </p>
          </AboutUs>
          <HorizontalLineMobile />
          <TimeSection>
            <Time>
              <p className='title'>Opening Times</p>
              <div className='days'>
                <div className='schedule'>
                  <p className='day'>Mon</p>
                  <p className='hours'>08:00 - 17:00</p>
                </div>
                <div className='schedule'>
                  <p className='day'>Mon</p>
                  <p className='hours'>08:00 - 17:00</p>
                </div>
                <div className='schedule'>
                  <p className='day'>Mon</p>
                  <p className='hours'>08:00 - 17:00</p>
                </div>
                <div className='schedule'>
                  <p className='day'>Mon</p>
                  <p className='hours'>08:00 - 17:00</p>
                </div>
              </div>
            </Time>
            <TimeSecond>
              <Status>
                <span>&#x2022;</span> Open now
              </Status>
              <div className='days'>
                <div className='schedule'>
                  <p className='day'>Fri</p>
                  <p className='hours'>08:00 - 17:00</p>
                </div>
                <div className='schedule'>
                  <p className='day'>Sat</p>
                  <p className='hours'>08:00 - 12:00</p>
                </div>
                <div className='schedule'>
                  <p className='day'>Sun</p>
                  <p className='hours'>Closed</p>
                </div>
              </div>
            </TimeSecond>
          </TimeSection>
        </Info>
      </Account>
      <Map />
    </AccountInfoMap>
  );
};

export default AccountInfo;
