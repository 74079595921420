import styled from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const TrackingLineBlock = styled.div`
  display: flex;
  width: 530px;
  margin-top: 28px;
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobile} {
    > button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 65px;
  &:last-child {
    margin-right: 0px;
  }
  @media ${device.mobile} {
    margin-right: 35px;
  }
`;

export const IcoDone = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background: #388638;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const LineDone = styled.span`
  position: absolute;
  top: 20px;
  left: -115px;
  border: 1px dashed #388638;
  width: 123px;
  height: 1px;
  z-index: -1;
  @media ${device.mobile} {
    left: -75px;
    width: 82px;
  }
`;

export const IcoDefault = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const LineDefault = styled.span`
  position: absolute;
  top: 20px;
  left: -115px;
  border: 1px dashed #dcdcdc;
  width: 123px;
  height: 1px;
  z-index: -1;
  @media ${device.mobile} {
    left: -93px;
    width: 95px;
  }
`;

export const Img = styled.img``;

export const TitleDone = styled.div`
  color: #29263a;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
  @media ${device.mobile} {
    text-align: center;
    height: 23px;
  }
`;

export const Title = styled.div`
  color: #29263a;
  opacity: 0.5;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
  @media ${device.mobile} {
    text-align: center;
    height: 23px;
  }
`;
