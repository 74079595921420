import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const ViewSelectionStyled = styled.div`
  display: flex;
}
  @media ${device.tablet} {
    ${(props) =>
      props.isMobileHidden &&
      css`
        display: none;
      `}
`;

export const ViewSelectionItem = styled.button`
  ${buttonReset};
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.3s;
  color: #c9cad3;

  ${(props) =>
    !props.checked &&
    css`
      &:hover {
        color: var(--main-green);
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      background: var(--main-green);
      color: var(--main-white);
    `}

  & + & {
    margin-left: 8px;
  }
`;
