import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import Lists from '../../components/lists/lists';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  Title
} from './appointments-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Appointments' },
  { link: '#', name: 'All Appointments' }
];

export const Appointments = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>All Appointments</Title>
      <Lists />
    </>
  );
};
