import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { paragraph30 } from '../../assets/style-helpers/typography';
import { LatoFont } from '../../assets/style-helpers/style-helpers';

export const ButtonMobile = styled.div`
  display: none;
  align-items: center;
  svg {
    margin-right: 11px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
  }
  @media ${device.tablet} {
    display: flex;
  }
`;

export const FilterButton = styled.button`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;
  @media ${device.tablet} {
    display: flex;
    width: unset;
    font-size: 14px;
    padding: 8px 28px;
    margin-bottom: unset;
    margin-right: 22px;

    img {
      margin-right: 11px;
    }
  }
  @media ${device.mobile} {
    width: unset;
    font-size: 14px;
    padding: 8px 10px;
    margin-bottom: unset;
    margin-right: 8px;

    img {
      margin-right: 11px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 25px;
  @media ${device.tablet} {
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 30px 0;
  @media ${device.tablet} {
    margin: 17px 0 17px 0;
  }
`;

export const NearMeBtnWrapper = styled.div`
  flex-shrink: 0;
  @media ${device.mobile} {
    button {
      padding: 8px 10px;
    }
    svg {
      display: none;
    }
  }
`;

export const NearMeDesktopText = styled.span`
  @media ${device.tablet} {
    display: none;
  }
`;

export const NearMeMobileText = styled.span`
  display: none;
  @media ${device.tablet} {
    display: inline;
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  @media ${device.mobile} {
    flex-grow: 1;
    justify-content: space-around;
    position: relative;
  }
`;

export const SearchWrapper = styled.div`
  flex-grow: 1;
`;

export const SortWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const TradesNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 12px;
  @media ${device.tablet} {
    margin-bottom: 20px;
    > section {
      width: 100%;
      margin-right: 33px;
      input {
        width: 100% !important;
      }
    }
  }
  @media ${device.mobile} {
    display: none;
  }
  > section {
    @media ${device.laptop} {
      width: 560px;
    }
    @media ${device.tablet} {
      width: 486px;
    }
  }
`;

export const TradesNavMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
  }
`;

export const ClearAll = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #676a75;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px 4px 14px;
  border: 1px solid var(--divider);
  border-radius: 20px;
  cursor: pointer;
  ${LatoFont};
  font-weight: 700;
  img {
    margin-right: 8px;
  }
`;

export const TagsSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
