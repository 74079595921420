import styled from 'styled-components';
import { LatoFont, mainContainer } from '../../assets/style-helpers/style-helpers';
import { title30 } from '../../assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 106px;
  @media ${device.tablet} {
    flex-direction: row-reverse;
  }
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const Col = styled.div`
  &:first-child {
    flex-basis: 396px;
    @media ${device.laptop} {
      flex-basis: 350px;
    }
    @media ${device.tablet} {
      flex-basis: 340px;
    }
    @media ${device.mobile} {
      margin-top: 40px;
    }
  }
  &:last-child {
    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 80px;
  @media ${device.laptop} {
    margin: 0 40px;
  }
  @media ${device.tablet} {
    margin-left: 0;
  }
  @media ${device.mobile} {
    margin-right: 0;
  }
`;

export const Title = styled.div`
  ${title30};
`;

export const Subtitle = styled.div`
  color: var(--main-subtext);
  margin-bottom: 20px;
  ${LatoFont};
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div``;
