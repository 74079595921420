import Header from '../../components/header/header';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from './shopping-center-single-styled';
import banner from 'assets/temp-img/single-shopping-center-top.png';
import logo from 'assets/temp-img/single-shopping-center-avatar.png';
import { StoreFrontTop } from '../../components/store-front-top/store-front-top';
import { OurProducts } from '../store-front-trades-services/components/our-products/our-products';
import { OurStores } from './components/our-stores/our-stores';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Shopping Centres' }
];

export const ShoppingCentreMock = {
  img: banner,
  logo: logo,
  name: 'Properties',
  type: 'Real Estate',
  rate: 4.5,
  reviews: 50,
  propertiesSold: 25,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ',
  address: '33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD',
  openTime: [
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    'Closed'
  ]
};

export const ShoppingCenterSingle = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <StoreFrontTop storeInfo={ShoppingCentreMock} />
        <OurStores />
        <OurProducts />
      </Container>
    </>
  );
};
