import { MobileBottomMenuStyled, MobileFooterItem } from './mobile-bottom-menu-styled';
import { ReactComponent as HomeComponentSvg } from './img/home.svg';
import { ReactComponent as DollarSvg } from './img/dollar.svg';
import { ReactComponent as AccountSvg } from './img/account.svg';
import { ReactComponent as HelpSvg } from './img/help.svg';

export const MobileBottomMenu = () => {
  return (
    <MobileBottomMenuStyled>
      <MobileFooterItem to={'#'}>
        <HomeComponentSvg />
        <span>Home</span>
      </MobileFooterItem>
      <MobileFooterItem to={'#'}>
        <DollarSvg />
        <span>Sell Items</span>
      </MobileFooterItem>
      <MobileFooterItem to={'#'}>
        <AccountSvg />
        <span>Account</span>
      </MobileFooterItem>
      <MobileFooterItem to={'#'}>
        <HelpSvg />
        <span>Help</span>
      </MobileFooterItem>
    </MobileBottomMenuStyled>
  );
};
