import Header from 'components/header/header';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import {
  Col,
  Container,
  Main,
  Subtitle,
  Title,
  TitleWrapper,
  TopWrapper,
  Wrapper
} from './cart-styled';
import { SponsoredAdv } from './components/sponosred-adv/sponsored-adv';
import { ProductsYouMayLike } from './components/products-you-may-like/products-you-may-like';
import { CartBlock } from './components/cart-block/cart-block';

import img from './components/product-item/img/temp-img.png';
import { AdSpace } from './components/ad-space/ad-space';
import { GlobalButton } from '../../components/global-button/global-button';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Cart' }
];

const products = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

const products2 = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

export const Cart = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Wrapper>
          <Col>
            <ProductsYouMayLike />
            <SponsoredAdv />
          </Col>
          <Main>
            <TopWrapper>
              <TitleWrapper>
                <Title>Cart Items</Title>
                <Subtitle>You have 6 items in your cart</Subtitle>
              </TitleWrapper>
              <GlobalButton type={'orange-secondary'}>Save for later</GlobalButton>
            </TopWrapper>

            <CartBlock products={products} />
            <CartBlock products={products2} />
          </Main>
          <Col>
            <SponsoredAdv />
            <AdSpace />
          </Col>
        </Wrapper>
      </Container>
    </>
  );
};
