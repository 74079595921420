import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div``;

export const Title = styled.div`
  ${title30};
  margin-bottom: 10px;
  @media ${device.tablet} {
    font-size: 24px;
    line-height: 28.8px;
  }
  @media ${device.mobile} {
    font-size: 24px;
    line-height: 28.8px;
  }
`;

export const PlansWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin-top: 32px;
  margin-bottom: 106px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
