import React from 'react';

import {
  Badge,
  Buttons,
  BuyBtn,
  ImgWrapper,
  Main,
  MainPriceTradesView,
  Price,
  MainBox,
  ContactsBox,
  TitleTradesView,
  Text,
  ContainerTradesView,
  RateStarsDesktop,
  ContentTradesView,
  ZWLPrice
} from './p-card-styled';

import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import { ApproveSeller } from '../approve-seller/approve-seller';
import { FavBtn } from '../categories-card/categories-card-styled';
import { viewType } from '../view-selection/view-selection';

const PCardTradesView = ({ product, isMobile, currentViewType }) => {
  return (
    <ContainerTradesView currentViewType={currentViewType}>
      <ImgWrapper currentViewType={currentViewType}>
        <ApproveSeller />
        <img src={product.img} alt={product.title} />
      </ImgWrapper>
      <Main currentViewType={currentViewType}>
        <MainBox>
          <RateStarsDesktop>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <TitleTradesView currentViewType={currentViewType}>{product.title}</TitleTradesView>
        <Text>{product.description}</Text>
        <ContentTradesView>
          <Price currentViewType={currentViewType}>
            <MainPriceTradesView currentViewType={currentViewType}>
              {product.price}
            </MainPriceTradesView>
            <ZWLPrice currentViewType={currentViewType}>ZWL 200</ZWLPrice>
          </Price>
          <Buttons>
            {currentViewType === viewType.list && (
              <FavBtn landScape={currentViewType === viewType.list}>
                <SvgIcon name={'heart-2'} />
              </FavBtn>
            )}

            <BuyBtn>
              <SvgIcon name='eye' />
            </BuyBtn>
          </Buttons>
        </ContentTradesView>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </ContainerTradesView>
  );
};

export default PCardTradesView;
