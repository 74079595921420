import {
  Container,
  Filters,
  FilterBtn,
  FiltersWrapepr,
  FiltersTitle,
  Buttons,
  Line
} from './filter-widget-bottom-styled';
import { GlobalButton } from '../../../global-button/global-button';

export const FilterWidgetBottom = ({ checkboxes, onClose, onTagClick }) => {
  return (
    <Container>
      <Filters>
        <FiltersTitle>
          <Line />
          {checkboxes.length} Selected
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6 8L10 12L14 8'
              stroke='#388638'
              strokeWidth='1.7'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </FiltersTitle>
        <FiltersWrapepr>
          {checkboxes.map((item) => {
            return (
              <FilterBtn key={item.id}>
                <svg
                  width='17'
                  height='18'
                  viewBox='0 0 17 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={() => {
                    onTagClick(item.id);
                  }}
                >
                  <path
                    d='M12.0404 5.45825L4.95703 12.5416M4.95703 5.45825L12.0404 12.5416'
                    stroke='#29263A'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                {item.name}
              </FilterBtn>
            );
          })}
        </FiltersWrapepr>
      </Filters>
      <Buttons>
        <GlobalButton
          type='gray'
          onClick={() => {
            onClose();
          }}
        >
          <svg
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8 7.5L13 12.5M13 7.5L8 12.5M7 17.5H14C15.4001 17.5 16.1002 17.5 16.635 17.2275C17.1054 16.9878 17.4878 16.6054 17.7275 16.135C18 15.6002 18 14.9001 18 13.5V6.5C18 5.09987 18 4.3998 17.7275 3.86502C17.4878 3.39462 17.1054 3.01217 16.635 2.77248C16.1002 2.5 15.4001 2.5 14 2.5H7C5.59987 2.5 4.8998 2.5 4.36502 2.77248C3.89462 3.01217 3.51217 3.39462 3.27248 3.86502C3 4.3998 3 5.09987 3 6.5V13.5C3 14.9001 3 15.6002 3.27248 16.135C3.51217 16.6054 3.89462 16.9878 4.36502 17.2275C4.8998 17.5 5.59987 17.5 7 17.5Z'
              stroke='#ACADB9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Cancel
        </GlobalButton>
        <GlobalButton
          onClick={() => {
            onClose();
          }}
        >
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Apply
        </GlobalButton>
      </Buttons>
    </Container>
  );
};
