export const Delete = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.7778 3.83247V3.27691C10.7778 2.49906 10.7778 2.11013 10.6264 1.81303C10.4932 1.5517 10.2808 1.33922 10.0194 1.20607C9.72233 1.05469 9.33341 1.05469 8.55556 1.05469H7.44444C6.66659 1.05469 6.27767 1.05469 5.98057 1.20607C5.71923 1.33922 5.50676 1.5517 5.3736 1.81303C5.22222 2.11013 5.22222 2.49906 5.22222 3.27691V3.83247M1.75 3.83247H14.25M12.8611 3.83247V11.6102C12.8611 12.777 12.8611 13.3604 12.634 13.8061C12.4343 14.1981 12.1156 14.5168 11.7236 14.7165C11.2779 14.9436 10.6946 14.9436 9.52778 14.9436H6.47222C5.30545 14.9436 4.72206 14.9436 4.27641 14.7165C3.8844 14.5168 3.56569 14.1981 3.36596 13.8061C3.13889 13.3604 3.13889 12.777 3.13889 11.6102V3.83247'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
