import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import { ProductLiveAuctionDay } from '../add-new-product-form-auction/components/product-live-auction-day/product-live-auction-day';
import { AddNewProductLayout } from './components/add-new-product-layout/add-new-product-layout';
import { ProductAttributes } from './components/product-attributes/product-attributes';
import { ProductCategories } from './components/product-categories/product-categories';
import { ProductDetails } from './components/product-details/product-details';
import { ProductImage } from './components/product-image/product-image';
import { ProductPricing } from './components/product-pricing/product-pricing';

import {
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper,
  Title
} from './add-new-product-form-auction-styled.jsx';
import { ProductSubmitBtns } from '../../../dashboard/components/add-product-form-components/product-submit-btns';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductFormAuctionProfile = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Add New Product</Title>
      <AddNewProductLayout
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      >
        <ProductDetails selectOptions={selectOptions} />
        <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
        <ProductImage />
        <ProductPricing selectOptions={selectOptions} />
        <ProductLiveAuctionDay />
        <ProductAttributes selectOptions={selectOptions} />
        <ProductSubmitBtns
          isSidebarHidden={isSidebarHidden}
          setIsSidebarHidden={setIsSidebarHidden}
        />
      </AddNewProductLayout>
    </>
  );
};
