import {
  CategoriesBreadcrumbsWrapper,
  CategoriesSectionSelectsWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalSelect } from 'components/global-select/global-select';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { InputGroup } from 'components/input-group/input-group';

export const ProductCategories = ({ selectOptions, breadcrumbs }) => {
  return (
    <Section>
      <SectionTitle>Categories</SectionTitle>
      <CategoriesSectionSelectsWrapper>
        <InputGroup label={'category'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
        <InputGroup label={'Subcategory'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
        <InputGroup label={'sub-Subcategory'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </CategoriesSectionSelectsWrapper>
      <CategoriesBreadcrumbsWrapper>
        <span>E.g.</span>
        <Breadcrumbs links={breadcrumbs} />
      </CategoriesBreadcrumbsWrapper>
    </Section>
  );
};
