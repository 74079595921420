import { useState } from 'react';

import { ViewSelection, viewType } from 'components/view-selection/view-selection';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { Container, Title, TitleWrapper } from './our-services-styled';
import { Section } from '../section/section';
import { ServiceCard } from './components/service-card/service-card';
import { StoreFrontProductSection } from '../../../../components/store-front-product-section/store-front-product-section';

const Button = () => (
  <GlobalButton type={'secondary'} height={40}>
    <SvgIcon name={'eye'} />
    See more services
  </GlobalButton>
);

const servicesCardsMock = [
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  }
];

export const OurServices = () => {
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  return (
    <StoreFrontProductSection
      searchBarLabel={'Search in trades and services'}
      additionalBtn={<Button />}
      setCurrentViewType={setCurrentViewType}
      currentViewType={currentViewType}
    >
      {servicesCardsMock.map((service, index) => {
        return (
          <ServiceCard
            service={service}
            key={index}
            isListView={currentViewType === viewType.list}
          />
        );
      })}
    </StoreFrontProductSection>
  );
};
