import React from 'react';

import { ReactComponent as Arrow } from './arrow.svg';
import { ResultsPerPageStyled } from './results-per-page-styled';

export const ResultsPerPage = ({ className }) => (
  <ResultsPerPageStyled className={className}>
    <p>Results per page: &nbsp;</p>
    <span>
      122
      <Arrow />
    </span>
  </ResultsPerPageStyled>
);
