import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './products-slider.scss';
import {
  BtnsWrapper,
  SliderWrapper,
  NextSlideBtn,
  PrevSlideBtn,
  Wrapper,
  FadeOutContainer
} from './products-slider-styled';
import { Container } from '../footer/footer-styled';
import { SvgIcon } from '../svg-icon/svg-icon';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  arrows: false,
  className: 'products-slick',
  dotsClass: 'products-dots',
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        dots: true
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    }
  ]
};

export const ProductsSlider = ({ children }) => {
  const slider = useRef(null);
  return (
    <Wrapper>
      <FadeOutContainer>
        <Container>
          <SliderWrapper>
            <Slider
              {...sliderSettings}
              ref={(r) => (slider.current = r)}
              className={'products-slider'}
            >
              {children}
            </Slider>
          </SliderWrapper>
        </Container>
      </FadeOutContainer>

      <Container>
        <BtnsWrapper>
          <PrevSlideBtn onClick={() => slider.current.slickPrev()}>
            <SvgIcon name={'slider-arrow'} />
          </PrevSlideBtn>
          <NextSlideBtn onClick={() => slider.current.slickNext()}>
            <SvgIcon name={'slider-arrow'} />
          </NextSlideBtn>
        </BtnsWrapper>
      </Container>
    </Wrapper>
  );
};
