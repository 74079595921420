export const FilterIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='11.6111'
        height='1.85714'
        rx='0.928571'
        transform='matrix(-1 0 0 1 19 12.2858)'
        fill='currentColor'
      />
      <path
        d='M2.6977 11.2447C1.62458 12.3326 1.62458 14.0965 2.6977 15.1843C3.77083 16.2722 5.51071 16.2722 6.58383 15.1843C7.65695 14.0965 7.65695 12.3326 6.58383 11.2447C5.51071 10.1569 3.77083 10.1569 2.6977 11.2447Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='1' y='4.85693' width='11.6111' height='1.85714' rx='0.928571' fill='currentColor' />
      <path
        d='M17.3023 3.81592C18.3754 4.90381 18.3754 6.66762 17.3023 7.75551C16.2292 8.8434 14.4893 8.8434 13.4162 7.75551C12.343 6.66762 12.343 4.90381 13.4162 3.81592C14.4893 2.72803 16.2292 2.72803 17.3023 3.81592Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
