import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  flex-grow: 1;
`;

export const Top = styled.div`
  margin-bottom: 16px;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;

export const InputWrapper = styled.div`
  max-width: 570px;
  margin-bottom: 28px;
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const Tags = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  margin-bottom: 40px;

  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
