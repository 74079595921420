import { useState } from 'react';
import { ReactComponent as CloseIcon } from './img/close-icon.svg';
import { ReactComponent as MinusIcon } from './img/minus.svg';
import { ReactComponent as PlusIcon } from './img/plus.svg';
import {
  Container,
  Counter,
  DeleteBtn,
  Description,
  DescriptionItem,
  ImgWrapper,
  Main,
  Price,
  PriceMobile,
  Subtitle,
  Title
} from './product-item-styled';

export const ProductItem = ({ product }) => {
  const [counter, setCouter] = useState(0);
  return (
    <Container>
      <ImgWrapper>
        <img src={product.img} alt={product.title} />
      </ImgWrapper>
      <Main>
        <Title>{product.title}</Title>
        <Subtitle>{product.subtitle}</Subtitle>
        <Description>
          <DescriptionItem>Qty: 1</DescriptionItem>
          <DescriptionItem> Black</DescriptionItem>
          <DescriptionItem>32 GB</DescriptionItem>
        </Description>
      </Main>
      <Counter>
        <PriceMobile>{product.price}</PriceMobile>
        <div>
          <button
            onClick={() => {
              setCouter(counter - 1);
            }}
          >
            <MinusIcon />
          </button>
          <input
            value={counter}
            onChange={(e) => {
              setCouter(+e.target.value);
            }}
          />
          <button
            onClick={() => {
              setCouter(counter + 1);
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </Counter>
      <Price>{product.price}</Price>
      <DeleteBtn>
        <span> Delete</span>
        <CloseIcon />
      </DeleteBtn>
    </Container>
  );
};
