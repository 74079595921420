import styled, { css } from 'styled-components';
import { LatoFont } from '../../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const BusinessSettingsSidebarStyled = styled.div`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 40px;

  @media ${device.laptop} {
    position: fixed;
    right: 0;
    top: 0;
    width: 670px;
    padding: 40px 103px 40px 32px;
    z-index: 1001;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            transform: translateX(100%);
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media ${device.tablet} {
    padding: 20px 12px;
    width: 100%;
    border-radius: 0;
  }
`;

export const BannerImg = styled.div`
  height: 186px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Main = styled.div`
  background: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
`;

export const TopInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  overflow: hidden;
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TitleWrapper = styled.div``;

export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const Description = styled.div``;

export const SecondaryTitle = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const DescriptionText = styled.div`
  ${LatoFont};
`;

export const Section = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--divider);
`;

export const Map = styled.div`
  margin-bottom: 16px;
  height: 146px;
  border-radius: 16px;
  overflow: hidden;
`;

export const Address = styled.div``;

export const AddressTop = styled.div`
  display: flex;
  align-items: center;
  ${SecondaryTitle} {
    margin-bottom: 0;
  }
  margin-bottom: 8px;
  svg {
    margin-right: 12px;
  }
`;

export const AddressText = styled.div`
  ${LatoFont}
`;

export const ContactsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ContactsTable = styled.div`
  @media ${device.mobile} {
    & + & {
      margin-top: 8px;
    }
  }
`;

export const ContactsItem = styled.div`
  display: flex;
  ${LatoFont};
  span:first-child {
    width: 80px;
    margin-right: 8px;
    font-weight: 700;
  }
  span:last-child {
    max-width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media ${device.mobile} {
      max-width: 100%;
    }
  }

  & + & {
    margin-top: 10px;
  }
`;

export const MobileBackBtn = styled.div`
  display: none;
  @media ${device.laptop} {
    cursor: pointer;
    display: flex;
    color: var(--main-green);
    margin-bottom: 24px;
    margin-top: 12px;
    svg {
      margin-right: 6px;
    }
  }
`;
