import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import {
  Container,
  Main,
  Side,
  Title,
  Wrapper
} from '../../pages/add-new-product-single/add-new-product-single-styled';

import { SidePreview } from '../side-preview/side-preview';
import { ProductPromotion } from '../product-promotion/product-promotion';
// import { ProductOffer } from '../add-product-form-components/product-offer';
// import { ProductServiceData } from '../add-product-form-components/product-service-data';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

// const selectOptions = [
//   { label: 'test', value: 'test' },
//   { label: 'test1', value: 'test1' },
//   { label: 'test2', value: 'test2' }
// ];

export const AddNewProductLayout = ({
  children,
  isSidebarHidden,
  setIsSidebarHidden,
  pageTitle = 'Add New Product',
  isProductPromotion = true
  // isServicePage = false
}) => {
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>{pageTitle}</Title>
      {isProductPromotion && <ProductPromotion />}
      {/* {isServicePage ? <ProductOffer selectOptions={selectOptions} /> : <ProductServiceData />}*/}
      <Wrapper>
        <Main>{children}</Main>
        <Side isSidebarHidden={isSidebarHidden}>
          <SidePreview
            isSidebarHidden={isSidebarHidden}
            onPreviewButtonClick={() => {
              setIsSidebarHidden(!isSidebarHidden);
            }}
          />
        </Side>
      </Wrapper>
    </Container>
  );
};
