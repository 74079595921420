import styled from 'styled-components';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
  margin-top: 80px;
  margin-bottom: 80px;
  @media ${device.tablet} {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const Banner = styled.div`
  flex-basis: 50%;
  margin-right: 35px;
  @media ${device.tablet} {
    flex-basis: 350px;
    margin-right: 20px;
  }
  @media ${device.mobile} {
    margin-top: 0;
    margin-bottom: 48px;
    margin-right: 0;
    flex-basis: auto;
    padding: 0 15px;
  }
`;

export const Wrapper = styled.div`
  flex-basis: calc(50% - 35px);
  > button {
    display: none;
  }
  @media ${device.tablet} {
    flex-grow: 1;
  }
  @media ${device.mobile} {
    > div {
      &:first-child {
        width: 92.5%;
        margin-left: 15px;
      }
    }
    > button {
      display: flex;
      width: 92.5%;
      margin-left: 15px;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
    > div:last-child {
      display: none;
    }
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    > div:nth-child(3) {
      display: none;
    }
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const Inner = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
