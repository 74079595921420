export const ReceiptCheckIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.49967 8.75L9.16634 10.4167L12.9163 6.66667M16.6663 17.5V6.5C16.6663 5.09987 16.6663 4.3998 16.3939 3.86502C16.1542 3.39462 15.7717 3.01217 15.3013 2.77248C14.7665 2.5 14.0665 2.5 12.6663 2.5H7.33301C5.93288 2.5 5.23281 2.5 4.69803 2.77248C4.22763 3.01217 3.84517 3.39462 3.60549 3.86502C3.33301 4.3998 3.33301 5.09987 3.33301 6.5V17.5L5.62467 15.8333L7.70801 17.5L9.99967 15.8333L12.2913 17.5L14.3747 15.8333L16.6663 17.5Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
