import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const SliderWrapper = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
    margin-bottom: 20px;
    .swiper {
      padding-right: 30px;
    }
    .swiper-slide {
      &:first-child {
        margin-left: 15px;
      }
      &:last-child {
        margin-right: 15px;
      }
    }
  }
`;
