import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import { Box } from '../calendar/components/box/box';
import { Container, DashboardBtnWrapper, Main, SidebarWrapper } from './calendar-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Calendar' }
];

export const CalendarProfile = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Box />
    </>
  );
};
