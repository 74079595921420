import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import React, { useState } from 'react';
import filter from '../../../../assets/filter.svg';
import search from '../../../../assets/search.svg';
import Checkbox from '../../../../components/checkbox/checkbox';
import Table from '../../../../components/table/table';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import {
  CheckBlock,
  CheckBox,
  Container,
  DropdownsWrapper,
  FilterButton,
  InputWrapper,
  LeftSide,
  MobileSearch,
  Navigation,
  SearchButton,
  SearchInput,
  Title
} from './all-services-styled';
import { DeleteServicePopup } from './components/delete-service-popup/delete-service-popup';
import { columnsData, columnsRows } from './mock';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Services' },
  { link: '#', name: 'All Services' }
];

const DropdownItems = ['Service Type', 'Status', 'Category'];

const AllServices = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Services</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
            <CheckBox>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={true}
                  label={'Deals  Of The Day'}
                />
              </CheckBlock>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={false}
                  label={'Featured Product'}
                />
              </CheckBlock>
            </CheckBox>
          </LeftSide>
        </Navigation>
        <Table
          columnsData={columnsData}
          columnsRows={columnsRows}
          hasCheckbox
          hasMenu
          onRemoveClick={() => setIsFeedbackPopupVisible(true)}
        />
        <DeleteServicePopup
          isFeedbackPopupVisible={isFeedbackPopupVisible}
          onClose={() => {
            setIsFeedbackPopupVisible(false);
          }}
        />
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default AllServices;
