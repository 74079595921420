import {
  buttonReset,
  cardStyle,
  LatoFont,
  mainContainer,
  PoppinsFont
} from 'assets/style-helpers/style-helpers';
import { title20, title30 } from 'assets/style-helpers/typography';
import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import imgPlaceholder from '../../components/icons/img-placeholder.svg';

export const Container = styled.div`
  ${mainContainer};
  display: flex;
  min-height: 100vh;

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
  @media ${device.mobile} {
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  margin: 32px 0px;
  margin-bottom: 100px;
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;

    @media ${device.laptop} {
      overflow-y: scroll;
      height: auto;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 3px;
        background: rgb(243 243 243);
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(56, 134, 56, 0.1);
      }
    }

    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
    padding-top: 5px;
  }
  @media ${device.mobile} {
    top: 135px;
    padding-top: 12.5px;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 14px;
  }
  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  ${title30};
  margin: 10px 0 30px;
  @media ${device.tablet} {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  @media ${device.mobile} {
    margin: 0px 0 15px;
  }
`;

export const Section = styled.div`
  ${cardStyle};
  padding: 32px;
  @media ${device.mobile} {
    padding: 0;
    box-shadow: none;
  }
  & + & {
    margin-top: 32px;
  }
`;

export const SectionTitle = styled.div`
  ${title20};
  margin-bottom: 16px;
`;

export const SectionText = styled.div`
  color: var(--secondary-text);
  ${LatoFont};
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const SectionSubtitle = styled.div`
  font-size: 14px;
  ${PoppinsFont};
  margin-top: 28px;
  margin-bottom: 18px;
`;

export const InputGroupWrapper = styled.div`
  span {
    ${LatoFont};
    a {
      color: var(--main-green);
    }
  }
  & + & {
    margin-top: 16px;
  }
`;

export const TopSection = styled(Section)``;

export const SelectWrapper = styled.div`
  max-width: 240px;
  flex-basis: 240px;
  margin-right: 40px;
`;

export const TopSectionInputGroupInner = styled.div`
  display: flex;
  ${LatoFont};
  align-items: center;
  @media ${device.mobile} {
    flex-wrap: wrap;
    ${SelectWrapper} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  ${LatoFont};
  & + & {
    margin-left: 16px;
  }
`;

export const Subtext = styled.div`
  color: var(--main-subtext);
  ${LatoFont};
  margin: 8px 0;
`;

export const DateInput = styled.input`
  -webkit-appearance: none;
  padding: 14px 24px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  ${LatoFont};
  &::-webkit-clear-button {
    font-size: 28px;
    height: 30px;
    position: relative;
  }

  &::-webkit-inner-spin-button {
    height: 28px;
  }

  &::-webkit-calendar-picker-indicator {
    font-size: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Main = styled.div`
  // flex-basis: 78%;
  width: 80%;
  margin-right: 32px;
  @media ${device.laptop} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const MainBlock = styled.div``;

export const Side = styled.div`
  width: 100%;
`;

export const CategoriesSectionSelectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MobileOpenPreviwBtnWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    margin-left: 12px;
  }
`;

export const CategoriesBreadcrumbsWrapper = styled.div`
  display: flex;
  ${LatoFont};
  margin-top: 14px;
  color: var(--main-green);
  > div {
    padding: 0;
    margin-left: 8px;
  }
`;

export const ImgUploadWrapper = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ImgPlaceholder = styled.div`
  border: 1px solid var(--divider);
  border-radius: 12px;
  background: url(${imgPlaceholder}) no-repeat center center;
  background-size: 72px 72px;
`;

export const ImgUploadMain = styled.div`
  margin-right: 40px;
  ${ImgPlaceholder} {
    padding: 156px 142px;
    margin-bottom: 16px;
  }
  @media ${device.mobile} {
    margin-right: 0;
    margin-bottom: 40px;
  }
`;

export const ImgUploadTitle = styled.div`
  color: var(--main-gray-3);
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const ImgUploadGallery = styled.div`
  ${ImgPlaceholder} {
    padding: 74px;
  }
`;

export const ImgUploadGalleryWrapper = styled.div`
  display: grid;
  margin-bottom: 16px;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

export const ImgUploadBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.mobile} {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

export const GridInputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 22px;
  margin: 18px 0;
  label {
    ${PoppinsFont};
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridInputWrapper4Items = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 22px;
  margin: 18px 0;
  label {
    ${PoppinsFont};
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PricingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 24px;

  a {
    color: var(--main-green);
    text-decoration: underline;
  }
`;

export const PricingBtnWrapper = styled.div`
  ${PoppinsFont};
`;

export const AttributesInputGroupRow = styled.div`
  display: flex;
  align-items: center;

  ${CheckboxWrapper} {
    flex-shrink: 0;
    margin-left: 24px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    ${CheckboxWrapper} {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
`;

export const TagsSection = styled.div`
  display: flex;
`;

const ColorDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 6px;
`;

export const ColorDotGreen = styled(ColorDot)`
  background: var(--main-green);
`;

export const ColorDotRed = styled(ColorDot)`
  background: #eb4335;
`;

export const ColorDotYellow = styled(ColorDot)`
  background: #ffbb00;
`;

export const FeaturesRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }

  button {
    ${buttonReset};
    color: var(--main-green);
    display: flex;
    align-items: center;
    justify-content: center;
    ${LatoFont};
    margin-top: 40px;
    svg {
      margin-right: 8px;
    }
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 24px;
  display: flex;
`;
