import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 16px;
`;

export const CheckboxWrapper = styled.div``;

export const Title = styled.div`
  font-size: 17px;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  margin-bottom: 12px;
  font-family: 'Lato', sans-serif;
  a {
    color: var(--main-green);
    font-weight: 700;
    &:hover {
      color: var(--main-green);
    }
  }
`;

export const ChecbkoxText = styled.div`
  font-family: 'Lato', sans-serif;
`;

export const Textarea = styled.textarea`
  display: block;
  resize: none;
  width: 100%;
  padding: 24px;
  height: 150px;
  margin: 16px 0;
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  font-family: 'Lato', sans-serif;

  &::placeholder {
    color: var(--main-subtext);
  }
`;
