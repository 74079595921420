import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import { useState } from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import avatar from '../../../../assets/icons/avatar3.png';
import close from '../../../../assets/icons/close.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { GlobalInputPhone } from '../../../../components/global-input-phone/global-input-phone';
import { GlobalInput } from '../../../../components/global-input/global-input';
import Header from '../../../../components/header/header';
import { InputGroup } from '../../../../components/input-group/input-group';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  BoxPhoto,
  BoxPhotoButton,
  ButtonTextInfo,
  ChangePassword,
  CloseBlock,
  Container,
  DashboardBtnWrapper,
  DeleteAccount,
  ForgotPassword,
  Form,
  FormBox,
  Main,
  Photo,
  PhotoBlock,
  Profile,
  ProfileAvatar,
  ProfileBox,
  ProfileContainer,
  ProfileTitle,
  SaveResult,
  SidebarWrapper,
  Title
} from './account-settings-styled';
import NotificationSidebar from '../../../dashboard/components/notifications-sidebar/notifications-sidebar';
import { HelpAndTutorialButton, NotificationIcon } from '../../../dashboard/dashboard-styled';
import notificationSidebarIcon from '../../../dashboard/components/notifications-sidebar/notification-sidebar-icon.svg';
import HelpAndTutorialsSidebar from '../../../dashboard/components/help-and-tutorials-sidebar/help-and-tutorials-sidebar';

const breadcrumbs = [
  { link: '#', name: 'My Account' },
  { link: '#', name: 'Account Settings' }
];

export const AccountSettings = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false);
  const [showHelpAndTutorialSidebar, setShowHelpAndTutorialSidebar] = useState(false);
  const hideSidebar = () => {
    setIsSidebarVisible(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  const showSidebar = () => {
    setIsSidebarVisible(true);
    document.querySelector('body').style.overflow = 'hidden';
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Account Settings</Title>
      <Profile>
        <ProfileContainer>
          <ProfileBox>
            <ProfileTitle>General information</ProfileTitle>
            <ProfileAvatar>
              <PhotoBlock>
                <Photo src={avatar} alt='Photo' />
                <CloseBlock>
                  <Photo src={close} alt='Photo' />
                </CloseBlock>
              </PhotoBlock>
              <BoxPhoto>
                <BoxPhotoButton>
                  <GlobalButton type={'orange-secondary'}>
                    <SvgIcon name={'download'} />
                    Upload a new photo
                  </GlobalButton>
                  <ButtonTextInfo>PNG, JPG, jpeg (Up to 5 MB)</ButtonTextInfo>
                </BoxPhotoButton>
              </BoxPhoto>
            </ProfileAvatar>
            <Form>
              <FormBox>
                <InputGroup label={'First Name'}>
                  <GlobalInput placeholder={'Langton'} />
                </InputGroup>
              </FormBox>
              <FormBox>
                <InputGroup label={'Last Name'}>
                  <GlobalInput placeholder={'Shoriwa'} />
                </InputGroup>
              </FormBox>
              <FormBox>
                <InputGroup label={'WhatsApp Number'}>
                  <GlobalInputPhone placeholder={'522 251 254'} />
                </InputGroup>
              </FormBox>
              <FormBox>
                <InputGroup label={'Email'}>
                  <GlobalInput placeholder={'myemail@gmail.com'} />
                </InputGroup>
              </FormBox>
              <SaveResult>
                <GlobalButton type={'secondary'}>
                  <SvgIcon name={'save'} />
                  Save Changes
                </GlobalButton>
              </SaveResult>
            </Form>
          </ProfileBox>
          <ProfileBox>
            <ProfileTitle>Change Password</ProfileTitle>
            <ChangePassword>
              <Form>
                <FormBox>
                  <InputGroup label={'Current password'}>
                    <GlobalInputPassword />
                  </InputGroup>
                </FormBox>
                <FormBox>
                  <InputGroup label={'New password'}>
                    <GlobalInputPassword />
                  </InputGroup>
                </FormBox>
                <FormBox>
                  <InputGroup label={'Repeat New password'}>
                    <GlobalInputPassword />
                  </InputGroup>
                </FormBox>
                <SaveResult>
                  <GlobalButton type={'secondary'}>
                    <SvgIcon name={'save'} />
                    Save Changes
                  </GlobalButton>
                  <ForgotPassword>Forgot password?</ForgotPassword>
                </SaveResult>
              </Form>
            </ChangePassword>
          </ProfileBox>
          <DeleteAccount>
            <GlobalButton type={'gray'} fullWidth>
              <SvgIcon name={'delete'} />
              Delete Account
            </GlobalButton>
          </DeleteAccount>
        </ProfileContainer>
      </Profile>
    </>
  );
};
