import { SectionHeader } from 'components/section-header/section-header';
import temp1 from 'assets/temp-img/temp1.png';
import temp2 from 'assets/temp-img/temp2.png';
import temp3 from 'assets/temp-img/temp3.png';
import { PCardSmall } from 'components/p-card-small/p-card-small';
import { AdvBanner } from 'components/adv-banner/adv-banner';

import { Banner, CardsWrapper, Container, Inner, Wrapper } from './section-with-banner-styled';
import { MobileProductSwiper } from '../mobile-product-swiper/mobile-product-swiper';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';

const productList = [
  {
    img: temp1,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    description: 'Samar Haute Plug | Norton',
    rate: 3.5,
    reviews: 51,
    delivery: true,
    label: 'new',
    labelColor: '#7CCC67'
  },
  {
    img: temp2,
    title: 'Peach Slices / Del Monte, 980 g',
    old_price: '$9.99',
    price: '$5.50',
    description: 'Samar Haute Plug | Norton',
    rate: 3.5,
    reviews: 51,
    delivery: false,
    label: '50%',
    labelColor: '#FFBB00'
  },
  {
    img: temp3,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    description: 'Samar Haute Plug | Norton',
    rate: 3.5,
    reviews: 51,
    delivery: true,
    label: 'sale'
  },
  {
    img: temp3,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    description: 'Samar Haute Plug | Norton',
    rate: 3.5,
    reviews: 51,
    delivery: true,
    label: 'sale'
  }
];

export const SectionWithBanner = () => {
  return (
    <Container>
      <Inner>
        <Banner>
          <AdvBanner
            buttonText='UP to 50% off'
            title={'Red Carpet Looks'}
            subtitle='Special Occasion Dresses & Style'
            img={'https://picsum.photos/200/300'}
          />
        </Banner>
        <Wrapper>
          <SectionHeader link='#' title={'Auction Sales'} />
          <MobileProductSwiper products={productList} />
          <GlobalButton fullWidth type={'orange-secondary'}>
            <SvgIcon name={'eye'} />
            See all
          </GlobalButton>
          <CardsWrapper>
            {productList.map((product, index) => {
              return <PCardSmall product={product} key={index} />;
            })}
          </CardsWrapper>
        </Wrapper>
      </Inner>
    </Container>
  );
};
