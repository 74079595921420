import styled from 'styled-components';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const Title = styled.div`
  font-family: 'Lato', sans-serif;
`;

const Btn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${buttonReset};
  transition: all 0.3s;

  &:hover {
    color: var(--main-white);
    background: var(--main-green);
  }
`;
export const Wrapper = styled.div`
  border: 1px solid var(--main-gray-2);
  border-radius: 6px;
  padding: 3px;
  display: flex;
  align-items: center;
`;

export const MinusBtn = styled(Btn)`
  background: #f5f7fb;
  color: var(--main-text);
`;

export const Input = styled.input`
  border: none;
  width: 42px;
  margin: 0 8px;
  text-align: center;
`;

export const PlusBtn = styled(Btn)`
  background: var(--main-green);
  color: var(--main-white);
`;

export const Box = styled.div`
  display: flex;
`;
