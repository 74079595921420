import React, { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  ButtonWrapper,
  MobileSearch,
  DropdownsWrapper,
  LeftSide,
  FilterButton,
  Heading,
  Total
} from './new-orders-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import { columnsData, columnsRows } from './mock';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import filter from '../../../../assets/filter.svg';
import {
  BottomTotal,
  ChangeStatus,
  Content,
  HorizontalLine,
  SecondDropdownWrapper,
  SecondSection,
  Section,
  SidebarSubtitle,
  SidebarTitle,
  Social,
  TextInfo,
  TotalAmount
} from '../all-orders/all-order-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalInputPhone } from '../../../../components/global-input-phone/global-input-phone';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import {
  SecondSidebarColumnsData,
  SecondSidebarColumnsRows,
  SidebarColumnsData,
  SidebarColumnsRows
} from '../all-orders/mock';
import { ButtonsWrapper } from '../create-new-order/create-new-order-styled';
import Sidebar from '../../../../components/sidebar/sidebar';
import { ReactComponent as PlusIcon } from '../../../dashboard/pages/create-new-order/icons/plus.svg';
import { ReactComponent as SaveIcon } from '../../../dashboard/pages/create-new-order/icons/save.svg';
import { ReactComponent as XSquareIcon } from '../../../dashboard/pages/create-new-order/icons/x-square.svg';
import whatsappIcon from '../../../../assets/whatsapp.svg';
import phoneIcon from '../../../../assets/phone_white.svg';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Orders' },
  { link: '#', name: 'New Orders' }
];

const DropdownItems = ['Status', 'Payment Method', 'Delivery Method'];
const orderStatusOptions = [
  { value: 'Option 1', label: 'Option 1' },
  { value: 'Option 2', label: 'Option 2' },
  { value: 'Option 3', label: 'Option 3' }
];

const CustomerList = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showOrderSidebar, setShowOrderSidebar] = useState(false);
  const [customerBillingDetails, setCustomerBillingDetails] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [cityTown, setCityTown] = useState('');
  const [country, setCountry] = useState('');
  const [secondStreetAddress, setSecondStreetAddress] = useState('');
  const [secondCityTown, setSecondCityTown] = useState('');
  const [secondCountry, setSecondCountry] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Heading>
          <Title>New Orders</Title>
          <Total>135</Total>
        </Heading>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
          </LeftSide>
          <ButtonWrapper onClick={() => setShowSidebar(true)}>
            <GlobalButton fullWidth height={40} type={'secondary'}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                  stroke='#388638'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <p>Create Order</p>
            </GlobalButton>
          </ButtonWrapper>
        </Navigation>
        <Table columnsData={columnsData} columnsRows={columnsRows} hasCheckbox />
        <Sidebar
          title={showSidebar ? 'Create New Order' : 'Order #12520'}
          width={showSidebar ? '1100px' : '900px'}
          showSidebar={showSidebar || showOrderSidebar}
          onClose={() => {
            setShowSidebar(false);
            setShowOrderSidebar(false);
          }}
          onCloseText={'Back to Newsletter'}
        >
          {showSidebar ? (
            <Content>
              <Section>
                <SidebarTitle>Customer Billing Details</SidebarTitle>
                <InputGroup label={'name'}>
                  <GlobalInput
                    placeholder={'Enter the name'}
                    value={customerBillingDetails}
                    onChange={(e) => setCustomerBillingDetails(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'email'}>
                  <GlobalInput
                    placeholder={'Enter your email'}
                    value={customerBillingDetails}
                    onChange={(e) => setCustomerBillingDetails(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'WhatsApp Number'}>
                  <GlobalInputPhone placeholder={'Your WhatsApp Number'} />
                </InputGroup>
                <SidebarSubtitle>Physical Address</SidebarSubtitle>
                <InputGroup label={'street address'}>
                  <GlobalInput
                    placeholder={'Enter your street address'}
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'city / town'}>
                  <GlobalInput
                    placeholder={'Enter your city or town'}
                    value={cityTown}
                    onChange={(e) => setCityTown(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'country'}>
                  <GlobalInput
                    placeholder={'Enter your country'}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </InputGroup>
              </Section>
              <Section>
                <SidebarTitle>Order Details</SidebarTitle>
                <InputGroup label={'order status'}>
                  <GlobalSelect options={orderStatusOptions} />
                </InputGroup>
                <InputGroup label={'payment method'}>
                  <GlobalSelect options={orderStatusOptions} />
                </InputGroup>
                <InputGroup label={'delivery method'}>
                  <GlobalSelect options={orderStatusOptions} />
                </InputGroup>
                <SidebarSubtitle>Delivery Address</SidebarSubtitle>
                <InputGroup label={'street address'}>
                  <GlobalInput
                    placeholder={'Enter your street address'}
                    value={secondStreetAddress}
                    onChange={(e) => setSecondStreetAddress(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'city / town'}>
                  <GlobalInput
                    placeholder={'Enter your city or town'}
                    value={secondCityTown}
                    onChange={(e) => setSecondCityTown(e.target.value)}
                  />
                </InputGroup>
                <InputGroup label={'country'}>
                  <GlobalInput
                    placeholder={'Enter your country'}
                    value={secondCountry}
                    onChange={(e) => setSecondCountry(e.target.value)}
                  />
                </InputGroup>
              </Section>
              <Section>
                <SidebarTitle>Order Items</SidebarTitle>
                <InputGroup label={'payment method'}>
                  <GlobalSelect options={orderStatusOptions} />
                </InputGroup>
                <Table
                  columnsData={SidebarColumnsData}
                  columnsRows={SidebarColumnsRows}
                  minWidth={'min-content'}
                />
              </Section>
              <ButtonsWrapper>
                <GlobalButton
                  type='primary'
                  onClick={() => {
                    setShowSidebar(false);
                    setShowOrderSidebar(true);
                  }}
                >
                  <PlusIcon />
                  Create Order
                </GlobalButton>
                <div>
                  <GlobalButton type='orange-secondary' onClick={() => setShowSidebar(false)}>
                    <SaveIcon />
                    Save Draft
                  </GlobalButton>
                  <GlobalButton type='gray' onClick={() => setShowSidebar(false)}>
                    <XSquareIcon />
                    Cancel Order
                  </GlobalButton>
                </div>
              </ButtonsWrapper>
            </Content>
          ) : (
            ''
          )}
          {showOrderSidebar ? (
            <Content>
              <SecondSection>
                <SidebarTitle>Customer Details</SidebarTitle>
                <TextInfo>
                  <p>Name</p>
                  <h5>Langton Showira</h5>
                </TextInfo>
                <TextInfo>
                  <p>Phone Number</p>
                  <h5>+380 66 12 23 123</h5>
                </TextInfo>
                <TextInfo>
                  <p>Email</p>
                  <h5>langtonshoriwa@gmail.com</h5>
                </TextInfo>
                <Social>
                  <div className='whatsapp'>
                    <img src={whatsappIcon} alt='' />
                  </div>
                  <div className='phone'>
                    <img src={phoneIcon} alt='' />
                  </div>
                </Social>
                <HorizontalLine />
                <SidebarTitle>Billing Details</SidebarTitle>
                <TextInfo>
                  <p>Name</p>
                  <h5>Langton Showira</h5>
                </TextInfo>
                <TextInfo>
                  <p>Phone Number</p>
                  <h5>+380 66 12 23 123</h5>
                </TextInfo>
                <TextInfo>
                  <p>Email</p>
                  <h5>langtonshoriwa@gmail.com</h5>
                </TextInfo>
                <TextInfo>
                  <p>Address</p>
                  <h5>6674 21 Street, Glen View 8, Harare Zimbabwe</h5>
                </TextInfo>
                <HorizontalLine />
                <SidebarTitle>Order Details</SidebarTitle>
                <TextInfo>
                  <p>Order Number</p>
                  <h5>#12520</h5>
                </TextInfo>
                <TextInfo>
                  <p>Amount</p>
                  <h5>$1250</h5>
                </TextInfo>
                <TextInfo>
                  <p>Payment Method</p>
                  <h5>Mukuru</h5>
                </TextInfo>
                <TextInfo>
                  <p>Delivery Address</p>
                  <h5>6674 21 Street, Glen View 8, Harare Zimbabwe</h5>
                </TextInfo>
                <HorizontalLine />
                <SidebarTitle>Order Status</SidebarTitle>
                <TextInfo>
                  <p>Order Status</p>
                  <SecondDropdownWrapper>
                    <Dropdown name={'Paid'} withBorders />
                    <ChangeStatus>Change Status</ChangeStatus>
                  </SecondDropdownWrapper>
                </TextInfo>
                <HorizontalLine />
                <SidebarTitle>Ordered Products</SidebarTitle>
                <Table
                  columnsData={SecondSidebarColumnsData}
                  columnsRows={SecondSidebarColumnsRows}
                  minWidth={'640px'}
                />
                <BottomTotal>
                  <TotalAmount>Total Amount</TotalAmount>
                  <SidebarTitle>$1250</SidebarTitle>
                </BottomTotal>
              </SecondSection>
            </Content>
          ) : (
            ''
          )}
        </Sidebar>
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default CustomerList;
