import styled from 'styled-components';
import { cardTitleBig } from '../../assets/style-helpers/typography';

export const SponsoredBannerCardStyled = styled.div`
  display: flex;

  & + & {
    margin-top: 16px;
  }
`;

export const SponsoredBannerCardLogo = styled.div`
  width: 102px;
  height: 102px;
  margin-right: 16px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SponsoredBannerCardContent = styled.div`
  max-width: 210px;
`;

export const SponsoredBannerCardTitle = styled.div`
  ${cardTitleBig};
`;

export const SponsoredBannerCardSubtitle = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-subtext);
  margin-bottom: 2px;
`;

export const SponsoredBannerCardText = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
