import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../assets/style-helpers/style-helpers';
import { AdSpace } from '../../components/ad-space/ad-space';
import { title20 } from '../../assets/style-helpers/typography';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    height: 100%;
    min-height: auto;
  }
  @media ${device.mobile} {
    margin-bottom: 0;
  }
`;

export const Map = styled.div`
  display: flex;
  flex-grow: 1;
  background: #5a5670;
  height: 740px;
`;

export const TopContainer = styled.div`
  padding: 0 103px;
  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

export const FinderWidgetMainWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const StoreFinderFilter = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const Wrapper = styled.div`
  //display: flex;
  //flex-wrap: wrap;
  width: 100%;
`;

const MobileBtn = styled.button`
  ${buttonReset};
  display: none;
  color: var(--main-white);
  background: var(--main-green);
  border-radius: 6px;
  height: 40px;
  position: absolute;
  svg {
    margin-right: 12px;
  }
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    width: 100%;
  }
`;

export const MobileFilterBtn = styled(MobileBtn)`
  max-width: 130px;
  top: 16px;
  left: 15px;
`;

export const MobileFinderBtn = styled(MobileFilterBtn)`
  color: #f76d21;
  background: #fff1e9;
  max-width: 140px;
  left: 160px;
`;

export const MobileResultsBtn = styled(MobileBtn)`
  max-width: 320px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 72px;
  position: fixed;
`;
export const MapWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const MapInner = styled.div`
  position: sticky;
  top: 150px;
  left: 0;
`;
export const Ad = styled.div`
  margin-top: 80px;
  padding: 32px;
  border-radius: 12px;
  background: #f4f4f4;
  max-width: 966px;
`;

export const AdTitle = styled.div`
  ${title20};
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
`;

export const AdContent = styled.div`
  display: flex;
  gap: 40px;
`;

export const AdCards = styled.div``;

export const AdSp = styled(AdSpace)`
  height: 230px;
  flex-grow: 1;
`;

export const C = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const K = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
`;

export const HideMapBtn = styled.button`
  ${buttonReset};
  border-radius: 6px;
  background: #fff;
  padding: 8px 20px;
  color: var(--main-green);
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  gap: 6px;
  position: absolute;
  top: 8px;
  left: 20px;
  z-index: 100;
  @media ${device.tablet} {
    display: none;
  }
`;
