import AvatarIco from '../../../../assets/icons/avatar.png';
import AvatarIco2 from '../../../../assets/icons/avatar2.png';
import Delivery from '../../../../assets/icons/delivery-ico.svg';
import LocationIco from '../../../../assets/icons/location.svg';
import Price from '../../../../assets/icons/price.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { ProductGallery } from '../../../../components/product-gallery/product-gallery';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import Navigations from '../navigations/navigations';
import {
  Avatar,
  Block,
  Box,
  Buttons,
  Data,
  Description,
  GalleryBox,
  Ico,
  Information,
  Item,
  Items,
  MainStyled,
  Photo,
  Title
} from './main-styled';

const items = [
  {
    name: 'Harare',
    ico: LocationIco
  },
  {
    name: 'Delivery',
    ico: Delivery
  },
  {
    name: '$2000 - $5000',
    ico: Price
  }
];

const items2 = [
  {
    name: 'Harare',
    ico: LocationIco
  },
  {
    name: '$200- $500',
    ico: Price
  }
];

const Main = ({ showBlock }) => {
  return (
    <MainStyled>
      <Navigations showBlock={showBlock} />
      <Box>
        <Information>
          <Avatar>
            <Photo src={AvatarIco} alt='Langton Shoriwa' />
            <Block>
              <Title>Langton Shoriwa</Title>
              <Data>25 Oct 2022 at 12:03</Data>
            </Block>
          </Avatar>
          <Description>
            I`m looking for this camera. I`m looking for this camera. I`m looking for this camera.
          </Description>
          <Items>
            {items.map((item, i) => {
              return (
                <Item key={i}>
                  <Ico src={item.ico} alt='' />
                  {item.name}
                </Item>
              );
            })}
          </Items>
        </Information>
        <GalleryBox>
          <ProductGallery />
          <Buttons>
            <GlobalButton height={40} type={'green'}>
              <SvgIcon name={'message-text-square'} />
              Message
            </GlobalButton>
            <GlobalButton height={40} type={'orange-secondary'}>
              <SvgIcon name={'comment-ico'} />
              Message
            </GlobalButton>
          </Buttons>
        </GalleryBox>
      </Box>
      <Box>
        <Information>
          <Avatar>
            <Photo src={AvatarIco2} alt='Langton Shoriwa' />
            <Block>
              <Title>Langton Shoriwa</Title>
              <Data>25 Oct 2022 at 12:03</Data>
            </Block>
          </Avatar>
          <Description>
            I`m looking for this camera. I`m looking for this camera. I`m looking for this camera.{' '}
          </Description>
          <Items>
            {items2.map((item, i) => {
              return (
                <Item key={i}>
                  <Ico src={item.ico} alt='' />
                  {item.name}
                </Item>
              );
            })}
          </Items>
        </Information>
        <Buttons>
          <GlobalButton height={40} type={'green'}>
            <SvgIcon name={'message-text-square'} />
            Message
          </GlobalButton>
          <GlobalButton height={40} type={'orange-secondary'}>
            <SvgIcon name={'comment-ico'} />
            Message
          </GlobalButton>
        </Buttons>
      </Box>
    </MainStyled>
  );
};

export default Main;
