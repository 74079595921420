import { GlobalButton } from '../../../../../../components/global-button/global-button';
import { GlobalCheckbox } from '../../../../../../components/global-checkbox/global-checkbox';
import { GlobalInput } from '../../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../../components/global-select/global-select';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { Tag } from '../../../../components/products/tag/tag';
import {
  AttributesInputGroupRow,
  CheckboxWrapper,
  ColorDotGreen,
  ColorDotRed,
  ColorDotYellow,
  FeaturesRow,
  InputGroupWrapper,
  Section,
  SectionTitle,
  TagsSection
} from '../../add-new-product-form-styled.jsx';

export const ProductAttributes = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Product Attributes</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'Brand'}>
          <AttributesInputGroupRow>
            <GlobalSelect options={selectOptions} />
            <CheckboxWrapper>
              <GlobalCheckbox>Has Variations</GlobalCheckbox>
            </CheckboxWrapper>
          </AttributesInputGroupRow>
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Colours'}>
          <AttributesInputGroupRow>
            <GlobalSelect options={selectOptions} />
            <CheckboxWrapper>
              <GlobalCheckbox>Has Variations</GlobalCheckbox>
            </CheckboxWrapper>
          </AttributesInputGroupRow>
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <TagsSection>
          <Tag>
            <ColorDotGreen />
            Green
          </Tag>
          <Tag>
            <ColorDotRed />
            Red
          </Tag>
          <Tag>
            <ColorDotYellow />
            Yellow
          </Tag>
        </TagsSection>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <FeaturesRow>
          <InputGroup label={'Length'}>
            <GlobalInput placeholder={'Enter the length'} />
          </InputGroup>
          <InputGroup label={'unit'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
          <div>
            <button>
              <SvgIcon name={'plus'} />
              Add Feature
            </button>
          </div>
        </FeaturesRow>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <TagsSection>
          <Tag>
            <ColorDotGreen />
            1m
          </Tag>
          <Tag>
            <ColorDotRed />
            5m
          </Tag>
          <Tag>
            <ColorDotYellow />
            1m
          </Tag>
        </TagsSection>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'plus'} /> Add New Attributes
        </GlobalButton>
      </InputGroupWrapper>
    </Section>
  );
};
