import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const FiltersStyled = styled.section`
  min-width: 238px;
  margin-right: 62px;

  // @media ${device.mobile} {
  //   display: none;
  // }
  // @media ${device.tablet} {
  //   display: none;
  // }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;

  @media ${device.mobile} {
    width: unset;
    font-size: 14px;
    padding: 11px 26px;
    margin-bottom: unset;

    img {
      margin-right: 11px;
    }
  }
`;

export const CategorySelection = styled.div`
  margin-bottom: 62px;
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  img {
    transform: rotate(180deg);
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #29263a;
    width: 221px;
    span {
      font-family: 'Lato';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--main-subtext);
      margin-left: 5px;
    }
  }

  img {
    cursor: pointer;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 30px 0;
`;

export const PriceSelection = styled.div`
  margin-bottom: 19px;
`;

export const DropdownItem = styled.div`
  margin-bottom: 28px;
`;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;
