// import { Container, Inner } from './buyer-requests-list-styled';
import Header from '../header/header';
import { BreadcrumbsContainer } from '../../pages/categories-real-estate/categories-real-estate-styled';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { GlobalFilter } from '../global-filter/global-filter';
import React, { useState } from 'react';
import { MainStyled } from '../../pages/categories-motor-vehicles/components/main/main-styled';
import { Container } from '../../pages/categories-motor-vehicles/categories-motor-vehicles-styled';
import {
  Inner,
  Content,
  Adv,
  AdSpaceWtapper,
  Title,
  Filters,
  SearchWrapper,
  CardsWrapper
} from './buyer-requests-list-styled';
import { SponsoredBanners } from '../../pages/single-product-general/components/sponsored-banners/sponsored-banners';
import InputWithLabel from '../input-with-label/input-with-label';
import { SelectWithLabel } from '../select-with-label/select-with-label';
import { PaginationWrapper } from '../../pages/categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import Pagination from '../pagination/pagination';
import { RequestCard } from './components/request-card/request-card';

import img from './img.jpg';
import { LoadMore } from '../load-more/load-more';

const breadcrumbs = [
  {
    link: '#',
    name: 'Home'
  },
  {
    link: '#',
    name: 'List of Buyer Requests'
  }
];

const cards = [
  {
    title: 'I’m looking for 10 Navy Blue Puffe...',
    price: '$200-500',
    priceZWL: 'ZWL 200000-500000',
    location: 'Langton Shoriwa | Harare',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare.',
    tags: ['Phones', '1m'],
    img: img
  },
  {
    title: 'I’m looking for 10 Navy Blue Puffe...',
    price: '$200-500',
    priceZWL: 'ZWL 200000-500000',
    location: 'Langton Shoriwa | Harare',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare.',
    tags: ['Phones', '1m'],
    img: img
  },
  {
    title: 'I’m looking for 10 Navy Blue Puffe...',
    price: '$200-500',
    priceZWL: 'ZWL 200000-500000',
    location: 'Langton Shoriwa | Harare',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare.',
    tags: ['Phones', '1m'],
    img: img
  },
  {
    title: 'I’m looking for 10 Navy Blue Puffe...',
    price: '$200-500',
    priceZWL: 'ZWL 200000-500000',
    location: 'Langton Shoriwa | Harare',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare.',
    tags: ['Phones', '1m'],
    img: img
  },
  {
    title: 'I’m looking for 10 Navy Blue Puffe...',
    price: '$200-500',
    priceZWL: 'ZWL 200000-500000',
    location: 'Langton Shoriwa | Harare',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare.',
    tags: ['Phones', '1m'],
    img: img
  }
];

export const BuyerRequestsList = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <MainStyled>
          <Inner>
            <Content>
              <Title>Buyer Requests</Title>
              <Filters>
                <SearchWrapper>
                  <InputWithLabel />
                </SearchWrapper>
                <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
              </Filters>
              <CardsWrapper>
                {cards.map((item, i) => (
                  <RequestCard data={item} key={i} />
                ))}
              </CardsWrapper>
              <PaginationWrapper>
                <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
              </PaginationWrapper>
            </Content>
            <Adv>
              <SponsoredBanners />
              <AdSpaceWtapper />
            </Adv>
          </Inner>
        </MainStyled>
      </Container>
    </>
  );
};
