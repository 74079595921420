import Header from '../../components/header/header';
import { AdvBanner, Container, Divider, Title } from './deals-of-the-day-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { CentresList } from '../../components/shopping-centres-info/components/centres-list';
import { SectionWithSlider } from '../home/components/section-with-slider/section-with-slider';
const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '/', name: 'Deals of the day' }
];
const shopsMock = [
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' },
  { name: 'Category' }
];
export const DealsOfTheDay = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>Deals of the day</Title>
        <CentresList isAllVisible={false} items={shopsMock} />
        <Divider />
      </Container>
      <SectionWithSlider sectionTitle={'Food and groceries'} />
      <SectionWithSlider sectionTitle={'Real Estate'} />
      <SectionWithSlider sectionTitle={'Trades & Services'} />
      <SectionWithSlider sectionTitle={'Motor Vehicles'} />
      <AdvBanner />
    </>
  );
};
