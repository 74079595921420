import Header from '../../components/header/header';
import { AuthPagesContainer } from '../../components/auth-pages-container/auth-pages-container';
import { GlobalInput } from '../../components/global-input/global-input';
import { InputGroup } from '../../components/input-group/input-group';
import { Description, ResetPasswordForm, SendBtn } from './reset-password-styled';
import { SvgIcon } from '../../components/svg-icon/svg-icon';

export const ResetPassword = () => {
  return (
    <>
      <Header />
      <AuthPagesContainer title={'Reset password'}>
        <ResetPasswordForm>
          <Description>
            Please enter your email address. You will receive a link to create a new password via
            email.
          </Description>
          <InputGroup label={'email'}>
            <GlobalInput placeholder={'Your email address'} />
          </InputGroup>
          <SendBtn height={52} fullWidth>
            <SvgIcon name={'mail'} />
            Send
          </SendBtn>
        </ResetPasswordForm>
      </AuthPagesContainer>
    </>
  );
};
