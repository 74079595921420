import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  margin-bottom: 16px;

  a {
    font-family: 'Poppins', sans-serif;
    color: var(--main-green);
    &:hover {
      color: var(--main-green);
    }
  }
`;

export const CardsWrapper = styled.div``;

export const Card = styled.div`
  & + & {
    margin-top: 12px;
  }
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--main-gray-2);
`;

export const ImgWrapper = styled.div`
  flex-basis: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CardMain = styled.div`
  padding: 16px 16px 16px 12px;
`;

export const CartTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
`;

export const CardBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Rate = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 700;
  svg {
    margin-right: 6px;
  }
  span {
    font-weight: 400;
    margin-left: 6px;
  }
`;

export const Price = styled.div`
  font-size: 16px;
`;
