import styled from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const SearchButton = styled.button`
  ${buttonReset};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  svg {
    margin-right: 8px;
  }
`;
