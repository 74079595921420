import styled, { css } from 'styled-components';
import { LatoFont, mainContainer } from '../../assets/style-helpers/style-helpers';
import { title20, title30 } from 'assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const CheckoutStyled = styled.div``;

export const Container = styled.div`
  ${mainContainer};
`;

export const Title = styled.h1`
  ${title30};
  margin-bottom: 24px;
  margin-top: 0;
  @media ${device.tablet} {
    font-size: 24px;
    margin-bottom: 0;
    &.mobile-hidden {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 106px;
  @media ${device.laptop} {
    align-items: flex-start;
  }
  @media ${device.tablet} {
    margin-bottom: 28px;
  }
`;

export const Col = styled.div`
  flex-basis: 508px;

  @media ${device.laptop} {
    &:first-child,
    &:nth-child(2) {
      flex-basis: calc(100% - 508px - 32px);
    }
    &:nth-child(2) {
      order: 3;
      margin-top: 32px;
    }
  }
  @media ${device.tablet} {
    flex-basis: 100%;
    &:first-child,
    &:nth-child(2) {
      flex-basis: 100%;
      margin-top: 0;
    }
    ${(props) =>
      props.isVisible
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }
`;

export const Card = styled.div`
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 32px;

  @media ${device.mobile} {
    padding: 0;
    box-shadow: none;
  }

  & + & {
    margin-left: 32px;
  }
`;

export const CardTitle = styled.h3`
  display: flex;
  align-items: center;
  ${title20};
  margin: 0 0 24px;
  span {
    background: #e7f0e7;
    border-radius: 50%;
    color: var(--main-green);
    height: 24px;
    width: 24px;
    display: flex;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const InputGroupWrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const InputGroupWrapperTwoRows = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

export const CardSection = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const CardSectionTitle = styled.div`
  margin-bottom: 16px;
`;

export const CardSectionText = styled.p`
  ${LatoFont};
  margin-bottom: 12px;
`;

export const RadioWrapper = styled.div`
  margin-bottom: 24px;
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 20px;
`;

export const MobileTitle = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 40px;
    @media ${device.mobile} {
      margin-left: 0;
    }
  }
  ${(props) =>
    props.isActive
      ? css`
          span {
            color: var(--main-white);
            background: var(--main-green);
          }
        `
      : css`
          span {
            color: var(--main-green);
            background: #e7f0e7;
          }
        `}
  span {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }
  @media ${device.mobile} {
    span {
      margin-right: 0;
      flex-shrink: 0;
    }
    &:nth-child(2) {
      flex-grow: 1;
      &::before,
      &::after {
        content: '';
        margin: 0 6px;
        background: var(--divider);
        height: 1px;
        flex-grow: 1;
        width: 100%;
      }
    }
    div {
      display: none;
    }
  }
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobile} {
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }
`;

export const MobileBtns = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
  }
`;

const StepBtn = styled.div`
  display: none;

  @media ${device.tablet} {
    ${(props) =>
      props.isVisible
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
  }
`;

export const StepOneBtn = styled(StepBtn)``;

export const StepTwoBtn = styled(StepBtn)`
  button + button {
    margin-left: 16px;
  }

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column-reverse;
    button {
      width: 100%;
    }

    button + button {
      margin-bottom: 16px;
      margin-left: 0px;
    }
  }
`;
