import { SectionHeader } from 'components/section-header/section-header';

import {
  Arrows,
  LatestVideosStyled,
  SliderWrapper,
  PrevSlideBtn,
  NextSlideBtn
} from './latest-videos-styled';
import blogImg from 'assets/temp-img/blog-img.png';
import { BlogCard } from '../blog-card/blog-card';
import { SvgIcon } from 'components/svg-icon/svg-icon';

import Slider from 'react-slick';
import React, { useEffect, useRef, useState } from 'react';

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  className: 'products-slick',
  dotsClass: 'products-dots',
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3.5
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const videoBlogs = [
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: true
  }
];

export const LatestVideos = () => {
  const slider = useRef(null);
  const wrapperRef = useRef(null);
  const [wrapperPosition, setWrapperPosition] = useState(0);

  useEffect(() => {
    setWrapperPosition(wrapperRef.current.getBoundingClientRect().left);
  }, [wrapperRef.current]);
  return (
    <LatestVideosStyled>
      <SectionHeader link={'videos'} title='Latest Videos' />
      <SliderWrapper ref={wrapperRef} style={{ width: `calc(100vw - ${wrapperPosition}px)` }}>
        <Slider {...sliderSettings} ref={(r) => (slider.current = r)}>
          {videoBlogs.map((blog, i) => (
            <BlogCard blog={blog} key={i} />
          ))}
        </Slider>
      </SliderWrapper>

      <Arrows>
        <PrevSlideBtn onClick={() => slider.current.slickPrev()}>
          <SvgIcon name={'slider-arrow'} />
        </PrevSlideBtn>
        <NextSlideBtn onClick={() => slider.current.slickNext()}>
          <SvgIcon name={'slider-arrow'} />
        </NextSlideBtn>
      </Arrows>
    </LatestVideosStyled>
  );
};
