import styled from 'styled-components';

export const LabelBlock = styled.label`
  margin-bottom: 18px;
  display: block;
`;

export const Name = styled.span`
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: #707070;
  line-height: 140%;
  margin-bottom: 10px;
  display: block;
`;
