import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const HelpAndTutorialsWrapper = styled.div`
  position: fixed;
  top: 133.98px;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(31, 49, 36, 0.4);
  z-index: 10000;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const Container = styled.div`
  position: absolute;
  // padding: ${(props) => (props.withoutHeading ? '0 160px 0 32px' : '40px 160px 0 32px')};
  padding: 40px 160px 0 32px;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9999;
  // width: ${(props) => (props.width ? props.width : '830px')};
  width: 565px;
  height: calc(100% - 133.98px);
  right: 0;

  @media ${device.tablet} {
    width: 100%;
    padding: 22px 20px 38px 22px;
  }
  @media ${device.mobile} {
    width: 100%;
    padding: 22px 15px 30px 15px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: var(--main-text);
  margin-bottom: 24px;
`;

export const VideosSection = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 70px;
`;

export const Video = styled.div`
  position: relative;
  max-width: 373px;
  cursor: pointer;
  margin-bottom: 14px;
`;

export const VideoImage = styled.img``;

export const PlayButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
`;

export const VideoWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Text = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

export const VideoTextCss = css`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: var(--main-text);
`;

export const Name = styled.p`
  ${VideoTextCss};
`;

export const Duration = styled.p`
  ${VideoTextCss};
`;

export const SidebarButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  position: absolute;
  left: -95px;
  top: 240px;
  background: #388638;
  border-radius: 6px 6px 0 0;
  transform: rotate(-90deg);
  color: var(--main-white);
  cursor: pointer;
`;
