import { SvgIcon } from '../svg-icon/svg-icon';
import { SectionLinkStyled } from './section-link-styled';

export const SectionLink = ({ link, isMobile = false }) => {
  return (
    <SectionLinkStyled to={link} isMobile={isMobile}>
      <SvgIcon name={'eye'} />
      <span>See all</span>
    </SectionLinkStyled>
  );
};
