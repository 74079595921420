import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const RecentUpdatesStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const InputWrapper = styled.div`
  flex-basis: 560px;
  .no-padding {
    padding-left: 16px;
  }
`;

export const SortWrapper = styled.div`
  flex-basis: 240px;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px 36px;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
