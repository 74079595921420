import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const Main = styled.div``;
