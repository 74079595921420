import styled from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';
import { title30 } from '../../../../../../assets/style-helpers/typography';

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  ${title30};
`;

export const CalendarWrapper = styled.div`
  img {
    @media ${device.laptop} {
      width: 100%;
    }
  }
`;
