import { ReactComponent as Eye } from '../../../../assets/eye-table-icon.svg';
import { TagsWrapper, Tag } from './new-orders-styled';
import { CheckboxWrapper, CheckboxWrapperHeader, StyledId } from '../all-orders/all-order-styled';
import Checkbox from '../../../../components/checkbox/checkbox';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Total',
    accessor: 'total'
  },
  {
    Header: 'Products',
    accessor: 'products'
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod'
  },
  {
    Header: 'Delivery Method',
    accessor: 'deliveryMethod'
  },
  {
    Header: 'Actions',
    accessor: 'eye'
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    customer: 'Langton Shoriwa',
    date: '07 Jul 2022',
    status: 'Paid',
    total: '$300 000 000',
    products: (
      <TagsWrapper>
        {Tags.map((data, index) => {
          return <Tag key={index}>{data}</Tag>;
        })}
      </TagsWrapper>
    ),
    paymentMethod: 'Cash On Delivery',
    deliveryMethod: 'Everything Zimbabwean',
    eye: <Eye />
  }
];
