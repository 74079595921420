import { StyledTextarea } from './global-textarea-styled';

export const GlobalTextarea = ({ value, onChange, placeholder, type, name }) => {
  return (
    <StyledTextarea
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      type={type}
      name={name}
    />
  );
};
