import { Container, Title, SectionLinkWrapper, RightSection } from './section-header-styled';
import { SectionLink } from '../section-link/section-link';
import { GlobalButton } from '../global-button/global-button';
import Countdown from 'react-countdown';

export const SectionHeader = ({
  title,
  link,
  hasClockCount,
  seeAllMobile = false,
  fontSize = 'lg',
  ...props
}) => {
  const { className } = props;
  return (
    <Container className={className} seeAllMobile={seeAllMobile}>
      <Title fontSize={fontSize} seeAllMobile={seeAllMobile}>
        {title}
      </Title>
      <RightSection seeAllMobile={seeAllMobile}>
        {hasClockCount ? (
          <GlobalButton type={'orange'} height={'40px'}>
            <svg
              width='20'
              height='19'
              viewBox='0 0 20 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19 9.02778L17.1731 10.9167L15.3452 9.02778M17.3966 10.4444C17.4297 10.1344 17.4467 9.81923 17.4467 9.5C17.4467 4.80558 13.765 1 9.22337 1C4.68173 1 1 4.80558 1 9.5C1 14.1944 4.68173 18 9.22337 18C11.8067 18 14.1117 16.7688 15.6193 14.843M9.22337 4.77778V9.5L11.9645 11.3889'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Ends in: &nbsp;
            <Countdown autoStart daysInHours date={Date.now() + 8.64e7} />
          </GlobalButton>
        ) : (
          ''
        )}
        {link && (
          <SectionLinkWrapper seeAllMobile={seeAllMobile}>
            <SectionLink link={link} />
          </SectionLinkWrapper>
        )}
      </RightSection>
    </Container>
  );
};
