import { useState } from 'react';
import Header from '../../../../components/header/header';
import {
  Container,
  DashboardBtnWrapper,
  Main,
  SidebarWrapper
} from '../../pages/account-settings/account-settings-styled';
import { Sidebar } from '../sidebars/sidebar-menu/menu';
import NotificationSidebar from '../../../dashboard/components/notifications-sidebar/notifications-sidebar';
import { HelpAndTutorialButton, NotificationIcon } from '../../../dashboard/dashboard-styled';
import notificationSidebarIcon from '../../../dashboard/components/notifications-sidebar/notification-sidebar-icon.svg';
import HelpAndTutorialsSidebar from '../../../dashboard/components/help-and-tutorials-sidebar/help-and-tutorials-sidebar';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { Outlet } from 'react-router';

export const AccountsWrapper = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false);
  const [showHelpAndTutorialSidebar, setShowHelpAndTutorialSidebar] = useState(false);
  const showSidebar = () => {
    setIsSidebarVisible(true);
    document.querySelector('body').style.overflow = 'hidden';
  };
  const hideSidebar = () => {
    setIsSidebarVisible(false);
    document.querySelector('body').style.overflow = 'auto';
  };
  return (
    <>
      <Header />
      <Container>
        <SidebarWrapper isSidebarVisible={isSidebarVisible}>
          <Sidebar
            onCloseBtnClick={() => {
              hideSidebar();
            }}
          />
        </SidebarWrapper>
        <NotificationSidebar
          showSidebar={showNotificationSidebar}
          setShowSidebar={setShowNotificationSidebar}
        />
        <NotificationIcon
          src={notificationSidebarIcon}
          showNotificationSidebar={showNotificationSidebar}
          showHelpAndTutorialSidebar={showHelpAndTutorialSidebar}
          onClick={() => setShowNotificationSidebar(true)}
          alt=''
        />
        <HelpAndTutorialsSidebar
          showSidebar={showHelpAndTutorialSidebar}
          setShowSidebar={setShowHelpAndTutorialSidebar}
        />
        <HelpAndTutorialButton
          onClick={() => setShowHelpAndTutorialSidebar(true)}
          showHelpAndTutorialSidebar={showHelpAndTutorialSidebar}
          showNotificationSidebar={showNotificationSidebar}
        >
          Help & Tutorials
        </HelpAndTutorialButton>
        <Main>
          <DashboardBtnWrapper>
            <GlobalButton
              type={'orange-secondary'}
              onClick={() => {
                showSidebar();
              }}
            >
              <BurgerIcon />
              Dashboard Menu
            </GlobalButton>
          </DashboardBtnWrapper>
          <Outlet />
        </Main>
      </Container>
    </>
  );
};
