import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const TableWrapper = styled.div`
  @media ${device.tablet} {
    overflow-x: scroll;

    margin-right: ${(props) => (props.hasMarginRight ? '-20px' : '0')};
  }
  @media ${device.mobile} {
    margin-right: -15px;
  }

  td {
    ${(props) =>
      props.hasMenu &&
      css`
        > div {
          //margin: 0 auto;
          width: max-content;
          padding: 6px 11.25px;
          border-radius: 4px;
          display: flex;
          align-items: center;

          &:hover {
            background: #f7f7f7;

            svg {
              filter: invert(40%) sepia(79%) saturate(372%) hue-rotate(70deg) brightness(93%)
                contrast(91%);
            }
          }
        }
      `}
  }

  thead {
    th {
      ${(props) =>
        props.hasCheckbox &&
        css`
          :first-child {
            display: flex;
            align-items: center;
          }
        `}
    }
  }
`;

export const Td = styled.td`
  > div {
    ${(props) =>
      props.isActive &&
      css`
        background: #f7f7f7;

        svg {
          filter: invert(40%) sepia(79%) saturate(372%) hue-rotate(70deg) brightness(93%)
            contrast(91%);
        }
      `}
  }
`;

export const Styles = styled.div`
  table {
    width: 100%;
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : '1118px')};
    border-spacing: 0;
    border: 1px solid var(--divider);
    border-radius: 12px 12px 10px 10px;
    border-collapse: separate;
    //overflow: hidden;

    thead {
      background: #f7f7f7;
      border: 1px solid #dcdcdc;
      border-radius: 12px 12px 0 0;
      border-collapse: separate;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: var(--main-subtext);

      th {
        :last-child {
          pointer-events: none;
        }

        span {
          margin-left: 4px;
        }
      }
    }

    tbody {
      tr {
        position: relative;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: var(--main-text);

        td {
          :last-child {
            cursor: pointer;
          }
        }
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid var(--divider);
      text-align: left;

      :first-child {
        padding: 25px 0 25px 22px;
      }

      :last-child {
        border-right: 0;
        text-align: center;
        //padding-right: 22px;
      }
    }
  }
`;

export const TableNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
  @media ${device.tablet} {
    margin-top: 28px;
  }
  @media ${device.mobile} {
    flex-direction: column;
    max-width: unset;
    margin-right: 68px;
    margin: 24px auto 0 auto;
  }
  ${(props) =>
    !props.usePagination &&
    css`
      display: none;
    `}
`;

export const ShowAll = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-green);
  text-decoration: underline;
  cursor: pointer;
  @media ${device.mobile} {
    display: flex;
    order: 1;
    margin-top: 12px;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: 52px;
  right: 26px;
  padding: 10px 14px;
  background-color: var(--main-white);
  box-shadow: 0 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 4px;
  z-index: 10;
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-text);
  }
`;

export const Remove = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #e54d45;
  }
`;

export const Arrows = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
  .active {
    path {
      stroke: #4f4f4f;
    }
  }
`;

export const HeadDiv = styled.div`
  display: flex;
  align-items: center;
`;
