import React from 'react';
import './store-locations.scss';

import {
  HorizontalLine,
  Map,
  MapMobile,
  StoreLocationsStyled,
  Title
} from './store-locations-styled';

import map from '../../../../assets/map_big.png';
import mapMobile from '../../../../assets/store-locations-map-mobile.png';

const StoreLocations = () => {
  return (
    <StoreLocationsStyled>
      <Title>Store Locations</Title>
      <HorizontalLine />
      <Map src={map} alt='' />
      <MapMobile src={mapMobile} alt='' />
    </StoreLocationsStyled>
  );
};

export default StoreLocations;
