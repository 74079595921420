import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const BannerSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Logo = styled.img`
  position: relative;
  object-fit: cover;
  @media ${device.laptop} {
    width: 100%;
    max-height: 500px;
  }

  @media ${device.mobile} {
    width: 100%;
    height: 600px;
  }
`;

export const Text = styled.div`
  position: absolute;
  left: 152px;
  right: 59.51%;
  top: 76px;

  @media ${device.mobile} {
    left: 27px;
    right: 59.51%;
    top: 190px;
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }
`;

export const LineOne = styled.p`
  font-size: 58px;
  line-height: 70px;
  white-space: nowrap;
  color: #ffcc3e;
  @media ${device.mobile} {
    font-size: 34px;
  }
`;

export const LineTwo = styled.p`
  font-size: 50px;
  line-height: 24px;
  white-space: nowrap;
  color: #ffffff;
  margin-left: 14px;

  @media ${device.mobile} {
    font-size: 34px;
  }
`;

export const LineThree = styled.p`
  font-size: 58px;
  line-height: 70px;
  color: #ffffff;

  @media ${device.mobile} {
    font-size: 34px;
    white-space: nowrap;
  }
`;

export const Emoji = styled.span`
  font-size: 40px;
  letter-spacing: 2px;
  margin-right: 12px;

  @media ${device.mobile} {
    font-size: 26px;
    margin-right: 4px;
  }
`;

export const Mark = styled.img`
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  letter-spacing: 2px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const MarkMobile = styled.img`
  display: none;
  position: absolute;
  left: 0;
  top: 60px;
  letter-spacing: 2px;

  @media ${device.mobile} {
    display: block;
  }
`;

export const Fashion = styled.span`
  position: relative;
  margin-left: 25px;
  z-index: 1000;

  @media ${device.mobile} {
    margin-left: 1px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 156px;
  top: 329px;
  background: var(--main-orange);
  border-radius: 6px;
  padding: 20px 26px 20px 21px;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  img {
    margin-right: 12px;
    filter: brightness(100);
  }

  @media ${device.mobile} {
    left: 27px;
    top: 390px;
  }
`;
