import Select from 'react-select';
import { Container, Label } from './select-with-label-styled';

const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 0px', marginRight: '10px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#29263A'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

const selectCustomStyle = (isVar2) => {
  return {
    container: (provided) => ({
      ...provided,
      fontFamily: 'Lato, sans-serif',
      maxWidth: '100%',
      paddingLeft: isVar2 ? '135px' : '112px'
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? 'var(--main-green)' : 'white',
      cursor: 'pointer'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      border: 'none',
      boxShadow: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    })
  };
};

export const SelectWithLabel = ({ label, isVar2, ...props }) => {
  return (
    <Container>
      <Label isVar2={isVar2}>{label}</Label>
      <Select
        {...props}
        components={{ DropdownIndicator: customDropdownIndicator }}
        styles={selectCustomStyle(isVar2)}
      />
    </Container>
  );
};
