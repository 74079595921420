import { GlobalInput } from '../../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../../components/global-select/global-select';
import { GlobalTextarea } from '../../../../../../components/global-textarea/global-textarea';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import { InputGroupWrapper, Section, SectionTitle } from '../../add-new-product-form-styled.jsx';

export const ProductDetails = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Product Details</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'product name'}>
          <GlobalInput placeholder={'Write the name of the product'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'product description'}>
          <GlobalTextarea placeholder={'Describe your product'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'product condition'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
    </Section>
  );
};
