import styled from 'styled-components';
import { buttonReset, linkReset } from 'assets/style-helpers/style-helpers';
import { cardTitleSmall } from 'assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${linkReset};
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid var(--main-gray-2);
`;

export const ImgWrapper = styled.div`
  height: 140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Label = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--main-orange);
  border-radius: 6px 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  padding: 0 7px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelNotAvailable = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #888888;
  border-radius: 6px 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  padding: 0 12px;
  color: #ffffff;
  @media ${device.tablet} {
    padding: 8px 6px;
    font-size: 10px;
    line-height: 100%;
  }
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 100%;
    padding: 8px 6px;
  }
`;

export const Title = styled.h3`
  ${cardTitleSmall};
  color: var(--main-text);
  font-weight: 600;
  margin: 0;
  height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 3px;
`;

export const RateStarsDesktop = styled.div`
  display: block;
  margin-left: -5px;

  svg {
    width: 10px;
    height: 10px;
  }

  div {
    font-size: 11px;
    margin-left: 3px;
    margin-right: 0px;
  }
`;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 7px;
  @media ${device.tablet} {
    margin-left: auto;
  }

  a {
    color: var(--main-green);
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  a + a {
    margin-left: 0px;
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const MainPrice = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const OldPrice = styled.div`
  text-decoration: line-through;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--main-subtext);
`;

export const Main = styled.div`
  padding: 8px 12px 16px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FavButton = styled.button`
  ${buttonReset};
  border-radius: 50%;
  width: 34px;
  height: 34px;
  border: 1px solid var(--main-gray-2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  svg {
    width: 14px;
  }
`;

export const FavBtn4 = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 12px;
  border: 1px solid var(--main-gray);
  background: var(--main-white);

  svg {
    width: 17px;
    path {
      fill: ${(props) => (props.isFav ? 'black' : 'none')};
      stroke: ${(props) => (props.isFav ? 'black' : 'black')};
    }
  }

  @media ${device.laptop} {
    margin-left: 5px;
  }
`;

export const BuyButton = styled.button`
  ${buttonReset};
  border-radius: 50%;
  background: var(--main-green);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  color: var(--main-white);

  svg {
    width: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const Description = styled.div`
  color: var(--main-gray);
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  margin-top: 4px;
  line-height: 20px;
`;
