export const Call = () => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.1223 4.75065C11.8956 4.90152 12.6062 5.27969 13.1633 5.83677C13.7204 6.39385 14.0985 7.10449 14.2494 7.87773M11.1223 1.58398C12.7288 1.76245 14.2269 2.48187 15.3706 3.62412C16.5143 4.76636 17.2356 6.26353 17.4161 7.86982M8.09576 10.9756C7.14452 10.0243 6.39339 8.94874 5.84239 7.80112C5.795 7.70241 5.7713 7.65306 5.7531 7.5906C5.6884 7.36865 5.73487 7.09612 5.86946 6.90815C5.90734 6.85526 5.95258 6.81001 6.04308 6.71951C6.31985 6.44274 6.45824 6.30435 6.54871 6.1652C6.88992 5.64041 6.88992 4.96386 6.54872 4.43908C6.45824 4.29992 6.31985 4.16153 6.04308 3.88476L5.88881 3.73049C5.46808 3.30976 5.25772 3.0994 5.0318 2.98513C4.58248 2.75787 4.05185 2.75787 3.60253 2.98513C3.3766 3.0994 3.16624 3.30976 2.74552 3.73049L2.62072 3.85528C2.20144 4.27457 1.99179 4.48421 1.83168 4.76924C1.65402 5.08551 1.52627 5.57673 1.52735 5.93949C1.52832 6.26641 1.59174 6.48984 1.71857 6.93669C2.40017 9.33813 3.68621 11.6042 5.5767 13.4946C7.46718 15.3851 9.73322 16.6712 12.1347 17.3528C12.5815 17.4796 12.8049 17.543 13.1319 17.544C13.4946 17.5451 13.9858 17.4173 14.3021 17.2397C14.5871 17.0796 14.7968 16.8699 15.2161 16.4506L15.3409 16.3258C15.7616 15.9051 15.9719 15.6947 16.0862 15.4688C16.3135 15.0195 16.3135 14.4889 16.0862 14.0396C15.9719 13.8136 15.7616 13.6033 15.3409 13.1825L15.1866 13.0283C14.9098 12.7515 14.7714 12.6131 14.6323 12.5226C14.1075 12.1814 13.4309 12.1814 12.9061 12.5226C12.767 12.6131 12.6286 12.7515 12.3518 13.0283C12.2613 13.1188 12.2161 13.164 12.1632 13.2019C11.9752 13.3365 11.7027 13.3829 11.4807 13.3183C11.4183 13.3 11.3689 13.2763 11.2702 13.229C10.1226 12.678 9.04701 11.9268 8.09576 10.9756Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
