import {
  Container,
  Title,
  Price,
  Features,
  Description,
  CurrentPlan,
  ButtonWrapper
} from './subscription-plan-styled';
import { GlobalButton } from 'components/global-button/global-button';

import { ReactComponent as CheckCircleIcon } from './check-circle-2.svg';
import { ReactComponent as ArrowCircleUp } from './arrow-circle-up.svg';

export const SubscriptionPlan = ({ subscriptionPlan }) => {
  const { isFree, isCurrent, isAccented, description, name, price, features } = subscriptionPlan;
  return (
    <Container isAccent={isAccented} isCurrent={isCurrent}>
      <Description>{description}</Description>
      <Title>{name}</Title>
      <div>
        <Price>
          {price} {!isFree && <span>/month</span>}
        </Price>
      </div>

      <Features>
        {features.map((item, i) => (
          <div key={i}>
            <span>{item.title}</span>
            {item.subtitle && <span>{item.subtitle}</span>}
          </div>
        ))}
      </Features>
      {isCurrent ? (
        <ButtonWrapper>
          <CurrentPlan>
            <CheckCircleIcon />
            Current Plan
          </CurrentPlan>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <GlobalButton type={'orange'} fullWidth>
            <ArrowCircleUp />
            Upgrade
          </GlobalButton>
        </ButtonWrapper>
      )}
    </Container>
  );
};
