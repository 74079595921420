import { useState } from 'react';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import Orders from '../../components/orders/orders';
import { CreateNewOrder } from '../../components/sidebars/sidebar-create-new-order/create-new-order';
import { Background, BoxSiteBar, SiteBar, Title } from './my-orders-styled.jsx';

const breadcrumbs = [
  { link: '#', name: 'Orders' },
  { link: '#', name: 'My Orders' }
];

export const MyOrders = () => {
  const [isSitebarOrder, setSitebarOrder] = useState(false);

  const newOrder = (status) => {
    setSitebarOrder(status);
    document.querySelector('body').style.overflow = 'hidden';
    scrollTo(0, 0);
  };

  const closeOrderForm = (status) => {
    setSitebarOrder(status);
    document.querySelector('body').style.overflow = 'auto';
  };

  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>My Orders</Title>
      <Orders newOrder={newOrder} />
      {isSitebarOrder && (
        <BoxSiteBar>
          <SiteBar>
            <CreateNewOrder closeOrderForm={closeOrderForm} />
          </SiteBar>
        </BoxSiteBar>
      )}
      {isSitebarOrder && <Background></Background>}
    </>
  );
};
