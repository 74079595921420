import {
  Block,
  ButtonInfo,
  MobileOpenPreviwBtnWrapper,
  SubmitWrapper
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { PromotePopup } from '../promote-popup/promote-popup';
import { useState } from 'react';
import { AdditionalInformationPopup } from '../additional-information-popup/additional-information-popup';

export const ProductSubmitBtns = ({ setIsSidebarHidden, isSidebarHidden }) => {
  const [isPromotePopupVisible, setIsPromotePopupVisible] = useState(false);
  const [isAdditionalInfoPopupVisible, setIsAdditionalInfoPopupVisible] = useState(false);
  return (
    <SubmitWrapper>
      <PromotePopup
        isVisible={isPromotePopupVisible}
        onClose={() => {
          setIsPromotePopupVisible(false);
        }}
        onPayBtnClick={() => {
          setIsPromotePopupVisible(false);
        }}
      />
      <AdditionalInformationPopup
        isVisible={isAdditionalInfoPopupVisible}
        onClose={() => setIsAdditionalInfoPopupVisible(false)}
        onContinueBtnClick={() => {
          setIsAdditionalInfoPopupVisible(false);
          setIsPromotePopupVisible(true);
        }}
      />
      <Block>
        <GlobalButton
          type={'primary'}
          onClick={() => {
            setIsAdditionalInfoPopupVisible(true);
          }}
        >
          <SvgIcon name={'plus'} />
          Add details
        </GlobalButton>
        <ButtonInfo>Add Payment \ Delivery \ Warranty details </ButtonInfo>
      </Block>
      <Block>
        <GlobalButton type={'transparent'}>Save Draft</GlobalButton>
        <MobileOpenPreviwBtnWrapper>
          <GlobalButton
            type={'secondary'}
            onClick={() => {
              setIsSidebarHidden(!isSidebarHidden);
            }}
          >
            <SvgIcon name={'eye'} />
            Product Preview
          </GlobalButton>
        </MobileOpenPreviwBtnWrapper>
      </Block>
    </SubmitWrapper>
  );
};
