import { ReactComponent as Eye } from '../../../../assets/eye-table-icon.svg';
import {
  TagsWrapper,
  Tag,
  StyledId,
  CheckboxWrapper,
  CheckboxWrapperHeader
} from './all-customers-styled';
import Checkbox from '../../../../components/checkbox/checkbox';

export const Tags = ['Mbinga Dzacho', 'Premium'];

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Customer Name',
    accessor: 'customerName'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Customer Since',
    accessor: 'customerSince'
  },
  {
    Header: 'Customer Lists',
    accessor: 'customerLists'
  },
  {
    Header: 'Orders',
    accessor: 'orders'
  },
  {
    Header: 'Total Spent',
    accessor: 'totalSpent'
  },
  {
    Header: 'Actions',
    accessor: 'eye'
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    customerName: 'Langton Shoriwa',
    contact: '+263 718 418 254',
    location: 'Harare, ZW',
    customerSince: '07 Jul 2022',
    customerLists: (
      <TagsWrapper>
        {Tags.map((data, index) => {
          return <Tag key={index}>{data}</Tag>;
        })}
      </TagsWrapper>
    ),
    orders: '5500',
    totalSpent: '$120 000 000.99',
    eye: <Eye />
  }
];

export const SidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Order status',
    accessor: 'orderStatus'
  },
  {
    Header: 'Ordered Items',
    accessor: 'orderedItems'
  },
  {
    Header: 'Value',
    accessor: 'value'
  }
];

export const SidebarColumnsRows = [
  {
    id: 12520,
    date: '07 Jul 2022',
    orderStatus: 'Completed',
    orderedItems: '100',
    value: '$200 000 000'
  },
  {
    id: 12520,
    date: '07 Jul 2022',
    orderStatus: 'Completed',
    orderedItems: '100',
    value: '$200 000 000'
  },
  {
    id: 12520,
    date: '07 Jul 2022',
    orderStatus: 'Completed',
    orderedItems: '100',
    value: '$200 000 000'
  }
];
