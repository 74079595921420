import {
  BlogCardContainer,
  BtnWrapper,
  Date,
  Description,
  ImgWrapper,
  Main,
  Tag,
  Tags,
  Title,
  VideoTimer
} from './blog-card-styled';

const PlayBtn = () => (
  <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='21' cy='21' r='21' fill='white' />
    <path
      d='M15.7891 13.782C15.7891 12.8513 15.7891 12.386 15.9831 12.1295C16.1522 11.906 16.4106 11.7677 16.6903 11.751C17.0114 11.7318 17.3985 11.99 18.1729 12.5062L28.2503 19.2245C28.8902 19.6511 29.2101 19.8644 29.3216 20.1332C29.4191 20.3682 29.4191 20.6324 29.3216 20.8674C29.2101 21.1362 28.8902 21.3495 28.2503 21.7761L18.1729 28.4944C17.3985 29.0106 17.0114 29.2688 16.6903 29.2496C16.4106 29.2329 16.1522 29.0946 15.9831 28.8711C15.7891 28.6146 15.7891 28.1493 15.7891 27.2186V13.782Z'
      stroke='#29263A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const BlogCard = ({ blog }) => {
  return (
    <BlogCardContainer>
      <ImgWrapper>
        <img src={blog.img} alt={blog.title} />
        {blog.withVideo && (
          <>
            <BtnWrapper>
              <PlayBtn />
            </BtnWrapper>
            <VideoTimer>01:35</VideoTimer>
          </>
        )}
      </ImgWrapper>
      <Main>
        <Tags>
          {blog.tags.map((tag, i) => {
            return <Tag key={i}>{tag}</Tag>;
          })}
        </Tags>
        <Title>{blog.title}</Title>
        <Description>{blog.description}</Description>
        <Date>{blog.date}</Date>
      </Main>
    </BlogCardContainer>
  );
};
