import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 12px;
  border-bottom: 1px solid var(--main-gray-2);

  & + & {
    margin-top: 12px;
  }
`;

export const ImgWrapper = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  font-weight: 600;
`;

export const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 10px;
  color: var(--main-subtext);
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoItem = styled.div`
  font-size: 12px;
  line-height: 1;
  color: #676a75;
  font-family: 'Lato', sans-serif;
  & + & {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid var(--main-subtext);
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;

export const Price = styled.div`
  font-weight: 600;
`;
