import styled from 'styled-components';
import { AdSpace } from '../ad-space/ad-space';
import { title30 } from '../../assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const Inner = styled.div`
  display: flex;
  gap: 48px;
  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  flex-basis: calc(100% - 328px - 48px);
`;

export const Adv = styled.div`
  flex-basis: 328px;
  @media ${device.laptop} {
    display: flex;
    gap: 50px;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const AdSpaceWtapper = styled(AdSpace)`
  height: 300px;
  margin-top: 48px;
  @media ${device.laptop} {
    margin-top: 0;
    width: 330px;
  }
`;

export const Title = styled.div`
  ${title30};
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 12px;
  margin-bottom: 30px;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const SearchWrapper = styled.div`
  flex-basis: 500px;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
