import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { ButtonWrapper, Container, InputWrapper, Main, Tags, Top } from './stores-sections-styled';
import { Tag } from '../tag/tag';
import { StoreCard } from '../../../../components/store-card/store-card';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { MobileFilterBtn } from '../../../store-front-trades-services/components/section/section-styled';
import { MobSearch } from '../../../../components/mob-search/mob-search';

const tags = ['Building & Construction'];

export const StoresSection = ({ cards, onFilterBtnClick }) => {
  return (
    <Container>
      <Top>
        <MobileFilterBtn onClick={onFilterBtnClick}>
          <GlobalButton height={40}>
            <SvgIcon name={'filter'} />
            Filters
          </GlobalButton>
        </MobileFilterBtn>
        <InputWrapper>
          <InputWithLabel
            placeholder={'Enter what store you are looking for...'}
            height={40}
            name={'Search in shopping centre'}
          />
        </InputWrapper>
        <MobSearch />
        <Tags>
          {tags.map((item, i) => (
            <Tag label={item} key={i} />
          ))}
          {tags.length > 0 && <Tag label={'Сlear all'} onClick={() => {}} isButton />}
        </Tags>
      </Top>
      <Main>
        {cards.map((item, i) => (
          <StoreCard store={item} key={i} isListView={false} isAddressHidden={true} />
        ))}
      </Main>
      <ButtonWrapper>
        <GlobalButton type={'orange-secondary'} height={40}>
          <SvgIcon name={'eye'} />
          See more shops
        </GlobalButton>
      </ButtonWrapper>
    </Container>
  );
};
