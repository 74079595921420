import styled from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const ArrowStyled = styled.div``;

export const TippyBox = styled.div`
  padding: 12px;
  background: var(--main-white);
  border-radius: 6px;
  color: var(--main-subtext);
  font-size: 12px;
  line-height: 17px;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 4px 60px rgba(70, 91, 131, 0.1);

  &[data-placement='bottom-start'] {
    margin-top: 0px;
    margin-left: -10px;
    & > ${ArrowStyled} {
      top: -10px;
      left: 0;
    }
  }
`;

export const TippyBtn = styled.button`
  ${buttonReset};
  display: flex;
  align-items: center;
`;
