import { SponsoredCard } from '../../../../components/sponsored-card/sponsored-card';
import { Container, Title } from './sponsored-adv-styled';

export const SponsoredAdv = () => {
  return (
    <Container>
      <Title>Sponsored</Title>
      <SponsoredCard />
      <SponsoredCard />
    </Container>
  );
};
