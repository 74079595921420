import newIcon from './icons/new.svg';
import approveIcon from './icons/approve.svg';
import declineIcon from './icons/decline.svg';
import clock from './icons/clock.svg';
import {
  ButtonGreen,
  ButtonRed,
  Buttons,
  Container,
  IconApprove,
  IconDecline,
  IconNew,
  Main,
  Msg,
  PName,
  Time
} from './offer-styled';

export const Offer = ({ offer }) => {
  const { type, name, productName, price, time } = offer;
  switch (type) {
    case 'approve':
      return (
        <Container>
          <IconApprove>
            <img src={approveIcon} alt='approve' />
          </IconApprove>
          <Main>
            <Msg>
              <span>{name}</span> - your offer was approved:
            </Msg>
            <PName>{productName}</PName>
            <Time>
              <img src={clock} alt='clock' />
              <span>{time}</span>
            </Time>
          </Main>
        </Container>
      );
    case 'decline':
      return (
        <Container>
          <IconDecline>
            <img src={declineIcon} alt='decline' />
          </IconDecline>
          <Main>
            <Msg>
              <span>{name}</span> - your offer was declined:
            </Msg>
            <PName>{productName}</PName>
            <Time>
              <img src={clock} alt='clock' />
              <span>{time}</span>
            </Time>
          </Main>
        </Container>
      );
    default:
      return (
        <Container>
          <IconNew>
            <img src={newIcon} alt='approve' />
          </IconNew>
          <Main>
            <Msg>
              You`ve got an offer from{' '}
              <span>
                {name} : {price}
              </span>
            </Msg>
            <PName>{productName}</PName>
            <Time>
              <img src={clock} alt='clock' />
              <span>{time}</span>
            </Time>
            <Buttons>
              <ButtonGreen>accept</ButtonGreen>
              <ButtonRed>Decline</ButtonRed>
            </Buttons>
          </Main>
        </Container>
      );
  }
};
