export const Track = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.0833 6.06479L9.99997 9.99998M9.99997 9.99998L2.91664 6.06479M9.99997 9.99998L10 17.9167M17.5 10V6.61788C17.5 6.33234 17.5 6.18957 17.4579 6.06224C17.4207 5.94959 17.3599 5.84619 17.2795 5.75895C17.1886 5.66033 17.0638 5.591 16.8142 5.45233L10.6475 2.02641C10.4112 1.89511 10.293 1.82946 10.1679 1.80372C10.0571 1.78094 9.94288 1.78094 9.83213 1.80372C9.70698 1.82946 9.58881 1.89511 9.35248 2.02641L3.18581 5.45233C2.93621 5.591 2.8114 5.66034 2.72053 5.75895C2.64013 5.84619 2.57929 5.94959 2.54207 6.06224C2.5 6.18957 2.5 6.33234 2.5 6.61788V13.3821C2.5 13.6677 2.5 13.8104 2.54207 13.9378C2.57929 14.0504 2.64013 14.1538 2.72053 14.2411C2.8114 14.3397 2.93621 14.409 3.18581 14.5477L9.35248 17.9736C9.58881 18.1049 9.70698 18.1705 9.83213 18.1963C9.94288 18.2191 10.0571 18.2191 10.1679 18.1963C10.293 18.1705 10.4112 18.1049 10.6475 17.9736L10.8333 17.8704M6.25 3.75L13.75 7.91667M18.3333 17.9167L17.5 17.0833M18.3333 15C18.3333 16.3807 17.214 17.5 15.8333 17.5C14.4526 17.5 13.3333 16.3807 13.3333 15C13.3333 13.6193 14.4526 12.5 15.8333 12.5C17.214 12.5 18.3333 13.6193 18.3333 15Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
