import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const NotificationsWrapper = styled.div`
  position: fixed;
  top: 133.98px;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(31, 49, 36, 0.4);
  z-index: 10000;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const Container = styled.div`
  position: absolute;
  // padding: ${(props) => (props.withoutHeading ? '0 160px 0 32px' : '40px 160px 0 32px')};
  padding: 40px 160px 0 32px;
  background: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9999;
  // width: ${(props) => (props.width ? props.width : '830px')};
  width: 565px;
  height: calc(100% - 133.98px);
  right: 0;

  @media ${device.tablet} {
    width: 100%;
    padding: 22px 20px 38px 22px;
  }
  @media ${device.mobile} {
    width: 100%;
    padding: 22px 15px 30px 15px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: var(--main-text);
`;

export const HorizontalLine = styled.div`
  width: 372px;
  border-bottom: 1px solid var(--main-gray-2);
  margin: 12px 0 20px 0;
`;

export const Tags = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 24px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  background: ${(props) => (props.selected ? '#e7f0e7' : '#ffffff')};
  border: ${(props) => (props.selected ? '1px solid #388638' : '1px solid rgba(56, 134, 56, 0.3)')};
  border-radius: 20px;
  margin-right: 12px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
`;

export const NotificationSection = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 20px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  padding: 20px;
  border-radius: 50%;
  background: linear-gradient(
      166.43deg,
      rgba(255, 255, 255, 0.1) 9.72%,
      rgba(255, 255, 255, 0) 78.4%
    ),
    #e7f0e7;
  margin-right: 14px;
`;

export const Text = styled.p`
  display: inline-table;
  max-width: 290px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-text);
`;

export const NormalText = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-text);
`;

export const BoldText = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: var(--main-text);
`;

export const HoursAgo = styled.div`
  display: flex;
  align-content: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--main-subtext);
  img {
    margin-right: 7.17px;
  }
`;

export const SidebarButton = styled.img`
  position: absolute;
  left: -40px;
  cursor: pointer;
`;
