import styled from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const Content = styled.div`
  max-width: 100%;
  width: 520px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Box = styled.div`
  padding: 35px;
`;

export const Title = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #29263a;
  width: 309px;
  margin: 0px auto;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5a5670;
  text-align: center;
  width: 309px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 7px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 370px;
  margin: 0px auto;
  margin-top: 32px;

  button {
    width: 50%;
  }
`;

export const Delete = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  color: #ffffff;
  display: flex;
  background: #e54d45;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  margin-left: 12px;

  svg {
    margin-right: 7px;
  }
`;
