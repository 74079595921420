import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { AdSpace } from '../../components/ad-space/ad-space';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
`;

export const Title = styled.div`
  font-size: 58px;
  line-height: 1.2;
  margin-bottom: 24px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 30px;
  }
`;

export const AdvBanner = styled(AdSpace)`
  height: 160px;
  border-radius: 0;
  margin-bottom: 100px;
`;

export const Divider = styled.div`
  margin-bottom: 90px;
`;
