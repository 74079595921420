import {
  GridInputWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';

export const ProductPricingAuction = () => {
  return (
    <Section>
      <SectionTitle>Pricing</SectionTitle>
      <GridInputWrapper>
        <InputGroup label={'start bidding price'}>
          <GlobalInput placeholder={'$'} />
        </InputGroup>
        <InputGroup label={'reserve price'}>
          <GlobalInput placeholder={'$'} />
        </InputGroup>{' '}
        <InputGroup label={'buy it now price'}>
          <GlobalInput placeholder={'$'} />
        </InputGroup>
      </GridInputWrapper>
    </Section>
  );
};
