import { AuthPagesContainer } from '../../components/auth-pages-container/auth-pages-container';
import Header from '../../components/header/header';
import {
  CompareTable,
  Description,
  SubTitle,
  Title,
  MobileTable,
  Section
} from './compare-account-styled';

import { ReactComponent as CheckCircle } from './icons/check-circle.svg';
import { ReactComponent as XCircle } from './icons/x-circle.svg';
import { GlobalButton } from '../../components/global-button/global-button';
import { ReactComponent as CheckIcon } from './icons/check-square.svg';

export const CompareAccount = () => {
  return (
    <>
      <Header />
      <AuthPagesContainer title={'Compare Accounts'}>
        <CompareTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <SubTitle>Free</SubTitle>
                <Title>Individual</Title>
                <Description>
                  Lorem ipsum dolor sit amet consectetur. Consectetur ut habitant.
                </Description>
                <GlobalButton>
                  <CheckIcon />
                  Get Started
                </GlobalButton>
              </th>
              <th>
                <SubTitle>90 Day Free Trial</SubTitle>
                <Title>Vendor</Title>
                <Description>
                  Lorem ipsum dolor sit amet consectetur. Consectetur ut habitant.
                </Description>
                <GlobalButton>
                  <CheckIcon />
                  Get Started
                </GlobalButton>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Buying</td>
              <td>
                <CheckCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Dashboard</td>
              <td>
                <CheckCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Wishlist</td>
              <td>
                <CheckCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Training</td>
              <td>
                <XCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>24/7 Support</td>
              <td>
                <XCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Social Media</td>
              <td>
                <XCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Store Finder</td>
              <td>
                <XCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Digital Storfront</td>
              <td>
                <XCircle />
              </td>
              <td>
                <CheckCircle />
              </td>
            </tr>
            <tr>
              <td>Featured Products</td>
              <td>
                <XCircle />
              </td>
              <td>Extra Fee</td>
            </tr>
            <tr>
              <td>Deals Of The Day</td>
              <td>
                <XCircle />
              </td>
              <td>Extra Fee</td>
            </tr>
            <tr>
              <td>Products</td>
              <td>90 Day Free Trial</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Auctions</td>
              <td>90 Day Free Trial</td>
              <td>90 Day Free Trial</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <GlobalButton>
                  <CheckIcon />
                  Get Started
                </GlobalButton>
              </td>
              <td>
                <GlobalButton>
                  <CheckIcon />
                  Get Started
                </GlobalButton>
              </td>
            </tr>
          </tbody>
        </CompareTable>
        <MobileTable>
          <Section>
            <SubTitle>Free</SubTitle>
            <Title>Individual</Title>
            <Description>
              Lorem ipsum dolor sit amet consectetur. Consectetur ut habitant.
            </Description>
            <GlobalButton>
              <CheckIcon />
              Get Started
            </GlobalButton>
            <table>
              <tr>
                <td>Buying</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Dashboard</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Wishlist</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Training</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>24/7 Support</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Social Media</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Store Finder</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Digital Storfront</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Featured Products</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Deals Of The Day</td>
                <td>
                  <XCircle />
                </td>
              </tr>
              <tr>
                <td>Products</td>
                <td>90 Day Free Trial</td>
              </tr>
              <tr>
                <td>Auctions</td>
                <td>90 Day Free Trial</td>
              </tr>
            </table>
            <GlobalButton>
              <CheckIcon />
              Get Started
            </GlobalButton>
          </Section>
          <Section>
            <SubTitle>Free</SubTitle>
            <Title>Individual</Title>
            <Description>
              Lorem ipsum dolor sit amet consectetur. Consectetur ut habitant.
            </Description>
            <GlobalButton>
              <CheckIcon />
              Get Started
            </GlobalButton>
            <table>
              <tr>
                <td>Buying</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Dashboard</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Wishlist</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Training</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>24/7 Support</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Social Media</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Store Finder</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Digital Storfront</td>
                <td>
                  <CheckCircle />
                </td>
              </tr>
              <tr>
                <td>Featured Products</td>
                <td>Extra Fee</td>
              </tr>
              <tr>
                <td>Deals Of The Day</td>
                <td>Extra Fee</td>
              </tr>
              <tr>
                <td>Products</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Auctions</td>
                <td>90 Day Free Trial</td>
              </tr>
            </table>
            <GlobalButton>
              <CheckIcon />
              Get Started
            </GlobalButton>
          </Section>
        </MobileTable>
      </AuthPagesContainer>
    </>
  );
};
