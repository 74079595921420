import {
  Box,
  Data,
  Information,
  InformationName,
  NameBlock,
  Status,
  Time,
  TitleTracking,
  TrackingLineBlock
} from './tracking-info-styled';
import { Ico } from './ico';

export const TrackingInfo = () => {
  const tracking = [
    {
      data: '17 Jul 2022',
      time: '08:15',
      statusName: 'Arrival Scan',
      status: 'Harare Warehouse',
      isActive: true,
      done: false
    },
    {
      data: '18 Jul 2022',
      time: '08:15',
      statusName: 'Departure',
      status: 'Harare Warehouse',
      isActive: true,
      done: false
    },
    {
      data: '19 Jul 2022',
      time: '08:15',
      statusName: 'Arrival',
      status: 'Bulawayo CBD',
      isActive: true,
      done: false
    },
    {
      data: '20 Jul 2022',
      time: '08:15',
      statusName: 'Out for delivery',
      status: 'Bulawayo CBD',
      isActive: true,
      done: true
    },
    {
      data: '21 Jul 2022',
      time: '08:15',
      statusName: 'Delivered',
      status: 'No. 17 Cowdray Park, Bulawayo',
      isActive: false,
      done: false
    }
  ];

  return (
    <>
      <TrackingLineBlock>
        <TitleTracking>Tracking Info</TitleTracking>
        {tracking.map((track, i) => {
          return (
            <Box key={i}>
              <Data>
                {i === tracking.length - 1 ? <NameBlock>ETA:</NameBlock> : ''}
                {track.data}
                <Time>{track.time}</Time>
              </Data>
              <Status>
                <Ico
                  status={track.status}
                  isActive={track.isActive}
                  done={track.done}
                  isLast={i === tracking.length - 1}
                />
              </Status>
              <Information>
                {track.statusName}
                <InformationName>{track.status}</InformationName>
              </Information>
            </Box>
          );
        })}
      </TrackingLineBlock>
    </>
  );
};
