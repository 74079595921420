import {
  Container,
  Form,
  InfoText,
  Input,
  LatestBid,
  LatestBidTitle,
  LatestBidValue,
  Timer,
  TimerBox,
  TimerDivider,
  TimerName,
  TimerTitle,
  TimerUntil,
  TimerValue,
  TimerWrapper
} from './auction-widget-styled';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { useState } from 'react';

export const AuctionWidget = () => {
  const [highestBid, setHighestBid] = useState(200);
  const [currentBid, setCurrentBid] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isBidHighest, setIsBidHighest] = useState(false);
  return (
    <Container>
      <LatestBid>
        <LatestBidTitle>Latest Bid</LatestBidTitle>
        <LatestBidValue>${highestBid}</LatestBidValue>
      </LatestBid>
      <Timer>
        <TimerTitle>Auction Ends In</TimerTitle>
        <TimerWrapper>
          <TimerBox>
            <TimerValue>06</TimerValue>
            <TimerName>Days</TimerName>
          </TimerBox>
          <TimerDivider>:</TimerDivider>
          <TimerBox>
            <TimerValue>04</TimerValue>
            <TimerName>Hours</TimerName>
          </TimerBox>
          <TimerDivider>:</TimerDivider>
          <TimerBox>
            <TimerValue>10</TimerValue>
            <TimerName>Minutes</TimerName>
          </TimerBox>
          <TimerDivider>:</TimerDivider>
          <TimerBox>
            <TimerValue>55</TimerValue>
            <TimerName>Seconds</TimerName>
          </TimerBox>
        </TimerWrapper>
        <TimerUntil>Until 25.12.2022</TimerUntil>
      </Timer>
      {isBidHighest && (
        <InfoText>
          <span className={'green'}>The Highest bid!</span> Your current bid:{' '}
          <span className={'orange'}>{highestBid}$</span>{' '}
        </InfoText>
      )}
      {!isBidHighest && currentBid && (
        <InfoText>
          Your current bid: <span className={'orange'}>{currentBid}$</span>{' '}
        </InfoText>
      )}

      <Form>
        <Input
          placeholder={'Enter your bid here'}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <GlobalButton
          height={40}
          type={isBidHighest ? 'secondary' : 'primary'}
          fullWidth
          onClick={() => {
            if (inputValue > highestBid) {
              setIsBidHighest(true);
              setHighestBid(+inputValue);
            } else {
              setIsBidHighest(false);
            }
            setCurrentBid(inputValue);
          }}
        >
          <SvgIcon name={'coins-hand'} />
          Place Bid
        </GlobalButton>
      </Form>
    </Container>
  );
};
