import {
  Container,
  LocationInput,
  SearchBtn,
  SearchBtn2,
  SearchInput,
  SelectWrapper
} from './store-finder-input-styled';
import searchIcon from 'assets/search.svg';
import { useState } from 'react';
import { SelectWithLabel } from '../../../select-with-label/select-with-label';
import {
  customDropdownIndicatorSmall,
  GlobalSelect,
  selectCustomStyleCategory,
  selectCustomStyleSmall
} from '../../../global-select/global-select';
import { ReactComponent as LocIcon } from '../../loc.svg';

const selectOptions = [
  { label: 'Category', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];
export const StoreFinderInput = ({ onSubmit }) => {
  const [searchData, setSearchData] = useState({
    location: '',
    searchQuery: ''
  });
  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(searchData);
      }}
    >
      <LocationInput>
        <span>Your location</span>
        <LocIcon />
      </LocationInput>
      <SearchInput
        placeholder='Search for anything'
        value={searchData.searchQuery}
        onChange={(e) => {
          setSearchData({ ...searchData, searchQuery: e.target.value });
        }}
      />
      <SelectWrapper>
        <GlobalSelect
          options={selectOptions}
          styles={selectCustomStyleCategory}
          dropdownIndicator={customDropdownIndicatorSmall}
        />
      </SelectWrapper>
      <SearchBtn2 type={'submit'}>
        <img src={searchIcon} alt='search icon' />
      </SearchBtn2>
    </Container>
  );
};
