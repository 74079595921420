import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { Subtitle } from '../add-new-product/add-new-product-styled';
import { SubscriptionPlan } from './components/subscription-plan/subscription-plan';
import { Container, PlansWrapper, Title } from './subscriptions-styled';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Manage Your Subscription' }
];

const plans = [
  {
    isFree: true,
    isCurrent: true,
    isAccented: false,
    description: 'Suitable For An Individual',
    name: 'Individual',
    price: 'Free',
    features: [
      {
        title: 'Sell Personal Products',
        subtitle: 'Maximum: 5'
      },
      {
        title: 'List Products On Auction',
        subtitle: 'Maximum: 3'
      }
    ]
  },
  {
    isFree: false,
    isCurrent: false,
    isAccented: false,
    description: 'Suitable For Small Businesses ',
    name: 'Basic',
    price: '30$',
    features: [
      {
        title: 'Sell Personal Products'
      },
      {
        title: 'List Products On Auction',
        subtitle: 'Maximum: 3'
      },
      {
        title: 'Store Front'
      },
      {
        title: 'Featured Products'
      }
    ]
  },
  {
    isFree: false,
    isCurrent: false,
    isAccented: true,
    description: 'Suitable For small businesses',
    name: 'SME',
    price: '50$',
    features: [
      {
        title: 'Sell Unlimited Products'
      },
      {
        title: 'List Unlimited Auction Products'
      },
      {
        title: 'Store Front'
      },
      {
        title: 'Featured Products',
        subtitle: 'Extra Fees Apply'
      },
      {
        title: '#DealsOfTheDay',
        subtitle: 'Extra Fees Apply'
      },
      {
        title: 'Training'
      },
      {
        title: 'Store Finder'
      },
      {
        title: '24/7 Support'
      }
    ]
  },
  {
    isFree: false,
    isCurrent: false,
    isAccented: false,
    description: 'Suitable For small businesses',
    name: 'Corporate',
    price: '150$',
    features: [
      {
        title: 'All in SME Package'
      },
      {
        title: 'Facebook Carousel'
      },
      {
        title: 'Google Ads'
      },
      {
        title: 'Dedicated Account Manager'
      },
      {
        title: 'WooCommerce Sync'
      }
    ]
  }
];

export const Subscriptions = () => {
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Manage Your Subscription</Title>
      <Subtitle>Select the package that suits your business needs</Subtitle>
      <PlansWrapper>
        {plans.map((plan, i) => (
          <SubscriptionPlan subscriptionPlan={plan} key={i} />
        ))}
      </PlansWrapper>
    </Container>
  );
};
