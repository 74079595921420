import React from 'react';

import { DropdownStyled, Name } from './dropdown-styled';

import arrowUp from '../../../../../assets/arrow_up.svg';

const Dropdown = ({ name, withBorders }) => {
  return (
    <DropdownStyled withBorders={withBorders}>
      <Name>{name || ''}</Name>
      <img src={arrowUp} alt='' />
    </DropdownStyled>
  );
};

export default Dropdown;
