import { TrackingInfo } from '../status/components/tracking-info/tracking-info';
import { TrackingLine } from '../status/components/tracking-line/tracking-line';

export const OrderTrackingStatus = () => {
  return (
    <>
      <TrackingLine />
      <TrackingInfo />
    </>
  );
};
