import { ModalWrapper } from '../modal-wrapper/modal-wrapper';
import { GlobalButton } from '../global-button/global-button';
import {
  Container,
  Title,
  Inner,
  InfoList,
  Info,
  Img,
  Textarea,
  InfoMain,
  InfoTitle,
  InfoListItem,
  Cat,
  Inputs,
  InputGrp,
  InpWrapper,
  InfoListMobile
} from './make-offer-popup-styled';
import { useState } from 'react';

export const MakeOfferPopup = ({ isVisible, onClose, onMakeOfferBtnClick }) => {
  const [offer, setOffer] = useState(250);
  return (
    <ModalWrapper isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title>Make offer</Title>
        <Inner>
          <Info>
            <Img>
              <img src='https://picsum.photos/500/500' alt='' />
            </Img>
            <InfoMain>
              <Cat>Properties & Associates</Cat>
              <InfoTitle>12 Roomed Home</InfoTitle>
              <InfoList>
                <InfoListItem>
                  Item Published On: <span>10/03/2022</span>
                </InfoListItem>
                <InfoListItem>
                  ID: <span>EZ124-585-22155-44</span>
                </InfoListItem>
              </InfoList>
            </InfoMain>
          </Info>
          <InfoListMobile>
            <InfoListItem>
              Item Published On: <span>10/03/2022</span>
            </InfoListItem>
            <InfoListItem>
              ID: <span>EZ124-585-22155-44</span>
            </InfoListItem>
          </InfoListMobile>
          <Inputs>
            <InputGrp>
              <label>current price</label>
              <InpWrapper>
                <input type='text' value={250} disabled />
              </InpWrapper>
            </InputGrp>
            <InputGrp>
              <label>Your offer</label>
              <InpWrapper>
                <input
                  type='text'
                  value={offer}
                  onChange={(e) => {
                    setOffer(e.target.value);
                  }}
                />
              </InpWrapper>
            </InputGrp>
          </Inputs>
          <InputGrp>
            <label htmlFor=''> Message (optional)</label>
            <Textarea placeholder={'Describe what are you looking for'} />
          </InputGrp>
        </Inner>
        <GlobalButton type={'orange'} fullWidth onClick={onMakeOfferBtnClick}>
          Make an offer
        </GlobalButton>
      </Container>
    </ModalWrapper>
  );
};
