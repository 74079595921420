import { Container, Subtitle, Title } from '../add-new-product/add-new-product-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as WordpressIcon } from './icons/wordpress.svg';
import { DashboardItemCard } from '../../components/dashboard-item-card/dashboard-item-card';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { UploadBtnWrapper, CardsWrapper } from './product-bulk-upload-styled';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Bulk Upload' }
];

const uploadCards = [
  {
    title: 'Import From File',
    subtitle: 'Use a CVS or XML File',
    icon: <DownloadIcon />
  },
  {
    title: 'Import From A WordPress Website',
    subtitle: 'WooCommerce API',
    icon: <WordpressIcon />
  }
];

export const ProductBulkUpload = () => {
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Product Bulk Upload</Title>
      <Subtitle>Please select the method you would like to use in uploading your products</Subtitle>
      <CardsWrapper>
        {uploadCards.map((item, i) => {
          return (
            <DashboardItemCard title={item.title} icon={item.icon} subtitle={item.subtitle} key={i}>
              <UploadBtnWrapper>
                <GlobalButton type='secondary' fullWidth>
                  <SvgIcon name='download' />
                  Upload
                </GlobalButton>
              </UploadBtnWrapper>
            </DashboardItemCard>
          );
        })}
      </CardsWrapper>
    </Container>
  );
};
