import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { BlogCardContainer } from '../blog-card/blog-card-styled';

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
    ${BlogCardContainer} {
      &:last-child {
        display: none;
      }
    }
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    ${BlogCardContainer} {
      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    ${BlogCardContainer} {
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;
