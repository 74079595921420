import styled from 'styled-components';

export const SidebarStyled = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--main-gray-2);
`;

export const SidebarContent = styled.div``;

export const SidebarTop = styled.div`
  color: var(--main-white);
  background: var(--main-green);
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
`;
