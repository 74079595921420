import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { Label } from '../label/label';
import { Form } from './form-tracking-styled';

export const FormTracking = () => {
  return (
    <Form action=''>
      <Label label='Order Number' placeholder='Order number here' nameField='order' />
      <Label
        label='Billing email / Phone number'
        placeholder='Billing Email / Phone Number here'
        nameField='billing'
      />
      <GlobalButton height={40} type={'green'}>
        <SvgIcon name={'track'} />
        Track Order
      </GlobalButton>
    </Form>
  );
};
