import temp1 from '../../../../assets/temp-img/temp1.png';
import temp2 from '../../../../assets/temp-img/temp2.png';
import temp3 from '../../../../assets/temp-img/temp3.png';
import { PCardSmall } from '../../../../components/p-card-small/p-card-small';
import {
  Container,
  ProductsBanner,
  ProductsBannerCards,
  ProductsBannerTitle,
  ProductsBannerTitleBadge,
  ProductsBannerTop,
  SponsoredBanner,
  SponsoredBannerAdSpace,
  SponsoredBannerContent,
  SponsoredBannersCards,
  SponsoredBannerTitle,
  ViewShopLink
} from './adv-section-styled';
import { SponsoredCard } from '../../../../components/sponsored-card/sponsored-card';

const products = [
  {
    img: temp1,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  },
  {
    img: temp2,
    title: 'Peach Slices / Del Monte, 980 g',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  },
  {
    img: temp3,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  }
];

export const AdvSection = () => {
  return (
    <Container>
      <ProductsBanner>
        <ProductsBannerTop>
          <ProductsBannerTitle>
            <ProductsBannerTitleBadge>Ad</ProductsBannerTitleBadge>
            <span>Samar Haute Plug</span>
          </ProductsBannerTitle>
          <ViewShopLink>Visit Shop</ViewShopLink>
        </ProductsBannerTop>
        <ProductsBannerCards>
          {products.map((item, index) => (
            <PCardSmall product={item} key={index} />
          ))}
        </ProductsBannerCards>
      </ProductsBanner>
      <SponsoredBanner>
        <SponsoredBannerTitle>Sponsored</SponsoredBannerTitle>
        <SponsoredBannerContent>
          <SponsoredBannersCards>
            <SponsoredCard />
            <SponsoredCard />
          </SponsoredBannersCards>
          <SponsoredBannerAdSpace>AD SPACE</SponsoredBannerAdSpace>
        </SponsoredBannerContent>
      </SponsoredBanner>
    </Container>
  );
};
