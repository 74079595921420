import React from 'react';

import { MotorVehiclesStyled, Controls } from './motor-vehicles-styled';

import CategoriesCard from '../../../../../components/categories-card/categories-card';

import {
  ButtonMobile,
  FilterButton
} from '../../../../company-page/components/products/products-styled';
import filter from '../../../../../assets/filter.svg';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import { CARS } from '../../../../../dummy-data/cars';
import {
  CardsSection,
  PaginationWrapper
} from '../../../../categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import { LoadMore } from '../../../../../components/load-more/load-more';
import { ResultsPerPage } from '../../../../../components/results-per-page/results-per-page';
import { Tags } from '../../../../../components/categories-top-filter/components/tags/tags';
import {
  NearMeBtnWrapper,
  NearMeDesktopText,
  NearMeMobileText
} from '../../../../../components/categories-top-filter/categories-top-filter-styled';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { ReactComponent as Pin } from '../../../../categories-real-estate/components/main/real-estate/pin.svg';
import { FilterByBuyType } from '../../../../../components/filter-by-buy-type/filter-by-buy-type';
import {
  ControlsMobile,
  TopSectionMobile
} from '../../../../categories-motor-vehicles-specific-search/components/main/motor-vehicles/motor-vehicles-styled';
import Pagination from '../../../../../components/pagination/pagination';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import { MobSort } from '../../../../../components/mob-sort/mob-sort';

export const TAGS = [
  {
    name: 'All results'
  },
  {
    name: 'Auctions'
  },
  {
    name: 'Buy now'
  }
];

export const CARDS = CARS;

const MotorVehicles = ({ onFilterBtnClick, currentViewType }) => {
  return (
    <MotorVehiclesStyled>
      <Controls>
        <Tags />
        <ResultsPerPage />
      </Controls>
      <ControlsMobile>
        <TopSectionMobile>
          <FilterButton onClick={onFilterBtnClick}>
            <img src={filter} alt='' />
            Filters
          </FilterButton>
          <NearMeBtnWrapper>
            <GlobalButton type={'orange-secondary'} onClick={() => {}}>
              <Pin />
              <NearMeDesktopText>Find products near you</NearMeDesktopText>
              <NearMeMobileText>Near you</NearMeMobileText>
            </GlobalButton>
          </NearMeBtnWrapper>
          <MobSort />
        </TopSectionMobile>

        <FilterByBuyType />
      </ControlsMobile>
      <CardsSection>
        {CARDS.map((data, index) => (
          <PCard
            product={data}
            type={ProductCardTypes.ONLY_VIEW}
            key={index}
            currentViewType={currentViewType}
          />
        ))}
      </CardsSection>
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
    </MotorVehiclesStyled>
  );
};

export default MotorVehicles;
