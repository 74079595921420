import styled from 'styled-components';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  margin-top: 40px;
`;

export const InfoSection = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 6px;

  & + & {
    margin-top: 12px;
  }
`;

export const InfoSectionTop = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--divider);
  font-weight: 600;
  svg {
    margin-right: 6px;
  }
  span {
    color: var(--main-green);
  }
`;

export const InfoSectionMain = styled.div`
  padding: 12px 16px;
  ${LatoFont};

  table {
    border: none;
    width: 100%;
    td:last-child {
      font-weight: 700;
      text-align: right;
    }
  }
`;
