import {
  ShoppingList,
  ShoppingListItem,
  ShoppingListItemDescription,
  ShoppingListItemTitle
} from '../shopping-centers-info-styled';

export const CentresList = ({ items, isAllVisible }) => {
  return (
    <ShoppingList isAllVisible={isAllVisible}>
      {items.map((card, i) => (
        <ShoppingListItem key={i}>
          <ShoppingListItemTitle>
            <span>{card.name}</span>
            <svg
              width='6'
              height='10'
              viewBox='0 0 6 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1L5 5L1 9'
                stroke='#DCDCDC'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </ShoppingListItemTitle>
          <ShoppingListItemDescription>20 Shopping Centres</ShoppingListItemDescription>
        </ShoppingListItem>
      ))}
    </ShoppingList>
  );
};
