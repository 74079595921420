import {
  Container,
  ImgWrapper,
  OldPrice,
  Price,
  MainPrice,
  Title,
  MainBox,
  Main,
  Label,
  RateStarsDesktop,
  Content,
  ContactsBox,
  Buttons,
  FavBtn4,
  LabelNotAvailable,
  BuyButton,
  Description
} from './p-card-small-styled';

import { SvgIcon } from '../svg-icon/svg-icon';

import { RateStars } from '../rate-stars/rate-stars';
import { LabelInStock, LabelOutStock, ZWLPrice } from '../p-card/p-card-styled';
import { ApproveSeller } from '../approve-seller/approve-seller';
import React, { useState } from 'react';

export const PCardSmall = ({ product, className }) => {
  const [isFav, setIsFav] = useState(false);

  const handleFavBtnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFav(!isFav);
  };
  return (
    <Container className={className}>
      <ImgWrapper>
        <ApproveSeller />
        <img src={product.img} alt={product.title} />
        <FavBtn4 onClick={handleFavBtnClick} isFav={isFav}>
          <SvgIcon name={'heart-2'} />
        </FavBtn4>
        {product.stock ? (
          <LabelInStock>Stock</LabelInStock>
        ) : (
          <LabelOutStock>Out stock</LabelOutStock>
        )}
        {product.delivery ? (
          <Label>Delivery Available</Label>
        ) : (
          <LabelNotAvailable>Delivery not available</LabelNotAvailable>
        )}
      </ImgWrapper>
      <Main>
        <MainBox>
          <RateStarsDesktop>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Title>{product.title}</Title>
        <Description>{product.description}</Description>
        <Content>
          <Price>
            <OldPrice>{product.old_price}</OldPrice>
            <MainPrice>{product.price}</MainPrice>
            <ZWLPrice>ZWL 200</ZWLPrice>
          </Price>
          <Buttons>
            <BuyButton>
              <SvgIcon name={'cart'} />
            </BuyButton>
          </Buttons>
        </Content>
      </Main>
    </Container>
  );
};
