import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 6px;
  font-family: 'Lato', sans-serif;
  align-items: center;
`;

export const Title = styled.div`
  margin-right: 4px;
`;

export const TitleValue = styled.div`
  color: var(--main-green);
`;

export const ColorsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ColorBox = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  background: ${(props) => props.color};

  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid var(--main-green);
    `}

  & + & {
    margin-left: 8px;
  }
`;
