import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Box = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 24px 32px;
  width: 488px;
  margin-left: 50px;
  display: none;
  @media ${device.laptop} {
    display: block;
    position: fixed;
    border-radius: 0px;
    padding: 24px 32px;
    padding-right: 98px;
    width: 580px;
    margin-left: 0;
    z-index: 111;
    top: 132px;
    right: 0;
    height: 100%;
    overflow-x: scroll;
    padding-bottom: 170px;
    &::-webkit-scrollbar {
      width: 3px;
      background: #fdfdfd;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #b7b7b7;
    }
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 24px 32px;
    padding-bottom: 170px;
  }
  @media ${device.mobile} {
    padding: 24px 15px;
    padding-bottom: 170px;
  }
`;

export const BoxPage = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 24px 32px;
  width: 488px;
  margin-left: 50px;
  @media ${device.laptop} {
    display: none;
  }
`;

export const Title = styled.div`
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #29263a;
  margin-bottom: 20px;
`;

export const Block = styled.div`
  margin-bottom: 20px;
`;

export const Tags = styled.div`
  margin-top: 15px;
`;

export const Tag = styled.div`
  border: 1px solid #bdd7bd;
  border-radius: 20px;
  padding: 7px 18px;
  margin-right: 8px;
  display: inline-block;
  margin-top: -30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #29263a;
`;

export const Img = styled.img``;

export const Gallery = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media ${device.mobile} {
    justify-content: space-between;
  }
`;

export const GalleryBlock = styled.div`
  position: relative;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  width: 99px;
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  &:last-child {
    margin-right: 0px;
  }
  @media ${device.mobile} {
    width: 80px;
    height: 80px;
  }
`;

export const GalleryPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: -10px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: -8px;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgb(70 91 131 / 10%);
  border-radius: 50px;
  border-radius: 12px;
  > img {
    width: 12.9px;
  }
`;

export const Buttons = styled.div`
  margin-top: 25px;

  button {
    &:nth-child(2) {
      margin-top: 10px;

      @media ${device.tablet} {
        display: none;
      }
    }
  }
`;

export const GlobalButtonBox = styled.div`
  @media ${device.mobile} {
    > button {
      width: 100%;
    }
  }
`;

export const CloseBox = styled.div`
  position: absolute;
  top: 2px;
  width: 24px;
  height: 24px;
  right: -7px;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  > img {
    width: 16px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const ArrowBack = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #388638;
  margin-top: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  display: none;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
  > img {
    margin-right: 5px;
  }
`;
