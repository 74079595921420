import {
  BottomMenu,
  Container,
  Item,
  ItemLink,
  ItemLinkInfo,
  ItemLinkSeller,
  ItemLinkTime,
  ItemLinkTitle,
  List,
  OfferLink
} from './latest-offers-styled';
import { useState } from 'react';
import { ModalWrapper } from '../../../../../../components/modal-wrapper/modal-wrapper';
import { CreateNewOfferPopup } from '../create-new-offer-popup/create-new-offer-popup';

const offersList = [
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  },
  {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula ante non elit scelerisque hendrerit. Duis interdum feugiat nulla, ut pellentesque ex blandit a. Quisque aliquam, dui et ultrices mollis, nulla neque elementum quam, pulvinar volutpat libero lacus ut erat. Proin massa erat, sodales eget egestas fermentum, porta vel ligula. ',
    company: 'Graphic Design Company',
    location: 'Kwekwe Kwekwe',
    time: '2 hrs'
  }
];

export const LatestOffers = () => {
  const [isCreateOfferModalVisible, setIsCreateOfferModalVisible] = useState(false);
  return (
    <Container>
      <ModalWrapper
        isVisible={isCreateOfferModalVisible}
        onClose={() => {
          setIsCreateOfferModalVisible(false);
        }}
      >
        <CreateNewOfferPopup />
      </ModalWrapper>
      <List>
        {offersList.map((offer, index) => {
          return (
            <Item key={index}>
              <ItemLink>
                <ItemLinkTitle>{offer.text}</ItemLinkTitle>
                <ItemLinkInfo>
                  <ItemLinkSeller>
                    <span>{offer.company}</span>
                    <span>{offer.location}</span>
                  </ItemLinkSeller>
                  <ItemLinkTime>{offer.time}</ItemLinkTime>
                </ItemLinkInfo>
              </ItemLink>
            </Item>
          );
        })}
      </List>
      <BottomMenu>
        <OfferLink to='#'>View all</OfferLink>
        <OfferLink
          to='#'
          onClick={(e) => {
            e.preventDefault();
            setIsCreateOfferModalVisible(true);
          }}
        >
          Create Offer
        </OfferLink>
      </BottomMenu>
    </Container>
  );
};
