import { GlobalButton } from 'components/global-button/global-button';

import { BusinessLinkCard } from '../business-link-card/business-link-card';
import { Bottom, CartBlockStyled, ProductsList, Total } from './cart-block-styled';
import { ProductItem } from '../product-item/product-item';

import { ReactComponent as CheckIcon } from './img/check.svg';
import { Link } from 'react-router-dom';
import { PromocodeInput } from '../promocode-input/promocode-input';

export const CartBlock = ({ products }) => {
  return (
    <CartBlockStyled>
      <BusinessLinkCard />
      <ProductsList>
        {products.map((item, i) => {
          return <ProductItem product={item} key={i} />;
        })}
      </ProductsList>
      <PromocodeInput />
      <Total>
        <div>
          <span>Sub Total</span>
          <span>$1150</span>
        </div>
        <div>
          <span>Total</span>
          <span>$1250</span>
        </div>
      </Total>
      <GlobalButton type='primary' fullWidth>
        <CheckIcon />
        Proceed to Checkout
      </GlobalButton>
      <Bottom>
        <Link to={'login'}>Login</Link> for a quick checkout
      </Bottom>
    </CartBlockStyled>
  );
};
