import React from 'react';
import arrowUp from '../../assets/arrow_up.svg';
import filter from '../../assets/filter.svg';
import {
  CategorySelection,
  Colors,
  FilterButton,
  FilterByButton,
  Filters,
  HorizontalLine,
  PriceSelection,
  Selector
} from '../../pages/company-page/components/products/products-styled';
import Checkbox from '../checkbox/checkbox';
import { GlobalButton } from '../global-button/global-button';
import PriceSelector from '../price-selector/price-selector';

const CATEGORIES = [
  {
    name: 'Erotic Clothing',
    amount: '98',
    checked: false
  },
  {
    name: 'Others Erotic Clothing',
    amount: '146',
    checked: false
  },
  {
    name: 'Handbags & Accessories',
    amount: '321',
    checked: true
  },
  {
    name: 'Women’s Handbags',
    amount: '235',
    checked: false
  },
  {
    name: 'Handbag Accessories',
    amount: '11',
    checked: false
  },
  {
    name: 'Men’s Fashion',
    amount: '96',
    checked: true
  },
  {
    name: 'Others Men’s Clothing',
    amount: '127',
    checked: true
  },
  {
    name: 'Shoes & Boots',
    amount: '203',
    checked: false
  }
];

const COLORS = [
  {
    color: '#FFFFFF',
    name: 'White',
    checked: false
  },
  {
    color: '#7CCC67',
    name: 'Green',
    checked: true
  },
  {
    color: '#ACADB9',
    name: 'Gray',
    checked: false
  },
  {
    color: '#FFD04E',
    name: 'Yellow',
    checked: true
  },
  {
    color: '#E54D45',
    name: 'Red',
    checked: false
  },
  {
    color: '#29263A',
    name: 'Black',
    checked: false
  }
];

export const ProductsFilter = () => {
  const [categories, setCategories] = React.useState(CATEGORIES);
  const [colors, setColors] = React.useState(COLORS);
  const [showCategories, setShowCategories] = React.useState(true);
  const [showPrice, setShowPrice] = React.useState(true);
  const [showColor, setShowColor] = React.useState(true);
  const [showOccasion, setShowOccasion] = React.useState(true);

  const handleCheckbox = (name, checked, arr, setState) => {
    const newState = arr.map((obj) => {
      if (obj.name === name) {
        return { ...obj, checked: checked };
      }
      return obj;
    });

    setState(newState);
  };
  return (
    <Filters>
      <FilterButton>
        Filters
        <img src={filter} alt='' />
      </FilterButton>
      <CategorySelection>
        <Selector>
          <p className='name'>Store Categories</p>
          <img
            className={showCategories ? '' : 'arrow-down'}
            src={arrowUp}
            onClick={() => setShowCategories(!showCategories)}
            alt=''
          />
        </Selector>
        {showCategories ? (
          <>
            {categories.map((data, index) => {
              return (
                <Checkbox
                  key={index}
                  wrapperClassName={'checkbox'}
                  checked={data.checked}
                  label={data.name}
                  labelNumber={data.amount}
                  onChange={(e) =>
                    handleCheckbox(data.name, e.target.checked, categories, setCategories)
                  }
                />
              );
            })}
          </>
        ) : (
          ''
        )}
      </CategorySelection>
      <HorizontalLine />
      <Selector>
        <p className='name'>Price in USD</p>
        <img
          className={showPrice ? '' : 'arrow-down'}
          src={arrowUp}
          onClick={() => setShowPrice(!showPrice)}
          alt=''
        />
      </Selector>
      <PriceSelection>
        {showPrice ? (
          <PriceSelector
            min={0}
            max={1000}
            onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
          />
        ) : (
          ''
        )}
      </PriceSelection>
      <HorizontalLine />
      <Selector>
        <p className='name'>Colour</p>
        <img
          className={showColor ? '' : 'arrow-down'}
          src={arrowUp}
          onClick={() => setShowColor(!showColor)}
          alt=''
        />
      </Selector>
      {showColor ? (
        <Colors>
          {colors.map((data, index) => {
            return (
              <Checkbox
                key={index}
                wrapperClassName={'checkbox'}
                checked={data.checked}
                label={data.name}
                withoutCheckmark
                color={data.color}
                onChange={(e) => handleCheckbox(data.name, e.target.checked, colors, setColors)}
              />
            );
          })}
        </Colors>
      ) : (
        ''
      )}
      <HorizontalLine />
      <Selector>
        <p className='name'>Occasion</p>
        <img
          className={showOccasion ? '' : 'arrow-down'}
          src={arrowUp}
          onClick={() => setShowOccasion(!showOccasion)}
          alt=''
        />
      </Selector>
      {showOccasion ? (
        <>
          <Checkbox wrapperClassName={'checkbox'} checked={true} label={'Casual'} />
          <Checkbox wrapperClassName={'checkbox'} checked={false} label={'Formal'} />
        </>
      ) : (
        ''
      )}
      <FilterByButton>
        <GlobalButton type={'primary'} height={40} fullWidth>
          <img src={filter} alt='' />
          Filter by Parameters
        </GlobalButton>
      </FilterByButton>

      <GlobalButton type={'secondary'} height={40} fullWidth>
        Reset Filter
      </GlobalButton>
    </Filters>
  );
};
