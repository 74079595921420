import { ButtonMobile } from '../categories-top-filter/categories-top-filter-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import React from 'react';
import { Container } from './mob-sort-styled';
import Select from 'react-select';
const selectCustomStyle = (isVar2) => {
  return {
    container: (provided) => ({
      ...provided,
      fontFamily: 'Lato, sans-serif',
      maxWidth: '100%'
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? 'var(--main-green)' : 'white',
      cursor: 'pointer'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      border: 'none',
      boxShadow: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    valueContainer: (provided) => ({
      display: 'flex'
    })
  };
};
const Control = ({ children, ...props }) => {
  return (
    <div {...props}>
      <ButtonMobile>
        <SvgIcon name='sort' />
        <p>Sort</p>
        {/* {children}*/}
      </ButtonMobile>{' '}
    </div>
  );
};
const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const MobSort = () => {
  return (
    <Container>
      <Select
        isSearchable={false}
        styles={selectCustomStyle()}
        components={{ Placeholder: Control, DropdownIndicator: null, SingleValue: Control }}
        options={selectOptions}
      />
    </Container>
  );
};
