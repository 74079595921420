export const EyeIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.21444 10.0604C1.10377 9.89092 1.04843 9.80621 1.01745 9.67554C0.994183 9.57739 0.994183 9.42261 1.01745 9.32446C1.04843 9.19379 1.10377 9.10908 1.21444 8.93965C2.12902 7.53952 4.85134 4 9 4C13.1487 4 15.871 7.53952 16.7856 8.93965C16.8962 9.10908 16.9516 9.19379 16.9825 9.32446C17.0058 9.42261 17.0058 9.57739 16.9825 9.67554C16.9516 9.80621 16.8962 9.89092 16.7856 10.0604C15.871 11.4605 13.1487 15 9 15C4.85134 15 2.12902 11.4605 1.21444 10.0604Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 11.8571C10.3465 11.8571 11.438 10.8018 11.438 9.5C11.438 8.19819 10.3465 7.14286 9 7.14286C7.65353 7.14286 6.56201 8.19819 6.56201 9.5C6.56201 10.8018 7.65353 11.8571 9 11.8571Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
