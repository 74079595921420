import {
  ClearBtn,
  Container,
  Dropdown,
  InputWrapper,
  Main,
  MobileClearBtn,
  Title,
  Top
} from './finder-widget-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { ReactComponent as TargetIcon } from './target.svg';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import Select from 'react-select';

const selectOptions = [
  { label: 'City', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const selectOptions2 = [
  { label: 'Suburb', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 0px', marginRight: '10px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#29263A'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};
const styles = {
  container: (provider) => ({
    ...provider,
    width: '100%'
  }),
  control: (provider) => ({
    ...provider,
    borderColor: 'transparent',
    boxShadow: 'none',
    border: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10000
  })
};
export const FinderWidget = () => {
  return (
    <Container>
      <Top>
        <Title>Product finder</Title>
        <ClearBtn>Clear filter</ClearBtn>
      </Top>
      <Main>
        <GlobalButton type={'secondary'}>
          <TargetIcon />
          Define location
        </GlobalButton>
        <InputWrapper>
          <Select
            options={selectOptions}
            placeholder={'Whole country'}
            styles={styles}
            components={{ DropdownIndicator: customDropdownIndicator }}
          />
        </InputWrapper>
        <Dropdown>
          <GlobalSelect options={selectOptions} dropdownIndicator={customDropdownIndicator} />
        </Dropdown>
        <Dropdown>
          <GlobalSelect options={selectOptions2} dropdownIndicator={customDropdownIndicator} />
        </Dropdown>
        <GlobalButton type={'orange'}>Filter by location</GlobalButton>
        <MobileClearBtn>Clear filter </MobileClearBtn>
      </Main>
    </Container>
  );
};
