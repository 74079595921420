import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import Checkbox from 'components/checkbox/checkbox';
import { Tooltip } from '../../../../components/tooltip/tooltip';
import styled from 'styled-components';

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;

export const ServiceDetails = () => {
  return (
    <Section>
      <SectionTitle>Service Details</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'service name'}>
          <GlobalInput placeholder={'Write the name of the service'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'service description'}>
          <GlobalTextarea placeholder={'Describe your service'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <Checkbox
          label={
            <CheckboxLabel>
              Taking Appointments for Service
              <Tooltip placement='bottom-start'>
                Enter your WhatsApp number here so we can contact you
              </Tooltip>
            </CheckboxLabel>
          }
        />
      </InputGroupWrapper>
    </Section>
  );
};
