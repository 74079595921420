import styled, { css } from 'styled-components';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  font-family: 'Lato', sans-serif;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const Title = styled.div``;

export const Value = styled.div`
  font-weight: 600;
  color: var(--main-green);
  margin-left: 4px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectBtn = styled.button`
  ${buttonReset};
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  padding: 2px 8px;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid var(--main-gray-2);

  & + & {
    margin-left: 8px;
  }
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid var(--main-green);
      background: #f2f6ff;
    `};
`;
