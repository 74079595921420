export const NotificationsTextIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.11111 2.88889H6.26667C4.77319 2.88889 4.02646 2.88889 3.45603 3.17954C2.95426 3.4352 2.54631 3.84315 2.29065 4.34491C2 4.91535 2 5.66208 2 7.15556V13.7333C2 15.2268 2 15.9735 2.29065 16.544C2.54631 17.0457 2.95426 17.4537 3.45603 17.7094C4.02646 18 4.77319 18 6.26667 18H12.8444C14.3379 18 15.0847 18 15.6551 17.7094C16.1569 17.4537 16.5648 17.0457 16.8205 16.544C17.1111 15.9735 17.1111 15.2268 17.1111 13.7333V10.8889M10.8889 14.4444H5.55556M12.6667 10.8889H5.55556M17.219 2.78105C18.2603 3.82245 18.2603 5.51089 17.219 6.55228C16.1776 7.59368 14.4891 7.59368 13.4477 6.55228C12.4063 5.51089 12.4063 3.82245 13.4477 2.78105C14.4891 1.73965 16.1776 1.73965 17.219 2.78105Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
