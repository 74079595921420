import React, { useState } from 'react';
import {
  TradesAndServicesStyled,
  ButtonMobile,
  GridButton,
  Header,
  ListButton,
  TradesNav,
  TradesNavMobile,
  RightSection,
  SortBy,
  Title,
  View,
  HorizontalLine,
  FilterButton,
  TypesSection,
  CardsSection,
  GridButtonTablet
} from './trades-and-services-styled';

import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { PCard, ProductCardTypes } from '../../../../components/p-card/p-card';

import filter from '../../../../assets/filter.svg';
import grid from '../../../../assets/grid.svg';
import gridBlue from '../../../../assets/grid_blue.svg';
import list from '../../../../assets/list.svg';

import img1 from '../../../../assets/trades_product_1.png';
import img2 from '../../../../assets/trades_product_2.png';
import img3 from '../../../../assets/trades_product_3.png';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { RealStateAdSpace } from '../../../categories-real-estate/components/main/real-estate/real-estate-styled';
import { ViewSelection, viewType } from '../../../../components/view-selection/view-selection';
import { PaginationWrapper } from '../../../categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import Pagination from '../../../../components/pagination/pagination';
import { LoadMore } from '../../../../components/load-more/load-more';
import { ResultsPerPage } from '../../../../components/results-per-page/results-per-page';
import { CategoriesTopFilter } from '../../../../components/categories-top-filter/categories-top-filter';

const CARDS = [
  {
    img: img1,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img2,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img3,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img2,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img3,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img1,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img3,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img2,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  },
  {
    img: img1,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam',
    rate: 4.1,
    reviews: 36,
    price: '$250'
  }
];

export const TYPES = [
  {
    name: 'Service Type'
  },
  {
    name: 'Sub Type'
  },
  {
    name: 'Province'
  },
  {
    name: 'City'
  },
  {
    name: 'Suburb'
  }
];

const TradesAndServices = ({ onFilterBtnClick }) => {
  const [search, setSearch] = React.useState('');
  const [currentView, setCurrentView] = useState(viewType.grid);
  return (
    <TradesAndServicesStyled>
      <CategoriesTopFilter
        title={'Trades and services'}
        onFilterBtnClick={onFilterBtnClick}
        currentViewType={currentView}
        setCurrentViewType={setCurrentView}
        isMobileViewHidden={false}
        setSearch={setSearch}
        search={search}
        searchPlaceholder={'Search in trades and services'}
      />
      <CardsSection currentViewType={currentView}>
        {CARDS.map((product, index) => {
          if (index === 4) {
            return (
              <React.Fragment key={index}>
                <RealStateAdSpace />
                <PCard
                  product={product}
                  type={ProductCardTypes.TRADES}
                  key={index}
                  currentViewType={currentView}
                />
              </React.Fragment>
            );
          }
          return (
            <PCard
              product={product}
              type={ProductCardTypes.TRADES}
              key={index}
              currentViewType={currentView}
            />
          );
        })}
      </CardsSection>
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
    </TradesAndServicesStyled>
  );
};

export default TradesAndServices;
