import { ContainerBox, Main, Side, Wrapper } from '../../add-new-product-form-auction-styled.jsx';

import { ProductOffer } from '../product-offer/product-offer';
import { SidePreview } from '../side-preview/side-preview';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductLayout = ({ children, isSidebarHidden, setIsSidebarHidden }) => {
  return (
    <>
      <ProductOffer selectOptions={selectOptions} />
      <ContainerBox>
        <Main>{children}</Main>
        <Wrapper>
          <Side isSidebarHidden={isSidebarHidden}>
            <SidePreview
              isSidebarHidden={isSidebarHidden}
              onPreviewButtonClick={() => {
                setIsSidebarHidden(!isSidebarHidden);
              }}
            />
          </Side>
        </Wrapper>
      </ContainerBox>
    </>
  );
};
