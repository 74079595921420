import styled from 'styled-components';

import {
  SectionText,
  SectionTitle,
  Section,
  InputGroupWrapper
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { buttonReset, LatoFont } from 'assets/style-helpers/style-helpers';
import fileIcon from 'assets/icons/file-check-03.svg';

const UploadFileName = styled.div`
  padding: 15px 24px 15px 48px;
  background: url(${fileIcon}) no-repeat 24px 17px;
  background-size: 18px 18px;
  ${LatoFont};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  border: 1px solid var(--divider);
`;

const ClearFileNameBtn = styled.div`
  ${buttonReset};
  margin-left: auto;
  display: flex;
`;

export const BusinessDocumentsSection = () => {
  return (
    <Section>
      <SectionTitle>Business Documents</SectionTitle>
      <SectionText>
        Optional: Upload your business documents. May be used to verify your business.
      </SectionText>
      <InputGroupWrapper>
        <InputGroup label={'sertificate of incorporation'}>
          <UploadFileName>
            Sertificate of Incorporation.pdf
            <ClearFileNameBtn>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.1654 5.83301L5.83203 14.1663M5.83203 5.83301L14.1654 14.1663'
                  stroke='#ACADB9'
                  strokeWidth='1.6'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </ClearFileNameBtn>
          </UploadFileName>
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'tax clearance'}>
          <GlobalButton type={'orange-secondary'}>
            <SvgIcon name={'upload'} />
            Upload
          </GlobalButton>
        </InputGroup>
      </InputGroupWrapper>
    </Section>
  );
};
