import styled from 'styled-components';
import { title30 } from '../../../../assets/style-helpers/typography';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarBtn = styled.div`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--divider);
  border-radius: 6px;
  cursor: pointer;
  svg {
    margin-left: 16px;
  }
`;

export const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 24px;
  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StatCardStyled = styled.div`
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  padding: 24px 16px;
  @media ${device.tablet} {
    padding: 14px;
  }
`;

export const StatCardTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StatCardIconWrapper = styled.div`
  background: #e7f0e7;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    margin-bottom: 4px;
  }
`;

export const StatCardTitle = styled.div`
  color: var(--secondary-text);
`;

export const Value = styled.div`
  ${title30};
  margin-bottom: 10px;
`;

export const Percent = styled.div`
  display: flex;
  align-items: center;
  ${LatoFont};
  color: var(--secondary-text);
  svg {
    margin-right: 8px;
  }
`;

export const Main = styled.div`
  flex-basis: 815px;
  margin-right: 32px;
  @media ${device.laptop} {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

export const Side = styled.div`
  flex-basis: calc(100% - 815px - 32px);
  min-width: 0;
  @media ${device.laptop} {
    flex-basis: auto;
    display: flex;
    width: 100%;
  }
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 106px;
  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const ChartWrapper = styled.div`
  margin-top: 32px;
`;

export const Chart = styled.div`
  background: var(--main-white);
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  height: 368px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const LegendItem = styled.div`
  padding-left: 20px;
  position: relative;
  ${LatoFont};
  font-weight: 700;

  & + & {
    margin-left: 24px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;

export const LegendItemGreen = styled(LegendItem)`
  &::before {
    background: var(--main-green);
  }
`;

export const LegendItemRed = styled(LegendItem)`
  &::before {
    background: var(--main-orange);
  }
`;

export const LegendItemOrange = styled(LegendItem)`
  &::before {
    background: #ffbb00;
  }
`;

export const StyledTable = styled.div`
  border: 1px solid var(--divider);
  width: 100%;
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
  ${LatoFont};
  font-size: 12px;
  line-height: 17px;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    background: #f7f7f7;
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--divider);
  }
  tr:not(:first-child) {
    padding-left: 20px;
    padding-right: 20px;
  }
  td {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--divider);
  }
`;

export const MessageList = styled.div``;

export const MessageStyled = styled.div`
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--divider);
  width: 100%;
  min-width: 0;
  & + & {
    margin-top: 20px;
  }
`;

export const MessageAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 16px;
`;

export const MessageMain = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

export const MessageTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MessageAuthor = styled.div``;

export const MessageDate = styled.div`
  font-size: 12px;
  color: var(--main-subtext);
  ${LatoFont};
`;

export const MessagetText = styled.div`
  ${LatoFont};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 385px;
  min-width: 0;
`;
