import React from 'react';
import './products.scss';

import grid from '../../../../assets/grid.svg';
import list from '../../../../assets/list.svg';
import filter from '../../../../assets/filter.svg';
import eye from '../../../../assets/eye.svg';

import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import {
  ButtonMobile,
  Container,
  ControlsSecond,
  FilterButton,
  GridButton,
  Header,
  HorizontalLine,
  ListButton,
  OurProducts,
  ProductCards,
  ProductsNav,
  ProductsNavMobile,
  RightSection,
  SeeMoreButton,
  SortBy,
  Title,
  View
} from './products-styled';
import Dropdown from './dropdown/dropdown';
import { PCard, ProductCardTypes } from '../../../../components/p-card/p-card';
import img1 from '../../../../assets/temp-img/temp4.png';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { ProductsFilter } from '../../../../components/products-filter/products-filter';
import { GlobalButton } from '../../../../components/global-button/global-button';

const CARDS = [
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img1,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 4.1,
    reviews: 36,
    old_price: '$19.99',
    price: '$10.00'
  }
];

const Products = () => {
  const [search, setSearch] = React.useState('');

  return (
    <Container>
      <ProductsFilter />
      <OurProducts>
        <Header>
          <Title>Our Products</Title>
          <View>
            <GridButton>
              <img src={grid} alt='' />
            </GridButton>
            <ListButton>
              <img src={list} alt='' />
            </ListButton>
          </View>
        </Header>
        <HorizontalLine />
        <ProductsNav>
          <InputWithLabel
            width={'400px'}
            height={'38px'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'Enter what you are looking for...'}
            borderColor={'#7CCC67'}
          />
          <ControlsSecond>
            <SortBy>Sort by:</SortBy>
            <Dropdown name={'Default'} />
          </ControlsSecond>
        </ProductsNav>
        <ProductsNavMobile>
          <FilterButton>
            <img src={filter} alt='' />
            Filters
          </FilterButton>
          <RightSection>
            <ButtonMobile>
              <SvgIcon name='search' />
              <p>Search</p>
            </ButtonMobile>
            <ButtonMobile>
              <SvgIcon name='sort' />
              <p>Sort</p>
            </ButtonMobile>
          </RightSection>
        </ProductsNavMobile>
        <ProductCards>
          {CARDS.map((product, index) => {
            return <PCard product={product} type={ProductCardTypes.BASIC} key={index} />;
          })}
        </ProductCards>
        <SeeMoreButton>
          <GlobalButton type={'secondary'}>
            <img src={eye} alt='' />
            See more products
          </GlobalButton>
        </SeeMoreButton>
      </OurProducts>
    </Container>
  );
};

export default Products;
