import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 12px 0 24px;
`;

export const BreadcrumbsLink = styled(Link)`
  color: var(--main-subtext);

  ${(props) =>
    props.isActive &&
    css`
      color: var(--main-text);
      &::after {
        display: none;
      }
    `};
  &::after {
    content: '/';
    margin: 0 12px;
  }
`;
