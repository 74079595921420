import { SectionHeader } from 'components/section-header/section-header';
import {
  Container,
  Main,
  Wrapper,
  MapWrapper,
  BtnWrapper
} from './popular-shopping-centres-styled';
import { StoreCard } from 'components/store-card/store-card';
import storeImg from '../../../store-finder/components/search-results/img/store-temp-img.png';
import storeLogo from '../../../store-finder/components/search-results/img/store-temp-logo.png';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

import mapImg from 'assets/temp-img/shopping-centres-map.png';
import { useRef, useState } from 'react';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { ShoppingCenterSection } from '../../../home/components/shopping-centers-section/shopping-center-section';

const mock = [
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -19.23878101020672,
      lng: 29.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  }
];

// eslint-disable-next-line no-unused-vars
export const PopularShoppingCentres = ({ title = 'Popular Shopping Centres', tags = false }) => {
  const tagsList = useRef(null);
  const [tagsListPosition, setTagsListPosition] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const scrollNext = () => {
    const newPosition = tagsListPosition - 50;
    setTagsListPosition(newPosition);
    tagsList.current.style.transform = `translateX(${newPosition}px)`;
  };
  return (
    <>
      <ShoppingCenterSection title={title} />
      <Container>
        {/* <SectionHeader title={title} />*/}

        <Wrapper>
          <Main>
            {/* {tags && (*/}
            {/*  <TagsWrapper>*/}
            {/*    <TagsListWrapper>*/}
            {/*      <TagsList ref={tagsList}>*/}
            {/*        <Tag>Bulawayo</Tag>*/}
            {/*        <Tag>Chitungwiza</Tag>*/}
            {/*        <Tag>Mutare</Tag>*/}
            {/*        <Tag>Gweru</Tag>*/}
            {/*        <Tag>Epworth</Tag>*/}
            {/*        <Tag>Mutare</Tag>*/}
            {/*        <Tag>Bulawayo</Tag>*/}
            {/*        <Tag>Epworth</Tag>*/}
            {/*        <Tag>Mutare</Tag>*/}
            {/*        <Tag>Bulawayo</Tag>*/}
            {/*      </TagsList>*/}
            {/*    </TagsListWrapper>*/}

            {/*    <ScrollNextBtn onClick={scrollNext}>*/}
            {/*      <svg*/}
            {/*        width='5'*/}
            {/*        height='8'*/}
            {/*        viewBox='0 0 5 8'*/}
            {/*        fill='none'*/}
            {/*        xmlns='http://www.w3.org/2000/svg'*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          d='M1 1L4 4L0.999999 7'*/}
            {/*          stroke='#29263A'*/}
            {/*          strokeWidth='1.7'*/}
            {/*          strokeLinecap='round'*/}
            {/*          strokeLinejoin='round'*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    </ScrollNextBtn>*/}
            {/*  </TagsWrapper>*/}
            {/* )}*/}
            <InputWithLabel
              placeholder={'Enter what store you are looking for...'}
              height={40}
              name={'Search in shopping centre'}
            />
            {mock.map((store, index) => {
              return <StoreCard store={store} key={index} isListView />;
            })}
            <BtnWrapper>
              <GlobalButton type={'orange-secondary'}>
                <SvgIcon name={'eye'} />
                See more shopping centres{' '}
              </GlobalButton>
            </BtnWrapper>
          </Main>
          <MapWrapper>
            <img src={mapImg} alt='' />
          </MapWrapper>
        </Wrapper>
      </Container>
    </>
  );
};
