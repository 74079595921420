import { Buttons, Container, SelectBtn, Title, Top, Value } from './select-with-buttons-styled';

export const SelectWithButtons = ({ title, values, onClick, currentValue }) => {
  return (
    <Container>
      <Top>
        <Title>{title}</Title>
        <Value>{currentValue.label}</Value>
      </Top>
      <Buttons>
        {values.map((btn, key) => (
          <SelectBtn
            key={key}
            onClick={() => {
              onClick(btn);
            }}
            isActive={btn.value === currentValue.value}
          >
            {btn.label}
          </SelectBtn>
        ))}
      </Buttons>
    </Container>
  );
};
