import { Container, Input, Label, Text } from './global-checkbox-styled';

export const GlobalCheckbox = ({ children, id, isChecked, onChange }) => {
  return (
    <Container>
      <Input type='checkbox' id={id} value={id} checked={isChecked} onChange={onChange} />
      <Label htmlFor={id} isChecked={isChecked} />
      <Text htmlFor={id}>{children}</Text>
    </Container>
  );
};
