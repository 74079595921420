import styled, { css } from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Title = styled.div`
  font-size: 58px;
  line-height: 70px;
  margin: 48px 0 32px;
  font-weight: 600;
  @media ${device.mobile} {
    font-size: 34px;
    line-height: 1.2;
  }
`;

export const Container = styled.div`
  ${mainContainer};
  ${(props) => {
    return (
      props.advSection &&
      css`
        display: block;
      `
    );
  }};
  @media ${device.laptop} {
    ${(props) => {
      return (
        props.advSection &&
        css`
          display: none;
        `
      );
    }}
  }
  @media ${device.tablet} {
    ${(props) => {
      return (
        props.advSection &&
        css`
          display: none;
        `
      );
    }}
  }
  @media ${device.mobile} {
    ${(props) => {
      return (
        props.advSection &&
        css`
          display: none;
        `
      );
    }}
  }
`;
