import styled from 'styled-components';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  padding-bottom: 80px;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--main-gray-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${title30};
`;

export const Content = styled.div``;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--main-green);
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a + a {
    margin-left: 8px;
  }
`;
