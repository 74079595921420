import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import Header from 'components/header/header';

import { Container } from '../shopping-centres/shopping-centres-styled';
import { PopularShoppingCentres } from '../shopping-centres/components/popular-shopping-centres/popular-shopping-centres';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Shopping Centres' },
  { link: '#', name: 'Shopping Centres in Harare' }
];

export const ShoppingCentresFiltered = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
      </Container>
      <PopularShoppingCentres title={'Shopping Centres in Harare'} tags />
    </>
  );
};
