import styled from 'styled-components';
import { title20 } from '../../../../../../assets/style-helpers/typography';
import { buttonReset, LatoFont } from '../../../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  background: #e4f6e4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;

  @media ${device.mobile} {
    flex-basis: auto;
    padding: 34px 16px 44px;
  }
`;

export const Title = styled.div`
  ${title20};
  text-align: center;
  margin: 12px 0 24px;
  line-height: 30px;
`;

export const LogInButton = styled.button`
  ${buttonReset};
  color: var(--main-green);
  ${LatoFont};
  font-weight: 600;
  margin-top: 12px;
  text-decoration: underline;
`;
