import React, { useState, useEffect } from 'react';
import './price-selector.scss';
import { RangeSlider } from '../range-slider/range-slider';

const PriceSelector = ({
  min,
  max,
  defaultMin,
  defaultMax,
  inputsTop = true,
  weight = false,
  km = false,
  values
}) => {
  const [selectorData, setSelectorData] = useState(values ? values : { min, max });
  const [symbol, setSymbol] = useState('$');

  const handleChange = (event, setState) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setState(value);
  };

  const handleRangeSliderChange = (data) => {
    setSelectorData(data);
  };

  useEffect(() => {
    if (values) {
      setSelectorData(values);
    }
  }, [values]);
  useEffect(() => {
    if (km) {
      setSymbol('km');
    }
    if (weight) {
      setSymbol('kg');
    }
  }, [km, weight]);

  return (
    <div className='price-selection'>
      {inputsTop ? (
        <div className='price-inputs'>
          <input
            type='number'
            value={selectorData.min}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, min: value });
              })
            }
          />
          <div className='horizontal-line' />
          <input
            type='number'
            value={selectorData.max}
            onChange={(e) =>
              handleChange(e, (value) => {
                setSelectorData({ ...selectorData, max: value });
              })
            }
          />
        </div>
      ) : (
        ''
      )}
      <RangeSlider
        defaultMin={defaultMin}
        defaultMax={defaultMax}
        min={min}
        max={max}
        minVal={selectorData.min}
        maxVal={selectorData.max}
        onChange={handleRangeSliderChange}
      />
      {!inputsTop ? (
        // <div className='price-text'>
        //   {!km ? (
        //     <>
        //       <p>{!weight ? `$${selectorData.min}` : `${selectorData.min}kg`}</p>
        //       <p>{!weight ? `$${selectorData.max}` : `${selectorData.max}kg`}</p>
        //     </>
        //   ) : (
        //     <>
        //       <p>{`${selectorData.min}km`}</p>
        //       <p>{`${selectorData.max}km`}</p>
        //     </>
        //   )}
        // </div>
        <div className='second-price-inputs'>
          <div>
            <span>{symbol}</span>
            <input
              type='number'
              value={selectorData.min}
              onChange={(e) =>
                handleChange(e, (value) => {
                  setSelectorData({ ...selectorData, min: value });
                })
              }
            />
          </div>
          <div>
            <span>{symbol}</span>
            <input
              type='number'
              value={selectorData.max}
              onChange={(e) =>
                handleChange(e, (value) => {
                  setSelectorData({ ...selectorData, max: value });
                })
              }
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PriceSelector;
