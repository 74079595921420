import { GlobalInput } from '../global-input/global-input';
import { Container } from './global-input-select-styled';
import Select from 'react-select';

const phonePrefixOptions = [
  { value: '+263', label: '+263' },
  { value: '+111', label: '+111' }
];

const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 0px', marginRight: '10px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#29263A'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

const selectCustomStyle = {
  container: (provided) => ({
    ...provided,
    position: 'absolute',
    fontFamily: 'Lato, sans-serif',
    top: '1px',
    left: '1px',
    maxWidth: '80px',
    width: '80px'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '50px',
    border: 'none',
    boxShadow: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    order: '1',
    alignSelf: 'center',
    color: '#E8E8E8',
    height: '20px'
  })
};

export const GlobalInputSelect = ({ name, value, placeholder, onChange }) => {
  return (
    <Container>
      <Select
        options={phonePrefixOptions}
        defaultValue={phonePrefixOptions[0]}
        styles={selectCustomStyle}
        components={{ DropdownIndicator: customDropdownIndicator }}
      />
      <GlobalInput
        name={name}
        type={'text'}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Container>
  );
};
