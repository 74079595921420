import {
  Avatar,
  AvatarWrapper,
  ChatAvatar,
  ChatContent,
  ChatContentText,
  ChatContentTop,
  ChatDate,
  ChatInner,
  ChatItem,
  ChatListWrapper,
  ChatUserName,
  Container,
  Control,
  DotsBtn,
  HeadCell,
  IncomeMessage,
  Info,
  InputWrapper,
  MainCell,
  MainChatDate,
  MessegesWrapper,
  MobileBackBtn,
  MsgSetings,
  OrderData,
  OrderDataItem,
  OutcomeMessage,
  RemoveBtn,
  Search,
  SearchBtn,
  User,
  UserName
} from './messeges-styled';
import { GlobalInput } from 'components/global-input/global-input';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as Dots } from 'assets/dots-services.svg';
import { ReactComponent as Trash } from 'assets/trash.svg';

import avatar from 'assets/temp-img/avatar.png';
import chatImg from 'assets/temp-img/chat-img.png';

const chatList = [
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  },
  {
    user: 'Langton Shoriwa',
    avatar: avatar,
    time: '1 day ago',
    text: 'Lorem ipsum dolor sit amet consectetur Sagittissti'
  }
];

import { ReactComponent as ClipIcon } from './icons/paperclip.svg';
import { ReactComponent as EmojiIcon } from './icons/emoji.svg';
import { ReactComponent as SendMessageIcon } from './icons/send-message.svg';
import { useState } from 'react';
import Tippy from '@tippyjs/react/headless';

export const Messages = () => {
  const [isMessegesOpen, setIsMessegesOpen] = useState(false);
  return (
    <>
      <MobileBackBtn
        isMessegesOpen={isMessegesOpen}
        onClick={() => {
          setIsMessegesOpen(false);
        }}
      >
        <SvgIcon name={'chevron-left'} />
        Back to Inbox
      </MobileBackBtn>

      <Container isMessegesOpen={isMessegesOpen}>
        <HeadCell className={'control'}>
          <Search>
            <GlobalInput placeholder={'Quick Search...'} />
            <SearchBtn>
              <SvgIcon name={'search'} />
            </SearchBtn>
          </Search>
        </HeadCell>
        <HeadCell className={'chat'}>
          <Info>
            <User>
              <Avatar>
                <img src={avatar} alt='' />
              </Avatar>
              <UserName>Langton Shoriwa</UserName>
            </User>
            <OrderData>
              <OrderDataItem>
                <span>Orders: </span>
                <span>5</span>
              </OrderDataItem>
              <OrderDataItem>
                <span>Total Spend: </span>
                <span>$400</span>
              </OrderDataItem>
            </OrderData>
          </Info>
        </HeadCell>
        <MainCell className={'control'}>
          <ChatListWrapper>
            {chatList.map((item, key) => (
              <ChatItem
                key={key}
                onClick={() => {
                  setIsMessegesOpen(true);
                }}
              >
                <AvatarWrapper>
                  <Tippy
                    appendTo={'parent'}
                    placement={'bottom-start'}
                    hideOnClick={true}
                    trigger={'click'}
                    render={(attrs) => (
                      <RemoveBtn>
                        <Trash />
                        Remove
                      </RemoveBtn>
                    )}
                  >
                    <DotsBtn>
                      <Dots />
                    </DotsBtn>
                  </Tippy>
                  <ChatAvatar>
                    <img src={item.avatar} alt={item.user} />
                  </ChatAvatar>
                </AvatarWrapper>

                <ChatContent>
                  <ChatContentTop>
                    <ChatUserName>{item.user}</ChatUserName>
                    <ChatDate>{item.time}</ChatDate>
                  </ChatContentTop>
                  <ChatContentText>{item.text}</ChatContentText>
                </ChatContent>
              </ChatItem>
            ))}
          </ChatListWrapper>
        </MainCell>
        <MainCell className={'chat'}>
          <Control>
            <InputWrapper>
              <button>
                <ClipIcon />
              </button>
              <button>
                <EmojiIcon />
              </button>
              <input type='text' placeholder='Write a message...' />
              <button>
                <SendMessageIcon />
              </button>
            </InputWrapper>
          </Control>
          <ChatInner>
            <MessegesWrapper>
              <MainChatDate>
                <span>28 June</span>
              </MainChatDate>
              <IncomeMessage>
                <div>
                  <span>Hi! Do you deliveries in Gweru?</span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </IncomeMessage>
              <OutcomeMessage>
                <MsgSetings>
                  <Tippy
                    appendTo={'parent'}
                    placement={'bottom-start'}
                    hideOnClick={true}
                    trigger={'click'}
                    render={(attrs) => (
                      <RemoveBtn>
                        <Trash />
                        Remove
                      </RemoveBtn>
                    )}
                  >
                    <DotsBtn>
                      <Dots />
                    </DotsBtn>
                  </Tippy>
                </MsgSetings>
                <div>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit
                    amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor
                    purus non enim praesent{' '}
                  </span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </OutcomeMessage>
              <IncomeMessage>
                <div>
                  <span>
                    <img src={chatImg} alt='' />
                    Hi! Do you deliveries in Gweru?
                  </span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </IncomeMessage>
              <MainChatDate>
                <span>28 June</span>
              </MainChatDate>
              <IncomeMessage>
                <div>
                  <span>Hi! Do you deliveries in Gweru?</span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </IncomeMessage>
              <OutcomeMessage>
                <div>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit
                    amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor
                    purus non enim praesent{' '}
                  </span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </OutcomeMessage>
              <IncomeMessage>
                <div>
                  <span>
                    <img src={chatImg} alt='' />
                    Hi! Do you deliveries in Gweru?
                  </span>
                  <ChatDate>12:20 PM</ChatDate>
                </div>
              </IncomeMessage>
            </MessegesWrapper>
          </ChatInner>
        </MainCell>
      </Container>
    </>
  );
};
