import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer}
`;

export const ButtonWrapper = styled.div`
  @media ${device.tablet} {
    margin-top: 24px;
  }
`;

export const ButtonsTop = styled.div`
  > button {
    &:last-child {
      min-width: 132px;
    }
  }
  button + button {
    margin-left: 12px;
    @media ${device.tablet} {
      margin-left: 0;
      margin-top: 12px;
    }
  }
  margin-bottom: 12px;

  @media ${device.tablet} {
    button {
      width: 100%;
    }
  }
`;
