import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { title20 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  overflow: hidden;
  padding: 24px;
  & + & {
    margin-top: 32px;
  }

  @media ${device.laptop} {
    flex-basis: calc(50% - 16px);
    & + & {
      margin-top: 0;
      margin-left: 32px;
    }
  }

  @media ${device.tablet} {
    flex-basis: 100%;
    & + & {
      margin-top: 32px;
      margin-left: 0;
    }
  }

  @media ${device.mobile} {
    padding: 0;
    box-shadow: none;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  ${title20};
  display: flex;
  align-items: center;
`;

export const NotificationsCounter = styled.span`
  width: 24px;
  height: 24px;
  background: #e7f0e7;
  border-radius: 16px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: var(--main-green);
  justify-content: center;
`;

export const Main = styled.div``;
