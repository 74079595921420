import Header from '../../components/header/header';
import {
  BannerBox,
  CenterColumn,
  CompleteIcon,
  Container,
  Inner,
  LeftColumn,
  Order,
  PageTop,
  RightColumn,
  Title,
  WarantyInfo
} from './thank-you-styled';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { Coupon } from '../../components/coupon/coupon';
import { SponsoredBanners } from '../single-product-general/components/sponsored-banners/sponsored-banners';
import { OrderInfo } from './components/order-info/order-info';
import { OrderDetails } from './components/order-details/order-details';
import {
  InfoSection,
  InfoSectionMain,
  InfoSectionTop
} from '../../components/product-info/components/delivery-info/delivery-info-styled';
import { ReactComponent as ShieldTick } from '../../components/product-info/components/delivery-info/icons/shield-tick.svg';

export const ThankYou = () => {
  return (
    <>
      <Header />
      <Container>
        <PageTop>
          <Order>Order #121232</Order>
          <Title>
            <CompleteIcon>
              <SvgIcon name={'check'} />
            </CompleteIcon>
            Thank you, Langton!
          </Title>
        </PageTop>
        <Inner>
          <LeftColumn>
            <Coupon />
            <OrderDetails />
          </LeftColumn>
          <CenterColumn>
            <OrderInfo />
            <WarantyInfo>
              <InfoSectionTop>
                <ShieldTick />
                Warranty Options
              </InfoSectionTop>
              <InfoSectionMain>
                <b>Full Warranty.</b> Lorem ipsum dolor sit amet consectetur. Leo portti vitae at at
                mattis ultrices parturient vel.
              </InfoSectionMain>
            </WarantyInfo>
          </CenterColumn>
          <RightColumn>
            <SponsoredBanners />
            <BannerBox>AD SPACE</BannerBox>
          </RightColumn>
        </Inner>
      </Container>
    </>
  );
};
