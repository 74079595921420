import React from 'react';
import './reviews-card.scss';

import accountIcon from '../../assets/review_account.svg';

import { ReviewCardStyled } from './reviews-styled';
import { RateStars } from '../rate-stars/rate-stars';

const ReviewCard = () => {
  return (
    <ReviewCardStyled>
      <div className='card'>
        <p className='text'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br />
          <span>Read more</span>
        </p>
        <div className='info'>
          <div className='user'>
            <img className='account-icon' src={accountIcon} alt='' />
            <p className='name'>Langton Shoriwa</p>
          </div>
          <div className='stars-mobile'>
            <p className='name'>Langton Shoriwa</p>
            <RateStars rate={3} height={14} width={14} isRateCounter={false} color={'#388638'} />
          </div>
        </div>
      </div>
    </ReviewCardStyled>
  );
};

export default ReviewCard;
