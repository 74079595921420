import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { title30 } from '../../assets/style-helpers/typography';

export const Container = styled.div`
  ${mainContainer};
  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 106px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.mobile} {
  }
`;

export const Col = styled.div`
  &:first-child {
    flex-basis: 396px;
    @media ${device.laptop} {
      flex-basis: 350px;
    }
    @media ${device.tablet} {
      flex-basis: 340px;
    }
    @media ${device.mobile} {
      margin-top: 40px;
    }
  }
  &:last-child {
    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 80px;
  @media ${device.laptop} {
    margin: 0 40px;
  }
  @media ${device.tablet} {
    margin-left: 0;
  }
  @media ${device.mobile} {
    margin-right: 0;
  }
`;

export const Title = styled.div`
  ${title30};
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const TrackingContainer = styled.div`
  width: 44%;
  @media ${device.tablet} {
    width: 100%;
    &:nth-child(2) {
      margin-top: 50px;
    }
  }
`;

export const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: 22px;
  @media ${device.tablet} {
    width: 787px;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;
