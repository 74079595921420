import React from 'react';
import './input.scss';

import arrow from '../../assets/arrow_up.svg';
import search from '../../assets/search.svg';

const Input = ({
  value,
  onChange,
  wrapperClassName,
  inputClassName,
  width,
  height,
  placeholder,
  borderColor,
  dropdownOnClick,
  withoutCategories = false
}) => {
  return (
    <section className={`${wrapperClassName} input-global`} style={{ width: width }}>
      <input
        id={'input'}
        className={`${inputClassName} `}
        type='text'
        value={value}
        onChange={onChange}
        style={{
          height: height,
          border: `1px solid ${!borderColor ? '#E2E5EB' : borderColor}`
        }}
        placeholder={placeholder}
      ></input>
      <div className='controls'>
        {!withoutCategories ? (
          <div className='dropdown' onClick={dropdownOnClick ? dropdownOnClick : () => {}}>
            <p className='name'>Category</p>
            <img src={arrow} alt='' />
          </div>
        ) : (
          ''
        )}
        <div className='search-button'>
          <img src={search} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Input;
