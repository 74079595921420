import { ReactComponent as ThreeDots } from '../../../../assets/three-dots.svg';

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'List Name',
    accessor: 'listName'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Customers',
    accessor: 'customers'
  },
  {
    Header: 'Total Revenue',
    accessor: 'totalRevenue'
  },
  {
    Header: 'Actions',
    accessor: 'dots'
  }
];
export const columnsRows = [
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    listName: 'Mbinga Dzangu',
    description: 'Contains my customers that spent an average of $100 or more per order',
    date: '07 Jul 2022',
    customers: '30',
    totalRevenue: '$1200.90',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12529,
    listName: 'John Mayer',
    description: 'Contains my customers that spent an average of $900 or more per order',
    date: '07 Jul 2029',
    customers: '77',
    totalRevenue: '$1770.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12521,
    listName: 'David Mayer',
    description: 'Contains my customers that spent an average of $200 or more per order',
    date: '07 Jul 2024',
    customers: '40',
    totalRevenue: '$1400.00',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  }
];

export const SidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Customer Name',
    accessor: 'customerName'
  },
  {
    Header: 'Orders',
    accessor: 'orders'
  },
  {
    Header: 'Total Spent',
    accessor: 'totalSpent'
  },
  {
    Header: 'Actions',
    accessor: 'dots'
  }
];

export const SidebarColumnsRows = [
  {
    id: 12520,
    customerName: 'Langton Shoriwa',
    orders: 100,
    totalSpent: '$200 000 000',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    customerName: 'Langton Shoriwa',
    orders: 100,
    totalSpent: '$200 000 000',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    customerName: 'Langton Shoriwa',
    orders: 100,
    totalSpent: '$200 000 000',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    customerName: 'Langton Shoriwa',
    orders: 100,
    totalSpent: '$200 000 000',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  }
];
