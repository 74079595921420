import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { Container, Union } from './categories-general-styled';
import { GlobalFilter } from '../../components/global-filter/global-filter';
import React, { useState } from 'react';
import { MainStyled } from './components/main/main-styled';
import Background from './components/main/background/background';
import { NearYou } from './components/main/near-you/near-you';
import { BreadcrumbsContainer } from '../categories-real-estate/categories-real-estate-styled';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Clothing & Apparel' }
];

const CategoriesGeneralNearYou = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <MainStyled>
          <Background
            title={'Clothing & Apparel'}
            subtitle={'Clothing & Apparel'}
            buttonText={'000-120-456-789'}
          />
          <NearYou />
        </MainStyled>
      </Container>
    </>
  );
};

export default CategoriesGeneralNearYou;
