import styled, { css } from 'styled-components';
import { device } from 'assets/style-helpers/breakpoints';
import { buttonReset } from 'assets/style-helpers/style-helpers';

export const OpenSidebarBtn = styled.button`
  display: flex;

  @media ${device.mobile} {
    display: none;
  }
  ${buttonReset};
  position: fixed;
  right: -50px;
  top: 340px;
  z-index: 1001;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: var(--main-light-orange);
  border-radius: 6px 6px 0 0;
  color: var(--main-orange);
  transition: all 0.3s;
  ${(props) =>
    props.isSidebarHidden
      ? css`
          transform: translateX(0) rotate(-90deg);
        `
      : css`
          transform: translateX(-564px) rotate(-90deg);
        `}
  svg {
    margin-right: 8px;
  }
`;

export const SidebarWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 564px;
  transition: all 0.3s;
  background: var(--main-white);
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1001;
  overflow-y: auto;

  ${(props) =>
    props.isVisible
      ? css`
          transform: translateX(100%);
        `
      : css`
          transform: translateX(0);
        `}// @media ${device.mobile} {
  //   display: none;
  // }
`;

export const Inner = styled.div`
  max-width: 462px;
  padding: 20px 0 20px 32px;
  @media ${device.mobile} {
    padding: 70px 16px 20px;
  }
`;

export const MapWrapper = styled.div`
  height: 190px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 16px;
`;

export const SidebarBackground = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(31, 49, 36, 0.4);
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s;
  ${(props) =>
    props.setIsSidebarHidden
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 1;
          pointer-events: all;
        `}
  @media ${device.mobile} {
    display: none;
  }
`;
