export const HeartIcon2 = () => {
  return (
    <svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.7 1C16.87 1 19 4.04773 19 6.89091C19 12.6489 10.16 17.3636 10 17.3636C9.84 17.3636 1 12.6489 1 6.89091C1 4.04773 3.13 1 6.3 1C8.12 1 9.31 1.93068 10 2.74886C10.69 1.93068 11.88 1 13.7 1Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
