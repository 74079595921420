import React from 'react';

import {
  MotorVehiclesStyled,
  ControlsSecond,
  SortBy,
  Tag,
  TagsSection,
  Controls,
  ControlsMobile,
  TopSectionMobile
} from './motor-vehicles-styled';

import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';
import CategoriesCard from '../../../../../components/categories-card/categories-card';

import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import miles from '../../../../../assets/miles.svg';
import year from '../../../../../assets/year.svg';
import tagCheckmark from '../../../../../assets/tag-checkmark-icon.svg';
import {
  ButtonMobile,
  FilterButton
} from '../../../../company-page/components/products/products-styled';
import filter from '../../../../../assets/filter.svg';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import { CARS } from '../../../../../dummy-data/cars';
import {
  CardsSection,
  PaginationWrapper
} from '../../../../categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import Pagination from '../../../../../components/pagination/pagination';
import { LoadMore } from '../../../../../components/load-more/load-more';
import { ResultsPerPage } from '../../../../../components/results-per-page/results-per-page';
import { FilterByBuyType } from '../../../../../components/filter-by-buy-type/filter-by-buy-type';
import { Tags } from '../../../../../components/categories-top-filter/components/tags/tags';
import {
  NearMeBtnWrapper,
  NearMeDesktopText,
  NearMeMobileText
} from '../../../../../components/categories-top-filter/categories-top-filter-styled';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { ReactComponent as Pin } from '../../../../categories-real-estate/components/main/real-estate/pin.svg';
import { viewType } from '../../../../../components/view-selection/view-selection';

export const CARDS = CARS;

const MotorVehicles = ({ onFilterBtnClick, currentViewType }) => {
  return (
    <MotorVehiclesStyled>
      <Controls>
        <Tags />
        <ResultsPerPage />
      </Controls>
      <ControlsMobile>
        <TopSectionMobile>
          <FilterButton onClick={onFilterBtnClick}>
            <img src={filter} alt='' />
            Filters
          </FilterButton>
          <NearMeBtnWrapper>
            <GlobalButton type={'orange-secondary'} onClick={() => {}}>
              <Pin />
              <NearMeDesktopText>Find products near you</NearMeDesktopText>
              <NearMeMobileText>Near you</NearMeMobileText>
            </GlobalButton>
          </NearMeBtnWrapper>
          <ButtonMobile>
            <SvgIcon name='sort' />
            <p>Sort</p>
          </ButtonMobile>
        </TopSectionMobile>

        <FilterByBuyType />
      </ControlsMobile>
      <CardsSection>
        {CARDS.map((data, index) => (
          <CategoriesCard
            key={index}
            product={data}
            landScape={currentViewType === viewType.list}
          />
        ))}
      </CardsSection>
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
    </MotorVehiclesStyled>
  );
};

export default MotorVehicles;
