import styled, { css } from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const TextStyle = css`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
`;

export const BackgroundStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const BackgroundImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  max-width: 100%;
  @media ${device.tablet} {
    width: 100%;
    height: 345px;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const BackgroundContent = styled.div`
  position: absolute;
  top: 26%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  ${TextStyle};
  font-size: 50px;
  line-height: 70px;
  @media ${device.tablet} {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  ${TextStyle};
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const Button = styled.div`
  ${TextStyle};
  display: flex;
  align-items: center;
  background: var(--main-orange);
  border-radius: 6px;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 22px 8px 18px;
  color: #ffffff;
  cursor: pointer;
  svg {
    margin-right: 13px;
  }
`;
