import styled from 'styled-components';
import { ulReset } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  background: #ffffff;
  /* new */

  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 16px;
  padding: 32px;

  @media ${device.mobile} {
    box-shadow: none;
    background: transparent;
    padding: 0;
  }
`;

export const Block = styled.div`
  & + & {
    border-top: 1px solid var(--main-gray-2);
    margin-top: 32px;
    padding-top: 32px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 600;
`;

export const List = styled.ul`
  ${ulReset};
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 12px;
  }
`;

export const ItemName = styled.div`
  font-family: 'Lato', sans-serif;
`;

export const ItemValue = styled.div`
  text-align: right;
  font-weight: 600;
`;
