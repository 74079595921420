export const InstagramIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_12719)'>
        <path
          d='M12.75 1.5H5.25C3.17893 1.5 1.5 3.17893 1.5 5.25V12.75C1.5 14.8211 3.17893 16.5 5.25 16.5H12.75C14.8211 16.5 16.5 14.8211 16.5 12.75V5.25C16.5 3.17893 14.8211 1.5 12.75 1.5Z'
          stroke='#388638'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.9988 8.52675C12.0914 9.15093 11.9847 9.78841 11.6941 10.3485C11.4035 10.9086 10.9437 11.3628 10.38 11.6465C9.81638 11.9302 9.17764 12.0289 8.55464 11.9287C7.93165 11.8285 7.35612 11.5343 6.90993 11.0881C6.46374 10.6419 6.1696 10.0664 6.06935 9.44341C5.96911 8.82041 6.06785 8.18167 6.35155 7.61803C6.63524 7.05439 7.08944 6.59456 7.64954 6.30393C8.20964 6.01331 8.84712 5.90669 9.4713 5.99925C10.108 6.09366 10.6974 6.39035 11.1526 6.84548C11.6077 7.30061 11.9044 7.89006 11.9988 8.52675Z'
          stroke='#388638'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.125 4.875H13.1325'
          stroke='#388638'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_12719'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
