import { useState } from 'react';
import { ReactComponent as CloseIco } from '../../../../../assets/icons/close.svg';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { GlobalInputSelect } from '../../../../../components/global-input-select/global-input-select';
import { GlobalInput } from '../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../components/global-select/global-select';
import { InputGroup } from '../../../../../components/input-group/input-group';
import Table from '../../../../../components/table/table';
import Checkbox from '../../../../../components/checkbox/checkbox';
import { columnsData, columnsRows } from './mock';

import {
  BoxClose,
  ButtonsWrapper,
  CheckboxesGroup,
  CheckboxWrapper,
  InputGroupWrapper,
  Main,
  Orders,
  SecondTitle,
  Section,
  SectionSubtitle,
  Title,
  Wrapper
} from './create-new-order-styled.jsx';

import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as SaveIcon } from './icons/save.svg';
import { ReactComponent as XSquareIcon } from './icons/x-square.svg';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

const checkboxMock = {
  paymentMethodChecked: true,
  deliveryMethodChecked: false
};
export const CreateNewOrder = ({ closeOrderForm }) => {
  const [checkboxes, setCheckboxes] = useState(checkboxMock);
  return (
    <>
      <Title>
        Create New Order
        <BoxClose onClick={() => closeOrderForm(false)}>
          <CloseIco />
        </BoxClose>
      </Title>
      <Wrapper>
        <Main>
          <Section>
            <SecondTitle>Customer Billing Details</SecondTitle>
            <InputGroupWrapper>
              <InputGroup label={'name'}>
                <GlobalInput placeholder={'Enter the name '} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'email'}>
                <GlobalInput placeholder={'Enter your email '} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'WhatsApp number'}>
                <GlobalInputSelect placeholder={'Your WhatsApp Number '} />
              </InputGroup>
            </InputGroupWrapper>

            <SectionSubtitle>Physical Address</SectionSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street Address'}>
                <GlobalInput placeholder={'Enter your streer address'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'City / town'}>
                <GlobalInput placeholder={'Enter your city or town'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput placeholder={'Enter your country'} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SecondTitle>Order Details</SecondTitle>

            <InputGroupWrapper>
              <InputGroup label={'Order status'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'Payment method'}>
                <CheckboxesGroup>
                  <CheckboxWrapper green={true}>
                    <Checkbox
                      id={1}
                      label={'Selected'}
                      checked={checkboxes.paymentMethodChecked}
                      onChange={(e) => setCheckboxes({ ...checkboxes, paymentMethodChecked: true })}
                      color={'#ffffff'}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper grey={true}>
                    <Checkbox
                      id={2}
                      label={'Not selected'}
                      checked={!checkboxes.paymentMethodChecked}
                      onChange={(e) =>
                        setCheckboxes({ ...checkboxes, paymentMethodChecked: false })
                      }
                      color={'#ffffff'}
                    />
                  </CheckboxWrapper>
                </CheckboxesGroup>
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'Delivery method'}>
                <CheckboxesGroup>
                  <CheckboxWrapper green={true}>
                    <Checkbox
                      id={1}
                      label={'Selected'}
                      checked={checkboxes.deliveryMethodChecked === true}
                      onChange={(e) =>
                        setCheckboxes({ ...checkboxes, deliveryMethodChecked: true })
                      }
                      color={'#ffffff'}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper grey={true}>
                    <Checkbox
                      id={2}
                      label={'Not selected'}
                      checked={checkboxes.deliveryMethodChecked !== true}
                      onChange={(e) =>
                        setCheckboxes({ ...checkboxes, deliveryMethodChecked: false })
                      }
                      color={'#ffffff'}
                    />
                  </CheckboxWrapper>
                </CheckboxesGroup>
              </InputGroup>
            </InputGroupWrapper>

            <SectionSubtitle>Delivery Address</SectionSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street Address'}>
                <GlobalInput placeholder={'Enter your streer address'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'City / town'}>
                <GlobalInput placeholder={'Enter your city or town'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput placeholder={'Enter your country'} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SecondTitle>Order Items</SecondTitle>
            <InputGroupWrapper>
              <InputGroup label={'delivery method'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
            <Orders>
              <Table columnsData={columnsData} columnsRows={columnsRows} />
            </Orders>
          </Section>
          <ButtonsWrapper>
            <GlobalButton type='primary'>
              <PlusIcon />
              Create Order
            </GlobalButton>
            <div>
              <GlobalButton type='orange-secondary'>
                <SaveIcon />
                Save Draft
              </GlobalButton>
              <GlobalButton type='gray'>
                <XSquareIcon />
                Cancel Order
              </GlobalButton>
            </div>
          </ButtonsWrapper>
        </Main>
      </Wrapper>
    </>
  );
};
