import styled from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';
import { scrollbarStyles } from '../../../../../../assets/style-helpers/style-helpers';

export const CartPopupBlockStyled = styled.div`
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  padding: 24px 12px 24px 24px;
  @media ${device.mobile} {
    padding: 0;
  }

  & + & {
    margin-top: 24px;
  }
`;

export const ProductsList = styled.div`
  margin: 16px 0;
  max-height: 230px;
  overflow-y: auto;
  padding-right: 12px;

  ${scrollbarStyles};
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  line-height: 26px;
  margin: 14px 0;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    padding-left: 15px;
    padding-right: 15px;
    flex-basis: calc(50% - 8px);
    svg {
      margin-right: 8px;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;

    button + button {
      margin-top: 16px;
    }
  }
`;
