import { SectionHeader } from 'components/section-header/section-header';
import { Container } from './our-stores-styled';
import Filters from '../filters/filters';
import { StoresSection } from '../stores-section/stores-section';
import storeImg from '../../../store-finder/components/search-results/img/store-temp-img.png';
import storeLogo from '../../../store-finder/components/search-results/img/store-temp-logo.png';
import { useState } from 'react';

const mock = [
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -19.23878101020672,
      lng: 29.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  }
];

export const OurStores = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  return (
    <>
      <SectionHeader title={'Our Stores'} />
      <Container>
        <Filters
          isMobileFilterVisible={isMobileFilterVisible}
          setMobileFilterVisible={setMobileFilterVisible}
        />
        <StoresSection
          cards={mock}
          onFilterBtnClick={() => {
            setMobileFilterVisible(true);
          }}
        />
      </Container>
    </>
  );
};
