export const BellIcon = () => {
  return (
    <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.88068 16.2C6.4455 16.6979 7.18742 17 8 17C8.81258 17 9.5545 16.6979 10.1193 16.2M12.806 5.8C12.806 4.52696 12.2997 3.30606 11.3984 2.40589C10.4971 1.50571 9.27464 1 8 1C6.72536 1 5.50293 1.50571 4.60163 2.40589C3.70032 3.30606 3.19398 4.52696 3.19398 5.8C3.19398 8.27215 2.56957 9.96477 1.87205 11.0843C1.28368 12.0287 0.989499 12.5009 1.00029 12.6326C1.01223 12.7785 1.04317 12.8341 1.16085 12.9213C1.26713 13 1.74623 13 2.70445 13H13.2956C14.2538 13 14.7329 13 14.8392 12.9213C14.9568 12.8341 14.9878 12.7785 14.9997 12.6326C15.0105 12.5009 14.7163 12.0287 14.1279 11.0843C13.4304 9.96477 12.806 8.27215 12.806 5.8Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
