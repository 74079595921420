import styled, { css } from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

const commonStyle = styled.button`
  ${buttonReset};
  border-radius: 6px;
  color: var(--main-white);
  padding: 18px 24px;
  line-height: 24px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  font-weight: 600;
`;

export const StyledButton = styled(commonStyle)`
  padding: 8px 20px;
  height: ${(props) => `${props.height}px`};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  background: var(--main-green);
  &:hover {
    background: var(--main-green);
  }

  svg,
  img {
    margin-right: 12px;
  }
`;

export const StyledGreenButton = styled(commonStyle)`
  padding: 8px 20px;
  height: ${(props) => `${props.height}px`};
  background: var(--main-green);

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.checked &&
    css`
      background: var(--main-orange);
    `}

  svg, img {
    margin-right: 12px;
  }
`;

export const StyledOrangeButton = styled(commonStyle)`
  padding: 8px 20px;
  height: ${(props) => `${props.height}px`};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  background: var(--main-orange);
  &:hover {
    background: var(--main-orange);
  }

  svg,
  img {
    margin-right: 12px;
  }
`;

export const StyledGrayButton = styled(commonStyle)`
  padding: 8px 20px;
  height: ${(props) => `${props.height}px`};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  background: #F7F7F7;
  color: var(--main-subtext);
  &:hover {
  }

  svg,
  img {
    margin-right: 12px;
  }
`;

export const StyledOrangeSecondaryButton = styled(commonStyle)`
  padding: 8px 20px;
  height: ${(props) => `${props.height}px`};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  color: var(--main-orange);
  ${(props) =>
    props.checked
      ? css`
          background: var(--main-orange);
          color: var(--main-white);
        `
      : css`
          background: rgba(247, 109, 33, 0.1);
        `}
  &:hover {
    background: var(--main-orange);
    color: var(--main-white);
  }

  svg,
  img {
    margin-right: 12px;
  }
`;

export const StyledSecondaryButton = styled(commonStyle)`
  background: rgba(56, 134, 56, 0.1);
  color: var(--main-green);
  padding: 8px 20px;

  height: ${(props) => `${props.height}px`};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &:hover {
    background: #e3f1e5;
    color: var(--main-green);
  }

  svg,
  img {
    margin-right: 12px;
  }
`;

export const StyledPrimaryRoundButton = styled(StyledButton)`
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.height}px`};
  padding: 0;
  border-radius: 50%;
  svg,
  img {
    margin-right: 0;
  }
`;

export const TransparentButton = styled(StyledButton)`
  background: transparent;
  color: var(--main-green);
  &:hover {
    color: var(--main-white);
  }
`;
