import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  align-items: center;
`;

export const Reviews = styled.div`
  color: var(--main-subtext);
  @media ${device.tablet} {
    display: none;
  }
`;

export const StarsWrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: ${(props) => (props.isMobileMinified ? 'none' : 'flex')};
  }
`;

export const Rate = styled.div`
  margin-left: 8px;
  margin-right: 6px;
`;

export const StarsWrapperMobile = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;
