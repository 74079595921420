export const ElectronicIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 13.7778L21 13.7778M8.4 20H15.6M7.32 17.3333H16.68C18.1921 17.3333 18.9482 17.3333 19.5258 17.0427C20.0338 16.787 20.4469 16.3791 20.7057 15.8773C21 15.3069 21 14.5601 21 13.0667V8.26667C21 6.77319 21 6.02646 20.7057 5.45603C20.4469 4.95426 20.0338 4.54631 19.5258 4.29065C18.9482 4 18.1921 4 16.68 4H7.32C5.80786 4 5.05179 4 4.47423 4.29065C3.96619 4.54631 3.55314 4.95426 3.29428 5.45603C3 6.02646 3 6.77319 3 8.26667V13.0667C3 14.5601 3 15.3069 3.29428 15.8773C3.55314 16.3791 3.96619 16.787 4.47423 17.0427C5.05179 17.3333 5.80786 17.3333 7.32 17.3333Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
