import styled, { css } from 'styled-components';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';
import { title20 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 620px;
  background: var(--main-white);
  z-index: 900;
  padding-top: 134px;
  overflow-y: auto;
  transition: all 0.3s;
  @media ${device.tablet} {
    width: 100%;
  }
  ${(props) =>
    props.isVisible
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(100%);
        `}
`;
export const Inner = styled.div`
  max-width: 454px;
  width: 100%;
  padding: 40px 0 40px 32px;
  @media ${device.tablet} {
    padding: 20px;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  ${title20};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const CloseBtn = styled.button`
  ${buttonReset};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    margin-bottom: 22px;
  }
`;

export const MobileBackBtnText = styled.div`
  display: none;
  align-items: center;
  color: var(--main-green);
  @media ${device.tablet} {
    display: flex;
  }
  svg {
    margin-right: 6px;
  }
`;

export const CloseBtnIconWrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;

export const CardsWrapper = styled.div`
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
