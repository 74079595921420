export const ClockIcon = () => {
  return (
    <svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 9.02778L17.1731 10.9167L15.3452 9.02778M17.3966 10.4444C17.4297 10.1344 17.4467 9.81923 17.4467 9.5C17.4467 4.80558 13.765 1 9.22337 1C4.68173 1 1 4.80558 1 9.5C1 14.1944 4.68173 18 9.22337 18C11.8067 18 14.1117 16.7688 15.6193 14.843M9.22337 4.77778V9.5L11.9645 11.3889'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
