import React, { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  MobileSearch,
  DropdownsWrapper,
  LeftSide,
  FilterButton,
  Content,
  SidebarTitle,
  SecondSection,
  TextInfo,
  Social,
  HorizontalLine,
  BottomTotal,
  TotalAmount,
  Tag,
  TagsWrapper,
  AddCustomerButton
} from './all-customers-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import Table from '../../../../components/table/table';
import { columnsData, columnsRows, Tags, SidebarColumnsData, SidebarColumnsRows } from './mock';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import filter from '../../../../assets/filter.svg';
import Sidebar from '../../../../components/sidebar/sidebar';
import whatsappIcon from '../../../../assets/whatsapp.svg';
import phoneIcon from '../../../../assets/phone_white.svg';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Customers' },
  { link: '#', name: 'All Customers' }
];

const DropdownItems = ['Location', 'Customer List', 'Delivery Method'];

const CustomerList = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Customers</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
          </LeftSide>
          <AddCustomerButton>
            <GlobalButton height={40} type='secondary'>
              <SvgIcon name={'plus-second'} />
              <p>Add customer</p>
            </GlobalButton>
          </AddCustomerButton>
        </Navigation>
        <Table
          columnsData={columnsData}
          columnsRows={columnsRows}
          hasCheckbox
          onClick={() => setShowSidebar(true)}
        />
        <Sidebar
          title={'Langton Shoriwa'}
          width={'900px'}
          showSidebar={showSidebar}
          onClose={() => setShowSidebar(false)}
          onCloseText={'Back to All Customers'}
        >
          <Content>
            <SecondSection>
              <SidebarTitle>Customer Details</SidebarTitle>
              <TextInfo>
                <p>Name</p>
                <h5>Langton Showira</h5>
              </TextInfo>
              <TextInfo>
                <p>Phone Number</p>
                <h5>+380 66 12 23 123</h5>
              </TextInfo>
              <TextInfo>
                <p>Email</p>
                <h5>langtonshoriwa@gmail.com</h5>
              </TextInfo>
              <TextInfo>
                <p>Customer Lists</p>
                <TagsWrapper>
                  {Tags.map((data, index) => {
                    return <Tag key={index}>{data}</Tag>;
                  })}
                </TagsWrapper>
              </TextInfo>
              <Social>
                <div className='whatsapp'>
                  <img src={whatsappIcon} alt='' />
                </div>
                <div className='phone'>
                  <img src={phoneIcon} alt='' />
                </div>
              </Social>
              <HorizontalLine />
              <SidebarTitle>Orders</SidebarTitle>
              <TextInfo>
                <p>Number of orders</p>
                <h5>52</h5>
              </TextInfo>
              <TextInfo>
                <p>Total Spent</p>
                <h5>$120 000 000.99</h5>
              </TextInfo>
              <HorizontalLine />
              <SidebarTitle>Order History</SidebarTitle>
              <Table
                columnsData={SidebarColumnsData}
                columnsRows={SidebarColumnsRows}
                minWidth={'640px'}
              />
              <BottomTotal>
                <TotalAmount>Total Amount</TotalAmount>
                <SidebarTitle>$600 000 000</SidebarTitle>
              </BottomTotal>
            </SecondSection>
          </Content>
        </Sidebar>
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default CustomerList;
