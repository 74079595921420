import React from 'react';
import {
  AvatarWrapper,
  BlackReviewsItem,
  Content,
  GreenReviewsItem,
  Info,
  InfoCategory,
  InfoMain,
  InfoTitle,
  RateWrapper,
  Reviews,
  TextareaWrapper,
  Title
} from './feedback-popup-styled';

import avatar from 'assets/temp-img/feedback-avatar.png';
import { RateStars } from 'components/rate-stars/rate-stars';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { ReviewWidget } from 'components/review-widget/review-widget';
import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';

export const FeedbackPopup = ({ isFeedbackPopupVisible, onClose }) => {
  return (
    <ModalWrapper isVisible={isFeedbackPopupVisible} onClose={onClose}>
      <Content>
        <Info>
          <AvatarWrapper>
            <img src={avatar} alt='' />
          </AvatarWrapper>
          <InfoMain>
            <InfoTitle>So & So Plumbing</InfoTitle>
            <InfoCategory>Trades & Services</InfoCategory>
            <RateWrapper>
              <RateStars height={17} width={17} rate={4.5} isRateCounter={false} />
              <Reviews>
                <GreenReviewsItem>10 Reviews</GreenReviewsItem>
                <BlackReviewsItem>25 Clients Served</BlackReviewsItem>
              </Reviews>
            </RateWrapper>
          </InfoMain>
        </Info>
        <Title>Shop Review</Title>
        <ReviewWidget />
        <TextareaWrapper>
          <GlobalTextarea placeholder={'Write your review here'} />
        </TextareaWrapper>
        <GlobalButton
          height={40}
          onClick={() => {
            onClose();
          }}
        >
          <SvgIcon name='notifications-text' />
          Add review
        </GlobalButton>
      </Content>
    </ModalWrapper>
  );
};
