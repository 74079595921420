import banner from './banner.jpg';
import logo from './logo.jpg';

import photo from './photo.jpg';

export const RealEstateStoreMock = {
  img: banner,
  logo: logo,
  name: 'Properties',
  type: 'Real Estate',
  rate: 4.5,
  reviews: 50,
  propertiesSold: 25,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ',
  address: '33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD',
  openTime: [
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    'Closed'
  ]
};

export const estateProductsMock = [
  {
    imgs: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/200',
      'https://picsum.photos/400/300'
    ],
    title: 'Master Duplex Master Duplex Master Duplex Master Duplex',
    location: 'Harare SBD',
    price: '$1 200 000',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet.',
    logo: '',
    agent: {
      img: photo,
      name: 'Langton Shoriwa',
      role: 'Property Agent'
    }
  },
  {
    imgs: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/200',
      'https://picsum.photos/400/300'
    ],
    title: 'Master Duplex Master Duplex Master Duplex Master Duplex',
    location: 'Harare SBD',
    price: '$1 200 000',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet.',
    logo: '',
    agent: {
      img: photo,
      name: 'Langton Shoriwa',
      role: 'Property Agent'
    }
  },
  {
    imgs: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/200',
      'https://picsum.photos/400/300'
    ],
    title: 'Master Duplex Master Duplex Master Duplex Master Duplex',
    location: 'Harare SBD',
    price: '$1 200 000',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet.',
    logo: '',
    agent: {
      img: photo,
      name: 'Langton Shoriwa',
      role: 'Property Agent'
    }
  },
  {
    imgs: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/200',
      'https://picsum.photos/400/300'
    ],
    title: 'Master Duplex Master Duplex Master Duplex Master Duplex',
    location: 'Harare SBD',
    price: '$1 200 000',
    rate: 3.5,
    reviews: 51,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet.',
    logo: '',
    agent: {
      img: photo,
      name: 'Langton Shoriwa',
      role: 'Property Agent'
    }
  }
];
