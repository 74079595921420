import React from 'react';
import { usePagination, DOTS } from './usePagination';
import { ArrowLeft, ArrowRight, Number, PaginationWrapper } from './pagination-styled';
import arrowLeft from '../../assets/arrow-pagination.svg';
import arrowRight from '../../assets/arrow-right.svg';

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationWrapper>
      <ArrowLeft firstPage={currentPage === 1} onClick={onPrevious}>
        <img src={arrowLeft} alt='' />
      </ArrowLeft>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <Number key={index}>&#8230;</Number>;
        }

        return (
          <Number
            currentPage={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
            key={index}
          >
            {pageNumber}
          </Number>
        );
      })}
      <ArrowRight lastPage={currentPage === lastPage} onClick={onNext}>
        <img src={arrowRight} alt='' />
      </ArrowRight>
    </PaginationWrapper>
  );
};

export default Pagination;
