import styled, { css } from 'styled-components';
import { title20 } from '../../../../assets/style-helpers/typography';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const CartPopupStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 620px;
  padding: 32px 103px 32px 32px;
  height: 100%;
  background: var(--main-white);
  overflow-y: auto;
  transition: all 0.3s;
  @media ${device.mobile} {
    padding: 15px;
    width: 100%;
  }

  ${(props) =>
    props.isOpened
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(100%);
        `}
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  ${title20};
`;

export const CloseBtn = styled.button`
  ${buttonReset};
`;

export const Main = styled.div``;
