import styled from 'styled-components';

export const ApproveSellerWrapper = styled.div`
  background: #fff;
  border-radius: 16px 16px 16px 0;
  padding: 3px 16px;
  color: var(--main-green);
`;

export const TippyIcon = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 10;
  cursor: pointer;
`;
