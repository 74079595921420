import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalSelect } from 'components/global-select/global-select';
import styled from 'styled-components';
import { buttonReset, LatoFont } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

const ServiceList = styled.div`
  margin-top: 18px;
`;

const ServiceItemStyled = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
    position: relative;
  }

  & + & {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--divider);
    @media ${device.mobile} {
      border: none;
      padding-top: 0;
    }
  }
`;

const ServiceItemName = styled.div`
  flex-grow: 1;
  padding-right: 8px;
  span {
    display: block;
    &:last-child {
      ${LatoFont};
      color: var(--secondary-text);
    }
  }
`;

const ServiceItemCol = styled.div`
  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:nth-child(2) {
    @media ${device.mobile} {
      border-radius: 12px 12px 0 0;
      background: #f7f7f7;
      padding: 16px 12px 8px;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: calc(100% - 24px);
        left: 12px;
        bottom: 0;
        z-index: 2;
        height: 1px;
        background: var(--divider);
      }
    }
  }
  &:nth-child(3) {
    @media ${device.mobile} {
      border-radius: 0 0 12px 12px;
      background: #f7f7f7;
      padding: 16px 12px 8px;
    }
  }
  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.mobile} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  & + & {
    margin-left: 40px;
    @media ${device.mobile} {
      margin-left: 0;
    }
  }
`;

const ServiceItemColTitle = styled.div`
  color: var(--main-gray-3);
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  margin-bottom: 10px;
`;

const ServiceItemColValue = styled.div`
  ${LatoFont};
  span {
    padding: 4px 12px;
  }
  input {
    max-width: 66px;
    border: 1px solid var(--divider);
    padding: 4px 12px;
    border-radius: 6px;
  }
`;

const DeleteBtn = styled.button`
  ${buttonReset};
`;

const ServiceItem = () => {
  return (
    <ServiceItemStyled>
      <ServiceItemName>
        <span>Tax Clearance Application</span>
        <span>Lorem ipsum dolor sit amet consecte dolor sit amet</span>
      </ServiceItemName>
      <ServiceItemCol>
        <ServiceItemColTitle>normal price</ServiceItemColTitle>
        <ServiceItemColValue>
          <span>$2000</span>
        </ServiceItemColValue>
      </ServiceItemCol>
      <ServiceItemCol>
        <ServiceItemColTitle>Offer price</ServiceItemColTitle>
        <ServiceItemColValue>
          <input value={'$2000'} />
        </ServiceItemColValue>
      </ServiceItemCol>
      <ServiceItemCol>
        <DeleteBtn>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.1663 5.83301L5.83301 14.1663M5.83301 5.83301L14.1663 14.1663'
              stroke='#ACADB9'
              strokeWidth='1.6'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </DeleteBtn>
      </ServiceItemCol>
    </ServiceItemStyled>
  );
};

export const AdditionalServices = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Additional Services</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'Additional Services'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <ServiceList>
        <ServiceItem />
        <ServiceItem />
        <ServiceItem />
      </ServiceList>
    </Section>
  );
};
