import { Container } from './featured-categories-styled';
import { SectionHeader } from '../../../../components/section-header/section-header';
import { FeaturesCategoriesCards } from '../../../../components/featured-categories-cards/features-categories-cards';

export const FeaturedCategories = () => {
  return (
    <Container>
      <SectionHeader title={'Featured Categories'} />
      <FeaturesCategoriesCards />
    </Container>
  );
};
