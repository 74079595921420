import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { Container, Union } from './categories-clothing-apparel-styled';
import Main from './components/main/main';
import { GlobalFilter } from '../../components/global-filter/global-filter';
import React, { useState } from 'react';
import { BreadcrumbsContainer } from '../categories-real-estate/categories-real-estate-styled';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Clothing & Apparel' }
];

const CategoriesClothingApparel = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main onFilterBtnClick={() => setMobileFilterVisible(!isMobileFilterVisible)} />
      </Container>
    </>
  );
};

export default CategoriesClothingApparel;
