import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--main-gray-2);
`;

export const ImgWrapper = styled.div`
  height: 220px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Content = styled.div`
  padding: 20px 20px 24px;
`;

export const Title = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 14px;
`;

export const Price = styled.div`
  font-size: 20px;
  line-height: 24px;
`;
