import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const MainStyled = styled.section`
  width: 100%;
  min-width: 0;
`;

export const Container = styled.div`
  max-width: 100%;
  min-width: 0;
  height: 100%;

  .swiper {
    height: 100%;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    gap: 9px;
    z-index: 5;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    background: #388638;
  }
`;
