import React from 'react';

import {
  BackgroundContent,
  BackgroundStyled,
  Title,
  SubTitle,
  Button,
  BackgroundImage
} from './background-styled';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';

import background from '../../../../../assets/categories_real_estate_background.png';

export const Background = ({ title, subtitle, buttonText }) => {
  return (
    <BackgroundStyled>
      <BackgroundImage src={background} alt='' />
      <BackgroundContent>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Button>
          <SvgIcon name='phone' />
          {buttonText}
        </Button>
      </BackgroundContent>
    </BackgroundStyled>
  );
};

export default Background;
