import Header from '../../components/header/header';
import {
  Container,
  Products,
  Title
} from '../single-product-general/single-product-general-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { ProductGallery } from '../../components/product-gallery/product-gallery';
import { ProductInfoLayout } from '../../components/product-info-layout/product-info-layout';
import { ProductDetails } from '../../components/product-info/components/product-details/product-details';
import { ProductsSlider } from '../../components/products-slider/products-slider';
import img1 from '../../assets/temp-img/temp4.png';
import img2 from '../../assets/temp-img/temp5.png';
import img3 from '../../assets/temp-img/temp6.png';
import { PCard, ProductCardTypes } from '../../components/p-card/p-card';

import { SponsoredBanners } from '../single-product-general/components/sponsored-banners/sponsored-banners';
import { AuctionSideBanner } from './components/auction-side-banner';
import { ProductInfoAuctionGeneral } from '../../components/product-info/product-info-auction-general';
import { Sidebar } from '../single-product-general/components/sidebar/sidebar';
import { useState } from 'react';

const dealsMock = [
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.8,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img2,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.8,
    reviews: 28,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    rate: 4.1,
    reviews: 30,
    old_price: '$19.99',
    price: '$10.00'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90'
  }
];

export const SingleProductAuction = ({ status }) => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs />
        <ProductInfoLayout>
          <ProductGallery />
          <SponsoredBanners />
          <ProductInfoAuctionGeneral
            status={status}
            onContactSellerBtnClick={() => {
              setIsSidebarHidden(false);
            }}
          />
          <ProductDetails reviewsTitle={'Seller Reviews'} attributesTitle={'Product Attributes'} />
          <AuctionSideBanner />
        </ProductInfoLayout>
      </Container>
      <Products>
        <Container>
          <Title>Related Products</Title>
        </Container>
        <ProductsSlider>
          {dealsMock.map((product, index) => {
            return <PCard product={product} type={ProductCardTypes.BASIC} key={index} />;
          })}
        </ProductsSlider>
      </Products>
      <Sidebar isSidebarHidden={isSidebarHidden} setIsSidebarHidden={setIsSidebarHidden} />
    </>
  );
};
