import styled, { css } from 'styled-components';
import { paragraph30 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  ${mainContainer};
  margin-top: 80px;
  margin-bottom: 80px;

  .slick-slider {
    display: flex;
    align-items: center;
    padding: 0 100px;

    @media ${device.mobile} {
      padding: 0;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 24px;
  color: #3664f6;
  background-color: rgba(54, 100, 246, 0.1);
  border-radius: 6px;
  border: none;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
    margin-right: 14px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 30px 0;
`;

export const SliderSection = styled.div`
  width: 100%;
  display: block;
  //border-bottom: 1px solid #E2E5EB;
  margin: 25px 0 30px 0;

  @media ${device.mobile} {
    display: none;
  }
`;

export const SliderSectionMobile = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    .swiper {
      padding-bottom: 32px;
    }
    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      gap: 9px;
      z-index: 5;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #f5f7fb;
    }

    .swiper-pagination-bullet-active {
      background: #388638;
    }
  }
`;

export const FadeOutContainer = styled.div`
  position: relative;
  &::after {
    position: absolute;
    height: 100%;
    width: 90px;
    background: linear-gradient(271.2deg, #ffffff 0.91%, rgba(255, 255, 255, 0) 98.87%);
    opacity: 0.6;
    top: 0;
    right: 0;
    content: '';
    z-index: 5;
    pointer-events: none;
  }
`;

export const ArrowsStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #c6d2e9;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: var(--main-green);
    img {
      filter: brightness(100);
    }
  }
`;

export const ArrowLeft = styled.div`
  ${ArrowsStyles};
  position: absolute;
  left: 0;
`;

export const ArrowRight = styled.div`
  ${ArrowsStyles};
  position: absolute;
  right: 0;
  img {
    transform: rotate(180deg);
  }
`;
