import styled from 'styled-components';
import { device } from 'assets/style-helpers/breakpoints';

export const TagsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;

  @media ${device.tablet} {
    padding-left: 0px;
    padding-bottom: 5px;
    &::-webkit-scrollbar {
      width: 1px;
      background: transparent;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(55 134 56 / 47%);
    }
  }

  @media ${device.mobile} {
    flex-wrap: nowrap;
    padding-left: 15px;
  }

  a + a {
    @media ${device.tablet} {
      margin-left: 14.5px;
    }
    @media ${device.mobile} {
      margin-left: 8px;
    }
  }

  a {
    font-size: 14px;
    color: var(--main-text);
    font-family: 'Poppins', sans-serif;
    position: relative;
    padding: 6px 16px;
    font-weight: 600;
    border: 1px solid rgba(56, 134, 56, 0.3);
    border-radius: 20px;
    @media ${device.tablet} {
      white-space: nowrap;
      font-size: 12px;
    }
    @media ${device.mobile} {
      white-space: nowrap;
      font-size: 12px;
    }
  }
`;
