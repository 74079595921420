import styled from 'styled-components';

export const Container = styled.div`
  width: 580px;
  max-width: 100%;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
`;

export const Section = styled.div`
  padding: 24px;
  border-radius: 6px;
  background: #f9f9f9;

  & + & {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  svg {
    margin-right: 6px;
  }
`;

export const CheboxSectionWrapper = styled.div`
  display: flex;
  gap: 36px;
`;

export const CheckboxSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RadiosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Btns = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 20px;
  button {
    width: 100%;
  }
`;
