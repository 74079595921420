import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import { ReactComponent as Cancel } from '../../../../../../assets/cancel.svg';
import { ReactComponent as InfoIco } from '../../../../../../assets/info-ico.svg';
import { GlobalButton } from '../../../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { Box, Buttons, Content, Delete, Description, Title } from './delete-service-popup-styled';

export const DeleteServicePopup = ({ isFeedbackPopupVisible, onClose }) => {
  return (
    <ModalWrapper isVisible={isFeedbackPopupVisible} onClose={onClose}>
      <Content>
        <Box>
          <Title>Are you sure you want to delete this listed service?</Title>
          <Description>
            <InfoIco />
            This action can not be reversed
          </Description>
          <Buttons>
            <GlobalButton fullWidth height={40} type={'gray'} onClick={() => onClose()}>
              <Cancel />
              Cancel
            </GlobalButton>
            <Delete>
              <SvgIcon name={'delete'} />
              Delete Service
            </Delete>
          </Buttons>
        </Box>
      </Content>
    </ModalWrapper>
  );
};
