import { useState } from 'react';
import { Outlet } from 'react-router';
import { GlobalButton } from '../../components/global-button/global-button';
import Header from '../../components/header/header';
import { Sidebar } from './components/sidebar/sidebar';
import {
  Container,
  DashboardBtnWrapper,
  HelpAndTutorialButton,
  Main,
  NotificationIcon,
  SidebarWrapper
} from './dashboard-styled';
import { ReactComponent as BurgerIcon } from './img/burger.svg';
import notificationSidebarIcon from './components/notifications-sidebar/notification-sidebar-icon.svg';
import NotificationSidebar from './components/notifications-sidebar/notifications-sidebar';
import HelpAndTutorialsSidebar from './components/help-and-tutorials-sidebar/help-and-tutorials-sidebar';

export const Dashboard = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false);
  const [showHelpAndTutorialSidebar, setShowHelpAndTutorialSidebar] = useState(false);
  return (
    <>
      <Header />
      <Container>
        <SidebarWrapper isSidebarVisible={isSidebarVisible}>
          <Sidebar
            onCloseBtnClick={() => {
              setIsSidebarVisible(false);
            }}
          />
        </SidebarWrapper>
        <NotificationSidebar
          showSidebar={showNotificationSidebar}
          setShowSidebar={setShowNotificationSidebar}
        />
        <NotificationIcon
          src={notificationSidebarIcon}
          showNotificationSidebar={showNotificationSidebar}
          showHelpAndTutorialSidebar={showHelpAndTutorialSidebar}
          onClick={() => setShowNotificationSidebar(true)}
          alt=''
        />
        <HelpAndTutorialsSidebar
          showSidebar={showHelpAndTutorialSidebar}
          setShowSidebar={setShowHelpAndTutorialSidebar}
        />
        <HelpAndTutorialButton
          onClick={() => setShowHelpAndTutorialSidebar(true)}
          showHelpAndTutorialSidebar={showHelpAndTutorialSidebar}
          showNotificationSidebar={showNotificationSidebar}
        >
          Help & Tutorials
        </HelpAndTutorialButton>
        <Main>
          <DashboardBtnWrapper>
            <GlobalButton
              type={'orange-secondary'}
              onClick={() => {
                setIsSidebarVisible(true);
              }}
            >
              <BurgerIcon />
              Dashboard Menu
            </GlobalButton>
          </DashboardBtnWrapper>
          <Outlet />
        </Main>
      </Container>
    </>
  );
};
