import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../assets/style-helpers/style-helpers';
import { title20 } from '../../assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  border-radius: 16px;
  border: 2px solid rgba(52, 168, 82, 0.51);
  background: #e7f9e7;
  box-shadow: 0px 4px 60px 0px rgba(62, 73, 59, 0.06);
  padding: 24px 32px;
  position: relative;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const Content = styled.div`
  max-width: 420px;
`;

export const Title = styled.div`
  ${title20};
  margin-bottom: 4px;
  line-height: 30px;
`;

export const Text = styled.div`
  color: #5a5670;

  /* body */
  ${LatoFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 12px;
`;

export const Icon = styled.div`
  position: absolute;
  top: 30px;
  right: 32px;
  @media ${device.mobile} {
    position: static;
  }
`;

export const Btn = styled.button`
  ${buttonReset};
  border-radius: 6px;
  background: #fff;
  color: var(--main-green);
  padding: 8px 16px;
  line-height: 24px;
  font-weight: 600;
`;
