import styled from 'styled-components';
import { buttonReset } from '../../../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 12px;
  margin-top: 24px;
  padding: 24px;
`;

export const SliderWrapper = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 16px;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div``;

export const SliderControl = styled.div`
  display: flex;
  align-items: center;
`;

export const SliderBtn = styled.button`
  ${buttonReset};
  color: #29263a;
`;

export const SliderPrevBtn = styled(SliderBtn)`
  margin-right: 12px;
  svg {
    transform: rotate(180deg);
  }
`;

export const SliderNextBtn = styled(SliderBtn)``;
