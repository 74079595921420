import React from 'react';
import './input-with-label.scss';

import search from '../../assets/search.svg';

const InputWithLabel = ({
  value,
  onChange,
  wrapperClassName,
  inputClassName,
  width,
  height,
  placeholder,
  isLabel = true,
  name = 'Search in stores'
}) => {
  return (
    <section
      className={`${wrapperClassName} input-products`}
      style={{ width: width, height: height }}
    >
      {isLabel && (
        <div className='label'>
          <p className='name'>{name}</p>
        </div>
      )}

      <input
        id={'input'}
        className={`${inputClassName} `}
        type='text'
        value={value}
        onChange={onChange}
        style={{}}
        placeholder={placeholder}
      ></input>
      <div className='controls'>
        <div className='search-button'>
          <img src={search} alt='' />
        </div>
      </div>
    </section>
  );
};

export default InputWithLabel;
