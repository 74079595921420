import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { ResultsPerPage } from '../../../../components/results-per-page/results-per-page';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const Mob = styled(ResultsPerPage)`
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`;
