import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';
import { AdSpace } from '../../components/ad-space/ad-space';

export const Container = styled.div`
  ${mainContainer};
  overflow-x: hidden;
`;

export const TagsWrapper = styled.div`
  overflow-y: auto;
  margin-top: 8px;
`;

export const Title = styled.p`
  color: var(--main-text);
  font-family: Poppins, sans-serif;
  font-size: 58px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px; /* 120.69% */
  @media ${device.tablet} {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: unset;
  }
  @media ${device.mobile} {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: unset;
  }
`;

export const FeaturesCategoriesCardsWrapper = styled.div`
  margin: 36px 0 72px;
`;

export const CardsWithBanner = styled.div`
  display: flex;
  margin-bottom: 80px;
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const CardsWrapper = styled.div`
  flex-basis: calc(100% - 32px - 790px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;

    flex-basis: calc(100% - 24px - 630px);
    > div:last-child {
      display: none;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    flex-basis: calc(100% - 20px - 366px);
    > div:nth-child(3) {
      display: none;
    }
  }

  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const TitleWrapper = styled.div``;

export const BannerWrapper = styled.div`
  flex-basis: 790px;
  margin-left: 32px;
  @media ${device.laptop} {
    flex-basis: 630px;
    margin-left: 24px;
  }
  @media ${device.tablet} {
    flex-basis: 366px;
    margin-left: 20px;
  }
  @media ${device.mobile} {
    margin-left: 0;
    margin-bottom: 16px;
    flex-basis: 280px;
  }
`;

export const PopularCategoriesAdSpace = styled(AdSpace)`
  height: 160px;
  margin-bottom: 80px;
  border-radius: 0;
  @media ${device.mobile} {
    height: 200px;
  }
`;
