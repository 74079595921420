import React, { useCallback, useEffect, useRef } from 'react';
import { Container, LabelsWrapper } from './range-slider-styled';

export const RangeSlider = ({
  min,
  max,
  defaultMin,
  defaultMax,
  onChange,
  minVal,
  maxVal,
  children
}) => {
  const minValRef = useRef(minVal);
  const maxValRef = useRef(maxVal);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }

    const secondMinPercent = getPercent(minValRef.current);
    const secondMaxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${secondMaxPercent - secondMinPercent}%`;
    }

    if (defaultMin === minVal && defaultMax === maxVal) {
      range.current.style.left = '0%';
      range.current.style.width = '100%';
      minValRef.current = defaultMin;
      maxValRef.current = defaultMax;
    }
  }, [minVal, maxVal, defaultMin, defaultMax, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  return (
    <Container>
      <div className='price-container'>
        <input
          type='range'
          min={min}
          max={max}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1);
            onChange({ min: value, max: maxValRef.current });
            minValRef.current = value;
          }}
          className='thumb thumb--left'
          style={{ zIndex: minVal > max - 100 && '5' }}
        />
        <input
          type='range'
          min={min}
          max={max}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1);
            onChange({ min: minValRef.current, max: value });
            maxValRef.current = value;
          }}
          className='thumb thumb--right'
        />

        <div className='slider'>
          <div className='slider__track' />
          <div ref={range} className='slider__range' />
        </div>
      </div>
      {children && <LabelsWrapper>{children}</LabelsWrapper>}
    </Container>
  );
};
