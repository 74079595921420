import styled, { css } from 'styled-components';
import { buttonReset, headerContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const HeaderWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;

  .store-locator {
    display: flex;
  }
`;

export const CartButton = styled.button`
  ${buttonReset}
`;

export const HeaderStyled = styled.header`
  background-color: var(--main-white);
  padding: 15px 0;

  .category-dropdown {
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
    transition: all 0.3s;
    padding: 12px 20px;
    border-radius: 4px;

    svg {
      width: 18px;
      height: 18px;
    }

    &.opened {
      background: #ecf6ec;
    }
    @media (max-width: 1300px) {
      margin-right: 16px;
    }

    @media ${device.tablet} {
      order: 4;
      margin-top: 20px;
      &.opened {
        background: none;
        path {
          stroke: white;
        }
      }
    }

    @media ${device.tablet} {
      margin-right: 0;
      padding: 0;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 24px;
      color: #29263a;
      white-space: nowrap;

      @media ${device.tablet} {
        display: none;
      }
    }
  }

  .logo-wrapper {
    @media ${device.tablet} {
      order: 1;
    }
  }

  .preferences {
    display: flex;
    align-items: center;
    margin-right: 45px;

    @media (max-width: 1300px) {
      margin-right: 16px;
    }

    @media ${device.tablet} {
      order: 2;
      flex-basis: calc(100% - 320px);
      justify-content: flex-end;
      margin-right: 0;
      &--loggedin {
        flex-basis: calc(100% - 170px);
      }
    }

    &__item {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--main-text);
      &::before {
        content: '';
        background: #e8e8e8;
        width: 1px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;
      }

      &--account {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .preferences__item:not(:first-child) + .preferences__item {
    margin-left: 21px;
    padding-left: 21px;
    &::before {
      display: block;
    }
  }

  .preferences__item + .preferences__item {
    @media ${device.tablet} {
      margin-left: 16px;
      padding-left: 16px;
      &::before {
        display: block;
      }
    }
    @media ${device.tablet} {
      margin-left: 12px;
      padding-left: 12px;
      &::before {
        display: block;
      }
    }
  }
`;

export const Container = styled.div`
  ${headerContainer};
`;

export const BurgerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: var(--main-green);

  @media ${device.tablet} {
    color: var(--main-white);
    width: 40px;
    height: 40px;
    background: var(--main-green);
    border-radius: 6px;
    margin-right: 10px;
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const Logo = styled.img`
  margin-right: 40px;
  @media (max-width: 1300px) {
    margin-right: 16px;
    max-width: 117px;
  }

  @media ${device.mobile} {
    margin-right: 12px;
  }
  @media (max-width: 400px) {
    max-width: 100px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-right: 45px;
  flex-basis: 920px;
  @media ${device.laptop} {
    flex-basis: 500px;
  }
  @media (max-width: 1300px) {
    margin-right: 16px;
    flex-basis: 375px;
  }

  @media ${device.tablet} {
    order: 5;
    margin-right: 0;
    margin-top: 20px;
    flex-basis: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
`;

export const LoginRegisterButton = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  min-width: 152.69px;
  min-height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  line-height: 24px;
  background-color: var(--main-orange);
  border: none;
  border-radius: 6px;
  cursor: pointer;

  @media ${device.tablet} {
    order: 3;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    font-family: 'Poppins', sans-serif;
    color: var(--main-white);
    border-radius: 4px;
    transition: all 0.3s;
    &:hover {
      background: rgba(255, 255, 255, 0.13);
    }
  }

  div {
    height: 20px;
    width: 1px;
    background: rgba(255, 255, 255, 0.27);
    margin: 0 6px;
  }
`;

export const LogoutBtn = styled(LoginRegisterButton)`
  color: var(--main-white);
  justify-content: center;
`;
export const StoreLocatorMobile = styled.div`
  display: none !important;
  @media ${device.tablet} {
    display: block !important;
  }
`;

export const CartPopupWrapper = styled.div`
  position: fixed;
  top: 134px;
  left: 0;
  width: 100%;
  height: calc(100% - 134px);
  background: rgba(31, 49, 36, 0.4);
  transition: all 0.3s;

  ${(props) => {
    return props.isCartOpened
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `;
  }}
`;
