import {
  Container,
  Item,
  ItemSubtitle,
  ItemTitle,
  ItemTitleWrapper,
  ItemValue,
  List,
  Title
} from './bid-history-widget-styled';

export const BIDS = [
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  },
  {
    title: 'Langton Shoriwa',
    subtitle: '2 mins ago',
    value: '$250'
  }
];

export const BidHistoryWidget = () => {
  return (
    <Container>
      <Title>Bid History</Title>
      <List>
        {BIDS.map((item, index) => {
          return (
            <Item key={index}>
              <ItemTitleWrapper>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemSubtitle>{item.subtitle}</ItemSubtitle>
              </ItemTitleWrapper>
              <ItemValue>{item.value}</ItemValue>
            </Item>
          );
        })}
      </List>
    </Container>
  );
};
