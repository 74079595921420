import styled, { css } from 'styled-components';
import { buttonReset } from 'assets/style-helpers/style-helpers';
import check from './img/check.svg';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Wrapper = styled.div`
  //margin-left: -18px;
  position: relative;
  z-index: 2;
  padding-right: ${(props) => (!props.isMapVisible ? '103px' : '24px')};
  width: 100%;
  max-width: ${(props) => (!props.isMapVisible ? '100%' : '594px')};
  padding-top: 48px;
  padding-bottom: 80px;
  background: var(--main-white);
  overflow-y: auto;
  @media ${device.tablet} {
    position: fixed;
    left: 0;
    top: 140px;
    margin-left: 0;
    padding: 72px 20px 80px;
    max-width: 100%;
    overflow-y: auto;
    z-index: 100;
    height: calc(100vh - 140px);
  }
`;

export const TopFilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const TypeFilter = styled.div`
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const TypeFilterItem = styled.button`
  ${buttonReset};
  padding: 5px 16px;
  border: 1px solid #c3dbc3;
  border-radius: 20px;
  transition: all 0.3s;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  &::before {
    position: absolute;
    content: '';
    left: 16px;
    top: 7px;
    width: 16px;
    height: 16px;
    background: url(${check}) no-repeat center;
    background-size: 100% 100%;
    opacity: 0;
  }
  ${(props) =>
    props.checked &&
    css`
      padding-left: 40px;
      background: #ecf6ec;
      border: 1px solid #388638;
      &::before {
        opacity: 1;
      }
    `}

  & + & {
    margin-left: 12px;
  }
`;

export const ContentSection = styled.div`
  margin-top: 40px;
`;

export const StoresSection = styled.section``;

export const ProductsSection = styled.section``;

export const StoresWrapper = styled.div`
  display: grid;
  grid-gap: 18px;
  margin-bottom: 32px;
  ${(props) =>
    props.isListView
      ? css`
          grid-template-columns: repeat(1, 1fr);
        `
      : css`
          grid-template-columns: ${(props) =>
            props.isMapVisible ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'};
        `}
  @media ${device.laptop} {
    ${(props) =>
      props.isListView
        ? css`
            grid-template-columns: repeat(1, 1fr);
          `
        : css`
            grid-template-columns: ${(props) =>
              props.isMapVisible ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
          `}
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isMapVisible ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)')};

  grid-gap: 9px;
  @media ${device.laptop} {
    grid-template-columns: ${(props) => (props.isMapVisible ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)')};
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
`;

export const MobileHideResultsBtn = styled.button`
  ${buttonReset};
  color: var(--main-green);
  align-items: center;
  justify-content: center;
  display: none;
  margin-bottom: 24px;
  svg {
    margin-right: 6px;
  }
  @media ${device.tablet} {
    display: flex;
  } ;
`;

export const MobileRangeSlider = styled.div`
  display: none;
  @media ${device.tablet} {
    max-width: 345px;
    display: block;
    margin: 0 auto 24px;
  }
  @media ${device.mobile} {
    max-width: 100%;
    width: 100%;
  }
`;

export const MobileRangeSliderTitle = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 600;
  }
`;

export const ShowMapBtn = styled.button`
  ${buttonReset};
  height: 34px;
  width: 120px;
  border-radius: 6px;
  background: #ecf6ec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-green);
`;

export const ViewWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FinderWidgetWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;
