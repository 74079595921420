import styled from 'styled-components';

export const AtributesStyled = styled.div`
  padding: 16px 0;
`;

export const AttributesItem = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`;

export const AttributesItemTitle = styled.span`
  font-weight: 700;
`;

export const AttributesItemText = styled.div``;
