import img1 from 'assets/temp-img/shopping-centre-1.jpg';
import img2 from 'assets/temp-img/shopping-centre-2.jpg';
import img3 from 'assets/temp-img/shopping-centre-3.jpg';
import {
  Card,
  Container,
  Description,
  ShoppingCards,
  ShoppingList,
  ShoppingListItem,
  ShoppingListItemDescription,
  ShoppingListItemTitle,
  ShowMoreBtnWrapper,
  Title
} from './shopping-centers-info-styled';
import { GlobalButton } from '../global-button/global-button';
import { useState } from 'react';
import { CentresList } from './components/centres-list';

const cardsMock = [
  {
    title: 'Harare',
    img: img1,
    description: '20 Shopping Centres'
  },
  {
    title: 'Bulawayo',
    img: img2,
    description: '20 Shopping Centres'
  },
  {
    title: 'Chitungwiza',
    img: img3,
    description: '20 Shopping Centres'
  }
];

const centersMock = [
  { name: 'Mutare' },
  { name: 'Kwekwe' },
  { name: 'Norton' },
  { name: 'Ruwa' },
  { name: 'Gweru' },
  { name: 'Kadoma' },
  { name: 'Marondera' },
  { name: 'Bindura' },
  { name: 'Ruwa' },
  { name: 'Hwange' },
  { name: 'Epworth' },
  { name: 'Masvingo' },
  { name: 'Chinhoyi' },
  { name: 'Zvishavane' },
  { name: 'Victoria Falls' },
  { name: 'Mutare' },
  { name: 'Kwekwe' },
  { name: 'Norton' },
  { name: 'Ruwa' },
  { name: 'Gweru' },
  { name: 'Kadoma' },
  { name: 'Marondera' },
  { name: 'Bindura' },
  { name: 'Ruwa' },
  { name: 'Hwange' },
  { name: 'Epworth' },
  { name: 'Masvingo' },
  { name: 'Chinhoyi' },
  { name: 'Zvishavane' },
  { name: 'Victoria Falls' }
];

export const ShoppingCentersInfo = () => {
  const [isAllVisible, setIsAllVisible] = useState(false);
  return (
    <Container>
      <ShoppingCards>
        {cardsMock.map((card, i) => (
          <Card key={i}>
            <img src={card.img} alt={card.title} />
            <Title>{card.title}</Title>
            <Description>{card.description}</Description>
          </Card>
        ))}
      </ShoppingCards>
      <CentresList items={centersMock} isAllVisible={isAllVisible} />
      <ShowMoreBtnWrapper>
        <GlobalButton
          type={'orange-secondary'}
          onClick={() => {
            setIsAllVisible(!isAllVisible);
          }}
        >
          <svg
            width='10'
            height='6'
            viewBox='0 0 10 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9 1L5 5L1 1'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Show more cities
        </GlobalButton>
      </ShowMoreBtnWrapper>
    </Container>
  );
};
