import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--main-gray-3);
  margin-bottom: 10px;
  font-size: 10px;
  line-height: 140%;
  display: block;
`;
