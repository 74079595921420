import styled from 'styled-components';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { title30 } from '../../assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
`;

export const Inner = styled.div`
  display: flex;
  margin-bottom: 100px;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const PageTop = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.div`
  ${title30};
  display: flex;
  font-weight: 600;
`;

export const Order = styled.div`
  font-family: 'Lato', sans-serif;
  color: var(--main-green);
  margin-bottom: 4px;
`;

export const CompleteIcon = styled.div`
  width: 32px;
  height: 32px;
  background: var(--main-green);
  color: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 50%;
`;

export const LeftColumn = styled.div`
  flex-basis: 492px;
`;

export const CenterColumn = styled.div`
  flex-grow: 1;
  margin: 0 32px;
  @media ${device.laptop} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }
`;

export const RightColumn = styled.div`
  flex-basis: 330px;
  @media ${device.laptop} {
    display: none;
  }
`;

export const BannerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #136400;
  border-radius: 12px;
  height: 299px;
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.2);
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const WarantyInfo = styled.div`
  border-radius: 16px;
  background: #fff;
  padding: 0 32px 4px;

  /* shadow */
  box-shadow: 0px 4px 60px 0px rgba(62, 73, 59, 0.06);
  margin-top: 30px;

  > div {
    padding-left: 0;
    padding-right: 0;
  }
`;
