export const Request = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.74952 11.2501L17.4995 2.50005M8.85584 11.5234L11.0459 17.1551C11.2389 17.6512 11.3353 17.8993 11.4743 17.9717C11.5948 18.0345 11.7384 18.0346 11.859 17.9719C11.998 17.8997 12.0948 17.6517 12.2883 17.1558L17.7803 3.08272C17.955 2.63507 18.0423 2.41124 17.9945 2.26822C17.953 2.14401 17.8556 2.04654 17.7314 2.00504C17.5883 1.95726 17.3645 2.04461 16.9169 2.2193L2.84373 7.71125C2.34784 7.90477 2.09989 8.00153 2.02763 8.14062C1.96499 8.26119 1.96508 8.40474 2.02786 8.52523C2.10028 8.66424 2.34834 8.7607 2.84446 8.95364L8.47613 11.1437C8.57684 11.1829 8.62719 11.2025 8.66959 11.2327C8.70717 11.2595 8.74004 11.2924 8.76685 11.33C8.79709 11.3724 8.81667 11.4227 8.85584 11.5234Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
