import { IcoDefault, Img } from './tracking-info-styled';
import icoCheck from '../../../../../assets/icons/check-tracking.svg';

export const Ico = ({ done, isLast, isActive, status }) => {
  return (
    <IcoDefault isLast={isLast} isActive={isActive}>
      {done && <Img src={icoCheck} alt={status} />}
    </IcoDefault>
  );
};
