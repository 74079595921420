export const CoinsHand = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.7746 6.95984C11.2976 7.29992 10.7138 7.49996 10.0834 7.49996C8.47252 7.49996 7.16669 6.19412 7.16669 4.58329C7.16669 2.97246 8.47252 1.66663 10.0834 1.66663C11.1275 1.66663 12.0435 2.21531 12.5588 3.04008M5.50002 16.7393H7.67526C7.95888 16.7393 8.24076 16.773 8.5157 16.8405L10.8141 17.399C11.3128 17.5205 11.8323 17.5323 12.3362 17.4345L14.8775 16.9401C15.5488 16.8093 16.1663 16.4878 16.6503 16.017L18.4483 14.2681C18.9617 13.7694 18.9617 12.9603 18.4483 12.4608C17.986 12.0112 17.254 11.9605 16.731 12.3419L14.6355 13.8707C14.3354 14.09 13.9703 14.2082 13.5947 14.2082H11.5713L12.8592 14.2081C13.5852 14.2081 14.1732 13.6361 14.1732 12.9299V12.6742C14.1732 12.0879 13.763 11.5766 13.1784 11.4349L11.1905 10.9514C10.867 10.8729 10.5357 10.8333 10.2026 10.8333C9.39863 10.8333 7.94327 11.499 7.94327 11.499L5.50002 12.5207M17.1667 5.41663C17.1667 7.02746 15.8609 8.33329 14.25 8.33329C12.6392 8.33329 11.3334 7.02746 11.3334 5.41663C11.3334 3.8058 12.6392 2.49996 14.25 2.49996C15.8609 2.49996 17.1667 3.8058 17.1667 5.41663ZM2.16669 12.1666L2.16669 17C2.16669 17.4667 2.16669 17.7 2.25751 17.8783C2.33741 18.0351 2.46489 18.1626 2.62169 18.2425C2.79995 18.3333 3.03331 18.3333 3.50002 18.3333H4.16669C4.6334 18.3333 4.86675 18.3333 5.04501 18.2425C5.20181 18.1626 5.3293 18.0351 5.40919 17.8783C5.50002 17.7 5.50002 17.4667 5.50002 17V12.1666C5.50002 11.6999 5.50002 11.4666 5.40919 11.2883C5.3293 11.1315 5.20181 11.004 5.04501 10.9241C4.86675 10.8333 4.6334 10.8333 4.16669 10.8333L3.50002 10.8333C3.03331 10.8333 2.79995 10.8333 2.6217 10.9241C2.46489 11.004 2.33741 11.1315 2.25751 11.2883C2.16669 11.4666 2.16669 11.6999 2.16669 12.1666Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
