import { SectionHeader } from 'components/section-header/section-header';
import Header from 'components/header/header';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import InputWithLabel from 'components/input-with-label/input-with-label';
import { SelectWithLabel } from 'components/select-with-label/select-with-label';

import { BlogVideosStyled, CardsWrapper, Container, Top } from './blog-updates-styled';
import { InputWrapper, SortWrapper } from '../../components/recent-updates/recent-updates-styled';
import blogImg from '../../../../assets/temp-img/blog-img.png';
import { BlogCard } from '../../components/blog-card/blog-card';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '/blog', name: 'Blog' },
  { link: '#', name: 'Updates' }
];

const videoBlogs = [
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  }
];

export const BlogUpdates = () => {
  return (
    <BlogVideosStyled>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <SectionHeader title={'Updates'} />
        <Top>
          <InputWrapper>
            <InputWithLabel
              height={40}
              placeholder={'Enter what you are looking for...'}
              isLabel={false}
              inputClassName={'no-padding'}
            />
          </InputWrapper>
          <SortWrapper>
            <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
          </SortWrapper>
        </Top>
        <CardsWrapper>
          {videoBlogs.map((blog, i) => {
            return <BlogCard blog={blog} key={i} />;
          })}
        </CardsWrapper>
      </Container>
    </BlogVideosStyled>
  );
};
