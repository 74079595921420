import {
  Buttons,
  Container,
  Description,
  MainButtons,
  MataInfoItem,
  MeniItem,
  MetaInfo,
  MetaInfoText,
  MetaInfoTitle,
  MobBtn,
  Orders,
  Price,
  RatingWrapper,
  Reviews,
  ReviewsOrders,
  ShareBtn,
  ShopName,
  Tag,
  TagsTitle,
  TagsWrapper,
  Title
} from './product-info-styled';
import { RateStars } from '../rate-stars/rate-stars';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { MakeOfferPopup } from '../make-offer-popup/make-offer-popup';
import { useState } from 'react';

export const ProductInfoTrandesServices = ({ setIsSidebarHidden }) => {
  const [isMakeOfferVisible, setMakeOfferVisible] = useState(false);

  return (
    <Container>
      <ShopName>Elen`s Beauty Spar</ShopName>
      <Title>Beauty Make Over</Title>
      <RatingWrapper>
        <RateStars rate={3.5} height={17} width={17} isRateCounter={false} />
        <ReviewsOrders>
          <Reviews>
            <a href='components/product-info/product-info-real-estate#'>10 Reviews</a>
          </Reviews>
          <Orders> 200 Orders</Orders>
        </ReviewsOrders>
      </RatingWrapper>
      <MetaInfo>
        <MataInfoItem>
          <MetaInfoTitle>Item Published On: </MetaInfoTitle>
          <MetaInfoText>10/03/2022</MetaInfoText>
        </MataInfoItem>
        <MataInfoItem>
          <MetaInfoTitle>ID: </MetaInfoTitle>
          <MetaInfoText>EZ124-585-22155-44</MetaInfoText>
        </MataInfoItem>
      </MetaInfo>
      <Price>$300</Price>
      <Description>
        Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
        morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare
        viverra leo ornare viverra.
      </Description>
      <MeniItem>
        <TagsTitle>Speciality:</TagsTitle>
        <TagsWrapper>
          <Tag>Eyelashes</Tag>
          <Tag>Natural</Tag>
          <Tag>Eyelashes</Tag>
          <Tag>Natural</Tag>
          <Tag>Eyelashes</Tag>
          <Tag>Natural</Tag>
          <Tag>Eyelashes</Tag>
        </TagsWrapper>
      </MeniItem>
      <Buttons>
        <MainButtons>
          <GlobalButton height={40} type='primary'>
            <SvgIcon name={'message-check-square'} />
            Book Now
          </GlobalButton>
          <GlobalButton
            height={40}
            type={'orange'}
            onClick={() => {
              setMakeOfferVisible(true);
            }}
          >
            <SvgIcon name={'message-text-square'} />
            Make an Offer
          </GlobalButton>
        </MainButtons>
        <MobBtn>
          <GlobalButton
            height={40}
            type='primary'
            onClick={() => {
              setIsSidebarHidden(false);
            }}
          >
            Contact Seller
          </GlobalButton>
        </MobBtn>
        <ShareBtn>
          <SvgIcon name={'share'} />
          Share
        </ShareBtn>
      </Buttons>
      <MakeOfferPopup
        isVisible={isMakeOfferVisible}
        onClose={() => {
          setMakeOfferVisible(false);
        }}
        onMakeOfferBtnClick={() => {
          setMakeOfferVisible(false);
        }}
      />
    </Container>
  );
};
