import styled from 'styled-components';
import { paragraph30 } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const DeliveryPartnersStyled = styled.section`
  margin: 0 100px 80px 106px;

  @media ${device.mobile} {
    margin: 0 0 50px 15px;
  }
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--main-gray-2);
  margin: 22px 0;
`;

export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    display: none;
  }
`;

export const ImagesMobile = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`;

export const ImageMobile = styled.img`
  max-width: 150px;
`;

export const FadeOutContainer = styled.div`
  position: relative;
  &::after {
    position: absolute;
    height: 100%;
    width: 90px;
    background: linear-gradient(271.2deg, #ffffff 0.91%, rgba(255, 255, 255, 0) 98.87%);
    opacity: 0.6;
    top: 0;
    right: 0;
    content: '';
    z-index: 5;
    pointer-events: none;
  }
`;
