import styled from 'styled-components';
import { inputReset, LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const PromocodeInputStyled = styled.div`
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  position: relative;
  height: 40px;
  padding: 8px 0;
  button {
    position: absolute;
    top: 2px;
    right: 3px;
  }
  input {
    ${inputReset};
    ${LatoFont};
    width: 100%;
    padding-left: 20px;
    padding-right: 80px;
    border: none;
  }
`;
