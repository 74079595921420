export const size = {
  mobile: '767px',
  tablet: '991px',
  laptop_xs: '1200px',
  laptop: '1600px'
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop_xs: `(max-width: ${size.laptop_xs})`,
  laptop: `(max-width: ${size.laptop})`
};
