import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 103px 124px 103px;
  // @media ${device.laptop} {
  //   padding: 16px 16px 124px;
  // }
  @media ${device.tablet} {
    padding: 0 15px 56px 15px;
  }
`;

export const BreadcrumbsContainer = styled.div`
  padding: 16px 103px 0px 103px;
  @media ${device.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
