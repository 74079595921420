export const HomeIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.3 21V14.2907C9.3 13.7829 9.3 13.529 9.3981 13.335C9.48438 13.1644 9.62207 13.0257 9.79141 12.9388C9.98393 12.84 10.236 12.84 10.74 12.84H13.26C13.764 12.84 14.0161 12.84 14.2086 12.9388C14.3779 13.0257 14.5156 13.1644 14.6019 13.335C14.7 13.529 14.7 13.7829 14.7 14.2907V21M3 10.5733L11.136 4.42613C11.4458 4.19204 11.6008 4.07499 11.7709 4.02987C11.9211 3.99004 12.0789 3.99004 12.2291 4.02987C12.3993 4.07499 12.5542 4.19204 12.864 4.42613L21 10.5733M4.8 9.21333V18.0987C4.8 19.1142 4.8 19.622 4.99619 20.0099C5.16876 20.3511 5.44413 20.6285 5.78282 20.8024C6.16786 21 6.67191 21 7.68 21H16.32C17.3281 21 17.8321 21 18.2172 20.8024C18.5559 20.6285 18.8312 20.3511 19.0038 20.0099C19.2 19.622 19.2 19.1142 19.2 18.0987V9.21333L13.728 5.07893C13.1083 4.61074 12.7985 4.37665 12.4582 4.28641C12.1579 4.20675 11.8421 4.20675 11.5418 4.28641C11.2015 4.37665 10.8917 4.61074 10.272 5.07893L4.8 9.21333Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
