import Tippy from '@tippyjs/react/headless';
import {
  Container,
  StarButton,
  TippyBox,
  ArrowStyled,
  StarsWrapper,
  Text
} from './review-widget-styled';
import { useState } from 'react';

const Arrow = ({ fill }) => {
  return (
    <ArrowStyled data-popper-arrow=''>
      <svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.25259 0.335586C7.65038 -0.111928 8.34962 -0.111928 8.74741 0.335586L16 8.49475H0L7.25259 0.335586Z'
          fill={fill}
        />
      </svg>
    </ArrowStyled>
  );
};

const Star = ({ starColor, tippyText, tippyBgColor, onClick, color }) => {
  return (
    <Tippy
      placement={'bottom-start'}
      render={(attrs) => (
        <TippyBox tabIndex='-1' {...attrs} tippyBgColor={tippyBgColor} color={color}>
          {tippyText}
          <Arrow data-popper-arrow='' id={'arrow'} className={'test'} fill={tippyBgColor} />
        </TippyBox>
      )}
    >
      <StarButton onClick={onClick}>
        <svg
          width='20'
          height='19'
          viewBox='0 0 20 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.22611 6.45514L10 0.954798L11.7739 6.45514C11.9207 6.91038 12.3449 7.21856 12.8232 7.21751L18.6025 7.20488L13.9195 10.5916C13.5319 10.872 13.3699 11.3706 13.5187 11.8252L15.3166 17.3177L10.6485 13.9105C10.2622 13.6285 9.73785 13.6285 9.35149 13.9105L4.68336 17.3177L6.48128 11.8252C6.63008 11.3706 6.46806 10.872 6.08048 10.5916L1.3975 7.20488L7.1768 7.21751C7.65512 7.21856 8.07929 6.91038 8.22611 6.45514Z'
            fill={starColor ? starColor : 'none'}
            stroke={starColor ? starColor : '#388638'}
            strokeWidth='1.2'
          />
        </svg>
      </StarButton>
    </Tippy>
  );
};

const stars = [
  {
    id: 1,
    color: '#E54D45',
    tippyText: 'Poor',
    tippyBgColor: '#FCEAE9'
  },
  {
    id: 2,
    color: '#F18C16',
    tippyText: 'Below Average',
    tippyBgColor: '#FCEDDC'
  },
  {
    id: 3,
    color: '#FFBB00',
    tippyText: 'Average',
    tippyBgColor: '#FFF5DC'
  },
  {
    id: 4,
    color: '#CBDC0A',
    tippyText: 'Above Average',
    tippyBgColor: '#F7FADA'
  },
  {
    id: 5,
    color: '#F76D21',
    tippyText: 'Excellent',
    tippyBgColor: '#E8F6E4'
  }
];

export const ReviewWidget = ({ shopName }) => {
  const [selectedStar, setSelectedStar] = useState(null);
  return (
    <Container>
      <StarsWrapper>
        {stars.map((star) => {
          return (
            <Star
              color={star.color}
              starColor={selectedStar && star.id <= selectedStar.id ? selectedStar.color : null}
              tippyText={star.tippyText}
              key={star.id}
              tippyBgColor={star.tippyBgColor}
              onClick={() => {
                setSelectedStar(star);
              }}
            />
          );
        })}
      </StarsWrapper>

      <Text color={selectedStar ? selectedStar.color : ''}>
        Your Experience With {shopName} Was: {selectedStar && <span>{selectedStar.tippyText}</span>}
      </Text>
    </Container>
  );
};
