import Header from 'components/header/header';
import { HomeMain } from './components/home-main/home-main';
import { StoresYouLoveSection } from 'components/stores-you-love-section/stores-you-love-section';
import { DealsOfDay } from './components/deals-of-day/deals-of-day';
import { SectionWithBanner } from './components/section-with-banner/section-with-banner';
import { FeaturedCategories } from './components/featured-categorires/featured-categories';
import { FoodAndGroceries } from './components/food-and-groceries/food-and-groceries';
import { SectionWithSlider } from './components/section-with-slider/section-with-slider';
import { HomeAdSpace, HomeAdSpace2, StoresYouLoveWrapper } from './home-styled';
import { ShoppingCenterSection } from './components/shopping-centers-section/shopping-center-section';
import { KnowledgeBaseSection } from './components/knowledge-base-section/knowledge-base-section';
import { AdSpace } from '../../components/ad-space/ad-space';

export const Home = () => {
  return (
    <>
      <HomeAdSpace />
      <Header />
      <HomeMain />
      <StoresYouLoveWrapper>
        <StoresYouLoveSection title={'Stores You’ll Love'} />
      </StoresYouLoveWrapper>
      <DealsOfDay />
      <SectionWithBanner />
      <FeaturedCategories />
      <HomeAdSpace2 />
      <FoodAndGroceries />
      <SectionWithSlider sectionTitle={'Real Estate'} />
      <SectionWithSlider sectionTitle={'Trades & Services'} />
      <SectionWithSlider sectionTitle={'Motor Vehicles'} />
      <HomeAdSpace2 />

      <ShoppingCenterSection />
      <KnowledgeBaseSection />
    </>
  );
};
