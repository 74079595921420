import {
  Container,
  MenuIcon,
  MenuItem,
  MenuItemMain,
  MenuSublist,
  MenuTitle,
  MenuWrapper,
  MobileMenu,
  MobileMenuItem,
  MobileMenuList,
  MobileMenuSection,
  MobileMenuTitlte,
  Subitem,
  Title,
  Wrapper
} from './shop-by-category-menu-styled';

import book from './icons/book.svg';
import clothing from './icons/clothing.svg';
import construction from './icons/construction.svg';
import electonic from './icons/electronic.svg';
import farming from './icons/farming.svg';
import food from './icons/food.svg';
import health from './icons/health.svg';
import home from './icons/home.svg';
import industrial from './icons/industrial.svg';
import motor from './icons/motor.svg';
import motorVehicles from './icons/motor-vehicles.svg';
import realEstate from './icons/real-estate.svg';
import sports from './icons/sports.svg';
import trades from './icons/trades.svg';

import markerPin from './mobile-icons/marker-pin.svg';
import buyerRequestIcon from './mobile-icons/buyer-request.svg';
import helpIcon from './mobile-icons/help-icon.svg';

import booksIcon from './mobile-icons/bookIcon.svg';
import clothingIcon from './mobile-icons/clothing-icon.svg';
import constructionIcon from './mobile-icons/construction-icon.svg';
import electricIcon from './mobile-icons/electric-icon.svg';
import foodIcon from './mobile-icons/food-icon.svg';
import healthIcon from './mobile-icons/health-icon.svg';
import homeIcon from './mobile-icons/home-icon.svg';
import industialIcon from './mobile-icons/industrial-icon.svg';
import motorIcon from './mobile-icons/motor-icon.svg';
import realEstateIcon from './mobile-icons/real-estate-icon.svg';
import sportsIcon from './mobile-icons/sporst-icon.svg';
import tradesIcon from './mobile-icons/trades-icon.svg';
import motorVehiclesIcon from './mobile-icons/motor-vehicles.svg';

import settingsIcon from './mobile-icons/settings-icon.svg';
import logOutIcon from './mobile-icons/log-out-icon.svg';
import { forwardRef } from 'react';

const menu = [
  {
    title: 'Books & Stationary',
    icon: book,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Electronic Goods',
    icon: electonic,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Health & Beauty',
    icon: health,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Home & Garden',
    icon: home,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Construction & Hardware Supplies',
    icon: construction,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Clothing & Apparel',
    icon: clothing,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Farming Supplies',
    icon: farming,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Food & Groceries',
    icon: food,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Food & Groceries',
    icon: food,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Industrial Supplies',
    icon: industrial,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Motor Spare & Accessories',
    icon: motor,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Food & Groceries',
    icon: food,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Motor Vehicles',
    icon: motorVehicles,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Real Estate',
    icon: realEstate,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Trades & Services',
    icon: trades,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  },
  {
    title: 'Sports Hobbies & Leisure',
    icon: sports,
    menuList: [
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary',
      'Books & Stationary'
    ]
  }
];

const mobileMenu1 = [
  {
    title: 'Help',
    icon: helpIcon,
    link: '#'
  },
  {
    title: 'Store Locator',
    icon: markerPin,
    link: '#'
  },
  {
    title: 'Buyer Requests',
    icon: buyerRequestIcon,
    link: '#'
  }
];

const mobileMenu2 = [
  {
    title: 'Books & Stationary',
    icon: booksIcon,
    link: '#'
  },
  {
    title: 'Clothing & Apparel',
    icon: clothingIcon,
    link: '#'
  },
  {
    title: 'Construction & Hardware Supplies',
    icon: constructionIcon,
    link: '#'
  },
  {
    title: 'Electronic Goods',
    icon: electricIcon,
    link: '#'
  },
  {
    title: 'Food & Groceries',
    icon: foodIcon,
    link: '#'
  },
  {
    title: 'Health & Beauty',
    icon: healthIcon,
    link: '#'
  },
  {
    title: 'Home & Garden',
    icon: homeIcon,
    link: '#'
  },
  {
    title: 'Industrial Supplies',
    icon: industialIcon,
    link: '#'
  },
  {
    title: 'Motor Spare & Accessories',
    icon: motorIcon,
    link: '#'
  },
  {
    title: 'Motor Vehicles',
    icon: motorVehiclesIcon,
    link: '#'
  },
  {
    title: 'Real Estate',
    icon: realEstateIcon,
    link: '#'
  },
  {
    title: 'Sports Hobbies & Leisure',
    icon: sportsIcon,
    link: '#'
  },
  {
    title: 'Trades & Services',
    icon: tradesIcon,
    link: '#'
  }
];

const mobileMenu3 = [
  {
    title: 'Account Settings',
    icon: settingsIcon,
    link: '#'
  },
  {
    title: 'Log Out',
    icon: logOutIcon,
    link: '#',
    isRed: true
  }
];

// eslint-disable-next-line react/display-name
export const ShopByCategoryMenu = forwardRef((props, ref) => {
  return (
    <Container isOpened={props.isOpened} ref={ref}>
      <Wrapper>
        <Title>Popular Categories</Title>
        <MenuWrapper>
          {menu.map((item, i) => (
            <MenuItem key={i}>
              <MenuIcon>
                <img src={item.icon} alt={item.title} />
              </MenuIcon>
              <MenuItemMain>
                <MenuTitle>{item.title}</MenuTitle>
                <MenuSublist>
                  {item.menuList.map((subitem, i) => {
                    return <Subitem key={i}>{subitem}</Subitem>;
                  })}
                </MenuSublist>
              </MenuItemMain>
            </MenuItem>
          ))}
        </MenuWrapper>
      </Wrapper>
      <MobileMenu>
        <MobileMenuSection>
          <MobileMenuTitlte>Menu</MobileMenuTitlte>
          <MobileMenuList>
            {mobileMenu1.map((item, key) => (
              <MobileMenuItem key={key} to={item.link}>
                <img src={item.icon} alt='' />
                <span>{item.title}</span>
              </MobileMenuItem>
            ))}
          </MobileMenuList>
        </MobileMenuSection>
        <MobileMenuSection>
          <MobileMenuTitlte>Categories</MobileMenuTitlte>
          <MobileMenuList>
            {mobileMenu2.map((item, key) => (
              <MobileMenuItem key={key}>
                <img src={item.icon} alt='' />
                <span>{item.title}</span>
              </MobileMenuItem>
            ))}
          </MobileMenuList>
        </MobileMenuSection>
        <MobileMenuSection>
          <MobileMenuTitlte>Menu</MobileMenuTitlte>
          <MobileMenuList>
            {mobileMenu3.map((item, key) => (
              <MobileMenuItem key={key} to={item.link} isRedColor={item.isRed}>
                <img src={item.icon} alt='' />
                <span>{item.title}</span>
              </MobileMenuItem>
            ))}
          </MobileMenuList>
        </MobileMenuSection>
      </MobileMenu>
    </Container>
  );
});
