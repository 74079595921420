import { ReactComponent as Dots } from '../../../../assets/dots.svg';
import { ReactComponent as FeaturedIco } from '../../../../assets/featured-Ico.svg';
import Product from '../../../../assets/product.png';
import { Radio } from '../../../../components/radio/radio';
import { Block, CategoriesBox, Description, IcoBox, Img, Status, Title } from './items-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product',
    accessor: 'product'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Days Left',
    accessor: 'daysleft'
  },
  {
    Header: 'Categories',
    accessor: 'categories'
  },
  {
    Header: 'Regular Price',
    accessor: 'regularprice'
  },
  {
    Header: 'Sale Price',
    accessor: 'saleprice'
  },
  {
    Header: 'Stock',
    accessor: 'stock'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Orders',
    accessor: 'orders'
  },
  {
    Header: 'Featured',
    accessor: 'featured'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }
];
export const columnsRows = [
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    product: (
      <Block>
        <Title>NEW Phone Huawei Y7</Title>
        <Description>Lorem ipsum dolor sit amet consectetur. Amet et tincidu...</Description>
      </Block>
    ),
    status: (
      <Status>
        <Radio id={'no'} isChecked={'no'} name={'Published'} title={'Published'} value={'no'} />
      </Status>
    ),
    daysleft: '30',
    categories: (
      <CategoriesBox>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</CategoriesBox>
    ),
    regularprice: '$250 000 000',
    saleprice: '$250 000 000',
    stock: '50k+',
    type: 'For Sale',
    orders: '50k+',
    featured: (
      <IcoBox>
        <FeaturedIco />
      </IcoBox>
    ),
    actions: (
      <IcoBox>
        <Dots />
      </IcoBox>
    )
  },
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    product: (
      <Block>
        <Title>NEW Phone Huawei Y7</Title>
        <Description>Lorem ipsum dolor sit amet consectetur. Amet et tincidu...</Description>
      </Block>
    ),
    status: (
      <Status>
        <Radio id={'no'} isChecked={'no'} name={'Published'} title={'Published'} value={'no'} />
      </Status>
    ),
    daysleft: '30',
    categories: (
      <CategoriesBox>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</CategoriesBox>
    ),
    regularprice: '$250 000 000',
    saleprice: '$250 000 000',
    stock: '50k+',
    type: 'For Sale',
    orders: '50k+',
    featured: (
      <IcoBox>
        <FeaturedIco />
      </IcoBox>
    ),
    actions: (
      <IcoBox>
        <Dots />
      </IcoBox>
    )
  }
];
