import styled from 'styled-components';
import {
  buttonReset,
  LatoFont,
  mainContainer,
  PoppinsFont
} from 'assets/style-helpers/style-helpers';
import { title20, title30 } from 'assets/style-helpers/typography';
import downloadIcon from './icon/download.svg';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
  margin-bottom: 106px;
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
`;

export const Tag = styled.div`
  background: #f9fcf9;
  border: 1px solid #bdd7bd;
  border-radius: 20px;
  ${LatoFont};
  font-weight: 700;
  padding: 2px 12px;
  line-height: 24px;
  margin-bottom: 20px;

  & + & {
    margin-left: 6px;
  }
`;

export const Title = styled.h1`
  ${title30};
  margin: 0 0 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
`;

export const Date = styled.div`
  ${LatoFont};
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--main-subtext);
  svg {
    margin-right: 12px;
  }
`;

export const ShareBtn = styled.button`
  ${buttonReset};
  display: flex;
  align-items: center;
  color: var(--main-orange);
  ${LatoFont};
  font-weight: 700;
  svg {
    margin-right: 8px;
  }
`;

export const ImgWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  height: 415px;
  margin-bottom: 40px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MainContent = styled.div`
  max-width: 800px;
  margin: 0 auto 80px;
  p {
    ${LatoFont};
    color: var(--secondary-text);
  }
  p + p {
    margin-top: 22px;
  }

  h4 {
    margin: 26px 0 14px;
    ${title20};
  }

  h5 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  ul {
    padding-left: 16px;
    ${LatoFont};
    color: var(--secondary-text);

    li::marker {
      color: var(--main-green);
    }
  }

  .download-link {
    padding: 17px 24px;
    border: 1px solid var(--divider);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    ${PoppinsFont};
    margin: 26px 0;
    color: var(--main-orange);
    &::after {
      position: absolute;
      content: '';
      background: url(${downloadIcon}) no-repeat center center;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      right: 32px;
      top: 16px;
    }
  }
`;

export const RecomendedSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 25px;

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);

    > a:last-child {
      display: none;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);

    > a:nth-child(3),
    > a:nth-child(4) {
      display: none;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RecomendedSectionTitle = styled.div`
  ${title30};
  padding-bottom: 18px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--divider);
`;
