export const EmailIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 7.58984L10.9402 12.5239C11.5022 12.9234 11.7832 13.1232 12.0888 13.2006C12.3588 13.2689 12.6412 13.2689 12.9112 13.2006C13.2168 13.1232 13.4978 12.9234 14.0598 12.5239L21 7.58984M8.08 18.8125H16.92C18.3481 18.8125 19.0622 18.8125 19.6077 18.5302C20.0875 18.2819 20.4776 17.8857 20.7221 17.3984C21 16.8444 21 16.1192 21 14.6687V9.14375C21 7.6933 21 6.96808 20.7221 6.41408C20.4776 5.92677 20.0875 5.53057 19.6077 5.28228C19.0622 5 18.3481 5 16.92 5H8.08C6.65187 5 5.9378 5 5.39232 5.28228C4.91251 5.53057 4.52241 5.92677 4.27793 6.41408C4 6.96808 4 7.6933 4 9.14375V14.6687C4 16.1192 4 16.8444 4.27793 17.3984C4.52241 17.8857 4.91251 18.2819 5.39232 18.5302C5.9378 18.8125 6.65187 18.8125 8.08 18.8125Z'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
