import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const AccountAddress = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin-right: 100px;

  @media ${device.mobile} {
    width: 100%;
    margin: 17px 0 0 0;
  }
`;

export const Info = styled.div`
  background-color: #f5f7fb;
  padding: 25px 21px 27px 21px;
  border-radius: 0 0 12px 12px;

  .title {
    display: flex;
    margin-bottom: 12px;
    img {
      width: 20px;
      margin-right: 12px;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #29263a;
    }
  }

  .address {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #29263a;
    margin-bottom: 18px;
  }

  .social {
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 9px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .whatsapp {
      background: linear-gradient(
          166.43deg,
          rgba(255, 255, 255, 0.1) 9.72%,
          rgba(255, 255, 255, 0) 78.4%
        ),
        #7ccc67;
      margin-right: 14px;
      cursor: pointer;
    }

    .phone {
      background: linear-gradient(
          166.43deg,
          rgba(255, 255, 255, 0.1) 9.72%,
          rgba(255, 255, 255, 0) 78.4%
        ),
        #3664f6;
      margin-right: 14px;
      cursor: pointer;
    }

    .message {
      background: linear-gradient(
          166.43deg,
          rgba(255, 255, 255, 0.1) 9.72%,
          rgba(255, 255, 255, 0) 78.4%
        ),
        #ffbb00;
      margin-right: 14px;
      cursor: pointer;
    }
  }
`;
