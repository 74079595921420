import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { linkReset } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const SectionLinkStyled = styled(Link)`
  ${linkReset};
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(247, 109, 33, 0.1);
  border-radius: 6px;
  height: 40px;
  color: var(--main-orange);
  font-weight: 600;
  span {
    margin-left: 12px;
    line-height: 24px;
  }

  ${(props) => {
    return (
      props.isMobile &&
      css`
        display: none;
        @media ${device.mobile} {
          display: flex;
          width: 100%;
        }
      `
    );
  }}
  @media ${device.mobile} {
    order: 1;
  }
`;
