import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { title30 } from '../../assets/style-helpers/typography';

export const Wrapper = styled.div`
  padding: 48px 15px 106px;
  @media ${device.tablet} {
    padding: 45px 16px 64px;
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 884px;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.08);
  border-radius: 12px;
  padding: 56px 16px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    box-shadow: none;
    padding: 0;
  }
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 32px;
`;
