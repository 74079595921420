import styled from 'styled-components';

export const CategoriesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 48px;
  right: 0;
  max-width: 268px;
  padding: 20px 28px;
  background: var(--main-white);
  border-radius: 12px;
  box-shadow: 0 4px 60px 0 rgba(62, 73, 59, 0.06);
  > a {
    &:last-child {
      margin-bottom: 0;
    }
  }
  transition: all 0.5s ease;
  height: ${(props) => (props.isOpen ? `${props.height}px` : 0)};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: ${(props) => (props.isOpen ? 'translateY(0)' : '')};
  overflow: hidden;
`;

export const CategoryLink = styled.a`
  color: var(--main-text);
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-decoration: none;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  &:hover {
    color: var(--main-green);
  }
`;
