import { SvgIcon } from '../svg-icon/svg-icon';
import {
  BottomSection,
  Button,
  Container,
  Line,
  Text,
  TextWithLines,
  Buttons
} from './google-facebook-auth-styled';

export const GoogleFacebookAuth = ({ children, description }) => {
  return (
    <Container>
      <TextWithLines>
        <Line />
        <Text> {description}</Text>
        <Line />
      </TextWithLines>
      <Buttons>
        <Button>
          <SvgIcon name={'google'} />
          Google
        </Button>
        <Button>
          <SvgIcon name={'facebook'} />
          Facebook
        </Button>
      </Buttons>
      <BottomSection>{children}</BottomSection>
    </Container>
  );
};
