import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  display: flex;
  gap: 14px;

  & + & {
    margin-top: 20px;
  }
`;

const Icon = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconNew = styled(Icon)`
  background: #f4f4f4;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #e54d45;
    border-radius: 50%;
    top: 9px;
    right: 3px;
  }
`;

export const Main = styled.div``;

export const Msg = styled.div`
  ${LatoFont};
  line-height: 22px;
  span {
    font-weight: 700;
  }
`;

export const PName = styled.div`
  ${LatoFont};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const Time = styled.div`
  ${LatoFont};
  color: var(--main-subtext);
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Buttons = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  ${buttonReset};
  text-transform: capitalize;
  color: #fff;
  border-radius: 6px;
  padding: 4px 20px;
  ${LatoFont};
  line-height: 22px; /* 157.143% */
`;

export const ButtonGreen = styled(Button)`
  background: #388638;
`;

export const ButtonRed = styled(Button)`
  background: #cb3b2f;
`;

export const IconApprove = styled(Icon)`
  background: linear-gradient(166deg, rgba(255, 255, 255, 0.1) 9.72%, rgba(255, 255, 255, 0) 78.4%),
    #e7f0e7;
`;

export const IconDecline = styled(Icon)`
  background: linear-gradient(166deg, rgba(255, 255, 255, 0.1) 9.72%, rgba(255, 255, 255, 0) 78.4%),
    rgba(203, 59, 47, 0.1);
`;
