import React, { useState } from 'react';

import { Container } from './categories-motor-vehicles-styled';
import Header from '../../components/header/header';
import Main from './components/main/main';
import { BreadcrumbsContainer } from '../categories-real-estate/categories-real-estate-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { GlobalFilter } from '../../components/global-filter/global-filter';

const breadcrumbs = [
  {
    link: '#',
    name: 'Home'
  },
  {
    link: '#',
    name: 'Motor Vehicles'
  }
];

const CategoriesRealEstate = ({ isSpareParts }) => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main
          isSpareParts={isSpareParts}
          onFilterBtnClick={() => setMobileFilterVisible(!isMobileFilterVisible)}
        />
      </Container>
    </>
  );
};

export default CategoriesRealEstate;
