import styled, { css } from 'styled-components';
import { paragraph30 } from '../../assets/style-helpers/typography';

import { device } from '../../assets/style-helpers/breakpoints';

export const Title = styled.div`
  margin-right: 40px;
  ${(props) => {
    switch (props.fontSize) {
      case 'lg':
        return css`
          ${paragraph30};
          font-weight: 600;

          @media ${device.tablet} {
            font-size: 24px;
          }
        `;
      case 'md':
        return css`
          font-size: 20px;
          @media ${device.tablet} {
            font-size: 18px;
          }
        `;
    }
  }}
  font-weight: 600;
  @media ${device.mobile} {
    margin-right: ${(props) => (props.seeAllMobile ? '0' : '40px')};
  }
`;

export const SectionLinkWrapper = styled.div`
  @media ${device.mobile} {
    display: ${(props) => (props.seeAllMobile ? 'block' : 'none')};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--main-gray-2);
  @media ${device.tablet} {
    padding-bottom: 12px;
  }
  @media ${device.mobile} {
    display: ${(props) => (props.seeAllMobile ? 'flex' : 'block')};
    padding-bottom: 17px;
    margin-bottom: 21px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  > button {
    margin-right: 10px;
  }
  @media ${device.mobile} {
    margin-top: ${(props) => (props.seeAllMobile ? '0' : '12px')};
  }
`;
