import image1 from '../assets/categories_motor_vehicles_image1.png';
import image2 from '../assets/categories_motor_vehicles_image2.png';
import image3 from '../assets/categories_motor_vehicles_image3.png';
import miles from '../assets/miles.svg';
import year from '../assets/year.svg';
import manual from '../assets/manual.svg';
import automatic from '../assets/automatic.svg';

export const CARS = [
  {
    img: [image1, image2, image3, image2],
    title: 'Non-Runner Mercedes Benz CLA 250',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: manual,
        name: 'Man. tr.'
      }
    ],
    price: '200 000',
    zwl_price: '20',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image3, image2, image3, image2],
    title: 'BMW X5 2020 For Rent',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200',
    zwl_price: '20',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image2, image2, image3, image2],
    title: 'Non-Runner Mercedes Benz CLA 250',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200 000',
    zwl_price: '20',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: 'BMW X5 2020 For Rent',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image3, image2, image3, image2],
    title: 'Non-Runner Mercedes Benz CLA 250',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '200 000',
    zwl_price: '20',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image2, image2, image3, image2],
    title: 'BMW X5 2020 For Rent',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200',
    zwl_price: '20',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image3, image2, image3, image2],
    title: 'BMW X5 2020 For Rent',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200',
    zwl_price: '20',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image2, image2, image3, image2],
    title: 'Non-Runner Mercedes Benz CLA 250',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200 000',
    zwl_price: '20',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: 'BMW X5 2020 For Rent',
    location: 'General Merchandise | Norton',
    old_price: '$39.99',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '63000km'
      },
      {
        image: year,
        name: '2020'
      },
      {
        image: automatic,
        name: 'Aut.'
      }
    ],
    price: '1 200',
    zwl_price: '20',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  }
];
