import React from 'react';
import { Container, MainStyled, SparePartsDiv } from './main-styled';
import Background from '../../components/main/background/background';
import MotorVehicles from '../../components/main/motor-vehicles/motor-vehicles';
import ChassisNumberSearch from './chassis-number-search/chassis-number-search';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
// import { SelectWithLabel } from '../../../../components/select-with-label/select-with-label';

export const slides = [
  {
    title: 'Best Car Sale',
    subtitle: 'Best Quality',
    buttonText: '30 % Discount'
  },
  {
    title: 'Best Car Sale',
    subtitle: 'Best Quality',
    buttonText: '50 % Discount'
  },
  {
    title: 'Best Motor Sale',
    subtitle: 'Best Quality',
    buttonText: '30 % Discount'
  }
];

const Main = ({ isSpareParts, onFilterBtnClick }) => {
  return (
    <MainStyled>
      {isSpareParts ? (
        <SparePartsDiv>
          <ChassisNumberSearch />
          <Background title='Best Car Sale' subtitle='Best Quality' buttonText='30 % Discount' />
        </SparePartsDiv>
      ) : (
        ''
      )}
      {!isSpareParts ? (
        <>
          <Container>
            <Swiper
              modules={[Autoplay, Pagination]}
              autoplay={{ delay: 2500 }}
              pagination={{ clickable: true }}
            >
              {slides.map((item) => {
                return (
                  <SwiperSlide key={item.title}>
                    <Background
                      title={item.title}
                      subtitle={item.subtitle}
                      buttonText={item.buttonText}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
          {/* <SelectWrapper> */}
          {/*  <SelectWithLabel*/}
          {/*    isVar2*/}
          {/*    label={'Results per page:'}*/}
          {/*    options={[{ label: '111', value: '111' }]}*/}
          {/*  />*/}
          {/* </SelectWrapper> */}
        </>
      ) : (
        ''
      )}
      <MotorVehicles isSpareParts={isSpareParts} onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;
