import { device } from 'assets/style-helpers/breakpoints';
import { LatoFont, scrollbarStyles } from 'assets/style-helpers/style-helpers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 0;
  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  padding: 22px 8px 22px 0px;
  @media ${device.laptop} {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 290px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const UserInfo = styled.div`
  max-width: 100%;
  margin-bottom: -10px;

  button {
    font-size: 12px;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
`;

export const Avatar = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;

  > img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`;

export const UserMain = styled.div`
  min-width: 0;
`;

export const BusinessName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;

  text-overflow: ellipsis;
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  ${LatoFont};
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
  }
  img {
    margin-right: 6px;
  }
`;

export const MenuWrapper = styled.div`
  max-height: 770px;
  overflow-y: auto;
  ${scrollbarStyles};
  padding-right: 8px;

  @media ${device.laptop} {
    height: auto;
  }
`;

export const MenuItem = styled.div`
  padding-top: 16px;
  border-top: 1px solid var(--divider);
  margin-top: 16px;
`;

export const MenuTitle = styled.div`
  padding-left: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  img {
    margin-right: 8px;
  }
`;

export const Submenu = styled.div`
  padding-left: 0px;
  ${LatoFont};
`;

export const SubmenuLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 32px;
  border-left: 4px solid #ffffff;
  border-radius: 0px 4px 4px 0px;
  &:after {
    position: absolute;
    left: -4px;
    top: 0;
    content: '';
    background: #388638;
    border-radius: 0px 4px 4px 0px;
    height: 100%;
    width: 4px;
    opacity: 0;
  }

  &:hover {
    color: #388638;
    svg {
      path {
        stroke: #388638;
      }
    }

    &:after {
      opacity: 1;
    }
  }
  @media ${device.laptop} {
    &:after {
      display: none;
    }
  }
  svg {
    margin-right: 8px;
  }
  & + & {
    margin-top: 8px;
  }
  img {
    margin-right: 8px;
  }
`;

export const CloseDashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 24px;
  }
`;

export const Img = styled.img``;

export const Exit = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #eb4335;
  cursor: pointer;
  margin-top: 21px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  svg {
    margin-right: 8px;
  }
`;

export const Notifications = styled.span`
  background: #e7f0e7;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: #388638;
  margin-left: auto;
`;
