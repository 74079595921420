import {
  Container,
  ImgWrapper,
  Info,
  InfoItem,
  Main,
  Price,
  PriceWrapper,
  Subtitle,
  Title
} from './order-card-styled';

import img from './mock.png';

export const OrderCard = () => {
  return (
    <Container>
      <ImgWrapper>
        <img src={img} alt='' />
      </ImgWrapper>
      <Main>
        <Title>Huawei Y7</Title>
        <Subtitle>Eltrons Electronics</Subtitle>
        <Info>
          <InfoItem>Qty: 1</InfoItem>
          <InfoItem>Black</InfoItem>
          <InfoItem>32 GB</InfoItem>
        </Info>
      </Main>
      <PriceWrapper>
        <Price>$250</Price>
      </PriceWrapper>
    </Container>
  );
};
