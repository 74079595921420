import styled from 'styled-components';
import { mainContainer } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Wrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  background: var(--light-bg);
  padding-top: 40px;
  padding-bottom: 80px;
  @media ${device.tablet} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    overflow-x: hidden;
    padding-bottom: 40px;
  }
`;

export const Container = styled.div`
  ${mainContainer};
  > button {
    display: none;
  }
  @media ${device.mobile} {
    padding: 0;
    display: flex;
    flex-direction: column;
    > button {
      display: flex;
      order: 1;
      margin: 0 15px;
    }
    > div {
      &:first-child {
        margin-left: 15px;
        width: 92.5%;
      }
    }
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 18px;
  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
    > div:last-child {
      display: none;
    }
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 7px;
  @media ${device.tablet} {
    margin-left: auto;
  }

  a {
    color: var(--main-green);
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  a + a {
    margin-left: 0px;
  }
`;
