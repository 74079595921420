import { ReactComponent as Eye } from '../../../../assets/eye-table-icon.svg';
import { Radio } from '../../../../components/radio/radio';
import { Status, Tag, TagsWrapper } from './orders-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Date',
    accessor: 'data'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Total',
    accessor: 'total'
  },
  {
    Header: 'Products',
    accessor: 'products'
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod'
  },
  {
    Header: 'Delivery Method',
    accessor: 'deliveryMethod'
  },
  {
    Header: 'Actions',
    accessor: 'eye'
  }
];
export const columnsRows = [
  {
    id: 12520,
    customer: 'Langton Shoriwa',
    data: '07 Jul 2022',
    status: (
      <Status>
        <Radio id={'no'} isChecked={'no'} name={'status'} title={'Paid'} value={'no'} />
      </Status>
    ),
    total: '$300 000 000',
    products: (
      <TagsWrapper>
        {Tags.map((data, index) => {
          return <Tag key={index}>{data}</Tag>;
        })}
      </TagsWrapper>
    ),
    paymentMethod: 'Cash On Delivery',
    deliveryMethod: 'Everything Zimbabwean',
    eye: <Eye />
  }
];
