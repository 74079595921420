import { FilterSection, FilterSectionTitle } from '../filter/filter-styled';
import { useState } from 'react';

export const FilterSectionC = ({ children, name }) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <FilterSection>
      <FilterSectionTitle onClick={() => setIsVisible(!isVisible)} isVisible={isVisible}>
        {name}{' '}
        <svg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1 4L4 1L7 4'
            stroke='#29263A'
            strokeWidth='1.7'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </FilterSectionTitle>
      {isVisible && <div>{children}</div>}
    </FilterSection>
  );
};
