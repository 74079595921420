import { Link } from 'react-router-dom';
import { Btn, BtnWrapper, Container, Inner, Subtitle, Title } from './adv-banner-styled';

export const AdvBanner = ({ title, subtitle, buttonText, link, img, titleSize = 'lg' }) => {
  return (
    <Container>
      <img src={img} alt='title' />
      <Inner>
        <Title titleSize={titleSize}>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <BtnWrapper>
          <Link to={link}>
            <Btn>{buttonText}</Btn>
          </Link>
        </BtnWrapper>
      </Inner>
    </Container>
  );
};
