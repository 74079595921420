import styled from 'styled-components';
import { buttonReset } from 'assets/style-helpers/style-helpers';

export const Container = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 16px;
  }
`;

export const ImgWrapper = styled.div`
  width: 53px;
  height: 53px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 2px;
  font-weight: 600;
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 6px 0 12px;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Btn = styled.button`
  ${buttonReset};
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: var(--main-green);
  color: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 14px;
    height: 14px;
  }
`;
