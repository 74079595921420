import React, { useState } from 'react';

import {
  Container,
  Main,
  Title,
  Location,
  Tags,
  Tag,
  PriceInfo,
  Price,
  Bids,
  Description,
  Logo,
  Text,
  Info,
  Controls,
  EyeButton,
  Button,
  LandScapeContainer,
  LandScapeMain,
  LandScapeInfo,
  UserInfo,
  Name,
  Role,
  RateStarsDesktop,
  ContactsBox,
  MainBox,
  UserLogo,
  Credentials,
  LandScapeDescription,
  SecondPrice,
  ZWLPrice,
  FavBtn
} from './categories-card-styled';

import { ProductCardSlider } from '../product-card-slider/product-card-slider';
import { SvgIcon } from '../svg-icon/svg-icon';

import location from '../../assets/location_pin_blue.svg';
import logo from '../../assets/logo_card.png';
import userLogo from '../../assets/landscape_card_logo.png';

import { RateStars } from '../rate-stars/rate-stars';
import { MainPrice, OldPrice } from '../p-card/p-card-styled';
import { size } from '../../assets/style-helpers/breakpoints';
import { useWindow } from '../../hooks/useWindow';

const CategoriesCard = ({ product, handleFavBtnClick, landScape, isSpareParts }) => {
  const [isFav, setIsFav] = useState(false);
  const isMobile = useWindow();
  console.log('isMobile', isMobile);
  return (
    <>
      {!landScape ? (
        <Container>
          <ProductCardSlider
            img={product.img}
            title={product.title}
            isFav={isFav}
            handleFavBtnClick={() => {
              setIsFav(!isFav);
            }}
          />
          <Main>
            <MainBox></MainBox>
            <Title>{product.title}</Title>
            <Location>General Merchandise | Norton</Location>
            {product.tags && (
              <Tags>
                {product.tags.map((data, index) => {
                  return (
                    <Tag key={index}>
                      <img src={data.image} alt='' />
                      &nbsp;{data.name}
                    </Tag>
                  );
                })}
              </Tags>
            )}

            <Price>
              <OldPrice>{product.price}</OldPrice>
              <MainPrice>{product.price}</MainPrice>
              <ZWLPrice>ZWL 200</ZWLPrice>
            </Price>
            <Description>
              <Logo src={logo} />
              <Text>{product.description}</Text>
            </Description>
            <Info>
              <ContactsBox>
                <a href='#'>
                  <SvgIcon name={'whatsup'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'email'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'phone'} />
                </a>
              </ContactsBox>
              <Controls>
                <Button isSpareParts={isSpareParts}>
                  {!isSpareParts ? 'Place Bid' : 'Add to Cart'}
                </Button>
                <EyeButton>
                  <SvgIcon name='eye' />
                </EyeButton>
              </Controls>
            </Info>
          </Main>
        </Container>
      ) : (
        <LandScapeContainer landScape={landScape}>
          <ProductCardSlider
            img={product.img}
            title={product.title}
            isFav={isFav}
            showFav={!(isMobile && landScape)}
            handleFavBtnClick={handleFavBtnClick}
            showArrows={!(isMobile && landScape)}
            showDots={!(isMobile && landScape)}
          />
          <LandScapeMain>
            <Title landScape={landScape}>{product.title}</Title>
            <Location landScape={landScape}>General Merchandise | Norton</Location>
            {product.tags && (
              <Tags landScape={landScape}>
                {product.tags.map((data, index) => {
                  return (
                    <Tag landScape={landScape} key={index}>
                      <img src={data.image} alt='' />
                      &nbsp;{data.name}
                    </Tag>
                  );
                })}
              </Tags>
            )}

            <PriceInfo landScape={landScape}>
              <Price landScape={landScape}>${product.price}</Price>
              <Bids landScape={landScape}>{product.bids}</Bids>
              <SecondPrice landScape={landScape}>${product.price}</SecondPrice>
              <ZWLPrice landScape={landScape}>ZWL {product.zwl_price}</ZWLPrice>
            </PriceInfo>
            <LandScapeDescription landScape={landScape}>
              <Logo landScape={landScape} src={logo} />
              <Text landScape={landScape}>{product.description}</Text>
            </LandScapeDescription>
            <LandScapeInfo landScape={landScape}>
              <ContactsBox landScape={landScape}>
                <a href='#'>
                  <SvgIcon name={'whatsup'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'email'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'phone'} />
                </a>
              </ContactsBox>
              <Controls landScape={landScape}>
                <FavBtn landScape={landScape} onClick={handleFavBtnClick} isFav={isFav}>
                  <SvgIcon name={'heart-2'} />
                </FavBtn>
                <Button landScape={landScape} isSpareParts={isSpareParts}>
                  {!isSpareParts ? 'Place Bid' : 'Add to Cart'}
                </Button>
                <EyeButton landScape={landScape} isSpareParts={isSpareParts}>
                  <SvgIcon name='eye' />
                </EyeButton>
              </Controls>
            </LandScapeInfo>
          </LandScapeMain>
        </LandScapeContainer>
      )}
    </>
  );
};

export default CategoriesCard;
