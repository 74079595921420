import { GlobalButton } from '../../../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import {
  ImgPlaceholder,
  ImgUploadBtnWrapper,
  ImgUploadGallery,
  ImgUploadGalleryWrapper,
  ImgUploadMain,
  ImgUploadTitle,
  ImgUploadWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-form-hire-item-styled.jsx';

export const ProductImage = () => {
  return (
    <Section>
      <SectionTitle>Product Image</SectionTitle>
      <ImgUploadWrapper>
        <ImgUploadMain>
          <ImgUploadTitle>main product image</ImgUploadTitle>
          <ImgPlaceholder />
          <ImgUploadBtnWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </ImgUploadBtnWrapper>
        </ImgUploadMain>
        <ImgUploadGallery>
          <ImgUploadTitle>product image Gallery</ImgUploadTitle>
          <ImgUploadGalleryWrapper>
            <ImgPlaceholder />
            <ImgPlaceholder />
            <ImgPlaceholder />
            <ImgPlaceholder />
          </ImgUploadGalleryWrapper>
          <ImgUploadBtnWrapper>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name={'upload'} />
              Upload
            </GlobalButton>
          </ImgUploadBtnWrapper>
        </ImgUploadGallery>
      </ImgUploadWrapper>
    </Section>
  );
};
