import React from 'react';
import {
  BottomPart,
  ChassisNumberSearchStyled,
  Subtitle,
  Title,
  TopPart,
  Link
} from './chassis-number-search-styled';
import { InputWrapper, Label, SearchButton, SearchInput } from '../../filters/filters-styled';
import search from '../../../../../assets/search.svg';

const ChassisNumberSearch = () => {
  const [chassisInput, setChassisInput] = React.useState('52WWC10338');
  return (
    <ChassisNumberSearchStyled>
      <TopPart>
        <Title>Search Your Vehicle`s Spare Parts</Title>
        <Subtitle>
          Please enter your vehicle Chassis Number below to see all the spare parts available for
          your vehicle.
        </Subtitle>
        <Label>Search by Chassis Number</Label>
        <InputWrapper>
          <SearchInput value={chassisInput} onChange={(e) => setChassisInput(e.target.value)} />
          <SearchButton>
            <img src={search} alt='' />
          </SearchButton>
        </InputWrapper>
      </TopPart>
      <BottomPart>
        <Link>Select Vehicle Manually</Link>
      </BottomPart>
    </ChassisNumberSearchStyled>
  );
};

export default ChassisNumberSearch;
