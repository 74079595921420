import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import {
  Attributes,
  AttributesList,
  AttributesListItem,
  AttributesTitle,
  Category,
  Description,
  DescriptionColor,
  DescriptionItem,
  DescriptionList,
  DescriptionListItem,
  DescriptionTitle,
  GalleryImages,
  GalleryWrapper,
  ImgPlaceholder,
  ImgPlaceholderBig,
  Main,
  MobileBackBtn,
  MobileTitle,
  PreviewBtn,
  Price,
  SidePreviewStyled,
  Subtitle,
  Title
} from './side-preview-styled';

export const SidePreview = ({ isSidebarHidden, onPreviewButtonClick }) => {
  return (
    <SidePreviewStyled isSidebarHidden={isSidebarHidden}>
      <PreviewBtn onClick={onPreviewButtonClick}>
        <SvgIcon name={'eye'} />
        Hide Product Preview
      </PreviewBtn>
      <MobileBackBtn onClick={onPreviewButtonClick}>
        <SvgIcon name={'chevron-left'} />
        Back to Add New Product
      </MobileBackBtn>
      <MobileTitle>Product Preview</MobileTitle>
      <GalleryWrapper>
        <ImgPlaceholderBig />
        <GalleryImages>
          <ImgPlaceholder />
          <ImgPlaceholder />
          <ImgPlaceholder />
          <ImgPlaceholder />
        </GalleryImages>
      </GalleryWrapper>
      <Main>
        <Category>Eltrons Electronics</Category>
        <Title>Product Name</Title>
        <Price>$Price</Price>
        <Subtitle>Stock Quantity</Subtitle>
        <Description>
          Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
          morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo
          ornare viverra leo ornare viverra.
        </Description>
        <DescriptionItem>
          <DescriptionTitle>Color:</DescriptionTitle>
          <DescriptionList>
            <DescriptionColor />
            <DescriptionColor />
            <DescriptionColor />
            <DescriptionColor />
          </DescriptionList>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionTitle>Storage Capacity:</DescriptionTitle>
          <DescriptionList>
            <DescriptionListItem>16 GB</DescriptionListItem>
            <DescriptionListItem> 32 GB</DescriptionListItem>
          </DescriptionList>
        </DescriptionItem>

        <Attributes>
          <AttributesTitle>Product Attributes</AttributesTitle>
          <AttributesList>
            <AttributesListItem>
              <span>Brand:</span> Huawei
            </AttributesListItem>
            <AttributesListItem>
              <span>Body:</span> 153x67x8.3mm, 161g; Glass front (Gorilla Glass Victus), plastic
              back, plastic frame; IP65/IP68 dust/water resistant (up to 1.5m for 30 mins)
            </AttributesListItem>
            <AttributesListItem>
              <span>Colour:</span> Red, Black, Gold, Matte Black
            </AttributesListItem>
            <AttributesListItem>
              <span>Rear camera:</span> Wide (main): 12 MP, f/1.8, 27mm, 1/2.8", OIS, PDAF; Ultra
              wide angle: 8 MP, f/2.2, 120˚, 16mm, 1/4.0"; Telephoto: 8 MP, f/2.2, 54mm, 1/4.4",
              PDAF, 2x optical zoom
            </AttributesListItem>
            <AttributesListItem>
              <span>Front camera:</span> 8 MP, f/2.0, 27mm (wide), 1/4.0"
            </AttributesListItem>
            <AttributesListItem>
              <span>Battery:</span> 5000mAh; Fast charging, Quick Charge, USB Power Delivery
            </AttributesListItem>
            <AttributesListItem>
              <span>Display:</span> 6.0" OLED, HDR, 1080x2520px resolution, 21:9 aspect ratio,
              457ppi; Triluminos display
            </AttributesListItem>
            <AttributesListItem>
              <span>Memory:</span> 128GB 6GB RAM; UFS; microSDXC (uses shared SIM slot)
            </AttributesListItem>
            <AttributesListItem>
              <span>OS/Software:</span> Android 12
            </AttributesListItem>
          </AttributesList>
        </Attributes>
      </Main>
    </SidePreviewStyled>
  );
};
