import React, { useEffect, useRef, useState } from 'react';

import Input from '../input/input';
import HeaderTopSection from './components/header-top-section/header-top-section';

import { Link } from 'react-router-dom';
import logo from '../../assets/ezimall_logo.svg';
import { SvgIcon } from '../svg-icon/svg-icon';
import { CartPopup } from './components/cart-popup/cart-popup';
import { StoreFinderBtn } from './components/store-finder-btn/store-finder-btn';
import { StoreFinderInput } from './components/store-finder-input/store-finder-input';
import {
  BurgerIconWrapper,
  CartButton,
  CartPopupWrapper,
  Container,
  HeaderStyled,
  HeaderWrapper,
  Inner,
  InputWrapper,
  LoginRegisterButton,
  Logo,
  LogoutBtn,
  StoreLocatorMobile
} from './header-styled';
import { ShopByCategoryMenu } from './components/shop-by-category-menu/shop-by-category-menu';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Categories from '../input/components/categories/categories';
import { useAuth } from '../../context/auth-context';
import { useNavigate } from 'react-router';

const Header = ({ isLoggedIn = false, isShopFinder = false, onStoreFinderSearch }) => {
  const { isAuth, logout } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState('');
  const [isCartOpened, setIsCartOpened] = useState(false);
  const [isShopByCategoryMenuOpened, setIsShopByCategoryMenuOpened] = useState(false);
  const [isCategoryDropdownOpened, setIsCategoryDropdownOpened] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (isShopByCategoryMenuOpened) {
      disableBodyScroll(ref.current);
    } else {
      enableBodyScroll(ref.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isShopByCategoryMenuOpened]);
  return (
    <>
      <HeaderWrapper>
        <HeaderTopSection />
        <HeaderStyled>
          <Container>
            <Inner>
              <Link to={'/'} className={'logo-wrapper'}>
                <Logo src={logo} alt='ezimall'></Logo>
              </Link>
              <div
                className={`category-dropdown ${isShopByCategoryMenuOpened && 'opened'}`}
                onClick={() => {
                  setIsShopByCategoryMenuOpened(!isShopByCategoryMenuOpened);
                }}
              >
                <BurgerIconWrapper>
                  {isShopByCategoryMenuOpened ? (
                    <svg
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15 2.5L3 14.5M3 2.5L15 14.5'
                        stroke='#388638'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  ) : (
                    <SvgIcon name={'burger'} />
                  )}
                </BurgerIconWrapper>
                <p>Shop by Category</p>
              </div>
              <InputWrapper>
                {isShopFinder ? (
                  <StoreFinderInput onSubmit={onStoreFinderSearch} />
                ) : (
                  <Input
                    height={'40px'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    dropdownOnClick={() => setIsCategoryDropdownOpened(!isCategoryDropdownOpened)}
                    placeholder={'Search...'}
                    borderColor={'#F76D21'}
                  />
                )}
                <Categories isOpen={isCategoryDropdownOpened} />
              </InputWrapper>
              <div className={`preferences ${isAuth && 'preferences--loggedin'}`}>
                <div className='preferences__item'>
                  <StoreLocatorMobile>
                    <StoreFinderBtn />
                  </StoreLocatorMobile>
                </div>
                {isAuth && (
                  <>
                    <div className='preferences__item preferences__item--account'>
                      <SvgIcon name={'account'} />
                    </div>
                    <div className='preferences__item'>
                      <Link to={'#'} className='preferences__link'>
                        <SvgIcon name={'heart-2'} />
                      </Link>
                    </div>
                  </>
                )}

                <div className='preferences__item'>
                  <CartButton
                    className='preferences__item'
                    onClick={() => {
                      setIsCartOpened(!isCartOpened);
                    }}
                  >
                    <SvgIcon name={'cart'} />
                  </CartButton>
                </div>
              </div>
              {!isAuth && (
                <LoginRegisterButton>
                  <Link to={'/login'}>Log In</Link>
                  <div />
                  <Link to={'/register'}>Register</Link>
                </LoginRegisterButton>
              )}
            </Inner>
          </Container>
        </HeaderStyled>
        <CartPopupWrapper isCartOpened={isCartOpened}>
          <CartPopup
            isOpened={isCartOpened}
            onCloseBtnClick={() => {
              setIsCartOpened(false);
            }}
          />
        </CartPopupWrapper>
      </HeaderWrapper>
      <ShopByCategoryMenu isOpened={isShopByCategoryMenuOpened} ref={ref} />
    </>
  );
};

export default Header;
