import React, { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  MobileSearch,
  DropdownsWrapper,
  LeftSide,
  FilterButton,
  MenuChildrenWrapper
} from './all-followers-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import Table from '../../../../components/table/table';
import { columnsData, columnsRows } from './mock';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import filter from '../../../../assets/filter.svg';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';
import { MobSearch } from '../../../../components/mob-search/mob-search';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Customers' },
  { link: '#', name: 'All Followers' }
];

const DropdownItems = ['Location'];

export const menuChildren = () => {
  return (
    <MenuChildrenWrapper>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 9L5.00001 5.00002M5.00001 5.00002L9 1M5.00001 5.00002L1 1M5.00001 5.00002L9 9'
          stroke='#E54D45'
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      Block
    </MenuChildrenWrapper>
  );
};

const CustomerList = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Followers</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
          </LeftSide>
        </Navigation>
        <Table
          columnsData={columnsData}
          columnsRows={columnsRows}
          hasCheckbox
          hasMenu
          minWidth={'728px'}
          menuChildren={menuChildren()}
        />
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default CustomerList;
