import storeImg from '../components/search-results/img/store-temp-img.png';
import storeLogo from '../components/search-results/img/store-temp-logo.png';

export const storeFinderProducts = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  }
];

export const storeFinderStoresMock = [
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -19.23878101020672,
      lng: 29.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  },
  {
    img: storeImg,
    logo: storeLogo,
    title: 'Beta CompanyPage Appliences — Beta CompanyPage Appliences',
    description: 'General Merchandise',
    location: {
      lat: -20.23878101020672,
      lng: 28.339569563132443
    },
    address: '33 Robson Manyika/Orr Street (Next to Central Vehicle Registration) Harare CBD',
    rate: 3.5,
    reviews: 51,
    link: '#',
    text: 'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra amet. Quam etiam nec nullam morbi leo ornare viverra amet.'
  }
];
