import styled from 'styled-components';
import { LatoFont, mainContainer } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
`;

export const BlogStyled = styled.div`
  overflow-x: hidden;
`;

export const Main = styled.div`
  margin-top: 64px;
  display: flex;
  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  flex-basis: calc(100% - 226px);
  padding-left: 64px;
  min-width: 0;
  @media ${device.tablet} {
    padding-left: 0;
    flex-basis: auto;
  }
`;

export const Sidebar = styled.div`
  flex-basis: 226px;
  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 12px;
  padding: 24px 16px;
  height: 750px;
  @media ${device.tablet} {
    height: auto;
    margin-bottom: 56px;
    flex-basis: auto;
  }
`;

export const Menu = styled.div`
  padding-left: 12px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  ${LatoFont};

  & + & {
    margin-top: 8px;
  }
  svg {
    margin-right: 8px;
  }
`;

export const MenuSection = styled.div`
  padding-bottom: 16px;

  & + & {
    padding-top: 16px;
    border-top: 1px solid var(--main-gray-2);
  }
`;

export const MenuTitle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;

  margin-bottom: 10px;
  svg {
    margin-right: 8px;
  }
`;
