import React, { useState } from 'react';
import filter from '../../../../assets/filter.svg';
import search from '../../../../assets/search.svg';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import Table from '../../../../components/table/table';
import Dropdown from '../dropdown/dropdown';
import {
  BoxCheckbox,
  ChecbkoxText,
  Container,
  DropdownsWrapper,
  FilterButton,
  InputWrapper,
  LeftSide,
  MobileSearch,
  Navigation,
  SearchButton,
  SearchInput
} from './items-styled';
import { columnsData, columnsRows } from './mock';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

const DropdownItems = ['Product Type', 'Status', 'Category'];

const Items = () => {
  const [quickSearch, setQuickSearch] = useState('');
  return (
    <Container>
      <Navigation>
        <LeftSide>
          <FilterButton>
            <img src={filter} alt='' />
            Filters
          </FilterButton>
          <MobileSearch>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 15L12.2779 12.2778M14.2222 7.61111C14.2222 11.2623 11.2623 14.2222 7.61111 14.2222C3.95989 14.2222 1 11.2623 1 7.61111C1 3.95989 3.95989 1 7.61111 1C11.2623 1 14.2222 3.95989 14.2222 7.61111Z'
                stroke='#29263a'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Search</p>
          </MobileSearch>
          <InputWrapper>
            <SearchInput
              placeholder={'Quick Search...'}
              value={quickSearch}
              onChange={(e) => setQuickSearch(e.target.value)}
            />
            <SearchButton>
              <img src={search} alt='' />
            </SearchButton>
          </InputWrapper>
          <DropdownsWrapper>
            {DropdownItems.map((data, index) => {
              return <Dropdown name={data} key={index} withBorders />;
            })}
          </DropdownsWrapper>
          <BoxCheckbox>
            <GlobalCheckbox>
              <ChecbkoxText>Deals Of The Day</ChecbkoxText>
            </GlobalCheckbox>
          </BoxCheckbox>
          <BoxCheckbox>
            <GlobalCheckbox>
              <ChecbkoxText>Featured Product</ChecbkoxText>
            </GlobalCheckbox>
          </BoxCheckbox>
          <GlobalButton type='secondary'>
            <SvgIcon name='plus-second' />
            <p>Add items</p>
          </GlobalButton>
        </LeftSide>
      </Navigation>
      <Table columnsData={columnsData} columnsRows={columnsRows} hasMenu />
    </Container>
  );
};

export default Items;
