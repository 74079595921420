import { StyledEyeButton } from './eye-button-styled';

const VisibleEye = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.2821 11C14.2821 12.815 12.8154 14.2817 11.0004 14.2817C9.18542 14.2817 7.71875 12.815 7.71875 11C7.71875 9.18499 9.18542 7.71832 11.0004 7.71832C12.8154 7.71832 14.2821 9.18499 14.2821 11Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9999 18.5808C14.2357 18.5808 17.2515 16.6742 19.3507 13.3742C20.1757 12.0817 20.1757 9.90917 19.3507 8.61667C17.2515 5.31667 14.2357 3.41 10.9999 3.41C7.76402 3.41 4.74819 5.31667 2.64902 8.61667C1.82402 9.90917 1.82402 12.0817 2.64902 13.3742C4.74819 16.6742 7.76402 18.5808 10.9999 18.5808Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const HiddenEye = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.3196 8.68082L8.68125 13.3192C8.08542 12.7233 7.71875 11.9075 7.71875 11C7.71875 9.18499 9.18542 7.71832 11.0004 7.71832C11.9079 7.71832 12.7237 8.08499 13.3196 8.68082Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3349 5.28916C14.7307 4.07916 12.8974 3.41916 10.9999 3.41916C7.76402 3.41916 4.74819 5.32583 2.64902 8.62583C1.82402 9.91833 1.82402 12.0908 2.64902 13.3833C3.37319 14.52 4.21652 15.5008 5.13319 16.2892'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.71875 17.9025C8.76375 18.3425 9.87292 18.5808 11.0004 18.5808C14.2362 18.5808 17.2521 16.6742 19.3512 13.3742C20.1762 12.0817 20.1762 9.90916 19.3512 8.61666C19.0487 8.14 18.7187 7.69083 18.3796 7.26917'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.2178 11.6417C13.9795 12.9342 12.9253 13.9883 11.6328 14.2267'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.68051 13.3192L1.83301 20.1667'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.1668 1.83334L13.3193 8.68084'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const EyeButton = ({ isVisible, onClick }) => {
  return (
    <StyledEyeButton isVisible={isVisible} onClick={onClick} type={'button'}>
      {isVisible ? <VisibleEye /> : <HiddenEye />}
    </StyledEyeButton>
  );
};
