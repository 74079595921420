import styled, { css } from 'styled-components';
import { title20 } from '../../../../../../assets/style-helpers/typography';
import { LatoFont } from '../../../../../../assets/style-helpers/style-helpers';
import icon from './check-circle.svg';

export const Container = styled.div`
  padding: 32px;
  border: 1px solid var(--divider);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.isCurrent &&
    css`
      background: #d4ecd4;
      border: 1px solid #dcdcdc;
      ${Price} {
        border: 1px dashed #388638;
      }
    `}
  ${(props) =>
    props.isAccent &&
    css`
      background: var(--main-green);
      border-color: transparent;
      color: var(--main-white);
      ${Title} {
        color: var(--main-white);
      }
      ${Description} {
        color: var(--main-white);
      }
      ${Price} {
        span {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    `}
`;

export const Title = styled.div`
  ${title20};
  margin-bottom: 12px;
  margin-top: 4px;
  color: var(--main-green);
`;

export const Description = styled.div`
  color: var(--main-gray-3);
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const Price = styled.div`
  padding: 8px 20px;
  border: 1px dashed var(--divider);
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
  ${title20};
  span {
    ${LatoFont};
    font-size: 12px;
    color: var(--secondary-text);
    margin-left: 4px;
  }
`;

export const Features = styled.div`
  border: 1px solid var(--divider);
  filter: drop-shadow(0px 4px 60px rgba(70, 91, 131, 0.1));
  border-radius: 12px;
  background: var(--main-white);
  ${LatoFont};
  color: var(--main-text);
  padding: 20px;
  margin-bottom: 24px;

  span + span {
    display: block;
    color: var(--secondary-text);
    font-size: 12px;
  }

  div {
    padding-left: 28px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 18px;
      height: 18px;
      background: url(${icon}) no-repeat center;
      background-size: 100% 100%;
    }
  }

  div + div {
    margin-top: 12px;
  }
`;

export const CurrentPlan = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-green);
  width: 100%;
  border-radius: 6px;
  background: var(--white, #fff);
  svg {
    margin-right: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;
