import { ReactComponent as ApplyChanges } from '../../../../../assets/apply-changes.svg';
import { ReactComponent as Calendar } from '../../../../../assets/calendar-respond.svg';
import { ReactComponent as Clock } from '../../../../../assets/clock-respond.svg';
import { ReactComponent as CloseIco } from '../../../../../assets/icons/close.svg';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { GlobalInput } from '../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../components/global-select/global-select';
import { InputGroup } from '../../../../../components/input-group/input-group';

import {
  BoxClose,
  ButtonsWrapper,
  ContactBox,
  ContactName,
  ContactText,
  ContactTitle,
  Content,
  DataText,
  DataTitle,
  Information,
  InformationContact,
  InformationData,
  InputGroupWrapper,
  Main,
  Message,
  MessageContent,
  MessageTitle,
  Section,
  SectionBox,
  Textarea,
  Title,
  ViewService,
  Wrapper
} from './respond-styled.jsx';

import { ReactComponent as XSquareIcon } from './icons/x-square.svg';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const Respond = () => {
  return (
    <>
      <Wrapper>
        <BoxClose>
          <CloseIco />
        </BoxClose>
        <Main>
          <Information>
            <InformationData>
              <DataTitle>Tuesday</DataTitle>
              <DataText>
                <Calendar /> 07 Jul 2022
              </DataText>
              <DataText>
                <Clock /> 09:00
              </DataText>
            </InformationData>
            <InformationContact>
              <ContactTitle>Langton Shoriwa</ContactTitle>
              <ContactBox>
                <ContactName>Phone:</ContactName>
                <ContactText>+263 71 77 562 256</ContactText>
              </ContactBox>
              <ContactBox>
                <ContactName>Email:</ContactName>
                <ContactText>myemail@gmail.com</ContactText>
              </ContactBox>
            </InformationContact>
          </Information>
          <Title>
            Company Registration
            <ViewService>View Service</ViewService>
          </Title>
          <Message>
            <MessageTitle>Message</MessageTitle>
            <MessageContent>
              <Content>Hi!</Content>
              <Content>
                Lorem ipsum dolor sit amet consectetur. Elementum eu tortor nulla enim et turpis
                purus. Cursus lorem vestibulum diam nulla ut donec. Enim vitae neque pellentesque
                sit nibh ut egestas. Viverra tellus tellus congue scelerisque fermentum ipsum
                commodo.
              </Content>
              <Content>Viverra tellus tellus congue scelerisque fermentum ipsum commodo.</Content>
            </MessageContent>
          </Message>
          <Section>
            <InputGroupWrapper>
              <InputGroup label={'Response message'}>
                <Textarea placeholder={'Write your response here'} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <InputGroupWrapper>
              <InputGroup label={'Appointment status'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SectionBox>
              <InputGroup label={'Date'}>
                <GlobalInput placeholder={'Date'} type='date' />
              </InputGroup>
              <InputGroup label={'Time'}>
                <GlobalInput placeholder={'Time'} type='time' />
              </InputGroup>
            </SectionBox>
          </Section>
          <ButtonsWrapper>
            <GlobalButton type='primary'>
              <ApplyChanges />
              Apply Changes
            </GlobalButton>
            <GlobalButton type='gray'>
              <XSquareIcon />
              Cancel
            </GlobalButton>
          </ButtonsWrapper>
        </Main>
      </Wrapper>
    </>
  );
};
