import { ReactComponent as GridIcon } from './img/grid-icon.svg';
import { ReactComponent as ListIcon } from './img/list-icon.svg';

import { ViewSelectionStyled, ViewSelectionItem } from './view-selection-styled';
export const viewType = {
  grid: 'grid',
  list: 'list'
};

export const ViewSelection = ({ currentViewType, setCurrentViewType, isMobileHidden = true }) => {
  return (
    <ViewSelectionStyled isMobileHidden={isMobileHidden}>
      <ViewSelectionItem
        checked={currentViewType === viewType.grid}
        onClick={() => {
          setCurrentViewType(viewType.grid);
        }}
      >
        <GridIcon />
      </ViewSelectionItem>
      <ViewSelectionItem
        checked={currentViewType === viewType.list}
        onClick={() => {
          setCurrentViewType(viewType.list);
        }}
      >
        <ListIcon />
      </ViewSelectionItem>
    </ViewSelectionStyled>
  );
};
