import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';

import { ItemCard } from '../../components/item-card/item-card';

import { CardsWrapper, Subtitle, Title } from './add-new-product-styled.jsx';

import { ReactComponent as CartIcon } from '../../components/icons/cart.svg';
import { ReactComponent as KeyIcon } from '../../components/icons/key.svg';
import { ReactComponent as ServicesIcon } from '../../components/icons/services.svg';
import { ReactComponent as TicketIcon } from '../../components/icons/ticket.svg';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Add New Product' }
];

const productCategories = [
  {
    title: 'General Product',
    subtitle: 'General Merchandise',
    icon: <CartIcon />
  },
  {
    title: 'Services',
    subtitle: 'Trades & Services',
    icon: <ServicesIcon />
  },
  {
    title: 'Rentals',
    subtitle: 'Houses, Rooms, Offices & More',
    icon: <KeyIcon />
  },
  {
    title: 'Bookings',
    subtitle: 'Hotels, BnB, Tickets & More',
    icon: <TicketIcon />
  }
];

export const AddNewProductAccount = () => {
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Add New Product</Title>
      <Subtitle>Select the type of product you would like to upload</Subtitle>
      <CardsWrapper>
        {productCategories.map((item, i) => {
          return <ItemCard title={item.title} icon={item.icon} subtitle={item.subtitle} key={i} />;
        })}
      </CardsWrapper>
    </>
  );
};
