import React from 'react';

import { GlobalButton } from 'components/global-button/global-button';
import { Container, LogInButton, Title } from './logged-out-banner-styled';

import { ReactComponent as UserCircleIcon } from './user-circle.svg';
import { ReactComponent as UserIcon } from './user.svg';
import { useNavigate } from 'react-router';

export const LoggedOutBanner = ({ onLogginBtnClick }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <UserCircleIcon />
      <Title>
        Welcome to <br />
        Everything Zimbabwean
      </Title>
      <GlobalButton
        onClick={() => {
          navigate('/register');
        }}
      >
        <UserIcon />
        Register Now
      </GlobalButton>
      <LogInButton onClick={onLogginBtnClick}>Log In</LogInButton>
    </Container>
  );
};
