import { SvgIcon } from 'components/svg-icon/svg-icon';
import { AdvBanner } from 'components/adv-banner/adv-banner';
import { PCardSmall } from 'components/p-card-small/p-card-small';

import { SideMenu } from './components/side-menu/side-menu';
import { LatestOffers } from './components/latest-offers/latest-offers';

import {
  BottomBannerWrapper,
  Card,
  CardsWrapper,
  Container,
  HomeMainStyled,
  Main,
  MainBottom,
  MainTop,
  MobileSidebar,
  SidebarWrapper,
  TagsWrapper,
  Wrapper
} from './home-main-styled';
import { Tags } from './components/tags/tags';
import { Sidebar } from './components/sidebar/sidebar';
import { useState } from 'react';
import { LoggedOutBanner } from './components/logged-out-banner/logged-out-banner';
import { AdvBannerSlider } from '../../../../components/adv-banner-slider/adv-banner-slider';
import { useAuth } from '../../../../context/auth-context';

const products = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    delivery: true,
    rate: 3.5,
    reviews: 51,
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    delivery: false,
    rate: 3.5,
    reviews: 51,
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    price: '$20',
    old_price: '$40',
    delivery: true,
    rate: 3.5,
    reviews: 51,
    img: 'https://picsum.photos/200/300',
    description: 'General Merchandise | Norton'
  }
];

export const HomeMain = () => {
  const { isAuth, login } = useAuth();
  return (
    <HomeMainStyled>
      <Container>
        <TagsWrapper>
          <Tags />
        </TagsWrapper>
        <Wrapper>
          <SidebarWrapper>
            <Sidebar>
              <>
                <span>Store Categories</span>
                <SvgIcon name={'label'}></SvgIcon>
              </>
              <SideMenu />
            </Sidebar>
          </SidebarWrapper>
          <Main>
            <MainTop>
              <AdvBannerSlider />
            </MainTop>
            <MainBottom>
              <MobileSidebar>
                {isAuth ? (
                  <Sidebar>
                    <>
                      <span>Latest Buyer Offers</span>
                      <SvgIcon name={'msg'}></SvgIcon>
                    </>
                    <LatestOffers />
                  </Sidebar>
                ) : (
                  <LoggedOutBanner
                    onLogginBtnClick={() => {
                      login();
                    }}
                  />
                )}
              </MobileSidebar>
              <BottomBannerWrapper>
                <AdvBanner
                  buttonText={'Spend & Save'}
                  title={'Spend US $30'}
                  img={'https://picsum.photos/200/300'}
                  titleSize={'sm'}
                />
              </BottomBannerWrapper>
              <CardsWrapper>
                {products.map((product, index) => {
                  return (
                    <Card key={index} className={`${index === 2 && 'lp-hide'}`}>
                      <PCardSmall product={product} />
                    </Card>
                  );
                })}
              </CardsWrapper>
            </MainBottom>
          </Main>
          <SidebarWrapper>
            {isAuth ? (
              <Sidebar>
                <>
                  <span>Latest Buyer Offers</span>
                  <SvgIcon name={'msg'}></SvgIcon>
                </>
                <LatestOffers />
              </Sidebar>
            ) : (
              <LoggedOutBanner
                onLogginBtnClick={() => {
                  login();
                }}
              />
            )}
          </SidebarWrapper>
        </Wrapper>
      </Container>
    </HomeMainStyled>
  );
};
