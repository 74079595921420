import styled, { css } from 'styled-components';
import { device } from './breakpoints';

export const buttonReset = css`
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
`;

export const linkReset = css`
  text-decoration: none;
  color: inherit;
`;

export const inputReset = css`
  border: none;
  &:focus {
    outline: none;
  }
`;

export const ulReset = css`
  padding-left: 0;
  list-style-type: none;
  margin: 0;
`;

export const mainContainer = css`
  max-width: 1632px;
  padding: 0 16px;
  margin: 0 auto;
  @media ${device.laptop} {
    max-width: 1264px;
  }

  @media ${device.tablet} {
    max-width: 768px;
    padding: 0 20px;
  }
  @media ${device.mobile} {
    max-width: 530px;
    padding: 0 15px;
  }
`;

export const headerContainer = css`
  padding: 0 103px;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

export const RoundBtn = styled.button`
  ${buttonReset};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    width: 40px;
    height: 40px;
  }
`;

export const LatoFont = css`
  font-family: 'Lato', sans-serif;
`;

export const PoppinsFont = css`
  font-family: 'Poppins', sans-serif;
`;

export const scrollbarStyles = css`
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f7f7f7;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(212, 236, 212, 0.32);
    border-radius: 8px;
  }
`;

export const cardStyle = css`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
`;
