import {
  Content,
  Container,
  Top,
  Description,
  Tags,
  TopItem,
  Price,
  PriceZWL,
  Location,
  Title,
  Bottom,
  ImgWrapper,
  Tag,
  Btns
} from './request-card-styled';
import { GlobalButton } from '../../../global-button/global-button';
import { SvgIcon } from '../../../svg-icon/svg-icon';

export const RequestCard = ({ data }) => {
  return (
    <Container>
      <ImgWrapper>
        <img src={data.img} alt='' />
      </ImgWrapper>
      <Content>
        <Top>
          <TopItem>
            <Title>{data.title}</Title>
            <Location>{data.location}</Location>
          </TopItem>
          <TopItem>
            <Price>{data.price}</Price>
            <PriceZWL>{data.priceZWL}</PriceZWL>
          </TopItem>
        </Top>
        <Description>{data.description}</Description>
        <Bottom>
          <Tags>
            {data.tags.map((item, i) => (
              <Tag key={i}>
                <span> {item}</span>
                <button>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.3334 4.66675L4.66669 11.3334M4.66669 4.66675L11.3334 11.3334'
                      stroke='#ACADB9'
                      strokeWidth='1.6'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
              </Tag>
            ))}
          </Tags>
          <Btns>
            <GlobalButton type={'orange-secondary'}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.25 10H6.25833M10 10H10.0083M13.75 10H13.7583M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 10.9976 2.69478 11.9497 3.04839 12.8204C3.11606 12.9871 3.1499 13.0704 3.165 13.1377C3.17976 13.2036 3.18516 13.2524 3.18517 13.3199C3.18518 13.3889 3.17265 13.4641 3.14759 13.6145L2.65344 16.5794C2.60169 16.8898 2.57582 17.0451 2.62397 17.1573C2.66611 17.2556 2.7444 17.3339 2.84265 17.376C2.95491 17.4242 3.11015 17.3983 3.42063 17.3466L6.38554 16.8524C6.53591 16.8273 6.61109 16.8148 6.68011 16.8148C6.74763 16.8148 6.79638 16.8202 6.86227 16.835C6.92962 16.8501 7.01294 16.8839 7.17958 16.9516C8.05025 17.3052 9.00238 17.5 10 17.5ZM6.66667 10C6.66667 10.2301 6.48012 10.4167 6.25 10.4167C6.01988 10.4167 5.83333 10.2301 5.83333 10C5.83333 9.76988 6.01988 9.58333 6.25 9.58333C6.48012 9.58333 6.66667 9.76988 6.66667 10ZM10.4167 10C10.4167 10.2301 10.2301 10.4167 10 10.4167C9.76988 10.4167 9.58333 10.2301 9.58333 10C9.58333 9.76988 9.76988 9.58333 10 9.58333C10.2301 9.58333 10.4167 9.76988 10.4167 10ZM14.1667 10C14.1667 10.2301 13.9801 10.4167 13.75 10.4167C13.5199 10.4167 13.3333 10.2301 13.3333 10C13.3333 9.76988 13.5199 9.58333 13.75 9.58333C13.9801 9.58333 14.1667 9.76988 14.1667 10Z'
                  stroke='#F76D21'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Message
            </GlobalButton>
            <GlobalButton type={'primary'}>
              <SvgIcon name={'eye'} />
              Details
            </GlobalButton>
          </Btns>
        </Bottom>
      </Content>
    </Container>
  );
};
