import React, { useState } from 'react';

import {
  ButtonMobile,
  CardsSection,
  ControlsSecond,
  FilterButton,
  GridButton,
  GridButtonTablet,
  Header,
  HorizontalLine,
  ListButton,
  MotorVehiclesStyled,
  RightSection,
  SortBy,
  Title,
  TradesNav,
  TradesNavMobile,
  TypesSection,
  View,
  LandScapeCardSection,
  Tag,
  TagsSection,
  ClearAll,
  PaginationWrapper
} from './motor-vehicles-styled';

import CategoriesCard from '../../../../../components/categories-card/categories-card';
import InputWithLabel from '../../../../../components/input-with-label/input-with-label';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';

import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import filter from '../../../../../assets/filter.svg';
import grid from '../../../../../assets/grid.svg';
import gridBlue from '../../../../../assets/grid_blue.svg';
import list from '../../../../../assets/list.svg';
import image4 from '../../../../../assets/spare-parts-card-image.png';
import miles from '../../../../../assets/miles.svg';
import year from '../../../../../assets/year.svg';
import car from '../../../../../assets/car-card-icon.svg';
import closeIcon from '../../../../../assets/tag-close-icon.svg';
import {
  NearMeBtnWrapper,
  RealStateAdSpace,
  SearchWrapper,
  SortWrapper,
  TagsWrapper
} from '../../../../categories-real-estate/components/main/real-estate/real-estate-styled';
import { CARS } from '../../../../../dummy-data/cars';
import { ViewSelection, viewType } from '../../../../../components/view-selection/view-selection';
import { LoadMore } from '../../../../../components/load-more/load-more';
import { GlobalButton } from '../../../../../components/global-button/global-button';
import { ReactComponent as Pin } from '../../../../categories-real-estate/components/main/real-estate/pin.svg';
import { SelectWithLabel } from '../../../../../components/select-with-label/select-with-label';
import { ResultsPerPage } from '../../../../../components/results-per-page/results-per-page';
import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import Pagination from '../../../../../components/pagination/pagination';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

export const SPARE_PARTS_CARDS = [
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image4, image4, image4, image4],
    title: 'Klokker Brake Disc Backing Plate (Front Left)',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: miles,
        name: '20020km'
      },
      {
        image: car,
        name: 'Toyota'
      }
    ],
    price: '20 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  }
];

const MotorVehicles = ({ isSpareParts, onFilterBtnClick }) => {
  const [search, setSearch] = React.useState('');
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  return (
    <MotorVehiclesStyled>
      <CategoriesTopFilter
        searchPlaceholder={
          isSpareParts ? 'Search in Motor Vehicles Spare Parts' : 'Search in Motor Vehicles'
        }
        search={search}
        setSearch={setSearch}
        setCurrentViewType={setCurrentViewType}
        currentViewType={currentViewType}
        isMobileViewHidden={false}
        title={isSpareParts ? 'Motor Vehicles Spare Parts' : 'Motor Vehicles'}
        onFilterBtnClick={onFilterBtnClick}
      />
      {currentViewType === viewType.grid ? (
        <CardsSection>
          <>
            {!isSpareParts ? (
              <>
                {CARDS.map((data, index) => {
                  if (index === 4) {
                    return (
                      <React.Fragment key={index}>
                        <RealStateAdSpace />
                        <CategoriesCard product={data} isSpareParts={isSpareParts} />
                      </React.Fragment>
                    );
                  }
                  return <CategoriesCard key={index} product={data} isSpareParts={isSpareParts} />;
                })}
              </>
            ) : (
              ''
            )}
          </>
          <>
            {isSpareParts ? (
              <>
                {SPARE_PARTS_CARDS.map((data, index) => {
                  if (index === 4) {
                    return (
                      <React.Fragment key={index}>
                        <RealStateAdSpace />
                        <CategoriesCard product={data} isSpareParts={isSpareParts} />
                      </React.Fragment>
                    );
                  }
                  return <CategoriesCard key={index} product={data} isSpareParts={isSpareParts} />;
                })}
              </>
            ) : (
              ''
            )}
          </>
        </CardsSection>
      ) : (
        <LandScapeCardSection>
          <>
            {!isSpareParts ? (
              <>
                {CARDS.map((data, index) => (
                  <CategoriesCard
                    key={index}
                    product={data}
                    landScape
                    isSpareParts={isSpareParts}
                  />
                ))}{' '}
              </>
            ) : (
              ''
            )}
          </>
          <>
            {isSpareParts && (
              <>
                {SPARE_PARTS_CARDS.map((data, index) => (
                  <CategoriesCard
                    key={index}
                    product={data}
                    landScape
                    isSpareParts={isSpareParts}
                  />
                ))}
              </>
            )}
          </>
        </LandScapeCardSection>
      )}
      <RealStateAdSpace />
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
    </MotorVehiclesStyled>
  );
};

export default MotorVehicles;
