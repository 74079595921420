import styled from 'styled-components';
import { title20, title30 } from '../../assets/style-helpers/typography';
import { LatoFont } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  max-width: 100%;
  width: 520px;
  @media ${device.mobile} {
    width: 340px;
  }
`;

export const Title = styled.div`
  ${title20};
`;

export const Inner = styled.div`
  margin: 24px 0;
  border-radius: 6px;
  background: #f9f9f9;
  padding: 16px 12px;
  @media ${device.mobile} {
    background: none;
    padding: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 36px;
`;

export const Img = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
  @media ${device.mobile} {
    width: 80px;
    height: 80px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoMain = styled.div``;

export const Cat = styled.div`
  ${LatoFont};
  margin-bottom: 4px;
  color: var(--main-subtext);
`;

export const InfoTitle = styled.div`
  ${title30};
  margin-bottom: 6px;
  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const InfoList = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const InfoListMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
    background: #f9f9f9;
    padding: 16px 12px;
    margin-bottom: 18px;
  }
`;

export const InfoListItem = styled.div`
  ${LatoFont};
  color: var(--main-subtext);
  span {
    font-weight: 600;
    color: #5a5670;
  }
`;

export const Inputs = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const InputGrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  label {
    color: #707070;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
  input {
    padding: 14px 14px 14px 50px;
    border: 1px solid var(--divider);
    border-radius: 6px;
    ${LatoFont};
    width: 100%;
    &:focus,
    &:active,
    &:focus-visible {
      border-color: #f76d21;
      outline: none;
    }
  }
`;

export const InpWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  &::before {
    content: '$';
    ${LatoFont};
    color: var(--main-subtext);
    position: absolute;
    left: 24px;
    top: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  padding: 14px 24px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  height: 110px;
  ${LatoFont};
  &:focus,
  &:active,
  &:focus-visible {
    border-color: #f76d21;
    outline: none;
  }
`;
