import React from 'react';
import { ProductCardSlider } from 'components/product-card-slider/product-card-slider';
import { RateStars } from '../../../../../../components/rate-stars/rate-stars';
import {
  Agent,
  AgentInfo,
  AgentName,
  AgentRole,
  Contacts,
  Container,
  Control,
  Description,
  DescriptionWrapper,
  LinkButton,
  ListViewAgent,
  Location,
  Logo,
  Main,
  RateStarsDesktop,
  Price,
  MainBox,
  ContactsBox,
  Title,
  LocationLand,
  FavBtn
} from './real-estate-card-styled';
import defaultLogo from './img/default-logo.svg';
import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { useWindow } from '../../../../../../hooks/useWindow';
import { viewType } from '../../../../../../components/view-selection/view-selection';

export const RealEstateCard = ({ product, isListView, view }) => {
  const isMobile = useWindow();
  return (
    <Container view={view} isListView={isListView}>
      <ProductCardSlider
        img={product.imgs}
        title={product.title}
        className={isListView && 'card-slider-list-view'}
        showFav={!(isMobile && view === viewType.list)}
      />
      <Main view={view}>
        <MainBox view={view}>
          <RateStarsDesktop view={view}>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <LocationLand view={view}>{product.location}</LocationLand>
          <ContactsBox view={view}>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Title view={view}>{product.title}</Title>
        <Location view={view}>{product.location}</Location>
        <Price view={view}>{product.price}</Price>
        <DescriptionWrapper view={view}>
          {!isListView && (
            <Logo view={view}>
              {product.logo ? (
                <img src={product.logo} alt={product.title} />
              ) : (
                <img src={defaultLogo} alt={product.title} />
              )}
            </Logo>
          )}

          <Description view={view}>
            <span>{product.description}</span>
          </Description>
        </DescriptionWrapper>
        <Control view={view}>
          {isListView ? (
            <ListViewAgent view={view}>
              <Logo>
                {product.logo ? (
                  <img src={product.logo} alt={product.title} />
                ) : (
                  <img src={defaultLogo} alt={product.title} />
                )}
              </Logo>
              <Agent>
                <Logo>
                  <img src={product.agent.img} alt={product.agent.name} />
                </Logo>
                <AgentInfo>
                  <AgentName>{product.agent.name}</AgentName>
                  <AgentRole>{product.agent.role}</AgentRole>
                </AgentInfo>
              </Agent>
            </ListViewAgent>
          ) : (
            <Contacts></Contacts>
          )}
          <FavBtn view={view}>
            <SvgIcon name={'heart-2'} />
          </FavBtn>
          <LinkButton view={view}>
            <SvgIcon name={'eye'} />
          </LinkButton>
        </Control>
      </Main>
    </Container>
  );
};
