import {
  Buttons,
  Container,
  MainButtons,
  MataInfoItem,
  MetaInfo,
  MetaInfoText,
  MetaInfoTitle,
  Price,
  ShopName,
  Title,
  Description,
  ShareBtn,
  MetaInfoTextBig,
  MataInfoItemColumn,
  MobileSection,
  Timer,
  TopTag,
  TopTagShopName,
  TopTagItemName,
  Status,
  OfferSent,
  OfferTimer,
  OfferApproved,
  OfferReject,
  GreenText,
  MobileContactSellerBtn
} from './product-info-styled';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { DeliveryInfo } from './components/delivery-info/delivery-info';
import { useState } from 'react';
import { MakeOfferPopup } from '../make-offer-popup/make-offer-popup';
import loading from 'assets/loading.svg';
import approvedIcon from 'assets/approve.svg';
import rejectIcon from 'assets/reject.svg';

export const ProductInfoAuctionGeneral = ({ status, onContactSellerBtnClick }) => {
  const [isMakeOfferVisible, setMakeOfferVisible] = useState(false);
  const [offerStatus, setOfferStatus] = useState(status);
  return (
    <Container>
      <MobileSection>
        <Timer>
          <SvgIcon name={'clock'} />
          Auction Ends In 06d:04h:10m:55s
        </Timer>
        <TopTag>
          <TopTagShopName>Eltrons Electronics</TopTagShopName>
          <TopTagItemName>Huawei Y7</TopTagItemName>
        </TopTag>
      </MobileSection>
      <ShopName>Eltrons Electronics</ShopName>
      <Title>Huawei Y7</Title>
      <MetaInfo>
        <MataInfoItem>
          <MetaInfoTitle>Item Published On: </MetaInfoTitle>
          <MetaInfoText>10/03/2022</MetaInfoText>
        </MataInfoItem>
        <MataInfoItem>
          <MetaInfoTitle>ID: </MetaInfoTitle>
          <MetaInfoText>EZ124-585-22155-44</MetaInfoText>
        </MataInfoItem>
        <MataInfoItemColumn>
          <MetaInfoTitle>Latest Bid: </MetaInfoTitle>
          <MetaInfoTextBig>$250</MetaInfoTextBig>
        </MataInfoItemColumn>
        <GreenText>Reserve price met</GreenText>
      </MetaInfo>
      <Description>
        Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
        morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare
        viverra leo ornare viverra.
      </Description>
      <Price>$450</Price>

      <Buttons>
        <MainButtons>
          <GlobalButton height={40} type='primary'>
            <SvgIcon name={'wallet'} />
            Buy now
          </GlobalButton>
          {!offerStatus && (
            <GlobalButton
              height={40}
              type={'orange'}
              onClick={() => {
                setMakeOfferVisible(true);
              }}
            >
              <SvgIcon name={'message-text-square'} />
              Make an Offer
            </GlobalButton>
          )}
          {(offerStatus === 'sent' || offerStatus === 'rejected') && (
            <GlobalButton
              height={40}
              type={'orange'}
              onClick={() => {
                setMakeOfferVisible(true);
              }}
            >
              <SvgIcon name={'message-text-square'} />
              Make another offer
            </GlobalButton>
          )}
          {offerStatus === 'approved' && (
            <GlobalButton
              height={40}
              type={'orange'}
              onClick={() => {
                setMakeOfferVisible(true);
              }}
            >
              <SvgIcon name={'message-text-square'} />
              Buy on my offer
            </GlobalButton>
          )}
        </MainButtons>
        <ShareBtn>
          <SvgIcon name={'share'} />
          Share
        </ShareBtn>
      </Buttons>

      {offerStatus === 'sent' && (
        <Status>
          <OfferSent>
            <img src={loading} />
            <span>Your offer has been sent</span>
            <OfferTimer>Expires in: 47:59:59</OfferTimer>
          </OfferSent>
        </Status>
      )}

      {offerStatus === 'approved' && (
        <Status>
          <OfferApproved>
            <img src={approvedIcon} />
            <span>Your offer has been approved</span>
            <button
              onClick={() => {
                setMakeOfferVisible(true);
              }}
            >
              Make another offer
            </button>
          </OfferApproved>
        </Status>
      )}

      {offerStatus === 'rejected' && (
        <Status>
          <OfferReject>
            <img src={rejectIcon} />
            <span>The seller rejected your offer</span>
          </OfferReject>
        </Status>
      )}
      <MobileContactSellerBtn>
        <GlobalButton type={'primary'} onClick={onContactSellerBtnClick}>
          Contact Seller
        </GlobalButton>
      </MobileContactSellerBtn>
      <DeliveryInfo />
      <MakeOfferPopup
        isVisible={isMakeOfferVisible}
        onClose={() => {
          setMakeOfferVisible(false);
        }}
        onMakeOfferBtnClick={() => {
          setOfferStatus('sent');
          setMakeOfferVisible(false);
        }}
      />
    </Container>
  );
};
