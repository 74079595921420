import styled from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  margin-top: 62px;
`;
export const Inner = styled.div`
  display: flex;
  gap: 30px;
  @media ${device.laptop} {
    gap: 16px;
  }
  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

export const MapWrapper = styled.div`
  flex-basis: 626px;
  img {
    max-width: 100%;
  }
  @media ${device.laptop} {
    flex-basis: 300px;
  }
  @media (max-width: 1300px) {
    flex-basis: 200px;
  }
  @media (max-width: 1200px) {
    flex-basis: 300px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 626px);
  @media ${device.laptop} {
    flex-basis: calc(100% - 300px);
  }
  @media (max-width: 1300px) {
    flex-basis: calc(100% - 200px);
  }
  @media (max-width: 1200px) {
    flex-basis: calc(100% - 300px);
  }
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

export const CardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-top: 36px;
  @media ${device.laptop} {
    gap: 16px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const RangeWrapper = styled.div`
  margin-bottom: 32px;
`;
