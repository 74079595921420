import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const UploadBtnWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
  grid-gap: 24px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
