import styled, { css } from 'styled-components';
import { buttonReset, LatoFont } from 'assets/style-helpers/style-helpers';

export const Container = styled.div`
  padding: 4px 16px;
  display: flex;
  border-radius: 20px;
  border: 1px solid var(--divider);
  align-items: center;
  color: var(--main-text);
  ${(props) =>
    props.isButton &&
    css`
      color: #676a75;
      border: none;
      cursor: pointer;
    `}
  & + & {
    margin-left: 20px;
  }
`;

export const CloseBtn = styled.button`
  ${buttonReset};
  margin-right: 4px;
  display: flex;
`;

export const Label = styled.div`
  ${LatoFont};
  font-weight: 700;
`;
