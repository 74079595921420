import styled from 'styled-components';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const TagsWrapper = styled.div`
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 22px;
  }
  @media ${device.mobile} {
    max-width: 100%;
    overflow-y: auto;
  }
`;

export const HomeMainStyled = styled.div`
  padding-top: 40px;
  @media ${device.tablet} {
    padding-top: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }

  @media ${device.mobile} {
    overflow: hidden;
  }
`;

export const SidebarWrapper = styled.div`
  flex-shrink: 0;
  flex-basis: 296px;
  @media ${device.laptop} {
    flex-basis: 275px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const MobileSidebar = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    flex-basis: 365px;
    margin-right: 15px;
  }
  @media ${device.mobile} {
    flex-basis: 100%;
    margin-right: 0;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  margin: 0 24px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    margin: 0 20px;
  }

  @media ${device.tablet} {
    margin: 0 0 14px;
    order: 0;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const Card = styled.div`
  flex-basis: 183px;
  flex-shrink: 0;
  & + & {
    margin-left: 10px;
  }
  @media ${device.tablet} {
    flex-basis: 167px;
  }
  &.lp-hide {
    @media ${device.laptop} {
      display: none;
    }
  }

  &.tb-show {
    @media ${device.tablet} {
      display: block;
    }
  }
`;

export const MainTop = styled.div`
  flex-basis: 50%;
  margin-bottom: 20px;
  min-width: 0;
  @media ${device.tablet} {
    flex-basis: 280px;
    margin-bottom: 15px;
  }
  @media ${device.mobile} {
    padding: 0 15px;
    margin-bottom: 0;
  }
`;

export const MainBottom = styled.div`
  display: flex;
  flex-basis: 50%;
  @media ${device.tablet} {
    flex-basis: 45%;
  }
  @media ${device.mobile} {
    margin-top: 16px;
    padding: 0 15px;
  }
`;

export const BottomBannerWrapper = styled.div`
  margin-right: 20px;
  flex-basis: 375px;
  @media ${device.laptop} {
    flex-basis: 246px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-basis: calc(100% - 395px);

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    flex-basis: calc(100% - 266px);
  }

  @media ${device.tablet} {
    flex-basis: calc(100% - 380px);
  }

  @media ${device.mobile} {
    display: none;
  }
`;
