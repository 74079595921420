import { Inner, Wrapper, Title } from './auth-pages-container-styled';

export const AuthPagesContainer = ({ children, title }) => {
  return (
    <Wrapper>
      <Inner>
        <Title>{title}</Title>
        {children}
      </Inner>
    </Wrapper>
  );
};
