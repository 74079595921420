import Product from '../../../../../assets/product.png';
import Dropdown from '../../dropdown/dropdown';
import { Img, SelectBox, SpanBox } from './create-new-order-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product Name',
    accessor: 'productname'
  },
  {
    Header: 'Colour',
    accessor: 'colour'
  },
  {
    Header: 'Capacity',
    accessor: 'capacity'
  },
  {
    Header: 'Model',
    accessor: 'model'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const columnsRows = [
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    productname: 'Dish Washing Machine',
    colour: (
      <SelectBox>
        <Dropdown name='Maroon' withBorders />
      </SelectBox>
    ),
    capacity: (
      <SelectBox>
        <Dropdown name='3kg' withBorders />
      </SelectBox>
    ),
    model: (
      <SelectBox>
        <Dropdown name='KM100' withBorders />
      </SelectBox>
    ),
    qty: <SpanBox>1</SpanBox>,
    price: <SpanBox>$2000</SpanBox>,
    total: '$2000'
  }
];
