import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { buttonReset, LatoFont } from '../../../../assets/style-helpers/style-helpers';

import arrow from './arrow.svg';

import location from './location.svg';

export const Container = styled.div`
  flex-basis: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  background: #ecf6ec;
  padding: 24px;
  button {
    padding-left: 8px;
    padding-right: 8px;
    svg {
      margin-right: 8px;
    }
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ClearBtn = styled.button`
  ${buttonReset};
  color: #809780;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  @media ${device.tablet} {
    display: none;
  }
`;

export const Title = styled.div`
  color: var(--dark, #29263a);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`;

export const Main = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid var(--main-gray-2);
  background: #fff;
  display: flex;
  padding-left: 30px;
  position: relative;
  flex-grow: 1;

  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 12px;
    background: url(${location}) no-repeat center;
    width: 24px;
    height: 24px;
  }

  @media ${device.tablet} {
    height: 50px;
  }
`;

export const Dropdown = styled.div`
  flex-grow: 1;
`;

export const MobileClearBtn = styled(ClearBtn)`
  display: none;
  @media ${device.tablet} {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
`;
