import { useState } from 'react';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import MakeRequest from '../buyer-requests/components/make-request/make-request';
import { BreadcrumbsBox, Container, Shadow } from './buyer-requests-styled';
import Filters from './components/filters/filters';
import Main from './components/main/main';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Buyer Requests' }
];

const BuyerRequests = () => {
  const [show, setShow] = useState(false);

  const showBlock = (info) => {
    setShow(info);
  };

  return (
    <>
      <Header />
      {show && <Shadow></Shadow>}
      <BreadcrumbsBox>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsBox>
      <Container>
        <Filters />
        <Main showBlock={showBlock} />
        <MakeRequest show={show} showBlock={showBlock} />
      </Container>
    </>
  );
};

export default BuyerRequests;
