import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { linkWithUnderline } from '../../../../assets/style-helpers/typography';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  background: #ffffff;
  /* new */

  box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
  border-radius: 16px;
  padding: 32px;
  @media ${device.tablet} {
    margin-bottom: 32px;
  }

  @media ${device.mobile} {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  color: #acadb9;
  margin-bottom: 20px;
`;

export const List = styled.div``;

export const Total = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-green);
  border-bottom: 1px solid var(--main-gray-2);
`;

export const TotalTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
`;

export const TotalValue = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
`;

export const BottomRight = styled.div``;

export const BottomLeft = styled.div``;

export const TrackingNumber = styled.div`
  font-family: 'Lato', sans-serif;
  margin-bottom: 2px;
  span {
    font-weight: 700;
  }
`;

export const TrackingLink = styled(Link)`
  ${linkWithUnderline};
`;
