import img1 from './img/img.png';
import { Container, List, Title } from './products-you-may-like-styled';
import { ProductsYouMauLikeItem } from './components/products-you-mau-like-item';

const items = [
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' },
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' },
  { title: 'CPU Cooling Fan For Your Home Today', price: '$19.90', img: img1, link: '#' }
];

export const ProductsYouMayLike = () => {
  return (
    <Container>
      <Title>Products You May Like</Title>
      <List>
        {items.map((item, i) => {
          return <ProductsYouMauLikeItem product={item} key={i} />;
        })}
      </List>
    </Container>
  );
};
