export const IndustrialIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 12H12.009M17.4 6.6H17.409M6.6 6.6H6.609M17.4 17.4H17.409M6.6 17.4H6.609M7.32 21H16.68C18.1921 21 18.9482 21 19.5258 20.7057C20.0338 20.4469 20.4469 20.0338 20.7057 19.5258C21 18.9482 21 18.1921 21 16.68V7.32C21 5.80786 21 5.05179 20.7057 4.47423C20.4469 3.96619 20.0338 3.55314 19.5258 3.29428C18.9482 3 18.1921 3 16.68 3H7.32C5.80786 3 5.05179 3 4.47423 3.29428C3.96619 3.55314 3.55314 3.96619 3.29428 4.47423C3 5.05179 3 5.80786 3 7.32V16.68C3 18.1921 3 18.9482 3.29428 19.5258C3.55314 20.0338 3.96619 20.4469 4.47423 20.7057C5.05179 21 5.80786 21 7.32 21ZM12.45 12C12.45 12.2485 12.2485 12.45 12 12.45C11.7515 12.45 11.55 12.2485 11.55 12C11.55 11.7515 11.7515 11.55 12 11.55C12.2485 11.55 12.45 11.7515 12.45 12ZM16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
