import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../assets/style-helpers/style-helpers';
import { AdSpace } from '../../components/ad-space/ad-space';

export const StoresYouLoveWrapper = styled.div`
  ${mainContainer};
  margin-top: 80px;
  margin-bottom: 80px;
  @media ${device.tablet} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const HomeAdSpace = styled(AdSpace)`
  border-radius: 0;
  height: 100px;
`;

export const HomeAdSpace2 = styled(AdSpace)`
  border-radius: 0;
  height: 160px;
  margin-bottom: 80px;
`;
