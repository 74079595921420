import {
  ButtonsWrapper,
  Container,
  Main,
  MobileOpenPreviewBtnWrapper,
  OpenSidebarBtn,
  OpenSidebarBtnSidebar,
  Side,
  SidebarBackground,
  Title,
  Wrapper
} from './business-information-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

import { BusinessInformationSection } from './components/business-information-section';
import { ContactInformationSection } from './components/contact-information-section';
import { OpeningTimesSection } from './components/opening-times-section';
import { SocialMediaSection } from './components/social-media-section';
import { StoreFrontSettingsSection } from './components/store-front-settings-section';
import { BusinessDocumentsSection } from './components/business-documents-section';

import { BusinessSettingsSidebar } from './components/business-settings-sidebar';
import { useState } from 'react';
import { ApproveVendorBanner } from '../../../../components/approve-vendor-banner/approve-vendor-banner';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Business Settings' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const BusinessInformation = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Business Settings</Title>
      <Wrapper>
        <Main>
          <BusinessInformationSection selectOptions={selectOptions} />
          <ContactInformationSection selectOptions={selectOptions} />
          <OpeningTimesSection />
          <SocialMediaSection />
          <StoreFrontSettingsSection />
          <BusinessDocumentsSection />
          <ButtonsWrapper>
            <div>
              <GlobalButton type={'primary'}>
                <SvgIcon name={'check-square'} />
                Save Changes
              </GlobalButton>
              <GlobalButton type={'orange-secondary'}>
                <SvgIcon name={'plus'} />
                Add Another Store
              </GlobalButton>
            </div>
            <MobileOpenPreviewBtnWrapper>
              <GlobalButton
                type={'primary'}
                onClick={() => {
                  setIsSidebarHidden(false);
                }}
              >
                <SvgIcon name={'eye'} />
                Store Front Preview
              </GlobalButton>
            </MobileOpenPreviewBtnWrapper>
          </ButtonsWrapper>
          <ApproveVendorBanner />
        </Main>
        <Side isSidebarHidden={isSidebarHidden}>
          <BusinessSettingsSidebar
            isSidebarHidden={isSidebarHidden}
            setIsSidebarHidden={setIsSidebarHidden}
          />
          <SidebarBackground />
        </Side>
        <OpenSidebarBtn
          isSidebarHidden={isSidebarHidden}
          onClick={() => {
            setIsSidebarHidden(!isSidebarHidden);
          }}
        >
          <SvgIcon name={'eye'} />
          Store Front Preview
        </OpenSidebarBtn>
        <OpenSidebarBtnSidebar
          isSidebarHidden={isSidebarHidden}
          onClick={() => {
            setIsSidebarHidden(!isSidebarHidden);
          }}
        >
          <SvgIcon name={'eye'} />
          Hide Store Front Preview
        </OpenSidebarBtnSidebar>
      </Wrapper>
    </Container>
  );
};
