import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Badge,
  Buttons,
  BuyBtn,
  Container,
  Content,
  Control,
  FavBtn3,
  ImgWrapperSecond,
  MainPrice,
  MainSecond,
  Mark,
  OldPrice,
  Price,
  RateStarsMobile,
  SubTitleSecond,
  Title,
  ZWLPrice
} from './p-card-styled';
import React from 'react';
import { ApproveSeller } from '../approve-seller/approve-seller';

const mark = [
  {
    name: 'Sale',
    color: '#E54D45'
  },
  {
    name: 'New',
    color: '#7CCC67'
  }
];

export const PCardDealsView = ({ product, handleFavBtnClick, isFav }) => {
  return (
    <Container>
      {product.mark &&
        mark.map((mark, index) =>
          product.mark == mark.name ? (
            <Mark color={mark.color} key={index}>
              {mark.name}
            </Mark>
          ) : (
            ''
          )
        )}
      <ImgWrapperSecond>
        <ApproveSeller />
        <img src={product.img} alt={product.title} />
      </ImgWrapperSecond>
      <MainSecond>
        <Title subtitle={product.subtitle}>{product.title}</Title>
        {product.subtitle && <SubTitleSecond>{product.subtitle}</SubTitleSecond>}
        <Content>
          <Price>
            {product.old_price && <OldPrice>{product.old_price}</OldPrice>}
            <MainPrice>{product.price}</MainPrice>
            <ZWLPrice>ZWL 200</ZWLPrice>
          </Price>
          <Control>
            <RateStarsMobile>
              {product.rate && (
                <RateStars rate={product.rate} reviews={product.reviews} color={'#388638'} />
              )}
            </RateStarsMobile>
            <Buttons>
              <BuyBtn>
                <SvgIcon name='cart' />
              </BuyBtn>
              <FavBtn3 onClick={handleFavBtnClick} isFav={isFav}>
                <SvgIcon name={'heart-2'} />
              </FavBtn3>
            </Buttons>
          </Control>
        </Content>
        {product.badge && <Badge>{product.badge}</Badge>}
      </MainSecond>
    </Container>
  );
};
