import styled from 'styled-components';
import { buttonReset } from 'assets/style-helpers/style-helpers';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  ${buttonReset};
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(56, 134, 56, 0.1);
  border-radius: 4px;
  color: var(--main-green);
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const PrevButton = styled(Button)``;

export const NextButton = styled(Button)`
  svg {
    transform: rotate(180deg);
  }
`;

export const Date = styled.div`
  width: 130px;
  text-align: center;
`;
