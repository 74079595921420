import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { buttonReset, LatoFont } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  min-width: 238px;
  margin-right: 62px;

  @media ${device.tablet} {
    min-width: 305px;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--main-white);
    z-index: 1000;
    padding: 0 20px;
    transition: all 0.3s;
    max-height: 100%;
    overflow-y: scroll;
    ${(props) =>
      props.isMobileFilterVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }
`;

export const MobileBg = styled.div`
  position: fixed;
  background: rgba(41, 38, 58, 0.4);
  transition: all 0.3s;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.isMobileFilterVisible
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
}
`;

export const MobileHeader = styled.div`
  display: none;
  @media ${device.tablet} {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      align-items: center;
      display: flex;
      gap: 10px;
    }
  }
`;

export const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-bottom: 38px;
  cursor: pointer;

  @media ${device.mobile} {
    width: unset;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }
`;

export const CloseFilterBtn = styled.div``;

export const Section = styled.div`
  & + & {
    margin-top: 72px;
  }
`;

export const FilterGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const Label = styled.div`
  ${LatoFont};
`;

export const FilterGroupTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ShowMoreBtn = styled.button`
  ${LatoFont};
  ${buttonReset};
  margin: 8px 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  transition: all 0.3s;
  &:hover {
    color: var(--main-green);
  }
`;
