export const ArrowUpIcon = () => {
  return (
    <svg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 20.7998V1.7998M8 1.7998L15 8.7998M8 1.7998L1 8.7998'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
