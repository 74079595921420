import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const AdSpaceWrapper = styled.div`
  display: flex;
  background: #136400;
  border-radius: 12px;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  align-items: center;
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.2);
  height: 299px;
  justify-content: center;
  margin-top: 40px;
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
