import React, { useState } from 'react';

import { Container, BreadcrumbsContainer } from './categories-real-estate-styled';
import Header from '../../components/header/header';
import Main from './components/main/main';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { GlobalFilter } from '../../components/global-filter/global-filter';

const breadcrumbs = [
  {
    link: '#',
    name: 'Home'
  },
  {
    link: '#',
    name: 'Real Estate'
  }
];

const CategoriesRealEstate = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main onFilterBtnClick={() => setMobileFilterVisible(!isMobileFilterVisible)} />
      </Container>
    </>
  );
};

export default CategoriesRealEstate;
