import { ClearAll, Tag, TagsSection, TagsWrapper } from '../../categories-top-filter-styled';
import closeIcon from '../../../../assets/tag-close-icon.svg';
import { ResultsPerPage } from '../../../results-per-page/results-per-page';
import React from 'react';
import { TAGS } from '../../categories-top-filter';

export const Tags = () => {
  return (
    <TagsSection>
      {TAGS.map((data, index) => {
        return (
          <Tag key={index}>
            <img src={closeIcon} alt='' />
            {data.name}
          </Tag>
        );
      })}
      <ClearAll>
        <img src={closeIcon} alt='' />
        Clear all
      </ClearAll>
    </TagsSection>
  );
};
