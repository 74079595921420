import {
  CheckboxWrapper,
  InputGroupWrapper,
  Section,
  SectionSubtitle,
  SectionTitle,
  Subtext
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';
import { Checkboxes, MapWrapper } from '../business-information-styled';
import Checkbox from 'components/checkbox/checkbox';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalSelect } from 'components/global-select/global-select';
import { Link } from 'react-router-dom';
import map from '../img/map.jpg';

export const ContactInformationSection = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Contact Information</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'Business whatsapp number'}>
          <GlobalInputSelect placeholder={'Your WhatsApp Number'} />
        </InputGroup>
        <Subtext>
          Add a WhatsApp to allow your customer to contact you via WhatsApp receive notifications
        </Subtext>
        <Checkboxes>
          <CheckboxWrapper>
            <Checkbox label={'Use the same number for phone calls'} />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox label={'Use this number for notifications'} />
          </CheckboxWrapper>
        </Checkboxes>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Phone number'}>
          <GlobalInputSelect placeholder={'Your Phone Number'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Business email address'}>
          <GlobalInput placeholder={'Enter your business email'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <Checkbox label={'Use this email for notifications'} />
      </InputGroupWrapper>

      <SectionSubtitle>Shop Address</SectionSubtitle>
      <InputGroupWrapper>
        <InputGroup label={'shop / office number'}>
          <GlobalInput placeholder={'e.g. Shop 3, 4th Floor, Galaxy Mall'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'street address'}>
          <GlobalInput placeholder={'e.g. 53 Jason Moyo Ave'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'street address'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'province'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'suburb'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <span>
          Does your business have have shops in multiple locations?{' '}
          <Link to={'#'}> Manage Shop Locations</Link>
        </span>
      </InputGroupWrapper>
      <SectionSubtitle>Map Location</SectionSubtitle>
      <MapWrapper>
        <img src={map} alt='' />
      </MapWrapper>
    </Section>
  );
};
