import styled from 'styled-components';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  cursor: pointer;
  ${LatoFont};
  & + & {
    margin-top: 16px;
  }
`;

export const Main = styled.div`
  span {
    margin-left: 6px;
    color: var(--main-subtext);
  }
`;

export const Sub = styled.div`
  margin-top: 16px;
`;

export const Subcategory = styled.div`
  padding: 0 10px;
  & + & {
    margin-top: 8px;
  }
  span {
    margin-left: 6px;
    color: var(--main-subtext);
  }
`;
