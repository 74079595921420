import { Title, TitleWrapper } from '../../properties-styled';
import { CardsWrapper, Container } from './services-styled';
import { ServicesCard } from './components/services-card/services-card';

const servicesMock = [
  {
    img: 'https://picsum.photos/200',
    title: 'House Plan Design For Modern House',
    price: 250
  },
  {
    img: 'https://picsum.photos/200',
    title: 'House Plan Design For Modern House',
    price: 250
  },
  {
    img: 'https://picsum.photos/200',
    title: 'House Plan Design For Modern House',
    price: 250
  },
  {
    img: 'https://picsum.photos/200',
    title: 'House Plan Design For Modern House',
    price: 250
  },
  {
    img: 'https://picsum.photos/200',
    title: 'House Plan Design For Modern House',
    price: 250
  }
];

export const Services = () => {
  return (
    <Container>
      <TitleWrapper>
        <Title>Our Services</Title>
      </TitleWrapper>
      <CardsWrapper>
        {servicesMock.map((service, index) => {
          return <ServicesCard service={service} key={index} />;
        })}
      </CardsWrapper>
    </Container>
  );
};
