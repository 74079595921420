import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { paragraph30 } from '../../../../assets/style-helpers/typography';

export const StoreLocationsStyled = styled.section`
  margin: 0 100px 78px 106px;
  @media ${device.mobile} {
    margin: 0 15px 47px 15px;
  }

  .swiper-slide {
    width: max-content;
  }
`;

export const Title = styled.div`
  ${paragraph30};
  margin-right: 40px;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--main-gray-2);
  margin: 22px 0;
`;

export const Map = styled.img`
  display: block;
  @media ${device.mobile} {
    display: none;
  }
`;

export const MapMobile = styled.img`
  display: none;
  @media ${device.mobile} {
    width: 100%;
    display: block;
  }
`;
