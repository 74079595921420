import { title30 } from 'assets/style-helpers/typography';
import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { LatoFont, mainContainer } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  ${mainContainer};
  display: flex;
  min-height: 100vh;

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
  @media ${device.mobile} {
  }
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;

    @media ${device.laptop} {
      overflow-y: scroll;
      height: auto;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 3px;
        background: rgb(243 243 243);
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(56, 134, 56, 0.1);
      }
    }

    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
    padding-top: 5px;
  }
  @media ${device.mobile} {
    top: 135px;
    padding-top: 12.5px;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 14px;
  }
  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 10px;
  }
`;

export const UploadBtnWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
  grid-gap: 24px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.div`
  ${title30};
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;
