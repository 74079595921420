import { ReactComponent as ThreeDots } from '../../../../assets/three-dots.svg';
import { StyledId, CheckboxWrapperHeader } from './all-followers-styled';
import Checkbox from '../../../../components/checkbox/checkbox';

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Follower Name',
    accessor: 'followerName'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Followers Since',
    accessor: 'followersSince'
  },
  {
    Header: 'Actions',
    accessor: 'dots'
  }
];

export const columnsRows = [
  {
    id: 12520,
    followerName: 'Langton Shoriwa',
    contact: '+263 718 418 254',
    location: 'Harare, ZW',
    followersSince: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  },
  {
    id: 12520,
    followerName: 'Langton Shoriwa',
    contact: '+263 718 418 254',
    location: 'Harare, ZW',
    followersSince: '07 Jul 2022',
    dots: (
      <div>
        <ThreeDots />
      </div>
    )
  }
];
