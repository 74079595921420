import React, { useState } from 'react';

import {
  Container,
  SearchResult,
  TextBold,
  Text,
  TextContainer,
  HorizontalLine
} from './categories-motor-vehicles-styled-specific-search';
import Header from '../../components/header/header';
import Main from './components/main/main';
import { GlobalFilter } from '../../components/global-filter/global-filter';
import { ControlsSecond, SortBy } from './components/main/motor-vehicles/motor-vehicles-styled';
import Dropdown from '../company-page/components/products/dropdown/dropdown';
import { FilterByBuyType } from '../../components/filter-by-buy-type/filter-by-buy-type';
import { SelectWithLabel } from '../../components/select-with-label/select-with-label';
import {
  NearMeBtnWrapper,
  NearMeDesktopText,
  NearMeMobileText
} from '../../components/categories-top-filter/categories-top-filter-styled';
import { GlobalButton } from '../../components/global-button/global-button';
import { ReactComponent as Pin } from '../categories-real-estate/components/main/real-estate/pin.svg';
import { SearchTopFilter } from './components/search-top-filter/search-top-filter';
import { ViewSelection, viewType } from '../../components/view-selection/view-selection';
import { MobViewSelection } from '../categories-motor-vehicles-generic-search/categories-motor-vehicles-styled-generic-search';

const CategoriesRealEstate = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [currentViewType, setCurrentVieType] = useState(viewType.grid);

  return (
    <>
      <Header />
      <SearchResult>
        <div>
          <TextContainer>
            <Text>1048 results for</Text>
            <TextBold>«BMW»</TextBold>
          </TextContainer>
          <div>
            <MobViewSelection>
              <ViewSelection
                isMobileHidden={false}
                setCurrentViewType={setCurrentVieType}
                currentViewType={currentViewType}
              />
            </MobViewSelection>
            <SearchTopFilter />
          </div>
        </div>
        <HorizontalLine />
      </SearchResult>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main
          onFilterBtnClick={() => {
            setMobileFilterVisible(!isMobileFilterVisible);
          }}
          currentViewType={currentViewType}
        />
      </Container>
    </>
  );
};

export default CategoriesRealEstate;
