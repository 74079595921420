import { Sort, SortActive, Sorts } from '../notifications/notifications-styled';
import { ReactComponent as CheckInboxAll } from '../../../../assets/icons/check-inbox.svg';
import { Offer } from './components/offer/offer';
import { Wrapper } from './offers-profile-styled';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';

const offers = [
  {
    type: 'new',
    name: 'John Dow',
    price: '250$',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  },
  {
    type: 'new',
    name: 'John Dow',
    price: '250$',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  },
  {
    type: 'decline',
    name: 'John Dow',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  },
  {
    type: 'approve',
    name: 'John Dow',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  },
  {
    type: 'decline',
    name: 'John Dow',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  },
  {
    type: 'approve',
    name: 'John Dow',
    productName: '#12325 Nike Air Jordan With Cool Lanb',
    time: '2 hours ago'
  }
];

export const OffersProfile = () => {
  return (
    <>
      <Sorts>
        <SortActive>
          <CheckInboxAll />
          All
        </SortActive>
        <Sort>Unread</Sort>
      </Sorts>
      <Wrapper>
        {offers.map((item, i) => (
          <Offer offer={item} key={i} />
        ))}
      </Wrapper>
      <GlobalButton type={'orange-secondary'}>
        <SvgIcon name={'eye'} />
        See all
      </GlobalButton>
    </>
  );
};
