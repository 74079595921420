import React from 'react';
import {
  ButtonMobile,
  ControlsSecond,
  FilterButton,
  GlobalButtonBox,
  Header,
  HorizontalLine,
  InputBox,
  RightSection,
  SortBy,
  Title,
  TradesAndServicesStyled,
  TradesNav,
  TradesNavMobile
} from './navigations-styled';

import filter from '../../../../assets/filter.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { MobSort } from '../../../../components/mob-sort/mob-sort';

const Navigations = ({ showBlock }) => {
  const [search, setSearch] = React.useState('');

  const showBox = () => {
    showBlock(true);
    document.querySelector('html').style.overflow = 'hidden';
  };

  return (
    <TradesAndServicesStyled>
      <Header>
        <Title>Buyer Requests</Title>
        <GlobalButtonBox>
          <GlobalButton onClick={showBox} height={40} type={'orange-secondary'}>
            <SvgIcon name={'plus-second'} />
            Message
          </GlobalButton>
        </GlobalButtonBox>
      </Header>
      <HorizontalLine />
      <TradesNav>
        <InputBox>
          <InputWithLabel
            height={'38px'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'Enter what you are looking for...'}
            borderColor={'#7CCC67'}
          />
        </InputBox>
        <ControlsSecond>
          <SortBy>Sort by:</SortBy>
          <Dropdown name={'Default'} />
        </ControlsSecond>
        <ButtonMobile>
          <SvgIcon name='sort' />
          <p>Sort</p>
        </ButtonMobile>
      </TradesNav>
      <TradesNavMobile>
        <FilterButton>
          <img src={filter} alt='' />
          Filters
        </FilterButton>
        <RightSection>
          <MobSearch />
          <MobSort />
        </RightSection>
      </TradesNavMobile>
    </TradesAndServicesStyled>
  );
};

export default Navigations;
