import banner from './banner.jpg';
import logo from './logo2.jpg';

export const TradesServicesStoreMock = {
  img: banner,
  logo: logo,
  name: 'Properties',
  type: 'Real Estate',
  rate: 4.5,
  reviews: 50,
  propertiesSold: 25,
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ',
  address: '33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD',
  openTime: [
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    '08:00 - 17:00',
    'Closed'
  ]
};
