import { IconWrapper, Inner, Item, Title } from './featured-categories-cards-styled';
import { SvgIcon } from '../svg-icon/svg-icon';

export const FeaturesCategoriesCards = () => {
  return (
    <Inner>
      <Item>
        <IconWrapper>
          <SvgIcon name={'sport'} />
        </IconWrapper>
        <Title>Sports, Hobbies & Leisure</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'home'} />
        </IconWrapper>
        <Title>Home & Garden</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'clothing'} />
        </IconWrapper>
        <Title>Clothing & Apparel</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'electronic'} />
        </IconWrapper>
        <Title>Electronic Goods</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'motor'} />
        </IconWrapper>
        <Title>Motor Spares & Accesories</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'trades'} />
        </IconWrapper>
        <Title>Trades & Services</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'farming'} />
        </IconWrapper>
        <Title>Farming Supplies</Title>
      </Item>
      <Item>
        <IconWrapper>
          <SvgIcon name={'vehicles'} />
        </IconWrapper>
        <Title>Motor Vehicles</Title>
      </Item>
    </Inner>
  );
};
