import styled from 'styled-components';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  ${title30};
`;

export const CalendarWrapper = styled.div``;
