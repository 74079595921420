import { RateStars } from '../../../../../../components/rate-stars/rate-stars';
import {
  BottomSection,
  Container,
  Content,
  MainBox,
  Description,
  ImgWrapper,
  ContactsBox,
  Main,
  RateWrapper,
  Price,
  Title
} from './service-card-styled';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

export const ServiceCard = ({ service, isListView }) => {
  return (
    <Container isListView={isListView}>
      <ImgWrapper>
        <img src={service.img} alt={service.title} />
      </ImgWrapper>
      <Main>
        <Content>
          <MainBox>
            <RateWrapper>
              <RateStars rate={service.rate} reviews={service.reviews} />
            </RateWrapper>
            <ContactsBox>
              <a href='#'>
                <SvgIcon name={'whatsup'} />
              </a>
              <a href='#'>
                <SvgIcon name={'email'} />
              </a>
              <a href='#'>
                <SvgIcon name={'phone'} />
              </a>
            </ContactsBox>
          </MainBox>
          <Title>{service.title}</Title>
          <Description>{service.description}</Description>
        </Content>

        <BottomSection>
          <Price>${service.price}</Price>
          <GlobalButton height={44} type={'primary-round'}>
            <SvgIcon name={'eye'} />
          </GlobalButton>
        </BottomSection>
      </Main>
    </Container>
  );
};
