import {
  Tag,
  TagsSection
} from '../../pages/categories-motor-vehicles-specific-search/components/main/motor-vehicles/motor-vehicles-styled';
import tagCheckmark from '../../assets/tag-checkmark-icon.svg';
import React from 'react';

export const TAGS = [
  {
    name: 'All results'
  },
  {
    name: 'Auctions'
  },
  {
    name: 'Buy now'
  }
];

export const FilterByBuyType = () => {
  return (
    <TagsSection>
      {TAGS.map((data, index) => {
        return (
          <Tag index={index} key={index}>
            {index === 0 ? <img src={tagCheckmark} alt='' /> : ''}
            {data.name}
          </Tag>
        );
      })}
    </TagsSection>
  );
};
