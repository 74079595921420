import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Address,
  BottomMenu,
  BtnsWrapper,
  Container,
  Content,
  Description,
  Divider,
  ImgWrapper,
  Logo,
  MainBox,
  ContactsBox,
  Text,
  Title,
  RateStarsDesktop,
  ViewBtn
} from './store-card-styled';

export const StoreCard = ({
  store,
  isListView = false,
  isMobileListDefault = true,
  isAddressHidden = false
}) => {
  return (
    <Container isListView={isListView} isMobileListDefault={isMobileListDefault}>
      <ImgWrapper>
        <img src={store.img} alt={store.title} />
        <Logo>
          <img src={store.logo} alt={store.title} />
        </Logo>
      </ImgWrapper>
      <Content>
        <MainBox></MainBox>
        <Title>{store.title}</Title>
        <Description>{store.description}</Description>
        {isListView && <Text>{store.text}</Text>}
        {isAddressHidden && <Divider />}
        {!isAddressHidden && <Address>{store.address}</Address>}
        <BottomMenu>
          <RateStars rate={store.rate} reviews={store.reviews} />
          <BtnsWrapper>
            {isListView && (
              <ContactsBox>
                <a href='#'>
                  <SvgIcon name={'whatsup'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'email'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'phone'} />
                </a>
              </ContactsBox>
            )}
            <ViewBtn>
              <SvgIcon name={'eye'} />
            </ViewBtn>
          </BtnsWrapper>
        </BottomMenu>
      </Content>
    </Container>
  );
};
