import styled, { css } from 'styled-components';

import { RoundBtn, buttonReset, inputReset } from 'assets/style-helpers/style-helpers';
import { cardPriceBig, cardTitleBig } from 'assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';
import { viewType } from '../view-selection/view-selection';

export const Main = styled.div`
  padding: 20px 20px 20px;
  padding-bottom: 35px;
  position: relative;
  ${(props) =>
    props.currentViewType &&
    css`
      flex-basis: calc(100% - 235px);
    `}
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            padding: 12px 12px 12px 10px;
            width: 100%;
            flex-basis: unset;
          `
        : css``}
  }
`;

export const MainSecond = styled.div`
  position: relative;
  padding: 10px 20px 20px;

  @media ${device.laptop} {
    padding: 0px 10px 20px;
    padding-right: 10px;
  }

  button {
    @media ${device.laptop} {
      width: 34px;
      height: 34px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  height: 220px;
  width: 100%;
  position: relative;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.currentViewType &&
    css`
      flex-basis: 230px;
    `}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            height: unset;
            max-width: 112px;
          `
        : css``}
  }
`;

export const ImgWrapperSecond = styled.div`
  position: relative;
  height: 147px;
  width: 100%;
  position: relative;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 0px;

  @media ${device.tablet} {
    height: 205px;
  }

  @media ${device.mobile} {
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ContainerCss = css`
  border-radius: 12px;
  background: #fff;
  overflow: hidden;
  border: 1px solid var(--main-gray-2);
  min-width: 0;
  max-width: 100%;
  width: 100%;
  //max-width: 295px;
  //width: 295px;
  transition: ease 0.5s;
  overflow: hidden;
  &:hover {
    box-shadow: 0 4px 40px rgba(78, 94, 108, 0.2);
    border: 1px solid transparent;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  ${ContainerCss};
  ${(props) => {
    if (props.currentViewType === viewType.list) {
      return css`
        display: flex;
        ${ImgWrapper} {
          flex-basis: 230px;
        }
        ${Content} {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
        }
        ${Control} {
          width: auto;
        }
      `;
    }
  }}
  @media ${device.tablet} {
    ${Main} {
      padding: 20px 12px 24px;
    }
  }
  @media ${device.mobile} {
    ${Main} {
      padding: 18px 12px 18px;
    }
  }
`;

export const ContainerTradesView = styled.div`
  border-radius: 12px;
  background: #fff;
  overflow: hidden;
  border: 1px solid var(--main-gray-2);
  min-width: 0;
  max-width: 100%;
  //max-width: 295px;
  //width: 295px;
  transition: ease 0.5s;
  overflow: hidden;
  &:hover {
    box-shadow: 0 4px 40px rgba(78, 94, 108, 0.2);
    border: 1px solid transparent;
  }

  ${(props) => {
    if (props.currentViewType === viewType.list) {
      return css`
        display: flex;
      `;
    }
  }}
  @media ${device.tablet} {
    max-width: 345px;
    width: 345px;
    ${ImgWrapper} {
      height: 191px;
    }
  }
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            max-width: unset;
            width: 100%;
            ${ImgWrapper} {
              height: unset;
            }
          `
        : css``}
  }
`;

export const OnlyViewCardStyled = styled(Container)`
  @media ${device.tablet} {
    max-width: 295px;
    width: 100%;
    ${Main} {
      padding: 20px 16px 30px;
    }
    ${ImgWrapper} {
      height: 220px;
    }
  }
`;

export const Title = styled.div`
  ${cardTitleBig};
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: ${(props) => (props.subtitle ? '4px' : '12px')};
  margin-top: 8px;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 22px;
    height: 44px;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list &&
      css`
        height: unset;
        font-size: 13px;
        margin-bottom: 4px;
        line-height: 19px;
      `}
  }
`;

export const SubTitle = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #acadb9;
  margin-top: 3px;
  margin-bottom: 14px;
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType &&
      css`
        ine-height: 16.294px;
        margin-bottom: 3.5px;
      `}
  }
`;

export const SubTitleSecond = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #acadb9;
  margin-bottom: 22px;

  @media ${device.laptop} {
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    margin-bottom: 11px;
  }
`;

export const TitleTradesView = styled.div`
  ${cardTitleBig};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
  margin-top: 8px;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 22px;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            margin: 5px 0;
            font-size: 17px !important;
          `
        : css``}
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          `
        : css`
            //display: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          `}
  }
`;

export const MainPrice = styled.div`
  ${cardPriceBig};
  @media ${device.laptop} {
    font-size: 18px;
  }
`;

export const ZWLPrice = styled.div`
  color: #acadb9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            color: #acadb9;
            font-family: Poppins, sans-serif;
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 16.294px; /* 181.04% */
          `
        : css``}
  }
`;

export const MainPriceTradesView = styled.div`
  ${cardPriceBig};
  @media ${device.mobile} {
    ${(props) =>
      props.currentViewType === viewType.list
        ? css`
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16.294px;
          `
        : css``}
  }
`;

export const OldPrice = styled.div`
  color: var(--main-subtext);
  text-decoration: line-through;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 12px;
    margin-right: 8px;
  }
`;

export const ContentTradesView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    align-items: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const BuyBtn = styled(RoundBtn)`
  background: var(--main-green);
  color: var(--main-white);
  transition: all ease 0.5s;
  svg {
    width: 18px;
  }
  &:hover {
    background-color: var(--main-green);
  }
`;

export const FavBtn = styled(RoundBtn)`
  display: ${(props) => (props.showFav ? 'flex' : 'none')};
  ${buttonReset};
  position: absolute;
  top: 16px;
  right: 19px;
  border: 1px solid var(--main-gray);
  background: var(--main-white);
  @media ${device.tablet} {
    top: 8px;
    right: 11px;
  }
  svg {
    width: 18px;

    path {
      fill: ${(props) => (props.isFav ? 'black' : 'none')};
      stroke: ${(props) => (props.isFav ? 'black' : 'black')};
    }
  }
`;

export const FavBtn2 = styled(RoundBtn)`
  border: 1px solid var(--main-gray-2);
  margin-left: 10px;
  svg {
    width: 18px;
  }
`;

export const FavBtn3 = styled(RoundBtn)`
  border: 1px solid var(--main-gray-2);
  margin-left: 10px;
  svg {
    width: 18px;
  }

  @media ${device.laptop} {
    margin-left: 5px;
  }
`;

export const FavBtn4 = styled(RoundBtn)`
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  top: 16px;
  right: 19px;
  border: 1px solid var(--main-gray);
  background: var(--main-white);
  margin-left: 10px;
  svg {
    width: 18px;
    path {
      fill: ${(props) => (props.isFav ? 'black' : 'none')};
      stroke: ${(props) => (props.isFav ? 'black' : 'black')};
    }
  }

  @media ${device.laptop} {
    margin-left: 5px;
  }
`;

export const Input = styled.input`
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 13px;
  line-height: 24px;
  ${inputReset};
  border-bottom: 1px solid var(--main-green);
  margin-right: 16px;
`;

export const Badge = styled.div`
  background: var(--main-green);
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-white);
  font-weight: 600;
  font-size: 9px;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px 16px;
  min-width: 112px;
  border-radius: 0 12px 0 0;
`;

export const SliderImgWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const OnlyViewWrapper = styled.div`
  display: flex;
`;

export const RateStarsDesktop = styled.div`
  display: block;
`;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--main-green);
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a + a {
    margin-left: 8px;
  }
`;

export const RateStarsMobile = styled.div`
  display: none;
  // @media ${device.tablet} {
  //   display: block;
  // }
`;

export const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Text = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: #676a75;
  margin-bottom: 14px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const Label = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--main-orange);
  border-radius: 6px 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  padding: 0 12px;
  color: #ffffff;
  @media ${device.tablet} {
    padding: 8px 6px;
    font-size: 10px;
    line-height: 100%;
  }
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 100%;
    padding: 8px 6px;
  }
`;

export const LabelInStock = styled(Label)`
  bottom: 28px;
  background: #3221f7;
`;

export const LabelOutStock = styled(LabelInStock)`
  background: #888599;
`;

export const LabelNotAvailable = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #888888;
  border-radius: 6px 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  padding: 0 12px;
  color: #ffffff;
  @media ${device.tablet} {
    padding: 8px 6px;
    font-size: 10px;
    line-height: 100%;
  }
  @media ${device.mobile} {
    font-size: 10px;
    line-height: 100%;
    padding: 8px 6px;
  }
`;

export const Mark = styled.div`
  position: absolute;
  left: 0px;
  top: 23px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  color: #ffffff;
  background: ${(props) => (props.color ? props.color : '')};
  border-radius: 2px;
  padding: 1px 10px;
  z-index: 1;
`;
