import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import {
  BusinessSettingsSidebarStyled,
  Section,
  Map,
  Main,
  SecondaryTitle,
  Title,
  Avatar,
  Address,
  AddressText,
  AddressTop,
  TopInfo,
  ContactsItem,
  Subtitle,
  ContactsTable,
  ContactsWrapper,
  TitleWrapper,
  DescriptionText,
  Description,
  BannerImg,
  MobileBackBtn
} from './business-settings-sidebar-styled';

import map from '../img/sidebar-map.jpg';
import banner from '../img/sidebar-banner.jpg';
import avatar from '../img/avatar.png';
import {
  OpenHours,
  OpenHoursItem,
  OpenHoursList,
  OpenHoursStatus,
  OpenHoursTitle,
  OpenHoursTop
} from '../../../../../components/store-front-top/store-front-top-styled';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';

export const BusinessSettingsSidebar = ({ isSidebarHidden, setIsSidebarHidden }) => {
  return (
    <BusinessSettingsSidebarStyled isSidebarHidden={isSidebarHidden}>
      <MobileBackBtn
        onClick={() => {
          setIsSidebarHidden(true);
        }}
      >
        <SvgIcon name={'chevron-left'} />
        Back to Business Settings
      </MobileBackBtn>
      <BannerImg>
        <img src={banner} alt='' />
      </BannerImg>
      <Main>
        <TopInfo>
          <Avatar>
            <img src={avatar} alt='' />
          </Avatar>
          <TitleWrapper>
            <Title>Business Name</Title>
            <Subtitle>Business Category</Subtitle>
          </TitleWrapper>
        </TopInfo>
        <Description>
          <SecondaryTitle>About Us</SecondaryTitle>
          <DescriptionText>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type.
          </DescriptionText>
        </Description>
        <Section>
          <Map>
            <img src={map} alt='' />
          </Map>
          <Address>
            <AddressTop>
              <MapPinIcon />
              <SecondaryTitle>Adress</SecondaryTitle>
            </AddressTop>
            <AddressText>
              33 Robson Manyika/Orr Street (Next To Central Vehicle Registration), Harare CBD
            </AddressText>
          </Address>
        </Section>
        <Section>
          <SecondaryTitle>Contacts</SecondaryTitle>
          <ContactsWrapper>
            <ContactsTable>
              <ContactsItem>
                <span>WhatsApp:</span>
                <span>+263 71 77 562 256</span>
              </ContactsItem>
              <ContactsItem>
                <span>Phone:</span>
                <span>+263 71 77 562 256</span>
              </ContactsItem>
              <ContactsItem>
                <span>Email:</span>
                <span>myemail@gmail.com</span>
              </ContactsItem>
              <ContactsItem>
                <span>Facebook:</span>
                <span className={'link'}>https://facebook.com/facebook.com...</span>
              </ContactsItem>
            </ContactsTable>
            <ContactsTable>
              <ContactsItem>
                <span>Instagram:</span>
                <span className={'link'}>https://facebook.com/facebook.com...</span>
              </ContactsItem>
              <ContactsItem>
                <span>Twitter:</span>
                <span className={'link'}>https://facebook.com/facebook.com...</span>
              </ContactsItem>
              <ContactsItem>
                <span>LinkedIn:</span>
                <span className={'link'}>https://facebook.com/facebook.com...</span>
              </ContactsItem>
              <ContactsItem>
                <span>Instagram:</span>
                <span className={'link'}>https://facebook.com/facebook.com...</span>
              </ContactsItem>
            </ContactsTable>
          </ContactsWrapper>
        </Section>
        <Section>
          <OpenHours>
            <OpenHoursTop>
              <OpenHoursTitle>Opening Times</OpenHoursTitle>
              <OpenHoursStatus>Open now</OpenHoursStatus>
            </OpenHoursTop>
            <OpenHoursList>
              <OpenHoursItem>
                <span>Mon</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Tue</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Wed</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Thu</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Fri</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Sat</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
              <OpenHoursItem>
                <span>Sun</span>
                <span>08:00 - 17:00</span>
              </OpenHoursItem>
            </OpenHoursList>
          </OpenHours>
        </Section>
      </Main>
    </BusinessSettingsSidebarStyled>
  );
};
