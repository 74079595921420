import { OrderCard } from '../order-card/order-card';
import {
  Subtitle,
  Container,
  Title,
  List,
  Total,
  TotalTitle,
  TotalValue,
  Bottom,
  BottomLeft,
  BottomRight,
  TrackingNumber,
  TrackingLink
} from './order-details-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

export const OrderDetails = () => {
  return (
    <Container>
      <Title>Order Details</Title>
      <Subtitle>You have ordered 10 items</Subtitle>
      <List>
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
      </List>
      <Total>
        <TotalTitle>Total</TotalTitle>
        <TotalValue>$1250</TotalValue>
      </Total>
      <Bottom>
        <BottomLeft>
          <TrackingNumber>
            Tracking Code: <span>EW2324IUK</span>
          </TrackingNumber>
          <TrackingLink>Track Order</TrackingLink>
        </BottomLeft>
        <BottomRight>
          <GlobalButton height={40} type={'secondary'}>
            <SvgIcon name={'download'} />
            Downaload PDF
          </GlobalButton>
        </BottomRight>
      </Bottom>
    </Container>
  );
};
