import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const MainStyled = styled.section`
  //max-width: 1278px;
  width: 100%;
  min-width: 0;
  @media ${device.laptop} {
    //max-width: 946px;
  }
`;

export const SparePartsDiv = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const SelectWrapper = styled.div`
  max-width: 220px;
  margin-top: 60px;
`;

export const Container = styled.div`
  max-width: 100%;
  min-width: 0;
  height: 100%;
  width: 100%;

  .swiper {
    height: 100%;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    gap: 9px;
    z-index: 5;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    background: #388638;
  }
`;
