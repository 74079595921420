import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { CloseButton, Container, Inner, Wrapper } from './modal-wrapper-styled';

import { ReactComponent as CloseIcon } from './close.svg';

// eslint-disable-next-line react/display-name
export const ModalWrapper = ({
  isVisible,
  children,
  onClose,
  alignUnset,
  withoutPadding = false
}) => {
  const ref = useRef();
  useEffect(() => {
    if (isVisible) {
      disableBodyScroll(ref.current);
    } else {
      enableBodyScroll(ref.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isVisible]);
  return ReactDOM.createPortal(
    <Container alignUnset={alignUnset} isVisible={isVisible} ref={ref}>
      <Wrapper>
        <Inner withoutPadding={withoutPadding}>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          {children}
        </Inner>
      </Wrapper>
    </Container>,
    document.getElementById('modal-root')
  );
};
