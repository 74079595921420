import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  max-width: 1690px;
  margin: 0 auto;
  padding: 0 16px;
  @media ${device.laptop} {
    max-width: 1266px;
  }
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;
    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
  }
  @media ${device.mobile} {
    top: 198px;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 24px;
  }
`;

export const NotificationIcon = styled.img`
  position: fixed;
  right: 0;
  top: 290px;
  cursor: pointer;
  margin-bottom: 16px;
  ${(props) =>
    props.showNotificationSidebar || props.showHelpAndTutorialSidebar
      ? css`
          z-index: 1000;
        `
      : css`
          z-index: 10000;
        `};
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const HelpAndTutorialButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  position: fixed;
  right: -57px;
  top: 419px;
  height: 40px;
  background: #388638;
  border-radius: 6px 6px 0 0;
  transform: rotate(-90deg);
  color: var(--main-white);
  cursor: pointer;
  ${(props) =>
    props.showHelpAndTutorialSidebar || props.showNotificationSidebar
      ? css`
          z-index: 1000;
        `
      : css`
          z-index: 10000;
        `};
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
