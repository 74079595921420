import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from './adv-banner-slider-styled';
import { AdvBanner } from '../adv-banner/adv-banner';
import { Autoplay, Pagination } from 'swiper';

const slides = [
  {
    title: 'Red Carpet Looks',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 50% off',
    img: 'https://picsum.photos/200/300'
  },
  {
    title: 'Slide 2',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 10% off',
    img: 'https://picsum.photos/300/400'
  },
  {
    title: 'Slide 3',
    subtitle: 'Special Occasion Dresses & Style',
    buttonText: 'UP to 75% off',
    img: 'https://picsum.photos/500/500'
  }
];

export const AdvBannerSlider = () => {
  return (
    <Container>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 2500 }}
        pagination={{ clickable: true }}
      >
        {slides.map((item) => {
          return (
            <SwiperSlide key={item.title}>
              <AdvBanner
                title={item.title}
                subtitle={item.subtitle}
                img={item.img}
                buttonText={item.buttonText}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};
