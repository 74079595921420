import { Container, Main, Sub, Subcategory } from './dropdown-category-styled';
import { useState } from 'react';

import { ReactComponent as Arrow } from './arrow.svg';

export const DropdownCategory = ({ title, subcategory }) => {
  const [isSubOpened, setIsSubOpened] = useState(false);
  return (
    <Container>
      <Main
        onClick={() => {
          setIsSubOpened(!isSubOpened);
        }}
        isSubOpened={isSubOpened}
      >
        {title}
        <Arrow />
      </Main>
      {isSubOpened && (
        <Sub>
          {subcategory.map((item, key) => (
            <Subcategory key={key}>{item}</Subcategory>
          ))}
        </Sub>
      )}
    </Container>
  );
};
