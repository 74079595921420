import { CategorySelection, FilterButton, FiltersStyled, Selector } from './filters-styled';

import arrowUp from 'assets/arrow_up.svg';
import filter from 'assets/filter.svg';
import { GlobalFilter } from '../../../../components/global-filter/global-filter';

export const SELECTORS = [
  {
    name: 'Building & Construction',
    amount: 98
  },
  {
    name: 'Education',
    amount: 1
  },
  {
    name: 'Electricity, Solar & Energy Supplies',
    amount: 10
  },
  {
    name: 'Engineering',
    amount: 20
  },
  {
    name: 'Health, Fitness & Beauty',
    amount: 20
  },
  {
    name: 'Home Repairs & Plumbing',
    amount: 70
  },
  {
    name: 'Legal Services',
    amount: 4
  },
  {
    name: 'Employment & Human Resources',
    amount: 120
  },
  {
    name: 'Marketing & Advertising',
    amount: 2
  }
];

export const TYPES = [
  {
    label: 'Category',
    name: 'Electronic Goods'
  }
];

const Filters = ({ isMobileFilterVisible, setMobileFilterVisible }) => {
  return (
    <GlobalFilter
      isAdSpace={false}
      isMobileFilterVisible={isMobileFilterVisible}
      onCloseFilterBtnClick={() => {
        setMobileFilterVisible(false);
      }}
    />
  );
};

export default Filters;
