import { ReactComponent as Dots } from '../../../../assets/dots-services.svg';
import ServicesPhoto from '../../../../assets/services-photo.svg';
import { ReactComponent as Star } from '../../../../assets/star-2.svg';
import Checkbox from '../../../../components/checkbox/checkbox';
import { Radio } from '../../../../components/radio/radio';
import {
  BookingStatus,
  Categories,
  CheckboxWrapper,
  CheckboxWrapperHeader,
  ServiceBox,
  ServiceDescription,
  ServiceTitle,
  StarBox,
  Status,
  StyledId,
  Thumbnail
} from './all-services-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: (
      <StyledId>
        <CheckboxWrapperHeader>
          <Checkbox />
        </CheckboxWrapperHeader>
        ID
      </StyledId>
    ),
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Service',
    accessor: 'service'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Days Left',
    accessor: 'daysleft'
  },
  {
    Header: 'Categories',
    accessor: 'categories'
  },
  {
    Header: 'Regular Price',
    accessor: 'regularprice'
  },
  {
    Header: 'Sale Price',
    accessor: 'saleprice'
  },
  {
    Header: 'Booking Status',
    accessor: 'bookingstatus'
  },
  {
    Header: 'Bookings',
    accessor: 'bookings'
  },
  {
    Header: 'Featured',
    accessor: 'featured'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    thumbnail: <Thumbnail src={ServicesPhoto} alt='Photo' />,
    service: (
      <ServiceBox>
        <ServiceTitle>Company Registration</ServiceTitle>
        <ServiceDescription>
          Lorem ipsum dolor sit amet consectetur. Amet et tincidu...
        </ServiceDescription>
      </ServiceBox>
    ),
    status: (
      <Status>
        <Radio id={'no'} isChecked={'no'} name={'status'} title={'Published'} value={'no'} />
      </Status>
    ),
    daysleft: '30',
    categories: <Categories>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</Categories>,
    regularprice: '$250 000',
    saleprice: '$250 000',
    bookingstatus: <BookingStatus>Enabled</BookingStatus>,
    bookings: '50k+',
    featured: (
      <StarBox>
        <Star />
      </StarBox>
    ),
    actions: <Dots />
  },
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    thumbnail: <Thumbnail src={ServicesPhoto} alt='Photo' />,
    service: (
      <ServiceBox>
        <ServiceTitle>Company Registration</ServiceTitle>
        <ServiceDescription>
          Lorem ipsum dolor sit amet consectetur. Amet et tincidu...
        </ServiceDescription>
      </ServiceBox>
    ),
    status: (
      <Status>
        <Radio id={'no'} isChecked={'no'} name={'status'} title={'Published'} value={'no'} />
      </Status>
    ),
    daysleft: '30',
    categories: <Categories>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</Categories>,
    regularprice: '$250 000',
    saleprice: '$250 000',
    bookingstatus: <BookingStatus>Enabled</BookingStatus>,
    bookings: '50k+',
    featured: (
      <StarBox>
        <Star />
      </StarBox>
    ),
    actions: <Dots />
  }
];
