import React, { useState } from 'react';
import Slider from 'react-slick';
import './reviews.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import feedback from 'assets/chat.svg';
import arrow from 'assets/arrow_left.svg';

import ReviewCard from '../../../../components/review-card/review-card';
import {
  ArrowLeft,
  ArrowRight,
  Container,
  FadeOutContainer,
  Header,
  HorizontalLine,
  SliderSection,
  SliderSectionMobile,
  Title
} from './reviews-styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GlobalButton } from 'components/global-button/global-button';
import { FeedbackPopup } from './components/feedback-popup/feedback-popup';
import { Pagination } from 'swiper';

function NextArrow(props) {
  const { onClick } = props;
  return (
    <ArrowRight onClick={onClick}>
      <img src={arrow} alt='' />
    </ArrowRight>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <ArrowLeft onClick={onClick}>
      <img src={arrow} alt='' />
    </ArrowLeft>
  );
}

const Reviews = ({ title, slidesToShow = 3 }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <GlobalButton
          type={'secondary'}
          onClick={() => {
            setIsFeedbackPopupVisible(true);
          }}
        >
          <img src={feedback} alt='' />
          Leave feedback
        </GlobalButton>
        <FeedbackPopup
          isFeedbackPopupVisible={isFeedbackPopupVisible}
          onClose={() => {
            setIsFeedbackPopupVisible(false);
          }}
        />
      </Header>
      <HorizontalLine />
      <SliderSection>
        <Slider {...settings}>
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
        </Slider>
      </SliderSection>
      <SliderSectionMobile>
        <FadeOutContainer>
          <Swiper slidesPerView={1.3} spaceBetween={15} modules={[Pagination]} pagination={true}>
            <SwiperSlide>
              <ReviewCard />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard />
            </SwiperSlide>
          </Swiper>
        </FadeOutContainer>
      </SliderSectionMobile>
    </Container>
  );
};

export default Reviews;
