import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { linkReset, ulReset } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  padding: 0 3px;
`;

export const List = styled.ul`
  ${ulReset};
  max-height: 468px;
  overflow: auto;
  @media ${device.tablet} {
    max-height: 241px;
  }
  @media ${device.mobile} {
    max-height: 155px;
  }
`;

export const Item = styled.li`
  border-radius: 6px;
  padding: 16px 12px;
  &:nth-child(even) {
    background: #f5f7fb;
  }
  @media ${device.tablet} {
    ${(props) => {
      return (
        props.isTabletHidden &&
        css`
          display: none;
        `
      );
    }}
  }

  @media ${device.mobile} {
    ${(props) => {
      return (
        props.isMobileHidden &&
        css`
          display: none;
        `
      );
    }}
  }
`;

export const ItemLink = styled(Link)`
  ${linkReset};
`;

export const ItemLinkTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 22px;
`;

export const ItemLinkInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #acadb9;
  font-size: 12px;
  line-height: 24px;
`;

export const ItemLinkSeller = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  span {
    position: relative;
  }
  span + span {
    margin-left: 6px;
    padding-left: 6px;
    &::before {
      content: '';
      height: 14px;
      width: 1px;
      background: #e8e8e8;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const ItemLinkTime = styled.div`
  flex-shrink: 0;
`;

export const BottomMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 24px;
  @media ${device.tablet} {
    padding: 0 16px 20px;
  }
  @media ${device.mobile} {
    padding: 18px 16px 24px;
  }
`;

export const OfferLink = styled(Link)`
  ${linkReset};
  color: var(--main-green);
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: var(--main-green);
    opacity: 0.5;
  }
`;
