import Header from '../../components/header/header';
import {
  BannerWrapper,
  CardsWithBanner,
  CardsWrapper,
  Container,
  FeaturesCategoriesCardsWrapper,
  PopularCategoriesAdSpace,
  Title,
  TitleWrapper,
  TagsWrapper
} from './popular-categories-styled';
import { Tags } from '../home/components/home-main/components/tags/tags';
import { FeaturesCategoriesCards } from '../../components/featured-categories-cards/features-categories-cards';
import { FoodAndGroceries } from '../home/components/food-and-groceries/food-and-groceries';
import { SectionWithSlider } from '../home/components/section-with-slider/section-with-slider';
import { AdvBanner } from '../../components/adv-banner/adv-banner';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { PCardSmall } from '../../components/p-card-small/p-card-small';
import { SectionHeader } from '../../components/section-header/section-header';
import temp1 from '../../assets/temp-img/temp1.png';
import temp2 from '../../assets/temp-img/temp2.png';
import temp3 from '../../assets/temp-img/temp3.png';

const products = [
  {
    img: temp1,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  },
  {
    img: temp2,
    title: 'Peach Slices / Del Monte, 980 g',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  },
  {
    img: temp3,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  },
  {
    img: temp3,
    title: 'Fanta orange, 2l',
    old_price: '$9.99',
    price: '$5.50',
    rate: 3.5,
    reviews: 51,
    label: 'sale',
    labelColor: '#E54D45'
  }
];

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Popular Categories' }
];

export const PopularCategories = () => {
  return (
    <>
      <Header />
      <Container>
        {/* <TagsWrapper> */}
        {/*  <Tags />*/}
        {/* </TagsWrapper> */}
        <Breadcrumbs links={breadcrumbs} />
        <Title>Popular Categories</Title>
        <FeaturesCategoriesCardsWrapper>
          <FeaturesCategoriesCards />
        </FeaturesCategoriesCardsWrapper>
        <CardsWithBanner>
          <TitleWrapper>
            <SectionHeader hasClockCount title={'Deals of the Day'} />
            <CardsWrapper>
              {products.map((item, index) => (
                <PCardSmall product={item} key={index} />
              ))}
            </CardsWrapper>
          </TitleWrapper>
          <BannerWrapper>
            <AdvBanner
              buttonText='30 % Save'
              title={'Healthy Vegetables'}
              subtitle='Limited Time Offer'
              img={'https://picsum.photos/200/300'}
            />
          </BannerWrapper>
        </CardsWithBanner>
      </Container>
      <FoodAndGroceries />
      <PopularCategoriesAdSpace />
      <SectionWithSlider sectionTitle={'Real Estate'} />
      <SectionWithSlider sectionTitle={'Trades & Services'} />
      <PopularCategoriesAdSpace />

      <SectionWithSlider sectionTitle={'Motor Vehicles'} />
    </>
  );
};
