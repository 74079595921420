import { Container, Title } from './section-header-knowledge-styled';

export const SectionHeaderKnowledge = ({ title, fontSize = 'lg', ...props }) => {
  const { className } = props;
  return (
    <Container className={className}>
      <Title fontSize={fontSize}>{title}</Title>
    </Container>
  );
};
