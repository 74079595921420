import { GlobalInput } from '../../../../components/global-input/global-input';
import { LabelBlock, Name } from './label-styled';

export const Label = ({ label, placeholder, nameField }) => {
  return (
    <LabelBlock htmlFor=''>
      <Name>{label}</Name>
      <GlobalInput name={nameField} placeholder={placeholder} />
    </LabelBlock>
  );
};
