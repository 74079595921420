import { RateStars } from '../rate-stars/rate-stars';
import {
  Buttons,
  Container,
  MainButtons,
  MataInfoItem,
  MetaInfo,
  MetaInfoText,
  MetaInfoTitle,
  Orders,
  Price,
  RatingWrapper,
  Reviews,
  ReviewsOrders,
  ShopName,
  Stock,
  Title,
  Description,
  MeniItem,
  ShareBtn,
  MobileContactSellerBtn
} from './product-info-styled';
import { Colors } from './components/colors/colors';
import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import { SelectWithButtons } from './components/select-with-buttons/select-with-buttons';
import { useState } from 'react';
import { Counter } from './components/counter/counter';
import { DeliveryInfo } from './components/delivery-info/delivery-info';
import { MakeOfferPopup } from '../make-offer-popup/make-offer-popup';

const capacityMock = [
  { value: 16, label: '16GB' },
  { value: 32, label: '32GB' }
];

export const ProductInfo = ({ onContactSellerBtnClick }) => {
  const [isMakeOfferVisible, setMakeOfferVisible] = useState(false);

  const [currentCapacity, setCurrentCapacity] = useState(capacityMock[0]);
  const [quantity, setQuantity] = useState(1);
  return (
    <Container>
      <ShopName>Eltrons Electronics</ShopName>
      <Title>Huawei Y7</Title>
      <RatingWrapper>
        <RateStars rate={3.5} height={17} width={17} isRateCounter={false} />
        <ReviewsOrders>
          <Reviews>
            <a href='#'>10 Reviews</a>
          </Reviews>
          <Orders>200 Orders</Orders>
        </ReviewsOrders>
      </RatingWrapper>
      <MetaInfo>
        <MataInfoItem>
          <MetaInfoTitle>Item Published On: </MetaInfoTitle>
          <MetaInfoText>10/03/2022</MetaInfoText>
        </MataInfoItem>
        <MataInfoItem>
          <MetaInfoTitle>ID: </MetaInfoTitle>
          <MetaInfoText>EZ124-585-22155-44</MetaInfoText>
        </MataInfoItem>
      </MetaInfo>
      <Price>$250</Price>
      <Stock>17 in Stock</Stock>
      <Description>
        Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam
        morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare
        viverra leo ornare viverra.
      </Description>
      <MeniItem>
        <Colors />
      </MeniItem>
      <MeniItem>
        <SelectWithButtons
          title={'Storage capacity:'}
          onClick={setCurrentCapacity}
          values={capacityMock}
          currentValue={currentCapacity}
        />
      </MeniItem>
      <MeniItem className={'flex'}>
        <Counter title={'Quantity:'} currentValue={quantity} setValue={setQuantity} />
        <ShareBtn>
          <SvgIcon name={'share'} />
          Share
        </ShareBtn>
      </MeniItem>
      <Buttons>
        <MainButtons>
          <GlobalButton height={40} type='primary'>
            <SvgIcon name={'cart'} />
            Add to cart
          </GlobalButton>
          <GlobalButton height={40} type={'orange-secondary'}>
            <SvgIcon name={'wallet'} />
            Buy Now
          </GlobalButton>
          <GlobalButton
            height={40}
            type={'orange'}
            onClick={() => {
              setMakeOfferVisible(true);
            }}
          >
            <SvgIcon name={'message-text-square'} />
            Make an Offer
          </GlobalButton>
        </MainButtons>
      </Buttons>
      <MobileContactSellerBtn>
        <GlobalButton type={'primary'} onClick={onContactSellerBtnClick}>
          Contact Seller
        </GlobalButton>
      </MobileContactSellerBtn>
      <DeliveryInfo />
      <MakeOfferPopup
        isVisible={isMakeOfferVisible}
        onClose={() => {
          setMakeOfferVisible(false);
        }}
        onMakeOfferBtnClick={() => {
          setMakeOfferVisible(false);
        }}
      />
    </Container>
  );
};
