import { Container, Date, NextButton, PrevButton } from './date-select-styled';
import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';

export const DateSelect = () => {
  return (
    <Container>
      <PrevButton>
        <SvgIcon name='chevron-left' />
      </PrevButton>
      <Date>October 2022</Date>
      <NextButton>
        <SvgIcon name='chevron-left' />
      </NextButton>
    </Container>
  );
};
