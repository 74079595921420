import { Container, Title, TitleWrapper } from './our-products-styled';
import { Section } from '../section/section';
import { PCard, ProductCardTypes } from '../../../../components/p-card/p-card';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { generalProductsMock } from '../../../../dummy-data/generalProductsMock';
import { useState } from 'react';

const Button = () => (
  <GlobalButton type={'secondary'} height={40}>
    <SvgIcon name={'eye'} />
    See more products
  </GlobalButton>
);

export const OurProducts = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);

  return (
    <Container>
      <TitleWrapper>
        <Title>Our Products</Title>
      </TitleWrapper>
      <Section
        button={<Button />}
        isSmallCardsOnMobile={true}
        isMobileFilterVisible={isMobileFilterVisible}
        setMobileFilterVisible={setMobileFilterVisible}
      >
        {generalProductsMock.map((product, index) => {
          return <PCard product={product} type={ProductCardTypes.BASIC} key={index} />;
        })}
      </Section>
    </Container>
  );
};
