import styled, { css } from 'styled-components';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid var(--main-gray-2);
`;

export const TabsSwitcher = styled.div``;

export const TabBtn = styled.button`
  ${buttonReset};
  color: #acadb9;
  font-weight: 600;
  transition: all 0.3s;
  position: relative;
  &::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    opacity: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: var(--main-green);
    border-radius: 4px 4px 0px 0px;
    transition: all 0.3s;
  }
  ${(props) =>
    props.isActive &&
    css`
      color: var(--main-text);

      &::after {
        opacity: 1;
      }
    `}

  &:hover {
    color: var(--main-text);
  }

  & + & {
    margin-left: 32px;
  }
`;

export const ReportBtn = styled.div`
  display: flex;
  align-items: center;
  color: #acadb9;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  &::after {
    position: absolute;
    bottom: -17px;
    left: 0;
    opacity: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: #e54d45;
    border-radius: 4px 4px 0px 0px;
    transition: all 0.3s;
  }
  ${(props) =>
    props.isActive &&
    css`
      color: #e54d45;

      &::after {
        opacity: 1;
      }
    `}
  &:hover {
    color: #e54d45;
  }
  svg {
    margin-right: 12px;
  }
`;

export const Main = styled.div`
  padding-bottom: 80px;
`;
