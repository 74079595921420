import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import Header from 'components/header/header';

import {
  Container,
  Date,
  Tag,
  Tags,
  ShareBtn,
  Title,
  FlexRow,
  ImgWrapper,
  MainContent,
  RecomendedSection,
  RecomendedSectionTitle
} from './blog-article-styled';

import { ReactComponent as CalendarIcon } from './icon/calendar.svg';
import { ReactComponent as ShareIcon } from './icon/share.svg';

import mainImg from 'assets/temp-img/article-img.png';
import { BlogCard } from '../../components/blog-card/blog-card';
import blogImg from '../../../../assets/temp-img/blog-img.png';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Blog' },
  { link: '#', name: 'Updates' },
  { link: '#', name: 'Here is something you didnt know about everythingzimbabwean.com' }
];

const blogs = [
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  },
  {
    img: blogImg,
    title: 'Here is something you didnt know about everythingzimbabwean.com',
    description:
      'Lorem ipsum dolor sit amet consectetur. Pellentesque pharetra egestas viverra dignissim. Ac elementum libero ametamet',
    date: '10 days ago',
    tags: ['Blog', 'Latest News', 'Updates'],
    withVideo: false
  }
];

export const BlogArticle = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Tags>
          <Tag>Blog</Tag>
          <Tag>Latest News</Tag>
          <Tag>Updates</Tag>
        </Tags>
        <Title>Here is something you didn't know about everythingzimbabwean.com</Title>
        <FlexRow>
          <Date>
            <CalendarIcon />
            20.02.2023
          </Date>
          <ShareBtn>
            <ShareIcon />
            Share
          </ShareBtn>
        </FlexRow>
        <ImgWrapper>
          <img src={mainImg} alt='' />
        </ImgWrapper>
        <MainContent>
          <p>
            Lorem ipsum dolor sit amet consectetur. Etiam purus eu non non. Nisi pulvinar ut vitae
            purus neque enim. Ultrices sapien amet turpis ligula rhoncus a placerat pellentesque.
            Scelerisque tellus proin eget rhoncus ullamcorper dictum. Id tellus pellentesque mollis
            vitae quisque ultrices morbi. Nibh blandit nisl massa morbi risus. Et vulputate
            fringilla ac nisl urna viverra urna adipiscing. Scelerisque augue auctor nisi eget.
            Ipsum risus scelerisque urna varius nisl eu commodo. Adipiscing integer morbi nisi nunc
            ultricies eget imperdiet volutpat. Tellus aliquam habitant sit diam mattis ullamcorper
            fringilla mauris duis. Amet egestas orci sit eget elit vel odio.
          </p>
          <p>
            Pellentesque integer pretium pretium id quis ultricies urna magna. Imperdiet ultrices
            tincidunt a volutpat risus. Facilisis pharetra vel dui pretium neque et cursus urna.
            Fames mi mauris rhoncus eleifend malesuada. Non eget ligula duis pulvinar eu et. Amet
            nibh non est in. Eleifend lobortis in senectus leo tincidunt vestibulum ipsum.
          </p>
          <h4>Lorem ipsum dolor sit amet consectetur?</h4>
          <p>
            Pellentesque integer pretium pretium id quis ultricies urna magna. Imperdiet ultrices
            tincidunt a volutpat risus. Facilisis pharetra vel dui pretium neque et cursus urna.
            Fames mi mauris rhoncus eleifend malesuada. Non eget ligula duis pulvinar eu et. Amet
            nibh non est in. Eleifend lobortis in senectus leo tincidunt vestibulum ipsum.
          </p>
          <h5>1. Lorem ipsum dolor sit amet consectetur etiam aliquam turpis vestibulum:</h5>

          <ul>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li> Facilisis semper at ultricies egestas quisque ultricies odio.</li>
            <li> Ut bibendum vulputate mauris potenti nibh.</li>
            <li> Volutpat nunc posuere habitant mi tempus.</li>
            <li> Dui posuere hac ipsum elit nec fermentum arcu mauris.</li>
          </ul>

          <a href='' className='download-link' download>
            Download document.pdf
          </a>
          <p>
            Lorem ipsum dolor sit amet consectetur. Etiam purus eu non non. Nisi pulvinar ut vitae
            purus neque enim. Ultrices sapien amet turpis ligula rhoncus a placerat pellentesque.
            Scelerisque tellus proin eget rhoncus ullamcorper dictum. Id tellus pellentesque mollis
            vitae quisque ultrices morbi. Nibh blandit nisl massa morbi risus. Et vulputate
            fringilla ac nisl urna viverra urna adipiscing. Scelerisque augue auctor nisi eget.
            Ipsum risus scelerisque urna varius nisl eu commodo. Adipiscing integer morbi nisi nunc
            ultricies eget imperdiet volutpat. Tellus aliquam habitant sit diam mattis ullamcorper
            fringilla mauris duis. Amet egestas orci sit eget elit vel odio.
          </p>
          <p>
            Pellentesque integer pretium pretium id quis ultricies urna magna. Imperdiet ultrices
            tincidunt a volutpat risus. Facilisis pharetra vel dui pretium neque et cursus urna.
            Fames mi mauris rhoncus eleifend malesuada. Non eget ligula duis pulvinar eu et. Amet
            nibh non est in. Eleifend lobortis in senectus leo tincidunt vestibulum ipsum.
          </p>

          <h5>2. Lorem ipsum dolor sit amet consectetur etiam aliquam turpis vestibulum:</h5>

          <ul>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li> Facilisis semper at ultricies egestas quisque ultricies odio.</li>
            <li> Ut bibendum vulputate mauris potenti nibh.</li>
            <li> Volutpat nunc posuere habitant mi tempus.</li>
            <li> Dui posuere hac ipsum elit nec fermentum arcu mauris.</li>
          </ul>
        </MainContent>

        <RecomendedSectionTitle>Recommended articles</RecomendedSectionTitle>
        <RecomendedSection>
          {blogs.map((blog, i) => {
            return <BlogCard blog={blog} key={i} />;
          })}
        </RecomendedSection>
      </Container>
    </>
  );
};
