import styled, { css } from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const ImgWrapper = styled.div`
  height: 190px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  line-height: 17px;
  height: 51px;
  margin-bottom: 14px;
  color: #676a75;
  font-family: 'Lato', sans-serif;
`;

export const Main = styled.div`
  padding: 12px 20px 20px;
`;

export const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 17px;
  line-height: 24px;
  margin: 8px 0;
`;

export const Price = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

export const RateWrapper = styled.div``;

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid var(--main-gray-2);
  overflow: hidden;

  ${(props) =>
    props.isListView &&
    css`
      display: flex;
      ${ImgWrapper} {
        flex-basis: 250px;
        flex-shrink: 0;
        @media ${device.mobile} {
          flex-basis: 150px;
        }
      }
      ${Description} {
        max-width: 700px;
      }
      ${Main} {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      ${BottomSection} {
        flex-direction: column;
        margin-left: 24px;
      }
      ${Title} {
        order: 0;
      }
      ${Description} {
        order: 1;
      }
      ${RateWrapper} {
        order: 2;
      }
    `}
`;

export const MainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContactsBox = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--main-green);
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a + a {
    margin-left: 8px;
  }
`;
