import { ButtonMobile } from '../categories-top-filter/categories-top-filter-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import React, { useState } from 'react';
import { Container, InputBlock, Input, CloseBtn, SearchBtn } from './mob-search-styled';
import { ReactComponent as CloseIcon } from './x.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';

export const MobSearch = () => {
  const [value, setValue] = useState('');
  const [isSearchVisible, setSearchVisible] = useState(false);
  return (
    <Container>
      <ButtonMobile
        onClick={() => {
          setSearchVisible(true);
        }}
      >
        <SvgIcon name='search' />
        <p>Search</p>
      </ButtonMobile>
      {isSearchVisible && (
        <InputBlock>
          <CloseBtn
            onClick={() => {
              setValue('');
            }}
          >
            <CloseIcon />
          </CloseBtn>
          <Input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <SearchBtn
            onClick={() => {
              setSearchVisible(false);
            }}
          >
            <SearchIcon />
          </SearchBtn>
        </InputBlock>
      )}
    </Container>
  );
};
