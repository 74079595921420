import React, { useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  ButtonWrapper,
  MobileSearch,
  LeftSide,
  FilterButton,
  Heading,
  Remove,
  MenuStyled,
  Edit,
  SidebarSubtitle,
  Block,
  ButtonsWrapper,
  AccountInfo,
  Avatar,
  Contacts,
  Name,
  ContactLabel,
  ContactInfo,
  Text,
  UpdateButtonWrapper
} from './manage-all-staff-members-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import { columnsData, columnsRows } from './mock';
import filter from '../../../../assets/filter.svg';
import { Content } from '../all-orders/all-order-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import Sidebar from '../../../../components/sidebar/sidebar';
import { ReactComponent as XSquareIcon } from '../../../dashboard/pages/create-new-order/icons/x-square.svg';
import { GlobalTextarea } from '../../../../components/global-textarea/global-textarea';
import avatar from '../../../../assets/langton_showira.png';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Manage Users' },
  { link: '#', name: 'Manage All Staff Members' }
];

const orderStatusOptions = [
  { value: 'Option 1', label: 'Administrator' },
  { value: 'Option 2', label: 'Shop Manager' },
  { value: 'Option 3', label: 'Product Manager' }
];

export const Menu = ({ onEditClick }) => {
  return (
    <MenuStyled>
      <Edit onClick={onEditClick}>
        <svg
          width='16'
          height='14'
          viewBox='0 0 16 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.0003 5.75019L9.33368 3.25019M1.66699 12.9377L3.92324 12.7027C4.1989 12.674 4.33673 12.6596 4.46556 12.6205C4.57985 12.5858 4.68862 12.5368 4.78892 12.4748C4.90196 12.4049 5.00002 12.313 5.19614 12.1291L14.0003 3.87519C14.7367 3.18483 14.7367 2.06555 14.0003 1.37519C13.264 0.684833 12.0701 0.684833 11.3337 1.37519L2.52948 9.62911C2.33336 9.81297 2.2353 9.90491 2.16075 10.0109C2.09461 10.1049 2.04234 10.2069 2.00533 10.314C1.96363 10.4348 1.94831 10.564 1.91769 10.8225L1.66699 12.9377Z'
            stroke='#29263A'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <p>Edit</p>
      </Edit>
      <Remove>
        <svg
          width='14'
          height='16'
          viewBox='0 0 14 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.66667 3.99967V3.46634C9.66667 2.7196 9.66667 2.34624 9.52134 2.06102C9.39351 1.81014 9.18954 1.60616 8.93865 1.47833C8.65344 1.33301 8.28007 1.33301 7.53333 1.33301H6.46667C5.71993 1.33301 5.34656 1.33301 5.06135 1.47833C4.81046 1.60616 4.60649 1.81014 4.47866 2.06102C4.33333 2.34624 4.33333 2.7196 4.33333 3.46634V3.99967M1 3.99967H13M11.6667 3.99967V11.4663C11.6667 12.5864 11.6667 13.1465 11.4487 13.5743C11.2569 13.9506 10.951 14.2566 10.5746 14.4484C10.1468 14.6663 9.58677 14.6663 8.46667 14.6663H5.53333C4.41323 14.6663 3.85318 14.6663 3.42535 14.4484C3.04903 14.2566 2.74307 13.9506 2.55132 13.5743C2.33333 13.1465 2.33333 12.5864 2.33333 11.4663V3.99967'
            stroke='#E54D45'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <p>Remove</p>
      </Remove>
    </MenuStyled>
  );
};

const ManageAllStaffMembers = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Heading>
        <Title>Manage All Staff Members</Title>
      </Heading>
      <Navigation>
        <LeftSide>
          <MobileSearch>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 15L12.2779 12.2778M14.2222 7.61111C14.2222 11.2623 11.2623 14.2222 7.61111 14.2222C3.95989 14.2222 1 11.2623 1 7.61111C1 3.95989 3.95989 1 7.61111 1C11.2623 1 14.2222 3.95989 14.2222 7.61111Z'
                stroke='#29263a'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Search</p>
          </MobileSearch>
          <InputWrapper>
            <SearchInput
              placeholder={'Quick Search...'}
              value={quickSearch}
              onChange={(e) => setQuickSearch(e.target.value)}
            />
            <SearchButton>
              <img src={search} alt='' />
            </SearchButton>
          </InputWrapper>
        </LeftSide>
        <ButtonWrapper onClick={() => setShowSidebar(true)}>
          <GlobalButton fullWidth height={40} type={'secondary'}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                stroke='#388638'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Add New Staff Member
          </GlobalButton>
        </ButtonWrapper>
      </Navigation>
      <Table
        columnsData={columnsData}
        columnsRows={columnsRows}
        hasMenu
        menuChildren={<Menu onEditClick={() => setShowEditSidebar(true)} />}
      />
      <Sidebar
        title={showSidebar ? 'Add New Staff Member' : 'Edit Staff Member'}
        width={'830px'}
        showSidebar={showSidebar || showEditSidebar}
        onClose={() => {
          setShowSidebar(false);
          setShowEditSidebar(false);
        }}
        onCloseText={'Back to All Staff Members'}
      >
        {showSidebar ? (
          <>
            <SidebarSubtitle>
              You are adding a user(s) to your business Zepathy Digital Agency
            </SidebarSubtitle>
            <Block>
              <InputGroup label={'email'}>
                <GlobalInput
                  placeholder={'User email here'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Block>
            <Block>
              <InputGroup label={'staff members role'}>
                <GlobalSelect options={orderStatusOptions} />
              </InputGroup>
            </Block>
            <Block>
              <InputGroup label={'message'}>
                <GlobalTextarea
                  placeholder={'Write the message here'}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </InputGroup>
            </Block>
            <ButtonsWrapper>
              <GlobalButton
                type='primary'
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                Send invitation
              </GlobalButton>
              <GlobalButton type='gray' onClick={() => setShowSidebar(false)}>
                <XSquareIcon />
                Cancel
              </GlobalButton>
            </ButtonsWrapper>
          </>
        ) : (
          ''
        )}
        {showEditSidebar ? (
          <Content>
            <AccountInfo>
              <Avatar src={avatar} />
              <Contacts>
                <Name>Langton Showira</Name>
                <Text>
                  <ContactLabel>Phone</ContactLabel>
                  <ContactInfo>+263 71 77 562 256</ContactInfo>
                </Text>
                <Text>
                  <ContactLabel>Email:</ContactLabel>
                  <ContactInfo>myemail@gmail.com</ContactInfo>
                </Text>
              </Contacts>
            </AccountInfo>
            <InputGroup label={'staff members role'}>
              <GlobalSelect options={orderStatusOptions} />
            </InputGroup>
            <UpdateButtonWrapper>
              <GlobalButton
                type='primary'
                onClick={() => {
                  setShowEditSidebar(false);
                }}
              >
                <svg
                  width='19'
                  height='18'
                  viewBox='0 0 19 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.66675 7.33333C1.66675 7.33333 3.33757 5.05685 4.69494 3.69854C6.05232 2.34022 7.92808 1.5 10.0001 1.5C14.1422 1.5 17.5001 4.85786 17.5001 9C17.5001 13.1421 14.1422 16.5 10.0001 16.5C6.58084 16.5 3.69601 14.2119 2.79322 11.0833M1.66675 7.33333V2.33333M1.66675 7.33333H6.66675'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                Update
              </GlobalButton>
            </UpdateButtonWrapper>
          </Content>
        ) : (
          ''
        )}
      </Sidebar>
    </Container>
  );
};

export default ManageAllStaffMembers;
