export const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 0px', marginRight: '10px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#29263A'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export const selectCustomStyle = {
  container: (provided) => ({
    ...provided,
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    maxWidth: '200px'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '40px',
    border: '1px solid var(--main-gray-2);',
    boxShadow: 'none',
    borderRadius: '6px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--main-text)'
  })
};
