import { css } from 'styled-components';

export const colors = css`
  :root {
    --main-white: #ffffff;
    --main-gray: #9aac95;
    --main-subtext: #acadb9;
    --main-gray-2: #e2e5eb;
    --main-green: #388638;
    --main-dark-green: #0e3904;
    --main-light-green: #264d1d;
    --main-light-green-2: #ebf3eb;
    --main-light-blue-2: #465b83;
    --main-text: #29263a;
    --light-bg: #f1f7f1;
    --main-orange: #f76d21;
    --main-light-orange: #fff0eb;
    --main-gray-3: #707070;
    --gray-bg: #f4f4f4;
    --divider: #dcdcdc;
    --secondary-text: #5a5670;
    --disabled-text: #c9cad3;
  }
`;
