import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { linkReset, PoppinsFont } from '../../../../assets/style-helpers/style-helpers';

export const BlogCardContainer = styled(Link)`
  ${linkReset};
  border: 1px solid var(--divider);
  display: inline-flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
`;

export const ImgWrapper = styled.div`
  height: 164px;
  margin-bottom: 16px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Main = styled.div`
  padding: 16px 16px 24px;
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 12px;
  line-height: 2;
  margin-bottom: 12px;
  font-weight: 700;
`;

export const Tag = styled.div`
  background: #f9fcf9;
  border: 1px solid #bdd7bd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 2px 12px;
`;

export const Title = styled.div`
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
  ${PoppinsFont};
`;

export const Description = styled.div`
  color: var(--secondary-text);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 14px;
`;

export const Date = styled.div`
  color: var(--main-subtext);
  font-size: 12px;
`;

export const BtnWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoTimer = styled.div`
  height: 20px;
  border-radius: 20px;
  overflow: hidden;
  background: rgba(41, 38, 58, 0.8);
  color: var(--main-white);
  font-size: 10px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-weight: 500;
  padding: 1px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;
