import {
  InputGroupWrapper,
  Section,
  SectionTitle
} from '../../add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../../components/global-input/global-input';
import { GlobalSelect } from '../../../../../components/global-select/global-select';
import { TagsWrapper } from '../business-information-styled';
import { Tag } from '../../add-new-product-single/components/tag/tag';
import { GlobalTextarea } from '../../../../../components/global-textarea/global-textarea';

export const BusinessInformationSection = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Business Information</SectionTitle>
      <InputGroupWrapper>
        <InputGroup label={'name'}>
          <GlobalInput placeholder={'Enter your business name'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'name'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <TagsWrapper>
          <Tag>General Merchandise </Tag>
          <Tag>Real Estate</Tag>
        </TagsWrapper>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'description'}>
          <GlobalTextarea placeholder={'Give a short description of your business'} />
        </InputGroup>
      </InputGroupWrapper>
    </Section>
  );
};
