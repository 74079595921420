import {
  Attributes,
  BannerWrapper,
  Container,
  Gallery,
  GallerySection,
  MainWrapper,
  Sponsored,
  Main
} from './product-info-layout-styled';

export const ProductInfoLayout = ({ children }) => {
  const [gallery, sponsored, info, attributes, banner] = children;

  return (
    <Container>
      <GallerySection>
        <Gallery>{gallery}</Gallery>
        <Sponsored>{sponsored}</Sponsored>
      </GallerySection>
      <Main>
        <MainWrapper>{info}</MainWrapper>
        <Attributes>{attributes}</Attributes>
      </Main>
      <BannerWrapper>{banner}</BannerWrapper>
    </Container>
  );
};
