import styled, { css } from 'styled-components';
import { buttonReset, inputReset } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';
import locationPin from './img/location-pin.svg';

const inputDefaultStyles = css`
  height: 34px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 24px;
`;

export const Container = styled.form`
  border: 1px solid var(--main-gray-2);
  padding: 2px;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  ${inputReset};
  ${inputDefaultStyles};
  margin: 0 16px;
  flex-grow: 1;
  &::placeholder {
    color: var(--main-subtext);
  }

  @media ${device.mobile} {
    max-width: calc(100% - 150px - 34px - 32px);
    flex-grow: 0;
    margin: 0 9px;
  }
`;

export const LocationInput = styled.div`
  ${inputReset};
  ${inputDefaultStyles};
  background: url(${locationPin}) no-repeat 16px 7px, #f4f4f4;
  flex-basis: 140px;
  border-radius: 6px;
  padding-left: 44px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  color: var(--main-text);
  svg {
    display: none;
  }
  @media ${device.laptop} {
    flex-basis: 200px;
  }
  @media ${device.mobile} {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding-left: 0 !important;
    flex-basis: 34px;
    background: #f4f4f4;
    svg {
      display: flex;
    }
    span {
      display: none;
    }
    width: 120px;
    padding-left: 16px;
  }
`;

export const SelectWrapper = styled.div`
  margin-right: 4px;
  margin-top: -2px;
`;

export const SearchBtn = styled.button`
  ${buttonReset};
  border-radius: 6px;
  background: var(--main-green);
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobile} {
    flex-shrink: 0;
  }
`;

export const SearchBtn2 = styled.button`
  ${buttonReset};
  border-radius: 6px;
  background: #f76d21;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  @media ${device.mobile} {
    flex-shrink: 0;
  }
`;
