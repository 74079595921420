import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import avatar from 'assets/temp-img/feedback-avatar.png';
import {
  AvatarWrapper,
  Info,
  InfoCategory,
  InfoMain,
  InfoTitle
} from '../../../company-page/components/reviews/components/feedback-popup/feedback-popup-styled';
import {
  BookingForm,
  BookingFormContent,
  BookingFormLeft,
  BookingFormRight,
  BookingFormTitle,
  Content,
  InputGroupWrapper,
  Notice,
  NoticeText,
  NoticeTitle,
  Slot,
  SlotDate,
  SlotSection,
  SlotsTitle,
  SlotsWrapper
} from './apointment-booking-popup-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalSelect } from 'components/global-select/global-select';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

const selectOptions = [
  { label: 'Select service', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const ApointmentBookingPopup = ({ isApointmentBookingVibisle, onClose }) => {
  return (
    <ModalWrapper alignUnset isVisible={isApointmentBookingVibisle} onClose={onClose}>
      <Content>
        <Info>
          <AvatarWrapper>
            <img src={avatar} alt='' />
          </AvatarWrapper>
          <InfoMain>
            <InfoTitle>So & So Plumbing</InfoTitle>
            <InfoCategory>Trades & Services</InfoCategory>
          </InfoMain>
        </Info>
        <Notice>
          <NoticeTitle>Notice</NoticeTitle>
          <NoticeText>
            This is a notice from service provider. This is a notice from service provider.
          </NoticeText>
        </Notice>
        <BookingForm>
          <BookingFormTitle>Appointment Booking</BookingFormTitle>
          <BookingFormContent>
            <BookingFormLeft>
              <InputGroupWrapper>
                <InputGroup label={'Service'}>
                  <GlobalSelect options={selectOptions} />
                </InputGroup>
              </InputGroupWrapper>
              <InputGroupWrapper>
                <InputGroup label={'Brief description  of appointment'}>
                  <GlobalTextarea placeholder={'Describe the appointment'} />
                </InputGroup>
              </InputGroupWrapper>
              <InputGroupWrapper>
                <InputGroup label={'appointment location'}>
                  <GlobalSelect options={selectOptions} />
                </InputGroup>
              </InputGroupWrapper>
            </BookingFormLeft>
            <BookingFormRight>
              <SlotsTitle>Next Available Slots</SlotsTitle>
              <SlotSection>
                <SlotDate>May 17</SlotDate>
                <SlotsWrapper>
                  <Slot isSelected={true}>11:00 AM</Slot>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                </SlotsWrapper>
              </SlotSection>
              <SlotSection>
                <SlotDate>May 17</SlotDate>
                <SlotsWrapper>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                </SlotsWrapper>
              </SlotSection>
              <SlotSection>
                <SlotDate>May 17</SlotDate>
                <SlotsWrapper>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                </SlotsWrapper>
              </SlotSection>
              <SlotSection>
                <SlotDate>May 17</SlotDate>
                <SlotsWrapper>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                  <Slot>11:30 AM</Slot>
                </SlotsWrapper>
              </SlotSection>
            </BookingFormRight>
          </BookingFormContent>
          <GlobalButton>
            <SvgIcon name={'message-question-square'} />
            Request an appointment
          </GlobalButton>
        </BookingForm>
      </Content>
    </ModalWrapper>
  );
};
