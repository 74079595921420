import {
  Container,
  ImagePreview,
  ImageWrapper,
  InputGroupWrapper,
  Title
} from './create-new-offer-popup-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalSelect } from 'components/global-select/global-select';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

import { ReactComponent as MessagePlusSquare } from './message-plus-square.svg';

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const CreateNewOfferPopup = () => {
  return (
    <Container>
      <Title>Create New Buyer Offer</Title>
      <InputGroupWrapper>
        <InputGroup label={'Name'}>
          <GlobalInput placeholder={'What are you looking for?'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'description'}>
          <GlobalTextarea placeholder={'What are you looking for?'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Product Category'}>
          <GlobalSelect options={selectOptions} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'min price'}>
          <GlobalInput placeholder={'$'} />
        </InputGroup>
        <InputGroup label={'max price'}>
          <GlobalInput placeholder={'$'} />
        </InputGroup>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputGroup label={'Image'}>
          <ImageWrapper>
            <ImagePreview>
              <img src='' alt='' />
            </ImagePreview>
            <GlobalButton type={'orange-secondary'}>
              <SvgIcon name='upload' />
              Upload
            </GlobalButton>
          </ImageWrapper>
        </InputGroup>
      </InputGroupWrapper>
      <GlobalButton fullWidth>
        <MessagePlusSquare />
        Create offer
      </GlobalButton>
    </Container>
  );
};
