import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 103px 124px 103px;
  // @media ${device.laptop} {
  //   padding: 16px 16px 124px;
  // }
  @media ${device.tablet} {
    padding: 0 15px 56px 15px;
  }
`;

export const BreadcrumbsBox = styled.div`
  padding: 16px 0px 0px 103px;
  @media ${device.tablet} {
    padding: 0 15px 5px 15px;
  }
`;

export const Shadow = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 49, 36, 0.4);
  z-index: 111;
`;
