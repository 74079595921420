import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Icon = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.isChecked ? '#388638' : '#DD0000')};
    opacity: 0.1;
  }
  &::after {
    width: 8px;
    height: 8px;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: ${(props) => (props.isChecked ? '#388638' : '#DD0000')};
  }
`;

export const Text = styled.div`
  text-transform: capitalize;
`;
