export const GoogleIcon = () => {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.9887 10.8621H14.3148C13.8883 10.8621 13.543 11.2074 13.543 11.6339V14.7265C13.543 15.1531 13.8883 15.4984 14.3148 15.4984H19.7637C19.1695 17.0472 18.0523 18.3421 16.6355 19.1648L18.9562 23.1867C22.6785 21.0335 24.8824 17.2503 24.8824 13.0203C24.8824 12.416 24.8367 11.9843 24.7504 11.5019C24.6793 11.1312 24.3594 10.8621 23.9887 10.8621Z'
        fill='#167EE6'
      />
      <path
        d='M13.0256 20.1398C10.3596 20.1398 8.03379 18.6824 6.78457 16.5293L2.7627 18.8449C4.80918 22.3945 8.64316 24.7812 13.0256 24.7812C15.1787 24.7812 17.2049 24.2023 18.9568 23.1918V23.1867L16.6361 19.1648C15.5697 19.7844 14.3408 20.1398 13.0256 20.1398Z'
        fill='#12B347'
      />
      <path
        d='M18.9516 23.1917V23.1867L16.6309 19.1648C15.5695 19.7792 14.3406 20.1398 13.0254 20.1398V24.7812C15.1785 24.7812 17.2047 24.2023 18.9516 23.1917Z'
        fill='#0F993E'
      />
      <path
        d='M5.80938 12.9238C5.80938 11.6085 6.16992 10.3796 6.78438 9.31831L2.7625 7.00269C1.74688 8.74448 1.16797 10.7656 1.16797 12.9238C1.16797 15.082 1.74688 17.1031 2.7625 18.8449L6.78438 16.5292C6.16484 15.4679 5.80938 14.239 5.80938 12.9238Z'
        fill='#FFD500'
      />
      <path
        d='M13.0256 5.70781C14.7623 5.70781 16.3619 6.32734 17.6061 7.35313C17.9158 7.60703 18.3627 7.58672 18.642 7.30742L20.8307 5.11875C21.1506 4.79883 21.1252 4.27578 20.785 3.98125C18.6979 2.16328 15.9811 1.06641 13.0256 1.06641C8.64316 1.06641 4.80918 3.45312 2.7627 7.00273L6.78457 9.31836C8.03379 7.16523 10.3596 5.70781 13.0256 5.70781Z'
        fill='#FF4B26'
      />
      <path
        d='M17.6059 7.35313C17.9156 7.60703 18.3625 7.58672 18.6418 7.30742L20.8305 5.11875C21.1504 4.79883 21.125 4.27578 20.7848 3.98125C18.6977 2.16328 15.9809 1.06641 13.0254 1.06641V5.70781C14.7621 5.70781 16.3617 6.32227 17.6059 7.35313Z'
        fill='#D93F21'
      />
    </svg>
  );
};
