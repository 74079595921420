import styled from 'styled-components';

export const DropdownStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin-right: 2px;
  cursor: pointer;
  border: ${(props) => (props.withBorders ? '1px solid #E2E5EB' : '')};
  padding: ${(props) => (props.withBorders ? '9px 5px 9px 18px' : '')};

  img {
    transform: rotate(180deg);
    margin-right: 12px;
  }
`;

export const Name = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-right: 60px;
`;
