import { Buttons, CartPopupBlockStyled, ProductsList, Summary } from './cart-popup-block-styled';

import { BusinessLinkCard } from 'pages/cart/components/business-link-card/business-link-card';
import { ProductItem } from 'pages/cart/components/product-item/product-item';
import { PromocodeInput } from 'pages/cart/components/promocode-input/promocode-input';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

export const CartPopupBlock = ({ products }) => {
  return (
    <CartPopupBlockStyled>
      <BusinessLinkCard />
      <ProductsList>
        {products.map((product, i) => {
          return <ProductItem product={product} key={i} />;
        })}
      </ProductsList>
      <PromocodeInput />
      <Summary>
        <span>Summary</span>
        <span>$1250</span>
      </Summary>
      <Buttons>
        <GlobalButton type={'primary'}>
          <SvgIcon name={'receipt'} />
          Proceed to Checkout
        </GlobalButton>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'cart'} />
          Go to Cart
        </GlobalButton>
      </Buttons>
    </CartPopupBlockStyled>
  );
};
