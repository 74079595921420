import React, { useState } from 'react';

import {
  Container,
  SearchResult,
  TextBold,
  Text,
  TextContainer,
  HorizontalLine,
  MobViewSelection
} from './categories-motor-vehicles-styled-generic-search';
import Header from '../../components/header/header';
import Main from './components/main/main';

import { GlobalFilter } from '../../components/global-filter/global-filter';
import { ControlsSecond, SortBy } from './components/main/motor-vehicles/motor-vehicles-styled';
import Dropdown from '../company-page/components/products/dropdown/dropdown';
import { SearchTopFilter } from '../categories-motor-vehicles-specific-search/components/search-top-filter/search-top-filter';
import { ViewSelection, viewType } from '../../components/view-selection/view-selection';

const CategoriesRealEstate = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [currentViewType, setCurrentVieType] = useState(viewType.grid);
  return (
    <>
      <Header />
      <SearchResult>
        <div>
          <TextContainer>
            <Text>1048 results for</Text>
            <TextBold>«metal»</TextBold>
          </TextContainer>
          <div>
            <MobViewSelection>
              <ViewSelection
                isMobileHidden={false}
                setCurrentViewType={setCurrentVieType}
                currentViewType={currentViewType}
              />
            </MobViewSelection>
            <SearchTopFilter />
          </div>
        </div>
        <HorizontalLine />
      </SearchResult>
      <Container>
        <GlobalFilter
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main
          onFilterBtnClick={() => {
            setMobileFilterVisible(!isMobileFilterVisible);
          }}
          currentViewType={currentViewType}
        />
      </Container>
    </>
  );
};

export default CategoriesRealEstate;
