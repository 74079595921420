import imgDelivered from '../../../../../assets/icons/delivered.svg';
import imgDelivery from '../../../../../assets/icons/delivery.svg';
import imgProcessed from '../../../../../assets/icons/processed.svg';
import imgTransit from '../../../../../assets/icons/route.svg';
import {
  Box,
  IcoDefault,
  IcoDone,
  Img,
  LineDefault,
  LineDone,
  Title,
  TitleDone,
  TrackingLineBlock
} from './tracking-line-styled';

export const TrackingLine = () => {
  const statuses = [
    {
      name: 'Processed',
      ico: imgProcessed,
      status: true
    },
    {
      name: 'In Transit',
      ico: imgTransit,
      status: true
    },
    {
      name: 'Out For Delivery',
      ico: imgDelivery,
      status: true
    },
    {
      name: 'Delivered',
      ico: imgDelivered,
      status: false
    }
  ];

  return (
    <>
      <TrackingLineBlock>
        {statuses.map((status, i) => {
          return (
            <Box key={i}>
              {status.status == true ? (
                <>
                  <IcoDone>
                    {i == 0 ? (
                      ''
                    ) : status.status == true ? (
                      <>
                        <LineDone></LineDone>
                      </>
                    ) : (
                      <>
                        <LineDefault></LineDefault>
                      </>
                    )}
                    <Img src={status.ico} alt={status.name} />
                  </IcoDone>
                  <TitleDone>{status.name}</TitleDone>
                </>
              ) : (
                <>
                  <>
                    <IcoDefault>
                      {i == 0 ? (
                        ''
                      ) : status.status == true ? (
                        <>
                          <LineDone></LineDone>
                        </>
                      ) : (
                        <>
                          <LineDefault></LineDefault>
                        </>
                      )}
                      <Img src={status.ico} alt={status.name} />
                    </IcoDefault>
                    <Title>{status.name}</Title>
                  </>
                </>
              )}
            </Box>
          );
        })}
      </TrackingLineBlock>
    </>
  );
};
