import { ReactComponent as CloseIco } from '../../../../../assets/icons/close.svg';
import Table from '../../../../../components/table/table';
import Dropdown from '../../dropdown/dropdown';
import { columnsData, columnsRows } from './mock';

import {
  BoxClose,
  Count,
  Edit,
  Main,
  Name,
  Orders,
  PriceBox,
  SecondTitle,
  Section,
  SectionOrders,
  Status,
  Title,
  Union,
  UnionContent,
  UnionContentFlex,
  UnionTitle,
  Wrapper
} from './order-styled.jsx';

const customerDetails = [
  {
    name: 'Name',
    content: 'Langton Shoriwa'
  },
  {
    name: 'Phone Number',
    content: '+380 66 12 23 123'
  },
  {
    name: 'Email',
    content: 'langtonshoriwa@gmail.com'
  }
];

const billingDetails = [
  {
    name: 'Name',
    content: 'Langton Shoriwa'
  },
  {
    name: 'Phone Number',
    content: '+380 66 12 23 123'
  },
  {
    name: 'Email',
    content: 'langtonshoriwa@gmail.com'
  },
  {
    name: 'Address',
    content: '6674 21 Street, Glen View 8, Harare Zimbabwe'
  }
];

const orderDetails = [
  {
    name: 'Order Number',
    content: '#12520'
  },
  {
    name: 'Amount',
    content: '$1250'
  },
  {
    name: 'Payment Method',
    content: 'Mukuru'
  },
  {
    name: 'Delivery Address',
    content: '6674 21 Street, Glen View 8, Harare Zimbabwe'
  }
];

const orderStatus = [
  {
    title: 'Order Status',
    status: 'Paid'
  }
];

export const Order = ({ closeOrderForm }) => {
  return (
    <>
      <Title>
        Order #12520
        <BoxClose onClick={closeOrderForm}>
          <CloseIco />
        </BoxClose>
      </Title>
      <Wrapper>
        <Main>
          <Section>
            <SecondTitle>Customer Details</SecondTitle>
            {customerDetails.map((item, key) => (
              <Union key={key}>
                <UnionTitle>{item.name}</UnionTitle>
                <UnionContent>{item.content}</UnionContent>
              </Union>
            ))}
          </Section>
          <Section>
            <SecondTitle>Billing Details</SecondTitle>
            {billingDetails.map((item, key) => (
              <Union key={key}>
                <UnionTitle>{item.name}</UnionTitle>
                <UnionContent>{item.content}</UnionContent>
              </Union>
            ))}
          </Section>
          <Section>
            <SecondTitle>Order Details</SecondTitle>
            {orderDetails.map((item, key) => (
              <Union key={key}>
                <UnionTitle>{item.name}</UnionTitle>
                <UnionContent>{item.content}</UnionContent>
              </Union>
            ))}
          </Section>
          <Section>
            <SecondTitle>Order Status</SecondTitle>
            <Union>
              <UnionTitle>{orderStatus[0].title}</UnionTitle>
              <UnionContentFlex>
                <Status>
                  <Dropdown name={orderStatus[0].status} withBorders />
                </Status>
                <Edit>Change Status</Edit>
              </UnionContentFlex>
            </Union>
          </Section>
          <SectionOrders>
            <SecondTitle>Ordered Products</SecondTitle>
            <Orders>
              <Table columnsData={columnsData} columnsRows={columnsRows} />
            </Orders>
          </SectionOrders>
          <PriceBox>
            <Name>Total Amount</Name>
            <Count>$1250</Count>
          </PriceBox>
        </Main>
      </Wrapper>
    </>
  );
};
