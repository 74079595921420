import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media ${device.laptop} {
    gap: 18px;
  }
  @media ${device.tablet} {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const IconWrapper = styled.div`
  width: 42px;
  height: 42px;
  display: block;
  margin-bottom: 8px;
  svg {
    width: 100%;
    height: 100%;
    fill: none;
    path {
      stroke: var(--main-green);
      stroke-width: 1;
      fill: none;
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 15px;
    text-align: center;
  }
`;

export const Item = styled.div`
  background: var(--light-bg);
  border-radius: 12px;
  padding: 24px 28px 32px;
  @media ${device.laptop} {
    padding: 16px 20px 30px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
  @media ${device.mobile} {
    padding: 16px 16px 20px;
  }
`;
