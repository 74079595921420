import React from 'react';

import MotorVehicles from '../../components/main/motor-vehicles/motor-vehicles';
import { MainStyled } from '../../../categories-motor-vehicles/components/main/main-styled';

const Main = ({ onFilterBtnClick, currentViewType }) => {
  return (
    <MainStyled>
      <MotorVehicles onFilterBtnClick={onFilterBtnClick} currentViewType={currentViewType} />
    </MainStyled>
  );
};

export default Main;
