import { useState } from 'react';
import {
  Container,
  Side,
  TabTitle,
  Main,
  FiltersWrapper,
  FilterWidgetBottomDesktop,
  FilterWidgetBottomMobile
} from './filter-widget-styled';
import { GlobalCheckbox } from '../global-checkbox/global-checkbox';
import { FilterWidgetBottom } from './components/filter-widget-bottom/filter-widget-bottom';
import { ModalWrapper } from '../modal-wrapper/modal-wrapper';

const initialFilters = [
  {
    id: 1,
    name: 'Filter type',
    qty: 22,
    checkboxes: [
      { name: 'Filter', id: 1, checked: false },
      { name: 'Filter 2', id: 2, checked: false },
      { name: 'Filter 3', id: 3, checked: false },
      { name: 'Filter 4', id: 4, checked: false },
      { name: 'Filter 5', id: 5, checked: false },
      { name: 'Filter 6', id: 6, checked: false },
      { name: 'Filter 7', id: 7, checked: false },
      { name: 'Filter 8', id: 8, checked: false }
    ]
  },
  {
    id: 2,
    name: 'Filter type 2',
    qty: 31,
    checkboxes: [
      { name: 'Filter 9', id: 1, checked: false },
      { name: 'Filter 10', id: 2, checked: false },
      { name: 'Filter 11', id: 3, checked: false },
      { name: 'Filter 12', id: 4, checked: false },
      { name: 'Filter 13', id: 5, checked: false },
      { name: 'Filter 14', id: 6, checked: false },
      { name: 'Filter 15', id: 7, checked: false },
      { name: 'Filter 16', id: 8, checked: false }
    ]
  },
  {
    id: 3,
    name: 'Filter type 3',
    qty: 2,
    checkboxes: [
      { name: 'Filter 17', id: 1, checked: false },
      { name: 'Filter 18', id: 2, checked: false },
      { name: 'Filter 19', id: 3, checked: false },
      { name: 'Filter 20', id: 4, checked: false },
      { name: 'Filter 21', id: 5, checked: false },
      { name: 'Filter 22', id: 6, checked: false },
      { name: 'Filter 23', id: 7, checked: false },
      { name: 'Filter 24', id: 8, checked: false }
    ]
  }
];

export const FilterWidget = ({ onClose, isVisible, setIsVisible }) => {
  const [filters, setFilters] = useState(initialFilters);

  const [currentTab, setCurrentTab] = useState(initialFilters[0]);

  const onCheckboxClick = (id) => {
    const currentGroup = filters.find((item) => item.id === currentTab.id);
    const currentItem = currentGroup.checkboxes.find((item) => item.id === id);
    const updatedItem = { ...currentItem, checked: !currentItem.checked };
    const updatedItemIndex = currentGroup.checkboxes.findIndex(
      (item) => item.id === updatedItem.id
    );
    const updatedGroupCheckboxes = [
      ...currentGroup.checkboxes.slice(0, updatedItemIndex),
      updatedItem,
      ...currentGroup.checkboxes.slice(updatedItemIndex + 1)
    ];

    const updatedGroupIndex = filters.findIndex((item) => item.id === currentGroup.id);
    const updatedFilters = [
      ...filters.slice(0, updatedGroupIndex),
      { ...currentGroup, checkboxes: updatedGroupCheckboxes },
      ...filters.slice(updatedGroupIndex + 1)
    ];
    setFilters(updatedFilters);
    setCurrentTab(updatedFilters[updatedGroupIndex]);
  };

  return (
    <ModalWrapper
      withoutPadding
      isVisible={isVisible}
      onClose={() => {
        setIsVisible(false);
      }}
    >
      <Container>
        <Side>
          {filters.map((item) => {
            return (
              <TabTitle
                isActive={currentTab.id === item.id}
                key={item.id}
                onClick={() => {
                  setCurrentTab(item);
                }}
              >
                <div>
                  {item.name}
                  <span>{item.qty}</span>
                </div>

                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8 14L12 10L8 6'
                    stroke='currentColor'
                    strokeWidth='1.7'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </TabTitle>
            );
          })}
        </Side>
        <Main>
          <FiltersWrapper>
            <GlobalCheckbox>Select all</GlobalCheckbox>
            {currentTab.checkboxes.map((item) => (
              <div key={item.id}>
                <GlobalCheckbox
                  isChecked={item.checked}
                  onChange={() => {
                    onCheckboxClick(item.id);
                  }}
                  id={item.id}
                >
                  {item.name}
                </GlobalCheckbox>
              </div>
            ))}
          </FiltersWrapper>
          <FilterWidgetBottomDesktop>
            <FilterWidgetBottom
              checkboxes={currentTab.checkboxes.filter((item) => item.checked)}
              onClose={onClose}
              onTagClick={onCheckboxClick}
            />
          </FilterWidgetBottomDesktop>
        </Main>
        <FilterWidgetBottomMobile>
          <FilterWidgetBottom
            checkboxes={currentTab.checkboxes.filter((item) => item.checked)}
            onClose={onClose}
          />
        </FilterWidgetBottomMobile>
      </Container>
    </ModalWrapper>
  );
};
