import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const ResultsPerPageStyled = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  > p {
    color: var(--main-subtext);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  > span {
    display: flex;
    align-items: center;
    color: var(--main-text);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    cursor: pointer;

    > svg {
      margin-left: 6px;
    }
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
