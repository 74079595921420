import styled from 'styled-components';
import { inputReset } from '../../assets/style-helpers/style-helpers';

export const StyledInput = styled.input`
  ${inputReset};
  height: 52px;
  border: 1px solid var(--main-gray-2);
  border-radius: 6px;
  width: ${(props) => (props.width ? props.width : '100%')};
  font-size: 14px;
  line-height: 24px;
  padding: 14px 24px;
  transition: all 0.3s;
  color: var(--main-text);
  font-family: 'Lato', sans-serif;
  &::placeholder {
    color: var(--main-subtext);
  }
  &:hover {
    border: 1px solid var(--main-gray);
  }
`;
