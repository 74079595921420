import React, { useState } from 'react';

import {
  FoodAndGroceryStyled,
  ButtonMobile,
  ControlsSecond,
  FilterButton,
  Header,
  HorizontalLine,
  RightSection,
  SortBy,
  Title,
  TradesNav,
  TradesNavMobile,
  View,
  DropdownStyled,
  Tags,
  Tag,
  ControlsSecondDesktop,
  ResultsPerPage
} from './food-and-grocery-styled';

import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import InputWithLabel from '../../../../../components/input-with-label/input-with-label';
import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';

import { RealStateAdSpace } from '../../../../categories-real-estate/components/main/real-estate/real-estate-styled';
import { CARS } from '../../../../../dummy-data/cars';
import { PaginationWrapper } from '../../../../categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import { LoadMore } from '../../../../../components/load-more/load-more';

import filter from '../../../../../assets/filter.svg';
import arrowDown from '../../../../../assets/dropdown_blue_arrow_down.svg';
import yogurt from '../../../../../assets/yogurt_image.png';
import glassenJuise from '../../../../../assets/glassen_juice_image.png';
import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import { viewType } from '../../../../../components/view-selection/view-selection';
import { CardsSection } from '../../../../categories/components/trades-and-services/trades-and-services-styled';
import Pagination from '../../../../../components/pagination/pagination';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

export const TAGS = ['Hampers', 'Christmas Essentials', 'Featured Products', '#DealsOfTheDay'];

const FOOD_PRODUCTS_LIST = [
  {
    title: 'Yogurt - A real dairy product 3%',
    subtitle: 'General Merchandise | Norton',
    price: '$10.00',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: yogurt
  },
  {
    title: 'GLASSEN Juice - Apple Juice NEW',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: glassenJuise
  },
  {
    title: 'Yogurt - A real dairy product 3%',
    subtitle: 'General Merchandise | Norton',
    price: '$10.00',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: yogurt
  },
  {
    title: 'GLASSEN Juice - Apple Juice NEW',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: glassenJuise
  },
  {
    title: 'Yogurt - A real dairy product 3%',
    subtitle: 'General Merchandise | Norton',
    price: '$10.00',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: yogurt
  },
  {
    title: 'GLASSEN Juice - Apple Juice NEW',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: glassenJuise
  },
  {
    title: 'Yogurt - A real dairy product 3%',
    subtitle: 'General Merchandise | Norton',
    price: '$10.00',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: yogurt
  },
  {
    title: 'GLASSEN Juice - Apple Juice NEW',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: glassenJuise
  },
  {
    title: 'Yogurt - A real dairy product 3%',
    subtitle: 'General Merchandise | Norton',
    price: '$10.00',
    rate: 4.1,
    reviews: 36,
    description: 'Samar Haute Plug | Norton',
    img: yogurt
  }
];

const MotorVehicles = ({ onFilterBtnClick }) => {
  const [search, setSearch] = React.useState('');
  const [currentView, setCurrentView] = useState(viewType.grid);

  return (
    <FoodAndGroceryStyled>
      <CategoriesTopFilter
        title={'Food and groceries'}
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search'}
        onFilterBtnClick={onFilterBtnClick}
        isMobileViewHidden={false}
        currentViewType={currentView}
        setCurrentViewType={setCurrentView}
      />
      <CardsSection currentViewType={currentView}>
        {FOOD_PRODUCTS_LIST.map((data, index) => (
          <PCard
            product={data}
            withLabel={true}
            type={ProductCardTypes.GROCERIES}
            key={index}
            currentViewType={currentView}
          />
        ))}
      </CardsSection>
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
      <RealStateAdSpace />
    </FoodAndGroceryStyled>
  );
};

export default MotorVehicles;
