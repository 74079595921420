import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.mobile} {
    height: 350px;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 50px;
  line-height: 70px;
  ${(props) => {
    switch (props.titleSize) {
      case 'lg':
        return css`
          font-size: 50px;
          line-height: 70px;
          @media ${device.tablet} {
            font-size: 32px;
            line-height: 38px;
          }
        `;
      case 'sm':
        return css`
          font-size: 30px;
          line-height: 1.1;
        `;
    }
  }}
`;

export const Subtitle = styled.div`
  text-align: center;
`;

export const Btn = styled.div`
  background: var(--main-orange);
  padding: 8px 16px;
  border-radius: 6px;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 24px;
`;

export const Inner = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
