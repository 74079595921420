import {
  Section,
  SectionTitle,
  GridInputWrapper,
  DateInput
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../components/input-group/input-group';

export const ProductLiveAuctionDay = () => {
  return (
    <Section>
      <SectionTitle>Live Auctions Day</SectionTitle>
      <GridInputWrapper>
        <InputGroup label={'Auction start date'}>
          <DateInput type={'date'} />
        </InputGroup>
        <InputGroup label={'Auction end date'}>
          <DateInput type={'date'} />
        </InputGroup>
      </GridInputWrapper>
    </Section>
  );
};
