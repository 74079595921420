export const PhoneIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.6873 9.41662C9.28584 10.6632 10.1018 11.8316 11.1351 12.8649C12.1684 13.8982 13.3368 14.7142 14.5834 15.3127C14.6906 15.3642 14.7442 15.3899 14.8121 15.4097C15.0532 15.48 15.3492 15.4295 15.5534 15.2833C15.6108 15.2422 15.66 15.193 15.7583 15.0947C16.059 14.794 16.2093 14.6437 16.3604 14.5454C16.9305 14.1748 17.6654 14.1748 18.2355 14.5454C18.3866 14.6437 18.537 14.794 18.8376 15.0947L19.0052 15.2623C19.4622 15.7193 19.6907 15.9478 19.8148 16.1932C20.0617 16.6813 20.0617 17.2577 19.8148 17.7458C19.6907 17.9912 19.4622 18.2197 19.0052 18.6767L18.8696 18.8123C18.4142 19.2678 18.1864 19.4955 17.8768 19.6694C17.5333 19.8624 16.9997 20.0012 16.6056 20C16.2505 19.9989 16.0078 19.93 15.5224 19.7923C12.9138 19.0519 10.4523 17.6549 8.39869 15.6013C6.34511 13.5477 4.94812 11.0862 4.20772 8.47761C4.06995 7.99221 4.00106 7.7495 4.00001 7.39439C3.99883 7.00033 4.1376 6.46673 4.33059 6.12317C4.50452 5.81356 4.73225 5.58583 5.1877 5.13037L5.32326 4.99481C5.78028 4.53779 6.00879 4.30928 6.25421 4.18515C6.74229 3.93828 7.3187 3.93828 7.80678 4.18515C8.05219 4.30928 8.2807 4.53779 8.73772 4.99481L8.90531 5.16239C9.20595 5.46304 9.35628 5.61337 9.45456 5.76453C9.8252 6.33459 9.8252 7.0695 9.45456 7.63956C9.35628 7.79072 9.20595 7.94105 8.90531 8.24169C8.807 8.34 8.75785 8.38915 8.71671 8.44661C8.5705 8.65079 8.52002 8.94684 8.5903 9.18793C8.61008 9.25577 8.63582 9.30939 8.6873 9.41662Z'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
