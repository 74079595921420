import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../../../../../assets/style-helpers/style-helpers';

export const TagStyled = styled.div`
  padding: 8px 12px 8px 18px;
  border: 1px solid #bdd7bd;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${LatoFont};
  font-weight: 600;
  line-height: 16px;

  & + & {
    margin-left: 8px;
  }
`;

export const CloseBtn = styled.button`
  ${buttonReset};
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
