import styled from 'styled-components';
import { buttonReset } from '../../../../../../assets/style-helpers/style-helpers';
import { linkWithUnderline } from '../../../../../../assets/style-helpers/typography';

export const Container = styled.div`
  padding-top: 16px;
`;

export const ReportCard = styled.div`
  padding: 16px 20px 20px;
  background: var(--light-bg);
  border-radius: 6px;

  & + & {
    margin-top: 12px;
  }
`;

export const ReportCardTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ReportCardAvatar = styled.div`
  margin-right: 12px;
  display: flex;
`;

export const ReportCardName = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
`;

export const ReportCardText = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  font-family: 'Lato', sans-serif;
`;

export const ReportCartBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Time = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: var(--main-subtext);
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

export const Btn = styled.button`
  ${buttonReset};
  ${linkWithUnderline};
  font-family: 'Lato', sans-serif;
`;
