import styled from 'styled-components';
import {
  buttonReset,
  headerContainer,
  LatoFont
} from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const TopSection = styled.div`
  padding: 8px 0;
  background-color: #f5f7fb;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  max-height: 45px;

  @media ${device.tablet} {
    display: none;
  }

  .contact-info {
    display: flex;
    align-items: center;

    .phone {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
      p {
        color: #acadb9;
      }
    }

    .line {
      border-left: 1px solid #e8e8e8;
      margin: 0 21px;
      content: '';
      height: 15px;
    }

    .chat {
      display: flex;

      .chat-icon,
      p {
        margin-right: 10px;
      }

      .arrow-icon {
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
    .sell-items-btn {
      ${buttonReset};
      color: var(--main-white);
      border-radius: 6px;
      padding: 2px 20px;
      display: flex;
      align-items: center;
      ${LatoFont};

      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 200% */
      margin-left: 40px;
      svg {
        margin-right: 8px;
      }
      background: linear-gradient(166deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%),
        #f76d21;
    }
  }
`;

export const Container = styled.div`
  ${headerContainer};
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonsDivider = styled.div`
  margin: 0 20px;
  width: 1px;
  height: 15px;
  background: #e8e8e8;
`;
