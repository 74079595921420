import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { GlobalButton } from 'components/global-button/global-button';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalSelect } from 'components/global-select/global-select';
import { InputGroup } from 'components/input-group/input-group';

import {
  InputGroupWrapper,
  Section,
  SectionSubtitle,
  SectionTitle
} from '../add-new-product-single/add-new-product-single-styled';
import { Title } from '../add-new-product/add-new-product-styled';
import { ButtonsWrapper, Container, Main, Side, Wrapper } from './create-new-order-styled';

import { useState } from 'react';
import { SidebarBackground } from '../business-information/business-information-styled';
import { CreateNewOderSidebar } from './components/create-new-oder-sidebar';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as SaveIcon } from './icons/save.svg';
import { ReactComponent as XSquareIcon } from './icons/x-square.svg';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Orders' },
  { link: '#', name: 'Create New Order' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const CreateNewOrder = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Create New Order</Title>
      <Wrapper>
        <Main>
          <Section>
            <SectionTitle>Customer Billing Details</SectionTitle>
            <InputGroupWrapper>
              <InputGroup label={'name'}>
                <GlobalInput placeholder={'Enter the name '} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'email'}>
                <GlobalInput placeholder={'Enter your email '} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'WhatsApp number'}>
                <GlobalInputSelect placeholder={'Your WhatsApp Number '} />
              </InputGroup>
            </InputGroupWrapper>

            <SectionSubtitle>Physical Address</SectionSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street Address'}>
                <GlobalInput placeholder={'Enter your streer address'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'City / town'}>
                <GlobalInput placeholder={'Enter your city or town'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput placeholder={'Enter your country'} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SectionTitle>Order Details</SectionTitle>

            <InputGroupWrapper>
              <InputGroup label={'Order status'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'payment method'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'delivery method'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>

            <SectionSubtitle>Delivery Address</SectionSubtitle>
            <InputGroupWrapper>
              <InputGroup label={'street Address'}>
                <GlobalInput placeholder={'Enter your streer address'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'City / town'}>
                <GlobalInput placeholder={'Enter your city or town'} />
              </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputGroup label={'country'}>
                <GlobalInput placeholder={'Enter your country'} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>
          <Section>
            <SectionTitle>Order Items</SectionTitle>
            <InputGroupWrapper>
              <InputGroup label={'delivery method'}>
                <GlobalSelect options={selectOptions} />
              </InputGroup>
            </InputGroupWrapper>
          </Section>

          <ButtonsWrapper>
            <GlobalButton type='primary'>
              <PlusIcon />
              Create Order
            </GlobalButton>

            <div>
              <GlobalButton type='orange-secondary'>
                <SaveIcon />
                Save Draft
              </GlobalButton>
              <GlobalButton type='gray'>
                <XSquareIcon />
                Cancel Order
              </GlobalButton>
            </div>
          </ButtonsWrapper>
        </Main>
        <Side isSidebarHidden={isSidebarHidden}>
          <CreateNewOderSidebar
            isSidebarHidden={isSidebarHidden}
            setIsSidebarHidden={setIsSidebarHidden}
          />
          <SidebarBackground />
        </Side>
      </Wrapper>
    </Container>
  );
};
