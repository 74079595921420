import { GlobalButton } from '../global-button/global-button';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Container,
  KnowledgeBox,
  Photo,
  Block,
  Title,
  Description,
  ButtonBlock
} from './knowledge-base-info-styled';
import img1 from '../../assets/article.png';

const singles = [
  {
    img: img1,
    title: 'News & Updates',
    description:
      'Stay informed about the newest products, services, and company developments as well as industry insights and trends.',
    titleLink: 'See All',
    link: 'See All'
  },
  {
    img: img1,
    title: 'News & Updates 2',
    description:
      'Stay informed about the newest products, services, and company developments as well as industry insights and trends.',
    titleLink: 'See All',
    link: 'See All'
  },
  {
    img: img1,
    title: 'News & Updates 3',
    description:
      'Stay informed about the newest products, services, and company developments as well as industry insights and trends.',
    titleLink: 'See All',
    link: 'See All'
  }
];

export const KnowledgeBaseInfo = () => {
  return (
    <Container>
      {singles.map((single, index) => (
        <KnowledgeBox key={index}>
          <Photo src={single.img} alt={single.title} />
          <Block>
            <Title>{single.title}</Title>
            <Description>{single.description}</Description>
            <ButtonBlock>
              <GlobalButton height={40} type={'green'} fullWidth>
                <SvgIcon name='eye' />
                {single.titleLink}
              </GlobalButton>
            </ButtonBlock>
          </Block>
        </KnowledgeBox>
      ))}
    </Container>
  );
};
