import { FoodAndGroceryStyled, ShowMore } from './clothing-apparel-styled';

import { GlobalButton } from '../../../../../components/global-button/global-button';

import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';

import product from '../../../../../assets/product2.png';
import { useNavigate } from 'react-router';
import { CARS } from '../../../../../dummy-data/cars';
import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import { useState } from 'react';
import { viewType } from '../../../../../components/view-selection/view-selection';
import { CardsSection } from '../../../../categories/components/trades-and-services/trades-and-services-styled';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

const FOOD_PRODUCTS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: false,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  }
];

const ActiveTags = ['$0 - $1mln', 'Used', 'Original', 'Harare Province', 'Harare'];

const ClothingApparel = ({ onFilterBtnClick }) => {
  const navigate = useNavigate();
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);

  return (
    <FoodAndGroceryStyled>
      <CategoriesTopFilter
        title={'Clothing & Apparel'}
        onNearBtnClick={() => {}}
        searchPlaceholder={'Search in Clothing & Apparel'}
        onFilterBtnClick={onFilterBtnClick}
        currentViewType={currentViewType}
        setCurrentViewType={setCurrentViewType}
        isMobileViewHidden={false}
      />
      <CardsSection currentViewType={currentViewType}>
        {FOOD_PRODUCTS_LIST.map((data, index) => (
          <PCard
            product={data}
            withLabel={true}
            type={ProductCardTypes.CLOTHING}
            key={index}
            currentViewType={currentViewType}
          />
        ))}
      </CardsSection>
      <ShowMore>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'eye'} />
          See more products
        </GlobalButton>
      </ShowMore>
    </FoodAndGroceryStyled>
  );
};

export default ClothingApparel;
