import { ReactComponent as ThreeDots } from '../../../../assets/three-dots.svg';

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Newsletter Name',
    accessor: 'newsletterName'
  },
  {
    Header: 'Subject',
    accessor: 'subject'
  },
  {
    Header: 'Date Sent',
    accessor: 'dateSent'
  },
  {
    Header: 'Customers',
    accessor: 'customers'
  },
  {
    Header: 'Actions',
    accessor: 'dots'
  }
];

export const columnsRows = [
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12522,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 99 Buyers',
    dateSent: '17 May 2024',
    customers: '90',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Newsletter Mbinga Dzangu',
    subject: 'New products in Stock. 20% Off to First 10 Buyers',
    dateSent: '07 Jul 2022',
    customers: '30',
    dots: <ThreeDots />
  },
  {
    id: 12520,
    newsletterName: 'Joe Biden',
    subject: 'New products in Stock.',
    dateSent: '09 Jul 2022',
    customers: '9',
    dots: <ThreeDots />
  },
  {
    id: 12529,
    newsletterName: 'Newsletter David Mayer',
    subject: 'New products in Stock. 20% Off to First 90 Buyers',
    dateSent: '07 Jul 2029',
    customers: '40',
    dots: <ThreeDots />
  }
];
