import Header from '../../components/header/header';
import { StoreFrontTop } from '../../components/store-front-top/store-front-top';
import { TradesServicesStoreMock } from './mock/trades-services-mock';
import { ButtonsTop, ButtonWrapper, Container } from './store-front-trades-services-styled';
import Reviews from '../company-page/components/reviews/reviews';
import { OurServices } from './components/our-services/our-services';
import { OurProducts } from './components/our-products/our-products';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../components/global-button/global-button';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { useState } from 'react';
import { ApointmentBookingPopup } from './components/apointment-booking-popup/apointment-booking-popup';
import { OtherStoresSidebar } from './components/other-stores-sidebar/other-stores-sidebar';

const breadcrumbs = [
  { link: '#', name: 'Home' },
  { link: '#', name: 'Trades & Services' },
  { link: '#', name: 'So & So Plumbing' }
];

const TradesAndServicesButtons = ({ onOtherStoreBtnClick }) => {
  const [isApointmentBookingVibisle, setIsApointmentBookingVibisle] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  return (
    <ButtonWrapper>
      <ApointmentBookingPopup
        isApointmentBookingVibisle={isApointmentBookingVibisle}
        onClose={() => {
          setIsApointmentBookingVibisle(false);
        }}
      />
      <ButtonsTop>
        <GlobalButton
          onClick={() => {
            setIsApointmentBookingVibisle(true);
          }}
        >
          <SvgIcon name={'annotation-check'} />
          Book An Appointment
        </GlobalButton>
        <GlobalButton
          checked={isFollowed}
          type={'orange-secondary'}
          onClick={() => {
            setIsFollowed(!isFollowed);
          }}
        >
          <SvgIcon name={'bell'} />
          {isFollowed ? 'Followed' : 'Follow'}
        </GlobalButton>
      </ButtonsTop>
      <GlobalButton type={'orange'} fullWidth onClick={onOtherStoreBtnClick}>
        <SvgIcon name={'building'} />
        Our other stores
      </GlobalButton>
    </ButtonWrapper>
  );
};

export const StoreFrontTradesServices = () => {
  const [isOtherStoresVisible, setIsOtherStoresVisible] = useState(false);
  return (
    <>
      <OtherStoresSidebar
        isVisible={isOtherStoresVisible}
        onCloseBtnClick={() => {
          setIsOtherStoresVisible(false);
        }}
        title={'Other Stores Of This Vendor'}
      />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <StoreFrontTop
          storeInfo={TradesServicesStoreMock}
          customButtonSection={() => (
            <TradesAndServicesButtons
              onOtherStoreBtnClick={() => {
                setIsOtherStoresVisible(true);
              }}
            />
          )}
        />
        <Reviews slidesToShow={4} title={'What Our Clients Are Saying'} />
        <OurServices />
        <OurProducts />
      </Container>
    </>
  );
};
