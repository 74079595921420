export const BooksIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 18.3V15.6H7.625C6.17525 15.6 5 16.8088 5 18.3M9.2 21H16.2C17.1801 21 17.6701 21 18.0445 20.8038C18.3738 20.6312 18.6415 20.3559 18.8093 20.0172C19 19.6321 19 19.1281 19 18.12V5.88C19 4.87191 19 4.36786 18.8093 3.98282C18.6415 3.64413 18.3738 3.36876 18.0445 3.19619C17.6701 3 17.1801 3 16.2 3H9.2C7.72986 3 6.99479 3 6.43327 3.29428C5.93935 3.55314 5.53778 3.96619 5.28611 4.47423C5 5.05179 5 5.80786 5 7.32V16.68C5 18.1921 5 18.9482 5.28611 19.5258C5.53778 20.0338 5.93935 20.4469 6.43327 20.7057C6.99479 21 7.72986 21 9.2 21Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
