import Header from 'components/header/header';
import { StoreFrontTop } from 'components/store-front-top/store-front-top';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Reviews from '../company-page/components/reviews/reviews';
import { Services } from './components/properties/components/services/services';
import { Properties } from './components/properties/properties';
import { RealEstateStoreMock } from './mock/real-estate-store-mock';
import { Container } from './store-front-real-estate-styled';
import { useState } from 'react';
import { OtherStoresSidebar } from '../store-front-trades-services/components/other-stores-sidebar/other-stores-sidebar';
import {
  ButtonsTop,
  ButtonWrapper
} from '../store-front-trades-services/store-front-trades-services-styled';
import { ApointmentBookingPopup } from '../store-front-trades-services/components/apointment-booking-popup/apointment-booking-popup';
import { GlobalButton } from '../../components/global-button/global-button';
import { SvgIcon } from '../../components/svg-icon/svg-icon';

const breadcrumbs = [
  {
    link: '#',
    name: 'Home'
  },
  {
    link: '#',
    name: 'Real Estate'
  },
  {
    link: '#',
    name: 'Properties'
  }
];

const RealEstateButtons = ({ onOtherStoreBtnClick }) => {
  const [isApointmentBookingVibisle, setIsApointmentBookingVibisle] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  return (
    <ButtonWrapper>
      <ApointmentBookingPopup
        isApointmentBookingVibisle={isApointmentBookingVibisle}
        onClose={() => {
          setIsApointmentBookingVibisle(false);
        }}
      />
      <ButtonsTop>
        <GlobalButton
          onClick={() => {
            setIsApointmentBookingVibisle(true);
          }}
        >
          <SvgIcon name={'annotation-check'} />
          Book An Appointment
        </GlobalButton>
        <GlobalButton
          checked={isFollowed}
          type={'orange-secondary'}
          onClick={() => {
            setIsFollowed(!isFollowed);
          }}
        >
          <SvgIcon name={'bell'} />
          {isFollowed ? 'Followed' : 'Follow'}
        </GlobalButton>
      </ButtonsTop>
      <GlobalButton type={'orange'} fullWidth onClick={onOtherStoreBtnClick}>
        <SvgIcon name={'building'} />
        Our other offices
      </GlobalButton>
    </ButtonWrapper>
  );
};

export const StoreFrontRealEstate = () => {
  const [isOtherStoresVisible, setIsOtherStoresVisible] = useState(false);

  return (
    <>
      <OtherStoresSidebar
        isVisible={isOtherStoresVisible}
        onCloseBtnClick={() => {
          setIsOtherStoresVisible(false);
        }}
        title={'Other Offices Of This Vendor'}
      />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <StoreFrontTop
          storeInfo={RealEstateStoreMock}
          customButtonSection={() => (
            <RealEstateButtons
              onOtherStoreBtnClick={() => {
                setIsOtherStoresVisible(true);
              }}
            />
          )}
        />
        <Reviews slidesToShow={4} title={'What Our Clients Are Saying'} />
        <Properties />
        <Services />
      </Container>
    </>
  );
};
