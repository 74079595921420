import styled from 'styled-components';
import { LatoFont } from '../../../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 20px 16px 24px;
  & + & {
    margin-top: 20px;
    @media ${device.tablet} {
      margin-top: 0;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const Info = styled.div``;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const Subtitle = styled.div`
  color: var(--main-subtext);
  ${LatoFont};
  margin-bottom: 4px;
`;

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RateInfo = styled.div`
  display: flex;
  ${LatoFont};
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
`;

export const Reviews = styled.div`
  color: var(--main-green);
  padding-right: 6px;
  margin-right: 6px;
  border-right: 1px solid var(--divider);
`;
export const Properties = styled.div``;

export const Main = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid var(--divider);
`;

export const AddressTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
