export const TiktokIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.1918 1.60865L11.8058 1H9.46977V6.48185L9.46181 11.8364C9.46579 11.8762 9.46977 11.9199 9.46977 11.9597C9.46977 13.3003 8.37935 14.3943 7.03424 14.3943C5.68912 14.3943 4.5987 13.3043 4.5987 11.9597C4.5987 10.6191 5.68912 9.52511 7.03424 9.52511C7.31281 9.52511 7.58342 9.57683 7.83414 9.66435V6.99105C7.57547 6.94729 7.30883 6.92342 7.03424 6.92342C4.26043 6.9274 2 9.18697 2 11.9637C2 14.7404 4.26043 17 7.03821 17C9.816 17 12.0764 14.7404 12.0764 11.9637V5.59473C13.0833 6.60119 14.3846 7.58379 15.8252 7.89806V5.16509C14.2612 4.4729 12.7052 2.42815 12.1918 1.60865Z'
        stroke='#388638'
        strokeWidth='1.5'
      />
    </svg>
  );
};
