import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.section`
  padding-bottom: 110px;
  padding-right: 40px;

  @media ${device.tablet} {
    padding-bottom: 60px;
    padding-right: 0;
  }

  @media ${device.mobile} {
    padding-bottom: 48px;
  }

  table {
    td > div {
      align-items: flex-start;
      padding-left: 0;
    }
    div:hover {
      background: transparent;
    }
  }
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const Status = styled.div``;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 17px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 282px;
  width: 100%;

  @media ${device.tablet} {
    max-width: unset;
    margin-right: 16px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileSearch = styled.div`
  display: none;

  @media ${device.mobile} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 11px;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: var(--main-text);
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid var(--divider);
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  padding: 6px 0 6px 18px;
  outline: none;
`;

export const SearchButton = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-green);
  max-height: 32px;
  border-radius: 6px;
  padding: 9px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const Content = styled.div`
  padding-bottom: 106px;
  @media ${device.tablet} {
    padding-bottom: 0;
  }
  @media ${device.mobile} {
    padding-bottom: 0;
  }
  > div {
    margin-bottom: 18px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  max-width: 202px;
  flex-wrap: wrap;
  margin: 15px 0;
`;

export const Tag = styled.div`
  width: max-content;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--main-text);
  background: #f7f7f7;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 0 6px 6px 0;
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }

  > div {
    width: 160px;
    height: 38px;
    margin-right: 12px;
    > p {
      margin-right: 9px;
    }
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FilterButton = styled.button`
  display: none;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }

  @media ${device.mobile} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }s
  }
`;

export const BoxCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ChecbkoxText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
`;

export const Img = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 6px;
  object-fit: cover;
`;

export const Block = styled.div`
  width: 185px !important;
  flex-direction: column;
  padding-left: 0 !important;
  align-items: flex-start !important;
`;

export const Title = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #29263a;
`;

export const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #29263a;
  margin-top: 1px;
`;

export const IcoBox = styled.div`
  cursor: pointer;
`;

export const CategoriesBox = styled.div`
  width: 120px !important;
  flex-direction: column;
  align-items: flex-start !important;
`;
