import { SvgIcon } from '../svg-icon/svg-icon';
import {
  CloseBtn,
  Container,
  CoponInfo,
  CopyBtn,
  CouponNumber,
  Description,
  Title
} from './coupon-styled';
import { GlobalButton } from '../global-button/global-button';

export const Coupon = () => {
  return (
    <Container>
      <CloseBtn>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.1673 5.83337L5.83398 14.1667M5.83398 5.83337L14.1673 14.1667'
            stroke='#ACADB9'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </CloseBtn>
      <Title>
        <SvgIcon name='gift' />
        Get 10% On Your Next Order
      </Title>
      <Description>
        To thank you for your order here is a <span>10% OFF</span> coupon to use on your next order
        below $100.
      </Description>
      <CoponInfo>
        <CouponNumber>EW2324IUK</CouponNumber>
        <CopyBtn>
          <SvgIcon name='copy' />
          Copy Coupon
        </CopyBtn>
      </CoponInfo>
      <GlobalButton height={40} type={'green'} fullWidth>
        <SvgIcon name={'cart'} />
        Continue Shopping
      </GlobalButton>
    </Container>
  );
};
