import { css } from 'styled-components';
import { device } from './breakpoints';

export const paragraph30 = css`
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
`;

export const title30 = css`
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
`;

export const title20 = css`
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
`;

export const categoryTitle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const tag = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  @media ${device.mobile} {
  }
`;

export const cardTitleBig = css`
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
`;

export const cardPriceBig = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const cardTitleSmall = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const linkWithUnderline = css`
  position: relative;
  color: var(--main-green);
  font-weight: 600;
  transition: all 0.3s;
  &:hover {
    color: var(--main-green);
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: var(--main-green);
    opacity: 0.5;
  }
`;
