import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { RangeSlider } from '../../../../../../components/range-slider/range-slider';
import {
  Categories,
  CategoriesItem,
  CategoriesList,
  CategoriesTitle,
  Container,
  FilterMain,
  FilterTitle
} from './filter-styled';

export const Filter = () => {
  return (
    <Container>
      <FilterTitle>
        <span>Filters</span>
        <SvgIcon name={'filter'} />
      </FilterTitle>
      <FilterMain>
        <Categories>
          <CategoriesTitle>Store Categories</CategoriesTitle>
          <CategoriesList>
            <CategoriesItem>Books & Stationary</CategoriesItem>
            <CategoriesItem>Clothing & Apparel</CategoriesItem>
            <CategoriesItem>Construction & Hardware Supplies</CategoriesItem>
            <CategoriesItem>Electronic Goods</CategoriesItem>
            <CategoriesItem>Farming Supplies</CategoriesItem>
            <CategoriesItem>Food & Groceries</CategoriesItem>
            <CategoriesItem>Health & Beauty</CategoriesItem>
            <CategoriesItem>Home & Garden</CategoriesItem>
            <CategoriesItem>Industrial Supplies</CategoriesItem>
            <CategoriesItem>Motor Spare & Accessories</CategoriesItem>
            <CategoriesItem>Motor Vehicles</CategoriesItem>
            <CategoriesItem>Real Estate</CategoriesItem>
            <CategoriesItem>Sports Hobbies & Leisure</CategoriesItem>
            <CategoriesItem>Trades & Services</CategoriesItem>
          </CategoriesList>
        </Categories>
        <CategoriesTitle>Search Radius</CategoriesTitle>
        <RangeSlider min={0.5} max={100} minVal={0.5} maxVal={100} onChange={() => {}}>
          <span>0.5Km</span>
          <span>100Km</span>
        </RangeSlider>
      </FilterMain>
    </Container>
  );
};
