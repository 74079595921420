import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import {
  BackgroundContent,
  BackgroundImage,
  BackgroundStyled,
  Button,
  SubTitle,
  Title
} from './background-styled';

import background from '../../../../../assets/clothing.png';

export const Background = ({ title, subtitle, buttonText }) => {
  return (
    <BackgroundStyled>
      <BackgroundImage src={background} alt='' />
      <BackgroundContent>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Button>
          <SvgIcon name='call' />
          {buttonText}
        </Button>
      </BackgroundContent>
    </BackgroundStyled>
  );
};

export default Background;
