import React, { useState } from 'react';

import { RealEstateStyled, LandScapeCardSection, RealStateAdSpace } from './real-estate-styled';

import CategoriesCard from '../../../../../components/categories-card/categories-card';

import image1 from '../../../../../assets/categories_card_image_1.png';
import image2 from '../../../../../assets/categories_card_image_2.png';
import image3 from '../../../../../assets/categories_card_image_3.png';
import bed from '../../../../../assets/bed.svg';
import measure from '../../../../../assets/measure.svg';
import {
  CardsSection,
  PaginationWrapper
} from '../../../../categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import { viewType } from '../../../../../components/view-selection/view-selection';

import { LoadMore } from '../../../../../components/load-more/load-more';

import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import Pagination from '../../../../../components/pagination/pagination';

export const TYPES = [
  {
    name: 'Service Type'
  },
  {
    name: 'Sub Type'
  },
  {
    name: 'Province'
  },
  {
    name: 'City'
  },
  {
    name: 'Suburb'
  }
];

export const CHECKBOXES = [
  {
    label: 'To Let',
    checked: true
  },
  {
    label: 'For Sale',
    checked: false
  }
];

export const CARDS = [
  {
    img: [image1, image2, image3, image2],
    title: 'Master Duplex in Mutare City Centre',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '200 000',
    bids: '3 Bids',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    rate: 3.5,
    reviews: 51,
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  },
  {
    img: [image1, image2, image3, image2],
    title: '1 Room to rent in Waterfalls',
    location: 'Harare SBD',
    tags: [
      {
        image: bed,
        name: '4 Bedrooms'
      },
      {
        image: measure,
        name: '400m'
      }
    ],
    price: '1 200',
    bids: 'Per month',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum...'
  }
];

const RealEstate = ({ onFilterBtnClick }) => {
  const [search, setSearch] = React.useState('');
  const [checkboxes, setCheckboxes] = React.useState(CHECKBOXES);
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  const handleCheckbox = (label, checked, arr, setState) => {
    const newState = arr.map((obj) => {
      if (obj.label === label) {
        return { ...obj, checked: checked };
      }
      return obj;
    });
    setState(newState);
  };
  return (
    <RealEstateStyled>
      <CategoriesTopFilter
        isMobileViewHidden={false}
        currentViewType={currentViewType}
        setCurrentViewType={setCurrentViewType}
        onFilterBtnClick={onFilterBtnClick}
        searchPlaceholder={'Enter what you are looking for...'}
        search={search}
        setSearch={setSearch}
        title={'Real estate'}
      />
      {currentViewType === viewType.grid ? (
        <CardsSection>
          {CARDS.map((data, index) => {
            if (index === 4) {
              return (
                <React.Fragment key={index}>
                  <RealStateAdSpace />
                  <CategoriesCard product={data} />
                </React.Fragment>
              );
            }
            return <CategoriesCard key={index} product={data} />;
          })}
        </CardsSection>
      ) : (
        <LandScapeCardSection>
          {CARDS.map((data, index) => {
            if (index === 4) {
              return (
                <React.Fragment key={index}>
                  <RealStateAdSpace />
                  <CategoriesCard product={data} landScape />
                </React.Fragment>
              );
            }
            return <CategoriesCard key={index} product={data} landScape />;
          })}
        </LandScapeCardSection>
      )}
      <PaginationWrapper>
        <Pagination currentPage={1} totalCount={10} pageSize={2} onPageChange={() => {}} />
      </PaginationWrapper>
    </RealEstateStyled>
  );
};

export default RealEstate;
