import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Form = styled.form`
  width: 587px;
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobile} {
    > button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;
