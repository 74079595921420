import styled from 'styled-components';
import { title20 } from 'assets/style-helpers/typography';
import placeholder from './placeholder.svg';
import { device } from 'assets/style-helpers/breakpoints';

export const Container = styled.div`
  width: 580px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Title = styled.div`
  ${title20};
  margin-bottom: 20px;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  & + & {
    margin-top: 18px;
  }
  > div {
    flex-grow: 1;
    @media ${device.mobile} {
      flex-basis: 100%;
    }
  }

  > div + div {
    margin-left: 12px;
    @media ${device.mobile} {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const ImagePreview = styled.div`
  border-radius: 12px;
  border: 1px solid var(--divider);
  width: 80px;
  height: 80px;
  background: url(${placeholder}) no-repeat center center;
  background-size: 32px 32px;
  margin-right: 18px;
`;
