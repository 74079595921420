import {
  CheckboxWrapper,
  GridInputWrapper4Items,
  PricingBtnWrapper,
  PricingRow,
  Section,
  SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';

export const ProductPricingHireItem = ({ selectOptions }) => {
  return (
    <Section>
      <SectionTitle>Pricing</SectionTitle>
      <CheckboxWrapper>
        <GlobalCheckbox>Price is on inquiry</GlobalCheckbox>
        <GridInputWrapper4Items>
          <InputGroup label={'Currency'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
          <InputGroup label={'Regular price'}>
            <GlobalInput placeholder={'$'} />
          </InputGroup>
          <InputGroup label={'Time'}>
            <GlobalSelect options={selectOptions} />
          </InputGroup>
          <InputGroup label={'Max Mileage'}>
            <GlobalInput placeholder={'Enter the max mileage'} />
          </InputGroup>
        </GridInputWrapper4Items>
        <PricingBtnWrapper>
          <GlobalButton type={'orange-secondary'}>
            <SvgIcon name={'plus'} />
            Add Price Variable
          </GlobalButton>
        </PricingBtnWrapper>
        <PricingRow>
          <GlobalCheckbox>This price is negotiable</GlobalCheckbox>
        </PricingRow>
      </CheckboxWrapper>
    </Section>
  );
};
