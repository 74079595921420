import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { Link } from 'react-router-dom';

export const StoreLocatorLink = styled(Link)`
  display: flex;
`;

export const Desktop = styled.div`
  display: flex;
  background: var(--main-green);
  padding: 2px 20px;
  border-radius: 6px;
  p {
    align-items: center;
  }
  color: var(--main-white);
  img {
    margin-right: 10px;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`;
