import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  max-width: 1690px;
  margin: 0 auto;
  padding: 0 16px;
  @media ${device.laptop} {
    max-width: 1266px;
  }
`;

export const SidebarWrapper = styled.div`
  flex-basis: 290px;
  margin-right: 32px;
  min-width: 0;
  @media ${device.laptop} {
    position: fixed;
    top: 134px;
    left: 0;
    background: var(--main-white);
    padding-left: 103px;
    padding-right: 16px;
    box-shadow: 0px 4px 60px rgba(70, 91, 131, 0.1);
    transition: all 0.3s;
    z-index: 100;

    @media ${device.laptop} {
      overflow-y: scroll;
      height: auto;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 3px;
        background: rgb(243 243 243);
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(56, 134, 56, 0.1);
      }
    }

    ${(props) =>
      props.isSidebarVisible
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-100%);
          `}
  }

  @media ${device.tablet} {
    width: 100%;
    padding-left: 16px;
    height: 100vh;
    padding-top: 5px;
  }
  @media ${device.mobile} {
    top: 135px;
    padding-top: 12.5px;
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const DashboardBtnWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    margin-bottom: 14px;
  }
  @media ${device.tablet} {
    margin-top: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 10px;
  }
`;

export const Title = styled.div`
  ${title30};
  margin-top: 5px;

  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobile} {
  }
`;

export const ProfileContainer = styled.div`
  width: 881px;
  @media ${device.laptop} {
    margin-bottom: 80px;
  }
  @media ${device.tablet} {
    width: 100%;
    background: #ffffff;
  }
`;

export const ProfileBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px;
  padding-bottom: 35px;
  margin-top: 30px;

  @media ${device.mobile} {
    box-shadow: 0px 0px 0px rgba(62, 73, 59, 0.06);
    border-radius: 0px;
    padding: 15px 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }
`;

export const ProfileTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #29263a;
  margin-bottom: 21px;
`;

export const ProfileAvatar = styled.div`
  display: flex;
  align-items: center;
`;

export const Profile = styled.div``;

export const PhotoBlock = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 4px;
  position: relative;
`;

export const CloseBlock = styled.span`
  position: absolute;
  right: -7px;
  top: -8px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(12, 50, 116, 0.06);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #388638;
  cursor: pointer;

  > img {
    width: 8.33px;
  }
`;

export const Photo = styled.img``;

export const BoxPhoto = styled.div`
  margin-left: 32px;
  margin-top: 5px;
`;

export const BoxPhotoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonTextInfo = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #acadb9;
  margin-top: 8px;
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FormBox = styled.div`
  width: 49%;
  margin-top: 20px;
  > label {
    width: 50%;
  }
  @media ${device.mobile} {
    width: 100%;
    > label {
      width: 100%;
    }
  }
`;

export const SaveResult = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
    > button {
      width: 100%;
    }
  }
`;

export const ForgotPassword = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #388638;
  margin-left: 26px;
  padding-bottom: 1px;
  cursor: pointer;
  border-bottom: 1px solid #388638;

  @media ${device.mobile} {
    margin-top: 12px;
    margin-left: 0px;
  }
`;

export const DeleteAccount = styled.div`
  margin-top: 35px;

  @media ${device.mobile} {
    margin-top: 20px;
    padding-top: 21px;
    border-top: 1px solid #dcdcdc;
  }
`;

export const ChangePassword = styled.div`
  margin-top: -20px;
`;
