import styled from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
`;

export const InputBlock = styled.div`
  position: absolute;
  padding: 3px;
  left: 0;
  top: 0;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const CloseBtn = styled.button`
  ${buttonReset};
  color: #c9cad3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBtn = styled.button`
  ${buttonReset};
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-green);
  border-radius: 4px;
`;
