import { SponsoredCard } from '../../../../components/sponsored-card/sponsored-card';
import { Container, Main, Title } from './sponsored-banners-styled';

export const SponsoredBanners = () => {
  return (
    <Container>
      <Title>Sponsored</Title>
      <Main>
        <SponsoredCard />
        <SponsoredCard />
      </Main>
    </Container>
  );
};
