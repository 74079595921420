import Product from '../../../../../assets/product.png';
import { Img } from './order-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product Name',
    accessor: 'productname'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const columnsRows = [
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    productname: 'Dish Washing Machine',
    qty: '100',
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    productname: 'Dish Washing Machine',
    qty: '100',
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Img src={Product} alt='Product' />,
    productname: 'Dish Washing Machine',
    qty: '100',
    price: '$200 000 000',
    total: '$200 000 000'
  }
];
