import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const List = styled.div`
  padding: 16px 8px 16px 16px;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: var(--main-text);
  span {
    margin-left: 10px;
  }
  &:hover {
    color: var(--main-green);
  }

  & + & {
    margin-top: 12px;
  }
`;
