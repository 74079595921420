import { AddNewProductLayout } from '../../components/add-new-product-layout/add-new-product-layout';
import { ProductDetails } from '../../components/add-product-form-components/product-details';
import { ProductCategories } from '../../components/add-product-form-components/product-categories';
import { ProductImage } from '../../components/add-product-form-components/product-image';
import { ProductAttributes } from '../../components/add-product-form-components/product-attributes';
import { ProductSubmitBtns } from '../../components/add-product-form-components/product-submit-btns';
import { useState } from 'react';
import { ProductPricingAuction } from '../../components/add-product-form-components/product-pricing-auction';
import { ProductLiveAuctionDay } from '../../components/add-product-form-components/product-live-auction-day';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'Add New Product' }
];

const selectOptions = [
  { label: 'test', value: 'test' },
  { label: 'test1', value: 'test1' },
  { label: 'test2', value: 'test2' }
];

export const AddNewProductSingleAuction = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <AddNewProductLayout
      isSidebarHidden={isSidebarHidden}
      setIsSidebarHidden={setIsSidebarHidden}
      pageTitle={'Add Auction Product'}
    >
      <ProductDetails selectOptions={selectOptions} />
      <ProductCategories selectOptions={selectOptions} breadcrumbs={breadcrumbs} />
      <ProductImage />
      <ProductPricingAuction />
      <ProductLiveAuctionDay />
      <ProductAttributes selectOptions={selectOptions} />
      <ProductSubmitBtns
        isSidebarHidden={isSidebarHidden}
        setIsSidebarHidden={setIsSidebarHidden}
      />
    </AddNewProductLayout>
  );
};
