import Header from '../../components/header/header';
import {
  Container,
  Products,
  Title
} from '../single-product-general/single-product-general-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { ProductInfoLayout } from '../../components/product-info-layout/product-info-layout';
import { ProductGallery } from '../../components/product-gallery/product-gallery';
import { SponsoredBanners } from '../single-product-general/components/sponsored-banners/sponsored-banners';
import { ProductDetails } from '../../components/product-info/components/product-details/product-details';
import { SideBanner } from '../single-product-general/components/side-banner/side-banner';
import { ProductsSlider } from '../../components/products-slider/products-slider';

import React, { useState } from 'react';
import { ServiceCard } from '../store-front-trades-services/components/our-services/components/service-card/service-card';
import { ProductInfoTrandesServices } from '../../components/product-info/product-info-trandes-services';
import { Sidebar } from '../single-product-general/components/sidebar/sidebar';

const servicesCardsMock = [
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  },
  {
    rate: 4,
    reviews: 36,
    title: 'General Plumbing',
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi psum ipsum dolor sit amet consectetur nullam...',
    price: 250,
    img: 'https://picsum.photos/200/300'
  }
];

export const SingleProductTradesAndServices = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs />
        <ProductInfoLayout>
          <ProductGallery />
          <SponsoredBanners />
          <ProductInfoTrandesServices setIsSidebarHidden={setIsSidebarHidden} />
          <ProductDetails attributesTitle={'Service Offers'} />
          <SideBanner />
        </ProductInfoLayout>
      </Container>
      <Products>
        <Container>
          <Title>Related Services</Title>
        </Container>
        <ProductsSlider>
          {servicesCardsMock.map((service, index) => {
            return <ServiceCard service={service} key={index} isListView={false} />;
          })}
        </ProductsSlider>
      </Products>
      <Sidebar isSidebarHidden={isSidebarHidden} setIsSidebarHidden={setIsSidebarHidden} />
    </>
  );
};
