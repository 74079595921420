import styled from 'styled-components';
import { device } from 'assets/style-helpers/breakpoints';

export const Container = styled.div`
  margin-bottom: 100px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
