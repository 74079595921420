import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
`;

export const FilterWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
`;

export const MainTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media ${device.mobile} {
    align-items: center;
    position: relative;
  }
`;

export const SearchWrapper = styled.div`
  flex-basis: 560px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const SelectWithLabelWrapper = styled.div`
  //flex-basis: 240px;
  margin-right: 15px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  ${(props) =>
    props.isListView &&
    css`
      grid-template-columns: 1fr;
    `};

  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
    ${(props) =>
      props.isListView &&
      css`
        grid-template-columns: 1fr;
      `}
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    ${(props) =>
      props.isListView &&
      css`
        grid-template-columns: 1fr;
      `}
    ${(props) =>
      props.isSmallCardsOnMobile &&
      css`
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 22px;
      `}
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    ${(props) =>
      props.isSmallCardsOnMobile &&
      css`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
      `}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media ${device.mobile} {
    button {
      width: 100%;
    }
  }
`;

export const MobileFilterBtn = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    margin-right: 22px;
  }
`;

export const MobileSort = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    margin-left: 32px;
  }
`;
