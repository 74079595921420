import GoogleMapReact from 'google-map-react';

import Header from 'components/header/header';
import { MobileBottomMenu } from 'components/mobile-bottom-menu/mobile-bottom-menu';

import {
  C,
  Container,
  FinderWidgetMainWrapper,
  HideMapBtn,
  K,
  Map,
  MapInner,
  MapWrapper,
  MobileFilterBtn,
  MobileFinderBtn,
  MobileResultsBtn,
  StoreFinderFilter,
  TopContainer,
  Wrapper
} from './store-finder-styled';
import { Filter } from './components/filter/filter';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { useState } from 'react';
import { SearchResults, searchType } from './components/search-results/search-results';
import { storeFinderProducts, storeFinderStoresMock } from './mock/mock';
import { viewType } from '../../components/view-selection/view-selection';
import { FinderWidget } from './components/finder-widget/finder-widget';
import { BreadcrumbsWrapper } from './components/filter/filter-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';

export const GoogleMapsMarker = () => (
  <svg
    width='124'
    height='124'
    viewBox='0 0 124 124'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.3' filter='url(#filter0_d_1_10658)'>
      <circle cx='62' cy='58' r='32' fill='#F78821' />
    </g>
    <g filter='url(#filter1_d_1_10658)'>
      <circle cx='62.0048' cy='58.0029' r='19.6923' fill='#F76D21' />
    </g>
    <circle cx='62.0096' cy='58.0057' r='7.38462' fill='white' />
    <defs>
      <filter
        id='filter0_d_1_10658'
        x='0'
        y='0'
        width='124'
        height='124'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='15' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.219608 0 0 0 0 0.52549 0 0 0 0 0.219608 0 0 0 0.3 0'
        />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_10658' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_10658' result='shape' />
      </filter>
      <filter
        id='filter1_d_1_10658'
        x='12.3125'
        y='12.3105'
        width='99.3828'
        height='99.3848'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='15' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.203922 0 0 0 0 0.658824 0 0 0 0 0.321569 0 0 0 0.3 0'
        />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_10658' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_10658' result='shape' />
      </filter>
    </defs>
  </svg>
);

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Store Finder' }
];

export const StoreFinder = () => {
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSearchDataVisible, setSearchDataVisible] = useState(true);
  const [currentSearchType, setCurrentSearchType] = useState(searchType.all);
  const [currentViewType, setCurrentViewType] = useState(viewType.grid);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [products, setProducts] = useState(storeFinderProducts);
  const [stores, setStores] = useState(storeFinderStoresMock);
  const onStoreFinderSearch = (data) => {
    setSearchData(data);
    setSearchDataVisible(true);
  };

  const onResultTypeChange = (type) => {
    setCurrentSearchType(type);
  };

  return (
    <>
      <Header isShopFinder onStoreFinderSearch={onStoreFinderSearch} />
      <Container>
        <TopContainer>
          <BreadcrumbsWrapper>
            <Breadcrumbs links={breadcrumbs} />
          </BreadcrumbsWrapper>
          {isSearchDataVisible && (
            <FinderWidgetMainWrapper>
              <FinderWidget />
            </FinderWidgetMainWrapper>
          )}
        </TopContainer>

        <MobileBottomMenu />

        <MobileResultsBtn>
          <SvgIcon name={'eye'} />
          <span>See 50+ results in selected location</span>
        </MobileResultsBtn>
        <C>
          <StoreFinderFilter>
            <Filter
              isMobileFilterVisible={isMobileFilterVisible}
              setIsMobileFilterVisible={setIsMobileFilterVisible}
              onHideResultsBtnClick={() => {
                setSearchDataVisible(false);
              }}
              isSearchDataVisible={isSearchDataVisible}
            />
          </StoreFinderFilter>
          <Wrapper>
            <K>
              {isSearchDataVisible && (
                <SearchResults
                  setIsMobileFilterVisible={setIsMobileFilterVisible}
                  isMapVisible={isMapVisible}
                  onShowMapBtnClick={() => {
                    setIsMapVisible(true);
                    setStores(storeFinderStoresMock);
                    setProducts(storeFinderProducts);
                  }}
                  onResultTypeChange={onResultTypeChange}
                  currentType={currentSearchType}
                  currentViewType={currentViewType}
                  setCurrentViewType={setCurrentViewType}
                  products={products}
                  stores={stores}
                  onHideResultBtnClick={() => {
                    setSearchDataVisible(false);
                  }}
                />
              )}
              {isMapVisible && (
                <MapWrapper>
                  <MapInner>
                    <MobileFilterBtn
                      onClick={() => {
                        setIsMobileFilterVisible(true);
                      }}
                    >
                      <SvgIcon name={'filter'} />
                      <span>Filter</span>
                    </MobileFilterBtn>
                    <MobileFinderBtn
                      onClick={() => {
                        setSearchDataVisible(true);
                      }}
                    >
                      <svg
                        width='25'
                        height='24'
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.6016 13C14.2584 13 15.6016 11.6569 15.6016 10C15.6016 8.34315 14.2584 7 12.6016 7C10.9447 7 9.60156 8.34315 9.60156 10C9.60156 11.6569 10.9447 13 12.6016 13Z'
                          stroke='#F76D21'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M12.6016 22C16.6016 18 20.6016 14.4183 20.6016 10C20.6016 5.58172 17.0198 2 12.6016 2C8.18328 2 4.60156 5.58172 4.60156 10C4.60156 14.4183 8.60156 18 12.6016 22Z'
                          stroke='#F76D21'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>

                      <span>Store Finder</span>
                    </MobileFinderBtn>
                    {isSearchDataVisible && (
                      <HideMapBtn
                        onClick={() => {
                          setIsMapVisible(false);
                          setStores([
                            ...storeFinderStoresMock,
                            ...storeFinderStoresMock,
                            ...storeFinderStoresMock
                          ]);
                          setProducts([
                            ...storeFinderProducts,
                            ...storeFinderProducts,
                            ...storeFinderProducts,
                            ...storeFinderProducts
                          ]);
                        }}
                      >
                        <span>Hide map</span>
                        <svg
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.5 15L12.5 10L7.5 5'
                            stroke='#388638'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </HideMapBtn>
                    )}

                    <Map>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: '' }}
                        defaultZoom={9}
                        defaultCenter={{
                          lat: -19.23878101020672,
                          lng: 29.339569563132443
                        }}
                      >
                        <GoogleMapsMarker
                          lat={-19.23878101020672}
                          lng={29.339569563132443}
                          showBallon={true}
                        />
                        <GoogleMapsMarker
                          lat={-19.172}
                          lng={29.339569563132443}
                          showBallon={true}
                        />
                        <GoogleMapsMarker
                          lat={-20.23878101020672}
                          lng={29.339569563132443}
                          showBallon={true}
                        />
                      </GoogleMapReact>
                    </Map>
                  </MapInner>
                </MapWrapper>
              )}
            </K>
          </Wrapper>
        </C>
      </Container>
    </>
  );
};
