import {
  ButtonMobile,
  CardsSecondSection,
  CardsSectionDeals,
  CardsSectionSecond,
  ClearTags,
  Close,
  Deals,
  DealsAd,
  DealsBox,
  DealsBoxCarts,
  FilterButton,
  FilterTags,
  FoodAndGroceryStyled,
  Header,
  HorizontalLine,
  RightSection,
  SecondTitle,
  ShowMore,
  Tag,
  Title,
  TradesNav,
  TradesNavMobile
} from './hats-styled';

import { GlobalButton } from '../../../../../components/global-button/global-button';

import { ReactComponent as CloseIco } from '../../../../../assets/close.svg';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';

import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import filter from '../../../../../assets/filter.svg';
import miles from '../../../../../assets/miles.svg';
import product from '../../../../../assets/product2.png';
import year from '../../../../../assets/year.svg';
import { CARS } from '../../../../../dummy-data/cars';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

const FOOD_PRODUCTS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: false,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  }
];

const FOOD_PRODUCTS_DEALS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    mark: 'Sale',
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    mark: 'New',
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    mark: 'New',
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    mark: 'New',
    old_price: '$39.99'
  }
];

const ActiveTags = ['$0 - $1mln', 'Used', 'Original', 'Harare Province', 'Harare'];

const Hats = ({ onFilterBtnClick }) => {
  return (
    <FoodAndGroceryStyled>
      <Header>
        <Title>Men’s Hats</Title>
      </Header>
      <HorizontalLine />
      <TradesNav>
        <FilterButton onClick={onFilterBtnClick}>
          <img src={filter} alt='' />
          Filters
        </FilterButton>
        <ButtonMobile>
          <SvgIcon name='sort' />
          <p>Sort</p>
        </ButtonMobile>
      </TradesNav>
      <TradesNavMobile>
        <FilterButton onClick={onFilterBtnClick}>
          <img src={filter} alt='' />
          Filters
        </FilterButton>
        <RightSection>
          <ButtonMobile>
            <SvgIcon name='search' />
            <p>Search</p>
          </ButtonMobile>
          <ButtonMobile>
            <SvgIcon name='sort' />
            <p>Sort</p>
          </ButtonMobile>
        </RightSection>
      </TradesNavMobile>
      <FilterTags>
        {ActiveTags.map((tag, index) => (
          <Tag key={index}>
            <Close>
              <CloseIco />
            </Close>
            {tag}
          </Tag>
        ))}
        <ClearTags>
          <Close>
            <CloseIco />
          </Close>
          Сlear all
        </ClearTags>
      </FilterTags>
      <CardsSectionSecond>
        {FOOD_PRODUCTS_LIST.map((data, index) => (
          <PCard product={data} withLabel={true} type={ProductCardTypes.CLOTHING} key={index} />
        ))}
      </CardsSectionSecond>
      <CardsSecondSection>
        <SecondTitle>Featured Products</SecondTitle>
        <CardsSectionSecond>
          {FOOD_PRODUCTS_LIST.map((data, index) => (
            <PCard product={data} withLabel={true} type={ProductCardTypes.CLOTHING} key={index} />
          ))}
        </CardsSectionSecond>
        <Deals>
          <DealsAd></DealsAd>
          <DealsBox>
            <SecondTitle>Deals Of The Day</SecondTitle>
            <DealsBoxCarts>
              <CardsSectionDeals>
                {FOOD_PRODUCTS_DEALS_LIST.map((data, index) => (
                  <PCard
                    product={data}
                    withLabel={true}
                    type={ProductCardTypes.DEALS}
                    key={index}
                  />
                ))}
              </CardsSectionDeals>
            </DealsBoxCarts>
          </DealsBox>
        </Deals>
        <CardsSectionSecond>
          {FOOD_PRODUCTS_LIST.map((data, index) => (
            <PCard product={data} withLabel={true} type={ProductCardTypes.CLOTHING} key={index} />
          ))}
        </CardsSectionSecond>
      </CardsSecondSection>
      <ShowMore>
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'eye'} />
          See more products
        </GlobalButton>
      </ShowMore>
    </FoodAndGroceryStyled>
  );
};

export default Hats;
