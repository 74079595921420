import styled, { css } from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';

export const TrackingLineBlock = styled.div`
  display: flex;
  width: 530px;
  margin-top: 42px;
  flex-direction: column;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const NameBlock = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #388638;
  text-align: right;
  margin-bottom: 3px;
`;

export const TitleTracking = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #29263a;
  margin-top: 10px;
  margin-bottom: 8px;
`;

export const Data = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29263a;
  text-align: right;
  width: 80px;
  @media ${device.mobile} {
    width: 95px;
  }
`;

export const Time = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29263a;
  text-align: right;
`;

export const Status = styled.div`
  margin: 38px 56px;
`;

export const Information = styled.div`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  color: #29263a;
`;

export const InformationName = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #29263a;
  margin-top: 2px;
  @media ${device.mobile} {
    width: 135px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const IcoDefault = styled.span`
  position: relative;
  width: 32px;
  height: 32px;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${(props) =>
    props.isActive
      ? css`
          background: #388638;
        `
      : css`
          background: #e8e8e8;
        `}
  &:after {
    position: absolute;
    content: '';
    left: 15px;
    bottom: -90px;
    border: 1px dashed #dcdcdc;
    height: 90px;
    width: 0px;
    z-index: -1;
    ${(props) =>
      props.isLast &&
      css`
        display: none;
      `}
  }
`;

export const Img = styled.img``;

export const TitleDone = styled.div`
  color: #29263a;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
`;

export const Title = styled.div`
  color: #29263a;
  opacity: 0.5;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
`;
