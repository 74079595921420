import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const AccountInfoMap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 78px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    margin: 15px 15px 0 15px;
  }
`;

export const Account = styled.section`
  width: 100%;
  background: #f5f7fb;
  border-radius: 12px;
  padding: 20px 20px 20px 20px;
  margin: 0 19px 0 106px;

  @media ${device.mobile} {
    margin: 0;
    padding: 12px 12px 25px 12px;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media ${device.mobile} {
    display: block;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e5eb;
  margin-right: 20px;
  border-radius: 6px;

  @media ${device.mobile} {
    width: 80px;
    height: 80px;
    margin-right: 15px;
  }
`;

export const Text = styled.div`
  .name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #29263a;
    margin-bottom: 5px;
  }

  .role {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #acadb9;
    margin-bottom: 10px;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;

  .stars {
    margin-right: 6px;
  }

  .reviews {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #29263a;

    span {
      color: #3664f6;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 18px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  background-color: var(--main-orange);
  border: none;
  border-radius: 6px;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-top: 12px;
    justify-content: center;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--main-gray-2);
  margin: 22px 0;
`;

export const HorizontalLineMobile = styled.div`
  display: none;
  @media ${device.mobile} {
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--main-gray-2);
    margin: 15px 0 22px 0;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media ${device.mobile} {
    display: block;
  }
`;

export const AboutUs = styled.div`
  width: 100%;
  @media ${device.mobile} {
    width: unset;
  }
  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #29263a;
    margin-bottom: 10px;
  }

  .description {
    max-width: 340px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #29263a;
  }
`;

export const TimeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 75%;
  @media ${device.mobile} {
    display: flex;
    justify-content: space-between;
    width: unset;
  }
`;

export const Time = styled.div`
  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #29263a;
    margin-bottom: 10px;
  }

  .schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #29263a;

    .day {
      font-weight: 700;
      margin-right: 12px;
    }

    .hours {
      font-weight: 400;
      color: #5a5670;
    }

    @media ${device.mobile} {
      justify-content: flex-start !important;
    }
  }
`;

export const TimeSecond = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: self-end;

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #29263a;
    margin-bottom: 10px;
  }

  .schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #29263a;

    .day {
      font-weight: 700;
      margin-right: 12px;
    }

    .hours {
      font-weight: 400;
      color: #5a5670;
    }
  }

  .status {
    margin-bottom: 12px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 73px;
  background: rgba(124, 204, 103, 0.15);
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: #7ccc67;
  margin-bottom: 14px;

  span {
    margin-right: 6px;
  }
`;
