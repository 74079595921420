import { Section, SectionTitle } from '../../add-new-product-single/add-new-product-single-styled';
import { Radio } from '../../../../../components/radio/radio';
import { useState } from 'react';
import {
  GreenText,
  HourInput,
  RadioWrapper,
  WorkingHoursCell,
  WorkingHoursForm,
  WorkingHoursRow
} from '../business-information-styled';
import Checkbox from '../../../../../components/checkbox/checkbox';

export const OpeningTimesSection = () => {
  const [activeRadio, setActiveRadio] = useState('no');
  // eslint-disable-next-line no-unused-vars
  const [openTimesList, setOpenTimesList] = useState([
    { title: 'Monday', isDisabled: true, start: '00:00', end: '00:00' },
    { title: 'Tuesday', isDisabled: false, start: '00:00', end: '00:00' },
    { title: 'Wednesday', isDisabled: false, start: '00:00', end: '00:00' },
    { title: 'Thursday', isDisabled: false, start: '00:00', end: '00:00' },
    { title: 'Friday', isDisabled: false, start: '00:00', end: '00:00' },
    { title: 'Saturday', isDisabled: false, start: '00:00', end: '00:00' },
    { title: 'Sunday', isDisabled: false, start: '00:00', end: '00:00' }
  ]);
  return (
    <Section>
      <SectionTitle>Opening Times</SectionTitle>
      <RadioWrapper>
        <Radio
          id={'no'}
          name={'open_hours'}
          isChecked={activeRadio === 'no'}
          title={'No opening hours'}
          value={'no'}
          cb={() => {
            setActiveRadio('no');
          }}
        />
        <Radio
          id={'always'}
          isChecked={activeRadio === 'always'}
          title={'Always open'}
          name={'open_hours'}
          value={'always'}
          cb={() => {
            setActiveRadio('always');
          }}
        />
        <Radio
          id={'select'}
          isChecked={activeRadio === 'select'}
          title={'Open on selected hours'}
          name={'open_hours'}
          value={'select'}
          cb={() => {
            setActiveRadio('select');
          }}
        />
      </RadioWrapper>
      <WorkingHoursForm>
        <WorkingHoursRow>
          <WorkingHoursCell></WorkingHoursCell>
          <WorkingHoursCell>
            <GreenText>Opening</GreenText>
          </WorkingHoursCell>
          <WorkingHoursCell>
            <GreenText>Closing</GreenText>
          </WorkingHoursCell>
          <WorkingHoursCell></WorkingHoursCell>
        </WorkingHoursRow>

        {openTimesList.map((item, i) => (
          <WorkingHoursRow key={i}>
            <WorkingHoursCell>{item.title}</WorkingHoursCell>
            <WorkingHoursCell>
              <HourInput disabled={item.isDisabled} value={item.start} />
            </WorkingHoursCell>
            <WorkingHoursCell>
              <HourInput disabled={item.isDisabled} value={item.end} />
            </WorkingHoursCell>
            <WorkingHoursCell>
              <Checkbox
                label={'Close for the day'}
                checked={item.isDisabled}
                onChange={() => {
                  const title = item.title;
                  const currentItemIndex = openTimesList.findIndex((item) => item.title === title);
                  const newState = { ...item, isDisabled: !item.isDisabled };
                  const newList = [
                    ...openTimesList.slice(0, currentItemIndex),
                    newState,
                    ...openTimesList.slice(currentItemIndex + 1)
                  ];
                  setOpenTimesList(newList);
                }}
              />
            </WorkingHoursCell>
          </WorkingHoursRow>
        ))}
      </WorkingHoursForm>
    </Section>
  );
};
