import styled from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const StyledEyeButton = styled.button`
  ${buttonReset};
  transition: all 0.3s;
  color: ${(props) => (props.isVisible ? 'var(--main-subtext)' : 'var(--main-gray-2)')};

  &:hover {
    color: var(--main-subtext);
  }
`;
