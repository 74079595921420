import { Link } from 'react-router-dom';

import { AuthPagesContainer } from 'components/auth-pages-container/auth-pages-container';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import { GlobalInput } from 'components/global-input/global-input';
import { GoogleFacebookAuth } from 'components/google-facebook-auth/google-facebook-auth';
import Header from 'components/header/header';
import { InputGroup } from 'components/input-group/input-group';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  ChecbkoxLabel,
  FormBotton,
  GrayText,
  InputGroupWrapper,
  LoginBtn,
  LoginForm,
  StyledLink
} from './login-styled';
import { useAuth } from '../../context/auth-context';
import { useNavigate } from 'react-router';

export const Login = () => {
  const { isAuth, login } = useAuth();
  const navigate = useNavigate();
  // const
  return (
    <>
      <Header />
      <AuthPagesContainer title={'Login'}>
        <LoginForm>
          <InputGroupWrapper>
            <InputGroup label={'E-mail'}>
              <GlobalInput placeholder={'Your email address'} />
            </InputGroup>
          </InputGroupWrapper>
          <InputGroupWrapper>
            <InputGroup label={'Password'}>
              <GlobalInputPassword />
            </InputGroup>
          </InputGroupWrapper>
          <LoginBtn
            height={52}
            fullWidth
            onClick={() => {
              login();
              navigate('/');
            }}
          >
            <SvgIcon name={'account'} />
            Log In
          </LoginBtn>
          <FormBotton>
            <GlobalCheckbox isChecked={true}>
              <ChecbkoxLabel>Remember me</ChecbkoxLabel>
            </GlobalCheckbox>
            <StyledLink>
              <Link to={'#'}>Forgot password?</Link>
            </StyledLink>
          </FormBotton>
          <GoogleFacebookAuth description={'Or login with'}>
            <GrayText>Don't have an account yet?</GrayText>
            <StyledLink>
              <Link to={'#'}>Sign up</Link>
            </StyledLink>
          </GoogleFacebookAuth>
        </LoginForm>
      </AuthPagesContainer>
    </>
  );
};
