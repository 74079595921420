import Header from '../../components/header/header';
import {
  BlogStyled,
  Container,
  Content,
  Main,
  Menu,
  MenuItem,
  MenuSection,
  MenuTitle,
  Sidebar
} from './blog-styled';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Featured } from './components/featured/featured';

import { ReactComponent as FaqIcon } from './icons/faq-icon.svg';
import { ReactComponent as HelpIcon } from './icons/help-icon.svg';
import { ReactComponent as NewsIcon } from './icons/news-icon.svg';
import { ReactComponent as RecentUpdatesIcon } from './icons/recent-updates-icon.svg';
import { ReactComponent as TutorialsIcon } from './icons/tutorials-icon.svg';
import { LatestVideos } from './components/latest-videos/latest-videos';
import { RecentUpdates } from './components/recent-updates/recent-updates';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Blog' }
];

export const Blog = () => {
  return (
    <BlogStyled>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Featured />
        <Main>
          <Sidebar>
            <MenuSection>
              <MenuTitle>
                <NewsIcon />
                News & Updates
              </MenuTitle>
              <Menu>
                <MenuItem>
                  <RecentUpdatesIcon />
                  Recent Updates
                </MenuItem>
              </Menu>
            </MenuSection>
            <MenuSection>
              <MenuTitle>
                <HelpIcon />
                Help Center
              </MenuTitle>
              <Menu>
                <MenuItem>
                  <FaqIcon />
                  Recent Updates
                </MenuItem>
                <MenuItem>
                  <TutorialsIcon />
                  Tutorials
                </MenuItem>
              </Menu>
            </MenuSection>
          </Sidebar>
          <Content>
            <LatestVideos />
            <RecentUpdates />
          </Content>
        </Main>
      </Container>
    </BlogStyled>
  );
};
