import styled from 'styled-components';
import { buttonReset, mainContainer } from 'assets/style-helpers/style-helpers';
import { device } from 'assets/style-helpers/breakpoints';

export const Container = styled.div`
  ${mainContainer};
  margin-bottom: 106px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Main = styled.div`
  flex-basis: 570px;
  margin-right: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 32px;
  @media ${device.tablet} {
    flex-basis: 100%;
    margin-right: 0;
  }
`;

export const MapWrapper = styled.div`
  flex-basis: calc(100% - 570px - 32px);
  @media ${device.tablet} {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TagsWrapper = styled.div`
  display: flex;
  padding-right: 50px;
  position: relative;
  overflow: hidden;
  @media ${device.tablet} {
    padding-right: 0;
    overflow-y: auto;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 6px 16px;
  border: 1px solid rgba(56, 134, 56, 0.3);
  border-radius: 20px;

  & + & {
    margin-left: 10px;
  }
`;

export const TagsList = styled.div`
  display: flex;
  transition: all 0.3s;
  @media ${device.tablet} {
    overflow-y: auto;
  }
`;

export const TagsListWrapper = styled.div`
  max-width: 520px;
  overflow: hidden;
  @media ${device.tablet} {
    max-width: 100%;
    overflow-y: auto;
  }
`;

export const ScrollNextBtn = styled.button`
  ${buttonReset};
  width: 34px;
  height: 34px;
  border: 1px solid rgba(56, 134, 56, 0.3);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;

  @media ${device.tablet} {
    display: none;
  }
`;
