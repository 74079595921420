export const HeartIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1173 6.36377H8.88272C7.57695 6.3736 6.32868 6.89642 5.41288 7.81706C4.49708 8.73769 3.98887 9.98064 4.00019 11.2721V16.1012C3.98201 18.7869 6.16726 20.9794 8.88272 20.9999H16.1173C17.4222 20.99 18.6697 20.4679 19.5854 19.5483C20.501 18.6287 21.0098 17.387 20.9998 16.0964V11.2721C21.0111 9.98064 20.5029 8.73769 19.5871 7.81706C18.6713 6.89642 17.4231 6.3736 16.1173 6.36377Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.1001 6.36269C8.1001 6.91497 8.54781 7.36269 9.1001 7.36269C9.65238 7.36269 10.1001 6.91497 10.1001 6.36269H8.1001ZM14.8999 6.36269C14.8999 6.91497 15.3477 7.36269 15.8999 7.36269C16.4522 7.36269 16.8999 6.91497 16.8999 6.36269H14.8999ZM13.5002 10.8662C13.5002 10.3139 13.0525 9.86621 12.5002 9.86621C11.9479 9.86621 11.5002 10.3139 11.5002 10.8662H13.5002ZM11.5002 13.118C11.5002 13.6703 11.9479 14.118 12.5002 14.118C13.0525 14.118 13.5002 13.6703 13.5002 13.118H11.5002ZM13.5002 13.1177C13.5002 12.5654 13.0525 12.1177 12.5002 12.1177C11.9479 12.1177 11.5002 12.5654 11.5002 13.1177H13.5002ZM11.5002 15.3695C11.5002 15.9218 11.9479 16.3695 12.5002 16.3695C13.0525 16.3695 13.5002 15.9218 13.5002 15.3695H11.5002ZM12.5001 14.1181C13.0524 14.1181 13.5001 13.6703 13.5001 13.1181C13.5001 12.5658 13.0524 12.1181 12.5001 12.1181V14.1181ZM10.2331 12.1181C9.68077 12.1181 9.23306 12.5658 9.23306 13.1181C9.23306 13.6703 9.68077 14.1181 10.2331 14.1181V12.1181ZM12.5001 12.1181C11.9478 12.1181 11.5001 12.5658 11.5001 13.1181C11.5001 13.6703 11.9478 14.1181 12.5001 14.1181V12.1181ZM14.7671 14.1181C15.3194 14.1181 15.7671 13.6703 15.7671 13.1181C15.7671 12.5658 15.3194 12.1181 14.7671 12.1181V14.1181ZM10.1001 6.36269C10.1001 5.06817 11.1642 4 12.5 4V2C10.0804 2 8.1001 3.94289 8.1001 6.36269H10.1001ZM12.5 4C13.8359 4 14.8999 5.06817 14.8999 6.36269H16.8999C16.8999 3.94289 14.9196 2 12.5 2V4ZM11.5002 10.8662V13.118H13.5002V10.8662H11.5002ZM11.5002 13.1177V15.3695H13.5002V13.1177H11.5002ZM12.5001 12.1181H10.2331V14.1181H12.5001V12.1181ZM12.5001 14.1181H14.7671V12.1181H12.5001V14.1181Z'
        fill='#29263A'
      />
    </svg>
  );
};
