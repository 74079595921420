import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: none;
  width: 100%;
  height: 160px;
  align-items: center;
  justify-content: center;
  background: #136400;
  margin-bottom: 106px;
  @media ${device.laptop} {
    display: flex;
  }
  @media ${device.tablet} {
    display: flex;
    height: 166px;
    margin: 56px 0;
  }
  @media ${device.mobile} {
    display: flex;
    margin: 48px 0;
  }
  span {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.2);
  }
`;
