import styled from 'styled-components';
import { buttonReset } from 'assets/style-helpers/style-helpers';
import { device } from 'assets/style-helpers/breakpoints';

export const LatestVideosStyled = styled.div`
  min-width: 0;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  min-width: 0;
  position: relative;
  z-index: 2;
  @media ${device.mobile} {
    width: 100% !important;
  }
  &::after {
    position: absolute;
    height: 100%;
    width: 180px;
    background: linear-gradient(271.2deg, #ffffff 0.91%, rgba(255, 255, 255, 0) 98.87%);
    opacity: 0.6;
    top: 0;
    right: 0;
    content: '';
    z-index: 5;
    pointer-events: none;
  }

  .slick-list {
    margin: 0 -8px;
  }
  .slick-slide > div {
    padding: 0 8px;
  }
`;

export const Arrows = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SliderBtn = styled.button`
  ${buttonReset};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const PrevSlideBtn = styled(SliderBtn)`
  border: 1px solid #c6d2e9;
  color: #29263a;
`;

export const NextSlideBtn = styled(SliderBtn)`
  color: #ffffff;
  margin-left: 16px;
  background: var(--main-green);
  svg {
    transform: rotate(180deg);
  }
`;
