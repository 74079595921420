import { Link } from 'react-router-dom';
import { TagsStyled } from './tags-styled';

export const Tags = () => {
  return (
    <TagsStyled>
      <Link to={'#'}>#DealsOfTheDay</Link>
      <Link to={'#'}>Featured Products</Link>
      <Link to={'#'}>Popular Categories</Link>
      <Link to={'#'}>#StoresYouWillLove</Link>
      <Link to={'#'}>#FindItOnAuction</Link>
    </TagsStyled>
  );
};
