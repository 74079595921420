import styled from 'styled-components';
import { LatoFont, PoppinsFont } from '../../../../assets/style-helpers/style-helpers';

export const PaymentStyled = styled.div``;

export const PaymentList = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid var(--divider);

  & + & {
    margin-top: 20px;
  }
`;

export const PaymentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 12px;
  }
`;

export const PaymentItemName = styled.div`
  ${LatoFont};
`;

export const PaymentItemTitle = styled.div``;

export const PaymentItemTotal = styled(PaymentItem)`
  color: var(--main-green);
  font-size: 17px;
  ${PaymentItemName} {
    ${PoppinsFont};
  }
`;

export const PaymentTypeTitle = styled.div`
  text-transform: uppercase;
  color: var(--main-gray-3);
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0.04em;
  margin-bottom: 10px;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
`;

export const Bottom = styled.div`
  margin-top: 24px;
  button {
    margin-top: 16px;
  }
`;

export const CheckboxLabelStyled = styled.div`
  color: var(--main-subtext);
  a {
    color: var(--main-green);
  }
`;
