import styled, { css } from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 106px;
`;

export const Main = styled.div`
  flex-basis: 866px;
  margin-right: 32px;
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const Side = styled.div`
  flex-basis: calc(100% - 866px - 32px);
  @media ${device.laptop} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
    overflow-y: auto;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            opacity: 0;
            pointer-events: none;
            transition-delay: 0.3s;
          `
        : css`
            opacity: 1;
            pointer-events: all;
          `};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  button + button {
    margin-left: 16px;
  }
  @media ${device.mobile} {
    display: block;
    > button {
      width: 100%;
      margin-bottom: 18px;
    }
    button + button {
      margin-left: unset;
    }
    > div {
      display: flex;
      flex-direction: column;
      > button {
        margin-bottom: 10px;
      }
    }
  }
`;

export const CreateNewOderSidebarStyled = styled.div`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 40px;
  @media ${device.laptop} {
    position: fixed;
    right: 0;
    top: 0;
    width: 670px;
    padding: 40px 103px 40px 32px;
    z-index: 1001;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            transform: translateX(100%);
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media ${device.tablet} {
    padding: 20px 12px;
    width: 100%;
    border-radius: 0;
  }
`;

export const HelpCardStyled = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  height: 190px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PLayBtn = styled.button`
  ${buttonReset};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  div:first-child {
    max-width: 320px;
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 24px;
`;

export const OpenSidebarBtn = styled.button`
  display: none;
  @media ${device.laptop} {
    display: flex;
  }
  @media ${device.tablet} {
    display: none;
  }
  ${buttonReset};
  position: fixed;
  right: -57px;
  top: 340px;
  z-index: 1001;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  cursor: pointer;
  height: 40px;
  background: #ebf3eb;
  border-radius: 6px 6px 0px 0px;
  color: var(--main-green);
  transition: all 0.3s;
  ${(props) =>
    props.isSidebarHidden
      ? css`
          transform: translateX(0) rotate(-90deg);
        `
      : css`
          transform: translateX(-670px) rotate(-90deg);
        `}
  svg {
    margin-right: 8px;
  }
`;
