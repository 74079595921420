export const EstateIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.1 11.1111H6.78C5.77191 11.1111 5.26786 11.1111 4.88282 11.3049C4.54413 11.4753 4.26876 11.7473 4.09619 12.0818C3.9 12.4621 3.9 12.9599 3.9 13.9556V20M20.1 20V6.84444C20.1 5.8488 20.1 5.35097 19.9038 4.97068C19.7312 4.63617 19.4559 4.36421 19.1172 4.19377C18.7321 4 18.2281 4 17.22 4H13.98C12.9719 4 12.4679 4 12.0828 4.19377C11.7441 4.36421 11.4688 4.63617 11.2962 4.97068C11.1 5.35097 11.1 5.8488 11.1 6.84444V20M21 20H3M14.25 7.55556H16.95M14.25 11.1111H16.95M14.25 14.6667H16.95'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
