import styled, { css } from 'styled-components';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div`
  margin-top: 40px;
`;
export const TabsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--main-gray-2);
  margin-bottom: 24px;
`;
export const TabBtn = styled.button`
  ${buttonReset};
  color: var(--main-subtext);
  position: relative;
  &::after {
    position: absolute;
    content: '';
    background: var(--main-green);
    border-radius: 4px 4px 0px 0px;
    height: 4px;
    width: 100%;
    left: 0;
    bottom: -16px;
    opacity: 0;
    transition: all 0.3s;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: var(--main-text);
      &::after {
        opacity: 1;
      }
    `}
`;
export const TabsContent = styled.div``;

export const MapWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 295px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
