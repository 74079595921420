import styled from 'styled-components';
import { LatoFont } from 'assets/style-helpers/style-helpers';
import { title30 } from 'assets/style-helpers/typography';
import { device } from 'assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const Title = styled.div`
  ${title30};
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 32px;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
