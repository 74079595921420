import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const ShoppingCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-bottom: 40px;
  @media ${device.tablet} {
    grid-gap: 16px;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`;

export const Card = styled.div`
  height: 200px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--main-white);
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(16, 29, 15, 0.6) 25.89%, rgba(217, 217, 217, 0) 85.25%);
    z-index: 2;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  position: relative;
  z-index: 2;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 6px;
  font-weight: 600;
`;

export const Description = styled.div`
  position: relative;
  z-index: 2;
  font-weight: 400;
`;

export const ShoppingList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 25px;
  max-height: 212px;
  overflow: hidden;
  ${(props) =>
    props.isAllVisible
      ? css`
          max-height: 100%;
        `
      : css`
          max-height: 212px;
        `}
  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ShoppingListItem = styled(Link)`
  display: flex;
  flex-direction: column;
`;

export const ShoppingListItemTitle = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  span {
    margin-right: 10px;
  }
`;

export const ShoppingListItemDescription = styled.span``;

export const ShowMoreBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;
