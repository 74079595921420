import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 103px 124px 103px;
  // @media ${device.laptop} {
  //   padding: 16px 16px 124px;
  // }
  @media ${device.tablet} {
    padding: 0 15px 56px 15px;
  }
`;
export const MobViewSelection = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
`;
export const SearchResult = styled.div`
  display: block;
  margin: 40px 0 14px 0;
  padding: 0 103px 0 103px;
  @media ${device.tablet} {
    padding: 0 15px 0 15px;
    margin-bottom: unset;
  }
  @media ${device.mobile} {
    padding: 0 15px 0 15px;
    margin-bottom: unset;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media ${device.mobile} {
      align-items: flex-end;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-text);
`;

export const TextBold = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--main-text);
  margin-left: 8px;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e5eb;
  margin: 25px 0 0 0;

  @media ${device.mobile} {
    margin: 15px 0 0 0;
  }
`;

export const ResultsPerPage = styled.div`
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    > p {
      color: var(--main-subtext);
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }

    > span {
      display: flex;
      align-items: center;
      color: var(--main-text);
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      cursor: pointer;

      > svg {
        margin-left: 6px;
      }
    }
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;
