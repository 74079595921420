import { GlobalCheckbox } from '../../../../../../components/global-checkbox/global-checkbox';
import { GlobalInput } from '../../../../../../components/global-input/global-input';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import { CheckboxWrapper, Section, SectionTitle } from '../../add-new-product-form-styled.jsx';

export const ProductInventory = () => {
  return (
    <Section>
      <SectionTitle>Inventory</SectionTitle>
      <InputGroup label={'stock quantity'}>
        <GlobalInput placeholder={'Write the stock quantity'} />
      </InputGroup>
      <InputGroup>
        <CheckboxWrapper>
          <GlobalCheckbox>Allow back-orders when stock is depleted</GlobalCheckbox>
        </CheckboxWrapper>
      </InputGroup>
    </Section>
  );
};
