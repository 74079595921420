import styled from 'styled-components';
import { buttonReset, LatoFont } from '../../assets/style-helpers/style-helpers';

export const ArrowStyled = styled.div``;

export const TippyBox = styled.div`
  border-radius: 4px;
  padding: 6px 12px;
  background: ${(props) => props.tippyBgColor};
  color: ${(props) => props.color};
  ${LatoFont};
  font-size: 12px;
  line-height: 1;

  &[data-placement='bottom-start'] {
    margin-top: 0px;
    margin-left: -10px;
    & > ${ArrowStyled} {
      top: -6px;
      left: -6px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StarButton = styled.button`
  ${buttonReset};
`;

export const StarsWrapper = styled.div`
  margin-right: 12px;
  display: flex;
`;

export const Text = styled.div`
  ${LatoFont};
  font-weight: 700;
  span {
    color: ${(props) => props.color};
  }
`;
