import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import React, { useState } from 'react';
import filter from '../../../../assets/filter.svg';
import search from '../../../../assets/search.svg';
import Checkbox from '../../../../components/checkbox/checkbox';
import Table from '../../../../components/table/table';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import {
  AddItemsBtn,
  CheckBlock,
  CheckBox,
  Container,
  DropdownsWrapper,
  FilterButton,
  InputWrapper,
  LeftSide,
  MobileSearch,
  Navigation,
  RightSide,
  SearchButton,
  SearchInput,
  SellItemBtn,
  Title
} from './all-sell-items-styled';
import { columnsData, columnsRows } from './mock';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'All Sell Items' }
];

const DropdownItems = ['Service Type', 'Status', 'Category'];

const AllSellItemsDashboard = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Sell Items</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
            <CheckBox>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={true}
                  label={'Deals  Of The Day'}
                />
              </CheckBlock>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={false}
                  label={'Featured Product'}
                />
              </CheckBlock>
            </CheckBox>
          </LeftSide>
          <RightSide>
            <AddItemsBtn>
              <GlobalButton type='secondary'>
                <SvgIcon name='plus-second' />
                <p>Add items</p>
              </GlobalButton>
            </AddItemsBtn>
          </RightSide>
        </Navigation>
        <Table columnsData={columnsData} columnsRows={columnsRows} hasCheckbox hasMenu />
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default AllSellItemsDashboard;
