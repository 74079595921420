import 'swiper/css';
import { SectionHeader } from 'components/section-header/section-header';
import { CardsWrapper, Container, Wrapper } from './deals-of-day-styled';
import { PCard, ProductCardTypes } from 'components/p-card/p-card';
import { MobileProductSwiper } from '../mobile-product-swiper/mobile-product-swiper';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import img1 from 'assets/temp-img/temp4.png';
import img2 from 'assets/temp-img/temp5.png';
import img3 from 'assets/temp-img/temp6.png';

const dealsMock = [
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    description: 'Samar Haute Plug | Norton',
    delivery: true,
    rate: 4.8,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90',
    stock: true
  },
  {
    img: img2,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    description: 'Samar Haute Plug | Norton',
    delivery: false,
    rate: 3.8,
    reviews: 28,
    old_price: '$39.99',
    price: '$19.90',
    stock: false
  },
  {
    img: img1,
    title: 'ALD / New Era Yankees Hat, black',
    description: 'Samar Haute Plug | Norton',
    delivery: true,
    rate: 4.1,
    reviews: 30,
    old_price: '$19.99',
    price: '$10.00',
    stock: true
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    description: 'Samar Haute Plug | Norton',
    delivery: true,
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90',
    stock: false
  },
  {
    img: img3,
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    description: 'Samar Haute Plug | Norton',
    delivery: true,
    rate: 3.5,
    reviews: 51,
    old_price: '$39.99',
    price: '$19.90',
    stock: false
  }
];

export const DealsOfDay = () => {
  return (
    <Wrapper>
      <Container>
        <SectionHeader link='#' title={'Deals of the Day'} hasClockCount />
        <GlobalButton type={'orange-secondary'}>
          <SvgIcon name={'eye'} />
          See all
        </GlobalButton>
        <CardsWrapper>
          {dealsMock.map((product, index) => {
            return <PCard product={product} hasLabel type={ProductCardTypes.BASIC} key={index} />;
          })}
        </CardsWrapper>
        <MobileProductSwiper products={dealsMock} />
      </Container>
    </Wrapper>
  );
};
