export const SportsIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.48096 11.5714H10.1603M8.32064 9.71429V13.4286M14.7595 12.5H14.7687M17.519 10.6429H17.5282M10.5733 6H13.4267C15.8412 6 17.0484 6 17.996 6.4619C18.8303 6.86858 19.5235 7.52015 19.9848 8.33126C20.5087 9.25251 20.5947 10.4681 20.7667 12.8993L20.993 16.0978C21.104 17.666 19.8736 19 18.3161 19C17.5196 19 16.7643 18.6429 16.2544 18.0252L15.9093 17.6071C15.5937 17.2248 15.4358 17.0336 15.2561 16.8791C14.8794 16.5552 14.4233 16.3396 13.9357 16.2548C13.7031 16.2143 13.4565 16.2143 12.9635 16.2143H11.0365C10.5435 16.2143 10.2969 16.2143 10.0643 16.2548C9.57668 16.3396 9.12061 16.5552 8.74387 16.8791C8.56416 17.0336 8.40633 17.2248 8.09068 17.6071L7.74559 18.0252C7.2357 18.6429 6.48036 19 5.68388 19C4.12639 19 2.89599 17.666 3.00696 16.0978L3.23327 12.8993C3.40529 10.4681 3.49131 9.25251 4.01521 8.33126C4.47649 7.52015 5.16968 6.86858 6.00399 6.4619C6.95157 6 8.15881 6 10.5733 6Z'
        stroke='#29263A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
