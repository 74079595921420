import styled from 'styled-components';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { title30 } from '../../../../assets/style-helpers/typography';

export const Container = styled.div`
  ${mainContainer};
  display: flex;
  min-height: 100vh;
  padding: 0;

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
  @media ${device.mobile} {
  }
`;

export const Main = styled.div`
  flex-basis: calc(100% - 290px - 32px);
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
  padding-bottom: 106px;
  @media ${device.tablet} {
    padding-bottom: 56px;
  }
  @media ${device.mobile} {
    padding-bottom: 48px;
  }
`;

export const Title = styled.div`
  ${title30};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const ViewService = styled.div`
  border-bottom: 1px solid #388638;
  font-family: 'Lato';
  color: #388638;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;
