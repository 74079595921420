import styled from 'styled-components';
import { device } from '../../../../../../assets/style-helpers/breakpoints';
import { LatoFont } from '../../../../../../assets/style-helpers/style-helpers';

export const Container = styled.section`
  padding-bottom: 110px;
  padding-right: 40px;
  @media ${device.tablet} {
    padding-bottom: 60px;
    padding-right: 0;
  }

  @media ${device.mobile} {
    padding-bottom: 48px;
  }
  table {
    thead {
      background: #f7f7f7;
      border: 1px solid #dcdcdc;
      border-radius: 12px 12px 0 0;
      border-collapse: separate;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: var(--main-subtext);

      tr {
        th {
          :last-child {
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          :last-child {
            cursor: unset;
          }
        }
      }
    }
  }
`;

export const Subtitle = styled.div`
  ${LatoFont};
  color: var(--secondary-text);
`;

export const Status = styled.div``;

export const Pending = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 3px 16px 4px;
  color: #f49910;
  background: #fef3e2;
  border-radius: 20px;
  text-align: center;
  width: 92px;
`;

export const Done = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 3px 16px 4px;
  color: #34a852;
  background: rgba(52, 168, 82, 0.12);
  border-radius: 20px;
  text-align: center;
  width: 92px;
`;

export const Canceled = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 3px 16px 4px;
  color: #e54d45;
  background: rgba(229, 77, 69, 0.12);
  border-radius: 20px;
  text-align: center;
  width: 92px;
`;

export const Label = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #29263a;
  margin-bottom: 8px;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 17px;

  @media ${device.tablet} {
    margin-bottom: 18px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 282px;
  width: 100%;

  @media ${device.tablet} {
    max-width: unset;
    margin-right: 16px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileSearch = styled.div`
  display: none;

  @media ${device.mobile} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 11px;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: var(--main-text);
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid var(--divider);
  border-radius: 6px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  padding: 6px 0 6px 18px;
  outline: none;
`;

export const SearchButton = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-green);
  max-height: 32px;
  border-radius: 6px;
  padding: 9px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const Content = styled.div`
  padding-bottom: 106px;
  @media ${device.tablet} {
    padding-bottom: 0;
  }
  @media ${device.mobile} {
    padding-bottom: 0;
  }
  > div {
    margin-bottom: 18px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  max-width: 202px;
  flex-wrap: wrap;
  margin: 15px 0;
`;

export const Tag = styled.div`
  width: max-content;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--main-text);
  background: #f7f7f7;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 0 6px 6px 0;
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: none;
  }

  > div {
    width: 160px;
    height: 38px;
    margin-right: 12px;
    > p {
      margin-right: 9px;
    }
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FilterButton = styled.button`
  display: none;
  justify-content: space-between;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  padding: 14px 20px;
  background-color: var(--main-green);
  border: none;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }

  @media ${device.mobile} {
    display: flex;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 14px;
    padding: 11px 26px;

    img {
      margin-right: 11px;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteBox = styled.div`
  display: flex;
  margin-left: 17px;
  padding-left: 15px;
  border-left: 1px solid #e8e8e8;
  svg {
    cursor: pointer;
  }
`;
