import styled, { css } from 'styled-components';
import { device } from '../../../../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../../../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 106px;
`;

export const Main = styled.div`
  flex-basis: 925px;
  margin-right: 32px;
  @media ${device.laptop} {
    flex-basis: auto;
    width: 100%;
  }
`;

export const Title = styled.div`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #29263a;
  margin-bottom: 15px;

  @media ${device.mobile} {
    font-size: 24px;
    padding-left: 15px;
  }
`;

export const BoxClose = styled.div`
  position: absolute;
  right: 40px;
  top: -4px;
  cursor: pointer;

  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const SectionSubtitle = styled.div`
  margin-bottom: 15px;
`;

export const SectionTitle = styled.div`
  margin-bottom: 15px;
`;

export const SecondTitle = styled.div`
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const InputGroupWrapper = styled.div`
  margin-bottom: 18px;
`;

export const Orders = styled.div`
  table {
    width: 100%;
    min-width: 100%;

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const Side = styled.div`
  flex-basis: calc(100% - 866px - 32px);
  @media ${device.laptop} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
    overflow-y: auto;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            opacity: 0;
            pointer-events: none;
            transition-delay: 0.3s;
          `
        : css`
            opacity: 1;
            pointer-events: all;
          `};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  button + button {
    margin-left: 16px;

    @media ${device.mobile} {
      margin-top: 16px;
    }
  }
  @media ${device.mobile} {
    flex-direction: column;
    margin: 0px 15px;
    margin-bottom: 15px;
  }
`;

export const CreateNewOderSidebarStyled = styled.div`
  background: #ffffff;
  /* drop shadow green_v */

  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  padding: 28px 32px 40px;
  @media ${device.laptop} {
    position: fixed;
    right: 0;
    top: 0;
    width: 670px;
    padding: 40px 103px 40px 32px;
    z-index: 1001;
    overflow-y: auto;
    height: 100vh;
    transition: all 0.3s;
    ${(props) =>
      props.isSidebarHidden
        ? css`
            transform: translateX(100%);
          `
        : css`
            transform: translateX(0);
          `}
  }

  @media ${device.tablet} {
    padding: 20px 12px;
    width: 100%;
    border-radius: 0;
  }
`;

export const HelpCardStyled = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  height: 190px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PLayBtn = styled.button`
  ${buttonReset};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  div:first-child {
    max-width: 320px;
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 24px;
`;

export const Section = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  border-radius: 16px;
  margin-bottom: 32px;
  padding: 32px;
  @media ${device.tablet} {
    padding: 15px;
  }
`;

export const Img = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
`;

export const SpanBox = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 12px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  text-align: center;
  color: #29263a;
`;

export const SelectBox = styled.div``;

export const CheckboxesGroup = styled.div`
  > div {
    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  background: ${(props) => (props.green ? '#E7F0E7' : '') || (props.grey ? '#F5F5F5' : '')};
  padding: 12px 0 12px 16px;
  border-radius: 6px;
  > div {
    > label {
      color: #29263a !important;
    }
  }
`;
