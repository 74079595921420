import filter from '../../../../assets/filter.svg';
import Table from '../../../../components/table/table';
import { Container, FilterButton, LeftSide, MobileSearch, Navigation } from './lists-styled';
import { columnsData, columnsRows } from './mock';

const List = () => {
  return (
    <Container>
      <Navigation>
        <LeftSide>
          <FilterButton>
            <img src={filter} alt='' />
            Filters
          </FilterButton>
          <MobileSearch>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 15L12.2779 12.2778M14.2222 7.61111C14.2222 11.2623 11.2623 14.2222 7.61111 14.2222C3.95989 14.2222 1 11.2623 1 7.61111C1 3.95989 3.95989 1 7.61111 1C11.2623 1 14.2222 3.95989 14.2222 7.61111Z'
                stroke='#29263a'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Search</p>
          </MobileSearch>
        </LeftSide>
      </Navigation>
      <Table columnsData={columnsData} columnsRows={columnsRows} />
    </Container>
  );
};

export default List;
