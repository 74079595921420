import { ReactComponent as Delete } from '../../../../assets/icons/delete-ico.svg';
import { ReactComponent as Respond } from '../../../../assets/respond.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { Action, Canceled, DeleteBox, Done, Pending } from './lists-styled';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Client Name',
    accessor: 'clientname'
  },
  {
    Header: 'Service Required',
    accessor: 'servicerequired'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Date & Time',
    accessor: 'datetime'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }
];

export const columnsRows = [
  {
    id: 12520,
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Pending>Pending</Pending>,
    actions: (
      <Action>
        <GlobalButton type={'primary'}>
          <Respond />
          Respond
        </GlobalButton>
        <DeleteBox>
          <Delete />
        </DeleteBox>
      </Action>
    )
  },
  {
    id: 12520,
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Done>Done</Done>,
    actions: ''
  },
  {
    id: 12520,
    clientname: 'Langton Shoriwa',
    servicerequired: 'Company Registration',
    contact: '+263 784 456 566',
    location: 'Harare, ZW',
    datetime: '07 Jul 2022, 09:00',
    status: <Canceled>Canceled</Canceled>,
    actions: ''
  }
];
